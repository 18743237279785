'use client'

import {
  ActionIcon,
  Alert,
  Avatar,
  Badge,
  Button,
  Combobox,
  createTheme,
  CSSVariablesResolver,
  defaultVariantColorsResolver,
  Divider,
  Fieldset,
  Input,
  InputBase,
  MantineColorsTuple,
  Menu,
  Modal,
  NavLink,
  Notification,
  parseThemeColor,
  Popover,
  ScrollArea,
  SegmentedControl,
  Select,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
  VariantColorsResolver,
} from '@mantine/core'
import { isUndefined } from 'lodash-es'
import { themeColors } from '~/client/dashboard/components/global/colors.ts'
import classes from './theme.module.css'

export const components = {}

export const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  // Set dark text for light backgrounds
  if (
    !isUndefined(parsedColor.shade) &&
    parsedColor.shade < 2 &&
    input.variant === 'filled'
  ) {
    return { ...defaultResolvedColors, color: 'var(--mantine-color-dark-3)' }
  }

  // Completely override variant
  if (input.variant === 'light') {
    // return {
    //   background: rgba(parsedColor.value, 0.1),
    //   hover: rgba(parsedColor.value, 0.15),
    //   border: `${rem(1)} solid ${parsedColor.value}`,
    //   color: darken(parsedColor.value, 0.1),
    // }
  }

  // Add new variants support
  if (input.variant === 'danger') {
    // return {
    //   background: 'var(--mantine-color-red-9)',
    //   hover: 'var(--mantine-color-red-8)',
    //   color: 'var(--mantine-color-white)',
    //   border: 'none',
    // }
  }

  return defaultResolvedColors
}

export const theme = createTheme({
  // Map colors to arrays
  colors: Object.fromEntries(
    Object.entries(themeColors).map(([key, value]) => [
      key,
      Object.values(value) as unknown as MantineColorsTuple,
    ]),
  ),
  variantColorResolver,
  fontFamily: '"Nunito", sans-serif',
  headings: {
    fontFamily: '"Roboto Slab", sans-serif',
    fontWeight: '700',
    sizes: {
      h1: {
        fontSize: '2.125rem',
        lineHeight: '1.3',
        fontWeight: '700',
      },
      h2: {
        fontSize: '1.625rem',
        lineHeight: '1.35',
        fontWeight: '700',
      },
      h3: {
        fontSize: '1.375rem',
        lineHeight: '1.4',
        fontWeight: '700',
      },
      h4: {
        fontSize: '1.125rem',
        lineHeight: '1.45',
        fontWeight: '700',
      },
      h5: {
        fontSize: '1rem',
        lineHeight: '1.5',
        fontWeight: '700',
      },
      h6: {
        fontSize: '0.875rem',
        lineHeight: '1.5',
        fontWeight: '700',
      },
    },
  },
  primaryShade: {
    light: 4,
    dark: 4,
  },
  focusRing: 'auto',
  white: '#fff',
  black: '#000',
  defaultRadius: 'sm',
  fontFamilyMonospace:
    'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
  primaryColor: 'blue',
  respectReducedMotion: false,
  cursorType: 'default',
  defaultGradient: {
    from: 'orange',
    to: 'yellow',
    deg: 45,
  },
  shadows: {
    xs: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1)',
    sm: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem, rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem',
    md: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0 0.625rem 0.625rem -0.3125rem',
    lg: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 1.75rem 1.4375rem -0.4375rem, rgba(0, 0, 0, 0.04) 0 0.75rem 0.75rem -0.4375rem',
    xl: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 2.25rem 1.75rem -0.4375rem, rgba(0, 0, 0, 0.04) 0 1.0625rem 1.0625rem -0.4375rem',
  },
  fontSizes: {
    xs: '0.625rem', // 10px
    sm: '0.75rem', // 12px
    md: '0.875rem', // 14px
    lg: '1.25rem', // 20px
    xl: '1.5rem', // 24px
  },
  radius: {
    xs: '0.125rem',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
    xl: '2rem',
  },
  spacing: {
    xs: '0.25rem', // 4px
    sm: '0.5rem', // 8px
    md: '0.75rem', // 12px
    lg: '1rem', // 16px
    xl: '2rem', // 32px
  },
  // NOTE: These must match the breakpoints in postcss.config
  breakpoints: {
    xs: '0em',
    sm: '36em',
    md: '54em',
    lg: '70em',
    xl: '100em',
  },
  activeClassName: classes.active,
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        classNames: {
          root: classes.actionIcon,
        },
      },
    }),
    Alert: Alert.extend({
      defaultProps: {
        color: 'yellow',
      },
      classNames: {
        root: classes.alert,
      },
    }),
    Avatar: Avatar.extend({
      classNames: {
        root: classes.avatar,
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        size: 'sm',
        variant: 'light',
      },
      classNames: {
        root: classes.badge,
      },
    }),
    Button: Button.extend({
      defaultProps: {
        classNames: {
          root: classes.button,
        },
      },
    }),
    Combobox: Combobox.extend({
      defaultProps: {
        arrowSize: 9,
        classNames: {},
      },
    }),
    ComboboxDropdown: Combobox.Dropdown.extend({
      defaultProps: {
        classNames: {
          dropdown: classes.combobox__dropdown,
        },
      },
    }),
    ComboboxOption: Combobox.Option.extend({
      defaultProps: {
        classNames: {
          option: classes.combobox__option,
        },
      },
    }),
    Divider: Divider.extend({
      defaultProps: {
        classNames: {
          root: classes.divider,
        },
      },
    }),
    Fieldset: Fieldset.extend({
      defaultProps: {
        classNames: {
          root: classes.fieldset,
        },
      },
    }),
    Input: Input.extend({
      defaultProps: {
        size: 'md',
        classNames: {
          input: classes.input,
        },
      },
    }),
    InputBase: InputBase.extend({
      defaultProps: {
        size: 'md',
        classNames: {
          root: classes.inputBase,
          input: classes.inputBase__input,
          label: classes.inputBase__label,
        },
      },
    }),
    InputWrapper: Input.Wrapper.extend({
      defaultProps: {
        size: 'md',
        classNames: {
          label: classes.textInput__label,
        },
      },
    }),
    Menu: Menu.extend({
      defaultProps: {
        classNames: {
          dropdown: classes.menu__dropdown,
          divider: classes.divider,
          item: classes.menu__item,
          itemSection: classes.menu__itemSection,
        },
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        padding: 'lg',
        radius: 'md',
        overlayProps: {
          backgroundOpacity: 0.3,
          blur: 3,
        },
        classNames: {
          title: classes.modal__title,
        },
      },
    }),
    NavLink: NavLink.extend({
      defaultProps: {
        classNames: {
          root: classes.navlink,
          label: classes.navlink__label,
        },
      },
    }),
    Notification: Notification.extend({
      defaultProps: {
        withBorder: true,
        withCloseButton: true,
        classNames: {
          root: classes.notification,
          body: classes.notification__body,
          description: classes.notification__description,
        },
      },
    }),
    Popover: Popover.extend({
      defaultProps: {
        vars: (theme, props, ctx) => {
          return {
            dropdown: {
              '--popover-shadow': 'var(--mantine-shadow-sm)',
            },
          }
        },
        classNames: {
          dropdown: classes.popover__dropdown,
        },
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      defaultProps: {
        transitionDuration: 120,
      },
      classNames: {
        root: classes.segmentedControl,
        indicator: classes.segmentedControl__indicator,
      },
    }),
    Select: Select.extend({
      defaultProps: {
        checkIconPosition: 'right',
        allowDeselect: false,
        size: 'md',
      },
    }),
    ScrollArea: ScrollArea.extend({
      defaultProps: {
        scrollbars: 'y',
      },
      classNames: {
        root: classes.scrollArea,
        scrollbar: classes.scrollArea__scrollbar,
      },
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        classNames: {
          root: classes.tabs,
          tab: classes.tabs__tab,
        },
      },
    }),
    Text: Text.extend({
      defaultProps: {
        classNames: {
          root: classes.text,
        },
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        size: 'md',
        classNames: {
          label: classes.textInput__label,
          input: classes.textInput__input,
        },
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        size: 'md',
        classNames: {
          input: classes.textarea__input,
          label: classes.textarea__label,
        },
      },
    }),
    Title: Title.extend({
      defaultProps: {
        classNames: {
          root: classes.title,
        },
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        multiline: true,
        maw: 200,
        arrowSize: 7,
        classNames: {
          tooltip: classes.tooltip,
          arrow: classes.tooltip__arrow,
        },
      },
    }),
  },
})

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-scale': '1',
    '--mantine-line-height': '1.2',
    '--mantine-line-height-xs': '1.2',
    '--mantine-line-height-sm': '1.2',
    '--mantine-line-height-md': '1.2',
    '--mantine-line-height-lg': '1.2',
    '--mantine-line-height-xl': '1.2',
  },
  light: {
    '--mantine-color-gray-light': 'rgba(195, 201, 205, 0.15)',
    '--mantine-color-gray-light-hover': 'rgba(195, 201, 205, 0.17)',
    '--mantine-color-text': themeColors.dark[500],
    '--mantine-color-placeholder': themeColors.gray[500],
    '--mantine-color-error': themeColors.red[500],
    '--mantine-line-height': '1.2',
    '--burger-color': themeColors.gray[700],
    '--tooltip-bg': themeColors.white[0],
    '--text-color-important': 'black',
  },
  dark: {
    '--mantine-color-gray-light': 'rgba(195, 201, 205, 0.1)',
    '--mantine-color-gray-light-hover': 'rgba(195, 201, 205, 0.12)',
    '--mantine-color-text': themeColors.dark[0],
    '--mantine-color-placeholder': 'rgba(255,255,255,0.5)',
    '--burger-color': themeColors.dark[0],
    '--tooltip-bg': themeColors.dark[500],
    '--text-color-important': themeColors.gray[0],
    '--mantine-color-error': themeColors.red[300],
  },
})
