'use client'
import {
  ActionIcon,
  Box,
  Button,
  Combobox,
  Divider,
  Input,
  Pill,
  ScrollArea,
  Tabs,
  Text,
  Title,
  Tooltip,
  useCombobox,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import dayjs from 'dayjs'
import { memo } from 'react'
import { RouteMap } from '~/client/RouteMap.ts'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  Portal,
  PortalTargetId,
} from '~/client/dashboard/components/global/Portals'
import {
  DropdownDivider,
  DropdownMenu,
  DropdownOption,
} from '~/client/dashboard/components/global/Prefabs'
import { ViewModeToggle } from '~/client/dashboard/components/global/ViewModeToggle'
import { ProcessIcon } from '~/client/dashboard/components/process/ProcessComponents'
import { Diagram } from '~/client/dashboard/components/process/diagram/Diagram'
import { ProcessDocumentSelectionInput } from '~/client/dashboard/forms/ProcessForms'
import {
  useArchiveProcess,
  useDeleteProcess,
  useDeleteRevision,
  useUnarchiveProcess,
  useUpdateRevisionProfile,
} from '~/client/dashboard/mutations/process-mutations'
import { useProcessQuery } from '~/client/dashboard/queries/process-queries'
import { DiagramViewMode, useDiagramContext } from "../../stores/DiagramStore"
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import {
  useProcessAccess,
  useProcessContext,
} from '~/client/dashboard/stores/ProcessStore'
import {
  useCanEditRevision,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore'
import { Column, Row } from '~/client/shared/Layout'
import { documentIcon } from '~/client/shared/data/document-data'
import { globalIcons } from '~/client/shared/data/global-data'
import { navigate, useNavigate } from '~/client/shared/hooks/useNavigate'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import { ButtonWithCommand, WithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { TITLE_MAX_LENGTH } from '~/schemas/revision-schema'
import classes from './Process.module.css'
import { useDarkMode, useDeviceSize } from '~/client/dashboard/stores/GlobalStore'

export const RevisionContent = () => {
  const revisionId = useProcessContext((x) => x.memberProfile.activeRevisionId)

  return (
    <Column
      w="100%"
      h="100%"
      style={{ overflow: 'hidden', position: 'relative' }}
    >
      <ContentInner />
    </Column>
  )
}

const ContentInner = memo(() => {
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const vars = useThemeVars()
  const processId = useProcessContext((x) => x.process.id)
  const isArchived = useProcessContext((x) => x.process.isArchived)
  const revisionHistory = useProcessContext((x) => x.process.revisionHistory)
  const organizationId = useProcessContext((x) => x.process.organizationId)
  const viewMode = useDiagramContext((x) => x.viewMode)
  const revisionId = useRevisionContext((x) => x.revision.id)
  const isDraft = useRevisionContext((x) => x.revision.isDraft)
  const title = useRevisionContext((x) => x.revision.title)
  const isTitleSet = useRevisionContext((x) => x.revision.isTitleSet)
  const access = useProcessAccess()
  const { updateProcess, isUpdatingProcess } = useUpdateRevisionProfile()
  const { unarchiveProcess, isUnarchivingProcess } = useUnarchiveProcess()

  // Refetch process
  useProcessQuery(
    {
      id: processId,
      organizationId,
    },
    { refetchOnMount: false, refetchOnWindowFocus: true },
  )

  return (
    <Column className={classes.process} style={{ height: '100%' }}>
      {/* Header */}
      <Row
        className={classes.process__header}
        mih={50}
        px={6}
        py={8}
        gap="sm"
        justify="stretch"
      >
        <Row pl={8} grow={1} gap="xs">
          <ProcessIcon id={processId} revisionId={revisionId} />
          {viewMode === DiagramViewMode.Viewing && (
            <Text className={classes.process__title} ml="sm" lineClamp={2}>
              {title}
            </Text>
          )}
          {viewMode === DiagramViewMode.Editing && (
            <Input
              classNames={{
                input: classes.process__title,
              }}
              c="white"
              bg="transparent"
              defaultValue={isTitleSet ? title : ''}
              placeholder="Name this process..."
              onBlur={(e) => {
                const value = e.currentTarget.value
                if (value === title) return
                updateProcess({
                  id: processId,
                  revisionId,
                  organizationId,
                  title: value,
                })
              }}
              styles={{
                wrapper: {
                  flexGrow: 1,
                  position: 'relative',
                  top: -1,
                },
                input: {
                  background: 'transparent',
                  height: 37,
                  minHeight: 37,
                  padding: '0 0 0 8px',
                  border: 'none',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              maxLength={TITLE_MAX_LENGTH}
            />
          )}
        </Row>
        <Row pl="xs">
          {revisionHistory.length > 0 ? (
            <RevisionDropdown />
          ) : (
            <Pill size="xs" bg={isDarkMode ? 'dark.4' : 'gray.3'}>
              Draft
            </Pill>
          )}
        </Row>
        <ProcessOptionsDropdown />
      </Row>
      {/* Categories */}
      <Row className={classes.process__categories} gap="md" pl={rem(20)}>
        <Box opacity={0.6}>
          <Icon name={documentIcon} />
        </Box>
        <Row mih={32} style={{ overflow: 'hidden' }}>
          <CategoriesInput />
        </Row>
      </Row>
      {/* Archived banner */}
      {isArchived && (
        <Row
          c="white"
          bg={isDarkMode ? 'orange.6' : 'orange.4'}
          justify="space-between"
          w="100%"
          top={0}
          left={0}
          p="sm"
        >
          <Row gap="sm">
            <Icon name="PiWarningCircle" size={18} />
            <Text size={deviceSize.isSmall ? 'sm' : 'md'} fw={700}>
              This process has been archived.
            </Text>
          </Row>
          <Button
            color="white"
            variant="outline"
            size="compact-sm"
            leftSection={<Icon name="PiArrowFatLineUp" />}
            onClick={() => {
              unarchiveProcess({
                id: processId,
                organizationId,
              })
            }}
          >
            Unarchive
          </Button>
        </Row>
      )}
      {/* Content */}
      <Column
        h="100%"
        w="100%"
        pos="relative"
        onScroll={(e) => {
          // This is a hack to prevent forced overflow from dropdown menus
          e.currentTarget.scrollTop = 0
          e.currentTarget.scrollLeft = 0
        }}
        style={{ overflow: 'hidden' }}
      >
        <Diagram key={processId} />
      </Column>
    </Column>
  )
})

const CategoriesInput = () => {
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationMemberId = useOrganizationContext((x) => x.currentMember.id)
  const id = useProcessContext((x) => x.process.id)
  const viewMode = useDiagramContext((x) => x.viewMode)

  return (
    <WithCommand
      model={ModelName.Process}
      command="SetCategories"
      queryKey={{ organizationId, id }}
    >
      {({ valid, reason }) => (
        <ProcessDocumentSelectionInput
          isDropdown={true}
          isEditable={viewMode === DiagramViewMode.Editing && valid}
          wrap={viewMode === DiagramViewMode.Editing && valid}
          nested={{
            input: {
              variant: 'unstyled',
              styles: {
                input: {
                  padding: '4px 0',
                },
              },
            },
          }}
          context={{
            processId: id,
            organizationId,
            organizationMemberId,
          }}
        />
      )}
    </WithCommand>
  )
}

const RevisionDropdown = () => {
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const processSlug = useProcessContext((x) => x.process.slug)
  const mainRevisionId = useProcessContext((x) => x.process.mainRevisionId)
  const isArchived = useProcessContext((x) => x.process.isArchived)
  const { published, drafts } = useProcessContext((x) => x.versions)

  const queryKey = useRevisionContext((x) => x.queryKey)
  const isDraft = useRevisionContext((x) => x.revision.isDraft)
  const revisionId = useRevisionContext((x) => x.revision.id)

  const access = useProcessAccess()
  const { modifyAsync, isModifying } = useCommand(
    ModelName.Revision,
    'Modify',
    queryKey,
  )

  const selectedItem =
    published.find((x) => x.revision.id === revisionId) ??
    drafts.find((x) => x.revision.id === revisionId)

  const parentRevisionId = selectedItem?.isDraft
    ? selectedItem.parent?.revision.id
    : null

  const isOldVersion =
    mainRevisionId !== revisionId && mainRevisionId !== parentRevisionId

  return (
    <Combobox
      store={combobox}
      offset={3}
      width={drafts.length > 0 ? 150 : 135}
      position="bottom-end"
      disabled={isModifying}
      onOptionSubmit={(val) => {
        if (val === 'new') {
          void modifyAsync({}).then(({ revision }) => {
            navigate(
              RouteMap.Process(organizationSlug, processSlug, revision.id),
            )
          })
        } else {
          navigate(RouteMap.Process(organizationSlug, processSlug, val))
        }
        combobox.closeDropdown()
      }}
    >
      {/* Top */}
      <Combobox.Target>
        <Button
          variant="subtle"
          color="gray"
          pr={2}
          styles={{
            root: {
              color: isDarkMode ? 'white' : 'black',
              padding: 0,
              paddingRight: 12,
              paddingLeft: 6,
              height: 30,
            },
          }}
          rightSection={
            <Combobox.Chevron color={isDarkMode ? 'white' : 'black'} />
          }
          onClick={() => combobox.toggleDropdown()}
        >
          <Row>
            {selectedItem ? (
              <Row>
                {isOldVersion && (
                  <Tooltip label="This version is out of date">
                    <Box c="yellow.4" mr={2}>
                      <Icon size="1.2rem" name="Warning" nudgeUp={1} />
                    </Box>
                  </Tooltip>
                )}
                {selectedItem.isDraft ? (
                  <Row gap="sm">
                    <Text fz={deviceSize.isSmall ? 'sm' : 'md'}>
                      {selectedItem.displayName}
                    </Text>
                    {selectedItem.parent && (
                      <Pill size="xs" bg={isDarkMode ? 'dark.4' : 'gray.3'}>
                        {deviceSize.isSmall
                          ? `v${selectedItem.parent.version}`
                          : selectedItem.parent.displayName}
                      </Pill>
                    )}
                  </Row>
                ) : (
                  <Text fz={deviceSize.isSmall ? 'sm' : 'md'}>
                    {selectedItem.displayName}
                  </Text>
                )}
              </Row>
            ) : (
              <Input.Placeholder>Select...</Input.Placeholder>
            )}
          </Row>
        </Button>
      </Combobox.Target>

      {/* Dropdown */}
      <Combobox.Dropdown
        onMouseLeave={() => combobox.resetSelectedOption()}
        p={0}
      >
        <ScrollArea.Autosize
          type="auto"
          mah="60vh"
          scrollbarSize="0.5rem"
          scrollbars="y"
        >
          <Combobox.Options>
            {/* Versions */}
            {published.map((item) => {
              const active = revisionId === item.revision.id
              return (
                <Combobox.Option
                  key={item.revision.id}
                  value={String(item.version)}
                  active={active}
                >
                  <Row justify="space-between">
                    <Column gap={2}>
                      <Text>{item.displayName}</Text>
                      <Text size="xs" opacity={0.7}>
                        {dayjs(item.publication.publishedAt).format(
                          'MMM D, YYYY',
                        )}
                      </Text>
                    </Column>
                    {active && (
                      <Icon name="MdCheckCircle" size={18} c="green.5" />
                    )}
                  </Row>
                </Combobox.Option>
              )
            })}
            {/* Drafts */}
            {drafts.length > 0 && (
              <>
                <Divider my={6} />
                {drafts.map((item) => {
                  const active = revisionId === item.revision.id
                  return (
                    <Combobox.Option
                      key={item.revision.id}
                      value={item.revision.id}
                      active={active}
                    >
                      <Row justify="space-between">
                        <Column gap={2}>
                          <Row align="center" gap={6}>
                            <Text>Draft</Text>
                            {item.parent && (
                              <Pill
                                size="xs"
                                bg={isDarkMode ? 'dark.4' : 'gray.3'}
                              >
                                {item.parent.displayName}
                              </Pill>
                            )}
                          </Row>
                          <Text size="xs" opacity={0.7}>
                            Edited {dayjs(item.updatedAt).format('MMM D, YYYY')}
                          </Text>
                        </Column>
                        {active && (
                          <Icon name="MdCheckCircle" size={18} c="green.5" />
                        )}
                      </Row>
                    </Combobox.Option>
                  )
                })}
              </>
            )}
            {/* Create new */}
            {access.edit && !isArchived && !isDraft && (
              <>
                <Divider my={6} />
                <Combobox.Option value="new">
                  <Row gap={8}>
                    <Icon size={14} name="PiPencilSimpleLine" />
                    <Text>Modify</Text>
                  </Row>
                </Combobox.Option>
              </>
            )}
          </Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  )
}

const ProcessOptionsDropdown = () => {
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const combobox = useCombobox()
  const navigate = useNavigate()
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const processId = useProcessContext((x) => x.id)
  const processSlug = useProcessContext((x) => x.process.slug)
  const organizationId = useProcessContext((x) => x.process.organizationId)
  const isArchived = useProcessContext((x) => x.process.isArchived)
  const viewMode = useDiagramContext((x) => x.viewMode)
  const setViewMode = useDiagramContext((x) => x.setViewMode)
  const access = useProcessAccess()
  const isDraft = useRevisionContext((x) => x.revision.isDraft)
  const revisionId = useRevisionContext((x) => x.id)
  const canEditRevision = useCanEditRevision()
  const { archiveProcess, isArchivingProcess } = useArchiveProcess()
  const { unarchiveProcess, isUnarchivingProcess } = useUnarchiveProcess()
  const { deleteProcess, isDeletingProcess } = useDeleteProcess()
  const { deleteRevision, isDeletingRevision } = useDeleteRevision()

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: (
        <Title component="div" order={3}>
          Delete process?
        </Title>
      ),
      children: (
        <Column pb="lg" gap={2}>
          <Text>This process and all revisions will be deleted.</Text>
          <Text>This action cannot be undone.</Text>
        </Column>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onCancel: () => {},
      onConfirm: () => deleteProcess({ id: processId, organizationId }),
    })

  return (
    <DropdownMenu
      store={combobox}
      width={160}
      position="bottom-end"
      keepMounted={true}
      target={
        <ActionIcon
          onClick={(e) => combobox.toggleDropdown()}
          radius="xl"
          size={36}
          color={isDarkMode ? 'dark.7' : 'white.0'}
        >
          <Icon name={globalIcons.MenuDots} size={24} />
        </ActionIcon>
      }
    >
      {!isArchived && (
        <>
          {/* Publish */}
          {isDraft && (
            <Combobox.Option value="publish" p={2} mb="xs">
              <ButtonWithCommand
                h={30}
                model={ModelName.Revision}
                command="Publish"
                fullWidth={true}
                queryKey={{
                  id: revisionId,
                  processId,
                  organizationId,
                }}
                onClick={({ publishAsync }) => {
                  void publishAsync({
                    // TODO: Allow publish message
                    message: '',
                  }).then(() => {
                    navigate(
                      RouteMap.Process(organizationSlug, processSlug, 'latest'),
                      { replace: true },
                    )
                  })
                }}
              >
                Publish
              </ButtonWithCommand>
            </Combobox.Option>
          )}
          {/* View mode */}
          {canEditRevision && (
            <>
              {!deviceSize.isXS && (
                <Portal target={PortalTargetId.OrgHeaderRight}>
                  <Row w={100}>
                    <ViewModeToggle
                      viewMode={viewMode}
                      onChange={setViewMode}
                    />
                  </Row>
                </Portal>
              )}
              {deviceSize.isXS && (
                <Row mb="xs">
                  <ViewModeToggle viewMode={viewMode} onChange={setViewMode} />
                </Row>
              )}
            </>
          )}
        </>
      )}
      {!isDraft && !isArchived && (
        <>
          <DropdownDivider />
          {/* Archive */}
          <DropdownOption
            icon={globalIcons.Archive}
            disabled={isArchivingProcess}
            onClick={() => {
              archiveProcess({ id: processId, organizationId })
            }}
          >
            Archive process
          </DropdownOption>
        </>
      )}
      {!isDraft && access.edit && (
        <>
          {isArchived && (
            <>
              {/* Unarchive */}
              <DropdownOption
                icon={globalIcons.Unarchive}
                disabled={isUnarchivingProcess}
                onClick={() => {
                  unarchiveProcess({ id: processId, organizationId })
                }}
              >
                Unarchive
              </DropdownOption>
              <DropdownDivider />
              {/* Delete */}
              <DropdownOption
                icon={globalIcons.Delete}
                type="danger"
                disabled={isDeletingProcess}
                onClick={() => {
                  openDeleteModal()
                }}
              >
                Delete
              </DropdownOption>
            </>
          )}
        </>
      )}
      {isDraft && (
        <>
          <DropdownDivider />
          <DropdownOption
            icon={globalIcons.Delete}
            value="delete"
            type="danger"
            disabled={isDeletingRevision}
            onClick={() => {
              deleteRevision({
                id: processId,
                revisionId,
                organizationId,
              })
            }}
          >
            <Row gap={8}>
              <Text>Delete draft</Text>
            </Row>
          </DropdownOption>
        </>
      )}
    </DropdownMenu>
  )
}
