'use client'

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { modals } from '@mantine/modals'
import { MemberStatus } from '@prisma/client'
import { z } from 'zod'
import { Column, Row } from '~/client/shared/Layout'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { MemberStatusIndicator } from '~/client/dashboard/components/global/Prefabs'
import {
  EditFormProps,
  FormButton,
  FormModal,
  FormProps,
  ModalFormProps,
} from '~/client/dashboard/forms/form-helpers'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { WithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { ClientModel } from '~/schemas'
import {
  DESCRIPTION_MAX_LENGTH,
  OrganizationMemberQueryKey,
  OrganizationMemberSchema,
  TITLE_MAX_LENGTH,
} from '~/schemas/organization-member-schema'
import { USERNAME_MAX_LENGTH } from '~/schemas/user-schema'

const OrganizationMemberFormSchema = z.object({
  alias: OrganizationMemberSchema.shape.alias,
  imageUrl: OrganizationMemberSchema.shape.imageUrl,
  title: OrganizationMemberSchema.shape.title,
  description: OrganizationMemberSchema.shape.description,
  isDisplayLocked: OrganizationMemberSchema.shape.isDisplayLocked,
})

type Settings = {
  placeholders?: Partial<z.infer<typeof OrganizationMemberFormSchema>>
  member: ClientModel['OrganizationMember']
  showStatusControls?: boolean
}
type OrganizationMemberFormProps = FormProps<
  typeof OrganizationMemberFormSchema,
  Settings
>

export const OrganizationMemberProfileForm = ({
  form,
  settings,
}: OrganizationMemberFormProps) => {
  const { initialValues, onSubmit, isUpdating, buttonText = 'Save' } = form
  const { placeholders = {}, showStatusControls = false, member } = settings
  const memberProfileForm = useForm({
    validate: zodResolver(OrganizationMemberFormSchema),
    initialValues: {
      ...initialValues,
      isUserManaged: !initialValues.isDisplayLocked,
    },
    transformValues: (values) => ({
      ...values,
      isDisplayLocked: !values.isUserManaged,
    }),
  })
  const { values } = memberProfileForm

  return (
    <form
      onSubmit={memberProfileForm.onSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      <WithCommand
        model={ModelName.OrganizationMember}
        command="UpdateProfile"
        queryKey={member}
        notAllowed="hide"
        validate={{
          isDisplayLocked: !values.isDisplayLocked,
        }}
      >
        <Checkbox
          label="User manages profile"
          {...memberProfileForm.getInputProps('isUserManaged', {
            type: 'checkbox',
          })}
        />
      </WithCommand>

      <WithCommand
        model={ModelName.OrganizationMember}
        command="UpdateProfile"
        queryKey={member}
        validate={{
          isDisplayLocked: !values.isUserManaged,
        }}
      >
        {({ valid, validationCtx }) => {
          const fieldsDisabled =
            (!validationCtx?.target.isSender && values.isUserManaged) || !valid

          return (
            <>
              <TextInput
                label="Display Name"
                maxLength={USERNAME_MAX_LENGTH}
                placeholder={placeholders.alias ?? ''}
                disabled={fieldsDisabled}
                {...memberProfileForm.getInputProps('alias')}
              />
              <TextInput
                leftSection={
                  <Avatar
                    size={30}
                    ml={rem(12)}
                    mr={rem(12)}
                    src={
                      memberProfileForm.values.imageUrl || placeholders.imageUrl
                    }
                  />
                }
                style={{ flexGrow: 1 }}
                leftSectionWidth={44}
                label="Image"
                placeholder="https://..."
                onFocus={(e) => e.currentTarget.select()}
                disabled={fieldsDisabled}
                {...memberProfileForm.getInputProps('imageUrl')}
              />
              <TextInput
                label="Title"
                maxLength={TITLE_MAX_LENGTH}
                disabled={fieldsDisabled}
                {...memberProfileForm.getInputProps('title')}
              />
              <Textarea
                label="Description"
                disabled={fieldsDisabled}
                maxLength={DESCRIPTION_MAX_LENGTH}
                {...memberProfileForm.getInputProps('description')}
              />
              {showStatusControls && (
                <Row w="100%" justify="space-between" align="flex-end">
                  <Box>
                    <OrganizationMemberStatusToggle member={member} />
                  </Box>
                  <FormButton mt="sm" loading={isUpdating} disabled={!valid}>
                    {buttonText}
                  </FormButton>
                </Row>
              )}
              {!showStatusControls && valid && (
                <FormButton
                  align={form.buttonAlign}
                  mt="sm"
                  loading={isUpdating}
                >
                  {buttonText}
                </FormButton>
              )}
            </>
          )
        }}
      </WithCommand>
    </form>
  )
}

/**
 * Edit Member
 */

type EditMemberFormProps = EditFormProps<
  OrganizationMemberFormProps,
  OrganizationMemberQueryKey
>

export const EditMemberForm = ({
  queryKey,
  settings = {},
  onSubmit,
  buttonAlign,
}: EditMemberFormProps) => {
  const { organizationMember } = useOrganizationMemberQuery(queryKey, {
    raw: true,
  })
  const { updateOrganizationMemberProfile, isPending: isUpdating } = useCommand(
    ModelName.OrganizationMember,
    'UpdateProfile',
    queryKey,
  )

  if (!organizationMember) return null

  return (
    <OrganizationMemberProfileForm
      settings={{
        ...settings,
        member: organizationMember,
      }}
      form={{
        initialValues: {
          alias: organizationMember.alias ?? '',
          imageUrl: organizationMember.imageUrl ?? '',
          title: organizationMember.title ?? '',
          description: organizationMember.description ?? '',
          isDisplayLocked: organizationMember.isDisplayLocked,
        },
        buttonAlign,
        isUpdating,
        onSubmit(values) {
          updateOrganizationMemberProfile({
            alias: values.alias ?? undefined,
            imageUrl: values.imageUrl ?? '',
            title: values.title ?? '',
            description: values.description ?? '',
            isDisplayLocked: values.isDisplayLocked,
          })
          onSubmit?.()
        },
      }}
    />
  )
}

export const EditOrganizationMemberModal = ({
  queryKey,
  settings,
  ...props
}: ModalFormProps<EditMemberFormProps>) => {
  return (
    <FormModal title="Update Member" {...props}>
      <EditMemberForm
        onSubmit={props.onClose}
        queryKey={queryKey}
        settings={settings}
        buttonAlign="flex-end"
      />
    </FormModal>
  )
}

/**
 * Custom Fields
 */

export const OrganizationMemberStatusToggle = ({
  member,
}: {
  member: ClientModel['OrganizationMember']
}) => {
  const { setAsOrganizationOwner } = useCommand(
    ModelName.OrganizationMember,
    'SetAsOwner',
    member,
  )
  const { status, organizationId, id } = member

  const openOwnerConfirmModal = () =>
    modals.openConfirmModal({
      title: (
        <Title component="div" order={3}>
          Change Ownership
        </Title>
      ),
      children: (
        <Column pb="lg">
          <Text>
            Are you sure you would like to make {member.alias} the organization
            owner?
          </Text>
        </Column>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onCancel: () => {},
      onConfirm: () => setAsOrganizationOwner({}),
    })

  if (status === MemberStatus.Active || status === MemberStatus.Inactive) {
    return (
      <Column gap="sm">
        <WithCommand
          model={ModelName.OrganizationMember}
          notAllowed="hide"
          command="SetAsOwner"
          queryKey={member}
        >
          {({ authorizationCtx }) =>
            authorizationCtx?.target.isOwner ? null : (
              <Button
                size="compact-sm"
                variant="subtle"
                onClick={openOwnerConfirmModal}
                leftSection={<Icon name="PiSealDuotone" c="green" size={14} />}
              >
                Set as organization owner
              </Button>
            )
          }
        </WithCommand>
        <Row gap="md">
          <MemberStatusIndicator status={status} />
          <WithCommand
            model={ModelName.OrganizationMember}
            command="SetStatus"
            notAllowed="disable"
            queryKey={member}
          >
            {({ setOrganizationMemberStatus }) => (
              <Button
                size="compact-sm"
                variant="light"
                color={status === MemberStatus.Inactive ? 'green' : 'red'}
                onClick={() => {
                  setOrganizationMemberStatus({
                    status:
                      status === MemberStatus.Inactive
                        ? MemberStatus.Active
                        : MemberStatus.Inactive,
                  })
                }}
              >
                {status === MemberStatus.Inactive
                  ? 'Activate user'
                  : 'Deactivate user'}
              </Button>
            )}
          </WithCommand>
        </Row>
      </Column>
    )
  }

  if (status === MemberStatus.Exited) {
    return (
      <Column gap="sm">
        <MemberStatusIndicator status={status} />
        <Row gap="sm">
          <Icon name="PiSignOutBold" size={14} />
          <Text size="sm">User left the organization</Text>
        </Row>
      </Column>
    )
  }
}
