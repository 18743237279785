import { useMediaQuery, useOs, OS } from '@mantine/hooks'
import { ReactNode, memo, useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'
import { isUndefined } from '~/utils/logic'

let isSizeLoaded = false

export type DeviceType = {
  isMobile: boolean
  os: OS
}

export const _useDeviceType = (): DeviceType => {
  const os = useOs()
  const isMobile = ['android', 'ios'].includes(os)

  return { isMobile, os }
}

export const useDeviceSizeMatches = (
  {
    min,
    max,
  }: {
    min?: number
    max?: number
  },
  units: 'px' | 'em' = 'px',
) => useMediaQuery(`(${min ?? 0}${units} < width < ${max ?? 10000}${units})`)

// XS and Small may both be true, or XL and Large.
//  These breakpoints should be identical to our postcss variables.
export const _useDeviceSize = (): DeviceSize => {
  const isXS =
    useMediaQuery(`(max-width: 36rem)`, undefined, {
      getInitialValueInEffect: !isSizeLoaded,
    }) ?? false
  const isSmall =
    (useMediaQuery(`(max-width: 54rem)`, undefined, {
      getInitialValueInEffect: !isSizeLoaded,
    }) ||
      isXS) ??
    false
  const isMedium = useMediaQuery(`(max-width: 70rem)`) ? !isSmall : false
  const isLarge = !isMedium && !isSmall
  const isXL = useMediaQuery(`(min-width: 100rem)`) ? !isSmall : false

  let size: DeviceSizeId
  if (isXS) {
    size = 'xs'
  } else if (isSmall) {
    size = 'sm'
  } else if (isMedium) {
    size = 'md'
  } else if (isLarge) {
    size = 'lg'
  } else {
    size = 'xl'
  }

  isSizeLoaded = isSmall !== undefined

  const result = useMemo(
    () => ({ size, isXS, isSmall, isMedium, isLarge, isXL, isSizeLoaded }),
    [isXS, isSmall, isMedium, isLarge, isXL, isSizeLoaded],
  )

  return result
}

type DeviceSizeId = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type DeviceSizeProps = {
  exact?: boolean
} & {
  [Size in DeviceSizeId]?: ReactNode | null
}

export type DeviceSize = {
  size: DeviceSizeId
  isXS: boolean
  isSmall: boolean
  isMedium: boolean
  isLarge: boolean
  isXL: boolean
  isSizeLoaded: boolean
}

export const DeviceSize = memo(
  (props: DeviceSizeProps) => {
    const { exact = false, ...sizes } = { ...props }
    const { size, isSizeLoaded } = useGlobalContext((x) => x.deviceSize)

    if (!exact) {
      if (isUndefined(sizes.sm)) sizes.sm = sizes.xs
      if (isUndefined(sizes.md)) sizes.md = sizes.sm ?? null
      if (isUndefined(sizes.lg)) sizes.lg = sizes.md ?? null
      if (isUndefined(sizes.xl)) sizes.xl = sizes.lg ?? null
    }

    if (isSizeLoaded) {
      return sizes[size]
    } else {
      return null
    }
  },
)
