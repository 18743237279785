import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  QueryOptions,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { NodeDetailQueryKey } from '~/schemas/node-schema'
import { api } from '~/utils/api'
import { cacheNodeDetails } from '~/client/dashboard/queries/revision-queries'

export const useNodeDetailQuery = (
  key: NodeDetailQueryKey,
  options: QueryOptions = {},
) => {
  const enabled = NodeDetailQueryKey.safeParse(key).success
  const result = api.NodeDetail.get.useQuery(
    parseKey(key, NodeDetailQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )
  const nodeDetail = result?.data ?? null

  useCacheResult(result, cacheNodeDetail, () => key, false)

  return {
    ...result,
    nodeDetail: nodeDetail,
    isLoadingNodeDetail: Boolean(result.isPending && enabled),
    isLoadingNodeDetailError: Boolean(result?.isError),
  }
}

/**
 * Cache get
 */

export const getNodeDetailFromCache = (
  ctx: ApiContext,
  key: NodeDetailQueryKey,
) => {
  key = NodeDetailQueryKey.parse(key)
  return ctx.NodeDetail.get.getData(key)
}

/**
 * Cache set
 */

export const cacheNodeDetail: CacheFn<
  ModelQueryKey['NodeDetail'],
  ClientModel['NodeDetail']
> = (ctx, data, key, itemOnly = false) => {
  key = NodeDetailQueryKey.parse(key)
  const previous = getNodeDetailFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.NodeDetail.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(
      ctx.NodeDetail.get,
      [ModelQueryKey.NodeDetail],
      result,
      result,
    )

    if (!itemOnly) {
      cacheNodeDetails(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (x.id === result.id ? result : x))
          }
        },
        {
          id: result.revisionId,
          processId: result.processId,
          organizationId: result.organizationId,
        },
        true,
      )
    }
  }

  return {
    previous,
    revert: () => {
      cacheNodeDetail(ctx, previous, key, itemOnly)
    },
  }
}
