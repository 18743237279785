import { cacheBootstrapOrganization } from '~/client/dashboard/queries/organization-queries'
import {
  cacheUser,
  useUserQuery,
} from '~/client/dashboard/queries/user-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'

/**
 * User client commands
 */

export const userCommandContext = {
  UpdateProfile() {},
  SetActiveOrganization() {},
  CreateOrganization() {},
  Delete() {},
} satisfies ClientCommandContextDefinitions<ModelName.User>

export const userCommandsClient = {
  UpdateProfile: {
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {},
        target: { isSender: sender.userProfile.id === target?.id },
        meta: {},
      }
    },
    getValidationContext({ sender, target }) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    onMutate: [
      (ctx, { params, key }) =>
        cacheUser(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          false,
        ),
    ],
  },
  SetActiveOrganization: {
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {},
        target: { isSender: sender.userProfile.id === target?.id },
        meta: {},
      }
    },
    getValidationContext({ sender, target }) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    onMutate: [
      (ctx, { params, key }) =>
        cacheUser(
          ctx,
          (old) =>
            old
              ? { ...old, activeOrganizationId: params.organizationId }
              : undefined,
          key,
          false,
        ),
    ],
  },
  CreateOrganization: {
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {},
        target: { isSender: sender.userProfile.id === target?.id },
        meta: {},
      }
    },
    getValidationContext({ sender, target }) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    onSuccess: [
      (ctx, result) => cacheBootstrapOrganization(ctx, result, result, false),
    ],
  },
  Delete: {
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {},
        target: { isSender: sender.userProfile.id === target?.id },
        meta: {},
      }
    },
    getValidationContext({ sender, target }) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    onSuccess: [
      (ctx, result) => {
        void logout?.()
        return {
          revert: () => {
            /* This cannot be reverted */
          },
        }
      },
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.User>

/**
 * User client context
 */

export const useUserModelCtx = ((key) => {
  const user = useUserContext((x) => x)
  const { user: target, isLoading, isError } = useUserQuery(key)

  return {
    target,
    sender: {
      userProfile: user,
    },
    ctx: {},
    state: {
      isLoading,
      failed: isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.User>
