import { Avatar, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'
import {
  EditFormProps,
  FormButton,
  FormProps,
} from '~/client/dashboard/forms/form-helpers'
import { useUserQuery } from '~/client/dashboard/queries/user-queries'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import {
  USERNAME_MAX_LENGTH,
  UserQueryKey,
  UserSchema,
} from '~/schemas/user-schema'

const UserFormSchema = z.object({
  username: UserSchema.shape.username,
  imageUrl: UserSchema.shape.imageUrl,
})

type Settings = {}
type UserFormProps = FormProps<typeof UserFormSchema, Settings>

export const UserProfileForm = ({ form, settings = {} }: UserFormProps) => {
  const { initialValues, onSubmit, isUpdating, buttonText = 'Save' } = form
  const userProfileForm = useForm({
    validate: zodResolver(UserFormSchema),
    initialValues,
  })

  return (
    <form
      onSubmit={userProfileForm.onSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      <TextInput
        label="Username"
        placeholder="John Doe"
        maxLength={USERNAME_MAX_LENGTH}
        {...userProfileForm.getInputProps('username')}
      />
      <TextInput
        leftSection={
          <Avatar
            size={30}
            ml="md"
            mr="md"
            src={userProfileForm.values.imageUrl}
          />
        }
        style={{ flexGrow: 1 }}
        leftSectionWidth={44}
        label="Image"
        placeholder="https://..."
        onFocus={(e) => e.currentTarget.select()}
        {...userProfileForm.getInputProps('imageUrl')}
      />
      <FormButton mt="sm" loading={isUpdating}>
        {buttonText}
      </FormButton>
    </form>
  )
}

type EditUserProfileFormProps = EditFormProps<UserFormProps, UserQueryKey>

export const EditUserForm = ({
  queryKey,
  settings = {},
  onSubmit,
}: EditUserProfileFormProps) => {
  const { user } = useUserQuery(queryKey)
  const { updateUserProfile, isUpdatingUserProfile } = useCommand(
    ModelName.User,
    'UpdateProfile',
  )

  if (!user) return null

  return (
    <UserProfileForm
      settings={settings}
      form={{
        initialValues: {
          username: user.username ?? '',
          imageUrl: user.imageUrl ?? '',
        },
        isUpdating: isUpdatingUserProfile,
        onSubmit(values) {
          updateUserProfile({
            key: {
              id: user.id,
            },
            params: {
              username: values.username,
              imageUrl: values.imageUrl ?? '',
            },
          })
          onSubmit?.()
        },
      }}
    />
  )
}
