'use client'

import { AppShell, Box, ScrollArea, Skeleton } from '@mantine/core'
import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'wouter'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import { Column, Row } from '~/client/shared/Layout'
import {
  PortalTarget,
  PortalTargetId,
} from '~/client/dashboard/components/global/Portals'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'
import classes from './DashboardShell.module.css'

export const NAVBAR_WIDTH = 260

export const DashboardShell = ({ children }: PropsWithChildren) => {
  const deviceSize = useGlobalContext(x => x.deviceSize)
  const isDarkMode = useGlobalContext(x => x.isDarkMode)
  const openedMedium = useGlobalContext((x) => x.isNavOpenMedium)
  const openedSmall = useGlobalContext((x) => x.isNavOpenSmall)
  const setNavOpenSmall = useGlobalContext((x) => x.setNavOpenSmall)
  const isArrowKeyNav = useGlobalContext((x) => x.isArrowKeyNav)
  const setIsArrowKeyNav = useGlobalContext((x) => x.setIsArrowKeyNav)
  const scrollMain = useGlobalContext((x) => x.scrollMain)
  const [location] = useLocation()

  useEffect(() => {
    const mouseListener = () => {
      setIsArrowKeyNav(false)
    }
    const keyListener = () => {
      setIsArrowKeyNav(true)
    }

    window.addEventListener('keyup', keyListener)
    window.addEventListener('keydown', keyListener)
    window.addEventListener('click', mouseListener)

    return () => {
      window.removeEventListener('keyup', keyListener)
      window.removeEventListener('keydown', keyListener)
      window.removeEventListener('click', mouseListener)
    }
  }, [])

  useEffect(() => {
    if (!openedSmall) return
    const listener = () => {
      setNavOpenSmall(false)
    }
    document.querySelector('main')?.addEventListener('click', listener)
    return () => {
      document.querySelector('main')?.removeEventListener('click', listener)
    }
  }, [openedSmall])

  useEffect(() => {
    document.body.toggleAttribute('data-key-nav', isArrowKeyNav)
  }, [isArrowKeyNav])

  useEffect(() => {
    if (deviceSize.isSmall) setNavOpenSmall(false)
  }, [location, deviceSize])

  const headerHeight = 56
  const bodyPadding = !scrollMain ? 0 : !deviceSize.isLarge ? 'lg' : 'xl'
  const bodyPaddingTop = !scrollMain
    ? 0
    : deviceSize.isSmall
      ? 'md'
      : deviceSize.isMedium
        ? 'lg'
        : 'xl'

  return (
    <AppShell
      id="dashboard"
      className={classes.dashboardShell}
      style={{ height: '100%' }}
      header={{ height: headerHeight }}
      layout={deviceSize.isSmall ? 'alt' : 'default'}
      navbar={{
        width: NAVBAR_WIDTH,
        breakpoint: 'md',
        collapsed: {
          mobile: !openedSmall,
          desktop: deviceSize.isMedium ? !openedMedium : false,
        },
      }}
      tabIndex={-1}
    >
      <AppShell.Header
        zIndex={deviceSize.isSmall ? 100 : 102}
        bg={isDarkMode ? 'dark.7' : 'light.0'}
      >
        <PortalTarget
          id={PortalTargetId.Header}
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        />
      </AppShell.Header>
      <AppShell.Navbar
        style={{
          justifyContent: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          ...(deviceSize.isSmall && {
            boxShadow: openedSmall
              ? '0 0 0 1000px rgba(0,0,0,0.2)'
              : '0 0 0 1000px rgba(0,0,0,0)',
            transition: '200ms ease all',
          }),
        }}
        w={NAVBAR_WIDTH}
        bg={isDarkMode ? 'dark.8' : 'light.0'}
      >
        <PortalTarget
          id={PortalTargetId.NavBar}
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        />
      </AppShell.Navbar>
      <AppShell.Main
        className={classes.main}
        data-scroll={scrollMain}
        style={{
          display: scrollMain ? 'flex' : 'block',
        }}
      >
        <ScrollArea
          h="100%"
          w="100%"
          scrollbars="y"
          classNames={{ viewport: classes.scrollViewport }}
        >
          <Box
            px={bodyPadding}
            pb={bodyPadding}
            pt={bodyPaddingTop}
            w="100%"
            h="100%"
            {...(scrollMain && {
              style: {
                display: 'flex',
                justifyContent: 'center',
              },
            })}
          >
            <Row
              w="100%"
              h="100%"
              align="flex-start"
              {...(scrollMain && {
                style: {
                  maxWidth: 1000,
                },
              })}
            >
              <ErrorBoundary id="DashboardShell">
                {children ?? <Skeleton />}
              </ErrorBoundary>
            </Row>
          </Box>
        </ScrollArea>
      </AppShell.Main>
    </AppShell>
  )
}

export const HeaderSkeleton = () => {
  const { isSmall } = useGlobalContext(x => x.deviceSize)

  return (
    <Row w="100%" justify="space-between" h="100%" pr="lg" pl="xs" gap="md">
      <Row gap="xl" justify="stretch" grow={1}>
        <Row gap="sm" pl="sm" grow={0}>
          <Skeleton h={40} w={40} radius="xs" />
          {!isSmall && <Skeleton h={22} w={120} radius="sm" />}
        </Row>
        <Row grow={1}>
          <Skeleton h={36} w="100%" maw={200} radius="xl" />
        </Row>
      </Row>
      <Skeleton h={40} w={40} radius="xl" />
    </Row>
  )
}

export const NavSkeleton = () => {
  return (
    <Column w="100%" p="md" justify="space-between">
      <Column gap="md">
        <Column p="md">
          <Skeleton height={18} mb="sm" radius="sm" w="90%" />
          <Skeleton height={18} mb="sm" radius="sm" w="90%" />
          <Skeleton height={18} mb="sm" radius="sm" w="90%" />
        </Column>
        <Column gap="xl">
          <NavSectionSkeleton />
          <NavSectionSkeleton />
        </Column>
      </Column>
      <Skeleton height={18} mb="sm" radius="sm" w="90%" />
    </Column>
  )
}

export const NavSectionSkeleton = () => {
  return (
    <Column>
      <Skeleton height={22} mb="md" radius="sm" w="90%" />
      <Column p="sm">
        <Skeleton height={14} mb="sm" radius="sm" w="60%" />
        <Skeleton height={14} mb="sm" radius="sm" w="60%" />
        <Skeleton height={14} mb="sm" radius="sm" w="60%" />
      </Column>
    </Column>
  )
}

export const BodySkeleton = () => {
  const deviceSize = useGlobalContext(x => x.deviceSize)
  
  return (
    <Column
      h="100%"
      w="100%"
      align="start"
      p={deviceSize.isLarge ? 'xl' : 'lg'}
    >
      <Skeleton
        h={30}
        maw={400}
        radius="sm"
        mb={deviceSize.isLarge ? 'xl' : 'lg'}
      />
      <Skeleton h={10} maw={200} radius="sm" mb="sm" />
      <Skeleton h={10} maw={200} radius="sm" mb="sm" />
      <Skeleton h={10} maw={200} radius="sm" mb="sm" />
    </Column>
  )
}
