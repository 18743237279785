import { TeamAccess } from '@prisma/client'
import { IconName } from '~/client/dashboard/components/global/Icon.tsx'

export const documentIcons = {
  Owner: 'PiIdentificationBadge',
  Member: 'PiUser',
  Draft: 'PiCircleDashed',
} satisfies {
  [key: string]: IconName
}

export const teamAccess = {
  Open: {
    iconName: 'PiGlobe',
    description: 'Anyone can view and join.',
  },
  Managed: {
    iconName: 'LockUser',
    description: 'Members can invite. Owners can edit.',
  },
} satisfies {
  [Access in TeamAccess]: {
    iconName: IconName
    description: string
  }
}
