import { refine, refinement, transformation } from '~/schemas/schema-helpers'
import { z } from 'zod'
import { replaceNewLine } from '~/utils/logic'
import { InputNodeId } from '~/schemas/node-schema'

/**
 * Constants
 */

export const POSITION_MAX = 80
export const TEXT_MAX_LENGTH = 80

/**
 * Connector schemas
 */

export type ConnectorSchema = z.infer<typeof ConnectorSchema>
export const ConnectorSchema = z.object({
  originId: z.number(),
  targetId: z.number().nullable(),
  position: z
    .number()
    .max(POSITION_MAX, `Connector position exceeds maximum: ${POSITION_MAX}`),
  text: z
    .string()
    .transform(replaceNewLine)
    .pipe(
      z
        .string()
        .max(
          TEXT_MAX_LENGTH,
          `Connector text exceeds maximum: ${TEXT_MAX_LENGTH}`,
        ),
    ),
})
export type Connector = z.infer<typeof ConnectorSchema>

export const InputConnectorSchema = ConnectorSchema.extend({
  originId: InputNodeId,
  targetId: InputNodeId.nullable(),
})
export type InputConnector = z.infer<typeof InputConnectorSchema>
