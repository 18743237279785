import {
  ApiContext,
  CacheFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { cacheTeamMembers } from '~/client/dashboard/queries/team-queries'
import { PartialRequired } from '~/client/globals'
import {
  TeamMemberQueryKey,
  teamMemberQueryKeys,
} from '~/schemas/team-member-schema'
import { api } from '~/utils/api'
import { ClientModel, ModelQueryKey } from '~/schemas'

export const useTeamMemberQuery = (
  key: PartialRequired<TeamMemberQueryKey>,
) => {
  const enabled = TeamMemberQueryKey.safeParse(key).success
  const result = api.TeamMember.get.useQuery(
    parseKey(key, TeamMemberQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )
  const teamMember = result?.data ?? null

  useCacheResult(result, cacheTeamMember, () => key, false)

  return {
    ...result,
    teamMember,
    isLoadingTeamMember: result.isPending && enabled,
    isLoadingTeamMemberError: Boolean(result?.isError),
  }
}

/**
 * Cache get
 */

export const getTeamMemberFromCache = (
  ctx: ApiContext,
  key: TeamMemberQueryKey,
) => {
  key = TeamMemberQueryKey.parse(key)
  return ctx.TeamMember.get.getData(key)
}

/**
 * Cache set
 */

export const cacheTeamMember: CacheFn<
  ModelQueryKey['TeamMember'],
  ClientModel['TeamMember'],
  boolean
> = (ctx, data, key, itemOnly = false) => {
  key = TeamMemberQueryKey.parse(key)
  const previous = getTeamMemberFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.TeamMember.get.setData(key, null)
  }

  if (result) {
    teamMemberQueryKeys.forEach((x) => {
      try {
        ctx.TeamMember.get.setData(x.parse(result), result)
      } catch {}
    })
    if (!itemOnly) {
      cacheTeamMembers(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (x.id === result.id ? result : x))
          }
        },
        { id: key.teamId, organizationId: key.organizationId },
        true,
      )
    }
  }
  return {
    previous,
    revert: () => {
      cacheTeamMember(ctx, previous, key, itemOnly)
    },
  }
}

/**
 * Cache remove
 */
