import { z } from 'zod'
import { GlobalRole, User } from '@prisma/client'
import { replaceNewLine } from '~/utils/logic'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

/**
 * Constants
 */

export const USERNAME_MAX_LENGTH = 50
export const TITLE_MAX_LENGTH = 60
export const DESCRIPTION_MAX_LENGTH = 200
export const IMAGE_URL_MAX_LENGTH = 1000

/**
 * Fields
 */

export const usernameSchema = z
  .string()
  .trim()
  .min(1, 'Username is required')
  .transform(replaceNewLine)
  .pipe(
    z
      .string()
      .max(
        USERNAME_MAX_LENGTH,
        `Must be less than ${USERNAME_MAX_LENGTH} characters`,
      ),
  )

export const imageUrlSchema = z.union([
  z
    .string()
    .trim()
    .url('Must be a URL')
    .max(
      IMAGE_URL_MAX_LENGTH,
      `Must be less than ${IMAGE_URL_MAX_LENGTH} characters`,
    ),
  z
    .string()
    .length(0)
    .transform((x) => null),
])

/**
 * User schemas
 */

export type UserSchema = z.infer<typeof UserSchema>
export const UserSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  externalAuthId: z.string(),
  username: usernameSchema,
  imageUrl: imageUrlSchema.nullable(),
  lastActiveAt: z.date(),
  activeOrganizationId: z.string().nullable(),
  role: z.nativeEnum(GlobalRole),
  handle: z.string().nullable(),
  // isArchived: z.boolean().default(false),
}) satisfies PrismaModelSchema<'User'>

export const CurrentUserQueryKey = z.object({})
export type CurrentUserQueryKey = z.infer<typeof CurrentUserQueryKey>
export const currentUserQueryKeys = [CurrentUserQueryKey]

const UserQueryKeys = z.tuple([
  z.object({
    id: z.string(),
  }),
  z.object({
    handle: z.string(),
  }),
])
export const userQueryKeys = UserQueryKeys.items

export const UserQueryKey = z.union(UserQueryKeys.items)
export type UserQueryKey = z.infer<typeof UserQueryKey>

export type ClientUserSchema = z.infer<typeof ClientUserSchema>
export const ClientUserSchema = UserSchema.pick({
  id: true,
  role: true,
  username: true,
  handle: true,
  imageUrl: true,
  createdAt: true,
  activeOrganizationId: true,
  lastActiveAt: true,
})

/**
 * Helpers
 */

export const toClientUser = (user: UserSchema): ClientUserSchema => {
  return {
    id: user.id,
    username: user.username,
    handle: user.handle,
    imageUrl: user.imageUrl,
    createdAt: user.createdAt,
    role: user.role,
    activeOrganizationId: user.activeOrganizationId,
    lastActiveAt: user.lastActiveAt,
  }
}
