import { Skeleton } from '@mantine/core'
import { ReactNode } from 'react'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import { Column, Row } from '~/client/shared/Layout'
import {
  BodySkeleton,
  HeaderSkeleton,
  NavSkeleton,
} from '~/client/dashboard/components/global/DashboardShell'
import {
  Portal,
  PortalTargetId,
} from '~/client/dashboard/components/global/Portals'
import { ActiveOrganizationDropdown } from '~/client/dashboard/components/organization/OrganizationDropdown'
import { UserDropdown } from '~/client/dashboard/components/user/UserDropdown'

export const UserShell = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <ErrorBoundary id="UserShell">{children}</ErrorBoundary>
    </>
  )
}

export const UserSkeleton = () => {
  return (
    <>
      <Portal target={PortalTargetId.NavBar}>
        <NavSkeleton />
      </Portal>
      <Portal target={PortalTargetId.Header}>
        <HeaderSkeleton />
      </Portal>
      <BodySkeleton />
    </>
  )
}

const Header = () => {
  return (
    <Row w="100%" justify="space-between" h="100%">
      {/* Left */}
      <Row style={{ flexGrow: 1 }}>
        <ActiveOrganizationDropdown />
      </Row>
      {/* Right */}
      <Row mr="lg" gap="sm">
        <UserDropdown />
      </Row>
    </Row>
  )
}
