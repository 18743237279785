import { TextInput, Textarea } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { OrganizationType } from '@prisma/client'
import { z } from 'zod'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import {
  ImageInput,
  OrganizationLogo,
} from '~/client/dashboard/components/global/Prefabs'
import {
  EditFormProps,
  FormButton,
  FormModal,
  FormProps,
  ModalFormProps,
  NewFormProps,
} from '~/client/dashboard/forms/form-helpers'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import {
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
  OrganizationQueryKey,
  OrganizationSchema,
} from '~/schemas/organization-schema'

import { BootstrapOrganizationProfile } from '~/schemas'

/**
 * Appearance
 */

const OrganizationFormSchema = z.object({
  name: z
    .string()
    .min(1, 'Organization name is required')
    .pipe(OrganizationSchema.shape.name),
  imageUrl: OrganizationSchema.shape.imageUrl,
  description: OrganizationSchema.shape.description,
})

type Settings = {}
type OrganizationFormProps = FormProps<typeof OrganizationFormSchema, Settings>

export const OrganizationAppearanceForm = ({
  form,
  settings,
}: OrganizationFormProps) => {
  const { initialValues, isUpdating, onSubmit, buttonText = 'Save' } = form
  const organizationAppearanceForm = useForm({
    validate: zodResolver(OrganizationFormSchema),
    initialValues,
  })

  return (
    <form
      onSubmit={organizationAppearanceForm.onSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      <TextInput
        label="Organization Name"
        data-autofocus
        maxLength={NAME_MAX_LENGTH}
        {...organizationAppearanceForm.getInputProps('name')}
      />
      <ImageInput
        label="Organization Logo"
        currentImageUrl={organizationAppearanceForm.values.imageUrl}
        avatar={
          <OrganizationLogo
            size={30}
            imageUrl={organizationAppearanceForm.values.imageUrl}
          />
        }
        {...organizationAppearanceForm.getInputProps('imageUrl')}
      />
      <Textarea
        label="Description"
        maxLength={DESCRIPTION_MAX_LENGTH}
        {...organizationAppearanceForm.getInputProps('description')}
      />
      <FormButton mt="sm" loading={isUpdating}>
        {buttonText}
      </FormButton>
    </form>
  )
}

/**
 * New Organization
 */

type NewOrganizationFormProps = NewFormProps<
  OrganizationFormProps,
  {
    userId: string
    onCreate?: (result: BootstrapOrganizationProfile) => void
  }
>

export const NewOrganizationForm = ({
  settings = {},
  userId,
  onSubmit,
  onCreate = () => {},
}: NewOrganizationFormProps) => {
  const { createOrganizationAsync, isCreatingOrganization } = useCommand(
    ModelName.User,
    'CreateOrganization',
    { id: userId },
  )

  return (
    <OrganizationAppearanceForm
      settings={settings}
      form={{
        initialValues: {
          name: '',
          description: '',
          imageUrl: '',
        },
        buttonText: 'Create',
        isUpdating: isCreatingOrganization,
        onSubmit(values) {
          onSubmit?.()
          void createOrganizationAsync({
            name: values.name,
            imageUrl: values.imageUrl,
            description: values.description,
            type: OrganizationType.Team,
          }).then(onCreate)
        },
      }}
    />
  )
}

export const NewOrganizationModal = ({
  settings,
  userId,
  onCreate,
  ...props
}: ModalFormProps<NewOrganizationFormProps>) => {
  return (
    <FormModal {...props} title="New Organization">
      <NewOrganizationForm
        onSubmit={props.onClose}
        onCreate={onCreate}
        settings={settings}
        userId={userId}
      />
    </FormModal>
  )
}

/**
 * Edit Organization
 */

type EditOrganizationFormProps = EditFormProps<
  OrganizationFormProps,
  OrganizationQueryKey
>

export const EditOrganizationForm = ({
  queryKey,
  settings = {},
  onSubmit,
}: EditOrganizationFormProps) => {
  const { organization } = useOrganizationQuery(queryKey)
  const { updateOrganizationProfile, isUpdatingOrganizationProfile } =
    useCommand(ModelName.Organization, 'UpdateProfile', {
      id: organization?.id!,
    })

  if (!organization) return null

  return (
    <OrganizationAppearanceForm
      settings={settings}
      form={{
        initialValues: {
          name: organization.name ?? '',
          imageUrl: organization.imageUrl ?? '',
          description: organization.description ?? '',
        },
        isUpdating: isUpdatingOrganizationProfile,
        onSubmit(values) {
          updateOrganizationProfile({
            name: values.name,
            imageUrl: values.imageUrl ?? '',
            description: values.description,
          })
          onSubmit?.()
        },
      }}
    />
  )
}

export const EditOrganizationModal = ({
  settings,
  queryKey,
  ...props
}: ModalFormProps<EditOrganizationFormProps>) => {
  return (
    <FormModal {...props} title="Category Settings">
      <EditOrganizationForm
        onSubmit={props.onClose}
        settings={settings}
        queryKey={queryKey}
      />
    </FormModal>
  )
}
