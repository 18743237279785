import {
  ModelCommandsValidation,
  ModelName,
  authorized,
  invalid,
  unauthorized,
  valid,
} from '~/commands/base-commands'

/**
 * ProcessMember commands validation
 */

export const processMemberValidation = {
  SetActiveRevision: {
    validate({ sender, target, meta }) {
      if (!target.isSender) {
        return unauthorized('Cannot modify another member')
      }

      return valid()
    },
    authorize({ sender, target }) {
      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.ProcessMember>
