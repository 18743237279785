import { useAuth } from '@clerk/clerk-react'
import { Box, Divider, Title } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Redirect } from 'wouter'
import { RouteMap } from '~/client/RouteMap.ts'
import { UserDropdown } from '~/client/dashboard/components/user/UserDropdown.tsx'
import { useValidateInvitation } from '~/client/dashboard/mutations/invitation-mutations.tsx'
import { BootstrapProvider } from '~/client/dashboard/stores/UserStore.tsx'
import {
  EnsureLoggedInWithEmail,
  InvitationAcceptButton,
  OrganizationCard,
} from '~/client/invite/Invitation.tsx'
import { Column, Row } from '~/client/shared/Layout.tsx'
import { Logo } from '~/client/shared/Logo.tsx'
import { PageShell } from '~/client/shared/PageShell.tsx'
import { InvitationValidationError } from '~/schemas/invitation-schema.ts'
import {
  toClientOrganizationMember,
  toDisplayMember,
} from '~/schemas/organization-member-schema.ts'
import {
  InvitationValidationResult,
  InvitationValidationSuccess,
} from '~/server/methods/invitation-methods.ts'

export default function Invite({ code }: { code: string }) {
  const { isSignedIn, isLoaded } = useAuth()
  const { validateInvitation } = useValidateInvitation()
  const [result, setResult] = useState<InvitationValidationResult>()

  useEffect(() => {
    void validateInvitation({
      code,
    }).then(setResult)
  }, [])

  if (!isLoaded || !result) return null

  if (!isSignedIn) {
    return (
      <PageShell>
        <Content result={result} />
      </PageShell>
    )
  }

  return (
    <BootstrapProvider>
      <PageShell
        header={
          <Row h="100%" justify="flex-end" pr="md">
            <UserDropdown />
          </Row>
        }
      >
        <Content result={result} />
      </PageShell>
    </BootstrapProvider>
  )
}

const Content = ({ result }: { result: InvitationValidationResult }) => {
  return (
    <Column
      w="100%"
      h={800}
      mah="75vh"
      mih={450}
      align="center"
      justify="center"
      p="lg"
    >
      <Column maw={700} mih={340} gap="md" align="center">
        <Logo />
        <Divider w="100%" maw={300} my="lg" />
        <Main result={result} />
      </Column>
    </Column>
  )
}

const Main = ({ result }: { result: InvitationValidationResult }) => {
  if (result.success) {
    return <AcceptanceForm result={result} />
  } else {
    switch (result.validationError) {
      case InvitationValidationError.AlreadyMember:
        return (
          <Redirect
            to={RouteMap.Organization(result.invitation.organization.slug)}
            replace={true}
          />
        )
      case InvitationValidationError.NotFound:
        return <Expired />
      case InvitationValidationError.Expired:
        return <Expired />
      case InvitationValidationError.RedemptionLimitReached:
        return <Expired />
    }
  }
}

const Expired = () => {
  return <Title order={3}>Invitation expired</Title>
}

const AcceptanceForm = ({
  result,
}: {
  result: InvitationValidationSuccess
}) => {
  const { invitation } = result
  const creator = toDisplayMember(
    toClientOrganizationMember(invitation.creator),
  )
  const organization = invitation.organization

  return (
    <>
      {invitation.memberAlias && (
        <Title order={3} ta="center">
          Welcome, {invitation.memberAlias}!
        </Title>
      )}
      <Title order={4} mb="lg" ta="center">
        {creator.alias} has invited you to join {invitation.organization.name}:
      </Title>
      <OrganizationCard organization={organization} />
      <Box mt="lg">
        <EnsureLoggedInWithEmail email={invitation.emailToMatch}>
          <Box>
            <InvitationAcceptButton code={invitation.code} />
          </Box>
        </EnsureLoggedInWithEmail>
      </Box>
    </>
  )
}
