'use client'

import React from 'react'
import { AppShell } from '@mantine/core'
import { Column, Row } from '~/client/shared/Layout'
import {
  DashboardButton,
  HomeButton,
  LoginButton,
  SignupButton,
} from '~/client/marketing/LoginButtons'
import { DarkModeToggle } from '~/client/marketing/DarkModeToggle'
import { PageShell } from '~/client/shared/PageShell'

export const MarketingShell = ({ children }: { children: React.ReactNode }) => {
  return (
    <PageShell
      header={
        <Row justify="center" w="100%" h="100%">
          <Row justify="space-between" w="100%" h="100%" maw={1200}>
            <HomeButton />
            <Row gap={10}>
              <DarkModeToggle />
              <DashboardButton />
              <LoginButton />
              <SignupButton />
            </Row>
          </Row>
        </Row>
      }
    >
      <Row justify="center" align="flex-start" w="100%" h="100%">
        <Column w="100%" p={20} maw={1200}>
          {children}
        </Column>
      </Row>
    </PageShell>
  )
}
