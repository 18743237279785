import { ActionIcon, Center, Menu } from '@mantine/core'
import { mergeRefs, useEventListener } from '@mantine/hooks'
import {
  Handle,
  useReactFlow,
  useStore as useReactFlowStore,
} from '@xyflow/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { ProcessSelectionMenu } from '~/client/dashboard/components/process/ProcessComponents'
import { DiagramNodeWrapper } from '~/client/dashboard/components/process/diagram/DiagramNodeWrapper'
import {
  DRAG_DISTANCE_START,
  DiagramBasisConnector,
  DiagramNodeProps,
  MAX_ZOOM,
  MIN_ZOOM,
  movingNodePosition,
  DiagramDirection,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import { ChooseNodeInsertMenu } from '~/client/dashboard/components/process/node/NodeComponents'
import {
  useDiagramContext,
  useDiagramStore,
} from '~/client/dashboard/stores/DiagramStore'
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import {
  useProcessAction,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore'
import { Box } from '~/client/shared/Layout'
import { useAsRef } from '~/client/shared/hooks/useAsRef'
import { NodeType } from '~/schemas/node-schema'
import classes from './Diagram.module.css'
import { dataTagSymbol } from '@tanstack/react-query'
import { useDarkMode } from '~/client/dashboard/stores/GlobalStore'
import { useThemeColor } from '~/client/dashboard/components/global/colors'

export type AddNode = (
  type: NodeType.Step | NodeType.Decision | NodeType.End | NodeType.Switch,
) => void

const doElementsOverlap = (el1: HTMLElement, el2: HTMLElement) => {
  const domRect1 = el1.getBoundingClientRect()
  const domRect2 = el2.getBoundingClientRect()

  return !(
    domRect1.top > domRect2.bottom ||
    domRect1.right < domRect2.left ||
    domRect1.bottom < domRect2.top ||
    domRect1.left > domRect2.right
  )
}

export const DeadEnd = ({}: {}) => {
  const isDarkMode = useDarkMode()
  const margin = 5
  const color = isDarkMode ? Color.dark(200) : Color.gray(500)

  return (
    <Center h={50} w={50}>
      <Box
        h={50 - margin * 2}
        w={50 - margin * 2}
        m={margin}
        justify={'center'}
        align={'center'}
        bg={isDarkMode ? Color.dark(400) : Color.gray(200)}
        style={{
          border: `2px solid ${color}`,
          color,
          borderRadius: '50%',
        }}
      >
        <Icon name="PiMinus" size={22} />
      </Box>
    </Center>
  )
}

export const AddNodeContent = (props: DiagramNodeProps) => {
  const theme = useThemeColor()
  const diagramStore = useDiagramStore()
  const nodeId = useNodeContext((x) => x.id)
  const addNodeId = props.id
  const isRoot = useNodeContext((x) => x.isRoot)
  const queryKey = useRevisionContext((x) => x.queryKey)
  const content = useRevisionContext((x) => x.content)
  const [transitionStep, setTransitionStep] = useState<
    null | 'InsertMain' | 'ChooseNode' | 'ChooseProcess'
  >(null)
  const [menuStep, setMenuStep] = useState<
    null | 'InsertMain' | 'ChooseNode' | 'ChooseProcess'
  >(null)
  const [dragging, setDragging] = useState(false)
  const [moving, setMoving] = useState(false)
  const mousePos = useRef({ x: 0, y: 0 })
  const [mouseStartPos, setMouseStartPos] = useState({ x: 0, y: 0 })
  const connector = props.data.connectorsIn[0]
  const moveableRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropTargetId = useRef<number | null>(null)
  const reactFlow = useAsRef(useReactFlow())
  const setMinZoom = useReactFlowStore((x) => x.setMinZoom)
  const setMaxZoom = useReactFlowStore((x) => x.setMaxZoom)
  const direction = useDiagramContext((x) => x.direction)
  const scale = useDiagramContext((x) => Math.min(1 / x.viewport.zoom, 2))
  const revisionId = useRevisionContext((x) => x.id)
  const rootRevisionId = useDiagramContext((x) => x.rootRevisionId)
  const { addStep } = useProcessAction(queryKey, 'addStep')

  const canAdd = revisionId === rootRevisionId

  const { setStepTarget } = useProcessAction(queryKey, 'setStepTarget')
  const dropdownRef = useRef<HTMLDivElement>(null)
  const wrapperRef = mergeRefs(
    useEventListener('dblclick', (e) => {
      e.stopPropagation()
    }),
    useEventListener('mousedown', (e) => {
      e.stopPropagation()
    }),
  )

  useEffect(() => {
    if (!menuStep) return

    setTransitionStep(menuStep)

    const listener = (e: MouseEvent) => {
      if (dropdownRef.current?.contains(e.target as HTMLElement)) return
      setMenuStep(null)
    }

    setTimeout(() => {
      // Re-focus the dropdown menu on content change
      const dropdown = document.querySelector(
        '[data-menu-dropdown]',
      ) as HTMLInputElement
      dropdown?.focus()

      document.body.addEventListener('click', listener)
    })
    return () => {
      document.body.removeEventListener('click', listener)
    }
  }, [menuStep])

  useEffect(() => {
    if (!dragging) return
    let startViewport = reactFlow.current.getViewport()
    movingNodePosition.position = {
      x: props.positionAbsoluteX,
      y: props.positionAbsoluteY,
      width: props.width ?? 0,
      height: props.height ?? 0,
    }

    // Lock zoom while dragging
    setMinZoom(startViewport.zoom)
    setMaxZoom(startViewport.zoom)

    const targets = [
      ...document.getElementsByClassName(classes.diagramNodeWrapper),
    ] as HTMLDivElement[]

    let isFirst = true
    const moveListener = (e: MouseEvent) => {
      const zoom = startViewport.zoom
      mousePos.current = { x: e.clientX, y: e.clientY }
      const newPosition = {
        x: props.positionAbsoluteX + (e.clientX - mouseStartPos.x) / zoom,
        y: props.positionAbsoluteY + (e.clientY - mouseStartPos.y) / zoom,
      }
      // TODO: Allow panning
      // reactFlow.current.setViewport({
      //   x: startViewport.x,
      //   y: startViewport.y,
      //   zoom,
      // })
      if (
        Math.abs(e.clientX - mouseStartPos.x) < DRAG_DISTANCE_START &&
        Math.abs(e.clientY - mouseStartPos.y) < DRAG_DISTANCE_START
      ) {
        return
      }

      targets.forEach((x) => {
        const targetParentGlobalId = x.dataset.parentGlobalId
        const targetType = x.dataset.type
        const targetId = Number(x.dataset.id)

        // Ignore self
        if (targetId === props.data.id) return

        // Ignore Start node
        if (targetType === NodeType.Start) return

        // Ignore nodes in other parents
        if (targetParentGlobalId !== props.data.parentGlobalId) return

        if (doElementsOverlap(x, buttonRef.current!)) {
          x.setAttribute('data-drop-target', 'true')
          dropTargetId.current = targetId
        } else {
          x.removeAttribute('data-drop-target')
          if (dropTargetId.current === targetId) {
            dropTargetId.current = null
          }
        }
      })

      if (isFirst) {
        setMoving(true)
        diagramStore.setState({ movingNode: props.id })
      }
      isFirst = false

      movingNodePosition.position = {
        ...movingNodePosition.position,
        ...newPosition,
      }
      if (moveableRef.current) {
        moveableRef.current.style.left = newPosition.x + 'px'
        moveableRef.current.style.top = newPosition.y + 'px'
      }
    }
    const mouseupListener = (e: MouseEvent) => {
      // Reset node position to initial
      if (moveableRef.current) {
        moveableRef.current.style.left = props.positionAbsoluteX + 'px'
        moveableRef.current.style.top = props.positionAbsoluteY + 'px'
      }
      diagramStore.setState({ movingNode: null })
      setDragging(false)
      setMoving(false)

      // Unlock zoom
      setMinZoom(MIN_ZOOM)
      setMaxZoom(MAX_ZOOM)

      const targetNode = content.nodes.find(
        (x) => x.id === dropTargetId.current!,
      )
      if (targetNode) {
        setStepTarget({
          originId: nodeId,
          position: connector.position,
          targetId: targetNode.id,
        })
      }

      dropTargetId.current = null
      targets.forEach((x) => {
        if (doElementsOverlap(x, buttonRef.current!)) {
          x.setAttribute('data-drop-target', 'true')
        } else {
          x.removeAttribute('data-drop-target')
        }
      })
    }

    document.body.addEventListener('pointermove', moveListener)
    moveableRef.current!.addEventListener('pointerup', mouseupListener)
    document.body.addEventListener('click', mouseupListener)

    return () => {
      document.body.removeEventListener('pointermove', moveListener)
      moveableRef.current!.removeEventListener('pointerup', mouseupListener)
      document.body.removeEventListener('click', mouseupListener)
    }
  }, [dragging, content])

  if (!canAdd)
    return (
      <Box w={props.width} h={props.height} align="center" justify="center">
        <DeadEnd />
      </Box>
    )

  return (
    <Box ref={wrapperRef}>
      <DiagramNodeWrapper
        {...props}
        moveableRef={moveableRef}
        positionAbsoluteX={props.positionAbsoluteX}
        positionAbsoluteY={props.positionAbsoluteY}
        key={addNodeId}
        withMotion={false}
      >
        <Box w={props.width} h={props.height} align="center" justify="center">
          <Menu
            shadow="md"
            withArrow={true}
            offset={5 * scale}
            withinPortal={false}
            closeOnItemClick={false}
            opened={Boolean(menuStep)}
            onClose={() => setMenuStep(null)}
            transitionProps={{
              onExited() {
                setTransitionStep(null)
              },
            }}
          >
            <Menu.Target>
              <ActionIcon
                ref={buttonRef}
                className="nopan"
                variant="filled"
                radius="50%"
                data-locked={Boolean(menuStep)}
                size={50}
                color={
                  moving
                    ? theme(['gray', 6], ['gray', 1])
                    : theme(
                        ['blue', Boolean(menuStep) ? 5 : 3],
                        ['blue', Boolean(menuStep) ? 6 : 4],
                      )
                }
                onPointerDown={(e) => {
                  if (!menuStep) {
                    setDragging(true)
                    setMouseStartPos({ x: e.clientX, y: e.clientY })
                  }
                }}
                onClick={(e) => {
                  if (
                    Math.abs(e.clientX - mouseStartPos.x) <
                      DRAG_DISTANCE_START &&
                    Math.abs(e.clientY - mouseStartPos.y) < DRAG_DISTANCE_START
                  ) {
                    setMenuStep('ChooseNode')
                  }
                }}
                style={{
                  pointerEvents: 'all',
                  zIndex: 2,
                  ...(Boolean(menuStep) && {
                    border: `3px solid ${theme(
                      ['white', 0],
                      ['dark', 9],
                      true,
                    )}`,
                    outline: `1px solid ${theme(
                      ['blue', 3],
                      ['blue', 4],
                      true,
                    )}`,
                  }),
                }}
              >
                <Icon name={moving ? 'PiArrowUpRight' : 'MdAdd'} size={32} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown
              ref={dropdownRef}
              style={{
                cursor: 'default',
                pointerEvents: 'all',
                transform: `scale(${scale})`,
              }}
              onMouseDown={(e) => e.stopPropagation()}
              onMouseMove={(e) => e.stopPropagation()}
            >
              {transitionStep === 'ChooseNode' && (
                <ChooseNodeInsertMenu
                  isInserting={false}
                  isRoot={isRoot}
                  addNode={(type) => {
                    addStep({
                      originId: connector.origin.id,
                      position: connector.position,
                      step: {
                        type,
                      },
                    })
                    setMenuStep(null)
                  }}
                  addProcess={() => {
                    setTimeout(() => {
                      setMenuStep('ChooseProcess')
                    })
                  }}
                />
              )}
              {transitionStep === 'ChooseProcess' && (
                <ProcessSelectionMenu
                  onSelect={(x) => {
                    addStep({
                      originId: nodeId,
                      position: connector.position,
                      step: {
                        type: NodeType.Subprocess,
                        title: x.title ?? '',
                        subprocessId: x.id,
                        // mainRevision is filtered in the selection menu
                        subprocessRevisionId: x.mainRevisionId!,
                      },
                    })
                    setMenuStep(null)
                  }}
                />
              )}
            </Menu.Dropdown>
          </Menu>
        </Box>
      </DiagramNodeWrapper>
    </Box>
  )
}
