import { TeamRole } from '@prisma/client'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import {
  cacheTeamMember,
  useTeamMemberQuery,
} from '~/client/dashboard/queries/team-member-queries'
import {
  useTeamMembersQuery,
  useTeamQuery,
} from '~/client/dashboard/queries/team-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
  useCommandMutation,
} from '~/commands/client-commands'
import { useTeamModelCtx } from '~/commands/team/team-commands-client'

/**
 * TeamMember client commands
 */

export const teamMemberCommandsClient = {
  SetRole: {
    getValidationContext({ sender, input, target, ctx, commandCtx }) {
      return {
        sender: {
          isOwner: sender.teamProfile?.role === TeamRole.Owner,
        },
        target: {
          isSender: sender.organizationProfile?.id === target.memberId,
          currentRole: target.role,
          newRole: input.params.role ?? TeamRole.Member,
        },
        meta: {
          teamAccess: ctx.team.access,
          teamOwnerCount: commandCtx.teamOwnerCount,
        },
      }
    },
    getAuthorizationContext({ sender, ctx }) {
      return {
        sender: {
          isJoined: Boolean(sender.teamProfile),
        },
        target: {},
        meta: {
          teamAccess: ctx.team.access,
        },
      }
    },
    onMutate: [
      (ctx, { params, key }) =>
        cacheTeamMember(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          false,
        ),
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.TeamMember>

/**
 * TeamMember command context
 */

export const teamMemberCommandContext = {
  SetRole({ input, target, ctx }) {
    const { teamMembers, isLoading, isError } = useTeamMembersQuery({
      id: ctx.team?.id,
      organizationId: ctx.team?.organizationId,
    })
    const teamOwnerCount = teamMembers.filter(
      (x) => x.role === TeamRole.Owner,
    ).length

    return {
      ctx: {
        isTargetJoined: Boolean(target),
        targetCurrentRole: target?.role,
        teamOwnerCount,
      },
      state: {
        failed: isError,
        isLoading,
      },
    }
  },
} satisfies ClientCommandContextDefinitions<ModelName.TeamMember>

/**
 * TeamMember client context
 */

export const useTeamMemberModelCtx = ((key) => {
  const { ctx, sender, state } = useTeamModelCtx({
    id: key.teamId,
    organizationId: key.organizationId,
  })

  const { teamMember: target, isLoading, isError } = useTeamMemberQuery(key)

  return {
    target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.TeamMember>
