'use client'

import { useUser } from '@clerk/clerk-react'
import { Organization } from '@prisma/client'
import { ReactNode, useContext, useEffect } from 'react'
import { createStore, useStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { UserSkeleton } from '~/client/dashboard/components/user/UserShell'
import { QueryRequestError } from '~/client/dashboard/queries/helpers/query-helpers'
import { _useBootstrapQuery } from '~/client/dashboard/queries/user-bootstrap-queries'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'
import { UserContext } from '~/client/dashboard/stores/UserContext'
import { ServerError } from '~/client/shared/ErrorPage'
import { useLogout } from '~/client/shared/hooks/useLogout'
import { ClientModel } from '~/schemas'
import {
  useUserOrganizationsQuery,
  useUserQuery,
} from '~/client/dashboard/queries/user-queries'

export type UserState = {
  id: string
  user: ClientModel['User']
  email: string | undefined
  organizations: Organization[]
  logout: () => Promise<void>
}

const PERSISTED_FIELDS = [] as (keyof UserState)[]

export type UserStoreProps = Pick<UserState, 'user' | 'email'>

export type UserStore = ReturnType<typeof createUserStore>

export const createUserStore = (props: UserStoreProps) => {
  const id = props.user.id

  return createStore<UserState>()(
    persist(
      (set) =>
        ({
          id,
          organizations: [],
          logout: async () => {},
          ...props,
        }) as UserState,
      {
        name: 'user-storage:' + id,
        version: 0, // Update if any fields change
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              PERSISTED_FIELDS.includes(key as keyof UserState),
            ),
          ),
        // Uncomment this if data migration is required
        // In general, just updating the version is fine because UI state is not that important
        // migrate: (persistedState: UserState, version) => {
        //   if (version === 0) {
        //     // if the stored value is in version 0, we rename the field to the new name
        //     persistedState.newField = persistedState.oldField
        //     delete persistedState.oldField
        //   }
        //   return persistedState
        // },
      },
    ),
  )
}

type BootstrapProviderProps = React.PropsWithChildren<{
  loader?: ReactNode
}>

export const BootstrapProvider = ({
  loader,
  children,
}: BootstrapProviderProps) => {
  const { error, ready, bootstrap } = _useBootstrapQuery()

  // Wait until bootstrap is cached
  if (!ready) {
    return loader ?? <UserSkeleton />
  }

  if (error || !bootstrap) {
    return <ServerError />
  }

  return <UserProvider id={bootstrap.user.id}>{children}</UserProvider>
}

type UserProviderProps = React.PropsWithChildren<{
  id: string
  loader?: ReactNode
  failure?: ReactNode
  notFound?: ReactNode
}>

export const UserProvider = ({
  children,
  id,
  failure,
  notFound,
}: UserProviderProps) => {
  const setUserStore = useGlobalContext((x) => x.setUserStore)
  const userStore = useGlobalContext((x) => x.userStore)
  const { user, error, isLoading } = useUserQuery({ id })
  const { organizations } = useUserOrganizationsQuery({ id })
  const _logout = useLogout()
  const email = useUser().user?.emailAddresses[0]?.emailAddress

  useEffect(() => {
    if (!user) return
    if (userStore) {
      userStore.setState({
        user,
        email,
      })
    } else {
      const store = createUserStore({
        user,
        email,
      })
      setUserStore(store)
    }
  }, [userStore, user, email])

  useEffect(() => {
    if (!userStore) return
    userStore.setState({
      organizations,
    })
  }, [userStore, organizations])

  useEffect(() => {
    if (!userStore) return
    logout = _logout
    userStore.setState({
      logout,
    })
  }, [userStore, _logout])

  if (error) {
    return failure ?? <QueryRequestError error={error} />
  }

  if (!user) {
    if (isLoading) return null
    return notFound
  }

  if (!userStore) return null

  __dev.user = user

  return (
    <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
  )
}

export const useUserContext = <T extends any>(
  selector: (state: UserState) => T,
) => {
  const store = useContext(UserContext)
  if (!store) throw new Error('Missing UserContext.Provider in the tree')
  return useStore(store, selector)
}

export const useUserStore = () => {
  const store = useContext(UserContext)
  if (!store) throw new Error('Missing UserContext.Provider in the tree')
  return store
}
