import { Skeleton, Text } from '@mantine/core'
import { Column } from '~/client/shared/Layout'

export default function About() {
  return (
    <Column>
      <Text fz={36}>About</Text>
      <Text>...Content here</Text>
      <Column mt={20} w={400}>
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </Column>
    </Column>
  )
}
