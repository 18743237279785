import { Box, Burger, Divider } from '@mantine/core'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { RouteMap } from '~/client/RouteMap'
import {
  BodySkeleton,
  HeaderSkeleton,
  NavSkeleton,
} from '~/client/dashboard/components/global/DashboardShell'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  Portal,
  PortalTarget,
  PortalTargetId,
} from '~/client/dashboard/components/global/Portals'
import { ActiveOrganizationDropdown } from '~/client/dashboard/components/organization/OrganizationDropdown'
import { OrganizationNav } from '~/client/dashboard/components/organization/OrganizationNav'
import { OrganizationSearchInput } from '~/client/dashboard/components/organization/OrganizationSearch'
import { UserDropdown } from '~/client/dashboard/components/user/UserDropdown'
import { useDocumentMemberQuery } from '~/client/dashboard/queries/document-member-queries'
import { useDocumentQuery } from '~/client/dashboard/queries/document-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'
import {
  OrganizationNavItem,
  useOrganizationContext,
} from '~/client/dashboard/stores/OrganizationStore'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import { Row } from '~/client/shared/Layout'
import { useDebugMount } from '~/client/shared/hooks/useDebugMount'
import { DeviceSize, useDeviceSizeMatches } from '~/client/shared/hooks/useDeviceSize'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { ButtonWithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'

export const OrganizationShell = ({ children }: { children: ReactNode }) => {
  const openedSmall = useGlobalContext((x) => x.isNavOpenSmall)
  const setNavOpenMedium = useGlobalContext((x) => x.setNavOpenMedium)
  const setNavOpenSmall = useGlobalContext((x) => x.setNavOpenSmall)
  const openedMedium = useGlobalContext((x) => x.isNavOpenMedium)

  return (
    <>
      <Portal target={PortalTargetId.NavBar}>
        <ErrorBoundary>
          <OrganizationNav />
        </ErrorBoundary>
      </Portal>
      <Portal target={PortalTargetId.Header}>
        <DeviceSize
          xs={
            <Header.Small
              toggle={() => setNavOpenSmall(!openedSmall)}
              opened={openedSmall}
            />
          }
          md={
            <Header.Medium
              toggle={() => setNavOpenMedium(!openedMedium)}
              opened={openedMedium}
            />
          }
          lg={<Header.Large />}
        />
      </Portal>
      <ErrorBoundary id="OrganizationShell">{children}</ErrorBoundary>
    </>
  )
}

export const CreateProcessButton = ({
  fullWidth = false,
}: {
  fullWidth?: boolean
}) => {
  const navigate = useNavigate()
  const [activeDocumentId, setActiveDocumentId] = useState<string>()
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const organizationMemberId = useOrganizationContext((x) => x.currentMember.id)
  const activeItems = useOrganizationContext((x) => x.activeItems)
  const { document } = useDocumentQuery({
    organizationId,
    id: activeDocumentId,
  })
  const { documentMember } = useDocumentMemberQuery({
    organizationId,
    memberId: organizationMemberId,
    documentId: activeDocumentId,
  })
  let documentIds: string[] = []

  const activeItem = activeItems[0]

  if (document) {
    const documentAccess = getMemberDocumentAccess({
      privacy: document.privacy,
      documentMemberRole: documentMember?.role ?? null,
    })
    if (documentAccess.edit) {
      documentIds = [document.id]
    }
  }

  useEffect(() => {
    if (!activeItem || activeItem.type !== 'Category') {
      setActiveDocumentId(undefined)
    } else {
      setActiveDocumentId(activeItem.id)
    }
  }, [activeItem?.id])

  return (
    <ButtonWithCommand
      variant="gradient"
      size="compact-md"
      radius={50}
      fullWidth={fullWidth}
      model={ModelName.Organization}
      command="CreateProcess"
      queryKey={{
        id: organizationId,
      }}
      validate={{
        documentIds,
      }}
      notAllowed="hide"
      onClick={({ createProcessAsync }) => {
        void createProcessAsync({}).then((result) => {
          navigate(RouteMap.Process(organizationSlug, result.process.slug))
        })
      }}
    >
      Create process
    </ButtonWithCommand>
  )
}

const Header = {
  Small: ({ opened, toggle }: { opened: boolean; toggle: () => void }) => {
    const isDeviceTiny = useDeviceSizeMatches({ max: 370 })
    const user = useUserContext((x) => x.user)
    const organizationId = useOrganizationContext((x) => x.id)
    const memberId = useOrganizationContext((x) => x.currentMember.id)
    const [search, setSearch] = useState('')
    const [isActive, setIsActive] = useState(false)
    const resetRef = useRef<(item?: any, clear?: boolean) => void>(() => {})
    useDebugMount('Header')

    const slideForSearch = (Boolean(search) || isActive) && isDeviceTiny

    return (
      <Row w="100%" justify="stretch" h="100%">
        {/* Left */}
        <div
          style={{
            transition: '150ms ease all',
            marginLeft: slideForSearch ? -80 : 0,
          }}
        >
          <Box m={6} mr={0}>
            <ActiveOrganizationDropdown iconOnly={true} />
          </Box>
        </div>
        <Row style={{ flexGrow: 1 }} justify="space-between" gap="sm">
          {/* Middle */}
          {user.activeOrganizationId && (
            <OrganizationSearchInput
              onSearchChange={setSearch}
              expandSearch={{ inactiveWidth: 116, activeWidth: '100%' }}
              dropdownPosition="bottom-start"
              resetRef={resetRef}
              onActive={() => setIsActive(true)}
              onInactive={() => setIsActive(false)}
              input={{
                ...(search && { w: '100%' }),
                m: 0,
                maw: 250,
                rightSection: search && (
                  <Box
                    onClick={() => {
                      resetRef.current?.(null, true)
                    }}
                  >
                    <Icon name="MdClose" size={20} />
                  </Box>
                ),
              }}
            />
          )}
          {/* Right */}
          <Row>
            <Row mr="lg" ml={2} gap="sm">
              <OrganizationUserDropdown />
            </Row>
            <Box mr="md">
              <Burger
                opened={opened}
                onClick={toggle}
                aria-label={opened ? 'Close navigation' : 'Open navigation'}
              />
            </Box>
          </Row>
        </Row>
      </Row>
    )
  },
  Medium: ({ opened, toggle }: { opened: boolean; toggle: () => void }) => {
    const user = useUserContext((x) => x.user)
    const activeNavItem = useOrganizationContext((x) => x.activeNavItem)
    useDebugMount('Header')

    return (
      <Row w="100%" justify="space-between" h="100%">
        {/* Left */}
        <Row style={{ flexGrow: 1 }}>
          <Box ml={12}>
            <Burger
              opened={opened}
              onClick={toggle}
              aria-label={opened ? 'Close navigation' : 'Open navigation'}
            />
          </Box>
          <Box ml="md" mr="sm" h={40} display="flex">
            <Divider orientation="vertical" />
          </Box>
          <ActiveOrganizationDropdown />
          {user.activeOrganizationId && (
            <Box ml="md">
              <OrganizationSearchInput
                expandSearch={{ inactiveWidth: 180, activeWidth: 230 }}
              />
            </Box>
          )}
        </Row>
        {/* Right */}
        <Row mr="lg" gap="lg">
          <PortalTarget id={PortalTargetId.OrgHeaderRight}></PortalTarget>
          {activeNavItem !== OrganizationNavItem.Process && (
            <CreateProcessButton />
          )}
          <OrganizationUserDropdown />
        </Row>
      </Row>
    )
  },
  Large: () => {
    const user = useUserContext((x) => x.user)
    const activeNavItem = useOrganizationContext((x) => x.activeNavItem)
    useDebugMount('Header')

    return (
      <Row w="100%" justify="space-between" h="100%">
        {/* Left */}
        <Row style={{ flexGrow: 1 }} pl="xs">
          <ActiveOrganizationDropdown />
          {user.activeOrganizationId && (
            <Box ml="lg">
              <OrganizationSearchInput
                expandSearch={{ inactiveWidth: 240, activeWidth: 300 }}
              />
            </Box>
          )}
        </Row>
        {/* Right */}
        <Row mr="lg" gap="lg">
          <PortalTarget id={PortalTargetId.OrgHeaderRight}></PortalTarget>
          {activeNavItem !== OrganizationNavItem.Process && (
            <CreateProcessButton />
          )}
          <OrganizationUserDropdown />
        </Row>
      </Row>
    )
  },
}

const OrganizationUserDropdown = () => {
  const organizationId = useOrganizationContext((x) => x.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const memberId = useOrganizationContext((x) => x.currentMember.id)
  const { organizationMember } = useOrganizationMemberQuery({
    id: memberId,
    organizationId,
  })

  return (
    <UserDropdown
      imageUrl={organizationMember?.imageUrl}
      username={organizationMember?.alias}
      organizationSlug={organizationSlug}
    />
  )
}

export const OrganizationSkeleton = () => {
  return (
    <>
      <Portal target={PortalTargetId.NavBar}>
        <NavSkeleton />
      </Portal>
      <Portal target={PortalTargetId.Header}>
        <HeaderSkeleton />
      </Portal>
      <BodySkeleton />
    </>
  )
}
