import { DocumentRole } from '@prisma/client'
import { z } from 'zod'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

export type DocumentAccess = z.infer<typeof DocumentAccess>
export const DocumentAccess = z.object({ edit: z.boolean(), view: z.boolean() })

/**
 * Document schemas
 */

export type DocumentMemberSchema = z.infer<typeof DocumentMemberSchema>
export const DocumentMemberSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  userId: z.string().nullable().readonly(),
  documentId: z.string().readonly(),
  memberId: z.string().readonly(),
  role: z.nativeEnum(DocumentRole),
}) satisfies PrismaModelSchema<'DocumentMemberProfile'>

const DocumentMemberQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    documentId: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    memberId: z.string(),
    documentId: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    userId: z.string(),
    documentId: z.string(),
    organizationId: z.string(),
  }),
])
export const documentMemberQueryKeys = DocumentMemberQueryKeys.items

export const DocumentMemberQueryKey = z.union(DocumentMemberQueryKeys.items)
export type DocumentMemberQueryKey = z.infer<typeof DocumentMemberQueryKey>

export const ClientDocumentMemberSchema = DocumentMemberSchema
