'use client'

import {
  ActionIcon,
  AppShell,
  AppShellSectionProps,
  Button,
  Collapse,
  NavLink,
  ScrollArea,
  Text,
  Textarea,
  Tooltip,
  useCombobox,
} from '@mantine/core'
import {
  useDisclosure,
  useLocalStorage,
  useShallowEffect,
} from '@mantine/hooks'
import { DocumentPrivacy, OrganizationType } from '@prisma/client'
import {
  MutableRefObject,
  ReactNode,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link } from 'wouter'
import { RouteMap } from '~/client/RouteMap.ts'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  DropdownDivider,
  DropdownMenu,
  DropdownOption,
} from '~/client/dashboard/components/global/Prefabs'
import {
  ItemViewProps,
  TreeItem,
  TreeView,
  TreeViewActions,
  buildDocumentFlatTree,
} from '~/client/dashboard/components/global/TreeView'
import { ActiveOrganizationDropdown } from '~/client/dashboard/components/organization/OrganizationDropdown'
import { CreateProcessButton } from '~/client/dashboard/components/organization/OrganizationShell'
import { ProcessIcon } from '~/client/dashboard/components/process/ProcessComponents'
import { SettingsItem } from '~/client/dashboard/components/settings/SettingsShell'
import {
  EditDocumentModal,
  NewDocumentModal,
  NewDocumentModalProps,
} from '~/client/dashboard/forms/DocumentForms'
import { useDocumentMemberQuery } from '~/client/dashboard/queries/document-member-queries'
import {
  useOrganizationDocumentsQuery,
  useOrganizationProcessesQuery,
  useOrganizationRevisionsQuery,
} from '~/client/dashboard/queries/organization-queries'
import {
  OrganizationNavItem,
  useOrganizationContext,
} from '~/client/dashboard/stores/OrganizationStore'
import { Box, Column, Row } from '~/client/shared/Layout'
import {
  documentIcon,
  documentIconChevron,
  documentIconChevronEmpty,
  documentIconEmpty,
  documentIconSize,
  documentIcons,
  documentPrivacy,
} from '~/client/shared/data/document-data'
import { globalIcons } from '~/client/shared/data/global-data'
import { navigate, useNavigate } from '~/client/shared/hooks/useNavigate'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'
import { NAME_MAX_LENGTH } from '~/schemas/document-schema'
import {
  useDarkMode,
  useDeviceSize,
  useGlobalContext,
} from '~/client/dashboard/stores/GlobalStore'
import classes from './OrganizationNav.module.css'
import { ButtonWithCommand } from '~/commands/WithCommand'

export const OrganizationNav = memo(() => {
  const deviceSize = useDeviceSize()
  const getDraftColor = useGlobalContext((x) => x.getDraftColor)
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const organizationType = useOrganizationContext((x) => x.organization.type)
  const [
    newDocumentOpened,
    { close: closeNewDocument, open: openNewDocument },
  ] = useDisclosure()
  const activeNavItem = useOrganizationContext((x) => x.activeNavItem)
  const { revisions } = useOrganizationRevisionsQuery({
    id: organizationId,
  })

  const numDrafts = revisions.filter((x) => x.isDraft).length

  return (
    <Column w="100%">
      {deviceSize.isSmall && (
        <div>
          <Column p={6} gap={12} align="flex-start">
            <Row w="100%" style={{ overflow: 'hidden' }}>
              <ActiveOrganizationDropdown />
            </Row>
            <Row w="100%" px="sm">
              <CreateProcessButton fullWidth={true} />
            </Row>
          </Column>
        </div>
      )}
      <Column style={{ overflow: 'auto', flex: '1 1' }} gap={10}>
        <ScrollArea type="never">
          <Column gap={6} w="100%" pb={40} mt="xs">
            {/* {isDebug && (
              <NavSection
                px={10}
                py={6}
                bg={isDarkMode ? `rgba(0,255,255,0.1)` : `rgba(0,255,255,0.1)`}
                bodyPadding={0}
                title="Debug"
              >
                <Column gap={2} w="100%">
                  <NavLink
                    component={Link}
                    href={location + '/playground'}
                    label={OrganizationNavItem.Playground}
                    leftSection={<Icon name="PiFlaskDuotone" size={18} />}
                    active={activeNavItem === OrganizationNavItem.Playground}
                  />
                </Column>
              </NavSection>
            )} */}
            <NavSection px={0}>
              <Column gap={2} w="100%">
                <NavLink
                  component={Link}
                  href={RouteMap.Organization(organizationSlug)}
                  label={OrganizationNavItem.Home}
                  leftSection={<Icon name={globalIcons.Home} size={18} />}
                  active={activeNavItem === OrganizationNavItem.Home}
                />
                {numDrafts > 0 && (
                  <NavLink
                    component={Link}
                    href={RouteMap.Drafts(organizationSlug)}
                    label={OrganizationNavItem.Drafts}
                    leftSection={
                      <Icon
                        name={documentIcons.Draft}
                        size={18}
                        bg={getDraftColor()}
                      />
                    }
                    active={activeNavItem === OrganizationNavItem.Drafts}
                  />
                )}
                {organizationType !== OrganizationType.Personal && (
                  <NavLink
                    component={Link}
                    href={RouteMap.TeamExplorer(organizationSlug)}
                    label={OrganizationNavItem.TeamExplorer}
                    leftSection={<Icon name={globalIcons.Team} size={18} />}
                    active={activeNavItem === OrganizationNavItem.TeamExplorer}
                  />
                )}
              </Column>
            </NavSection>
            <NavSection title="Favorites">
              <Text fz="sm">Not implemented</Text>
            </NavSection>
            <UnlistedProcessesSection />
            <NavSection
              bodyPadding={0}
              title="Directory"
              rightSection={
                <Tooltip label="Create a new category" withArrow={true}>
                  <ActionIcon
                    component="div"
                    size={20}
                    onClick={() => {
                      openNewDocument()
                    }}
                    radius={99}
                    // variant="outline"
                  >
                    <Icon name="PiPlusBold" />
                  </ActionIcon>
                </Tooltip>
              }
            >
              <NavTree key={organizationId} />
            </NavSection>
          </Column>
        </ScrollArea>
      </Column>
      <NavSection px={0} pb={8} pt={2} style={{ flex: 0 }}>
        <Column w="100%">
          {organizationType !== OrganizationType.Personal && (
            <NavLink
              component={Link}
              href={RouteMap.Settings(
                SettingsItem.Invitations,
                organizationSlug,
              )}
              label={OrganizationNavItem.Invitations}
              active={activeNavItem === OrganizationNavItem.Invitations}
              leftSection={<Icon name="PiUserPlus" size={18} />}
            />
          )}
          <NavLink
            component={Link}
            href={RouteMap.Settings(SettingsItem.Profile, organizationSlug)}
            label={OrganizationNavItem.Settings}
            leftSection={<Icon name="PiGear" size={18} />}
            active={activeNavItem === OrganizationNavItem.Settings}
          />
        </Column>
      </NavSection>
      <CreateDocumentModal
        opened={newDocumentOpened}
        onClose={closeNewDocument}
        organizationId={organizationId}
      />
    </Column>
  )
})

const UnlistedProcessesSection = () => {
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const { processes } = useOrganizationProcessesQuery({
    id: organizationId,
  })
  const activeProcesses = useOrganizationContext((x) =>
    x.activeItems.filter((x) => x.type === 'Process'),
  )
  const unlistedProcesses = processes
    .filter(
      (x) =>
        Boolean(x.mainRevisionId) &&
        x.documentIds.length === 0 &&
        !x.isArchived,
    )
    .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))

  if (unlistedProcesses.length === 0) return null

  return (
    <NavSection title="Unlisted" defaultOpened={false}>
      <Column w="100%">
        {unlistedProcesses.map((x) => (
          <NavLink
            key={x.id}
            h={32}
            component={Link}
            href={RouteMap.Process(organizationSlug, x.slug)}
            active={activeProcesses.some(
              (activeItem) => x.id === activeItem.id,
            )}
            label={
              <Row gap="sm">
                <ProcessIcon
                  id={x.id}
                  size={20}
                  revisionId={x.mainRevisionId}
                />
                {x.title}
              </Row>
            }
          />
        ))}
      </Column>
    </NavSection>
  )
}

const CreateDocumentModal = (
  props: Omit<NewDocumentModalProps, 'parentId'>,
) => {
  const navigate = useNavigate()
  const activeItems = useOrganizationContext((x) => x.activeItems)
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const rootDocumentId = useOrganizationContext(
    (x) => x.organization.rootDocumentId,
  )
  const { documents } = useOrganizationDocumentsQuery({
    id: organizationId,
  })

  let targetDocumentId = rootDocumentId
  const firstActiveItem = activeItems[0]
  if (firstActiveItem?.type === 'Category') {
    // Find the closest document and get its parent.
    //  New documents will be created as siblings.
    const documentItem = documents?.find((x) => x.id === firstActiveItem.id)
    if (documentItem) {
      targetDocumentId = documentItem.parentId ?? targetDocumentId
    }
  }

  return (
    <NewDocumentModal
      {...props}
      parentId={targetDocumentId}
      onCreate={(result) => {
        navigate(RouteMap.Document(organizationSlug, result.slug))
      }}
    />
  )
}

type NavSectionProps = {
  title?: string
  rightSection?: ReactNode
  bodyPadding?: number
  children: ReactNode
} & AppShellSectionProps

const NavSection = ({
  title,
  rightSection,
  children,
  bodyPadding = 10,
  defaultOpened = true,
  ...props
}: NavSectionProps & { defaultOpened?: boolean }) => {
  const [opened, setOpened] = useLocalStorage({
    key: 'nav:' + title,
    defaultValue: defaultOpened,
  })
  const [ready, setReady] = useState(false)

  useEffect(() => {
    // Hack to ensure collapse transition doesn't run on first render
    setTimeout(() => {
      setReady(true)
    }, 1000)
  }, [])

  return (
    <AppShell.Section px={10} {...props}>
      {title && (
        <Button
          className={classes.sectionButton}
          color="gray"
          data-important
          variant="subtle"
          size="compact-md"
          justify="space-between"
          onClick={() => setOpened(!opened)}
          fullWidth={true}
          rightSection={
            <Row onClick={(e) => e.stopPropagation()} gap={6}>
              <Row gap={2} className={classes.rightSectionActions}>
                {rightSection}
              </Row>
              <Box onClick={() => setOpened(!opened)}>
                <Icon
                  size={14}
                  name={opened ? 'PiCaretDownBold' : 'PiCaretRightBold'}
                />
              </Box>
            </Row>
          }
        >
          {title}
        </Button>
      )}
      <Collapse
        in={opened || !title}
        px={bodyPadding}
        transitionDuration={ready ? 150 : 0}
        transitionTimingFunction="linear"
      >
        <Row my="xs">{children}</Row>
      </Collapse>
    </AppShell.Section>
  )
}

const NavTree = () => {
  const actionRef = useRef<TreeViewActions | undefined>()
  const [data, setData] = useState<TreeItem[]>([])
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const rootDocumentId = useOrganizationContext(
    (x) => x.organization.rootDocumentId,
  )
  const { documents } = useOrganizationDocumentsQuery({
    id: organizationId,
  })
  const { processes } = useOrganizationProcessesQuery({
    id: organizationId,
  })
  const [editingTitleId, setEditingTitleId] = useState<string | null>(null)
  const [newDocumentParentId, setNewDocumentParentId] = useState(rootDocumentId)
  const [
    newDocumentOpened,
    { close: closeNewDocument, open: openNewDocument },
  ] = useDisclosure()
  const activeItems = useOrganizationContext((x) => x.activeItems)
  const activeDocumentIds = activeItems
    .flatMap((item) =>
      item.type === 'Category'
        ? item.id
        : processes.find((x) => x.id === item.id)?.documentIds,
    )
    .filter(Boolean)

  const isViewingProcess = activeItems.some((x) => x.type === 'Process')

  useEffect(() => {
    setData(
      buildDocumentFlatTree(
        rootDocumentId,
        documents,
        processes,
        (x) => !x.data.isArchived && x.type === 'Category',
        true,
      ),
    )
  }, [documents, processes, rootDocumentId])

  const selectedIds = data
    .filter((x) => activeDocumentIds.some((id) => id === x.data.id))
    .map((x) => x.id)

  useShallowEffect(() => {
    if (isViewingProcess) {
      if ((actionRef.current?.selectedIds.size ?? 0) > 0) {
        actionRef.current?.setSelectedIds(new Set())
      }
    } else {
      actionRef.current?.setSelectedIds(new Set(selectedIds))
    }
  }, [selectedIds, isViewingProcess])

  // Expand to ensure all active documentIds are visible in tree
  const expandedIds = activeDocumentIds
    .flatMap((id) => {
      const treeId = data.find((x) => x.data.id === id)?.id
      return data.filter(
        (x) => treeId && treeId.includes(x.data.id) && treeId !== x.data.id,
      )
    })
    .map((x) => x.id)

  useShallowEffect(() => {
    actionRef.current?.setExpandedIds(
      new Set([...actionRef.current.expandedIds, ...expandedIds]),
    )
  }, [expandedIds])

  const activeItemIds = data
    .filter((x) => activeDocumentIds.some((id) => id === x.data.id))
    .map((x) => x.id)

  if (data.length <= 1)
    return (
      <Row px="sm">
        <DirectoryEmptyState />
      </Row>
    )

  return (
    <>
      <TreeView
        data={data}
        appearance="default"
        tabWidth={14}
        maxTabWidth={160}
        defaultVisibleIds={activeItemIds}
        actionRef={actionRef}
        onEditContent={setEditingTitleId}
        onItemSelect={(item) => {
          return navigate(RouteMap.Document(organizationSlug, item.data.slug))
        }}
        ItemView={(props) => {
          return (
            <NavTreeItem
              key={props.element.id}
              props={props}
              isActive={
                isViewingProcess &&
                activeDocumentIds.includes(props.element.data.id)
              }
              editingTitleId={editingTitleId}
              setEditingTitleId={setEditingTitleId}
              actionRef={actionRef}
              onCreateSubdocument={() => {
                openNewDocument()
                setNewDocumentParentId(props.element.data.id)
              }}
            />
          )
        }}
      />
      <NewDocumentModal
        opened={newDocumentOpened}
        onClose={closeNewDocument}
        onCreate={(result) => {
          navigate(RouteMap.Document(organizationSlug, result.slug))
        }}
        organizationId={organizationId}
        parentId={newDocumentParentId}
      />
    </>
  )
}

const NavTreeItem = memo(
  ({
    props,
    isActive,
    editingTitleId,
    setEditingTitleId,
    actionRef,
    onCreateSubdocument,
  }: {
    props: ItemViewProps
    isActive: boolean
    editingTitleId: string | null
    setEditingTitleId: (id: string | null) => void
    actionRef: MutableRefObject<TreeViewActions | undefined>
    onCreateSubdocument: () => void
  }) => {
    const isDarkMode = useDarkMode()
    const organizationId = useOrganizationContext((x) => x.organization.id)
    const organizationSlug = useOrganizationContext((x) => x.organization.slug)
    const organizationMemberId = useOrganizationContext(
      (x) => x.currentMember.id,
    )
    const { element } = props
    const nameInputRef = useRef<HTMLTextAreaElement>(null)
    const [
      editCategoryOpened,
      { open: openEditCategoryModal, close: closeEditCategoryModal },
    ] = useDisclosure(false)

    const { updateDocumentProfile, valid: canUpdate } = useCommand(
      ModelName.Document,
      'UpdateProfile',
      {
        id: props.element.data.id,
        organizationId: props.element.data.organizationId,
      },
    )

    const { documentMember } = useDocumentMemberQuery({
      memberId: organizationMemberId,
      documentId: element.data.id,
      organizationId,
    })

    const access = getMemberDocumentAccess({
      documentMemberRole: documentMember?.role ?? null,
      privacy: element.data.privacy,
    })

    // NOTE: DO NOT USE QUERY HOOKS IN HERE
    //  For unknown reasons, this results in unpredictable behavior,
    //  particularly if a command fails validation,
    //  resulting in infinite re-fetches.

    const [title, setTitle] = useState(element.name)
    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    })
    const isEditingTitle = canUpdate && editingTitleId === element.id
    const icon =
      element.data.privacy !== DocumentPrivacy.Open ? (
        <Box opacity={0.7} ml="xs" align="flex-end">
          <Icon
            nudgeUp={1}
            name={documentPrivacy[element.data.privacy].iconName}
            style={{ display: 'inline' }}
          />
        </Box>
      ) : null

    useEffect(() => {
      setTimeout(() => {
        nameInputRef.current?.focus()
      })
    }, [isEditingTitle])

    return (
      <Row
        className={classes.treeItem}
        px={8}
        py={6}
        gap={6}
        w="100%"
        justify="stretch"
        tabIndex={-1}
      >
        <TreeView.Caret props={props} size={20} />
        <Box
          mr={2}
          c={isActive ? (isDarkMode ? 'blue.4' : 'blue.5') : 'inherit'}
        >
          {isActive ? (
            <Icon
              name={
                access.view ? documentIconChevron : documentIconChevronEmpty
              }
              nudgeUp={1}
              size={documentIconSize}
            />
          ) : (
            <Icon
              name={access.view ? documentIcon : documentIconEmpty}
              nudgeUp={1}
              size={documentIconSize}
            />
          )}
        </Box>
        {isEditingTitle && (
          <Textarea
            ref={nameInputRef}
            variant="unstyled"
            size="md"
            autosize={true}
            minRows={1}
            placeholder="Name..."
            defaultValue={element.name}
            styles={{ root: { flexGrow: 1 } }}
            classNames={{
              input: classes.titleInput,
            }}
            onKeyDown={(e) => {
              e.stopPropagation()
              if (e.key === 'Escape') {
                setEditingTitleId(element.name)
                const target = e.currentTarget
                setTimeout(() => {
                  target?.blur()
                })
              }
              if (e.key === 'Enter') e.currentTarget.blur()
            }}
            onFocus={(e) => e.currentTarget.select()}
            onBlur={() => {
              actionRef.current?.focusItem(editingTitleId)
              setEditingTitleId(null)
              if (title === element.name) return
              if (element.type === 'Category') {
                updateDocumentProfile({
                  title,
                })
              }
            }}
            onChange={(e) => {
              setTitle(e.currentTarget.value)
            }}
            maxLength={NAME_MAX_LENGTH}
          />
        )}
        {!isEditingTitle && (
          <Row style={{ flexGrow: 1 }} display={'inline-flex'}>
            <Text size="md" lh={1.2} lineClamp={3}>
              {element.name}
            </Text>
            {icon}
          </Row>
        )}
        <DropdownMenu
          store={combobox}
          classNames={{
            dropdown: classes.treeItemDropdown,
          }}
          // width={180}
          position="bottom-start"
          target={
            <ActionIcon
              tabIndex={-1}
              onKeyDown={(e) => e.stopPropagation()}
              onKeyUp={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation()
                combobox.toggleDropdown()
              }}
              onBlur={() => combobox.closeDropdown()}
              className={classes.treeItemDots}
              variant="subtle"
              color={isDarkMode ? 'gray.3' : 'gray.9'}
              data-open={combobox.dropdownOpened}
              radius="50%"
              size={19}
            >
              <Icon name="PiDotsThreeBold" size={18} />
            </ActionIcon>
          }
        >
          <Column onClick={(e) => e.stopPropagation()}>
            <DropdownOption
              icon="PiPlusCircle"
              withCommand={{
                model: ModelName.Organization,
                command: 'CreateProcess',
                queryKey: {
                  id: element.data.organizationId,
                },
                validate: {
                  documentIds: [element.data.id],
                },
                tooltip: {
                  position: 'right',
                },
              }}
              onClick={({ createProcessAsync }) => {
                void createProcessAsync({}).then((result) => {
                  navigate(
                    RouteMap.Process(organizationSlug, result.process.slug),
                  )
                })
              }}
            >
              Create process
            </DropdownOption>
            <DropdownOption
              icon="PiTagSimple"
              withCommand={{
                model: ModelName.Document,
                command: 'CreateDocument',
                queryKey: element.data,
                tooltip: {
                  position: 'right',
                },
              }}
              onClick={() => {
                onCreateSubdocument()
              }}
            >
              Create subcategory
            </DropdownOption>
            <DropdownDivider />
            <DropdownOption
              icon="PiPencilSimple"
              withCommand={{
                model: ModelName.Document,
                command: 'UpdateProfile',
                queryKey: element.data,
                tooltip: {
                  position: 'right',
                },
              }}
              onClick={() => {
                setEditingTitleId(element.id)
              }}
            >
              Change name
            </DropdownOption>
            <DropdownOption
              icon={globalIcons.Settings}
              onClick={openEditCategoryModal}
              withCommand={{
                model: ModelName.Document,
                command: 'UpdateProfile',
                queryKey: element.data,
              }}
            >
              Edit
            </DropdownOption>
            <EditDocumentModal
              queryKey={element.data}
              opened={editCategoryOpened}
              onClose={closeEditCategoryModal}
              settings={{
                deleteButton: true,
              }}
            />
          </Column>
        </DropdownMenu>
      </Row>
    )
  },
  (prev, next) => {
    return prev.props.isExpanded === next.props.isExpanded
  },
)

export const DirectoryEmptyState = ({}: {}) => {
  const navigate = useNavigate()
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const rootDocumentId = useOrganizationContext(
    (x) => x.organization.rootDocumentId,
  )

  return (
    <Column gap="sm" align="flex-start" mt="lg" ml="md">
      <Text fs="italic">{`Organize your processes here.`}</Text>
      <ButtonWithCommand
        variant="outline"
        color="blue.3"
        size="compact-md"
        model={ModelName.Document}
        command="CreateDocument"
        queryKey={{
          id: rootDocumentId,
          organizationId,
        }}
        notAllowed="hide"
        onClick={({ createDocumentAsync }) => {
          void createDocumentAsync({}).then((result) => {
            navigate(RouteMap.Document(organizationSlug, result.document.slug))
          })
        }}
      >
        Create a category
      </ButtonWithCommand>
    </Column>
  )
}
