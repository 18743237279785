import { RevisionPublication } from '@prisma/client'
import { ClientModel } from '~/schemas'

export const getLatestRevision = (revisionHistory: RevisionPublication[]) => {
  return (
    revisionHistory
      .sort(
        (d1, d2) =>
          new Date(d1.publishedAt).getTime() -
          new Date(d2.publishedAt).getTime(),
      )
      .reverse()[0] ?? null
  )
}

export const getProcessDateInfo = (x: ClientModel['Process']) => {
  const latestRevision = getLatestRevision(x.revisionHistory)
  return {
    createdAt: x.createdAt,
    updatedAt: latestRevision?.updatedAt,
    lastPublishedAt: latestRevision?.publishedAt,
  }
}
