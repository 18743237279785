import {
  ActionIcon,
  ActionIconProps,
  ElementProps,
  Paper,
  SegmentedControl,
} from '@mantine/core'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  useDiagramContext,
} from '~/client/dashboard/stores/DiagramStore'
import { Box, Row } from '~/client/shared/Layout'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import classes from './Diagram.module.css'
import {DiagramDirection} from '~/client/dashboard/components/process/diagram/diagram-settings.tsx'
import { useDarkMode } from '~/client/dashboard/stores/GlobalStore'

const DiagramControlButton = (
  props: ElementProps<'button'> & ActionIconProps,
) => {
  const isDarkMode = useDarkMode()

  return (
    <ActionIcon
      color={isDarkMode ? 'dark.5' : 'light.1'}
      className={classes.diagramControls__actionIcon}
      {...props}
    />
  )
}

const DiagramDirectionToggle = () => {
  const vars = useThemeVars()
  const setDiagramDirection = useDiagramContext((x) => x.setDirection)
  const diagramDirection = useDiagramContext((x) => x.direction)

  return (
    <Paper style={{ pointerEvents: 'all' }}>
      <SegmentedControl
        value={diagramDirection}
        onChange={(x) => setDiagramDirection(x as DiagramDirection)}
        styles={{
          label: {
            padding: `${vars.spacing.xs} ${vars.spacing.sm}`,
          },
        }}
        data={[
          {
            value: DiagramDirection.Down,
            label: (
              <Row justify="center" gap={8}>
                <Icon name="PiArrowsVertical" />
              </Row>
            ),
          },
          {
            value: DiagramDirection.Right,
            label: (
              <Row justify="center" gap={8}>
                <Icon name="PiArrowsHorizontal" />
              </Row>
            ),
          },
        ]}
      />
    </Paper>
  )
}

export const DiagramControls = () => {
  const toggleDiagramFullscreen = useDiagramContext((x) => x.toggleFullscreen)
  const isDiagramFullscreen = useDiagramContext((x) => x.isFullscreen)

  return (
    <div className={classes.diagramControls}>
      <Row className={classes.diagramControls__bottom} justify="space-between">
        {/* Bottom Left */}
        <Box>
          {/* <DiagramDirectionToggle /> */}
        </Box>
        {/* Bottom Right */}
        <Box>
          <DiagramControlButton
            className={classes.diagramControls__actionIcon}
            onClick={() => void toggleDiagramFullscreen()}
          >
            {isDiagramFullscreen ? (
              <Icon name="PiArrowsInSimple" />
            ) : (
              <Icon name="PiArrowsOutSimple" />
            )}
          </DiagramControlButton>
        </Box>
      </Row>
    </div>
  )
}
