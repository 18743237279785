import {
  authorized,
  ModelCommandsValidation,
  ModelName,
  invalid,
  valueMatches,
  unauthorized,
  valid,
} from '~/commands/base-commands'
import {
  MemberStatus,
  OrganizationRole,
  OrganizationType,
} from '@prisma/client'

/**
 * Member commands validation
 */

export const organizationMemberValidation = {
  SetAsOwner: {
    validate({ target, meta }) {
      return valid()
    },
    authorize({ sender, target, meta }) {
      if (target.isOwner) {
        return invalid('Member is already owner')
      }
      if (target.status !== MemberStatus.Active) {
        return invalid('Organization owner must be an active user')
      }
      if (!target.hasUser) {
        return invalid('Member does not have an associated user')
      }
      if (meta.organizationType === OrganizationType.Personal) {
        return invalid('Cannot change ownership of personal organization')
      }
      if (!sender.isOwner) {
        return unauthorized(
          'Organization owner must be set by the current owner',
        )
      }

      return authorized()
    },
  },
  UpdateProfile: {
    validate({ sender, target, meta }) {
      if (
        target.isDisplayLockedChanged &&
        !valueMatches([OrganizationRole.Admin], sender.role)
      ) {
        return invalid(`Only organization admins can lock or unlock members`)
      }

      if (
        target.isDisplayLockedChanged &&
        valueMatches([OrganizationRole.Admin], target.role)
      ) {
        return invalid(`Organization admins cannot be locked`)
      }

      if (target.isDisplayLockedChanged && target.isSender) {
        return invalid(`Cannot lock own profile`)
      }

      return valid()
    },
    authorize({ sender, target, meta }) {
      if (
        !target.isSender &&
        !valueMatches([OrganizationRole.Admin], sender.role)
      ) {
        return unauthorized(`Only organization admins can modify other members`)
      }

      if (
        target.isSender &&
        target.isDisplayLocked &&
        !valueMatches([OrganizationRole.Admin], sender.role)
      ) {
        return unauthorized(
          `Your organization profile is locked. Speak with your administrator if you'd like to make changes.`,
        )
      }

      return authorized()
    },
  },
  SetStatus: {
    validate({ sender, target, meta }) {
      return valid()
    },
    authorize({ sender, target, meta }) {
      if (!valueMatches([OrganizationRole.Admin], sender.role)) {
        return unauthorized(
          `Only organization admins can change a member's status`,
        )
      }

      if (target.isSender) {
        return unauthorized(`Cannot deactivate self`)
      }

      if (target.isOwner) {
        return unauthorized(`Cannot deactivate organization owner`)
      }

      if (target.status === MemberStatus.Exited) {
        return unauthorized(
          `Cannot change status of a member that has exited the organization`,
        )
      }

      return authorized()
    },
  },
  SetRole: {
    validate({ sender, target, meta }) {
      return valid()
    },
    authorize({ sender, target, meta }) {
      if (!valueMatches([OrganizationRole.Admin], sender.role)) {
        return unauthorized(
          `Only organization admins can change a member's role`,
        )
      }

      if (target.isSender) {
        return unauthorized(`Cannot change your own role`)
      }

      if (target.isOwner) {
        return unauthorized(`Cannot change organization owner's role`)
      }

      return authorized()
    },
  },
  Leave: {
    validate({ sender, target, meta }) {
      return valid()
    },
    authorize({ sender, target, meta }) {
      if (meta.organizationType === OrganizationType.Personal) {
        return unauthorized(`Cannot leave personal organization`)
      }

      if (!target.isSender) {
        return unauthorized(
          `Cannot leave organization on behalf of another user`,
        )
      }

      if (target.isOwner) {
        return unauthorized(`Organization owner cannot leave organization`)
      }

      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.OrganizationMember>
