'use client'
import {
  Portal as MantinePortal,
  OptionalPortal as MantineOptionalPortal,
} from '@mantine/core'
import React, { PropsWithChildren } from 'react'
import { Box, BoxProps } from '~/client/shared/Layout'

export enum PortalTargetId {
  NavBar,
  Header,
  SettingsTitleRight,
  OrgHeaderRight,
}

const getPortalId = (target: PortalTargetId) => 'portal-target_' + target

export const PortalTarget = ({
  id,
  children,
  ...props
}: Omit<BoxProps, 'id'> & { id: PortalTargetId }) => {
  return (
    <Box id={getPortalId(id)} {...props}>
      {children}
    </Box>
  )
}

export const Portal = ({
  target,
  children,
}: PropsWithChildren<{ target: PortalTargetId }>) => {
  return (
    <MantinePortal target={'#' + getPortalId(target)}>{children}</MantinePortal>
  )
}

export const OptionalPortal = ({
  target,
  children,
  withinPortal,
}: PropsWithChildren<{ target: PortalTargetId; withinPortal: boolean }>) => {
  return (
    <MantineOptionalPortal
      withinPortal={withinPortal}
      target={'#' + getPortalId(target)}
    >
      {children}
    </MantineOptionalPortal>
  )
}
