import { cacheOrganizationInvitations } from '~/client/dashboard/queries/organization-queries'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CacheRemoveFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { PartialRequired } from '~/client/globals'
import {
  OrganizationInvitationQueryKey,
  organizationInvitationQueryKeys,
} from '~/schemas/invitation-schema'
import { api } from '~/utils/api'
import { isMatch } from '~/utils/logic'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { ModelName } from '~/commands/base-commands'

export const useInvitationQuery = (
  key: PartialRequired<OrganizationInvitationQueryKey>,
) => {
  const enabled = OrganizationInvitationQueryKey.safeParse(key).success
  const result = api.Invitation.get.useQuery(
    parseKey(key, OrganizationInvitationQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )
  const invitation = result?.data ?? null

  useCacheResult(result, cacheInvitation, () => key, false)

  return {
    ...result,
    invitation,
    isLoadingInvitation: result.isPending && enabled,
    isLoadingInvitationError: Boolean(result?.isError),
  }
}

export const useInvitationRedemptionsQuery = (
  key: PartialRequired<OrganizationInvitationQueryKey>,
) => {
  const result = api.Invitation.getRedemptions.useQuery(
    parseKey(key, OrganizationInvitationQueryKey),
    {
      enabled: OrganizationInvitationQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheInvitationRedemptions, () => key, false)

  return { ...result, invitationRedemptions: result.data ?? [] }
}

/**
 * Cache get
 */

export const getInvitationFromCache = (
  ctx: ApiContext,
  key: OrganizationInvitationQueryKey,
) => {
  key = OrganizationInvitationQueryKey.parse(key)
  return ctx.Invitation.get.getData(key)
}

export const getInvitationRedemptionsFromCache = (
  ctx: ApiContext,
  key: OrganizationInvitationQueryKey,
) => {
  key = OrganizationInvitationQueryKey.parse(key)
  return ctx.Invitation.getRedemptions.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheInvitation: CacheFn<
  ModelQueryKey['Invitation'],
  ClientModel['Invitation'],
  boolean
> = (ctx, data, key, itemOnly = false) => {
  key = OrganizationInvitationQueryKey.parse(key)
  const previous = getInvitationFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.Invitation.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(
      ctx.Invitation.get,
      organizationInvitationQueryKeys,
      result,
      result,
    )

    if (!itemOnly) {
      cacheOrganizationInvitations(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (x.id === result.id ? result : x))
          }
        },
        { id: key.organizationId },
        true,
      )
    }
  }

  return {
    previous,
    revert: () => {
      cacheInvitation(ctx, previous, key, itemOnly)
    },
  }
}

export const cacheInvitationRedemptions: CacheFn<
  ModelQueryKey['Invitation'],
  ClientModel['InvitationRedemption'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = OrganizationInvitationQueryKey.parse(key)
  const previous = getInvitationRedemptionsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const invitation = getInvitationFromCache(ctx, key)
    cacheEachKey(
      ctx.Invitation.getRedemptions,
      organizationInvitationQueryKeys,
      invitation,
      result,
    )
    if (!listOnly) {
      // result.forEach((x) => {
      //   cacheInvitationRedemption(ctx, x, x, true)
      // })
    }
  }

  return {
    previous,
    revert: () => {
      cacheInvitationRedemptions(ctx, previous, key, listOnly)
    },
  }
}

/**
 * Cache remove
 */

export const removeInvitationFromCache: CacheRemoveFn<
  ModelQueryKey['Invitation'],
  ClientModel['Invitation']
> = (ctx, key) => {
  key = OrganizationInvitationQueryKey.parse(key)
  const previous = getInvitationFromCache(ctx, key)

  if (previous) {
    cacheEachKey(
      ctx.Invitation.get,
      organizationInvitationQueryKeys,
      previous,
      null,
    )
  }

  const { revert } = cacheOrganizationInvitations(
    ctx,
    (old) => {
      if (!old) return
      return old.filter((x) => !isMatch(x, key))
    },
    { id: key.organizationId },
    true,
  )

  return {
    previous,
    revert,
  }
}
