import {
  Combobox,
  InputBase,
  Text,
  TextInput,
  Textarea,
  useCombobox,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { TeamAccess } from '@prisma/client'
import { z } from 'zod'
import { Column, Row } from '~/client/shared/Layout'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  EditFormProps,
  FormButton,
  FormModal,
  FormProps,
  ModalFormProps,
  NewFormProps,
} from '~/client/dashboard/forms/form-helpers'
import { useTeamQuery } from '~/client/dashboard/queries/team-queries'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { teamAccess } from '~/client/shared/data/team-data'
import {
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
  TeamQueryKey,
  TeamSchema,
} from '~/schemas/team-schema'
import classes from './Forms.module.css'
import {
  ImageInput,
  TeamLogo,
} from '~/client/dashboard/components/global/Prefabs'
import { ClientModel } from '~/schemas'

/**
 * Appearance
 */

const TeamFormSchema = z.object({
  id: TeamSchema.shape.id.optional(),
  name: z.string().min(1, 'Team name is required').pipe(TeamSchema.shape.name),
  description: TeamSchema.shape.description,
  imageUrl: TeamSchema.shape.imageUrl,
  access: TeamSchema.shape.access,
})

type Settings = {}
type TeamFormProps = FormProps<typeof TeamFormSchema, Settings>

export const TeamSettingsForm = ({ form, settings = {} }: TeamFormProps) => {
  const { initialValues, isUpdating, onSubmit, buttonText = 'Save' } = form
  const teamSettingsForm = useForm({
    validate: zodResolver(TeamFormSchema),
    initialValues,
  })

  return (
    <form
      // onSubmit={documentSettingsForm.onSubmit(onSubmit)}
      onSubmit={teamSettingsForm.onSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      <TextInput
        label="Name"
        data-autofocus
        maxLength={NAME_MAX_LENGTH}
        {...teamSettingsForm.getInputProps('name')}
      />
      <ImageInput
        label="Team Logo"
        currentImageUrl={teamSettingsForm.values.imageUrl}
        avatar={
          <TeamLogo size={30} imageUrl={teamSettingsForm.values.imageUrl} />
        }
        {...teamSettingsForm.getInputProps('imageUrl')}
      />
      <AccessSelect {...teamSettingsForm.getInputProps('access')} />
      <Textarea
        label="Description"
        maxLength={DESCRIPTION_MAX_LENGTH}
        {...teamSettingsForm.getInputProps('description')}
      />
      <FormButton mt="sm" loading={isUpdating}>
        {buttonText}
      </FormButton>
    </form>
  )
}

/**
 * New Team
 */

type NewTeamFormProps = NewFormProps<
  TeamFormProps,
  {
    organizationId: string
    onCreate?: (team: ClientModel['Team']) => void
  }
>

export const NewTeamForm = ({
  onSubmit,
  onCreate,
  settings = {},
  organizationId,
}: NewTeamFormProps) => {
  const { createTeamAsync, isCreatingTeam } = useCommand(
    ModelName.Organization,
    'CreateTeam',
    { id: organizationId },
  )

  return (
    <TeamSettingsForm
      settings={settings}
      form={{
        initialValues: {
          name: '',
          description: '',
          access: TeamAccess.Open,
          imageUrl: '',
        },
        buttonText: 'Create',
        isUpdating: isCreatingTeam,
        onSubmit(values) {
          onSubmit?.()
          void createTeamAsync({
            name: values.name,
            description: values.description,
            access: values.access,
            imageUrl: values.imageUrl,
          }).then(onCreate)
        },
      }}
    />
  )
}

export const NewTeamModal = ({
  organizationId,
  onCreate,
  ...props
}: ModalFormProps<NewTeamFormProps>) => {
  return (
    <FormModal {...props} title="New Team">
      <NewTeamForm
        onSubmit={props.onClose}
        onCreate={onCreate}
        organizationId={organizationId}
      />
    </FormModal>
  )
}

/**
 * Edit Team
 */

type EditTeamFormProps = EditFormProps<TeamFormProps, TeamQueryKey>

export const EditTeamForm = ({
  queryKey,
  settings = {},
  onSubmit,
}: EditTeamFormProps) => {
  const { team } = useTeamQuery(queryKey)
  const { updateTeamProfile, isPending: isUpdating } = useCommand(
    ModelName.Team,
    'UpdateProfile',
    queryKey,
  )

  if (!team) return null

  return (
    <TeamSettingsForm
      settings={settings}
      form={{
        initialValues: {
          id: team.id,
          name: team.name,
          description: team.description,
          imageUrl: team.imageUrl,
          access: team.access,
        },
        isUpdating,
        onSubmit(values) {
          updateTeamProfile({
            name: values.name,
            description: values.description,
            imageUrl: values.imageUrl,
            access: values.access,
          })
          onSubmit?.()
        },
      }}
    />
  )
}

export const EditTeamModal = ({
  settings,
  queryKey,
  ...props
}: ModalFormProps<EditTeamFormProps>) => {
  return (
    <FormModal {...props} title="Team Settings">
      <EditTeamForm
        onSubmit={props.onClose}
        settings={settings}
        queryKey={queryKey}
      />
    </FormModal>
  )
}

/**
 * Custom inputs
 */

const AccessSelect = ({
  value,
  onChange,
  error,
}: {
  value?: TeamAccess
  onChange: (value: TeamAccess) => void
  error?: string
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const Option = ({ access }: { access: TeamAccess }) => {
    const active = access === value

    return (
      <Combobox.Option value={access}>
        <Row justify="space-between">
          <OptionContent access={access} />
          {active && <Icon size={20} name="PiCheckBold" />}
        </Row>
      </Combobox.Option>
    )
  }

  const OptionContent = ({ access }: { access: TeamAccess }) => {
    return (
      <Row gap="sm">
        <Icon size={20} nudgeUp={2} name={teamAccess[access].iconName} />
        <Column gap={2} px="sm" py="xs">
          <Text fz="md" fw={600} data-important>
            {access}
          </Text>
          <Text fz="sm">{teamAccess[access].description}</Text>
        </Column>
      </Row>
    )
  }

  return (
    <Combobox
      store={combobox}
      offset={0}
      onOptionSubmit={(val) => {
        onChange(val as TeamAccess)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          size="md"
          label="Privacy"
          component="button"
          error={error}
          pointer
          rightSection={
            <Row gap="xs" justify="flex-end" w="100%" pr="sm">
              <Icon name="PiCaretDownBold" />
            </Row>
          }
          onClick={(e) => {
            e.preventDefault()
            combobox.toggleDropdown()
          }}
          rightSectionPointerEvents="none"
          styles={{
            section: {
              width: rem(60),
            },
            input: {
              height: 56,
            },
          }}
        >
          <OptionContent access={value!} />
        </InputBase>
      </Combobox.Target>
      <Combobox.Dropdown className={classes.dropdown}>
        <Combobox.Options>
          <Option access={TeamAccess.Open} />
          <Option access={TeamAccess.Managed} />
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
