import { TeamAccess, TeamRole } from '@prisma/client'
import {
  ModelName,
  ModelCommandsValidation,
  authorized,
  unauthorized,
  valid,
  valueMatches,
} from '~/commands/base-commands'

/**
 * Team commands validation
 */

export const teamValidation = {
  UpdateProfile: {
    validate({ target, sender, meta }) {
      return valid()
    },
    authorize({ target, sender, meta }) {
      if (!sender.isJoined) {
        return unauthorized(`Only members can change this team's settings`)
      }

      if (valueMatches([TeamAccess.Managed], target.access)) {
        if (!valueMatches([TeamRole.Member], sender.role)) {
          return unauthorized(`Only owners can change this team's settings`)
        }
      }

      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.Team>
