import { DocumentPrivacy } from '@prisma/client'
import { z } from 'zod'
import { PrismaModelSchema } from '~/schemas/schema-helpers'
import { replaceNewLine } from '~/utils/logic'

/**
 * Constants
 */

export const NAME_MAX_LENGTH = 80
export const DESCRIPTION_MAX_LENGTH = 200

/**
 * Fields
 */

const titleSchema = z
  .string()
  .trim()
  .min(1, 'Name is required')
  .transform(replaceNewLine)
  .pipe(
    z
      .string()
      .max(
        NAME_MAX_LENGTH,
        `Name must be less than ${NAME_MAX_LENGTH} characters`,
      ),
  )

const descriptionSchema = z
  .string()
  .max(
    DESCRIPTION_MAX_LENGTH,
    `Description must be less than ${DESCRIPTION_MAX_LENGTH} characters`,
  )

/**
 * Document schemas
 */

export type DocumentSchema = z.infer<typeof DocumentSchema>
export const DocumentSchema = z.object({
  id: z.string().readonly(),
  organizationId: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  parentId: z.string().readonly().nullable(),
  creatorId: z.string().readonly(),
  isArchived: z.boolean(),
  // permissions: z.nativeEnum(DocumentPermissions),
  privacy: z.nativeEnum(DocumentPrivacy),
  slug: z.string(),
  title: titleSchema,
  description: descriptionSchema.nullable(),
}) satisfies PrismaModelSchema<'Document'>

export type ClientDocumentSchema = z.infer<typeof ClientDocumentSchema>
export const ClientDocumentSchema = DocumentSchema.merge(
  z.object({
    type: z.literal('Category'),
  }),
)

const DocumentQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    slug: z.string(),
    organizationId: z.string(),
  }),
])
export const documentQueryKeys = DocumentQueryKeys.items

export const DocumentQueryKey = z.union(DocumentQueryKeys.items)
export type DocumentQueryKey = z.infer<typeof DocumentQueryKey>
