import { SetNonNullable } from 'type-fest'
import { z, ZodError } from 'zod'
import { fromZodError } from 'zod-validation-error'
import { ApiContext } from '~/client/dashboard/queries/helpers/query-helpers'
import { toast } from '~/client/shared/Toast'
import {
  CommandInput,
  CommandOutput,
  CommandParameters,
  CommandParams,
  Commands,
  CommandSchemaMessages,
  commandValidation,
  CommandValidationContext,
  error,
  getCommand,
  getCommandInputSchema,
  invalid,
  ModelName,
  parseCommandInputSchema,
  ValidationResult,
} from '~/commands/base-commands'
import {
  documentMemberCommandContext,
  documentMemberCommandsClient,
  useDocumentMemberModelCtx,
} from '~/commands/document-member/document-member-commands-client'
import {
  documentCommandContext,
  documentCommandsClient,
  useDocumentModelCtx,
} from '~/commands/document/document-commands-client'
import { invitationRedemptionCommandContext, invitationRedemptionCommandsClient, useInvitationRedemptionModelCtx } from '~/commands/invitation-redemption/invitation-redemption-commands-client'
import {
  invitationCommandContext,
  invitationCommandsClient,
  useInvitationModelCtx,
} from '~/commands/invitation/invitation-commands-client'
import { nodeDetailCommandContext, nodeDetailCommandsClient, useNodeDetailModelCtx } from '~/commands/node-detail/node-detail-commands-client'
import {
  organizationMemberCommandContext,
  organizationMemberCommandsClient,
  useOrganizationMemberModelCtx,
} from '~/commands/organization-member/organization-member-commands-client'
import {
  organizationCommandContext,
  organizationCommandsClient,
  useOrganizationModelCtx,
} from '~/commands/organization/organization-commands-client'
import {
  processMemberCommandContext,
  processMemberCommandsClient,
  useProcessMemberModelCtx,
} from '~/commands/process-member/process-member-commands-client'
import {
  processCommandContext,
  processCommandsClient,
  useProcessModelCtx,
} from '~/commands/process/process-commands-client'
import {
  revisionCommandContext,
  revisionCommandsClient,
  useRevisionModelCtx,
} from '~/commands/revision/revision-commands-client'
import {
  teamMemberCommandContext,
  teamMemberCommandsClient,
  useTeamMemberModelCtx,
} from '~/commands/team-member/team-member-commands-client'
import {
  teamCommandContext,
  teamCommandsClient,
  useTeamModelCtx,
} from '~/commands/team/team-commands-client'
import {
  userCommandContext,
  userCommandsClient,
  useUserModelCtx,
} from '~/commands/user/user-commands-client'
import { ModelQueryKey, ModelQueryKeyShape } from '~/schemas'
import { api } from '~/utils/api'
import { every } from '~/utils/logic'

export const clientCommands = {
  [ModelName.User]: userCommandsClient,
  [ModelName.Organization]: organizationCommandsClient,
  [ModelName.OrganizationMember]: organizationMemberCommandsClient,
  [ModelName.Team]: teamCommandsClient,
  [ModelName.TeamMember]: teamMemberCommandsClient,
  [ModelName.Document]: documentCommandsClient,
  [ModelName.DocumentMember]: documentMemberCommandsClient,
  [ModelName.Process]: processCommandsClient,
  [ModelName.ProcessMember]: processMemberCommandsClient,
  [ModelName.Revision]: revisionCommandsClient,
  [ModelName.NodeDetail]: nodeDetailCommandsClient,
  [ModelName.Invitation]: invitationCommandsClient,
  [ModelName.InvitationRedemption]: invitationRedemptionCommandsClient,
} satisfies ClientCommandDefinitionsAll

export type ClientCommands = typeof clientCommands

export const clientModelContexts = {
  [ModelName.User]: useUserModelCtx,
  [ModelName.Organization]: useOrganizationModelCtx,
  [ModelName.OrganizationMember]: useOrganizationMemberModelCtx,
  [ModelName.Team]: useTeamModelCtx,
  [ModelName.TeamMember]: useTeamMemberModelCtx,
  [ModelName.Document]: useDocumentModelCtx,
  [ModelName.DocumentMember]: useDocumentMemberModelCtx,
  [ModelName.Process]: useProcessModelCtx,
  [ModelName.ProcessMember]: useProcessMemberModelCtx,
  [ModelName.Revision]: useRevisionModelCtx,
  [ModelName.NodeDetail]: useNodeDetailModelCtx,
  [ModelName.Invitation]: useInvitationModelCtx,
  [ModelName.InvitationRedemption]: useInvitationRedemptionModelCtx,
} satisfies {
  [Model in ModelName]: Function
}

type ModelContextDefinitions = typeof clientModelContexts

export const clientCommandContexts = {
  [ModelName.User]: userCommandContext,
  [ModelName.Organization]: organizationCommandContext,
  [ModelName.OrganizationMember]: organizationMemberCommandContext,
  [ModelName.Team]: teamCommandContext,
  [ModelName.TeamMember]: teamMemberCommandContext,
  [ModelName.Document]: documentCommandContext,
  [ModelName.DocumentMember]: documentMemberCommandContext,
  [ModelName.Process]: processCommandContext,
  [ModelName.ProcessMember]: processMemberCommandContext,
  [ModelName.Revision]: revisionCommandContext,
  [ModelName.NodeDetail]: nodeDetailCommandContext,
  [ModelName.Invitation]: invitationCommandContext,
  [ModelName.InvitationRedemption]: invitationRedemptionCommandContext,
} satisfies {
  [Model in ModelName]: ClientCommandContextDefinitions<Model>
}

type ClientCommandContexts = typeof clientCommandContexts

export const useCommand = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends z.infer<ModelQueryKeyShape[Model]> | undefined = undefined,
  Params extends Partial<CommandParams<Model, Command>> | undefined = undefined,
>(
  model: Model,
  command: Command,
  key?: Key,
  params?: Params,
) => {
  const authorization = useCommandAuthorization(
    model,
    command,
    // @ts-ignore
    { key: key, params: params ?? {} },
  )

  const commandSchema = getCommand(model, command)

  // const modelCommandsDefinition = clientCommands[
  //   model
  // ] as ClientCommandDefinitions<Model>
  // const commandDefinition = modelCommandsDefinition[
  //   command
  // ] as ClientCommandDefinition<Model, Command>

  // @ts-ignore
  const { messages = {} } = commandSchema
  // @ts-ignore
  const { onMutate, onSuccess } = clientCommands[model][command]

  const commandResult = useCommandMutation(model, command, {
    onMutate,
    onSuccess,
    messages,
  })

  const executor = (isAsync?: boolean) =>
    key
      ? (input = {}) =>
          // @ts-ignore
          commandResult[isAsync ? 'mutateAsync' : 'mutate']({
            key,
            params: {
              ...(params ?? {}),
              ...input,
            },
          })
      : (input: CommandInput<Model, Command>) =>
          // @ts-ignore
          commandResult[isAsync ? 'mutateAsync' : 'mutate']({
            key: input?.key,
            params: {
              ...(params ?? {}),
              // @ts-ignore
              ...(input?.params ?? {}),
            },
          })

  type RequiredInput = Key extends undefined
    ? {
        key: z.infer<ModelQueryKeyShape[Model]>
        params: Omit<CommandParameters<Model, Command>, keyof Params>
      }
    : Omit<CommandParameters<Model, Command>, keyof Params>

  // @ts-ignore
  return {
    ...authorization,
    ...commandResult,
    // @ts-ignore
    [commandSchema.variables.execute]: executor(false),
    // @ts-ignore
    [commandSchema.variables.execute + 'Async']: executor(true) as any,
    // @ts-ignore
    [commandSchema.variables.isPending]: commandResult.isPending,
  } as { isPending: boolean } & AuthorizationResult<Model, Command> & {
      // @ts-ignore
      [name in (typeof commandSchema)['variables']['isPending']]: boolean
    } & {
      // @ts-ignore
      [name in (typeof commandSchema)['variables']['execute']]: (
        input: RequiredInput,
      ) => void
    } & {
      // @ts-ignore
      [name in `${(typeof commandSchema)['variables']['execute']}Async`]: (
        input: RequiredInput,
      ) => Promise<CommandOutput<Model, Command>>
    }
}

export type AuthorizationResult<
  Model extends ModelName,
  Command extends keyof Commands[Model],
> = ValidationResult & {
  // @ts-ignore
  validationCtx?: CommandValidationContext<
    // @ts-ignore
    Commands[Model][Command]['schemas']['validate']
  >
  // @ts-ignore
  authorizationCtx?: CommandValidationContext<
    // @ts-ignore
    Commands[Model][Command]['schemas']['authorize']
  >
}

export const useCommandAuthorization = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
>(
  model: Model,
  name: Command,
  input: CommandInput<Model, Command>,
): AuthorizationResult<Model, Command> => {
  const schema = getCommandInputSchema(model, name)

  // @ts-ignore
  const modelCtx = clientModelContexts[model](input.key ?? {})
  const commandCtx =
    // @ts-ignore
    clientCommandContexts[model][name]({ input, ...modelCtx }) ?? {}

  const parsedKeyResult = schema.shape.key.safeParse(input.key)
  if (!parsedKeyResult.success) {
    return error(fromZodError(parsedKeyResult.error as ZodError).message)
  }

  if (modelCtx.state.isLoading) return invalid('Loading permissions...')
  if (modelCtx.state.failed) return error('Failed to load model context')

  if (commandCtx.state?.isLoading) return invalid('Loading permissions...')
  if (commandCtx.state?.failed) return error('Failed to load command context')

  // Ensure all values in CTX are defined and not null
  const allCtxDefined = every(
    {
      target: modelCtx.target,
      sender: modelCtx.sender,
      ...(modelCtx.ctx ?? {}),
    },
    (x) => x !== undefined && x !== null,
  )
  if (!allCtxDefined) return error('Command is missing context')

  let validationCtx
  // @ts-ignore
  if (input.params) {
    // @ts-ignore
    validationCtx = clientCommands[model][name].getValidationContext({
      input,
      ...modelCtx,
      commandCtx: commandCtx.ctx,
    })
    if (validationCtx.failed) return error('Failed to load')

    // @ts-ignore
    const validationResult = commandValidation[model][name].validate({
      ...validationCtx,
      input,
    }) as ValidationResult
    if (!validationResult.valid) return { ...validationResult, validationCtx }
  }

  // @ts-ignore
  if (schema.shape.params) {
    // Partial validation if skipping (only test params passed in)
    // @ts-ignore
    const paramParser = schema.shape.params.partial()
    // @ts-ignore
    const parsedParamsResult = paramParser.safeParse(input.params)
    if (!parsedParamsResult.success) {
      return error(fromZodError(parsedParamsResult.error as ZodError).message)
    }
  }

  const authorizationCtx =
    // @ts-ignore
    clientCommands[model][name].getAuthorizationContext({
      input,
      ...modelCtx,
      commandCtx: commandCtx.ctx,
    })

  // @ts-ignore
  const authorizationResult = commandValidation[model][name].authorize(
    authorizationCtx,
  ) as ValidationResult
  return { ...authorizationResult, validationCtx, authorizationCtx }
}

export type ClientCommandDefinitionsAll = {
  [Model in ModelName]: ClientCommandDefinitions<Model>
}

export type ClientCommandDefinitions<Model extends ModelName> = {
  [Command in keyof Commands[Model]]: ClientCommandDefinition<Model, Command>
}

export type ClientModelContext<Model extends ModelName> = Omit<
  SetNonNullable<ReturnType<ModelContextDefinitions[Model]>>,
  'ctx' | 'state'
> & {
  ctx: SetNonNullable<ReturnType<ModelContextDefinitions[Model]>['ctx']>
}

export type ClientCommandContext<
  Model extends ModelName,
  Command extends keyof Commands[Model],
  // @ts-ignore
> = ReturnType<ClientCommandContexts[Model][Command]>['ctx']

export type ClientCommandDefinition<
  Model extends ModelName,
  Command extends keyof Commands[Model],
> = {
  getValidationContext: (
    context: {
      input: CommandInput<Model, Command, true>
    } & ClientModelContext<Model> & {
        commandCtx: ClientCommandContext<Model, Command>
      },
  ) => CommandValidationContext<
    // @ts-ignore
    Commands[Model][Command]['schemas']['validate']
  >
  getAuthorizationContext: (
    context: {
      input: CommandInput<Model, Command>
    } & ClientModelContext<Model> & {
        commandCtx: ClientCommandContext<Model, Command>
      },
  ) => CommandValidationContext<
    // @ts-ignore
    Commands[Model][Command]['schemas']['authorize']
  >
} & (
  | {
      onMutate: CommandMutationHandler<Model, Command>[]
      onSuccess?: CommandSuccessHandler<Model, Command>[]
    }
  | {
      onMutate?: CommandMutationHandler<Model, Command>[]
      onSuccess: CommandSuccessHandler<Model, Command>[]
    }
)

export type ClientCommandContextDefinitions<Model extends ModelName> = {
  [Command in keyof Commands[Model]]: (
    ctx: {
      input: CommandInput<Model, Command, true>
    } & ReturnType<ModelContextDefinitions[Model]>,
  ) => void | {
    state: {
      isLoading: boolean
      failed: boolean
    }
    ctx: {
      [prop: string]: null | undefined | object | string | number | boolean
    }
  }
}

export type ClientModelContextDefinition<Model extends ModelName> = (
  ctx: z.infer<ModelQueryKeyShape[Model]>,
) => {
  target: unknown
  sender: {
    [prop: `${string}Profile`]: unknown
  }
  state: {
    isLoading: boolean
    failed: boolean
  }
  ctx: { [prop: string]: null | undefined | object }
}

type CommandMutationHandler<
  Model extends ModelName,
  Command extends keyof Commands[Model],
> = (
  ctx: ApiContext,
  input: CommandInput<Model, Command>,
) => void | {
  revert: () => void
}

type CommandSuccessHandler<
  Model extends ModelName,
  Command extends keyof Commands[Model],
> = (
  ctx: ApiContext,
  result: CommandOutput<Model, Command>,
  input: CommandInput<Model, Command>,
) => {
  revert: () => void
}

export const useCommandMutation = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
>(
  model: Model,
  command: Command,
  options: {
    onMutate?: CommandMutationHandler<Model, Command>[]
    onSuccess?: CommandSuccessHandler<Model, Command>[]
    messages?: CommandSchemaMessages
  },
) => {
  const ctx = api.useUtils()
  const { onMutate = [], onSuccess = [], messages = {} } = options

  // @ts-ignore
  const result = api[model][command].useMutation({
    onMutate(input: CommandInput<Model, Command>) {
      // Validate input
      // @ts-ignore
      input = parseCommandInputSchema(model, command, input)

      return {
        toast: messages.processing
          ? toast.processing(messages.processing)
          : null,
        mutateCacheFns: onMutate.map((cb) => cb(ctx, input)),
      }
    },
    onSuccess(
      result: CommandOutput<Model, Command>,
      input: CommandInput<Model, Command>,
      context?: any,
    ) {
      onSuccess.forEach((cb) => cb(ctx, result, input))

      if (messages.success) {
        if (context?.toast) {
          context.toast.onSuccess(messages.success)
        } else {
          toast.success(messages.success)
        }
      } else if (context?.toast) {
        context.toast.hide()
      }
    },
    onError(err: Error, input: CommandInput<Model, Command>, context?: any) {
      context.mutateCacheFns?.forEach((x: any) => {
        x?.revert()
      })

      if (messages.failure) {
        if (context?.toast) {
          context.toast.onFailure(messages.failure)
        } else {
          toast.failure(messages.failure)
        }
      } else if (context?.toast) {
        context.toast.hide()
      }
    },
  })

  return result as CommandResult<Model, Command>
}

type CommandResult<
  Model extends ModelName,
  Command extends keyof Commands[Model],
> = {
  isPending: boolean
  mutate: (input: CommandInput<Model, Command>) => void
  mutateAsync: (
    input: CommandInput<Model, Command>,
  ) => Promise<CommandOutput<Model, Command>>
}
