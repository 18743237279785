import {
  Accordion,
  ActionIcon,
  Avatar,
  Divider,
  Pill,
  rem,
  Text,
  TextProps,
  Tooltip,
} from '@mantine/core'
import dayjs from 'dayjs'
import { ReactNode, useState } from 'react'
import { Link } from 'wouter'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { MemberAvatar } from '~/client/dashboard/components/global/Prefabs'
import { ProcessIcon } from '~/client/dashboard/components/process/ProcessComponents'
import {
  useProcessQuery,
  useProcessRevisionsQuery,
} from '~/client/dashboard/queries/process-queries'
import { useRevisionQuery } from '~/client/dashboard/queries/revision-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { useProcessVersionsQuery } from '~/client/dashboard/stores/ProcessStore'
import { RouteMap } from '~/client/RouteMap.ts'
import { Box, Column, Row } from '~/client/shared/Layout'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { uniq } from '~/utils/logic'
import { getProcessDateInfo } from '~/utils/model-logic'
import classes from './Process.module.css'
import { useGlobalContext, useDeviceSize, useDarkMode } from '~/client/dashboard/stores/GlobalStore'

const MAX_AVATARS = 3

export const ProcessesAccordion = ({
  processIds,
  draftsOnly = false,
}: {
  processIds: string[]
  draftsOnly?: boolean
}) => {
  const organizationId = useOrganizationContext((x) => x.id)
  const [open, setOpen] = useState<string[]>([])

  return (
    <Accordion
      chevronPosition="left"
      chevronSize={24}
      variant="separated"
      multiple={true}
      value={open}
      chevron={null}
      classNames={{
        chevron: classes.processAccordion__chevron,
      }}
    >
      {processIds.map((id) => (
        <ProcessAccordionItem
          key={id}
          processId={id}
          organizationId={organizationId}
          draftsOnly={draftsOnly}
          toggleOpen={() => {
            if (open.includes(id)) {
              setOpen(open.filter((x) => x !== id))
            } else {
              setOpen([...open, id])
            }
          }}
        />
      ))}
    </Accordion>
  )
}

export const ProcessAccordionItem = ({
  organizationId,
  processId,
  toggleOpen,
  draftsOnly = false,
}: {
  organizationId: string
  processId: string
  toggleOpen: () => void
  draftsOnly?: boolean
}) => {
  const { process } = useProcessQuery({
    id: processId,
    organizationId,
  })
  const { revisions } = useProcessRevisionsQuery({
    id: processId,
    organizationId,
  })

  if (!process) return null

  if (process.mainRevisionId) {
    const id = process.mainRevisionId
    return (
      <Accordion.Item
        className={classes.processAccordion__item}
        value={processId}
      >
        <Accordion.Control
          className={classes.processAccordion__control}
          onClick={toggleOpen}
        >
          <Row w="100%" justify="stretch">
            <Box grow={0}>
              <ActionIcon
                component={Box}
                mx="sm"
                w={24}
                variant="subtle"
                radius="sm"
                size={24}
                tabIndex={-1}
              >
                <Box className={classes.processAccordion__icon}>
                  <Icon name="PiCaretRightBold" nudgeUp={1} size={14} />
                </Box>
              </ActionIcon>
            </Box>
            <ProcessItemPublished
              id={processId}
              organizationId={organizationId}
              mainRevisionId={id}
            />
          </Row>
        </Accordion.Control>
        <Accordion.Panel>
          <ProcessItemContent
            queryKey={{
              id: processId,
              organizationId,
            }}
            mainRevisionId={id}
            showPublished={!draftsOnly}
          />
        </Accordion.Panel>
      </Accordion.Item>
    )
  } else if (revisions[0]) {
    return (
      <Accordion.Item
        value={processId}
        className={classes.processAccordion__item}
      >
        <Accordion.Control
          px="md"
          className={classes.processAccordion__control}
        >
          <Row w="100%" justify="stretch">
            <ProcessItemDraft queryKey={revisions[0]} avatarSize={26} />
          </Row>
        </Accordion.Control>
        {/* <Accordion.Panel>
          <ProcessItemContent
            queryKey={{
              id: processId,
              organizationId,
            }}
          />
        </Accordion.Panel> */}
      </Accordion.Item>
    )
  } else {
    return null
  }
}

export const ProcessItemPublished = ({
  id,
  organizationId,
  mainRevisionId,
}: {
  id: string
  organizationId: string
  mainRevisionId: string
}) => {
  const deviceSize = useGlobalContext(x => x.deviceSize)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const { process } = useProcessQuery({
    id,
    organizationId,
  })
  const { revision } = useRevisionQuery({
    id: mainRevisionId,
    processId: id,
    organizationId,
  })
  const { revisions } = useProcessRevisionsQuery({
    id,
    organizationId,
  })

  if (!process || !revision) return null

  const memberIds = uniq(
    process.revisionHistory
      .sort(
        (d1, d2) =>
          new Date(d1.publishedAt).getTime() -
          new Date(d2.publishedAt).getTime(),
      )
      .map(
        (x) =>
          revisions.find((revision) => x.revisionId === revision.id)?.creatorId,
      )
      .filter((x): x is string => Boolean(x)),
  )

  const date = getProcessDateInfo(process)

  return (
    <Column w="100%" className={classes.processItem}>
      <Row justify="stretch" pos="relative">
        <Row py={0} w="100%" lh="1.2" gap="md" justify="space-between">
          {/* Left */}
          <Column gap="sm">
            <Row shrink={1} grow={1}>
              <ProcessItemTitle
                href={RouteMap.Process(organizationSlug, process.slug)}
                icon={
                  <ProcessIcon id={process.id} revisionId={mainRevisionId} />
                }
                fz={deviceSize.isSmall ? rem(14) : rem(16)}
              >
                {revision.title}
              </ProcessItemTitle>
            </Row>
          </Column>
          {/* Right */}
          <Row shrink={0} grow={0} gap="md">
            <Avatar.Group>
              {memberIds.slice(0, MAX_AVATARS).map((x) => (
                <MemberAvatar key={x} glance={true} id={x} size={26} />
              ))}
              {memberIds.length > MAX_AVATARS && (
                <Avatar radius="xl" size={26}>
                  +{memberIds.length - MAX_AVATARS}
                </Avatar>
              )}
            </Avatar.Group>
            <ProcessItemDate date={date} />
            {/* <Box w="100%" grow={1} justify="flex-end">
                <Text size="sm" ta="start" w={74}>{`${item.views} view${
                  item.views === 1 ? '' : 's'
                }`}</Text>
              </Box> */}
          </Row>
        </Row>
        {/* <Row py={0} ml="auto" pos="absolute" right={10}>
        <DropdownMenu
          store={combobox}
          position="bottom-end"
          target={
            <ActionIcon
              variant="subtle"
              pos="relative"
              size={36}
              onClick={() => combobox.toggleDropdown()}
              color="gray"
              radius="50%"
              style={{
                color: Color.theme(['gray', 6], ['dark', 0], true),
              }}
            >
              <Icon name={globalIcons.MenuDots} size={30} />
            </ActionIcon>
          }
        >
          <DropdownOption
            icon="MdOpenInNew"
            href={RouteMap.Process(organizationSlug, process.slug)}
            linkTarget="_blank"
          >
            Open in new tab
          </DropdownOption>
        </DropdownMenu>
      </Row> */}
      </Row>
    </Column>
  )
}

export const ProcessItemDraft = ({
  queryKey,
  parentVersion,
  mainRevisionId,
  avatarSize,
}: {
  queryKey: ModelQueryKey['Revision']
  parentVersion?: string
  mainRevisionId?: string
  avatarSize?: number
}) => {
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const { process } = useProcessQuery({
    id: queryKey.processId,
    organizationId: queryKey.organizationId,
  })
  const { revision } = useRevisionQuery(queryKey)

  if (!revision || !process) return null

  return (
    <Row key={revision.id} justify="space-between" w="100%">
      <Column align="flex-start">
        <Row style={{ position: 'relative' }}>
          {mainRevisionId && mainRevisionId !== revision.parentRevisionId && (
            <Tooltip label="This draft is out of date">
              <Box
                c="yellow.4"
                mr={2}
                style={{ position: 'absolute', left: -22 }}
              >
                <Icon size="1.2rem" name="Warning" />
              </Box>
            </Tooltip>
          )}
          <ProcessItemTitle
            href={RouteMap.Process(organizationSlug, process.slug, revision.id)}
            icon={
              parentVersion ? (
                <Row gap={0}>
                  <Pill
                    size="xs"
                    bg={isDarkMode ? 'dark.4' : 'gray.3'}
                    styles={{
                      label: {
                        textDecoration: 'none',
                      },
                    }}
                  >
                    {parentVersion}
                  </Pill>
                </Row>
              ) : (
                <ProcessIcon id={revision.processId} revisionId={revision.id} />
              )
            }
            fz={deviceSize.isSmall ? rem(14) : rem(16)}
          >
            <Row gap="sm">
              <Box>{revision.title}</Box>
            </Row>
          </ProcessItemTitle>
        </Row>
      </Column>
      <Row shrink={0} grow={0} gap="md">
        <MemberAvatar
          glance={true}
          id={revision.creatorId}
          size={avatarSize ?? 20}
        />
        <ProcessItemDate date={revision} />
      </Row>
    </Row>
  )
}

export const ProcessItemContent = ({
  queryKey,
  showDrafts = true,
  showPublished = true,
  mainRevisionId,
}: {
  queryKey: ModelQueryKey['Process']
  showDrafts?: boolean
  showPublished?: boolean
  mainRevisionId?: string
}) => {
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const { drafts, published, isLoading } = useProcessVersionsQuery(queryKey)
  const { process } = useProcessQuery(queryKey)

  if (!process) return null

  return (
    <Column pl={28}>
      {/* <Divider mb="xs" label="Revisions" labelPosition="center" /> */}
      {showPublished && published.length > 0 && (
        <Column gap="xs" pl={32}>
          {published.map((x) => (
            <Row key={x.revision.id} justify="space-between">
              <ProcessItemTitle
                href={RouteMap.Process(
                  organizationSlug,
                  process.slug,
                  x.revision.id,
                )}
                c={isDarkMode ? 'white' : 'black'}
                fz={deviceSize.isSmall ? rem(11) : rem(13)}
                fw={700}
              >
                {x.displayName}
              </ProcessItemTitle>
              <Row shrink={0} grow={0} gap="md">
                <MemberAvatar
                  glance={true}
                  id={x.revision.creatorId}
                  size={20}
                />
                <ProcessItemDate date={x.publication} />
              </Row>
            </Row>
          ))}
        </Column>
      )}
      {showDrafts && drafts.length > 0 && (
        <>
          {showPublished && (
            <Divider my="md" label="Drafts" labelPosition="center" />
          )}
          <Column gap="xs">
            {drafts.map((x) => (
              <ProcessItemDraft
                key={x.revision.id}
                queryKey={x.revision}
                parentVersion={x.parent?.displayName}
                mainRevisionId={mainRevisionId}
              />
            ))}
          </Column>
        </>
      )}
    </Column>
  )
}

type ProcessItemDate = {
  createdAt: Date
  updatedAt: Date
  lastPublishedAt?: Date
}

const ProcessItemDate = ({ date }: { date: ProcessItemDate }) => {
  return (
    <Tooltip
      label={
        <Column>
          <Text c="dimmed" fz="sm" fw={500}>
            Updated
          </Text>
          <Text className={classes.processItem__date} fz="sm" fw={500}>
            {dayjs(date.updatedAt).format('MMMM D, YYYY h:mm:ss A')}
          </Text>
        </Column>
      }
      openDelay={200}
    >
      <Text className={classes.processItem__date} fz="sm" fw={300} miw={74}>
        {dayjs(date.updatedAt).format('MMM D, YYYY')}
      </Text>
    </Tooltip>
  )
}

const ProcessItemTitle = ({
  href,
  icon,
  children,
  ...props
}: {
  href: string
  icon?: ReactNode
  children: ReactNode
} & TextProps) => {
  return (
    <Link href={href}>
      <Row className={classes.processItem__link}>
        {icon}
        <Text
          className={classes.processItem__title}
          component='div'
          p="xs"
          ml={icon ? 6 : 0}
          fw={props.fw ?? 500}
          fz={props.fz ?? rem(12)}
          pr={10}
          // truncate="end"
          // lineClamp={2}
          lh="1.2"
          style={{
            flexShrink: 1,
            flexGrow: 1,
            textAlign: 'start',
          }}
          {...props}
        >
          {children}
        </Text>
      </Row>
    </Link>
  )
}
