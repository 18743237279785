import { z } from 'zod'
import { replaceNewLine } from '~/utils/logic'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

export enum NodeType {
  Start = 'Start',
  Step = 'Step',
  Subprocess = 'Subprocess',
  Decision = 'Decision',
  Switch = 'Switch',
  End = 'End',
}

/**
 * Constants
 */

export const TITLE_MAX_LENGTH = 60
export const DESCRIPTION_MAX_LENGTH = 3000

/**
 * Node schemas
 */

export const InputNodeId = z.union([z.number(), z.string()], {
  errorMap: () => ({
    message: 'Node ID must be a string or number',
  }),
})

export type NodeDetailSchema = z.infer<typeof NodeDetailSchema>
export const NodeDetailSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  nodeId: z.number().readonly(),
  processId: z.string().readonly(),
  revisionId: z.string().readonly(),
  organizationId: z.string().readonly(),
  description: z
    .string()
    .max(
      DESCRIPTION_MAX_LENGTH,
      `Description must be less than ${DESCRIPTION_MAX_LENGTH} characters`,
    ),
}) satisfies PrismaModelSchema<'NodeDetail'>

export type NodeDetailQueryKey = z.infer<typeof NodeDetailQueryKey>
export const NodeDetailQueryKey = z.object({
  nodeId: z.number(),
  processId: z.string(),
  revisionId: z.string(),
  organizationId: z.string(),
})

export const ClientNodeDetailSchema = NodeDetailSchema

export const NodeSchemaBase = z.object({
  id: z.number(),
  title: z
    .string()
    .transform(replaceNewLine)
    .pipe(
      z
        .string()
        .max(
          TITLE_MAX_LENGTH,
          `Node text exceeds maximum: ${TITLE_MAX_LENGTH}`,
        ),
    ),
})

export const NodeSchemaStep = NodeSchemaBase.extend({
  type: z.literal(NodeType.Step),
})
export type StepNode = z.infer<typeof NodeSchemaStep>

export const NodeSchemaDecision = NodeSchemaBase.extend({
  type: z.literal(NodeType.Decision),
})
export type DecisionNode = z.infer<typeof NodeSchemaDecision>

export const NodeSchemaSwitch = NodeSchemaBase.extend({
  type: z.literal(NodeType.Switch),
})
export type SwitchNode = z.infer<typeof NodeSchemaSwitch>

export const NodeSchemaSubprocess = NodeSchemaBase.extend({
  type: z.literal(NodeType.Subprocess),
  subprocessId: z.string(),
  subprocessRevisionId: z.string(),
})
export type SubprocessNode = z.infer<typeof NodeSchemaSubprocess>

export const NodeSchemaStart = NodeSchemaBase.extend({
  type: z.literal(NodeType.Start),
})
export type StartNode = z.infer<typeof NodeSchemaStart>

export const NodeSchemaEnd = NodeSchemaBase.extend({
  type: z.literal(NodeType.End),
})
export type EndNode = z.infer<typeof NodeSchemaEnd>

export const NodeSchema = z.discriminatedUnion('type', [
  NodeSchemaStep,
  NodeSchemaDecision,
  NodeSchemaSwitch,
  NodeSchemaSubprocess,
  NodeSchemaStart,
  NodeSchemaEnd,
])
export type NodeSchema = z.infer<typeof NodeSchema>

export const InputNodeSchema = z.discriminatedUnion('type', [
  NodeSchemaStep.extend({ id: InputNodeId }),
  NodeSchemaDecision.extend({ id: InputNodeId }),
  NodeSchemaSwitch.extend({ id: InputNodeId }),
  NodeSchemaSubprocess.extend({ id: InputNodeId }),
  NodeSchemaStart.extend({ id: InputNodeId }),
  NodeSchemaEnd.extend({ id: InputNodeId }),
])
export type InputNode = z.infer<typeof InputNodeSchema>

/**
 * Helpers
 */

export const isStep = (x: NodeSchema): x is StepNode => x.type === NodeType.Step

export const isDecision = (x: NodeSchema): x is DecisionNode =>
  x.type === NodeType.Decision

export const isSwitch = (x: NodeSchema): x is SwitchNode => x.type === NodeType.Switch

export const isSubprocess = (x: NodeSchema): x is SubprocessNode =>
  x.type === NodeType.Subprocess

export const isStartNode = (x: NodeSchema | InputNode): x is StartNode =>
  x.type === NodeType.Start

export const isEndNode = (x: NodeSchema | InputNode): x is EndNode =>
  x.type === NodeType.End
