import {
  Box,
  Button,
  ButtonProps,
  CSSProperties,
  Modal,
  ModalProps,
  Title,
} from '@mantine/core'
import { ReactNode } from 'react'
import { z, ZodObject, ZodSchema, ZodTypeAny } from 'zod'
import { ValidationResult, Commands } from '~/commands/base-commands'

type ButtonAlign = 'flex-start' | 'flex-end' | 'center'

export type FormAuthorization = Partial<{
  [Model in keyof Commands]: Partial<{
    [Command in keyof Commands[Model]]: ValidationResult
  }>
}>

type FormInnerProps<Schema extends ZodSchema> = {
  initialValues: z.infer<Schema>
  isUpdating: boolean
  onSubmit: (values: z.infer<Schema>) => void
  onChange?: (values: z.infer<Schema>) => void
  buttonText?: string
  buttonAlign?: ButtonAlign
}

export type FormProps<
  Schema extends ZodSchema,
  Settings extends {} | undefined = undefined,
  Authorization extends FormAuthorization | undefined = undefined,
> = {
  form: FormInnerProps<Schema>
} & (Settings extends Settings
  ? {
      settings: Settings
    }
  : {}) &
  (Authorization extends FormAuthorization
    ? {
        authorization: Authorization
      }
    : {})

export type NewFormProps<
  T extends FormProps<any> | FormProps<any, {}> | FormProps<any, {}, {}>,
  Props extends { onCreate?: ((result: any) => void) | undefined },
> = {
  onSubmit?: () => void
  buttonAlign?: ButtonAlign
} & Props &
  (T extends FormProps<any, {}> ? { settings?: Partial<T['settings']> } : {}) &
  (T extends FormProps<any, {}, {}>
    ? { authorization: T['authorization'] }
    : {})

export type EditFormProps<
  T extends FormProps<any> | FormProps<any, {}> | FormProps<any, {}, {}>,
  QueryKey extends {},
> = {
  queryKey: QueryKey
  onSubmit?: () => void
  buttonAlign?: ButtonAlign
} & (T extends FormProps<any, {}>
  ? { settings?: Partial<T['settings']> }
  : {}) &
  (T extends FormProps<any, {}, {}>
    ? { authorization: T['authorization'] }
    : {})

export type ModalFormProps<T> = ModalProps & T

export const FormButton = ({
  loading,
  children,
  align = 'flex-start',
  ...props
}: ButtonProps & { align?: ButtonAlign }) => {
  return (
    <Box style={{ alignSelf: align }}>
      <Button loading={loading} size="md" {...props} type="submit">
        {children}
      </Button>
    </Box>
  )
}

export const FormModal = ({
  title,
  children,
  ...props
}: ModalProps & { title: ReactNode }) => {
  return (
    <Modal
      size={400}
      {...props}
      title={
        <Title component="div" order={3}>
          {title}
        </Title>
      }
    >
      {children}
    </Modal>
  )
}
