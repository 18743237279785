import { useComputedColorScheme, useMantineColorScheme } from '@mantine/core'
import { useCallback } from 'react'

export const useColors = () => {
  const { setColorScheme } = useMantineColorScheme()
  const colorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  })
  const isDarkMode = colorScheme === 'dark'

  const toggleColorScheme = useCallback(() => {
    setColorScheme(colorScheme === 'dark' ? 'light' : 'dark')
  }, [colorScheme])

  return {
    setColorScheme,
    toggleColorScheme,
    colorScheme,
    isDarkMode,
  }
}
