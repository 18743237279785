import { navigate as wouterNavigate } from 'wouter/use-browser-location'

type WouterNavigate = typeof wouterNavigate
type NavigateOptions = Parameters<WouterNavigate>[1]

export const useNavigate = () => navigate

export const navigate = (path: string, options?: NavigateOptions) => {
  // De-dupe navigation requests
  if (typeof path === 'string') {
    const realPath = path.split(`~`).at(-1)!
    if (realPath === document.location.pathname) return
    return wouterNavigate(realPath, options)
  }
}
