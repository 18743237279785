import { TeamRole } from '@prisma/client'
import { z } from 'zod'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

/**
 * Constants
 */

export const NAME_MAX_LENGTH = 60
export const DESCRIPTION_MAX_LENGTH = 200

/**
 * Team schemas
 */

export type TeamMemberSchema = z.infer<typeof TeamMemberSchema>
export const TeamMemberSchema = z.object({
  id: z.string(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  memberId: z.string().readonly(),
  teamId: z.string().readonly(),
  userId: z.string().readonly().nullable(),
  role: z.nativeEnum(TeamRole),
}) satisfies PrismaModelSchema<'TeamMemberProfile'>

const TeamMemberQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    teamId: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    memberId: z.string(),
    teamId: z.string(),
    organizationId: z.string(),
  }),
])
export const teamMemberQueryKeys = TeamMemberQueryKeys.items

export const TeamMemberQueryKey = z.union(TeamMemberQueryKeys.items)
export type TeamMemberQueryKey = z.infer<typeof TeamMemberQueryKey>

export const ClientTeamMemberSchema = TeamMemberSchema
