import { Alert } from '@mantine/core'
import React, { Component, ErrorInfo, ReactNode } from 'react'
import { Icon } from '~/client/dashboard/components/global/Icon'

interface Props {
  children?: ReactNode
  id?: string
}

interface State {
  hasError: boolean
  id?: string
}

export class SwallowErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn(`Swallowed error: `, error, errorInfo)
  }

  public render() {
    if (this.state.hasError) return null

    return this.props.children
  }
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(
      `Uncaught error in boundary: "${this.props.id}"`,
      error,
      errorInfo,
    )
    debugger
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Alert
          variant="light"
          color="red"
          title="Error"
          m="sm"
          icon={<Icon name="Warning" size={24} />}
          styles={{
            title: {
              fontSize: 15,
            },
          }}
        >
          An error has occurred. Please reload the page.
        </Alert>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
