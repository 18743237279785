import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandContextDefinitions,
  ClientCommandDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import {
  cacheProcessMemberProfile,
  useProcessMemberProfileQuery,
} from '~/client/dashboard/queries/process-member-queries.tsx'
import { useProcessModelCtx } from '~/commands/process/process-commands-client'

/**
 * ProcessMember client commands
 */

export const processMemberCommandContext = {
  SetActiveRevision: () => {},
} satisfies ClientCommandContextDefinitions<ModelName.ProcessMember>

export const processMemberCommandsClient = {
  SetActiveRevision: {
    getValidationContext({ input, target, sender, ctx, commandCtx }) {
      return {
        sender: {},
        target: {
          isSender: target.memberId === sender.processProfile?.memberId,
        },
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target, commandCtx }) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key, params }) =>
        cacheProcessMemberProfile(
          ctx,
          (old) => {
            if (!old) return undefined
            return {
              ...old,
              activeRevisionId: params.revisionId,
            }
          },
          key,
          false,
        ),
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.ProcessMember>

/**
 * Process client context
 */

export const useProcessMemberModelCtx = ((key) => {
  const { ctx, sender, state } = useProcessModelCtx({
    id: key.processId,
    organizationId: key.organizationId,
  })

  const {
    memberProfile: target,
    isLoading,
    isError,
  } = useProcessMemberProfileQuery(key)

  return {
    target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
      processMember: target,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.ProcessMember>
