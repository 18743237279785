import { OrganizationRole } from '@prisma/client'
import React, { ReactNode, useContext, useEffect } from 'react'
import { Redirect } from 'wouter'
import { createStore, useStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { RouteMap } from '~/client/RouteMap.ts'
import { BodySkeleton } from '~/client/dashboard/components/global/DashboardShell'
import {
  useDocumentMembersQuery,
  useDocumentQuery,
} from '~/client/dashboard/queries/document-queries'
import { QueryRequestError } from '~/client/dashboard/queries/helpers/query-helpers'
import { DocumentContext } from '~/client/dashboard/stores/DocumentContext'
import {
  useGlobalContext,
  useGlobalStore,
} from '~/client/dashboard/stores/GlobalStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { RequestError } from '~/client/shared/ErrorPage'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { DocumentQueryKey } from '~/schemas/document-schema'

export type DocumentState = {
  id: string
  queryKey: ModelQueryKey['Document']
  document: ClientModel['Document']
  canEdit: boolean
}

const PERSISTED_FIELDS = [] as (keyof DocumentState)[]

type DocumentStoreProps = {
  document: ClientModel['Document']
}

export type DocumentStore = ReturnType<typeof createDocumentStore>

export const createDocumentStore = ({ document }: DocumentStoreProps) => {
  const DEFAULT_PROPS = {
    id: document.id,
    queryKey: {
      id: document.id,
      organizationId: document.organizationId,
    },
    document,
  } satisfies Partial<DocumentState>

  return createStore<DocumentState>()(
    persist(
      (set) =>
        ({
          ...DEFAULT_PROPS,
        }) as DocumentState,
      {
        name: 'document-storage:' + document.id,
        version: 0, // Update if any fields change
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              PERSISTED_FIELDS.includes(key as keyof DocumentState),
            ),
          ),
        // Uncomment this if data migration is required
        // In general, just updating the version is fine because UI state is not that important
        // migrate: (persistedState: DocumentState, version) => {
        //   if (version === 0) {
        //     // if the stored value is in version 0, we rename the field to the new name
        //     persistedState.newField = persistedState.oldField
        //     delete persistedState.oldField
        //   }
        //   return persistedState
        // },
      },
    ),
  )
}

type DocumentProviderProps = React.PropsWithChildren<{
  queryKey: DocumentQueryKey
  loader?: ReactNode
  failure?: ReactNode
  notFound?: ReactNode
}>

export const DocumentProvider = ({
  children,
  queryKey,
  loader,
  failure,
  notFound,
}: DocumentProviderProps) => {
  const addDocumentStore = useGlobalContext((x) => x.addDocumentStore)
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const addActiveItem = useOrganizationContext((x) => x.addActiveItem)
  const removeActiveItem = useOrganizationContext((x) => x.removeActiveItem)

  const { document, error, isLoading } = useDocumentQuery(queryKey, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })

  const id = document?.id
  const documentStore = useGlobalContext((x) =>
    id ? x.documentStores.get(id) : null,
  )

  const canEdit = useCanEditDoc(id)

  // Fetch and cache participant info
  useDocumentMembersQuery({
    id,
    organizationId,
  })

  useEffect(() => {
    if (!document || !documentStore) return
    documentStore.setState({
      document,
    })

    addActiveItem({ type: 'Category', id: document.id })
    return () => removeActiveItem(document.id)
  }, [documentStore, document])

  useEffect(() => {
    if (!documentStore) return
    documentStore.setState({
      canEdit,
    })
  }, [documentStore, canEdit])

  useEffect(() => {
    if (id && document && !documentStore) {
      const store = createDocumentStore({
        document,
      })
      addDocumentStore(id, store)
    }
  }, [id, documentStore, document])

  if (error) {
    return failure ?? <QueryRequestError error={error} />
  }

  if (!document) {
    if (isLoading) return loader ?? <BodySkeleton />
    return notFound ?? <RequestError code={404} message="Not Found" />
  }

  if (!documentStore) return null

  __dev.document = {
    ...document,
    store: documentStore,
  }

  return (
    <DocumentContext.Provider value={documentStore}>
      <DocumentContextLogic />
      {children}
    </DocumentContext.Provider>
  )
}

export const useDocumentContext = <T extends any>(
  selector: (state: DocumentState) => T,
) => {
  const store = useContext(DocumentContext)
  if (!store) throw new Error('Missing DocumentContext.Provider in the tree')
  return useStore(store, selector)
}

export const useDocumentStore = () => {
  const store = useContext(DocumentContext)
  if (!store) throw new Error('Missing DocumentContext.Provider in the tree')
  return store
}

const DocumentContextLogic = () => {
  // Side-effects here

  return null
}

/**
 * Helpers
 */

export const useCanEditDoc = (id?: string) => {
  const role = useOrganizationContext((x) => x.currentMember.role)
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const { document } = useDocumentQuery({ id, organizationId })

  if (!document || !role) return false

  return canEditDoc(role, document)
}

export const canEditDoc = (
  role: OrganizationRole,
  document: ClientModel['Document'],
) => {
  // TODO: Return value based on user access
  return true
}
