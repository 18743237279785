/// <reference lib="dom" />
/// <reference lib="dom.iterable" />

'use client'

import * as _Color from '~/client/dashboard/components/global/colors'
import type { OS } from '@mantine/hooks'
import { rem } from '@mantine/core'
import { Logout } from '~/client/shared/hooks/useLogout'

declare type PartialRequired<T extends {}> = {
  [K in keyof T]: T[K] | undefined
}
declare global {
  let isDebug: boolean
  let Color: typeof _Color
  let rem: typeof rem
  let logout: Logout

  // Hold global vars for console access
  let __dev: { [key: string]: any }

  // Safeguard
  let process: { [key: string]: never }
}

globalThis.Color = _Color
globalThis.rem = rem
globalThis.logout = null
globalThis.isDebug = process.env.NODE_ENV === 'development'
globalThis.__dev = {}
