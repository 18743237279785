import {
  ActionIcon,
  Box,
  Button,
  Select,
  Table,
  Tabs,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { MemberStatus, OrganizationRole } from '@prisma/client'
import { Link } from 'wouter'
import { RouteMap } from '~/client/RouteMap.ts'
import { Column, Row } from '~/client/shared/Layout'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  Portal,
  PortalTargetId,
} from '~/client/dashboard/components/global/Portals'
import {
  MemberAvatar,
  MemberRoleBadge,
  OwnerBadge,
} from '~/client/dashboard/components/global/Prefabs'
import { SettingsItem } from '~/client/dashboard/components/settings/SettingsShell'
import { EditOrganizationMemberModal } from '~/client/dashboard/forms/OrganizationMemberForms'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { WithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import classes from './Members.module.css'
import { useOrganizationMembersQuery } from '~/client/dashboard/queries/organization-queries'
import { ClientModel } from '~/schemas'
import { useDeviceSize } from '~/client/dashboard/stores/GlobalStore'

export const Members = () => {
  const deviceSize = useDeviceSize()
  const organization = useOrganizationContext((x) => x.organization)
  const { members } = useOrganizationMembersQuery(organization)

  const activeMembers = members.filter((x) => x.status === MemberStatus.Active)
  const inactiveMembers = members.filter(
    (x) => x.status !== MemberStatus.Active,
  )

  return (
    <Column>
      <Portal target={PortalTargetId.SettingsTitleRight}>
        {!deviceSize.isSmall && (
          <Link
            href={RouteMap.Settings(
              SettingsItem.Invitations,
              organization.slug,
            )}
            asChild
          >
            <Button
              component="a"
              variant="outline"
              leftSection={<Icon name="PiUserPlus" size={18} />}
              rightSection={<Icon name="MdArrowRightAlt" size={18} />}
            >
              Invite users
            </Button>
          </Link>
        )}
        {deviceSize.isSmall && (
          <Link
            href={RouteMap.Settings(
              SettingsItem.Invitations,
              organization.slug,
            )}
            asChild
          >
            <ActionIcon variant="outline" component="a">
              <Icon name="PiUserPlus" size={18} />
            </ActionIcon>
          </Link>
        )}
      </Portal>
      <Tabs defaultValue="Active">
        <Tabs.List mb="xs">
          <Tabs.Tab value="Active">Active ({activeMembers.length})</Tabs.Tab>
          <Tabs.Tab value="Inactive">
            Inactive ({inactiveMembers.length})
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="Active">
          <MembersTable members={activeMembers} />
        </Tabs.Panel>
        <Tabs.Panel value="Inactive">
          <MembersTable members={inactiveMembers} />
        </Tabs.Panel>
      </Tabs>
    </Column>
  )
}

const MemberItem = ({
  member,
}: {
  member: ClientModel['OrganizationMember']
}) => {
  const ownerId = useOrganizationContext((x) => x.organization.ownerId)
  const selfMemberId = useOrganizationContext((x) => x.currentMember.id)
  const [opened, { open, close }] = useDisclosure()
  const isOwner = member.userId === ownerId
  const isSelf = member.id === selfMemberId

  return (
    <>
      <EditOrganizationMemberModal
        opened={opened}
        onClose={close}
        queryKey={member}
        settings={{
          showStatusControls: true,
        }}
      />
      <Table.Tr key={member.id} data-status={member.status} h={rem(60)}>
        <Table.Td className={classes.columnCreator}>
          <Row gap="sm">
            <MemberAvatar glance={false} size={40} id={member.id} />
            <Column gap="xs">
              <Row gap={rem(2)}>
                {isOwner && <OwnerBadge />}
                <Text>{member.alias}</Text>
              </Row>
              <Box>
                <MemberRoleBadge role={member.role} />
              </Box>
              {/* <Text opacity={0.7}>@{x.handle}</Text> */}
            </Column>
          </Row>
        </Table.Td>
        <Table.Td className={classes.columnControls}>
          <Row gap="sm" justify="flex-end">
            <WithCommand
              model={ModelName.OrganizationMember}
              command="SetRole"
              queryKey={member}
              notAllowed="hide"
            >
              {({ setOrganizationMemberRole }) => (
                <Select
                  defaultValue={member.role}
                  size="sm"
                  w={100}
                  onChange={(role) => {
                    if (!role) return
                    setOrganizationMemberRole({
                      role: role as OrganizationRole,
                    })
                  }}
                  data={[
                    {
                      value: OrganizationRole.Admin,
                      label: 'Admin',
                    },
                    {
                      value: OrganizationRole.User,
                      label: 'User',
                    },
                    {
                      value: OrganizationRole.Guest,
                      label: 'Guest',
                    },
                  ]}
                />
              )}
            </WithCommand>
            <WithCommand
              model={ModelName.OrganizationMember}
              command="UpdateProfile"
              queryKey={member}
              notAllowed="hide"
            >
              <ActionIcon
                variant="light"
                color="dark"
                onClick={() => {
                  open()
                }}
              >
                <Icon name="PiGear" />
              </ActionIcon>
            </WithCommand>
          </Row>
        </Table.Td>
      </Table.Tr>
    </>
  )
}

export const MembersTable = ({
  members,
}: {
  members: ClientModel['OrganizationMember'][]
}) => {
  const organization = useOrganizationContext((x) => x.organization)

  const rows = members.map((x) => {
    return <MemberItem key={x.id} member={x} />
  })

  return (
    <Table className={classes.invitationsTable} w="100%">
      {/* <Table.Thead>
        <Table.Tr>
          <Table.Th className={classes.columnCreator}>Member</Table.Th>
        </Table.Tr>
      </Table.Thead> */}
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  )
}
