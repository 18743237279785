import {
  Button,
  Combobox,
  Divider,
  Input,
  Text,
  useCombobox,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Organization, OrganizationType } from '@prisma/client'
import { ReactNode } from 'react'
import { RouteMap } from '~/client/RouteMap.ts'
import { Box, Column, Row } from '~/client/shared/Layout'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { NAVBAR_WIDTH } from '~/client/dashboard/components/global/DashboardShell'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { OrganizationLogo } from '~/client/dashboard/components/global/Prefabs'
import { roleColors } from '~/client/dashboard/components/global/colors'
import { NewOrganizationModal } from '~/client/dashboard/forms/OrganizationForms'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { logoUrl } from '~/client/shared/data/global-data'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'

const OptionItem = ({
  organization,
  active = false,
  iconSize = 40,
  iconOnly = false,
  withRole = false,
}: {
  organization: Organization
  active?: boolean
  iconSize?: number
  iconOnly?: boolean
  withRole?: boolean
}) => {
  const imageUrl =
    organization.type === OrganizationType.Personal
      ? logoUrl
      : organization.imageUrl
  const userId = useUserContext((x) => x.id)
  const { organizationMember } = useOrganizationMemberQuery({
    userId,
    organizationId: organization.id,
  })

  if (!organizationMember) return null

  const badge =
    organization.type === OrganizationType.Personal ? (
      <Text size="sm" c="yellow">
        Personal
      </Text>
    ) : (
      <Text size="sm" c={roleColors[organizationMember.role][500]}>
        {organizationMember.role}
      </Text>
    )

  return (
    <Row gap={12} justify="space-between" style={{ overflow: 'hidden' }}>
      <Row gap={12} justify="flex-start" style={{ overflow: 'hidden' }}>
        <OrganizationLogo
          imageUrl={imageUrl}
          name={organization.name}
          size={iconSize}
        />
        {!iconOnly && (
          <Column align="start" w="100%" style={{ overflow: 'hidden' }}>
            <Text fz="md" fw={500} truncate="end" w="100%">
              {organization.name}
            </Text>
            {withRole && badge}
          </Column>
        )}
      </Row>
      {active && <Icon name="MdCheckCircle" size={18} c="green.5" />}
    </Row>
  )
}

export const OrganizationDropdown = ({
  iconOnly = false,
  filter = () => true,
  activeId,
  children,
  onSelect,
}: {
  iconOnly?: boolean
  filter?: (item: Organization) => boolean
  children?: ReactNode
  activeId: string | null
  onSelect: (organizationId: string) => void
}) => {
  const isDarkMode = useGlobalContext(x => x.isDarkMode)
  const user = useUserContext((x) => x.user)
  const items = useUserContext((x) => x.organizations).filter(filter)
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const selectedOption = items.find((item) => item.id === activeId)

  const options = items.map((item) => (
    <Combobox.Option
      key={item.id}
      value={item.id}
      active={user.activeOrganizationId === item.id}
    >
      <OptionItem
        organization={item}
        withRole={true}
        iconSize={28}
        active={user.activeOrganizationId === item.id}
      />
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      offset={3}
      width={240}
      position="bottom-start"
      onOptionSubmit={(x) => {
        onSelect(x)
        combobox.closeDropdown()
      }}
    >
      {/* Top */}
      <Combobox.Target>
        <Button
          variant="subtle"
          color="gray"
          maw={NAVBAR_WIDTH}
          styles={{
            root: {
              color: isDarkMode ? 'white' : 'black',
              padding: 0,
              paddingRight: 12,
              paddingLeft: 6,
              height: 50,
            },
          }}
          rightSection={
            <Combobox.Chevron color={isDarkMode ? 'white' : 'black'} />
          }
          onClick={() => combobox.toggleDropdown()}
        >
          {selectedOption ? (
            <OptionItem organization={selectedOption} iconOnly={iconOnly} />
          ) : (
            <Input.Placeholder>Choose organization...</Input.Placeholder>
          )}
        </Button>
      </Combobox.Target>

      {/* Dropdown */}
      <Combobox.Dropdown
        mah="70vh"
        onMouseLeave={() => combobox.resetSelectedOption()}
      >
        <Combobox.Options>
          <>
            {options}
            {children}
          </>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export const ActiveOrganizationDropdown = ({
  iconOnly = false,
}: {
  iconOnly?: boolean
}) => {
  const setLocation = useNavigate()
  const [createOpened, { open: openCreate, close: closeCreate }] =
    useDisclosure(false)
  const user = useUserContext((x) => x.user)
  const organizations = useUserContext((x) => x.organizations)

  return (
    <>
      <OrganizationDropdown
        activeId={user.activeOrganizationId}
        iconOnly={iconOnly}
        onSelect={(val) => {
          if (val === 'new') {
            openCreate()
            return
          }
          const slug = organizations.find((x) => x.id === val)?.slug
          if (slug) setLocation(RouteMap.Organization(slug))
        }}
      >
        <Divider my={10} />
        <Combobox.Option key="new" value="new" mb={4}>
          <Row gap={12}>
            <Box w={28} justify="center" align="center">
              <Icon name="MdAdd" size={20} />
            </Box>
            <Text>Create organization</Text>
          </Row>
        </Combobox.Option>
      </OrganizationDropdown>
      {/* Create modal */}
      <NewOrganizationModal
        opened={createOpened}
        userId={user.id}
        onClose={closeCreate}
        onCreate={(result) => {
          setLocation(RouteMap.Organization(result.organization.slug))
        }}
      />
    </>
  )
}
