import { usePrevious } from '@mantine/hooks'
import { memo, useEffect } from 'react'
import { getConnectorId } from '~/client/dashboard/components/process/diagram/diagram-settings'
import { useRevisionContentQuery } from '~/client/dashboard/queries/revision-queries'
import {
  useDiagramContext,
  useDiagramStore,
} from '~/client/dashboard/stores/DiagramStore'
import {
  NodeProvider,
  getNodeGlobalId,
  useNodeContext,
  useNodeGlobalId,
} from '~/client/dashboard/stores/NodeStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import {
  ProcessProvider,
  getProcessPathString,
  useProcessAccess,
  useProcessContext,
} from '~/client/dashboard/stores/ProcessStore'
import {
  RevisionProvider,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore'
import useEffectOnce from '~/client/shared/hooks/useEffectOnce'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { ConnectorSchema } from '~/schemas/connector-schema'
import { NodeType } from '~/schemas/node-schema'
import { differenceBy } from '~/utils/logic'

export const DiagramNodeListWatcher = memo((props: {}) => {
  const treePath = useProcessContext((x) => x.treePath)
  const diagramStore = useDiagramStore()
  const content = useRevisionContext((x) => x.content)
  const previous = usePrevious(content)

  useEffect(() => {
    // Remove nodes or connectors that no longer exist in content
    if (!previous) return

    const removedConnectors = differenceBy(
      previous.connectors,
      content.connectors,
      getConnectorId,
    )
    const newConnectors = new Map(
      diagramStore.getState().basisContent.connectors,
    )
    removedConnectors.forEach((x) => {
      const globalId = getNodeGlobalId(getConnectorId(x), treePath)!
      newConnectors.delete(globalId)
    })

    const removedNodes = differenceBy(previous.nodes, content.nodes, 'id')
    const newNodes = new Map(diagramStore.getState().basisContent.nodes)
    removedNodes.forEach((x) => {
      const globalId = getNodeGlobalId(x.id, treePath)!
      newNodes.delete(globalId)
    })

    diagramStore.setState({
      basisContent: {
        connectors: newConnectors,
        nodes: newNodes,
      },
    })
  }, [content])

  return (
    <>
      {content.connectors.map((x) => {
        const id = getConnectorId(x)
        return (
          <DiagramConnectorWatcher key={id} connector={x} id={id} {...props} />
        )
      })}
      {content.nodes.map((x) => (
        <NodeProvider key={x.id} id={x.id}>
          <DiagramNodeWatcher />
        </NodeProvider>
      ))}
    </>
  )
})

const DiagramConnectorWatcher = ({
  connector,
  id,
}: {
  connector: ConnectorSchema
  id: string
}) => {
  const { originId, targetId, position, text } = connector
  const globalId = useNodeGlobalId(id)
  const processId = useProcessContext((x) => x.id)
  const parentNodeGlobalId = useProcessContext((x) => x.parentNodeGlobalId)
  const revisionId = useRevisionContext((x) => x.id)
  const targetGlobalId = useNodeGlobalId(targetId)
  const originGlobalId = useNodeGlobalId(originId)
  const diagramStore = useDiagramStore()
  const direction = useDiagramContext((x) => x.direction)

  useEffect(() => {
    diagramStore.setState({
      basisContent: {
        ...diagramStore.getState().basisContent,
        connectors: new Map(
          diagramStore.getState().basisContent.connectors,
        ).set(globalId, {
          id,
          globalId,
          processId,
          revisionId,
          parentNodeGlobalId,
          position,
          text,
          origin: {
            id: originId,
            globalId: originGlobalId,
          },
          target: {
            id: targetId ?? null,
            globalId: targetGlobalId ?? null,
          },
        }),
      },
    })
  }, [
    id,
    diagramStore,
    globalId,
    processId,
    revisionId,
    parentNodeGlobalId,
    position,
    originId,
    originGlobalId,
    targetId,
    targetGlobalId,
    direction,
    text,
  ])

  return null
}

const DiagramNodeWatcher = (props: {}) => {
  const organizationId = useOrganizationContext((x) => x.id)
  const processId = useProcessContext((x) => x.id)
  const treePath = useProcessContext((x) => x.treePath)
  const parentNodeGlobalId = useProcessContext((x) => x.parentNodeGlobalId)
  const isDraft = useRevisionContext((x) => x.revision.isDraft)
  const revisionId = useRevisionContext((x) => x.id)
  const revisionQueryKey = useRevisionContext((x) => x.queryKey)
  const revisionContent = useRevisionContext((x) => x.content)
  const globalId = useNodeContext((x) => x.globalId)
  const nodeId = useNodeContext((x) => x.id)
  const type = useNodeContext((x) => x.type)
  const access = useProcessAccess()
  const isExpanded = useNodeContext((x) => x.isExpanded)
  const subprocessId = useNodeContext((x) => x.subprocessId)
  const subprocessRevisionId = useNodeContext((x) => x.subprocessRevisionId)
  const rootNodeId = useRevisionContext((x) => x.revision.rootNodeId)!
  const diagramStore = useDiagramStore()

  const { content: subprocessContent } = useRevisionContentQuery({
    id: subprocessRevisionId,
    processId: subprocessId,
    organizationId,
  })

  const { setContent, isSettingContent } = useCommand(
    ModelName.Revision,
    'SetContent',
    revisionQueryKey,
  )

  const isExpandedSubdoc = isExpanded && subprocessId && subprocessRevisionId

  // Reconcile subprocess endings with its outgoing connectors
  useEffectOnce(
    () => {
      if (type !== NodeType.Subprocess) return

      if (!access.edit) return

      // Do not update processes that appear as children in the diagram
      if (parentNodeGlobalId) return

      // Do not attempt to update unless revision is unpublished
      if (!isDraft) return

      if (!subprocessContent) return

      const subprocessEndNodes = subprocessContent.nodes.filter(
        (x) => x.type === NodeType.End,
      )

      const existingConnectors = revisionContent.connectors.filter(
        (x) => x.originId === nodeId,
      )

      const defaultTargetId =
        existingConnectors.find((x) => x.targetId)?.targetId ?? null

      setContent({
        content: {
          nodes: revisionContent.nodes,
          connectors: [
            ...revisionContent.connectors.filter((x) => x.originId !== nodeId),
            ...subprocessEndNodes.map((x) => {
              const existing = revisionContent.connectors.find(
                (connector) =>
                  connector.originId === nodeId && connector.position === x.id,
              )
              return {
                position: x.id,
                originId: nodeId,
                targetId: existing?.targetId ?? defaultTargetId,
                text: x.title,
              }
            }),
          ],
        },
      })
    },
    [
      subprocessContent,
      revisionContent,
      type,
      nodeId,
      parentNodeGlobalId,
      isDraft,
      access.edit,
    ],
    ([subprocessContent]) => Boolean(subprocessContent),
  )

  useEffect(() => {
    diagramStore.setState({
      basisContent: {
        ...diagramStore.getState().basisContent,
        nodes: new Map(diagramStore.getState().basisContent.nodes).set(
          globalId,
          {
            id: nodeId,
            processId,
            revisionId,
            globalId,
            treePath,
            type,
            isRoot: nodeId === rootNodeId,
            nodePath: getProcessPathString(treePath),
            subprocessId,
            subprocessRevisionId,
            parentNodeGlobalId,
            isExpanded,
          },
        ),
      },
    })
  }, [
    isExpandedSubdoc,
    diagramStore,
    nodeId,
    globalId,
    type,
    processId,
    revisionId,
    subprocessId,
    subprocessRevisionId,
    parentNodeGlobalId,
    rootNodeId,
    treePath,
  ])

  if (isExpandedSubdoc) {
    return (
      <ProcessProvider
        queryKey={{ id: subprocessId, organizationId }}
        treePath={[
          ...treePath,
          {
            nodeId,
            processId,
            revisionId,
          },
        ]}
      >
        <RevisionProvider id={subprocessRevisionId}>
          <DiagramNodeListWatcher />
        </RevisionProvider>
      </ProcessProvider>
    )
  }

  return null
}
