'use client'
import { Flex, FlexProps, Loader, Tooltip } from '@mantine/core'
import React, { RefObject, forwardRef } from 'react'

export type BoxProps = FlexProps & {
  loading?: boolean
  loadingHeight?: number
  tooltip?: string
  grow?: number
  shrink?: number
} & {
  component?: React.ElementType
}

export const Box = forwardRef(
  (
    {
      children,
      tooltip,
      loading,
      loadingHeight = 50,
      style = {},
      grow,
      shrink,
      ...props
    }: BoxProps,
    ref,
  ) => {
    const content = (
      // @ts-ignore
      <Flex
        ref={ref as any}
        style={{
          flexGrow: grow,
          flexShrink: shrink,
          ...style,
          ...(loading
            ? {
                alignItems: 'center',
                justifyContent: 'center',
                height: loadingHeight,
              }
            : {}),
        }}
        {...props}
      >
        {loading ? <Loader size={50} /> : children}
      </Flex>
    )

    return tooltip ? <Tooltip label={tooltip}>{content}</Tooltip> : content
  },
)

export const Row = forwardRef(
  (
    {
      children,
      className,
      style = {},
      align = 'center',
      grow,
      shrink,
      ...props
    }: BoxProps,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        className={className}
        {...props}
        style={
          {
            flexDirection: 'row',
            alignItems: align,
            flexGrow: grow,
            flexShrink: shrink,
            ...style,
          } as React.CSSProperties
        }
      >
        {children}
      </Box>
    )
  },
)

export const Column = forwardRef(
  (
    {
      children,
      className,
      align = 'stretch',
      style = {},
      grow,
      shrink,
      ...props
    }: BoxProps,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        className={className}
        {...props}
        style={
          {
            flexDirection: 'column',
            alignItems: align,
            flexGrow: grow,
            flexShrink: shrink,
            ...style,
          } as React.CSSProperties
        }
      >
        {children}
      </Box>
    )
  },
)
