import {
  ActionIcon,
  Box,
  ButtonProps,
  Combobox,
  ComboboxStore,
  Input,
  InputBase,
  Pill,
  PillsInput,
  PillsInputProps,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
  useCombobox,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { getHotkeyHandler } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { DocumentPrivacy } from '@prisma/client'
import { useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import { Column, Row } from '~/client/shared/Layout'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  TreeSearch,
  TreeSearchItem,
} from '~/client/dashboard/components/global/Search'
import {
  DefaultTreeItemView,
  TreeItem,
  TreeView,
  TreeViewActions,
  buildDocumentFlatTree,
} from '~/client/dashboard/components/global/TreeView'
import {
  EditFormProps,
  FormButton,
  FormModal,
  FormProps,
  ModalFormProps,
  NewFormProps,
} from '~/client/dashboard/forms/form-helpers'
import { useDocumentQuery } from '~/client/dashboard/queries/document-queries'
import { useOrganizationMemberDocumentProfilesQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationDocumentsQuery } from '~/client/dashboard/queries/organization-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { ButtonWithCommand, WithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { useCommand, useCommandAuthorization } from '~/commands/client-commands'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'
import { documentPrivacy } from '~/client/shared/data/document-data'
import { ClientModel, ModelQueryKey } from '~/schemas'
import {
  DESCRIPTION_MAX_LENGTH,
  DocumentQueryKey,
  DocumentSchema,
  NAME_MAX_LENGTH,
} from '~/schemas/document-schema'
import classes from './Forms.module.css'
import { RouteMap } from '~/client/RouteMap'
import { useDarkMode } from '~/client/dashboard/stores/GlobalStore'

/**
 * Appearance
 */

const DocumentFormSchema = z.object({
  id: DocumentSchema.shape.id.optional(),
  title: DocumentSchema.shape.title,
  description: DocumentSchema.shape.description,
  // TODO: Zod.nonNullable when available
  parentId: DocumentSchema.shape.parentId,
  privacy: DocumentSchema.shape.privacy,
})

type Settings = {
  deleteButton?: boolean
  onDelete?: () => void
}
type DocumentFormProps = FormProps<
  typeof DocumentFormSchema,
  Settings & {
    queryKey?: ModelQueryKey['Document']
    privacyOptions: DocumentPrivacyOptions
  }
>

export const DocumentSettingsForm = ({ form, settings }: DocumentFormProps) => {
  const { initialValues, isUpdating, onSubmit, buttonText = 'Save' } = form
  const documentSettingsForm = useForm({
    validate: zodResolver(DocumentFormSchema),
    initialValues,
    onValuesChange: form.onChange,
  })

  return (
    <form
      // onSubmit={documentSettingsForm.onSubmit(onSubmit)}
      onSubmit={documentSettingsForm.onSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      <TextInput
        label="Name"
        data-autofocus
        maxLength={NAME_MAX_LENGTH}
        {...documentSettingsForm.getInputProps('title')}
      />
      <DocumentItemSelectInput
        label="Parent"
        {...documentSettingsForm.getInputProps('parentId')}
        currentDocumentId={documentSettingsForm.values.id}
      />
      <PrivacySelectInput
        label="Privacy"
        {...documentSettingsForm.getInputProps('privacy')}
        options={settings.privacyOptions}
      />
      <Textarea
        label="Description"
        maxLength={DESCRIPTION_MAX_LENGTH}
        {...documentSettingsForm.getInputProps('description')}
      />
      <Row justify="space-between" align="flex-end">
        {settings.deleteButton && settings.queryKey && (
          <DeleteCategoryButton
            queryKey={settings.queryKey}
            onDelete={settings.onDelete}
          />
        )}
        <FormButton mt="sm" loading={isUpdating}>
          {buttonText}
        </FormButton>
      </Row>
    </form>
  )
}

/**
 * New Document
 */

type NewDocumentFormProps = NewFormProps<
  DocumentFormProps,
  {
    organizationId: string
    parentId: string
    onCreate?: (document: ClientModel['Document']) => void
  }
>

export const NewDocumentForm = ({
  onSubmit,
  onCreate,
  settings = {},
  organizationId,
  parentId,
}: NewDocumentFormProps) => {
  const [currentParentId, setCurrentParentId] = useState(parentId)
  const { createDocumentAsync, isPending } = useCommand(
    ModelName.Document,
    'CreateDocument',
  )

  const privacyOptions = usePrivacyOptionsCreate({
    id: currentParentId,
    organizationId,
  })

  return (
    <DocumentSettingsForm
      settings={{
        ...settings,
        privacyOptions,
      }}
      form={{
        initialValues: {
          title: '',
          description: '',
          privacy: DocumentPrivacy.Open,
          parentId,
        },
        buttonText: 'Create',
        isUpdating: isPending,
        onChange({ parentId }) {
          setCurrentParentId(parentId as string)
        },
        onSubmit(values) {
          onSubmit?.()
          void createDocumentAsync({
            key: {
              id: values.parentId!,
              organizationId,
            },
            params: {
              title: values.title,
              description: values.description,
              privacy: values.privacy,
            },
          }).then(({ document }) => onCreate?.(document))
        },
      }}
    />
  )
}

export type NewDocumentModalProps = ModalFormProps<NewDocumentFormProps>
export const NewDocumentModal = ({
  parentId,
  organizationId,
  onCreate,
  ...props
}: NewDocumentModalProps) => {
  return (
    <FormModal {...props} title="New Category">
      <NewDocumentForm
        onSubmit={props.onClose}
        onCreate={onCreate}
        parentId={parentId}
        organizationId={organizationId}
      />
    </FormModal>
  )
}

/**
 * Edit Document
 */

type EditDocumentFormProps = EditFormProps<DocumentFormProps, DocumentQueryKey>

export const EditDocumentForm = ({
  queryKey,
  settings = {},
  onSubmit,
}: EditDocumentFormProps) => {
  const { document } = useDocumentQuery(queryKey)
  const { updateDocumentProfile, isPending: isUpdating } = useCommand(
    ModelName.Document,
    'UpdateProfile',
    queryKey,
  )
  const [currentParentId, setCurrentParentId] = useState(document?.parentId)
  const privacyOptions = usePrivacyOptionsEdit(queryKey, {
    parentId: currentParentId,
  })

  if (!document) return null

  return (
    <DocumentSettingsForm
      settings={{
        ...settings,
        queryKey,
        privacyOptions,
      }}
      form={{
        initialValues: {
          id: document.id,
          title: document.title,
          description: document.description,
          parentId: document.parentId!,
          privacy: document.privacy,
        },
        isUpdating,
        onChange({ parentId }) {
          setCurrentParentId(parentId)
        },
        onSubmit(values) {
          updateDocumentProfile({
            parentId: values.parentId,
            title: values.title,
            description: values.description,
            privacy: values.privacy,
          })
          onSubmit?.()
        },
      }}
    />
  )
}

export const EditDocumentModal = ({
  settings = {},
  queryKey,
  ...props
}: ModalFormProps<EditDocumentFormProps>) => {
  return (
    <FormModal {...props} title="Category Settings">
      <EditDocumentForm
        onSubmit={props.onClose}
        settings={{
          ...settings,
          onDelete() {
            props.onClose()
            settings.onDelete?.()
          },
        }}
        queryKey={queryKey}
      />
    </FormModal>
  )
}

/**
 * Custom inputs
 */

const PrivacySelectInput = ({
  options,
  value,
  label,
  onChange,
  error,
}: {
  options: DocumentPrivacyOptions
  value?: DocumentPrivacy
  label?: string
  onChange: (value: DocumentPrivacy) => void
  error?: string
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const currentEnabled = value ? options[value].enabled : false
  useEffect(() => {
    if (!currentEnabled) {
      const firstAvailable = (Object.keys(options) as DocumentPrivacy[]).find(
        (x) => options[x].enabled,
      )
      if (firstAvailable) onChange(firstAvailable)
    }
  }, [currentEnabled])

  const Option = ({ privacy }: { privacy: DocumentPrivacy }) => {
    const active = privacy === value

    const { enabled, message } = options[privacy]

    return (
      <Tooltip
        label={message}
        disabled={!message}
        openDelay={200}
        maw={300}
        transitionProps={{
          duration: 70,
        }}
      >
        <Combobox.Option disabled={!enabled} value={privacy}>
          <Row justify="space-between">
            <OptionContent privacy={privacy} />
            {active && <Icon size={20} name="PiCheckBold" />}
          </Row>
        </Combobox.Option>
      </Tooltip>
    )
  }

  const OptionContent = ({ privacy }: { privacy: DocumentPrivacy }) => {
    return (
      <Row gap="sm">
        <Icon size={20} nudgeUp={2} name={documentPrivacy[privacy].iconName} />
        <Column gap={2} px="sm" py="xs">
          <Text fz="md" fw={600} data-important>
            {privacy}
          </Text>
          <Text fz="sm">{documentPrivacy[privacy].description}</Text>
        </Column>
      </Row>
    )
  }

  return (
    <Combobox
      store={combobox}
      offset={0}
      onOptionSubmit={(val) => {
        onChange(val as DocumentPrivacy)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          size="md"
          label={label}
          component="button"
          error={error}
          pointer
          rightSection={
            <Row gap="xs" justify="flex-end" w="100%" pr="sm">
              <Icon name="PiCaretDownBold" />
            </Row>
          }
          onClick={(e) => {
            e.preventDefault()
            combobox.toggleDropdown()
          }}
          rightSectionPointerEvents="none"
          styles={{
            section: {
              width: rem(60),
            },
            input: {
              height: 56,
            },
          }}
        >
          <OptionContent privacy={value!} />
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown className={classes.dropdown}>
        <Combobox.Options>
          {Object.keys(options).map((x) => (
            <Option key={x} privacy={x as DocumentPrivacy} />
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

type DocumentPrivacyOptions = {
  [privacy in DocumentPrivacy]: {
    enabled: boolean
    message?: string
  }
}

const usePrivacyOptionsCreate = (
  queryKey: ModelQueryKey['Document'],
): DocumentPrivacyOptions => {
  const { valid: openEnabled, reason: openInvalidReason } =
    useCommandAuthorization(ModelName.Document, 'CreateDocument', {
      key: queryKey,
      params: {
        privacy: DocumentPrivacy.Open,
      },
    })
  const { valid: protectedEnabled, reason: protectedInvalidReason } =
    useCommandAuthorization(ModelName.Document, 'CreateDocument', {
      key: queryKey,
      params: {
        privacy: DocumentPrivacy.Protected,
      },
    })
  const { valid: managedEnabled, reason: managedInvalidReason } =
    useCommandAuthorization(ModelName.Document, 'CreateDocument', {
      key: queryKey,
      params: {
        privacy: DocumentPrivacy.Managed,
      },
    })

  return {
    [DocumentPrivacy.Open]: {
      enabled: openEnabled,
      message: openInvalidReason?.message,
    },
    [DocumentPrivacy.Protected]: {
      enabled: protectedEnabled,
      message: protectedInvalidReason?.message,
    },
    [DocumentPrivacy.Managed]: {
      enabled: managedEnabled,
      message: managedInvalidReason?.message,
    },
  }
}

const usePrivacyOptionsEdit = (
  queryKey: ModelQueryKey['Document'],
  { parentId }: { parentId?: string | null },
): DocumentPrivacyOptions => {
  const { valid: openEnabled, reason: openInvalidReason } =
    useCommandAuthorization(ModelName.Document, 'UpdateProfile', {
      key: queryKey,
      params: {
        privacy: DocumentPrivacy.Open,
        parentId,
      },
    })
  const { valid: protectedEnabled, reason: protectedInvalidReason } =
    useCommandAuthorization(ModelName.Document, 'UpdateProfile', {
      key: queryKey,
      params: {
        privacy: DocumentPrivacy.Protected,
        parentId,
      },
    })
  const { valid: managedEnabled, reason: managedInvalidReason } =
    useCommandAuthorization(ModelName.Document, 'UpdateProfile', {
      key: queryKey,
      params: {
        privacy: DocumentPrivacy.Managed,
        parentId,
      },
    })

  return {
    [DocumentPrivacy.Open]: {
      enabled: openEnabled,
      message: openInvalidReason?.message,
    },
    [DocumentPrivacy.Protected]: {
      enabled: protectedEnabled,
      message: protectedInvalidReason?.message,
    },
    [DocumentPrivacy.Managed]: {
      enabled: managedEnabled,
      message: managedInvalidReason?.message,
    },
  }
}

export const DocumentItemSelectInput = ({
  currentDocumentId,
  value,
  error,
  label,
  onChange,
}: {
  currentDocumentId?: string
  value?: string
  error?: string
  label?: string
  onChange: (id: string) => void
}) => {
  // TODO: Forms/inputs should not rely on context
  //  This Component should be split into one that receives data[] (no knowledge of organizationId/member)
  //  See <DocumentItemMultiSelectInput />
  const organization = useOrganizationContext((x) => x.organization)
  const organizationProfileId = useOrganizationContext(
    (x) => x.currentMember.id,
  )
  const resetRef = useRef<(item?: any, clear?: boolean) => void>(() => {})
  const comboboxRef = useRef<ComboboxStore>(null)
  const [defaultVisibleIds, setDefaultVisibleIds] = useState<string[]>([])
  const [defaultSelectedIds, setDefaultSelectedIds] = useState<string[]>([])
  const [documentTreeData, setDocumentTreeData] = useState<
    TreeItem<ClientModel['Document']>[]
  >([])

  const { documents } = useOrganizationDocumentsQuery({
    id: organization.id,
  })
  const { memberDocumentProfiles } = useOrganizationMemberDocumentProfilesQuery(
    {
      id: organizationProfileId,
      organizationId: organization.id,
    },
  )

  const isRoot = value === organization.rootDocumentId

  useEffect(() => {
    const data = buildDocumentFlatTree<ClientModel['Document']>(
      organization.rootDocumentId,
      documents,
      [],
      (x) => x.type === 'Category' && !x.data.isArchived,
    )
    const visibleIds = data
      .filter((x) => x.data.id === value || x.data.id === currentDocumentId)
      .map((x) => x.id)
    setDocumentTreeData(data)
    setDefaultVisibleIds(visibleIds)
    const selectedId = data.find((x) => x.data.id === value)?.id
    setDefaultSelectedIds(selectedId ? [selectedId] : [])
  }, [documents, value])

  useEffect(() => {
    resetRef.current(null, true)
  }, [value])

  const selectedItemName = documentTreeData.find(
    (x) => x.data.id === value,
  )?.name

  const canEditDocument = (document: ClientModel['Document']) => {
    const documentMemberProfile =
      memberDocumentProfiles.find(
        (member) => member.documentId === document.id,
      ) ?? null

    // TODO: Check team roles
    const documentAccess = getMemberDocumentAccess({
      privacy: document.privacy,
      documentMemberRole: documentMemberProfile?.role ?? null,
    })

    return documentAccess.edit
  }

  return (
    <Input.Wrapper label={label} error={error}>
      <TreeSearch
        dropdown={true}
        data={documentTreeData}
        resetRef={resetRef}
        // NOTE: Difference between filter and disabledFilter is unclear
        filter={(x) => {
          if (!canEditDocument(x.data)) return false
          return !currentDocumentId || !x.path.includes(currentDocumentId)
        }}
        disabledFilter={(x) => {
          if (!canEditDocument(x.data)) return true
          return currentDocumentId ? x.path.includes(currentDocumentId) : false
        }}
        comboboxRef={comboboxRef}
        defaultVisibleIds={defaultVisibleIds}
        defaultSelectedIds={defaultSelectedIds}
        asSelect={true}
        withinPortal={true}
        input={{
          placeholder: isRoot ? 'None' : selectedItemName,
          classNames: {
            input: `${classes.documentParentInput} ${
              isRoot ? classes.documentParentRoot : ''
            }`,
          },
          styles: {
            section: {
              width: rem(60),
            },
          },
          rightSectionPointerEvents: 'none',
          rightSection: (
            <Row gap="xs" justify="flex-end" w="100%" pr="sm">
              {!isRoot && (
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  onClick={() => {
                    onChange(organization.rootDocumentId)
                  }}
                  style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                >
                  <Icon name="MdClose" size={20} />
                </ActionIcon>
              )}
              <Icon name="PiCaretDownBold" />
            </Row>
          ),
        }}
        onSelect={(x) => onChange(x.data.id)}
      />
    </Input.Wrapper>
  )
}

export type DocumentItemMultiSelectInputProps = {
  data: TreeItem<ClientModel['Document']>[]
  isDropdown?: boolean
  label?: string
  error?: string
  value: string[]
  onAdd: (id: string) => void
  onRemove: (id: string) => void
  disabledFilter?: (item: TreeItem<ClientModel['Document']>) => boolean
  isEditable?: boolean
  wrap?: boolean
  maxItemWidth?: number
  nested?: {
    input?: PillsInputProps
  }
}
export const DocumentItemMultiSelectInput = ({
  data = [],
  isDropdown = false,
  maxItemWidth = 200,
  label,
  error,
  value,
  onAdd,
  onRemove,
  disabledFilter = () => false,
  isEditable = false,
  wrap = true,
  nested = {},
}: DocumentItemMultiSelectInputProps) => {
  const isDarkMode = useDarkMode()
  const navigate = useNavigate()
  const [opened, setOpened] = useState(false)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const combobox = useCombobox({
    opened,
  })
  const actionRef = useRef<TreeViewActions>()
  const inputRef = useRef<HTMLInputElement>(null)
  const truncatePills = isEditable || value.length > 1

  const [search, setSearch] = useState('')
  const [defaultVisibleIds] = useState(() =>
    data.filter((x) => value.includes(x.id)).map((x) => x.id),
  )
  const documentIds = value.filter((id) => data.some((x) => x.data.id === id))

  const values = documentIds.map((id) => {
    const item = data.find((x) => x.data.id === id)!
    const disabled = disabledFilter(item)
    const icon =
      item?.data.privacy !== DocumentPrivacy.Open ? (
        <Box opacity={0.5} ml="xs">
          <Icon
            nudgeUp={1}
            name={documentPrivacy[item.data.privacy].iconName}
          />
        </Box>
      ) : null

    return (
      <Pill
        key={id}
        onClick={() => {
          if (!isEditable && item) {
            navigate(RouteMap.Document(organizationSlug, item.data.slug))
          }
        }}
        style={{
          cursor: isEditable ? 'default' : 'pointer',
        }}
        disabled={disabled}
        withRemoveButton={isEditable}
        onRemove={() => {
          onRemove(id)
          inputRef.current?.blur()
          setOpened(false)
        }}
        maw={truncatePills ? maxItemWidth : 'auto'}
        bg={isDarkMode ? 'dark.5' : 'light.2'}
        c={isDarkMode ? 'white' : 'black'}
      >
        <Row>
          {item?.name}
          {disabled && isEditable && (
            <Tooltip label="You do not have permission to remove this category">
              {icon}
            </Tooltip>
          )}
          {!isEditable && icon}
        </Row>
      </Pill>
    )
  })

  const isSelectable = (item: TreeItem<ClientModel['Document']>) =>
    !value.includes(item.data.id) && !disabledFilter(item)

  const filteredData = data.filter(
    (item) =>
      isSelectable(item) &&
      item.parent &&
      item.data.title.toLowerCase().includes(search.trim().toLowerCase()),
  )
  const isFiltered = search.trim().length > 0

  useEffect(() => {
    if (isFiltered) {
      combobox.selectFirstOption()
    }
  }, [filteredData.length])

  const selectionMenu = useMemo(() => {
    if (!isEditable) return null
    return (
      <>
        {!isFiltered && (
          <Combobox.Options>
            <TreeView
              data={data}
              actionRef={actionRef}
              onBlur={() => {
                setOpened(false)
              }}
              ItemView={(props) => <DefaultTreeItemView {...props} />}
              onItemSelect={(item) => {
                onAdd(item.data.id)
                inputRef.current?.focus()
              }}
              defaultVisibleIds={defaultVisibleIds}
              selectableFilter={(item) => isSelectable(item)}
              disabledFilter={(item) => {
                return value.includes(item.data.id) || disabledFilter(item)
              }}
            />
          </Combobox.Options>
        )}
        {isFiltered && (
          <Combobox.Options>
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <Combobox.Option key={item.id} value={item.id}>
                  <TreeSearchItem
                    key={item.id}
                    data={data}
                    item={{
                      ...item,
                      path: item.path.map(
                        (pathId) => data.find((x) => x.data.id === pathId)?.id!,
                      ),
                    }}
                  />
                </Combobox.Option>
              ))
            ) : (
              <Combobox.Empty>
                <Row p="sm" pb="md" justify="center">
                  Nothing found
                </Row>
              </Combobox.Empty>
            )}
          </Combobox.Options>
        )}
      </>
    )
  }, [isFiltered, filteredData, isEditable])

  return (
    <Input.Wrapper
      label={label}
      error={error}
      w="100%"
      styles={{
        root: {
          overfow: 'hidden',
        },
      }}
    >
      <Combobox
        withinPortal={false}
        position="bottom-start"
        store={combobox}
        onOptionSubmit={(value) => {
          const item = data.find((x) => x.id === value)
          if (item) onAdd(item.data.id)
          setSearch('')
        }}
      >
        <PillsInput
          h="auto"
          mih="auto"
          {...nested.input}
          styles={{
            ...(nested.input?.styles ?? {}),
            input: {
              cursor: isEditable ? 'initial' : 'default',
              height: 'auto',
              minHeight: 'auto',
              // @ts-ignore
              ...(nested.input?.styles?.input ?? {}),
            },
          }}
        >
          <Pill.Group
            gap={4}
            styles={{
              group: wrap
                ? {}
                : {
                    flexWrap: 'nowrap',
                    overflowY: 'hidden',
                    overflowX: 'scroll',
                    scrollbarWidth: 'none',
                  },
            }}
          >
            {values}

            {isEditable && (
              <Combobox.DropdownTarget>
                <Combobox.EventsTarget>
                  <PillsInput.Field
                    value={search}
                    ref={inputRef}
                    w={150}
                    maw={150}
                    fz="sm"
                    placeholder="Add category..."
                    ml="xs"
                    onFocus={() => {
                      setOpened(true)
                    }}
                    onClick={() => {
                      // if (opened) {
                      //   setOpened(false)
                      // }
                    }}
                    onBlur={(e) => {}}
                    onChange={(event) => {
                      setSearch(event.currentTarget.value)
                      setOpened(true)
                    }}
                    onKeyDown={getHotkeyHandler([
                      [
                        'Escape',
                        (e) => {
                          if (document.activeElement === inputRef.current) {
                            inputRef.current?.blur()
                            setOpened(false)
                          }
                        },
                      ],
                      [
                        'ArrowDown',
                        (e) => {
                          // Focus first item in tree
                          if (!isFiltered) {
                            setTimeout(() => {
                              actionRef.current?.focusItem(data[1]?.id)
                            }, 100)
                          }
                        },
                      ],
                    ])}
                    // onKeyDown={(event) => {
                    // TODO: Bring this back once we use command Process.SetDocumentIds
                    // if (event.key === 'Backspace' && search.length === 0) {
                    //   event.preventDefault()
                    //   handleValueRemove(value[value.length - 1])
                    // }
                    // }}
                  />
                </Combobox.EventsTarget>
              </Combobox.DropdownTarget>
            )}
          </Pill.Group>
        </PillsInput>
        {isDropdown ? (
          <Combobox.Dropdown miw={270} maw={400}>
            {selectionMenu}
          </Combobox.Dropdown>
        ) : (
          selectionMenu
        )}
      </Combobox>
    </Input.Wrapper>
  )
}

type DeleteCategoryProps = {
  queryKey: ModelQueryKey['Document']
  onDelete?: () => void
}

const DeleteCategoryButton = ({
  queryKey,
  onDelete,
  ...props
}: DeleteCategoryProps & ButtonProps) => {
  const openDeleteModal = useDeleteCategoryConfirmation({
    queryKey,
    onDelete,
  })

  return (
    <ButtonWithCommand
      onClick={() => {
        openDeleteModal()
      }}
      model={ModelName.Document}
      command={'Delete'}
      queryKey={queryKey}
      variant="subtle"
      color="red"
      {...props}
    >
      Delete Category
    </ButtonWithCommand>
  )
}

export const useDeleteCategoryConfirmation = ({
  queryKey,
  onDelete,
}: DeleteCategoryProps) => {
  const { deleteDocument, isPending: isDeletingDocument } = useCommand(
    ModelName.Document,
    'Delete',
    queryKey,
  )

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: (
        <Title component="div" order={3}>
          Delete category?
        </Title>
      ),
      children: (
        <Column pb="lg">
          <Text>
            This category will be removed from all associated processes. This
            action cannot be undone.
          </Text>
        </Column>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onCancel: () => {},
      onConfirm: () => {
        deleteDocument({})
        onDelete?.()
      },
    })

  return openDeleteModal
}
