import {
  ModelName,
  ModelCommandsValidation,
  authorized,
  valid,
} from '~/commands/base-commands'

/**
 * NodeDetail commands validation
 */

export const nodeDetailValidation = {
  Example: {
    validate() {
      return valid()
    },
    authorize() {
      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.NodeDetail>
