import { Column } from '~/client/shared/Layout'
import { DocumentEmptyState } from '~/client/dashboard/components/document/Document'
import { PageTitle } from '~/client/dashboard/components/global/Prefabs'
import { ProcessesAccordion } from '~/client/dashboard/components/process/ProcessItem'
import { useOrganizationRevisionsQuery } from '~/client/dashboard/queries/organization-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { ClientModel } from '~/schemas'
import { sortBy } from '~/utils/logic'

export const DraftsContent = () => {
  const organization = useOrganizationContext((x) => x.organization)
  const { revisions, isLoading } = useOrganizationRevisionsQuery({
    id: organization.id,
  })
  const drafts = sortBy(
    revisions.filter((x) => x.isDraft),
    (x) => -x.updatedAt.getTime(),
  ) as ClientModel['Revision'][]

  const isEmpty = !isLoading && drafts.length === 0
  const processIds = Array.from(new Set(drafts.map((x) => x.processId)))

  return (
    <Column h="100%" w="100%">
      <PageTitle title="Drafts" allowXL={true} />
      <Column w="100%" maw={800} mt="lg" gap="md">
        {!isEmpty && (
          <ProcessesAccordion processIds={processIds} draftsOnly={true} />
        )}
        {/* // <Column key={id} gap="xs" mih={40} py="sm">
          //   {drafts.map((x) => (
          //     <ProcessItemDraft key={id} queryKey={x} />
          //   ))}
          // </Column> */}
        {isEmpty && <DocumentEmptyState />}
      </Column>
    </Column>
  )
}
