import { z } from 'zod'
import {
  PrismaModelSchema,
  refine,
  transformation,
} from '~/schemas/schema-helpers'
import {
  InputNodeSchema,
  isEndNode,
  isStartNode,
  NodeSchema,
} from '~/schemas/node-schema'
import {
  Connector,
  ConnectorSchema,
  InputConnectorSchema,
} from '~/schemas/connector-schema'

/**
 * Constants
 */

export const TITLE_MAX_LENGTH = 100
export const MAX_NODES = 300
export const REVISION_MESSAGE_MAX_LENGTH = 1000

/**
 * Refiners / Transformers
 */

export type TempNode = Omit<NodeSchema, 'id'> & { id: string }
export const validateProcessContent = refine<ProcessContentInput>(
  (content, ctx) => {
    // A map of nodes that need to be assigned a valid ID
    const tempNodes = new Map<string, TempNode>()

    // A map of updated nodes (will remain equivalent if arguments are valid)
    const nodes = new Map<number, NodeSchema>()

    // Track whether a start node was included
    let startNodeId: string | number | null = null

    // Iterate and validate each node, storing it upon completion
    content.nodes.forEach((node, i) => {
      const addIssue = (message: string) =>
        ctx.addIssue({
          message,
          path: ['nodes', i],
        })

      // Ensure ID exists
      if (!node.id) {
        addIssue('All nodes must have an ID')
        return
      }

      const isStart = isStartNode(node)
      if (isStart) {
        // Ensure only one start node
        if (startNodeId) {
          addIssue('Cannot have more than one start node')
        }
        startNodeId = startNodeId ?? node.id
      }

      // This must remain the final validation for nodes.
      //  Additional validation should be placed above this point.
      if (typeof node.id === 'string') {
        if (tempNodes.get(node.id)) {
          // Check for duplicate IDs
          addIssue('All node IDs must be unique')
        }

        // Store as a temporary node to be assigned an ID
        tempNodes.set(node.id, node as TempNode)
        return
      } else {
        if (nodes.get(node.id)) {
          // Check for duplicate IDs
          addIssue('All node IDs must be unique')
        }
      }
      // NOTE: Do not add additional validation here. Look above.
      // Store the node
      nodes.set(node.id, node as NodeSchema)
    })

    if (!startNodeId) {
      // Ensure a start node exists
      ctx.addIssue({
        message: 'Process must have a start node',
        path: ['nodes'],
      })
    }

    // A map from temporary IDs to actual IDs (assigned here)
    const tempIdMap = new Map<string, number>()

    let nextAttemptedId = 1
    const getNextAvailableId = () => {
      let isAvailable = false
      for (nextAttemptedId; !isAvailable; nextAttemptedId++) {
        if (!nodes.get(nextAttemptedId)) isAvailable = true
      }
      nextAttemptedId -= 1
      return nextAttemptedId
    }

    // Iterate temp nodes and assign them valid IDs
    tempNodes.forEach((x) => {
      const tempId = x.id
      const node = { ...x } as unknown as NodeSchema

      // Convert temporary ID to permanent
      // Note: This is inefficient but safe. It could be optimized in many ways.
      //  The simplest approach is probably to store available IDs on the content
      //  as nodes are deleted. If none, simply use the highest ID + 1
      node.id = getNextAvailableId()

      // Store a map from the temp ID to the valid node
      tempIdMap.set(tempId, node.id)

      nodes.set(node.id, node)
    })

    // Store a list of all IDs that were generated from temp IDs
    //  We'll use this to ensure no incidental connections were passed in
    const generatedIds = new Set(tempIdMap.values())

    // Set of [ position, originId ] (test for uniqueness)
    const connectorPositions = new Set<string>()
    // Set of [ targetId, originId ] (test for uniqueness)
    const connectorTargets = new Set<string>()

    // An array updated connectors (will remain equivalent if arguments are valid)
    const connectors: Connector[] = []

    content.connectors.forEach((connector, i) => {
      const addIssue = (message: string) =>
        ctx.addIssue({
          message,
          path: ['connectors', i],
        })

      // Ensure node IDs provided were not created by coincidence
      if (generatedIds.has(connector.originId as number)) {
        addIssue('originId does not match any node ID')
      }
      if (generatedIds.has(connector.targetId as number)) {
        addIssue('targetId does not match any node ID')
      }

      // Ensure sure a connector doesn't point to and from the same node
      if (connector.targetId === connector.originId) {
        addIssue('targetId and originId cannot be equal')
      }

      // Map temp node IDs to the valid ID
      connector.originId =
        tempIdMap.get(connector.originId as string) ??
        (connector.originId as number)
      connector.targetId =
        tempIdMap.get(connector.targetId as string) ??
        (connector.targetId as number)

      const originNode = nodes.get(connector.originId)
      const targetNode = nodes.get(connector.targetId)

      // Ensure connector doesn't target start node
      if (targetNode && isStartNode(targetNode)) {
        addIssue('Connector cannot target start node')
      }

      // Ensure connector doesn't originate at end node
      if (originNode && isEndNode(originNode)) {
        addIssue('Connector cannot originate at end node')
      }

      // Ensure every connector points to/from an existing node
      if (!nodes.get(connector.originId)) {
        addIssue('originId does not match any node ID')
      }

      if (connector.targetId && !nodes.get(connector.targetId)) {
        addIssue('targetId does not match any node ID')
      }

      // Ensure a connector doesn't have the same origin/position as another
      const originPositionId = `${connector.originId}${connector.position}`
      if (connectorPositions.has(originPositionId)) {
        addIssue('All connectors of originId must have a unique position')
      }
      connectorPositions.add(originPositionId)

      // Ensure a connector doesn't have the same origin/target as another
      if (connector.targetId) {
        const originTargetId = `${connector.originId}${connector.targetId}`

        // NOTE: Leaving here in case this is desired behavior
        //  There may be valid cases, especially under Switch nodes.
        // if (connectorTargets.has(originTargetId)) {
        //   addIssue('All connectors of originId must have a unique targetId')
        // }
        connectorTargets.add(originTargetId)
      }

      // Store the connector
      connectors.push(connector as Connector)
    })

    // Convert updated content back to its array format
    return {
      nodes: Array.from(nodes.values()),
      connectors: connectors,
    } as ProcessContentInput
  },
)

/**
 * Revision.content
 */

// Enforce validity for content on process
export const ProcessContentSchema = z.object({
  nodes: z
    .array(NodeSchema)
    .max(MAX_NODES, 'Number of nodes exceeds maximum: ' + MAX_NODES),
  connectors: z.array(ConnectorSchema),
})
export type ProcessContent = z.infer<typeof ProcessContentSchema>

// Enforce types for content that may be automatically
//  transformed into a valid processContent
const contentInputSchema = z.object({
  nodes: z
    .array(InputNodeSchema)
    .max(MAX_NODES, 'Number of steps exceeds maximum: ' + MAX_NODES),
  connectors: z.array(InputConnectorSchema),
})
export type ProcessContentInput = z.infer<typeof contentInputSchema>

export const parseContent = (content: ProcessContentInput) =>
  ProcessContentInputSchema.parse(content)

// Not intended for direct use in most cases
export const ProcessContentInputSchema = contentInputSchema
  .transform(transformation(validateProcessContent))
  // NOTE: This is a failsafe. We re-validate after our transformation
  //  to ensure that we didn't break anything. This could be removed
  //  with high-enough confidence in the transformation method.
  // .superRefine(refinement(validateProcessContent))
  // Revalidate with higher restrictions
  .pipe(ProcessContentSchema)
  .catch((ctx) => {
    // Log bad input for debugging
    if (process.env.NODE_ENV === 'development') {
      console.info('(Development) Bad process content:', ctx.input)
    }
    throw ctx.error
  }) as z.ZodType<ProcessContent, z.ZodTypeDef, ProcessContentInput>

/**
 * Revision
 */

export type RevisionSchema = z.infer<typeof RevisionSchema>
export const RevisionSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  processId: z.string().readonly(),
  creatorId: z.string().readonly(),
  parentRevisionId: z.string().readonly().nullable(),
  rootNodeId: z.number().nullable(),
  isTemplate: z.boolean(),
  title: z
    .string()
    .trim()
    .max(
      TITLE_MAX_LENGTH,
      `Title must be less than ${TITLE_MAX_LENGTH} characters`,
    ),
  content: ProcessContentSchema,
}) satisfies PrismaModelSchema<'Revision'>

export const RevisionQueryKey = z.object({
  id: z.string(),
  processId: z.string(),
  organizationId: z.string(),
})
export type RevisionQueryKey = z.infer<typeof RevisionQueryKey>
export const revisionQueryKeys = [RevisionQueryKey]

export type ClientRevisionSchema = z.infer<typeof ClientRevisionSchema>
export const ClientRevisionSchema = RevisionSchema.omit({
  content: true,
}).merge(
  z.object({
    isDraft: z.boolean(),
    isTitleSet: z.boolean(),
  }),
)

/**
 * RevisionPublication
 */

export type RevisionPublicationSchema = z.infer<
  typeof RevisionPublicationSchema
>

export const RevisionPublicationSchema = z.object({
  id: z.string().readonly(),
  version: z.number().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  revisionId: z.string().readonly(),
  processId: z.string().readonly(),
  approverId: z.string().readonly(),
  publishedAt: z.date().readonly(),
  message: z
    .string()
    .max(
      REVISION_MESSAGE_MAX_LENGTH,
      `Message must be less than ${REVISION_MESSAGE_MAX_LENGTH} characters`,
    )
    .readonly(),
}) satisfies PrismaModelSchema<'RevisionPublication'>
