import { toast } from '~/client/shared/Toast'
import { cacheInvitation } from '~/client/dashboard/queries/invitation-queries'
import { cacheBootstrapOrganization } from '~/client/dashboard/queries/organization-queries'
import { QuerySchema } from '~/schemas'
import { api } from '~/utils/api'

/**
 * Invitation
 */

export const useUpdateInvitation = () => {
  const ctx = api.useUtils()
  const result = api.Invitation.updateInvitation.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Invitation.updateInvitation.parse(input)

      const { id, organizationId } = input
      return cacheInvitation(
        ctx,
        (old) => (old ? { ...old, ...input } : undefined),
        { id, organizationId },
        false,
      )
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to update invitation')
    },
  })

  return {
    ...result,
    updateInvitation: result.mutate,
    isUpdatingInvitation: result.isPending,
  }
}

export const useArchiveInvitation = () => {
  const ctx = api.useUtils()
  const result = api.Invitation.archiveInvitation.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Invitation.archiveInvitation.parse(input)

      const { id, organizationId } = input
      return cacheInvitation(
        ctx,
        (old) =>
          old
            ? {
                ...old,
                isArchived: true,
              }
            : undefined,
        { id, organizationId },
        false,
      )
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to disable invitation')
    },
  })

  return {
    ...result,
    archiveInvitation: result.mutate,
    isArchivingInvitation: result.isPending,
  }
}

export const useUnarchiveInvitation = () => {
  const ctx = api.useUtils()
  const result = api.Invitation.unarchiveInvitation.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Invitation.unarchiveInvitation.parse(input)

      const { id, organizationId } = input
      return cacheInvitation(
        ctx,
        (old) =>
          old
            ? {
                ...old,
                isArchived: false,
              }
            : undefined,
        { id, organizationId },
        false,
      )
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to enable invitation')
    },
  })

  return {
    ...result,
    unarchiveInvitation: result.mutate,
    isUnarchivingInvitation: result.isPending,
  }
}

export const useRedeemInvitation = () => {
  const ctx = api.useUtils()
  const result = api.Invitation.redeemInvitation.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Invitation.redeemInvitation.parse(input)

      return toast.processing('Redeeming invitation...')
    },
    onSuccess(result, input, context) {
      context?.onSuccess('Invitation redeemed')
      cacheBootstrapOrganization(
        ctx,
        result,
        {
          id: result.id,
        },
        {},
      )
    },
    onError(err, input, context) {
      if (context) {
        context.onFailure('Failed to redeem invitation')
      } else {
        toast.failure('Failed to redeem invitation')
      }
    },
  })

  return {
    ...result,
    redeemInvitation: result.mutateAsync,
    isRedeemingInvitation: result.isPending,
  }
}

export const useValidateInvitation = () => {
  const ctx = api.useUtils()
  const result = api.Invitation.validateInvitation.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Invitation.validateInvitation.parse(input)
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {},
  })

  return {
    ...result,
    validateInvitation: result.mutateAsync,
    isValidatingInvitation: result.isPending,
  }
}
