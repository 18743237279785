/**
 * Provides state and helpers global to the entire application.
 *   Every entrypoint should start here.
 */

// Mantine core styles must be first
import '@mantine/core/styles.css'

// Additional Mantine imports
import '@mantine/notifications/styles.css'
import '@mantine/tiptap/styles.css'

// Global styles
import '~/client/index.css'

// Global types
import '~/client/globals.d.ts'

// Other imports

import { ClerkProvider, useAuth } from '@clerk/clerk-react'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink, loggerLink } from '@trpc/client'
import React, {
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import superjson from 'superjson'
import { createStore, useStore } from 'zustand'
import {
  ColorHelper,
  hashStringToColor,
  nodeColor,
} from '~/client/dashboard/components/global/colors'
import { DocumentStore } from '~/client/dashboard/stores/DocumentStore'
import { GlobalContext } from '~/client/dashboard/stores/GlobalContext'
import { NodeStore } from '~/client/dashboard/stores/NodeStore'
import { OrganizationStore } from '~/client/dashboard/stores/OrganizationStore'
import { ProcessStore } from '~/client/dashboard/stores/ProcessStore'
import { RevisionStore } from '~/client/dashboard/stores/RevisionStore'
import { UserStore } from '~/client/dashboard/stores/UserStore'
import { useColors } from '~/client/shared/hooks/useColors'
import {
  DeviceSize,
  DeviceType,
  _useDeviceSize,
  _useDeviceType,
} from '~/client/shared/hooks/useDeviceSize'
import { cssVariablesResolver, theme } from '~/client/theme.tsx'
import { NodeType } from '~/schemas/node-schema'
import { api } from '~/utils/api.ts'

const CLERK_PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
const isStrictMode = import.meta.env.VITE_REACT_STRICT_MODE === 'true'

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Clerk Publishable Key')
}

export type GlobalState = {
  isDarkMode: boolean
  deviceSize: DeviceSize
  deviceType: DeviceType
  isArrowKeyNav: boolean
  scrollMain: boolean
  mousePosition: { x: 0; y: 0 }
  isNavOpenSmall: boolean
  isNavOpenMedium: boolean

  // Stores
  userStore: UserStore | null
  organizationStores: Map<string, OrganizationStore>
  documentStores: Map<string, DocumentStore>
  processStores: Map<string, ProcessStore>
  revisionStores: Map<string, RevisionStore>
  nodeStores: Map<string, NodeStore>

  // Helpers
  adjustColorForTheme: (color: ColorHelper) => ColorHelper
  getAvatarColor: (username: string) => ColorHelper
  getNodeColor: (nodeType: NodeType) => ColorHelper
  getNodeStrokeColor: (color: ColorHelper) => ColorHelper
  getDocColor: (id: string) => ColorHelper
  getDocStrokeColor: (id?: string) => ColorHelper
  getDocLightColor: (id?: string) => ColorHelper
  getDraftColor: () => string

  // Actions
  setUserStore: (store: UserStore) => void
  addOrganizationStore: (id: string, store: OrganizationStore) => void
  addDocumentStore: (id: string, store: DocumentStore) => void
  addProcessStore: (id: string, store: ProcessStore) => void
  addRevisionStore: (id: string, store: RevisionStore) => void
  addNodeStore: (id: string, store: NodeStore) => void
  setNavOpenSmall: (val: boolean) => void
  setNavOpenMedium: (val: boolean) => void
  setIsArrowKeyNav: (val: boolean) => void
  setScrollMain: (scrollMain: boolean) => void
}

type GlobalStoreProps = Pick<
  GlobalState,
  'isDarkMode' | 'deviceSize' | 'deviceType'
>

export const createGlobalStore = (props: GlobalStoreProps) =>
  createStore<GlobalState>((set, get) => ({
    userStore: null,
    organizationStores: new Map(),
    documentStores: new Map(),
    processStores: new Map(),
    revisionStores: new Map(),
    nodeStores: new Map(),
    isArrowKeyNav: false,
    scrollMain: false,
    mousePosition: { x: 0, y: 0 },
    isNavOpenMedium: true,
    isNavOpenSmall: false,
    setNavOpenSmall: (val) =>
      set(() => ({
        isNavOpenSmall: val,
      })),
    setNavOpenMedium: (val) =>
      set(() => ({
        isNavOpenMedium: val,
      })),
    setIsArrowKeyNav: (val) =>
      set(() => ({
        isArrowKeyNav: val,
      })),
    setScrollMain: (scrollMain) =>
      set(() => ({
        scrollMain,
      })),
    setUserStore: (store) =>
      set(() => ({
        userStore: store,
      })),
    addOrganizationStore: (id, store) =>
      set(({ organizationStores }) => ({
        organizationStores: new Map(organizationStores).set(id, store),
      })),
    addDocumentStore: (id, store) =>
      set(({ documentStores }) => ({
        documentStores: new Map(documentStores).set(id, store),
      })),
    addProcessStore: (id, store) =>
      set(({ processStores }) => ({
        processStores: new Map(processStores).set(id, store),
      })),
    addRevisionStore: (id, store) =>
      set(({ revisionStores }) => ({
        revisionStores: new Map(revisionStores).set(id, store),
      })),
    addNodeStore: (id, store) =>
      set(({ nodeStores }) => ({
        nodeStores: new Map(nodeStores).set(id, store),
      })),
    adjustColorForTheme(color) {
      const { isDarkMode } = get()
      if (isDarkMode) {
        return color.tint(0.3).desaturate(0.1)
      } else {
        return color.shade(0.3).desaturate(0.1)
      }
    },
    getAvatarColor(username) {
      const { adjustColorForTheme } = get()
      let color = Color.of(hashStringToColor(username))
      return adjustColorForTheme(color)
    },
    getNodeColor(type) {
      const { isDarkMode } = get()
      return nodeColor[isDarkMode ? 'dark' : 'light'][type]
    },
    getNodeStrokeColor(color) {
      const { isDarkMode } = get()
      return isDarkMode ? color.lighten(0.3) : color.darken(0.3)
    },
    getDocColor(id) {
      const { adjustColorForTheme } = get()
      let color = Color.of(hashStringToColor(id))
      return adjustColorForTheme(color)
    },
    getDocStrokeColor(id) {
      const { getNodeStrokeColor, getDocColor } = get()
      if (!id) return Color.of('#333')
      const color = getDocColor(id)
      return getNodeStrokeColor(color)
    },
    getDocLightColor(id) {
      const { isDarkMode, getDocColor } = get()
      if (!id) return Color.of('#444')
      return isDarkMode
        ? Color.of('black').darken(1).mix(getDocColor(id))
        : Color.of('white').lighten(1).mix(getDocColor(id))
    },
    getDraftColor() {
      const { isDarkMode } = get()
      return isDarkMode ? 'rgba(255,255,255,0.06)' : 'rgba(0,0,0,0.06)'
    },
    ...props,
  }))

export type GlobalStore = ReturnType<typeof createGlobalStore>

export const GlobalProvider = ({ children }: PropsWithChildren) => {
  return (
    <StrictModeSwitch>
      <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
        <MantineProvider
          theme={theme}
          classNamesPrefix="a3"
          cssVariablesResolver={cssVariablesResolver}
        >
          <GlobalProviderInner>{children}</GlobalProviderInner>
        </MantineProvider>
      </ClerkProvider>
    </StrictModeSwitch>
  )
}

const GlobalProviderInner = ({ children }: PropsWithChildren) => {
  const deviceSize = _useDeviceSize()
  const deviceType = _useDeviceType()
  const { isDarkMode } = useColors()
  const { getToken } = useAuth()

  const [queryClient] = useState(() => new QueryClient())
  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          transformer: superjson,
          url: `/api/trpc`,
          async headers() {
            return {
              Authorization: `Bearer ${await getToken()}`,
            }
          },
          // async fetch(url, options) {
          //   return fetch(url, {
          //     ...options,
          //     credentials: 'include',
          //     headers: {
          //     },
          //   })
          // },
        }),
      ],
    }),
  )

  const [globalStore] = useState<GlobalStore>(() =>
    createGlobalStore({
      deviceSize,
      deviceType,
      isDarkMode,
    }),
  )

  useSyncField(globalStore, { deviceSize })
  useSyncField(globalStore, { deviceType })
  useSyncField(globalStore, { isDarkMode })

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ModalsProvider>
          <GlobalContext.Provider value={globalStore}>
            {children}
          </GlobalContext.Provider>
          <Notifications />
        </ModalsProvider>
      </QueryClientProvider>
    </api.Provider>
  )
}

const useSyncField = (store: GlobalStore, field: Partial<GlobalState>) => {
  useEffect(() => {
    store.setState(field)
  }, [store, ...Object.values(field)])
}

export const useGlobalContext = <T extends any>(
  selector: (state: GlobalState) => T,
) => {
  const store = useContext(GlobalContext)
  if (!store) throw new Error('Missing GlobalContext.Provider in the tree')
  return useStore(store, selector)
}

export const useGlobalStore = () => {
  const store = useContext(GlobalContext)
  if (!store) throw new Error('Missing GlobalContext.Provider in the tree')
  return store
}

const StrictModeSwitch = ({ children }: PropsWithChildren) => {
  if (isStrictMode) return <React.StrictMode>{children}</React.StrictMode>
  return children
}

/**
 * Selectors
 */

export const useDeviceType = () => useGlobalContext((x) => x.deviceType)
export const useDeviceSize = () => useGlobalContext((x) => x.deviceSize)
export const useDarkMode = () => useGlobalContext((x) => x.isDarkMode)
