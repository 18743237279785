import { useFullscreen, useShallowEffect } from '@mantine/hooks'
import { ReactFlowProvider, Viewport, useViewport } from '@xyflow/react'
import {
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { createStore, useStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { RouteMap } from '~/client/RouteMap'
import {
  DiagramBasisConnectors,
  DiagramBasisNodes,
  DiagramDirection,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import { DiagramContext } from '~/client/dashboard/stores/DiagramContext'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { useProcessContext } from '~/client/dashboard/stores/ProcessStore'
import { useCanEditRevision, useRevisionContext } from '~/client/dashboard/stores/RevisionStore'
import { navigate } from '~/client/shared/hooks/useNavigate'

export enum DiagramViewMode {
  Viewing = 'viewing',
  Editing = 'editing'
}

const PERSISTED_FIELDS = [
  'expandedNodeIds',
  'viewMode',
  'direction',
] as (keyof DiagramState)[]

export type DiagramState = {
  rootRevisionId: string
  movingNode: string | null
  viewport: Viewport
  isFocused: boolean
  isFullscreen: boolean
  direction: DiagramDirection
  diagramElementRef: MutableRefObject<HTMLDivElement>
  basisContent: {
    nodes: DiagramBasisNodes
    connectors: DiagramBasisConnectors
  }
  currentNodeId: string | null
  expandedNodeIds: string[]
  viewMode: DiagramViewMode
  debug: boolean

  // Actions
  scrollToNode: (id: string, duration?: number, zoom?: number) => void
  setCurrentNode: (id: string | null, forceInboundNodeId?: string) => void
  toggleFullscreen: () => Promise<void>
  expandSubdocNode: (id: string) => void
  collapseSubdocNode: (id: string) => void
  setDirection: (dir: DiagramDirection) => void
  setViewMode: (viewMode: DiagramViewMode) => void
}

type CreateDiagramStoreProps = Pick<
  DiagramState,
  | 'rootRevisionId'
  | 'toggleFullscreen'
  | 'isFullscreen'
  | 'diagramElementRef'
  | 'currentNodeId'
  | 'viewMode'
>

const createDiagramStore = (props: CreateDiagramStoreProps) =>
  createStore<DiagramState>()(
    persist(
      (set) => ({
        ...props,
        debug: false,
        inboundNodeId: null,
        expandedNodeIds: [],
        direction: DiagramDirection.Right,
        movingNode: null,
        viewport: {
          x: 0,
          y: 0,
          zoom: 1,
        },
        isFocused: false,
        basisContent: {
          nodes: new Map(),
          connectors: new Map(),
        },
        scrollToNode: () => {},
        setCurrentNode: () => {},
        expandSubdocNode: (id) =>
          set(({ expandedNodeIds }) => {
            if (expandedNodeIds.some((x) => x === id)) return {}
            return {
              expandedNodeIds: [...expandedNodeIds, id],
            }
          }),
        collapseSubdocNode: (id) =>
          set(({ expandedNodeIds }) => {
            return {
              expandedNodeIds: expandedNodeIds.filter((x) => x !== id),
            }
          }),
        setDirection: (diagramDirection) =>
          set(() => ({
            direction: diagramDirection,
          })),
        setViewMode: (viewMode) =>
          set(() => ({
            viewMode,
          })),
      }),
      {
        name: `diagram-storage:${props.rootRevisionId}`,
        version: 0, // Update if any stored fields change
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              PERSISTED_FIELDS.includes(key as keyof DiagramState),
            ),
          ),
      },
    ),
  )

export type DiagramStore = ReturnType<typeof createDiagramStore>

type DiagramProviderProps = {
  currentNodeId: DiagramState['currentNodeId']
  viewMode?: DiagramViewMode
  children: ReactNode
}

export const DiagramProvider = ({
  currentNodeId,
  viewMode = DiagramViewMode.Viewing,
  children,
}: DiagramProviderProps) => {
  const rootRevisionId = useRevisionContext((x) => x.revision.id)
  const rootNodeId = useRevisionContext((x) => x.revision.rootNodeId)
  const storeRef = useRef<DiagramStore>()
  const {
    toggle: toggleFullscreen,
    fullscreen: isFullscreen,
    ref: diagramElementRef,
  } = useFullscreen()

  if (!storeRef.current) {
    storeRef.current = createDiagramStore({
      rootRevisionId,
      isFullscreen,
      toggleFullscreen,
      diagramElementRef: diagramElementRef as any,
      currentNodeId: currentNodeId ?? String(rootNodeId),
      viewMode: viewMode ?? DiagramViewMode.Viewing,
    })
  }

  return (
    <ReactFlowProvider>
      <DiagramContext.Provider value={storeRef.current}>
        <DiagramContextLogic currentNodeId={currentNodeId} />
        {children}
      </DiagramContext.Provider>
    </ReactFlowProvider>
  )
}

export const useDiagramContext = <T extends any>(
  selector: (state: DiagramState) => T,
) => {
  const store = useContext(DiagramContext)
  if (!store) {
    throw new Error('Missing DiagramProvider')
  }
  return useStore(store, selector)
}

export const useDiagramStore = () => {
  const store = useContext(DiagramContext)
  if (!store) throw new Error('Missing DiagramContext.Provider in the tree')
  return store
}

const DiagramContextLogic = ({
  currentNodeId,
}: {
  currentNodeId: string | null
}) => {
  const diagramStore = useDiagramStore()
  const version = useRevisionContext((x) => x.version)
  const setViewMode = useDiagramContext((x) => x.setViewMode)
  const processSlug = useProcessContext((x) => x.process.slug)
  const canEdit = useCanEditRevision()
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const viewport = useViewport()

  useEffect(() => {
    diagramStore.setState({ currentNodeId })
  }, [diagramStore, currentNodeId])

  useShallowEffect(() => {
    diagramStore.setState({ viewport })
  }, [diagramStore, viewport])

  useEffect(() => {
    diagramStore.setState({
      setCurrentNode: (id) => {
        const nodePath = RouteMap.Process(
          organizationSlug,
          processSlug,
          version,
          id ?? undefined,
        )
        navigate(nodePath, {
          // TODO: Do not replace if navigation triggered by click on connector
          //  And viewMode === ViewMode.Viewing
          replace: true,
        })
      },
    })
  }, [organizationSlug, processSlug, version, currentNodeId])

  useEffect(() => {
    if (!canEdit) {
      setViewMode(DiagramViewMode.Viewing)
    } else {
      setViewMode(DiagramViewMode.Editing)
    }
  }, [canEdit, setViewMode])

  return null
}

