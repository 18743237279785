import { z } from 'zod'
import {
  ModelName,
  ModelCommandsSchema,
  ModelCommandsValidation,
  valid,
  authorized,
} from '~/commands/base-commands'
import { OrganizationSchema } from '~/schemas/organization-schema'
import { UserSchema } from '~/schemas/user-schema'

import { BootstrapOrganizationProfile } from '~/schemas'

/**
 * User commands schema
 */

export const userCommandsSchema = {
  UpdateProfile: {
    summary: `Update user's profile settings`,
    description: ``,
    method: 'PATCH',
    messages: {
      success: 'Profile has been updated',
    },
    schemas: {
      input: z.object({
        username: UserSchema.shape.username,
        imageUrl: UserSchema.shape.imageUrl.optional(),
      }),
      output: z.literal(true),
      authorize: {
        sender: {},
        target: { isSender: z.boolean() },
        meta: {},
      },
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
    },
    variables: {
      execute: 'updateUserProfile',
      isPending: 'isUpdatingUserProfile',
    },
  },
  SetActiveOrganization: {
    summary: `Set user's active organization`,
    description: ``,
    method: 'PATCH',
    messages: {},
    variables: {
      execute: 'setActiveOrganization',
      isPending: 'isSettingActiveOrganization',
    },
    schemas: {
      input: z.object({
        organizationId: OrganizationSchema.shape.id,
      }),
      output: z.literal(true),
      authorize: {
        sender: {},
        target: { isSender: z.boolean() },
        meta: {},
      },
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
    },
  },
  CreateOrganization: {
    summary: `Create a new organization`,
    description: ``,
    method: 'POST',
    messages: {
      processing: 'Creating new organization...',
      success: 'New organization created',
    },
    variables: {
      execute: 'createOrganization',
      isPending: 'isCreatingOrganization',
    },
    schemas: {
      input: z.object({
        type: OrganizationSchema.shape.type,
        name: OrganizationSchema.shape.name,
        description: OrganizationSchema.shape.description.optional(),
        imageUrl: OrganizationSchema.shape.imageUrl.optional(),
      }),
      // TODO: Resolve this circular dependency
      output: z.any() as unknown as typeof BootstrapOrganizationProfile,
      authorize: {
        sender: {},
        target: { isSender: z.boolean() },
        meta: {},
      },
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
    },
  },
  Delete: {
    summary: `Delete a user`,
    description: ``,
    method: 'DELETE',
    messages: {
      processing: 'Deleting account...',
      failure: 'Failed to delete account',
      success: 'Account deleted',
    },
    variables: {
      execute: 'deleteUser',
      isPending: 'isDeletingUser',
    },
    schemas: {
      input: z.object({}),
      output: z.literal(true),
      authorize: {
        sender: {},
        target: { isSender: z.boolean() },
        meta: {},
      },
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
    },
  },
} as const satisfies ModelCommandsSchema
