import {
  ActionIcon,
  Button,
  Input,
  Loader,
  SimpleGrid,
  Text,
  Title,
  Tooltip,
  useCombobox,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { DocumentRole } from '@prisma/client'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'wouter'
import { RouteMap } from '~/client/RouteMap.ts'
import {
  DocumentParticipantDrawer,
  DocumentParticipantSidebar,
} from '~/client/dashboard/components/document/DocumentMemberList'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  DropdownDivider,
  DropdownMenu,
  DropdownOption,
  PageTitle,
  PageTitleBreadcrumb,
} from '~/client/dashboard/components/global/Prefabs'
import {
  TreeItem,
  buildDocumentFlatTree,
} from '~/client/dashboard/components/global/TreeView'
import { useThemeColor } from '~/client/dashboard/components/global/colors'
import { ProcessesAccordion } from '~/client/dashboard/components/process/ProcessItem'
import {
  EditDocumentModal,
  NewDocumentModal,
  useDeleteCategoryConfirmation,
} from '~/client/dashboard/forms/DocumentForms'
import { useDocumentMembersQuery } from '~/client/dashboard/queries/document-queries'
import {
  useOrganizationDocumentsQuery,
  useOrganizationProcessesQuery,
} from '~/client/dashboard/queries/organization-queries'
import { useDocumentContext } from '~/client/dashboard/stores/DocumentStore'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { Box, Column, Row } from '~/client/shared/Layout'
import {
  documentIcon,
  documentIconEmpty,
  documentIconSize,
  documentPrivacy,
} from '~/client/shared/data/document-data'
import { globalIcons } from '~/client/shared/data/global-data'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import { ButtonWithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { ClientModel } from '~/schemas'
import { NAME_MAX_LENGTH } from '~/schemas/document-schema'
import { sortBy } from '~/utils/logic'
import { getProcessDateInfo } from '~/utils/model-logic'
import classes from './Document.module.css'

export const DocumentContent = () => {
  const deviceType = useGlobalContext((x) => x.deviceType)
  const deviceSize = useGlobalContext((x) => x.deviceSize)
  const [
    participantDrawerOpened,
    { close: closeParticipantDrawer, open: openParticipantDrawer },
  ] = useDisclosure()
  const theme = useThemeColor()
  const navigate = useNavigate()
  const document = useDocumentContext((x) => x.document)
  const documentSlug = useDocumentContext((x) => x.document.slug)
  const organization = useOrganizationContext((x) => x.organization)
  const organizationMemberId = useOrganizationContext((x) => x.currentMember.id)

  const { documents } = useOrganizationDocumentsQuery({
    id: organization.id,
  })
  const { processes: allProcesses } = useOrganizationProcessesQuery({
    id: organization.id,
  })

  const documentSiblings =
    documents
      .filter((x) => x.parentId === document.parentId && !x.isArchived)
      .map((x) => ({
        ...x,
        title: x.id === organization.rootDocumentId ? 'Directory' : x.title,
      })) ?? []
  const processes = sortBy(
    allProcesses.filter(
      (x) => x.documentIds.includes(document?.id) && !x.isArchived,
    ) ?? [],
    (x) =>
      getProcessDateInfo(x).lastPublishedAt ?? getProcessDateInfo(x).createdAt,
  )
  const [path, setPath] = useState<PageTitleBreadcrumb[]>()
  const [editOpened, { open: openEdit, close: closeEdit }] =
    useDisclosure(false)
  const [
    newDocumentOpened,
    { close: closeNewDocument, open: openNewDocument },
  ] = useDisclosure()
  const settingsCombobox = useCombobox()
  const { documentMembers } = useDocumentMembersQuery({
    id: document.id,
    organizationId: document.organizationId,
  })

  const isEmpty = processes.length === 0

  const onDelete = () => {
    if (
      document.parentId &&
      document.parentId !== organization.rootDocumentId
    ) {
      navigate(RouteMap.Document(organization.slug, documentSlug))
    } else {
      navigate(RouteMap.DashboardHome())
    }
  }

  const openDeleteModal = useDeleteCategoryConfirmation({
    queryKey: document,
    onDelete,
  })

  useEffect(() => {
    if (!organization || !document) return
    const tree = buildDocumentFlatTree(
      organization.rootDocumentId,
      documents,
      processes,
    )
    const item = tree.find((x) => x.data.id === document.id)
    if (!item) return
    setPath(
      item.path
        .slice(1, item.path.length - 1)
        .map((x) => tree.find((treeItem) => treeItem.data.id === x))
        .filter((x): x is TreeItem => Boolean(x))
        .map((x) => {
          return {
            name: x.name || 'New Category',
            href: RouteMap.Document(organization.slug, x.data.slug),
          }
        }),
    )
  }, [organization, document?.id])

  if (!document || !path) return null

  const isRoot = document.id === organization.rootDocumentId

  const isJoined = documentMembers.some(
    (x) => x.memberId === organizationMemberId,
  )

  const draftProcesses = processes.filter((x) => !x.mainRevisionId)
  const publishedProcesses = processes.filter((x) => x.mainRevisionId)

  return (
    <Row className={classes.documentContent} align="stretch" gap="lg" pb="xl">
      <Column grow={1} shrink={1} style={{ minWidth: 0 }}>
        <PageTitle
          breadcrumbs={deviceSize.isSmall ? [] : path}
          selectedHref={RouteMap.Document(organization.slug, document.slug)}
          allowXL={true}
          options={documentSiblings.map((x) => ({
            Title: ({ isHeader }) => (
              <Row gap="sm">
                {!isHeader && (
                  <Icon size={documentIconSize} name={documentIcon} />
                )}
                {isRoot ? 'Directory' : x.title}
                {isHeader && (
                  <Tooltip label={documentPrivacy[x.privacy].description}>
                    <Box opacity={0.5}>
                      <Icon
                        size={'0.5em'}
                        name={documentPrivacy[x.privacy].iconName}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Row>
            ),
            value: x.id,
            href: RouteMap.Document(organization.slug, x.slug),
          }))}
          rightSection={
            isRoot ? null : (
              <Row gap="sm">
                <ButtonWithCommand
                  model={ModelName.Document}
                  command="AddParticipant"
                  queryKey={document}
                  notAllowed="hide"
                  onClick={({ addDocumentParticipant }) =>
                    addDocumentParticipant({})
                  }
                  validate={{
                    memberId: organizationMemberId,
                    role: DocumentRole.Default,
                  }}
                >
                  Join
                </ButtonWithCommand>
                <DropdownMenu
                  store={settingsCombobox}
                  position="bottom-end"
                  target={
                    <ActionIcon
                      variant="subtle"
                      pos="relative"
                      size={36}
                      onClick={() => settingsCombobox.toggleDropdown()}
                      color="gray"
                      radius="50%"
                      style={{
                        color: theme(['gray', 6], ['dark', 0], true),
                      }}
                    >
                      <Icon name={globalIcons.MenuDots} size={30} />
                    </ActionIcon>
                  }
                >
                  {deviceType.isMobile && (
                    <DropdownOption
                      icon={globalIcons.MemberList}
                      onClick={openParticipantDrawer}
                    >
                      Members
                    </DropdownOption>
                  )}
                  <DropdownOption
                    icon={globalIcons.Join}
                    onClick={({ addDocumentParticipant }) =>
                      addDocumentParticipant({})
                    }
                    withCommand={{
                      model: ModelName.Document,
                      command: 'AddParticipant',
                      notAllowed: 'hide',
                      queryKey: {
                        id: document.id,
                        organizationId: organization.id,
                      },
                      validate: {
                        memberId: organizationMemberId,
                        role: DocumentRole.Default,
                      },
                    }}
                  >
                    Join
                  </DropdownOption>
                  {isJoined && (
                    <DropdownOption
                      icon={globalIcons.Exit}
                      onClick={({ removeDocumentParticipant }) =>
                        removeDocumentParticipant({})
                      }
                      withCommand={{
                        model: ModelName.Document,
                        command: 'RemoveParticipant',
                        notAllowed: 'disable',
                        queryKey: {
                          id: document.id,
                          organizationId: organization.id,
                        },
                        validate: { memberId: organizationMemberId },
                      }}
                    >
                      Leave
                    </DropdownOption>
                  )}
                  <DropdownDivider />
                  <DropdownOption
                    icon="PiPlusCircle"
                    withCommand={{
                      model: ModelName.Organization,
                      command: 'CreateProcess',
                      queryKey: {
                        id: organization.id,
                      },
                      validate: {
                        documentIds: [document.id],
                      },
                    }}
                    onClick={({ createProcessAsync }) => {
                      void createProcessAsync({}).then((result) => {
                        navigate(
                          RouteMap.Process(
                            organization.slug,
                            result.process.slug,
                          ),
                        )
                      })
                    }}
                  >
                    Create process
                  </DropdownOption>
                  <DropdownOption
                    icon={documentIconEmpty}
                    onClick={openNewDocument}
                    withCommand={{
                      model: ModelName.Document,
                      command: 'CreateDocument',
                      queryKey: {
                        id: document.id,
                        organizationId: organization.id,
                      },
                    }}
                  >
                    Create subcategory
                  </DropdownOption>
                  <DropdownOption
                    icon={globalIcons.Settings}
                    onClick={openEdit}
                    withCommand={{
                      model: ModelName.Document,
                      command: 'UpdateProfile',
                      queryKey: {
                        id: document.id,
                        organizationId: organization.id,
                      },
                    }}
                  >
                    Edit
                  </DropdownOption>
                  <DropdownDivider />
                  <DropdownOption
                    icon={globalIcons.Delete}
                    type="danger"
                    onClick={() => {
                      openDeleteModal()
                    }}
                    withCommand={{
                      model: ModelName.Document,
                      command: 'Delete',
                      queryKey: {
                        id: document.id,
                        organizationId: organization.id,
                      },
                    }}
                  >
                    Delete
                  </DropdownOption>
                </DropdownMenu>
              </Row>
            )
          }
        />
        <Column w="100%">
          <DocumentChildrenGrid
            documentId={document.id}
            organizationId={organization.id}
          />
          <Title order={5} mb="md">
            Processes
          </Title>
          {isEmpty && <DocumentEmptyState documentId={document.id} />}
          <ProcessesAccordion
            processIds={publishedProcesses.map((x) => x.id)}
          />
          {draftProcesses.length > 0 && (
            <>
              <Title order={5} mb="md" mt="xl">
                Drafts
              </Title>
              <ProcessesAccordion
                processIds={draftProcesses.map((x) => x.id)}
              />
            </>
          )}
        </Column>
      </Column>
      {deviceType.isMobile && (
        <DocumentParticipantDrawer
          opened={participantDrawerOpened}
          onClose={closeParticipantDrawer}
        />
      )}
      {!deviceType.isMobile && <DocumentParticipantSidebar />}
      {/* Settings modal */}
      <EditDocumentModal
        queryKey={{ id: document.id, organizationId: organization.id }}
        opened={editOpened}
        onClose={closeEdit}
        settings={{
          deleteButton: true,
          onDelete,
        }}
      />
      {/* New document modal */}
      <NewDocumentModal
        opened={newDocumentOpened}
        onClose={closeNewDocument}
        parentId={document.id}
        organizationId={organization.id}
      />
    </Row>
  )
}

export const DocumentChildrenGrid = ({
  documentId,
  organizationId,
}: {
  documentId: string
  organizationId: string
}) => {
  const { documents } = useOrganizationDocumentsQuery({
    id: organizationId,
  })
  const documentChildren =
    documents.filter((x) => x.parentId === documentId && !x.isArchived) ?? []

  return (
    <SimpleGrid
      cols={{ base: 1, md: 2, lg: 3 }}
      spacing={{ base: 'sm', lg: 'md' }}
      verticalSpacing={{ base: 'sm', lg: 'md' }}
      mb="xl"
    >
      {documentChildren.map((x) => (
        <DocumentItem key={x.id} item={x} />
      ))}
      <AddDocumentButton key={documentChildren.length} />
    </SimpleGrid>
  )
}

const AddDocumentButton = () => {
  const organizationId = useOrganizationContext((x) => x.id)
  const rootDocumentId = useOrganizationContext(
    (x) => x.organization.rootDocumentId,
  )
  const documentId = useDocumentContext((x) => x.id)
  const { createDocument, isPending: isCreatingDocument } = useCommand(
    ModelName.Document,
    'CreateDocument',
    {
      organizationId,
      id: documentId,
    },
  )
  const [title, setTitle] = useState('')

  const submit = useCallback(() => {
    if (title.length === 0 || isCreatingDocument) return
    return createDocument({
      title,
    })
  }, [title, isCreatingDocument])

  const placeholder =
    rootDocumentId === documentId ? 'New category...' : 'Add subcategory...'

  return (
    <Row
      className={classes.addDocument}
      component="form"
      align="stretch"
      onSubmit={(e) => {
        e.preventDefault()
        submit()
      }}
    >
      <Input
        variant="unstyled"
        className={classes.addDocumentInput}
        disabled={isCreatingDocument}
        placeholder={placeholder}
        h="100%"
        maxLength={NAME_MAX_LENGTH}
        leftSection={
          isCreatingDocument ? (
            <Loader color="blue" size={25} />
          ) : (
            <Box opacity={0.7}>
              <Icon name={documentIcon} size={25} />
            </Box>
          )
        }
        rightSection={
          title && (
            <ActionIcon
              className={classes.addDocument__check}
              variant="filled"
              onClick={submit}
              radius="50%"
              size={22}
            >
              <Icon name="PiCheckBold" size={14} />
            </ActionIcon>
          )
        }
        rightSectionPointerEvents="auto"
        classNames={{
          wrapper: classes.addDocument__wrapper,
          input: classes.addDocument__input,
          section: classes.addDocument__section,
        }}
        onChange={(e) => {
          const title = e.currentTarget.value
          setTitle(title)
        }}
      />
    </Row>
  )
}

export const DocumentItem = ({ item }: { item: ClientModel['Document'] }) => {
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const vars = useThemeVars()

  if (!item) return null

  return (
    <Link href={RouteMap.Document(organizationSlug, item.slug)} asChild>
      <Button
        className={classes.documentItem}
        component="a"
        variant="default"
        color="blue"
        justify="stretch"
        c={vars.colors.text}
        pl="sm"
        pr="xs"
        leftSection={
          <Icon
            size={25}
            c={'gray.5'}
            name={documentIcon}
            style={{ flexShrink: 0 }}
          />
        }
        rightSection={
          null
          // <DropdownButton
          //   className={classes.documentMenuButton}
          //   size={36}
          //   variant="transparent"
          //   onClick={(e) => {
          //     e.preventDefault()
          //   }}
          // >
          //   <Menu.Item
          //     component="a"
          //     leftSection={<Icon name="MdOpenInNew" size={18} />}
          //     href={ROUTES.Document(item.id)}
          //     target="_blank"
          //   >
          //     Open in new tab
          //   </Menu.Item>
          //   <Menu.Item
          //     leftSection={<Icon name="PiArchive" size={18} />}
          //     onClick={(e) => {
          //       e.preventDefault()
          //       archiveDocument({
          //         id: item.id,
          //         organizationId: item.organizationId,
          //       })
          //     }}
          //   >
          //     Archive
          //   </Menu.Item>
          // </DropdownButton>
        }
        styles={{
          label: {
            width: '100%',
          },
        }}
      >
        <Text
          ml="xs"
          fw={500}
          ta="start"
          p={0}
          pr={10}
          lh="1.2"
          truncate="end"
          lineClamp={2}
          style={{
            flexShrink: 1,
            flexGrow: 1,
            textWrap: 'wrap',
            whiteSpace: 'break-spaces',
          }}
        >
          {item.title}
        </Text>
      </Button>
    </Link>
  )
}

export const DocumentEmptyState = ({ documentId }: { documentId?: string }) => {
  const navigate = useNavigate()
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)

  return (
    <Column gap="lg" align="flex-start" mt="lg" ml="md">
      <Text fs="italic">{`There doesn't seem to be anything here.`}</Text>
      <ButtonWithCommand
        variant="outline"
        color="blue"
        size="compact-md"
        model={ModelName.Organization}
        command="CreateProcess"
        queryKey={{
          id: organizationId,
        }}
        validate={{
          documentIds: documentId ? [documentId] : [],
        }}
        notAllowed="hide"
        onClick={({ createProcessAsync }) => {
          void createProcessAsync({}).then((result) => {
            navigate(RouteMap.Process(organizationSlug, result.process.slug))
          })
        }}
      >
        Create a process.
      </ButtonWithCommand>
    </Column>
  )
}
