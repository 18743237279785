import { DefaultMantineColor, StyleProp, Text } from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'
import {
  CSSProperties,
  Dispatch,
  KeyboardEventHandler,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactTreeView, {
  INodeRendererProps,
  TreeViewAction,
} from 'react-accessible-treeview'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import { Box, Row } from '~/client/shared/Layout'
import { useAsRef } from '~/client/shared/hooks/useAsRef'
import { Icon } from '~/client/dashboard/components/global/Icon'
import classes from '~/client/dashboard/components/global/TreeView.module.css'
import { ProcessIcon } from '~/client/dashboard/components/process/ProcessComponents'
import { ActiveOrganizationItem } from '~/client/dashboard/stores/OrganizationStore'
import { documentIcon, documentPrivacy } from '~/client/shared/data/document-data'
import { ClientModel } from '~/schemas'
import { differenceBy } from '~/utils/logic'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import { DocumentPrivacy } from '@prisma/client'
import { RouteMap } from '~/client/RouteMap'

export const TREE_RESERVED_KEYS = [
  'ArrowUp',
  'ArrowDown',
  'ArrowRight',
  'ArrowLeft',
  'Shift',
  'Control',
  'Enter',
  'Alt',
  ' ',
]

export const toTreeItem = <
  T extends ClientModel['Document'] | ClientModel['Process'],
>(
  path: string[],
  data: T,
): TreeItem<T> => {
  return {
    id: path.join('-'),
    parent: path.length > 1 ? path.slice(0, path.length - 1).join('-') : null,
    children: [],
    organizationId: data.organizationId,
    path,
    type: data.type,
    name: data.title || 'New ' + data.type,
    data,
  }
}

const traverseDown = (
  path: string[],
  document: ClientModel['Document'],
  documentMap: Map<string, ClientModel['Document']>,
  processMap: Map<string, ClientModel['Process']>,
  cb: (
    path: string[],
    child: ClientModel['Document'] | ClientModel['Process'],
  ) => void,
) => {
  // Traverse document children
  let childDocuments: ClientModel['Document'][] = []
  documentMap.forEach((x) => {
    if (x.parentId === document.id) childDocuments.push(x)
  })
  childDocuments.forEach((x) => {
    const child = documentMap.get(x.id)!
    // TODO: Log to Sentry
    if (!child) return console.warn('Child not found')
    cb(path, child)
    traverseDown([...path, child.id], child, documentMap, processMap, cb)
  })

  // Traverse process children
  let childProcesses: ClientModel['Process'][] = []
  processMap.forEach((x) => {
    if (x.documentIds.includes(document.id)) childProcesses.push(x)
  })
  childProcesses.forEach((x) => {
    const child = processMap.get(x.id)!
    // TODO: Log to Sentry
    if (!child) return console.warn('Child not found')
    cb(path, child)
  })
}

export const buildDocumentFlatTree = <
  T extends ClientModel['Document'] | ClientModel['Process'],
>(
  rootDocumentId: string,
  documents: ClientModel['Document'][],
  processes: ClientModel['Process'][],
  filter?: (item: TreeItem<T>) => boolean,
  filterCategories = false,
): TreeItem<T>[] => {
  const documentMap = new Map(documents.map((x) => [x.id, x]))
  const processMap = new Map(processes.map((x) => [x.id, x]))
  const root = documents.find((x) => x.id === rootDocumentId)!

  if (!root) return []

  const itemMap = new Map<string, TreeItem<T>>()
  itemMap.set(root.id, toTreeItem([root.id], root) as TreeItem<T>)

  traverseDown([root.id], root, documentMap, processMap, (path, child) => {
    const parentId = path.join('-')
    let parentItem = itemMap.get(parentId)!

    if (!parentItem) return

    const childItem = toTreeItem(
      [...parentItem.path, child.id],
      child,
    ) as TreeItem<T>
    const isMatch = !filter || filter(childItem)
    if (isMatch || (!filterCategories && childItem.type === 'Category')) {
      parentItem.children.push(childItem.id)
      itemMap.set(childItem.id, childItem)
    }
  })

  return Array.from(itemMap.values())
}

export type TreeViewActions = {
  selectedIds: Set<string>
  setSelectedIds: (ids: Set<string>) => void
  expandedIds: Set<string>
  setExpandedIds: (ids: Set<string>) => void
  focusItem: (id?: string) => void
}

export type TreeViewProps<T extends DocumentOrProcess> = {
  data: TreeItem<T>[]
  ItemView?: TreeItemView
  appearance?: keyof typeof Appearance
  onItemSelect?: (item: TreeItem<T>) => void
  onKeyDown?: KeyboardEventHandler
  onBlur?: () => void
  onEditContent?: (itemId: string) => void
  selectableFilter?: (item: TreeItem<T>) => boolean
  disabledFilter?: (item: TreeItem<T>) => boolean
  defaultVisibleIds?: string[]
  defaultSelectedIds?: string[]
  // defaultFocusedId?: string
  tabWidth?: number
  maxTabWidth?: number
  style?: CSSProperties
  mah?: string | number
  actionRef?: MutableRefObject<TreeViewActions | undefined>
}

export const TreeView = <T extends DocumentOrProcess>({
  data,
  ItemView,
  appearance = 'default',
  onItemSelect = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
  selectableFilter = () => true,
  disabledFilter = () => false,
  tabWidth = 8,
  maxTabWidth = 1000,
  defaultVisibleIds = [],
  defaultSelectedIds = [],
  onEditContent,
  // defaultFocusedId,
  style,
  mah,
  actionRef,
}: TreeViewProps<T>) => {
  const viewport = useRef<HTMLDivElement>(null)
  // const previousFocusedId = useRef<string | undefined>(defaultFocusedId)
  // const [initialEffectsRan, setInitialEffectsRan] = useState(false)
  const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set())
  const [selectedIds, setSelectedIds] = useState<Set<string>>(
    new Set(defaultSelectedIds),
  )
  const [expandedReady, setExpandedReady] = useState(false)
  const [selectedReady, setSelectedReady] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [dispatch, setDispatch] = useState<Dispatch<TreeViewAction>>()
  const ref = useClickOutside(() => {
    dispatch?.({
      type: 'BLUR',
    })
    onBlur()
  })

  // Set to the value of `data` immediately after render
  const [previousData, setPreviousData] = useState(data)

  const removedItems = differenceBy(previousData, data, 'id')

  const focusItem = (id?: string) => {
    if (!id || !dispatch || !viewport.current) return
    dispatch({
      type: 'FOCUS',
      id,
    })
    const target: HTMLInputElement = viewport.current
      .querySelector(`[data-id="${id}"]`)
      ?.closest('.tree-branch-wrapper')!
    target?.focus()
  }

  const expandToNode = (id: string) => {
    const expanded = new Set(expandedIds)
    data
      .filter((x) => id.includes(x.data.id) && id !== x.data.id)
      .forEach((x) => {
        expanded.add(x.id)
      })
    setExpandedIds(expanded)
  }

  if (actionRef) {
    // Expose actions to parent component
    actionRef.current = {
      selectedIds,
      setSelectedIds,
      expandedIds,
      setExpandedIds,
      focusItem,
    }
  }

  useEffect(() => {
    if (!viewport.current) return
    // Ensure focused is in view
    viewport.current
      .querySelector('.tree-node--focused')
      ?.scrollIntoView({ block: 'center' })
  })

  useEffect(() => {
    if (expandedReady) return
    // Build default expanded nodes using the visible nodes' paths
    const expanded = new Set<string>()
    defaultVisibleIds.forEach((id) => {
      data
        .filter((x) => id.includes(x.data.id) && id !== x.data.id)
        .forEach((x) => {
          expanded.add(x.id)
        })
    })
    setExpandedIds(expanded)
    setExpandedReady(true)
  }, [defaultVisibleIds])

  useEffect(() => {
    if (selectedReady) return
    setSelectedIds(new Set(defaultSelectedIds))
    setSelectedReady(true)
  }, [defaultSelectedIds])

  useEffect(() => {
    selectedIds.forEach(expandToNode)
  }, [selectedIds])

  const _selectedIds = useAsRef(selectedIds)

  // useEffect(() => {
  //   // Run initial effects
  //   if (initialEffectsRan) return
  //   if (dispatch) {
  //     const exists = data.some((x) => x.id === defaultFocusedId)
  //     if (!exists) return

  //     focusItem(defaultFocusedId!)
  //     setInitialEffectsRan(true)
  //   }
  // }, [dispatch, defaultFocusedId, initialEffectsRan])

  // useEffect(() => {
  //   // Focus new item on default change
  //   if (!initialEffectsRan) return
  //   if (!viewport.current) return
  //   if (previousFocusedId.current === defaultFocusedId) return
  //   if (dispatch && defaultFocusedId) {
  //     const exists = data.some((x) => x.id === defaultFocusedId)
  //     if (!exists) return

  //     focusItem(defaultFocusedId)
  //   }

  //   previousFocusedId.current = defaultFocusedId
  // }, [dispatch, previousFocusedId.current, defaultFocusedId])

  const Item = ItemView ?? Appearance[appearance].itemView

  useEffect(() => {
    if (scrolled || !viewport.current || !defaultVisibleIds[0]) return
    viewport.current
      .querySelector(`[data-id="${defaultVisibleIds[0]}"]`)
      ?.scrollIntoView({ block: 'center' })
    setScrolled(true)
  }, [viewport.current, defaultVisibleIds, scrolled])

  useEffect(() => {
    // This block of logic exists to correct an issue inside React Accessible Treeview.
    //  When a node is removed, it needs to be kept in memory for exactly one render.
    setTimeout(() => {
      setPreviousData(data)
    })
  }, [data])

  // Append hidden items for this render
  //  Removed items must be kept around temporarily to allow React Accessible Treeview to run diff checks
  data = [...data, ...removedItems.map((x) => ({ ...x, hidden: true }))]

  return (
    <ErrorBoundary>
      <div
        ref={viewport}
        style={{ height: '100%', width: '100%', maxHeight: mah, ...style }}
        tabIndex={-1}
        onKeyDown={(e) => {
          if (!TREE_RESERVED_KEYS.includes(e.key)) {
            onKeyDown(e)
          }
          if (e.altKey) {
            ;(document.activeElement as HTMLInputElement)?.blur?.()
          }
          if (e.key === 'F2') {
            const focusedId = viewport.current
              ?.querySelector('.tree-node--focused')
              ?.getAttribute('data-id')
            if (focusedId) onEditContent?.(focusedId)
          }
        }}
      >
        {data.length > 1 && (
          <ReactTreeView
            ref={ref}
            data={data}
            selectedIds={Array.from(selectedIds).filter((id) =>
              data.some((x) => x.id === id),
            )}
            expandedIds={Array.from(expandedIds).filter((id) =>
              data.some((x) => x.id === id),
            )}
            expandOnKeyboardSelect={true}
            multiSelect
            onNodeSelect={(x) => {
              if (
                x.isSelected &&
                selectableFilter(x.element as TreeItem<T>) &&
                !selectedIds.has(x.element.id as string)
              ) {
                onItemSelect(x.element as TreeItem<T>)
                setSelectedIds(new Set([x.element.id as string]))
              }
            }}
            onExpand={(x) => {
              const expanded = new Set(expandedIds)
              if (x.isExpanded) {
                expanded.add(x.element.id as string)
                setExpandedIds(expanded)
              } else {
                expanded.delete(x.element.id as string)
                setExpandedIds(expanded)
              }
            }}
            className={classes.root}
            nodeRenderer={(props) =>
              (props.element as TreeItem<T> & { hidden: boolean })
                .hidden ? null : (
                <ItemViewWrapper
                  {...(props as ItemViewProps)}
                  selected={_selectedIds.current.has(
                    props.element.id as string,
                  )}
                  selectable={selectableFilter(props.element as TreeItem<T>)}
                  isDisabled={disabledFilter(props.element as TreeItem<T>)}
                  setDispatch={dispatch ? null : setDispatch}
                  className={Appearance[appearance].className}
                  onClick={(item) => {
                    // Manually emit select event if already selected
                    if (props.isSelected) {
                      onItemSelect(item as TreeItem<T>)
                    }
                  }}
                  tabWidth={tabWidth}
                  maxTabWidth={maxTabWidth}
                >
                  <Item {...(props as ItemViewProps)} />
                </ItemViewWrapper>
              )
            }
          />
        )}
      </div>
    </ErrorBoundary>
  )
}

/**
 * Item
 */

export type DocumentOrProcess = ClientModel['Document'] | ClientModel['Process']
export type TreeItem<T extends DocumentOrProcess = DocumentOrProcess> = {
  // Schema required for react-accessible-treeview
  id: string // Union of path
  parent: string | null // Parent ID in tree
  children: string[] // Child Ids
  organizationId: string
  path: string[]
  type: ActiveOrganizationItem['type']
  name: string

  // Store document as metadata
  data: T
}

export type ItemViewProps = Omit<INodeRendererProps, 'element'> & {
  element: TreeItem
  onClick: (item: TreeItem) => void
}
export type TreeItemView = (props: ItemViewProps) => ReactNode

const ItemViewWrapper = ({
  children,
  tabWidth,
  maxTabWidth,
  className,
  onClick,
  setDispatch,
  selectable,
  selected,
  ...props
}: PropsWithChildren<
  ItemViewProps & {
    tabWidth: number
    maxTabWidth: number
    selectable: boolean
    selected: boolean
    className?: string
    setDispatch: null | ((dispatch: Dispatch<TreeViewAction>) => void | null)
  }
>) => {
  useEffect(() => {
    // This is a roundabout way to access the inner dispatch function
    //  used to perform hidden actions like 'focus'
    setDispatch?.(() => props.dispatch)
  }, [setDispatch])

  return (
    <div
      style={{
        paddingLeft: Math.min(tabWidth * (props.level - 1), maxTabWidth),
      }}
    >
      <div className={className}>
        <Row
          {...props.getNodeProps({
            onClick: (e) => {
              onClick(props.element)
              if (selectable) {
                props.handleSelect(e)
              } else {
                props.handleExpand(e)
              }
            },
          })}
          data-id={props.element.id}
          data-type={props.element.type}
          data-archived={props.element.data.isArchived}
          data-disabled={props.isDisabled}
          data-selected={selected}
          data-selectable={selectable}
        >
          {children}
        </Row>
      </div>
    </div>
  )
}

/**
 * Utilities
 */

TreeView.ItemIcon = ({
  item,
  size,
}: {
  item: ActiveOrganizationItem
  size?: string | number
}) => {
  if (item.type === 'Process') {
    return <ProcessIcon id={item.id} size={size ?? 20} revisionId={null} />
  }

  return <Icon nudgeUp={1} size={size ?? 24} name={documentIcon} />
}

const Caret = ({
  props,
  size = 14,
  iconSize = 12,
}: {
  props: ItemViewProps
  size?: number
  iconSize?: number
}) => {
  const vars = useThemeVars()
  const { handleExpand, isBranch, isExpanded } = props

  return (
    <Box
      className={classes.caret}
      w={size}
      h={size}
      data-is-branch={isBranch}
      align="center"
      justify="center"
      onClick={(e) => {
        e.stopPropagation()
        handleExpand(e)
      }}
      style={{
        flexShrink: 0,
      }}
    >
      {isBranch && (
        <Icon
          nudgeUp={1}
          size={iconSize}
          name={isExpanded ? 'PiCaretDownBold' : 'PiCaretRightBold'}
        />
      )}
    </Box>
  )
}
TreeView.Caret = Caret

export const DefaultTreeItemView: TreeItemView = (props) => {
  const { element } = props
  const icon =
    element.data.privacy !== DocumentPrivacy.Open ? (
      <Box opacity={0.7} ml="xs" align="flex-end">
        <Icon
          nudgeUp={1}
          name={documentPrivacy[element.data.privacy].iconName}
          style={{ display: 'inline' }}
        />
      </Box>
    ) : null

  return (
    <Row style={{ padding: 8 }} gap={6}>
      <TreeView.Caret props={props} size={20} />
      <Box w={24} align="center" justify="center">
        <TreeView.ItemIcon item={element.data} size={20} />
      </Box>
      <Row style={{ flexGrow: 1 }} display={'inline-flex'}>
        <Text size="md" lh={1.2} lineClamp={3}>
          {element.name}
        </Text>
        {icon}
      </Row>
    </Row>
  )
}

const Appearance = {
  default: {
    className: classes.defaultItem,
    itemView: DefaultTreeItemView,
  },
}

export const hrefForTreeItem = (
  organizationSlug: string,
  item: TreeItem,
) => {
  if (item.type === 'Process') {
    return RouteMap.Process(organizationSlug, item.data.slug)
  } else if (item.type === 'Category') {
    return RouteMap.Document(organizationSlug, item.data.slug)
  }
  return RouteMap.Organization(organizationSlug)
}

