import { DocumentChildrenGrid } from '~/client/dashboard/components/document/Document'
import { DocumentProvider } from '~/client/dashboard/stores/DocumentStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { Column } from '~/client/shared/Layout'

export const HomeContent = () => {
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const rootDocumentId = useOrganizationContext(
    (x) => x.organization.rootDocumentId,
  )

  return (
    <Column h="100%" w="100%">
      <DocumentProvider queryKey={{ id: rootDocumentId, organizationId }}>
        <DocumentChildrenGrid
          documentId={rootDocumentId}
          organizationId={organizationId}
        />
      </DocumentProvider>
    </Column>
  )
}
