'use client'
import { FocusTrap } from '@mantine/core'
import {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  ReactFlow,
  type Edge as ReactFlowEdge,
  type Node as ReactFlowNode,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import ELK, { ElkExtendedEdge, ElkNode } from 'elkjs/lib/elk.bundled.js'
import { memo, useEffect, useState } from 'react'
import { useThemeColor } from '~/client/dashboard/components/global/colors'
import { DiagramNodeListWatcher } from '~/client/dashboard/components/process/diagram/DiagramBasisNodes'
import { DiagramControls } from '~/client/dashboard/components/process/diagram/DiagramControls'
import {
  DiagramBasisConnector,
  DiagramBasisNode,
  DiagramBasisNodes,
  DiagramConnector,
  DiagramDirection,
  DiagramNodeData,
  DiagramNodeType,
  MAX_ZOOM,
  MIN_ZOOM,
  edgeDefaults,
  edgeTypes,
  getAddNodeId,
  getHandleId,
  layoutOptionsDefaults,
  nodeSettings,
  nodeTypes,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import { NodePaneWrapper } from '~/client/dashboard/components/process/node/NodePane'
import { useDiagramContext } from '~/client/dashboard/stores/DiagramStore'
import { NodeType } from '~/schemas/node-schema'
import { sortBy } from '~/utils/logic'
import classes from './Diagram.module.css'

const elk = new ELK()

type ElkNodePlus = ElkNode & {
  children?: ElkNodePlus[]
  diagramNodeType: DiagramNodeType
  data: Pick<
    DiagramNodeData,
    | 'id'
    | 'globalId'
    | 'type'
    | 'treePath'
    | 'parentGlobalId'
    | 'connectorsIn'
    | 'connectorsOut'
    | 'textInset'
    | 'isExpanded'
  >
}

type ElkEdgePlus = ElkExtendedEdge & {
  data: DiagramBasisConnector
}

type NodeBuilderState = {
  basisConnectors: DiagramBasisConnector[]
  basisNodes: DiagramBasisNode[]
  // Map is used separately for quick lookups
  basisNodesMap: DiagramBasisNodes
}

type NodeBuilderSettings = {
  direction: DiagramDirection
}

const getElkRevisionNodeChildren = (
  state: NodeBuilderState,
  ctx: {
    parentNode: DiagramBasisNode | undefined
  },
  settings: NodeBuilderSettings,
): { children: ElkNodePlus[]; edges: ElkEdgePlus[] } => {
  const { parentNode } = ctx ?? {}
  const { basisConnectors, basisNodes } = state

  const children: ElkNodePlus[] = []
  const edges: ElkEdgePlus[] = []

  basisNodes.forEach((x) => {
    // Filter out root node and its connections from subprocesses
    if (parentNode && x.isRoot) return
    if (x.parentNodeGlobalId === parentNode?.globalId) {
      const node = getElkNode(state, x, settings)
      children.push(
        node,
        // "Add" Nodes (empty target connectors)
        ...node.data.connectorsOut
          .filter((x) => !x.target.globalId)
          .map((x) => {
            const { layoutOptions, size } = nodeSettings.Add(
              settings.direction,
              1,
            )
            return {
              ...size,
              layoutOptions,
              id: getAddNodeId(x.globalId),
              diagramNodeType: 'add',
              data: { ...node.data, connectorsIn: [x], connectorsOut: [] },
            } as ElkNodePlus
          }),
      )
    }
  })

  basisConnectors.forEach((x) => {
    let origin = state.basisNodesMap.get(x.origin.globalId)
    if (!origin) return

    // Filter out root node and its connections from subprocesses
    if (parentNode && origin.isRoot) return

    const target = x.target.globalId
      ? state.basisNodesMap.get(x.target.globalId)
      : null
    // If globalId is empty, this will replaced with an AddNode (don't return)
    if (x.target.globalId && !target) return

    if (x.parentNodeGlobalId === parentNode?.globalId) {
      edges.push({
        id: x.globalId,
        sources: [getHandleId(x.globalId, 'source')],
        targets: [x.target.globalId ?? getAddNodeId(x.globalId)],
        data: x,
      })
    }
  })

  return { children, edges }
}

const getElkNode = (
  state: NodeBuilderState,
  node: DiagramBasisNode | undefined,
  settings: NodeBuilderSettings,
): ElkNodePlus => {
  const { basisConnectors } = state
  const connectorsIn = node
    ? basisConnectors.filter((x) => x.target.globalId === node.globalId)
    : ([] as DiagramBasisConnector[])
  let connectorsOut =
    node && !node.isExpanded
      ? sortBy(
          basisConnectors.filter((x) => x.origin.globalId === node.globalId),
          'position',
        )
      : ([] as DiagramBasisConnector[])

  const { children, edges } =
    !node || node.isExpanded
      ? getElkRevisionNodeChildren(
          state,
          {
            parentNode: node,
          },
          settings,
        )
      : { children: [], edges: [] }

  if (!node) {
    // Return root node
    return {
      id: 'Root',
      diagramNodeType: 'custom',
      children,
      edges,
      data: {} as DiagramNodeData,
    }
  }

  if (node.type === NodeType.End && node.parentNodeGlobalId) {
    // Connect subdoc end nodes directly to parent connection
    connectorsOut = basisConnectors.filter(
      (x) =>
        x.origin.globalId === node.parentNodeGlobalId && x.position === node.id,
    )
  }

  const { size, getOutgoingPortSettings, layoutOptions, textInsets } =
    nodeSettings[node.type](settings.direction, connectorsOut.length)

  return {
    id: node.globalId,
    diagramNodeType: 'custom',
    layoutOptions,
    ...size,
    children,
    edges,
    ports: [
      ...connectorsIn.map((x) => ({
        id: getHandleId(x.globalId, 'target'),
      })),
      ...connectorsOut.map((x, i) => ({
        id: getHandleId(x.globalId, 'source'),
        ...getOutgoingPortSettings(i),
      })),
    ],
    data: {
      id: node.id,
      globalId: node.globalId,
      type: node.type,
      treePath: node.treePath,
      connectorsIn,
      connectorsOut,
      parentGlobalId: node.parentNodeGlobalId,
      isExpanded: node.isExpanded,
      textInset: textInsets,
    },
  }
}

const useLayoutedElements = () => {
  const direction = useDiagramContext((x) => x.direction)
  const basisContent = useDiagramContext((x) => x.basisContent)
  const theme = useThemeColor()

  const [result, setResult] = useState<{
    nodes: ReactFlowNode[]
    edges: ReactFlowEdge[]
  }>({
    nodes: [],
    edges: [],
  })

  useEffect(() => {
    const basisNodesMap = basisContent.nodes
    const basisConnectorsMap = basisContent.connectors
    const basisNodes = Array.from(basisNodesMap?.values() ?? [])
    const basisConnectors = Array.from(basisConnectorsMap?.values() ?? [])

    console.log({ basisNodes, basisConnectors })

    const graph = getElkNode(
      {
        basisConnectors,
        basisNodes,
        basisNodesMap,
      },
      undefined,
      {
        direction,
      },
    )

    console.log({ graph, direction })

    // Get nodes and positions
    void elk
      .layout(graph, {
        logging: false,
        measureExecutionTime: false,
        layoutOptions: {
          ...layoutOptionsDefaults,
          'elk.direction': direction,
        },
      })
      .then((result: ElkNode) => {
        const nodes = [] as ReactFlowNode<DiagramNodeData>[]
        const edges = [] as DiagramConnector[]

        console.log({ result })

        forEachDown(result as ElkNodePlus, (node, parent) => {
          const hasParent = Boolean(node.data.parentGlobalId)

          edges.push(
            ...((node.edges ?? []) as ElkEdgePlus[]).map((x) => {
              // NOTE: Could leverage multiple sections from Elk layout
              const startPoint = x.sections![0].startPoint
              const endPoint = x.sections!.at(-1)!.endPoint

              return {
                ...edgeDefaults,
                markerEnd: {
                  ...edgeDefaults.markerEnd,
                  color: theme(['gray', 7], ['gray', 1], true),
                },
                id: x.id,
                source: x.data.origin.globalId,
                target: x.data.target.globalId ?? getAddNodeId(x.id),
                sourceHandle: getHandleId(x.id, 'source'),
                targetHandle: getHandleId(x.id, 'target'),
                type: 'custom',
                data: {
                  ...x.data,
                  startPoint,
                  endPoint,
                },
              } as DiagramConnector
            }),
          )

          const parentPosition = parent
            ? {
                x: parent.x ?? 0,
                y: parent.y ?? 0,
              }
            : {
                x: 0,
                y: 0,
              }

          if (node.data.type === NodeType.End) {
            // Re-map connectors from expanded subdocs to their end nodes
            const connector = edges.find(
              (x) =>
                x.source === node.data.parentGlobalId &&
                x.data.position === node.data.id,
            )
            if (connector) {
              connector.source = node.id
            }
          }

          const hydratedNode = {
            id: node.id,
            type: node.diagramNodeType,
            position: { x: node.x ?? 0, y: node.y ?? 0 },
            width: node.width,
            height: node.height,
            parentNode: node.data.parentGlobalId,
            extent: hasParent ? 'parent' : undefined,
            style: {
              zIndex: 1,
            },
            data: {
              ...node.data,
              parentPosition,
              connectorsIn: node.data.connectorsIn,
              connectorsOut: node.data.connectorsOut,
            },
          } as ReactFlowNode<DiagramNodeData>

          if (parent) {
            // Don't add root node to diagram
            nodes.push(hydratedNode)
          }
        })

        setResult({
          nodes,
          edges,
        })
      })
  }, [basisContent, direction])

  return result
}

type DiagramProps = {}

export const Diagram = ({}: DiagramProps) => {
  const ref = useDiagramContext((x) => x.diagramElementRef)
  const setCurrentNode = useDiagramContext((x) => x.setCurrentNode)

  return (
    <>
      <div className={classes.diagramShadow}></div>
      <div
        className={classes.diagram}
        ref={ref}
        tabIndex={1}
        onMouseDownCapture={() => {
          // Trigger document click (close modals, etc.)
          document.body.dispatchEvent(
            new MouseEvent('mousedown', { bubbles: true }),
          )
        }}
        onClick={(e) => {
          const target = e.target as HTMLElement
          if (
            target.closest('input') ||
            target.closest('button') ||
            target.closest('.react-flow__node') ||
            target.closest(`.${classes.diagramNodeWrapper}`)
          )
            return
          setCurrentNode(null)
        }}
      >
        <FocusTrap active={true}>
          <div style={{ height: '100%', width: '100%' }}>
            <DiagramContent />
          </div>
          {/* Controls overlay */}
          <DiagramControls />
        </FocusTrap>
        {/* Node overlay */}
        <NodePaneWrapper />
      </div>
    </>
  )
}

const DiagramContent = memo(() => {
  const theme = useThemeColor()
  const { nodes, edges } = useLayoutedElements()

  // TODO: REMOVE
  console.log({
    nodes,
    edges,
  })

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes as any}
        edgeTypes={edgeTypes}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        style={{ padding: 40 }}
        edgesUpdatable={false}
        edgesFocusable={false}
        nodesConnectable={false}
        connectionLineType={ConnectionLineType.SmoothStep}
        proOptions={{
          hideAttribution: true,
        }}
        fitView={true}
        fitViewOptions={{
          maxZoom: 1,
          minZoom: MIN_ZOOM,
        }}
      >
        <DiagramNodeListWatcher />
        <Background
          gap={40}
          color={theme(['gray', 3], ['gray', 7], true)}
          variant={BackgroundVariant.Cross}
        />
      </ReactFlow>
    </>
  )
})

/**
 * Util
 */

type SceneNode = {
  id: string
  children?: SceneNode[]
  [prop: string]: any
}

// Move down the scene graph, executing side effects each child
const forEachDown = <T extends SceneNode>(
  node: T,
  fn: (next: T, parent?: T) => void,
): void => {
  fn(node)
  const children = node.children || []
  children.forEach((x) =>
    forEachDown(x, (next, parent) => {
      // @ts-ignore
      fn(next, parent || node)
    }),
  )
}
