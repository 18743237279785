import {
  Combobox,
  ComboboxProps,
  ComboboxStore,
  Highlight,
  InputBase,
  MantineSize,
  Modal,
  ScrollArea,
  Text,
  TextInputProps,
  Tooltip,
  useCombobox,
} from '@mantine/core'
import { getHotkeyHandler } from '@mantine/hooks'
import {
  Fragment,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link } from 'wouter'
import { Box, Column, Row } from '~/client/shared/Layout'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  DocumentOrProcess,
  TreeItem,
  TreeItemView,
  TreeView,
  TreeViewActions,
} from '~/client/dashboard/components/global/TreeView'
import { uniqBy } from '~/utils/logic'
import classes from './Search.module.css'
import { ClientModel } from '~/schemas'
import fuzzysort from 'fuzzysort'

type TreeSearch<T extends DocumentOrProcess = DocumentOrProcess> = {
  data: TreeItem<T>[]
  defaultVisibleIds?: string[]
  defaultSelectedIds?: string[]
  dropdown?: boolean
  filter?: (item: TreeItem<T>) => boolean
  disabledFilter?: (item: TreeItem<T>) => boolean
  onSelect: (item: TreeItem<T>) => void
  withinPortal?: boolean
  input?: SearchInputProps
  asSelect?: boolean
  inputRef?: MutableRefObject<HTMLInputElement | null>
  resetRef?: MutableRefObject<(item?: any, clear?: boolean) => void>
  comboboxRef?: MutableRefObject<ComboboxStore | null>
  ItemView?: TreeItemView
}
export const TreeSearch = <
  T extends ClientModel['Document'] | ClientModel['Process'],
>({
  data,
  defaultVisibleIds,
  defaultSelectedIds,
  dropdown = false,
  expandSearch,
  height,
  asSelect,
  filter = () => true,
  disabledFilter = () => false,
  withinPortal,
  input,
  inputRef,
  resetRef,
  comboboxRef,
  ItemView,
  onSelect,
}: TreeSearch<T> & {
  expandSearch?: ExpandSearch
  height?: string | number
}) => {
  const _inputRef = useRef<HTMLInputElement>(null)
  const _resetRef = useRef<() => void>(() => {})
  const actionRef = useRef<TreeViewActions>()

  inputRef = inputRef ?? _inputRef
  resetRef = resetRef ?? _resetRef

  // Convert to flat searchable list
  const listData = uniqBy(
    data.map((x) => ({
      ...x,
      id: x.data.id,
    })),
    'id',
  )

  return (
    <ItemSearch
      data={listData}
      filter={(x) => filter(x) && !disabledFilter(x)}
      onOptionSubmit={(item) =>
        onSelect(data.find((x) => x.data.id === item.id)!)
      }
      focusNext={() => {
        actionRef.current?.focusItem(data[1]?.id)
      }}
      onInputFocus={() => {}}
      dropdown={dropdown}
      expandSearch={expandSearch}
      resetRef={resetRef}
      inputRef={inputRef}
      comboboxRef={comboboxRef}
      input={input}
      height={height}
      asSelect={asSelect}
      withinPortal={withinPortal}
      ItemIcon={({ item }) => <TreeView.ItemIcon item={item} size={24} />}
    >
      {data.length > 1 && (
        <Column>
          <TreeView
            onKeyDown={() => {
              inputRef.current?.focus()
            }}
            data={data}
            defaultVisibleIds={defaultVisibleIds}
            defaultSelectedIds={defaultSelectedIds}
            disabledFilter={disabledFilter}
            actionRef={actionRef}
            onItemSelect={(item) => {
              onSelect(item)
              resetRef.current?.(null, true)
            }}
            selectableFilter={filter}
            ItemView={ItemView}
          />
        </Column>
      )}
      {/* Empty tree will have length=1 (root) */}
      {data.length === 1 && (
        <Combobox.Empty>
          <Row p={10} pb={dropdown ? 10 : 16} justify="center">
            Nothing found
          </Row>
        </Combobox.Empty>
      )}
    </ItemSearch>
  )
}

export const TreeSearchModal = ({
  opened,
  onClose,
  size,
  mah,
  title,
  ...props
}: TreeSearch & {
  opened: boolean
  onClose: () => void
  size?: MantineSize | string | number
  mah?: string | number
  title?: string
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const resetRef = useRef<(item?: any, clear?: boolean) => void>(() => {})

  return (
    <Modal
      opened={opened}
      withCloseButton={false}
      onClose={() => {
        onClose()
      }}
      padding={0}
      keepMounted={true}
      size={size}
      trapFocus={false}
      title={title}
      yOffset="18vh"
      transitionProps={{
        onEntered() {
          inputRef.current?.focus()
        },
        onExited() {
          resetRef.current(null, true)
        },
      }}
      styles={{
        body: {
          overflow: 'hidden',
          display: 'flex',
          maxHeight: mah,
        },
      }}
    >
      <TreeSearch inputRef={inputRef} resetRef={resetRef} {...props} />
    </Modal>
  )
}

type SearchInputProps = Omit<
  TextInputProps,
  'onFocus' | 'onBlur' | 'onClick' | 'onChange' | 'onKeyDown'
>

export type ExpandSearch = {
  inactiveWidth: string | number
  activeWidth: string | number
}

export type SearchItem = {
  id: string
  name: string
  path?: string[] // Optionally display path above item
  highlighted?: ReactNode
}

export type ItemSearchProps<T extends SearchItem> = {
  data: T[]
  onOptionSubmit?: (item: T) => void
  filter?: (item: T) => boolean
  focusNext?: () => void
  onInputFocus?: () => void
  onActive?: () => void
  onInactive?: () => void
  onSearchChange?: (text: string) => void
  children?: ReactNode
  dropdown?: boolean
  dropdownWidth?: number | string
  expandSearch?: ExpandSearch
  dropdownPosition?: ComboboxProps['position']
  resetRef?: MutableRefObject<(selectedItem?: T, clearSearch?: boolean) => void>
  inputRef?: MutableRefObject<HTMLInputElement | null>
  comboboxRef?: MutableRefObject<ComboboxStore | null>
  input?: SearchInputProps
  asSelect?: boolean
  placeholder?: string
  width?: number | string
  height?: number | string
  withinPortal?: boolean
  itemHref?: (item: T) => string
  ItemWrapper?: (props: PropsWithChildren<{ item: T }>) => ReactNode
  ItemIcon?: (props: { item: T }) => ReactNode
}

const DropdownWrapper = ({
  dropdown,
  children,
}: PropsWithChildren<{ dropdown: boolean }>) => {
  if (dropdown) {
    return <Combobox.Dropdown>{children}</Combobox.Dropdown>
  } else {
    return children
  }
}

export const ItemSearch = <T extends SearchItem>({
  data,
  onOptionSubmit,
  filter,
  focusNext,
  onInputFocus,
  onSearchChange,
  onActive,
  onInactive,
  children,
  dropdown = false,
  dropdownPosition = 'bottom-start',
  dropdownWidth,
  expandSearch,
  resetRef,
  inputRef,
  comboboxRef,
  input,
  asSelect,
  withinPortal = false,
  placeholder = 'Search...',
  width,
  height,
  itemHref,
  ItemWrapper: _ItemWrapper,
  ItemIcon,
}: ItemSearchProps<T>) => {
  const navigate = useNavigate()
  const combobox = useCombobox({
    onDropdownClose() {
      updateFocus()
    },
  })
  if (comboboxRef) comboboxRef.current = combobox
  const containerRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [resultData, setResultData] = useState<typeof data>([])
  const searchVal = search.trim().toLowerCase()

  const isFiltered = searchVal.length > 0

  useEffect(() => {
    if (isFocused) {
      onActive?.()
    } else {
      onInactive?.()
    }
  }, [isFocused])

  useEffect(() => {
    onSearchChange?.(searchVal)
  }, [searchVal])

  useEffect(() => {
    const filteredData = filter ? data.filter(filter) : data
    setResultData(
      fuzzysort
        .go(searchVal, filteredData, {
          keys: ['name' /* , 'path' */],
          threshold: -10000,
          limit: 15,
          all: false,
        })
        .map((x) => ({
          ...x.obj,
          highlighted: (
            <>
              {x[0]
                ? fuzzysort.highlight(x[0], (x, i) => (
                    <span
                      key={i}
                      style={{
                        color: 'var(--text-color-important)',
                        fontWeight: 700,
                      }}
                    >
                      {x}
                    </span>
                  ))
                : null}
            </>
          ),
        })),
    )
  }, [searchVal, data])

  const updateFocus = () => {
    setTimeout(() => {
      const isFocused = Boolean(
        containerRef.current?.contains(document.activeElement),
      )
      setIsFocused(isFocused)
    })
  }

  const reset = (item?: T, clear?: boolean) => {
    if (clear) setSearch('')
    ;(document.activeElement as HTMLInputElement)?.blur?.()
    if (dropdown) combobox.closeDropdown()
    combobox.resetSelectedOption()
    updateFocus()
    setTimeout(() => {
      // Handle nav for
      if (inputRef?.current) inputRef.current.value = ''
      if (item && itemHref) navigate(itemHref(item))
    })
  }
  if (resetRef) resetRef.current = reset

  const InputWrapper = dropdown ? Combobox.Target : Combobox.EventsTarget

  useEffect(() => {
    if (asSelect) return
    if (dropdown && !isFocused) combobox.closeDropdown()
    if (dropdown && isFocused) combobox.openDropdown()
  }, [dropdown, isFocused, asSelect])

  useEffect(() => {
    if (isFiltered) {
      combobox.selectFirstOption()
    }
  }, [isFiltered, resultData.length])

  return (
    <Box
      w="100%"
      ref={containerRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        overflow: 'auto',
      }}
    >
      <Combobox
        store={combobox}
        offset={4}
        width={dropdownWidth ?? 'target'}
        position={dropdownPosition}
        transitionProps={{ transition: 'pop' }}
        withinPortal={withinPortal}
        keepMounted={true}
        onOptionSubmit={(val) => {
          const item = data.find((x) => x.id === val)!
          onOptionSubmit?.(item)
          reset(item)
        }}
      >
        <Row w="100%">
          <InputWrapper>
            <InputBase
              ref={inputRef}
              size="md"
              // m={6}
              w={
                expandSearch
                  ? isFocused
                    ? expandSearch.activeWidth
                    : expandSearch.inactiveWidth
                  : '100%'
              }
              style={{
                transition: '150ms ease width',
                maxWidth: width ?? 'none',
              }}
              styles={{ root: { flexGrow: 1 } }}
              onKeyDown={getHotkeyHandler([
                [
                  'Escape',
                  (e) => {
                    if (children && search.length > 0) {
                      setSearch('')
                      e.stopPropagation()
                    } else if (dropdown && combobox.dropdownOpened) {
                      combobox.closeDropdown()
                      e.stopPropagation()
                    } else {
                      combobox.targetRef.current?.blur()
                    }
                  },
                ],
                [
                  'ArrowDown',
                  (e) => {
                    // Focus first item in tree
                    if (!isFiltered) {
                      setTimeout(() => {
                        focusNext?.()
                      }, 100)
                    }
                  },
                ],
              ])}
              value={search}
              onChange={(event) => {
                if (dropdown) combobox.openDropdown()
                const value = event.currentTarget.value
                setSearch(value)
                combobox.updateSelectedOptionIndex()
                if (value.length === 0) {
                  combobox.resetSelectedOption()
                }
              }}
              onFocus={() => {
                updateFocus()
                onInputFocus?.()
              }}
              onBlur={() => {
                updateFocus()
              }}
              onClick={() => {
                if (asSelect) combobox.toggleDropdown()
              }}
              leftSectionPointerEvents="none"
              placeholder={placeholder}
              {...input}
            />
            {/* </Row> */}
          </InputWrapper>
        </Row>
        <DropdownWrapper dropdown={dropdown}>
          <ScrollArea.Autosize
            style={{
              flex: '1 1',
              display: 'flex',
              overflow: 'hidden',
              minHeight: 0,
              maxHeight: height,
            }}
            styles={{
              viewport: {
                flex: '1 1',
              },
            }}
          >
            {(isFiltered || !children) && (
              <Combobox.Options>
                {resultData.length > 0 ? (
                  resultData.map((item) => (
                    <Combobox.Option
                      key={item.id}
                      value={item.id}
                      className={classes.searchItemOption}
                    >
                      <TreeSearchItem
                        data={data}
                        item={item}
                        itemHref={itemHref}
                        ItemIcon={ItemIcon}
                      />
                    </Combobox.Option>
                  ))
                ) : (
                  <Combobox.Empty>
                    <Row p={10} pb={dropdown ? 10 : 16} justify="center">
                      Nothing found
                    </Row>
                  </Combobox.Empty>
                )}
              </Combobox.Options>
            )}
            <div style={{ display: isFiltered ? 'none' : 'block' }}>
              {children}
            </div>
          </ScrollArea.Autosize>
        </DropdownWrapper>
      </Combobox>
    </Box>
  )
}

export const TreeSearchItem = <T extends SearchItem>({
  data,
  item,
  itemHref,
  ItemIcon,
}: {
  data: T[]
  item: T
  itemHref?: (item: T) => string
  ItemIcon?: (props: { item: T }) => ReactNode
}) => {
  return (
    <Row
      className={classes.searchItemContent}
      mih={32}
      gap={10}
      {...(itemHref && {
        component: Link,
        href: itemHref(item),
      })}
    >
      {ItemIcon && (
        <Box shrink={0} justify="center" align="center" w={24}>
          <ItemIcon item={item} />
        </Box>
      )}
      <Column>
        {item.path && <ItemSearch.Path path={item.path} data={data} />}
        <ItemSearch.Name name={item.highlighted ?? item.name} />
      </Column>
    </Row>
  )
}

ItemSearch.Path = ({
  path,
  data,
  startIndex = 1,
}: {
  path: string[]
  data: SearchItem[]
  startIndex?: number
}) => {
  // TODO: This should accept the actual path values
  const pathItems = path
    .map((id) => data.find((x) => x.id === id))
    .filter(Boolean) as SearchItem[]
  const pathItemsDisplayed = pathItems.slice(
    Math.max(path.length - 3, startIndex),
    path.length - 1,
  )

  if (pathItemsDisplayed.length === 0) return null

  return (
    <Tooltip
      openDelay={900}
      position="top-start"
      ml={-34}
      label={pathItems
        .slice(startIndex)
        .map((x) => x.name)
        .join('  ‣  ')}
    >
      <Row opacity={0.6} style={{ flexShrink: 0 }}>
        <Row gap={3} h={12}>
          {path.length - startIndex > 3 && (
            <>
              <Text fz="xs" mr={2}>
                ...
              </Text>
              <Box opacity={0.5}>
                <Icon name="PiCaretRightBold" size={10} />
              </Box>
            </>
          )}
          {pathItemsDisplayed.map((pathItem) => {
            return (
              <Fragment key={pathItem.id}>
                <Text fz="xs" fw={500} maw={120} truncate="end">
                  {data.find((x) => x.id === pathItem.id)?.name}
                </Text>
                <Box opacity={0.5}>
                  <Icon name="PiCaretRightBold" size={10} />
                </Box>
              </Fragment>
            )
          })}
        </Row>
      </Row>
    </Tooltip>
  )
}

ItemSearch.Name = ({ name }: { name: string | ReactNode }) => {
  return (
    <Text size="md" lineClamp={3}>
      {name}
    </Text>
  )
}
