import {
  DocumentOrProcess,
  TreeItem,
} from '~/client/dashboard/components/global/TreeView'
import type { SettingsItem } from '~/client/dashboard/components/settings/SettingsShell.tsx'
import type { ActiveOrganizationItem } from '~/client/dashboard/stores/OrganizationStore.tsx'

// Absolute route paths
export const RouteMap = {
  /**
   * Dashboard
   */

  DashboardHome: () => `~/dashboard`,
  Organization: (organizationSlug: string) =>
    `~/dashboard/o/${organizationSlug}`,
  TeamExplorer: (organizationSlug: string) =>
    `~/dashboard/o/${organizationSlug}/teams`,
  Team: (organizationSlug: string, slug: string) =>
    `~/dashboard/o/${organizationSlug}/teams/${slug}`,
  Drafts: (organizationSlug: string) =>
    `~/dashboard/o/${organizationSlug}/drafts`,
  Document: (organizationSlug: string, slug: string) =>
    `~/dashboard/o/${organizationSlug}/category/${slug}`,
  Process: (
    organizationSlug: string,
    slug: string,
    revisionVersion?: string | number,
    nodeId?: string,
  ) =>
    `~/dashboard/o/${organizationSlug}/process/${slug}${revisionVersion ? '/' + revisionVersion : ''}${nodeId ? '/' + nodeId : ''}`,

  // Settings
  Settings: (item: SettingsItem, organizationSlug?: string) => {
    if (organizationSlug) {
      return `~/dashboard/o/${organizationSlug}/settings${SettingsRouteMap[item]}`
    } else {
      return `~/dashboard/settings${SettingsRouteMap[item]}`
    }
  },

  /**
   * Marketing
   */

  Home: () => '~/',
  About: () => `~/about`,
  Login: () => `~/login`,
  Signup: () => `~/signup`,

  /**
   * Standalone
   */

  Invite: (code: string) => `/invite/${code}`,
}

const SettingsRouteMap = {
  Profile: '/profile',
  Account: '/account',
  Appearance: '/appearance',
  Invitations: '/invitations',
  Members: '/members',
} satisfies {
  [item in SettingsItem]: string
}
