import { z } from 'zod'
import { ModelCommandsSchema } from '~/commands/base-commands'
import { RevisionSchema } from '~/schemas/revision-schema'

/**
 * ProcessMember commands schema
 */

export const processMemberCommandsSchema = {
  SetActiveRevision: {
    summary: `Set active process revision for process member`,
    description: ``,
    method: 'PATCH',
    schemas: {
      input: z.object({
        revisionId: RevisionSchema.shape.id,
      }),
      output: z.literal(true),
      validate: {
        sender: {},
        target: {
          isSender: z.boolean(),
        },
        meta: {},
      },
      authorize: {
        sender: {},
        target: {},
        meta: {},
      },
    },
    messages: {},
    variables: {
      execute: 'setActiveRevision',
      isPending: 'isSettingActiveRevision',
    },
  },
} as const satisfies ModelCommandsSchema
