import { useRef, useEffect } from 'react'

export const useRequestAnimationFrame = (
  callback: (time: number) => void,
  condition: boolean,
) => {
  const requestRef = useRef<number>()
  const previousTimeRef = useRef<number>()

  const animate = (time: number) => {
    if (previousTimeRef.current) callback(time - previousTimeRef.current)
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    if (!condition) return
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current!)
  }, [condition])
}
