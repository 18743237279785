import { OrganizationRole } from '@prisma/client'
import { Simplify } from 'type-fest'
import { z, ZodTypeAny } from 'zod'
import { ModelName } from '~/commands/base-commands'
import {
  ClientDocumentMemberSchema,
  DocumentMemberQueryKey,
  DocumentMemberSchema,
} from '~/schemas/document-member-schema'
import {
  ClientDocumentSchema,
  DocumentQueryKey,
  DocumentSchema,
} from '~/schemas/document-schema'
import {
  ClientInvitationRedemptionSchema,
  InvitationRedemptionQueryKey,
  InvitationRedemptionSchema,
} from '~/schemas/invitation-redemption-schema'
import {
  ClientInvitationSchema,
  OrganizationInvitationQueryKey,
  OrganizationInvitationSchema,
} from '~/schemas/invitation-schema'
import {
  ClientNodeDetailSchema,
  NodeDetailQueryKey,
  NodeDetailSchema,
  NodeSchemaBase,
} from '~/schemas/node-schema'
import {
  ClientOrganizationMemberSchema,
  OrganizationMemberQueryKey,
  OrganizationMemberSchema,
} from '~/schemas/organization-member-schema'
import {
  ClientOrganizationSchema,
  OrganizationQueryKey,
  OrganizationSchema,
} from '~/schemas/organization-schema'
import {
  ClientProcessSchema,
  ProcessQueryKey,
  ProcessSchema,
} from '~/schemas/process-schema'
import {
  ClientRevisionSchema,
  ProcessContentInputSchema,
  RevisionPublicationSchema,
  RevisionQueryKey,
  RevisionSchema,
} from '~/schemas/revision-schema'
import {
  ClientTeamMemberSchema,
  TeamMemberQueryKey,
  TeamMemberSchema,
} from '~/schemas/team-member-schema'
import {
  ClientTeamSchema,
  TeamQueryKey,
  TeamSchema,
} from '~/schemas/team-schema'
import {
  ClientUserSchema,
  UserQueryKey,
  UserSchema,
  CurrentUserQueryKey,
} from '~/schemas/user-schema'
import './prisma-json'
import {
  ClientProcessMemberProfileSchema,
  ProcessMemberProfileQueryKey,
  ProcessMemberProfileSchema,
} from '~/schemas/process-member-schema.ts'

/**
 * Routes
 */

export type Model = {
  [Name in keyof typeof Model]: Simplify<z.infer<(typeof Model)[Name]>>
}
export const Model = {
  User: UserSchema,
  Organization: OrganizationSchema,
  OrganizationMember: OrganizationMemberSchema,
  Team: TeamSchema,
  TeamMember: TeamMemberSchema,
  Document: DocumentSchema,
  DocumentMember: DocumentMemberSchema,
  Process: ProcessSchema,
  ProcessMember: ProcessMemberProfileSchema,
  Revision: RevisionSchema,
  NodeDetail: NodeDetailSchema,
  Invitation: OrganizationInvitationSchema,
  InvitationRedemption: InvitationRedemptionSchema,
} satisfies {
  [Model in ModelName]: ZodTypeAny
}

export type ClientModel = {
  [Name in keyof typeof ClientModel]: Simplify<
    z.infer<(typeof ClientModel)[Name]>
  >
}
export const ClientModel = {
  User: ClientUserSchema,
  Organization: ClientOrganizationSchema,
  OrganizationMember: ClientOrganizationMemberSchema,
  Team: ClientTeamSchema,
  TeamMember: ClientTeamMemberSchema,
  Document: ClientDocumentSchema,
  DocumentMember: ClientDocumentMemberSchema,
  Process: ClientProcessSchema,
  ProcessMember: ClientProcessMemberProfileSchema,
  Revision: ClientRevisionSchema,
  NodeDetail: ClientNodeDetailSchema,
  Invitation: ClientInvitationSchema,
  InvitationRedemption: ClientInvitationRedemptionSchema,
} satisfies {
  [Model in ModelName]: ZodTypeAny
}

export type ModelQueryKey = {
  [Name in keyof typeof ModelQueryKey]: Simplify<
    z.infer<(typeof ModelQueryKey)[Name]>
  >
}
export const ModelQueryKey = {
  User: UserQueryKey,
  Organization: OrganizationQueryKey,
  OrganizationMember: OrganizationMemberQueryKey,
  Team: TeamQueryKey,
  TeamMember: TeamMemberQueryKey,
  Document: DocumentQueryKey,
  DocumentMember: DocumentMemberQueryKey,
  Process: ProcessQueryKey,
  ProcessMember: ProcessMemberProfileQueryKey,
  Revision: RevisionQueryKey,
  NodeDetail: NodeDetailQueryKey,
  Invitation: OrganizationInvitationQueryKey,
  InvitationRedemption: InvitationRedemptionQueryKey,
} satisfies {
  [Model in ModelName]: ZodTypeAny
}

export type ModelQueryKeyShape = typeof ModelQueryKey

export const QuerySchema = {
  User: {
    // CurrentUser queries
    getBootstrap: CurrentUserQueryKey,
    getCurrent: CurrentUserQueryKey,

    // User queries
    get: UserQueryKey,
    getOrganizations: UserQueryKey,
  },
  Organization: {
    bootstrap: OrganizationQueryKey,
    get: OrganizationQueryKey,
    getTeams: OrganizationQueryKey,
    getMembers: OrganizationQueryKey,
    getDocuments: OrganizationQueryKey,
    getProcesses: OrganizationQueryKey,
    getRevisions: OrganizationQueryKey,
    getInvitations: OrganizationQueryKey,
  },
  OrganizationMember: {
    get: OrganizationMemberQueryKey,
    getDocumentProfiles: OrganizationMemberQueryKey,
  },
  Team: {
    get: TeamQueryKey,
    getMembers: TeamQueryKey,
  },
  TeamMember: {
    get: TeamMemberQueryKey,
  },
  Document: {
    get: DocumentQueryKey,
    getMembers: DocumentQueryKey,
  },
  DocumentMember: {
    get: DocumentMemberQueryKey,
  },
  Process: {
    get: ProcessQueryKey,
    getRevisions: ProcessQueryKey,

    // Commands
    updateRevisionSettings: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
      revisionId: RevisionPublicationSchema.shape.revisionId,
      title: RevisionSchema.shape.title.optional(),
    }),
    setActiveRevision: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
      revisionId: RevisionSchema.shape.id,
    }),
    deleteRevision: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
      revisionId: RevisionSchema.shape.id,
    }),
    setContent: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
      revisionId: RevisionSchema.shape.id,
      content: ProcessContentInputSchema,
    }),
    archive: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
    }),
    unarchive: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
    }),
    delete: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
    }),
    // TODO: Rethink this
    reportView: z.object({
      id: ProcessSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
    }),
    setNodeDescription: z.object({
      processId: ProcessSchema.shape.id,
      revisionId: RevisionSchema.shape.id,
      nodeId: NodeSchemaBase.shape.id,
      description: NodeDetailSchema.shape.description,
      organizationId: OrganizationSchema.shape.id,
    }),
  },
  ProcessMember: {
    get: ProcessMemberProfileQueryKey,
  },
  Revision: {
    get: RevisionQueryKey,
    getContent: RevisionQueryKey,
    getNodeDetails: RevisionQueryKey,
  },
  NodeDetail: {
    get: NodeDetailQueryKey,
  },
  Invitation: {
    get: OrganizationInvitationQueryKey,
    getRedemptions: OrganizationInvitationQueryKey,

    // Commands
    updateInvitation: z.object({
      id: OrganizationInvitationSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
      role: OrganizationInvitationSchema.shape.role.optional(),
      label: OrganizationInvitationSchema.shape.label.optional(),
      validUntil: OrganizationInvitationSchema.shape.validUntil.optional(),
      inviteMessage:
        OrganizationInvitationSchema.shape.inviteMessage.optional(),
      emailToMatch: OrganizationInvitationSchema.shape.emailToMatch.optional(),
      memberAlias: OrganizationInvitationSchema.shape.memberAlias.optional(),
      memberImageUrl:
        OrganizationInvitationSchema.shape.memberImageUrl.optional(),
      memberTitle: OrganizationInvitationSchema.shape.memberTitle.optional(),
      memberDescription:
        OrganizationInvitationSchema.shape.memberDescription.optional(),
      memberIsDisplayLocked:
        OrganizationInvitationSchema.shape.memberIsDisplayLocked.optional(),
    }),
    validateInvitation: z.object({
      code: OrganizationInvitationSchema.shape.id,
    }),
    redeemInvitation: z.object({
      code: OrganizationInvitationSchema.shape.id,
    }),
    archiveInvitation: z.object({
      id: OrganizationInvitationSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
    }),
    unarchiveInvitation: z.object({
      id: OrganizationInvitationSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
    }),
    deleteInvitation: z.object({
      id: OrganizationInvitationSchema.shape.id,
      organizationId: OrganizationSchema.shape.id,
    }),
  },
  InvitationRedemption: {
    get: InvitationRedemptionQueryKey,
  },
} satisfies {
  [Model in ModelName]: {
    [query: string]: ZodTypeAny
  }
}

export type BootstrapDocument = z.infer<typeof BootstrapDocument>
export const BootstrapDocument = ClientModel.Document.merge(
  z.object({
    members: z.array(ClientDocumentMemberSchema),
  }),
)

export type BootstrapProcess = z.infer<typeof BootstrapProcess>
export const BootstrapProcess = z.object({
  process: ClientProcessSchema,
  activeRevision: ClientRevisionSchema,
  mainRevision: ClientRevisionSchema.nullable(),
  memberProfile: ProcessMemberProfileSchema.nullable(),
})

export type BootstrapOrganization = z.infer<typeof BootstrapOrganization>
export const BootstrapOrganization = ClientModel.Organization.merge(
  z.object({
    documents: z.array(BootstrapDocument),
    processes: z.array(BootstrapProcess),
    revisions: z.array(ClientRevisionSchema),
    members: z.array(ClientOrganizationMemberSchema),
  }),
)

export type BootstrapOrganizationProfile = z.infer<
  typeof BootstrapOrganizationProfile
>
export const BootstrapOrganizationProfile = z.object({
  id: z.string(),
  userId: z.string(),
  role: z.nativeEnum(OrganizationRole),
  organization: BootstrapOrganization,
})

export type Bootstrap = z.infer<typeof Bootstrap>
export const Bootstrap = z.object({
  user: UserSchema,
  profiles: z.array(BootstrapOrganizationProfile),
})
