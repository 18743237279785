import { useDocumentMemberQueryList } from '~/client/dashboard/queries/document-member-queries'
import { useOrganizationDocumentsQuery } from '~/client/dashboard/queries/organization-queries'
import { useProcessMemberProfileQuery } from '~/client/dashboard/queries/process-member-queries'
import { useProcessQuery } from '~/client/dashboard/queries/process-queries'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'
import { getMemberProcessAccess } from '~/commands/process/process-commands-validation'
import { ClientModel, ModelQueryKey } from '~/schemas'

export const useDocumentsWithProfileQuery = ({
  documentIds,
  organizationId,
  organizationMemberId,
}: {
  documentIds: string[]
  organizationId?: string
  organizationMemberId?: string
}) => {
  const {
    documents: allDocuments,
    isLoadingDocuments,
    isLoadingDocumentsError,
  } = useOrganizationDocumentsQuery({
    id: organizationId,
  })

  const documents = documentIds.map((id) =>
    allDocuments.find((x) => x.id === id),
  )

  // Get current documentMember for each document
  const {
    documentMemberResults,
    isLoadingDocumentMembers,
    isLoadingDocumentMembersError,
  } = useDocumentMemberQueryList(
    documents
      .filter((x): x is ClientModel['Document'] => Boolean(x))
      .map((x) => ({
        memberId: organizationMemberId,
        documentId: x.id,
        organizationId: organizationId,
      })),
  )

  // Zip all documents with their corresponding profile
  const documentMemberProfileMap = documents.map((document) => {
    const memberProfile =
      documentMemberResults.find((x) => x?.documentId === document?.id) ?? null
    return {
      document,
      memberProfile,
    }
  })

  return {
    isLoading: isLoadingDocuments || isLoadingDocumentMembers,
    isError: isLoadingDocumentsError || isLoadingDocumentMembersError,
    documentMemberProfileMap,
  }
}

export const useProcessAccessQuery = (
  key: ModelQueryKey['Process'],
  processMemberKey: ModelQueryKey['ProcessMember'] | null,
) => {
  const { process } = useProcessQuery(key)
  const { memberProfile } = useProcessMemberProfileQuery(
    processMemberKey ?? undefined,
  )
  const { documentMemberProfileMap } = useDocumentsWithProfileQuery({
    documentIds: process?.documentIds ?? [],
    organizationId: process?.organizationId,
    organizationMemberId: memberProfile?.memberId,
  })

  const hasCategoryViewAccess = documentMemberProfileMap
    .map(({ document, memberProfile }) =>
      document
        ? getMemberDocumentAccess({
            privacy: document.privacy,
            documentMemberRole: memberProfile?.role ?? null,
          })
        : { edit: false, view: false },
    )
    .some((x) => x.view)

  if (!process || !memberProfile) {
    return {
      view: false,
      edit: false,
    }
  }

  return getMemberProcessAccess({
    privacy: process.privacy,
    processMemberRole: memberProfile.role,
    hasCategoryViewAccess,
  })
}
