'use client'

import { Loader } from '@mantine/core'
import { useShallowEffect } from '@mantine/hooks'
import {
  FunctionComponent,
  ReactNode,
  Suspense,
  lazy,
  memo,
  useEffect,
} from 'react'
import { Redirect, Route, Switch, useLocation, useParams } from 'wouter'
import { RouteMap } from '~/client/RouteMap.ts'
import { DocumentContent } from '~/client/dashboard/components/document/Document.tsx'
import { DraftsContent } from '~/client/dashboard/components/drafts/Drafts.tsx'
import { DashboardShell } from '~/client/dashboard/components/global/DashboardShell.tsx'
import { HomeContent } from '~/client/dashboard/components/organization/Home.tsx'
import { OrganizationShell } from '~/client/dashboard/components/organization/OrganizationShell.tsx'
import { RevisionContent } from '~/client/dashboard/components/process/Revision.tsx'
import { Account } from '~/client/dashboard/components/settings/Account.tsx'
import { Appearance } from '~/client/dashboard/components/settings/Appearance.tsx'
import { Invitations } from '~/client/dashboard/components/settings/Invitations.tsx'
import { Members } from '~/client/dashboard/components/settings/Members.tsx'
import { Profile } from '~/client/dashboard/components/settings/Profile.tsx'
import {
  SettingsItem,
  SettingsShell,
} from '~/client/dashboard/components/settings/SettingsShell.tsx'
import { TeamExplorerContent } from '~/client/dashboard/components/team/TeamExplorer.tsx'
import { UserShell } from '~/client/dashboard/components/user/UserShell.tsx'
import { useCurrentUserQuery } from '~/client/dashboard/queries/user-bootstrap-queries'
import { useUserOrganizationsQuery } from '~/client/dashboard/queries/user-queries'
import { DiagramProvider } from '~/client/dashboard/stores/DiagramStore'
import {
  DocumentProvider,
  useDocumentContext,
} from '~/client/dashboard/stores/DocumentStore.tsx'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore.tsx'
import {
  OrganizationNavItem,
  OrganizationProvider,
  useOrganizationContext,
} from '~/client/dashboard/stores/OrganizationStore.tsx'
import {
  ProcessProvider,
  useProcessContext,
} from '~/client/dashboard/stores/ProcessStore.tsx'
import {
  RevisionProvider,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore.tsx'
import {
  BootstrapProvider,
  useUserContext,
} from '~/client/dashboard/stores/UserStore.tsx'
import Invite from '~/client/invite/Invite.tsx'
import About from '~/client/marketing/About.tsx'
import Home from '~/client/marketing/Home.tsx'
import Login from '~/client/marketing/Login.tsx'
import { MarketingShell } from '~/client/marketing/MarketingShell.tsx'
import Signup from '~/client/marketing/Signup.tsx'
import { NotFound } from '~/client/shared/ErrorPage.tsx'
import { Row } from '~/client/shared/Layout.tsx'
import Loading from '~/client/shared/Loading'
import { Playground } from '~/client/shared/Playground.tsx'

const DocumentTitleRoot = 'About3'

const LazyAdmin = lazy(() => import('~/client/admin/Admin'))

const Admin = () => (
  <Suspense>
    <LazyAdmin />
  </Suspense>
)

export const RouterRoot = memo(() => {
  const { user, isPending } = useCurrentUserQuery({})
  const [currentPath] = useLocation()

  if (isPending) return <Loading />

  return (
    <Switch>
      {/* Dashboard */}
      {user && (
        <Route path="/dashboard" nest>
          <DashboardShell>
            <UserRouteWrapper />
          </DashboardShell>
        </Route>
      )}
      {!user && (
        <Route path="/dashboard/*?">
          {/* TODO: Set next URL to current path if login */}
          <Redirect to={`/login?next=${currentPath}`} replace={true} />
        </Route>
      )}
      {/* Admin */}
      <Route path="/admin">
        <Admin />
      </Route>
      {/* Standalone pages */}
      <Route path="/invite/:code">
        <InvitationRoute />
      </Route>
      {/* Marketing pages */}
      <MarketingShell>
        <Switch>
          {!user && (
            <Route path="/signup">
              <Signup />
            </Route>
          )}
          {!user && (
            <Route path="/login">
              <Login />
            </Route>
          )}
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Route>
            <Redirect to={user ? '~/dashboard' : '/'} replace={true} />
          </Route>
        </Switch>
      </MarketingShell>
    </Switch>
  )
})

const InvitationRoute = () => {
  const code = useParams().code ?? ''
  return <Invite code={code} />
}

const TeamRoutesWrapper = () => {
  const id = useParams().teamId ?? ''

  return (
    // <TeamProvider id={id}>
    <TeamRoutes />
    // </TeamProvider>
  )
}

const TeamRoutes = () => {
  // TODO: Team content
  // const id = useDocumentContext((x) => x.id)
  // const title = useDocumentContext((x) => x.document.title)

  // useEffect(() => {
  //   // Set title for page (not document model)
  //   document.title = `${title} - ${DocumentTitleRoot}`
  // }, [title])

  return null

  // return (
  // <NestedRoutes base={`/team/${id}`}>
  //   <Route path="/" nest>
  //     <DocumentContent />
  //   </Route>
  //   <Route path="/playground" nest>
  //     <Playground />
  //   </Route>
  // </NestedRoutes>
  // )
}

const DocumentRoutesWrapper = () => {
  const documentSlug = useParams().documentId ?? ''
  const organizationId = useOrganizationContext((x) => x.id)

  return (
    <DocumentProvider queryKey={{ slug: documentSlug, organizationId }}>
      <DocumentRoutes />
    </DocumentProvider>
  )
}

const DocumentRoutes = () => {
  const id = useDocumentContext((x) => x.id)
  const title = useDocumentContext((x) => x.document.title)

  useEffect(() => {
    // Set title for page (not document model)
    document.title = `${title} - ${DocumentTitleRoot}`
  }, [title])

  return (
    <Switch>
      {/* <Route path="/edit">
        <DocumentContent />
      </Route> */}
      <Route path="/playground">
        <Playground />
      </Route>
      <Route>
        <DocumentContent />
      </Route>
    </Switch>
  )
}

const ProcessRoutesWrapper = () => {
  const processSlug = useParams().processId ?? ''
  const organizationId = useOrganizationContext((x) => x.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)

  return (
    <ProcessProvider
      queryKey={{ slug: processSlug, organizationId }}
      notFound={
        <Redirect to={RouteMap.Organization(organizationSlug)} replace={true} />
      }
    >
      <ProcessRoutes />
    </ProcessProvider>
  )
}

const ProcessRoutes = () => {
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const activeRevisionId = useProcessContext(
    (x) => x.memberProfile.activeRevisionId,
  )
  const mainRevisionId = useProcessContext((x) => x.process.mainRevisionId)
  const fallbackRevisionId = activeRevisionId ?? mainRevisionId

  return (
    <Switch>
      {/* <Route path="/edit">
        <ProcessContent />
      </Route> */}
      <Route path="/playground">
        <Playground />
      </Route>
      {mainRevisionId && (
        <Route path="/latest">
          <RevisionRoutesWrapper id={mainRevisionId} />
        </Route>
      )}
      <Route path="/:revisionId/:nodeId?">
        <RevisionRoutesWrapper />
      </Route>
      {activeRevisionId && (
        <Route>
          <RevisionRoutesWrapper id={activeRevisionId} />
        </Route>
      )}
      {mainRevisionId && (
        <Route>
          <Redirect to={'/latest'} replace={true} />
        </Route>
      )}
      <Route>
        <Redirect to={RouteMap.Organization(organizationSlug)} replace={true} />
      </Route>
    </Switch>
  )
}

const RevisionRoutesWrapper = ({ id }: { id?: string }) => {
  const paramsId = useParams().revisionId ?? ''
  const nodeId = useParams().nodeId ?? null
  const revisionHistory = useProcessContext((x) => x.process.revisionHistory)
  let derivedId = id ?? paramsId

  // Check whether the identifier is a version index (e.g. "2")
  const item = revisionHistory[Number(derivedId) - 1]
  if (item) {
    derivedId = item.revisionId
  }

  return (
    <RevisionProvider id={derivedId}>
      <DiagramProvider currentNodeId={nodeId}>
        <RevisionRoutes />
      </DiagramProvider>
    </RevisionProvider>
  )
}

const RevisionRoutes = () => {
  const title = useRevisionContext((x) => x.revision.title)

  useEffect(() => {
    const pageTitle = title
    document.title = `${pageTitle} - ${DocumentTitleRoot}`
  }, [title])

  return (
    <Switch>
      {/* <Route path="/edit">
        <ProcessContent />
      </Route> */}
      <Route path="/playground">
        <Playground />
      </Route>
      <Route>
        <RevisionContent />
      </Route>
    </Switch>
  )
}

const GroupContent = () => {
  const params = useParams()
  const slugs = (params.processIds ?? '').split(',')
  const organizationId = useOrganizationContext((x) => x.id)

  return (
    <Row h="100%">
      {slugs.map((slug) => (
        <ProcessProvider queryKey={{ slug, organizationId }} key={slug}>
          <RevisionContent />
        </ProcessProvider>
      ))}
    </Row>
  )
}

export const SettingsPathMap = {
  [SettingsItem.Profile]: {
    path: '/profile',
    Component: Profile,
  },
  [SettingsItem.Account]: {
    path: '/account',
    Component: Account,
  },
  [SettingsItem.Appearance]: {
    path: '/appearance',
    Component: Appearance,
  },
  [SettingsItem.Invitations]: {
    path: '/invitations',
    Component: Invitations,
  },
  [SettingsItem.Members]: {
    path: '/members',
    Component: Members,
  },
} satisfies {
  [item in SettingsItem]: {
    path: string
    Component: FunctionComponent
  }
}

const SettingsRoutes = () => {
  return (
    <Switch>
      {Object.entries(SettingsPathMap).map(([item, { Component, path }]) => (
        <Route key={path} path={path}>
          <SettingsShell activeItem={item as SettingsItem}>
            <Component />
          </SettingsShell>
        </Route>
      ))}
    </Switch>
  )
}

export const OrganizationPathMap = {
  [OrganizationNavItem.Drafts]: {
    path: '/drafts',
    Component: DraftsContent,
    settings: {
      scrollMain: true,
    },
  },
  [OrganizationNavItem.Team]: {
    path: '/teams/:teamId',
    Component: TeamRoutesWrapper,
    settings: {
      scrollMain: true,
    },
  },
  [OrganizationNavItem.TeamExplorer]: {
    path: '/teams',
    Component: TeamExplorerContent,
    settings: {
      scrollMain: true,
    },
  },
  [OrganizationNavItem.Process]: {
    path: '/process/:processId',
    Component: ProcessRoutesWrapper,
    settings: {
      scrollMain: false,
    },
  },
  [OrganizationNavItem.Document]: {
    path: '/category/:documentId',
    Component: DocumentRoutesWrapper,
    settings: {
      scrollMain: true,
    },
  },
  [OrganizationNavItem.Settings]: {
    path: '/settings',
    Component: SettingsRoutes,
    settings: {
      scrollMain: false,
    },
  },
  // Experimental
  [OrganizationNavItem.Group]: {
    path: '/g/:processIds',
    Component: GroupContent,
    settings: {
      scrollMain: false,
    },
  },
  // Home must be last in list
  [OrganizationNavItem.Home]: {
    path: '/',
    Component: HomeContent,
    settings: {
      scrollMain: true,
    },
  },
  // NOTE: Do not put anything beneath "Home"
} satisfies Partial<{
  [item in OrganizationNavItem]: {
    path: string
    Component: FunctionComponent
    settings: OrganizationPageSettings
  }
}>

type OrganizationPageSettings = {
  scrollMain: boolean
}

const OrganizationPageWrapper = ({
  item,
  settings,
  children,
}: {
  item: OrganizationNavItem
  settings: OrganizationPageSettings
  children: ReactNode
}) => {
  const activeNavItem = useOrganizationContext((x) => x.activeNavItem)
  const setActiveNavItem = useOrganizationContext((x) => x.setActiveNavItem)
  const setScrollMain = useGlobalContext((x) => x.setScrollMain)

  useShallowEffect(() => {
    setActiveNavItem(item)
    setScrollMain(settings.scrollMain)

    // Override in child if necessary
    document.title = `${item} - About3`
  }, [activeNavItem, item])

  return children
}

const OrganizationNotFound = () => {
  const userId = useUserContext((x) => x.id)
  const { organizations } = useUserOrganizationsQuery({ id: userId })

  const slug = organizations[0]?.slug
  if (!slug) return null

  return <Redirect to={RouteMap.Organization(slug)} replace={true} />
}

const OrganizationRouteWrapper = () => {
  const slug = useParams().organizationId ?? ''

  return (
    <OrganizationProvider
      queryKey={{ slug }}
      notFound={<OrganizationNotFound />}
    >
      <OrganizationShell>
        <OrganizationRoutes />
      </OrganizationShell>
    </OrganizationProvider>
  )
}

const OrganizationRoutes = () => {
  return (
    <Switch>
      <Route path="/playground" nest>
        <Playground />
      </Route>
      {Object.entries(OrganizationPathMap).map(
        ([item, { Component, path, settings }]) => (
          <Route key={path} path={path} nest>
            <OrganizationPageWrapper
              item={item as OrganizationNavItem}
              settings={settings}
            >
              <Component />
            </OrganizationPageWrapper>
          </Route>
        ),
      )}
      <Route nest>
        <NotFound />
      </Route>
    </Switch>
  )
}

// Redirect to user's active organization.
// If none, use first available organization.
const RedirectUnmatched = () => {
  const user = useUserContext((x) => x.user)
  const organizations = useUserContext((x) => x.organizations)
  const activeOrganization =
    organizations.find((x) => x.id === user.activeOrganizationId) ??
    organizations[0]

  // Technically, this should never occur
  if (!activeOrganization) {
    return <NotFound />
  }

  return (
    <Redirect
      to={RouteMap.Organization(activeOrganization.slug)}
      replace={true}
    />
  )
}

const UserRouteWrapper = () => {
  return (
    <BootstrapProvider>
      <UserShell>
        <UserRoutes />
      </UserShell>
    </BootstrapProvider>
  )
}

const UserRoutes = () => {
  const userId = useUserContext((x) => x.id)

  if (!userId) return

  return (
    <Switch>
      <Route path="/o/:organizationId" nest>
        <OrganizationRouteWrapper />
      </Route>
      <Route>
        <RedirectUnmatched />
      </Route>
    </Switch>
  )
}
