'use client'

import { NotificationData, notifications } from '@mantine/notifications'
import type { NotificationProps } from '@mantine/core'
import type { TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc'
import { Icon } from '~/client/dashboard/components/global/Icon'

// For tRPC errors only
export const toastForError = (e: any, options: NotificationOptions = {}) => {
  let errorMessage: string | null = null
  if (e.data?.zodError) {
    errorMessage = e.data.zodError.fieldErrors.content?.[0]
  } else if (e.data?.code) {
    const code = e.data.code as TRPC_ERROR_CODE_KEY
    switch (code) {
      case 'TOO_MANY_REQUESTS': {
        errorMessage = 'Too many requests. Try again later.'
        break
      }
    }
  }
  notifications.show?.({
    message: errorMessage ?? 'Something went wrong',
    color: 'red',
    ...options,
  })
}

export const toast = {
  default: (
    message: string,
    props: Omit<NotificationProps, 'message'> = {},
  ) => {
    notifications.show({
      message,
      autoClose: 5000,
      ...props,
    })
  },
  success: (message: string, props: Omit<NotificationData, 'message'> = {}) => {
    return notifications.show({
      color: 'green.3',
      icon: <Icon size={18} name="PiCheckBold" />,
      message,
      autoClose: 5000,
      ...props,
    })
  },
  failure: (message: string, props: Omit<NotificationData, 'message'> = {}) => {
    return notifications.show({
      color: 'red.3',
      icon: <Icon size={20} name="TbExclamationMark" />,
      message,
      autoClose: 6000,
      ...props,
    })
  },
  processing: (
    message: string,
    props: Omit<NotificationData, 'message'> = {},
  ) => {
    const id = notifications.show({
      loading: true,
      message,
      withCloseButton: false,
      ...props,
    })

    return {
      hide: () => {
        notifications.hide(id)
      },
      onSuccess: (
        message: string,
        props: Omit<NotificationData, 'message'> = {},
      ) => {
        notifications.update({
          id,
          color: 'green.3',
          message,
          icon: <Icon size={18} name="PiCheckBold" />,
          withCloseButton: true,
          loading: false,
          autoClose: 2500,
          ...props,
        })
      },
      onFailure: (
        message: string,
        props: Omit<NotificationData, 'message'> = {},
      ) => {
        notifications.update({
          id,
          color: 'red.3',
          message,
          withCloseButton: true,
          icon: <Icon size={20} name="TbExclamationMark" />,
          loading: false,
          autoClose: 3000,
          ...props,
        })
      },
    }
  },
}
