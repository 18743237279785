import {
  authorized,
  ModelCommandsValidation,
  ModelName,
  invalid,
  valueMatches,
  unauthorized,
  valid,
} from '~/commands/base-commands'
import {
  MemberStatus,
  OrganizationRole,
  OrganizationType,
} from '@prisma/client'

/**
 * Organization commands validation
 */

export const organizationValidation = {
  UpdateProfile: {
    validate() {
      return valid()
    },
    authorize({ sender }) {
      if (!valueMatches([OrganizationRole.Admin], sender.role)) {
        return unauthorized(
          'Only organization admins can update organization profile',
        )
      }
      return authorized()
    },
  },
  Archive: {
    validate() {
      return valid()
    },
    authorize({ sender }) {
      if (!sender.isOwner) {
        return unauthorized(
          'Only organization owners can archive organizations',
        )
      }
      return authorized()
    },
  },
  Unarchive: {
    validate() {
      return valid()
    },
    authorize({ sender }) {
      if (!sender.isOwner) {
        return unauthorized(
          'Only organization owners can unarchive organizations',
        )
      }
      return authorized()
    },
  },
  Delete: {
    validate() {
      return valid()
    },
    authorize({ sender, target }) {
      if (!sender.isOwner) {
        return unauthorized('Only organization owners can delete organizations')
      }
      if (target.type === OrganizationType.Personal) {
        return invalid('Personal organizations cannot be deleted')
      }
      return authorized()
    },
  },
  CreateTeam: {
    validate() {
      return valid()
    },
    authorize({ sender, target }) {
      if (target.type === OrganizationType.Personal) {
        return unauthorized('Personal organizations cannot have teams')
      }
      if (valueMatches([OrganizationRole.Guest], sender.role)) {
        return unauthorized('Guests cannot create teams')
      }
      return authorized()
    },
  },
  CreateInvitation: {
    validate() {
      return valid()
    },
    authorize({ sender, target }) {
      if (target.type === OrganizationType.Personal) {
        return unauthorized(
          'Personal organizations do not support additional users',
        )
      }
      if (valueMatches([OrganizationRole.Guest], sender.role)) {
        return unauthorized('Guests cannot create invitations')
      }
      return authorized()
    },
  },
  CreateProcess: {
    validate({ sender, target, meta }) {
      if (meta.documentIds.includes(target.rootDocumentId)) {
        return invalid('Cannot assign processes to root document')
      }

      const missingDocumentAccess = sender.documentsAccess.filter(
        (x) => !x.edit,
      )
      if (missingDocumentAccess.length > 0) {
        // TODO: Accurate messaging (sometimes you must be an owner)
        return unauthorized('Only members can add processes to this category')
      }

      if (meta.isVersionControlled) {
        if (valueMatches([OrganizationType.Personal], target.type)) {
          return unauthorized(
            'Personal organizations do not support version control',
          )
        }
      }

      return valid()
    },
    authorize({ sender, target }) {
      if (valueMatches([OrganizationRole.Guest], sender.role)) {
        return unauthorized('Guests cannot create processes')
      }
      return authorized()
    },
  },
  // Unarchive: {
  //   validate() {
  //     return valid()
  //   },
  //   authorize() {
  //     // TODO:
  //     return authorized()
  //   },
  // },
} satisfies ModelCommandsValidation<ModelName.Organization>
