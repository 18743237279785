import {
  ModelCommandsValidation,
  ModelName,
  authorized,
  invalid,
  unauthorized,
  valid,
} from '~/commands/base-commands'

/**
 * Revision commands validation
 */

export const revisionValidation = {
  Modify: {
    validate(ctx) {
      return valid()
    },
    authorize({ target }) {
      // If a user has view access to a Revision,
      //  they are permitted to create a draft
      if (target.isDraft) {
        return invalid('Cannot modify a draft')
      }

      return authorized()
    },
  },
  SetContent: {
    validate(ctx) {
      return valid()
    },
    authorize({ sender, target }) {
      if (!target.isDraft) {
        return invalid('Cannot change a published revision')
      }

      if (!sender.isCreator) {
        // TODO: Rethink this behavior
        return unauthorized('Only revision creators can make changes')
      }

      return authorized()
    },
  },
  Publish: {
    validate(ctx) {
      return valid()
    },
    authorize({ sender, target }) {
      if (!target.isDraft) {
        return invalid('Revision has already been published')
      }

      if (!sender.processAccess.edit) {
        return unauthorized('Missing permissions for publish')
      }

      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.Revision>
