import { Button, Text } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { Column } from '~/client/shared/Layout.tsx'
import { ButtonWithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'

export const Playground = () => {
  const [x, setX] = useState(1)
  const [obj] = useState({
    a: 1,
    b: 2,
  })

  useInterval(() => {
    setX(x + 1)
  }, 1000)

  return (
    <Column pos="relative" p={100} gap={20}>
      <Text size="md" lineClamp={3} maw={200}>
        This is a long piece of <b>text</b>.This is a long piece of text.This is
        a long piece of text.This is a long piece of text.
      </Text>
      <Button
        loading={true}
        variant="outline"
        color="blue"
        size="compact-md"
      >Testibng</Button>
      <ButtonWithCommand
        loading={true}
        variant="gradient"
        size="compact-md"
        radius={50}
        model={ModelName.Organization}
        command="CreateProcess"
        queryKey={{ id: '1' }}
        // validate={{}}
        notAllowed="show"
        onClick={({ createProcessAsync }) => {}}
      >
        Create process
      </ButtonWithCommand>
    </Column>
  )
}

const Child = ({ obj }: any) => {
  useEffect(() => {
    console.log('OBJ CHANGED')
  }, [obj])

  return null
}
