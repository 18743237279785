import { useNodeContext } from '~/client/dashboard/stores/NodeStore.tsx'
import { NodeType } from '~/schemas/node-schema.ts'
import { DiagramSubprocessNode } from '~/client/dashboard/components/process/diagram/DiagramSubprocessNode.tsx'
import { DiagramSwitchNode } from '~/client/dashboard/components/process/diagram/DiagramSwitchNode.tsx'
import { DiagramStepNode } from '~/client/dashboard/components/process/diagram/DiagramStepNode.tsx'
import ErrorBoundary from '~/client/shared/ErrorBoundary.tsx'
import { DiagramNodeContext } from '~/client/dashboard/components/process/diagram/DiagramNodeWrapper.tsx'
import { DefaultNodePoints } from '~/client/dashboard/components/process/diagram/DiagramConnectors.tsx'
import { useProcessContext } from '~/client/dashboard/stores/ProcessStore.tsx'
import { DiagramViewMode, useDiagramContext } from "~/client/dashboard/stores/DiagramStore"
import { AddNodeContent, DeadEnd } from '~/client/dashboard/components/process/diagram/DiagramAddNode.tsx'
import { DiagramNodeProps } from '~/client/dashboard/components/process/diagram/diagram-settings.tsx'

export const CustomNode = (props: DiagramNodeProps) => {
  return (
    <ErrorBoundary>
      <DiagramNodeContext globalId={props.data.globalId}>
        <div
          style={{
            width: props.width,
            height: props.height,
          }}
        >
          <DefaultNodePoints {...props} />
          <CustomNodeContent {...props} />
        </div>
      </DiagramNodeContext>
    </ErrorBoundary>
  )
}

const CustomNodeContent = (props: DiagramNodeProps) => {
  const type = useNodeContext((x) => x.type)

  switch (type) {
    case NodeType.Subprocess:
      return <DiagramSubprocessNode {...props} />
    case NodeType.Switch:
      return <DiagramSwitchNode {...props} />
    default:
      return <DiagramStepNode {...props} />
  }
}

export const AddNode = (props: DiagramNodeProps) => {
  const viewMode = useDiagramContext((x) => x.viewMode)

  return (
    <ErrorBoundary>
      <DiagramNodeContext globalId={props.data.globalId}>
        <div
          style={{
            width: props.width,
            height: props.height,
          }}
        >
          <DefaultNodePoints {...props} isAdd={true} />
          {viewMode === DiagramViewMode.Editing && <AddNodeContent {...props} />}
        </div>
      </DiagramNodeContext>
    </ErrorBoundary>
  )
}
