import { z } from 'zod'
import type { ModelCommandsSchema } from '~/commands/base-commands'
import { OrganizationSchema } from '~/schemas/organization-schema'
import { TeamMemberSchema } from '~/schemas/team-member-schema'
import { TeamSchema } from '~/schemas/team-schema'

/**
 * TeamMember commands schema
 */

export const teamMemberCommandsSchema = {
  SetRole: {
    summary: `Set a member's role within a team`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        role: TeamMemberSchema.shape.role,
      }),
      output: z.literal(true),
      validate: {
        sender: {
          isOwner: z.boolean(),
        },
        target: {
          isSender: z.boolean(),
          currentRole: TeamMemberSchema.shape.role.optional(),
          newRole: TeamMemberSchema.shape.role,
        },
        meta: {
          teamAccess: TeamSchema.shape.access,
          teamOwnerCount: z.number(),
        },
      },
      authorize: {
        sender: {
          isJoined: z.boolean(),
        },
        target: {},
        meta: {
          teamAccess: TeamSchema.shape.access,
        },
      },
    },
    messages: {
      failure: 'Failed to update team member role',
    },
    variables: {
      execute: 'setTeamMemberRole',
      isPending: 'isSettingTeamMemberRole',
    },
  },
} as const satisfies ModelCommandsSchema
