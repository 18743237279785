'use client'

import { Loader, Text, Transition } from '@mantine/core'
import { Column, Row } from '~/client/shared/Layout.tsx'

export const Loading = ({ mounted = true }: { mounted?: boolean }) => {
  return (
    <Transition mounted={mounted} transition="fade" duration={1000}>
      {(style) => (
        <Column align="center" justify="center" w="100%" h="70%" style={style}>
          <Loader color="blue" size="xl" type="bars" />
        </Column>
      )}
    </Transition>
  )
}

export default Loading
