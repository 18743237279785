import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Loader,
  MantineColor,
  Table,
  Text,
  Tooltip,
} from '@mantine/core'
import { useClipboard, useDisclosure } from '@mantine/hooks'
import { OrganizationRole } from '@prisma/client'
import { useCallback, useState } from 'react'
import { Box, Column, Row } from '~/client/shared/Layout'
import { toast } from '~/client/shared/Toast'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  Portal,
  PortalTargetId,
} from '~/client/dashboard/components/global/Portals'
import {
  DateText,
  MemberAvatar,
  MemberRoleBadge,
} from '~/client/dashboard/components/global/Prefabs'
import { ManageInvitationModal } from '~/client/dashboard/forms/InvitationForms'
import {
  useInvitationQuery,
  useInvitationRedemptionsQuery,
} from '~/client/dashboard/queries/invitation-queries'
import {
  useOrganizationInvitationsQuery,
  useOrganizationMembersQuery,
} from '~/client/dashboard/queries/organization-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { ClientModel } from '~/schemas'
import { OrganizationInvitationQueryKey } from '~/schemas/invitation-schema'
import classes from './Invitations.module.css'
import { useCommand } from '~/commands/client-commands'
import { ModelName } from '~/commands/base-commands'
import { useDarkMode, useDeviceSize } from '~/client/dashboard/stores/GlobalStore'

export const INVITATION_PATH = (code: string) => `/invite/${code}`
export const INVITATION_URL = (code: string) =>
  `${document.location.protocol}//${document.location.host}${INVITATION_PATH(
    code,
  )}`

export const Invitations = () => {
  const deviceSize = useDeviceSize()
  const organization = useOrganizationContext((x) => x.organization)
  const [opened, { open, close }] = useDisclosure()
  const [editingInvitation, setEditingInvitation] = useState<string | null>(
    null,
  )
  const { isLoadingInvitations } = useOrganizationInvitationsQuery({
    id: organization.id,
  })
  const { createInvitationAsync, isCreatingInvitation } = useCommand(
    ModelName.Organization,
    'CreateInvitation',
  )

  const onCreateClicked = useCallback(() => {
    void createInvitationAsync({
      key: {
        id: organization.id,
      },
      params: {
        role: OrganizationRole.User,
      },
    }).then((x) => {
      setEditingInvitation(x.id)
      open()
    })
  }, [createInvitationAsync, setEditingInvitation])

  return (
    <Column w="100%" mt={deviceSize.isSmall ? 0 : 'lg'}>
      <Portal target={PortalTargetId.SettingsTitleRight}>
        {deviceSize.isSmall && (
          <ActionIcon disabled={isCreatingInvitation} onClick={onCreateClicked}>
            <Icon name="PiPlusBold" />
          </ActionIcon>
        )}
        {!deviceSize.isSmall && (
          <Button
            size="compact-md"
            disabled={isCreatingInvitation}
            onClick={onCreateClicked}
            leftSection={<Icon name="PiPlusBold" />}
          >
            New Invitation
          </Button>
        )}
      </Portal>
      <InvitationTable />
      {isLoadingInvitations && (
        <Row p="xl" w="100%" justify="center">
          <Loader />
        </Row>
      )}
      {/* Edit modal */}
      <ManageInvitationModal
        queryKey={{
          id: editingInvitation!,
          organizationId: organization.id,
        }}
        opened={opened}
        onClose={close}
      />
    </Column>
  )
}

const InvitationItem = ({
  invitation,
}: {
  invitation: ClientModel['Invitation']
}) => {
  const deviceSize = useDeviceSize()
  const clipboard = useClipboard({ timeout: 2000 })
  const [opened, { open, close }] = useDisclosure()
  const organization = useOrganizationContext((x) => x.organization)
  const { invitationRedemptions } = useInvitationRedemptionsQuery({
    id: invitation.id,
    organizationId: organization.id,
  })
  const { members } = useOrganizationMembersQuery({
    id: organization.id,
  })

  const creator = members.find((member) => invitation.creatorId === member.id)
  const isExpired = invitation.validUntil && invitation.validUntil < new Date()
  const hasRedemptionsRemaining =
    invitation.maxRedemptions >
    invitationRedemptions.filter(
      (redemption) => redemption.invitationId === invitation.id,
    ).length

  return (
    <>
      {/* Edit modal */}
      <ManageInvitationModal
        queryKey={invitation}
        opened={opened}
        onClose={close}
      />
      <Table.Tr
        key={invitation.id}
        data-disabled={invitation.isArchived}
        h={rem(54)}
      >
        <Table.Td className={classes.columnCreator}>
          <Row gap="sm">
            <MemberAvatar glance={true} size={30} id={invitation.creatorId} />
            <Text>{creator?.alias ?? 'Deleted user'}</Text>
          </Row>
        </Table.Td>
        <Table.Td className={classes.columnCode}>
          <Anchor
            href={INVITATION_URL(invitation.code)}
            onClick={(e) => {
              e.preventDefault()
              clipboard.copy(INVITATION_URL(invitation.code))
              toast.success('Invitation link copied to clipboard', {
                autoClose: 2000,
              })
            }}
          >
            {invitation.code}
          </Anchor>
        </Table.Td>
        <Table.Td className={classes.columnCreatedAt}>
          <DateText date={invitation.createdAt} />
        </Table.Td>
        <Table.Td className={classes.columnExpiration}>
          <Column gap="xs" align="center" w={90}>
            {!isExpired && hasRedemptionsRemaining && (
              <Row>
                {!invitation.validUntil && (
                  <Text opacity={0.7} fz="sm">
                    Never expires
                  </Text>
                )}
                {invitation.validUntil && (
                  <DateText date={invitation.validUntil} />
                )}
              </Row>
            )}
            <InvitationBadges {...invitation} />
          </Column>
        </Table.Td>
        <Table.Td className={classes.columnControls}>
          <Row gap="sm" justify="flex-end">
            <Column align="flex-end" gap="xs">
              {invitation.isArchived && (
                <Tooltip label="Invitation disabled">
                  <Box>
                    <Icon size={16} name="MdLinkOff" />
                  </Box>
                </Tooltip>
              )}
              {!invitation.isArchived && (
                <MemberRoleBadge role={invitation.role} />
              )}
              {deviceSize.isSmall && <InvitationBadges {...invitation} />}
            </Column>
            <ActionIcon
              variant="light"
              color="dark"
              onClick={() => {
                open()
              }}
            >
              <Icon name="PiGear" />
            </ActionIcon>
          </Row>
        </Table.Td>
      </Table.Tr>
    </>
  )
}

const InvitationTable = () => {
  const organization = useOrganizationContext((x) => x.organization)
  const { invitations, isLoadingInvitations } =
    useOrganizationInvitationsQuery(organization)

  const rows = invitations.map((x) => (
    <InvitationItem key={x.id} invitation={x} />
  ))

  return (
    <Table className={classes.invitationsTable} w="100%">
      <Table.Thead>
        <Table.Tr>
          <Table.Th className={classes.columnCreator}>Creator</Table.Th>
          <Table.Th className={classes.columnCode}>Code</Table.Th>
          <Table.Th className={classes.columnCreatedAt}>Date Created</Table.Th>
          <Table.Th className={classes.columnExpiration}>
            Expiration Date
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{!isLoadingInvitations && rows}</Table.Tbody>
    </Table>
  )
}

export const InvitationBadges = (props: OrganizationInvitationQueryKey) => {
  const isDarkMode = useDarkMode()
  const { invitation } = useInvitationQuery(props)
  const { invitationRedemptions } = useInvitationRedemptionsQuery(props)

  if (!invitation) return null

  let redemptionColor = isDarkMode ? 'green.3' : ('green.5' as MantineColor)
  let redemptionText = null as null | string
  if (invitation.maxRedemptions <= 1 && invitationRedemptions.length) {
    redemptionText = 'Redeemed'
  } else if (invitation.maxRedemptions > 1) {
    if (invitation.maxRedemptions <= invitationRedemptions.length) {
      redemptionText = 'All redeemed'
    } else {
      redemptionText =
        invitation.maxRedemptions - invitationRedemptions.length + ' left'
      redemptionColor = 'yellow'
    }
  }

  const hasRedemptionsRemaining =
    invitation.maxRedemptions > invitationRedemptions.length

  const redemptionBadge = redemptionText ? (
    <Badge key="redemption" color={redemptionColor}>
      {redemptionText}
    </Badge>
  ) : null

  const isExpired = invitation.validUntil && invitation.validUntil < new Date()
  const expiredBadge =
    isExpired && hasRedemptionsRemaining ? (
      <Badge key="expired" color="gray.6">
        Expired
      </Badge>
    ) : null

  if (!expiredBadge && !redemptionBadge) return null

  return [expiredBadge, redemptionBadge].filter(Boolean)
}
