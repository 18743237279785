import { z } from 'zod'
import type { ModelCommandsSchema } from '~/commands/base-commands'
import {
  OrganizationMemberSchema,
  OrganizationMemberSchemaActiveStatus,
} from '~/schemas/organization-member-schema'
import { OrganizationSchema } from '~/schemas/organization-schema'

/**
 * Member commands schema
 */

export const organizationMemberCommandsSchema = {
  SetAsOwner: {
    summary: 'Set a member as the organization owner',
    description: '',
    method: 'PATCH',
    schemas: {
      input: undefined,
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          isOwner: z.boolean(),
        },
        target: {
          status: OrganizationMemberSchema.shape.status,
          isOwner: z.boolean(),
          hasUser: z.boolean(),
        },
        meta: {
          organizationType: OrganizationSchema.shape.type,
        },
      },
    },
    messages: {
      failure: 'Failed to set organization owner',
    },
    variables: {
      execute: 'setAsOrganizationOwner',
      isPending: 'isSettingAsOrganizationOwner',
    },
  },
  UpdateProfile: {
    summary: `Update an organization member's profile settings`,
    method: 'PATCH',
    schemas: {
      input: z.object({
        alias: OrganizationMemberSchema.shape.alias.optional(),
        title: OrganizationMemberSchema.shape.title.optional(),
        imageUrl: OrganizationMemberSchema.shape.imageUrl.optional(),
        description: OrganizationMemberSchema.shape.description.optional(),
        isDisplayLocked:
          OrganizationMemberSchema.shape.isDisplayLocked.optional(),
      }),
      output: z.literal(true),
      validate: {
        sender: {
          role: OrganizationMemberSchema.shape.role.nullable(),
        },
        target: {
          role: OrganizationMemberSchema.shape.role,
          isSender: z.boolean(),
          isDisplayLockedChanged: z.boolean(),
        },
        meta: {},
      },
      authorize: {
        sender: {
          role: OrganizationMemberSchema.shape.role.nullable(),
        },
        target: {
          isSender: z.boolean(),
          isDisplayLocked: OrganizationMemberSchema.shape.isDisplayLocked,
        },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update member profile',
      success: 'Profile has been updated',
    },
    variables: {
      execute: 'updateOrganizationMemberProfile',
      isPending: 'isUpdatingOrganizationMemberProfile',
    },
  },
  SetStatus: {
    summary: `Set an organization member's status`,
    method: 'PATCH',
    schemas: {
      input: z.object({
        status: OrganizationMemberSchemaActiveStatus,
      }),
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          role: OrganizationMemberSchema.shape.role.nullable(),
        },
        target: {
          status: OrganizationMemberSchema.shape.status,
          isSender: z.boolean(),
          isOwner: z.boolean(),
        },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update member status',
    },
    variables: {
      execute: 'setOrganizationMemberStatus',
      isPending: 'isSettingOrganizationMemberStatus',
    },
  },
  SetRole: {
    summary: `Set a member's role within an organization`,
    method: 'PATCH',
    schemas: {
      input: z.object({
        role: OrganizationMemberSchema.shape.role,
      }),
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          role: OrganizationMemberSchema.shape.role.nullable(),
        },
        target: {
          isSender: z.boolean(),
          isOwner: z.boolean(),
        },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update member role',
    },
    variables: {
      execute: 'setOrganizationMemberRole',
      isPending: 'isSettingOrganizationMemberRole',
    },
  },
  Leave: {
    summary: `Leave an organization`,
    description: `Once a member has exited an organization, they must accept an invitation in order to become an active member again.`,
    method: 'PATCH',
    schemas: {
      input: undefined,
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {},
        target: {
          isSender: z.boolean(),
          isOwner: z.boolean(),
        },
        meta: {
          organizationType: OrganizationSchema.shape.type,
        },
      },
    },
    messages: {
      failure: 'Failed to leave organization',
    },
    variables: {
      execute: 'leaveOrganization',
      isPending: 'isLeavingOrganization',
    },
  },
} as const satisfies ModelCommandsSchema
