import { OrganizationType } from '@prisma/client'
import { z } from 'zod'
import { replaceNewLine } from '~/utils/logic'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

/**
 * Constants
 */

export const NAME_MAX_LENGTH = 50
export const DESCRIPTION_MAX_LENGTH = 200
export const INVITATION_NAME_MAX_LENGTH = 50
export const INVITATION_MESSAGE_MAX_LENGTH = 200
export const IMAGE_URL_MAX_LENGTH = 1000

/**
 * Fields
 */

const nameSchema = z
  .string()
  .trim()
  .transform(replaceNewLine)
  .pipe(
    z
      .string()
      .max(
        NAME_MAX_LENGTH,
        `Name must be less than ${NAME_MAX_LENGTH} characters`,
      ),
  )

const descriptionSchema = z
  .string()
  .max(
    DESCRIPTION_MAX_LENGTH,
    `Description must be less than ${DESCRIPTION_MAX_LENGTH} characters`,
  )

const imageUrlSchema = z.union([
  z
    .string()
    .trim()
    .url('Must be a URL')
    .max(
      IMAGE_URL_MAX_LENGTH,
      `Must be less than ${IMAGE_URL_MAX_LENGTH} characters`,
    ),
  z.string().length(0),
])

/**
 * Organization schemas
 */

export type OrganizationSchema = z.infer<typeof OrganizationSchema>
export const OrganizationSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  ownerId: z.string(),
  slug: z.string(),
  rootDocumentId: z.string().nullable(),
  type: z.nativeEnum(OrganizationType),
  isArchived: z.boolean(),
  name: nameSchema,
  imageUrl: imageUrlSchema.nullable(),
  description: descriptionSchema.nullable(),
}) satisfies PrismaModelSchema<'Organization'>

const OrganizationQueryKeys = z.tuple([
  z.object({
    id: OrganizationSchema.shape.id,
  }),
  z.object({
    slug: OrganizationSchema.shape.slug,
  }),
])
export const organizationQueryKeys = OrganizationQueryKeys.items

export const OrganizationQueryKey = z.union(OrganizationQueryKeys.items)
export type OrganizationQueryKey = z.infer<typeof OrganizationQueryKey>

export const ClientOrganizationSchema = OrganizationSchema.merge(
  z.object({
    rootDocumentId: z.string(),
  }),
)
