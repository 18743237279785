import { z } from 'zod'
import type { ModelCommandsSchema } from '~/commands/base-commands'
import { DocumentMemberSchema } from '~/schemas/document-member-schema'
import { DocumentSchema } from '~/schemas/document-schema'

/**
 * DocumentMember commands schema
 */

export const documentMemberCommandsSchema = {
  SetRole: {
    summary: `Set a member's role within a category`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        role: DocumentMemberSchema.shape.role,
      }),
      output: z.literal(true),
      validate: {
        sender: {
          isOwner: z.boolean(),
        },
        target: {
          isSender: z.boolean(),
          currentRole: DocumentMemberSchema.shape.role.optional(),
          newRole: DocumentMemberSchema.shape.role,
        },
        meta: {
          documentPrivacy: DocumentSchema.shape.privacy,
          documentOwnerCount: z.number(),
        },
      },
      authorize: {
        sender: {
          isJoined: z.boolean(),
        },
        target: {},
        meta: {
          isDocumentRoot: z.boolean(),
        },
      },
    },
    messages: {
      failure: 'Failed to update member role',
    },
    variables: {
      execute: 'setDocumentMemberRole',
      isPending: 'isSettingDocumentMemberRole',
    },
  },
} as const satisfies ModelCommandsSchema
