/**
 * Process
 */
import { cacheOrganizationProcesses } from '~/client/dashboard/queries/organization-queries'
import { cacheRevision } from '~/client/dashboard/queries/revision-queries'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CacheRemoveFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  QueryOptions,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { PartialRequired } from '~/client/globals'
import { BootstrapProcess, ClientModel, ModelQueryKey } from '~/schemas'
import { ProcessQueryKey, processQueryKeys } from '~/schemas/process-schema'
import { api } from '~/utils/api'
import { isMatch } from '~/utils/logic'
import { cacheProcessMemberProfile } from '~/client/dashboard/queries/process-member-queries.tsx'

export const useProcessQuery = (
  key?: PartialRequired<ProcessQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = ProcessQueryKey.safeParse(key).success
  const result = api.Process.get.useQuery(parseKey(key, ProcessQueryKey), {
    enabled,
    staleTime: CHILD_ITEM_STALE_TIME,
    gcTime: CHILD_ITEM_CACHE_TIME,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: RETRY,
    ...options,
  })
  const process = result?.data ?? null

  useCacheResult(result, cacheProcess, () => key, false)

  return {
    ...result,
    process,
    isLoadingProcess: result.isPending && enabled,
    isLoadingProcessError: Boolean(result?.isError),
  }
}

export const useProcessRevisionsQuery = (
  key?: PartialRequired<ProcessQueryKey>,
  options: QueryOptions = {},
) => {
  const result = api.Process.getRevisions.useQuery(
    parseKey(key, ProcessQueryKey),
    {
      enabled: ProcessQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )

  useCacheResult(result, cacheProcessRevisions, () => key, false)

  return {
    ...result,
    revisions: result.data ?? [],
    isLoadingRevisions: result.isPending,
  }
}

/**
 * Cache get
 */

export const getProcessFromCache = (ctx: ApiContext, key: ProcessQueryKey) => {
  key = ProcessQueryKey.parse(key)
  return ctx.Process.get.getData(key)
}

export const getRevisionsFromCache = (
  ctx: ApiContext,
  key: ProcessQueryKey,
) => {
  key = ProcessQueryKey.parse(key)
  return ctx.Process.getRevisions.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheBootstrapProcess: CacheFn<
  ModelQueryKey['Process'],
  BootstrapProcess
> = (ctx, data, key) => {
  key = ProcessQueryKey.parse(key)
  const result = typeof data === 'function' ? data() : data

  if (result) {
    // Cache process
    cacheProcess(ctx, result.process, key, false)

    // Cache active revision
    cacheRevision(ctx, result.activeRevision, result.activeRevision, {
      updateOrganizationRevisions: false,
      updateProcessRevisions: false,
    })

    // Cache main revision
    if (result.mainRevision) {
      cacheRevision(ctx, result.mainRevision, result.mainRevision, {
        updateOrganizationRevisions: false,
        updateProcessRevisions: false,
      })
    }

    // Cache member profile
    if (result.memberProfile) {
      cacheProcessMemberProfile(
        ctx,
        result.memberProfile,
        result.memberProfile,
        {},
      )
    }
  }

  return {
    previous: undefined,
    revert: () => {
      cacheBootstrapProcess(ctx, undefined, key, {})
    },
  }
}

export const cacheProcess: CacheFn<
  ModelQueryKey['Process'],
  ClientModel['Process'],
  boolean
> = (ctx, data, key, itemOnly = false) => {
  key = ProcessQueryKey.parse(key)
  const previous = getProcessFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.Process.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(ctx.Process.get, processQueryKeys, result, result)

    if (!itemOnly) {
      cacheOrganizationProcesses(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            old = [...old, result]
          } else {
            old = old.map((x) => (x.id === result.id ? result : x))
          }

          return old
        },
        { id: key.organizationId },
        true,
      )
    }
  }

  return {
    previous,
    revert: () => {
      cacheProcess(ctx, previous, key, itemOnly)
    },
  }
}

export const cacheProcessRevisions: CacheFn<
  ModelQueryKey['Process'],
  ClientModel['Revision'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = ProcessQueryKey.parse(key)
  const previous = getRevisionsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const process = getProcessFromCache(ctx, key)
    cacheEachKey(ctx.Process.getRevisions, processQueryKeys, process, result)

    if (!listOnly) {
      result.forEach((x) => {
        cacheRevision(ctx, x, x, {
          updateOrganizationRevisions: true,
          updateProcessRevisions: false,
        })
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheProcessRevisions(ctx, previous, key, listOnly)
    },
  }
}

/**
 * Cache remove
 */

export const removeProcessFromCache: CacheRemoveFn<
  ModelQueryKey['Process'],
  ClientModel['Process']
> = (ctx, key) => {
  key = ProcessQueryKey.parse(key)
  const previous = getProcessFromCache(ctx, key)

  if (previous) {
    cacheEachKey(ctx.Process.get, processQueryKeys, previous, null)
  }

  const { revert } = cacheOrganizationProcesses(
    ctx,
    (old) => {
      if (!old) return
      return old.filter((x) => !isMatch(x, key))
    },
    { id: key.organizationId },
    true,
  )

  return {
    previous,
    revert,
  }
}
