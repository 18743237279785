import { cacheDocumentMember } from '~/client/dashboard/queries/document-member-queries'
import { cacheOrganizationMembers } from '~/client/dashboard/queries/organization-queries'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CacheRemoveFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { cacheUser } from '~/client/dashboard/queries/user-queries'
import { PartialRequired } from '~/client/globals'
import { ModelName } from '~/commands/base-commands'
import { ClientModel, ModelQueryKey } from '~/schemas'
import {
  OrganizationMemberQueryKey,
  organizationMemberQueryKeys,
  toDisplayMember,
} from '~/schemas/organization-member-schema'
import { api } from '~/utils/api'
import { isMatch } from '~/utils/logic'

export const useOrganizationMemberQuery = (
  key: PartialRequired<OrganizationMemberQueryKey>,
  options: {
    // Return actual field data (Do not apply user fields)
    raw?: boolean
  } = {},
) => {
  const { raw } = options
  const enabled = OrganizationMemberQueryKey.safeParse(key).success
  const result = api.OrganizationMember.get.useQuery(
    parseKey(key, OrganizationMemberQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )
  const organizationMember = result?.data ?? null

  useCacheResult(result, cacheOrganizationMember, () => key, {})

  return {
    ...result,
    organizationMember:
      raw || !organizationMember
        ? organizationMember
        : toDisplayMember(organizationMember),
    isLoadingOrganizationMember: result.isPending && enabled,
    isLoadingOrganizationMemberError: result.isError,
  }
}

export const useOrganizationMemberDocumentProfilesQuery = (
  key: PartialRequired<OrganizationMemberQueryKey>,
) => {
  const result = api.OrganizationMember.getDocumentProfiles.useQuery(
    parseKey(key, OrganizationMemberQueryKey),
    {
      enabled: OrganizationMemberQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheOrganizationMemberDocumentProfiles, () => key, {})

  return { ...result, memberDocumentProfiles: result.data ?? [] }
}

/**
 * Cache get
 */

export const getOrganizationMemberFromCache = (
  ctx: ApiContext,
  key: OrganizationMemberQueryKey,
) => {
  key = OrganizationMemberQueryKey.parse(key)
  return ctx.OrganizationMember.get.getData(key)
}

export const getOrganizationMemberDocumentProfilesFromCache = (
  ctx: ApiContext,
  key: OrganizationMemberQueryKey,
) => {
  key = OrganizationMemberQueryKey.parse(key)
  return ctx.OrganizationMember.getDocumentProfiles.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheOrganizationMember: CacheFn<
  ModelQueryKey['OrganizationMember'],
  ClientModel['OrganizationMember'],
  { updateUser: boolean; updateOrganizationMembers: boolean }
> = (
  ctx,
  data,
  key,
  { updateUser = true, updateOrganizationMembers = true },
) => {
  key = OrganizationMemberQueryKey.parse(key)
  const previous = getOrganizationMemberFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.OrganizationMember.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(
      ctx.OrganizationMember.get,
      organizationMemberQueryKeys,
      result,
      result,
    )

    if (updateUser) {
      cacheUser(ctx, result.user, { id: result.id }, true)
    }
    if (updateOrganizationMembers) {
      cacheOrganizationMembers(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (x.id === result.id ? result : x))
          }
        },
        { id: key.organizationId },
        true,
      )
    }
  }
  return {
    previous,
    revert: () => {
      cacheOrganizationMember(ctx, previous, key, {
        updateUser,
        updateOrganizationMembers,
      })
    },
  }
}

export const cacheOrganizationMemberDocumentProfiles: CacheFn<
  ModelQueryKey['OrganizationMember'],
  ClientModel['DocumentMember'][]
> = (ctx, data, key) => {
  key = ModelQueryKey['OrganizationMember'].parse(key)
  const previous = getOrganizationMemberDocumentProfilesFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const organizationMember = getOrganizationMemberFromCache(ctx, key)
    cacheEachKey(
      ctx.OrganizationMember.getDocumentProfiles,
      organizationMemberQueryKeys,
      organizationMember,
      result,
    )

    // NOTE: We do not cache individual document members here.
    //  This does not seem necessary since the document
    //  itself manages that information.
  }

  return {
    previous,
    revert: () => {
      cacheOrganizationMemberDocumentProfiles(ctx, previous, key, {})
    },
  }
}

/**
 * Cache remove
 */

export const removeOrganizationMemberFromCache: CacheRemoveFn<
  ModelQueryKey['OrganizationMember'],
  ClientModel['OrganizationMember']
> = (ctx, key) => {
  key = OrganizationMemberQueryKey.parse(key)
  const previous = getOrganizationMemberFromCache(
    ctx,
    OrganizationMemberQueryKey.parse(key),
  )

  if (previous) {
    cacheEachKey(
      ctx.OrganizationMember.get,
      organizationMemberQueryKeys,
      previous,
      null,
    )
  }

  const { revert } = cacheOrganizationMembers(
    ctx,
    (old) => {
      if (!old) return
      return old.filter((x) => !isMatch(x, key))
    },
    { id: key.organizationId },
    true,
  )

  return {
    previous,
    revert,
  }
}
