'use client'

import { useClerk } from '@clerk/clerk-react'
import { useQueryClient } from '@tanstack/react-query'

export const useLogout = () => {
  const { signOut } = useClerk()
  const queryClient = useQueryClient()

  return (options?: { redirectUrl?: string }) => {
    const { redirectUrl = '/login' } = options ?? {}

    return signOut().then(() => {
      queryClient.removeQueries()
      document.location = redirectUrl
    })
  }
}

export type Logout = ReturnType<typeof useLogout>
