'use client'

import { useUser } from '@clerk/clerk-react'
import { Badge, Button, Card, Text } from '@mantine/core'
import { ReactNode, useCallback } from 'react'
import { RouteMap } from '~/client/RouteMap.ts'
import { Column, Row } from '~/client/shared/Layout'
import { useLogout } from '~/client/shared/hooks/useLogout'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { OrganizationLogo } from '~/client/dashboard/components/global/Prefabs'
import { useRedeemInvitation } from '~/client/dashboard/mutations/invitation-mutations'

import { InvitationValidationSuccess } from '~/server/methods/invitation-methods'

export const EnsureLoggedInWithEmail = ({
  email,
  children,
}: {
  email: string | null
  children: ReactNode
}) => {
  const { user } = useUser()
  const logout = useLogout()

  const logIn = useCallback(() => {
    // Log out and set redirect to this page
    void logout({ redirectUrl: '/login?next=' + document.location.pathname })
  }, [])

  const isValidLogin =
    user &&
    (!email ||
      user.emailAddresses.some(
        (x) => x.emailAddress.toLowerCase() === email.toLowerCase(),
      ))
  if (!isValidLogin)
    return (
      <Column gap="sm" align="center">
        {email && (
          <Column mb="lg" align="center">
            <Text fz="sm" opacity={0.7}>
              Required email:
            </Text>
            <Text fz="md" fw={700} opacity={1}>
              {email}
            </Text>
          </Column>
        )}
        <Row>
          <Button size="md" onClick={logIn}>
            Log In
          </Button>
        </Row>
      </Column>
    )

  return children
}

export const InvitationAcceptButton = ({ code }: { code: string }) => {
  const { redeemInvitation, isRedeemingInvitation } = useRedeemInvitation()
  const navigate = useNavigate()

  return (
    <Button
      size="xl"
      loading={isRedeemingInvitation}
      onClick={() => {
        void redeemInvitation({
          code,
        }).then((result) => {
          return navigate(RouteMap.Organization(result.organization.slug))
        })
      }}
    >
      Join
    </Button>
  )
}

export const OrganizationCard = ({
  organization,
}: {
  organization: InvitationValidationSuccess['invitation']['organization']
}) => {
  return (
    <Card p="xl">
      <Card.Section>
        <Column align="center">
          <OrganizationLogo
            imageUrl={organization.imageUrl}
            name={organization.name}
            size={70}
          />
          <Badge
            variant="filled"
            size="lg"
            color="orange"
            pos="relative"
            top={-4}
          >
            {organization._count.members} member
            {organization._count.members === 1 ? '' : 's'}
          </Badge>
        </Column>
      </Card.Section>
    </Card>
  )
}
