import {ProcessPrivacy} from '@prisma/client'
import {z} from 'zod'
import {RevisionPublicationSchema} from '~/schemas/revision-schema'
import {PrismaModelSchema} from '~/schemas/schema-helpers'

/**
 * Process schemas
 */

export type ProcessSchema = z.infer<typeof ProcessSchema>
export const ProcessSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  mainRevisionId: z.string().nullable(),
  isVersionControlled: z.boolean().readonly(),
  isArchived: z.boolean(),
  privacy: z.nativeEnum(ProcessPrivacy),
  slug: z.string(),
}) satisfies PrismaModelSchema<'Process'>

const ProcessQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    slug: z.string(),
    organizationId: z.string(),
  }),
])
export const processQueryKeys = ProcessQueryKeys.items

export const ProcessQueryKey = z.union(ProcessQueryKeys.items)
export type ProcessQueryKey = z.infer<typeof ProcessQueryKey>

export type ClientProcessSchema = z.infer<typeof ClientProcessSchema>
export const ClientProcessSchema = ProcessSchema.merge(
  z.object({
    title: z.string().nullable(),
    revisionHistory: z.array(RevisionPublicationSchema),
    documentIds: z.array(z.string()),
    type: z.literal('Process'),
  }),
)

