import { MemberStatus, OrganizationRole, Prisma } from '@prisma/client'
import { z } from 'zod'
import {
  ClientUserSchema,
  imageUrlSchema,
  toClientUser,
} from '~/schemas/user-schema'
import { replaceNewLine } from '~/utils/logic'
import { USERNAME_MAX_LENGTH } from './user-schema'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

/**
 * Constants
 */

export const TITLE_MAX_LENGTH = 60
export const DESCRIPTION_MAX_LENGTH = 200

/**
 * Fields
 */

const titleSchema = z
  .string()
  .trim()
  .max(TITLE_MAX_LENGTH, `Must be less than ${TITLE_MAX_LENGTH} characters`)

const aliasSchema = z
  .string()
  .trim()
  .transform(replaceNewLine)
  .pipe(
    z
      .string()
      .max(
        USERNAME_MAX_LENGTH,
        `Must be less than ${USERNAME_MAX_LENGTH} characters`,
      ),
  )

const descriptionSchema = z
  .string()
  .max(
    DESCRIPTION_MAX_LENGTH,
    `Must be less than ${DESCRIPTION_MAX_LENGTH} characters`,
  )

/**
 * Member schemas
 */

export type OrganizationMemberSchema = z.infer<typeof OrganizationMemberSchema>
export const OrganizationMemberSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  userId: z.string().readonly().nullable(),
  alias: aliasSchema.nullable(),
  title: titleSchema.nullable(),
  imageUrl: imageUrlSchema.nullable(),
  description: descriptionSchema.nullable(),
  role: z.nativeEnum(OrganizationRole),
  status: z.nativeEnum(MemberStatus),
  isDisplayLocked: z.boolean(),
}) satisfies PrismaModelSchema<'OrganizationMember'>

// Declares which statuses can be explicitly set
export const OrganizationMemberSchemaActiveStatus = z.enum([
  MemberStatus.Active,
  MemberStatus.Inactive,
])

const OrganizationMemberQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    userId: z.string().nullable(),
    organizationId: z.string(),
  }),
])
export const organizationMemberQueryKeys = OrganizationMemberQueryKeys.items

export const OrganizationMemberQueryKey = z.union(
  OrganizationMemberQueryKeys.items,
)
export type OrganizationMemberQueryKey = z.infer<
  typeof OrganizationMemberQueryKey
>

export type ClientOrganizationMemberSchema = z.infer<
  typeof ClientOrganizationMemberSchema
>
export const ClientOrganizationMemberSchema = OrganizationMemberSchema.merge(
  z.object({
    user: ClientUserSchema.nullable(),
  }),
)

/**
 * Helpers
 */

type MemberWithUser = Prisma.OrganizationMemberGetPayload<{
  include: {
    user: true
  }
}>

export const toClientOrganizationMember = (
  member: MemberWithUser,
): ClientOrganizationMemberSchema => {
  return {
    ...member,
    user: member.user ? toClientUser(member.user) : null,
  }
}

export const toDisplayMember = (
  member: ClientOrganizationMemberSchema,
): ClientOrganizationMemberSchema => {
  return {
    ...member,
    alias:
      member.alias || (member.user ? member.user.username : 'Deleted user'),
    imageUrl: member.imageUrl || (member.user?.imageUrl ?? null),
    title: member.title ?? '',
    description: member.description || '',
    isDisplayLocked: member.isDisplayLocked ?? false,
  }
}
