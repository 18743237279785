import { EdgeLabel } from '~/client/dashboard/components/process/diagram/DiagramConnectors'
import {
  DiagramNodeSVG,
  DiagramNodeWrapper,
} from '~/client/dashboard/components/process/diagram/DiagramNodeWrapper'
import {
  DiagramNodeProps,
  SWITCH_NODE_CONTENT_HEIGHT,
  SWITCH_NODE_CONTENT_MARGIN,
  SWITCH_NODE_ITEM_HEIGHT,
  SWITCH_NODE_ITEM_INSET,
  SWITCH_NODE_ITEM_MARGIN,
  SWITCH_NODE_WIDTH,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import {
  DiagramViewMode,
  useDiagramContext,
} from '~/client/dashboard/stores/DiagramStore'
import { useDarkMode, useGlobalContext } from '~/client/dashboard/stores/GlobalStore.tsx'
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import {
  useProcessAction,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore'
import { Box, Column, Row } from '~/client/shared/Layout'
import { NodeType } from '~/schemas/node-schema'
import { sortBy } from '~/utils/logic'

export const DiagramSwitchNode = (props: DiagramNodeProps) => {
  const { data } = props
  const isDarkMode = useDarkMode()
  const id = useNodeContext((x) => x.id)
  const globalId = useNodeContext((x) => x.globalId)
  const getNodeColor = useGlobalContext((x) => x.getNodeColor)
  const getNodeStrokeColor = useGlobalContext((x) => x.getNodeStrokeColor)

  const queryKey = useRevisionContext((x) => x.queryKey)
  const currentNodeId = useDiagramContext((x) => x.currentNodeId)
  const setCurrentNode = useDiagramContext((x) => x.setCurrentNode)
  const viewMode = useDiagramContext((x) => x.viewMode)
  const debug = useDiagramContext((x) => x.debug)

  const isActive = currentNodeId === globalId
  const { setConnectorText } = useProcessAction(queryKey, 'setConnectorText')
  const { addConnector } = useProcessAction(queryKey, 'addConnector')
  const { deleteConnector } = useProcessAction(queryKey, 'deleteConnector')

  const color = getNodeColor(NodeType.Switch)
  const strokeColor = getNodeStrokeColor(color)

  const labelWidth = SWITCH_NODE_WIDTH - SWITCH_NODE_ITEM_INSET * 2
  const labelColor = isDarkMode
    ? color.lighten(0.05).desaturate(0.2).toString()
    : color.lighten(0.05).toString()

  const connectors = data.connectorsOut

  return (
    <>
      <DiagramNodeWrapper {...props} key={globalId}>
        <Column key={globalId} style={{ height: '100%', width: '100%' }}>
          {/* Header */}
          <Row
            h={SWITCH_NODE_CONTENT_HEIGHT}
            mb={SWITCH_NODE_CONTENT_MARGIN}
            w="100%"
          >
            <DiagramNodeSVG
              type={data.type}
              nodeId={globalId}
              width={props.width}
              height={props.height}
              textInset={data.textInset}
            />
          </Row>
          {/* Labels */}
          {sortBy(connectors, 'position').map((x) => {
            return (
              <Box
                key={x.id + '-label'}
                w={labelWidth}
                h={SWITCH_NODE_ITEM_HEIGHT}
                ml={SWITCH_NODE_ITEM_INSET}
                mb={SWITCH_NODE_ITEM_MARGIN}
                py={3}
                px={8}
                align="stretch"
                justify="stretch"
                onClick={() => setCurrentNode(globalId)}
                style={{ position: 'relative' }}
              >
                {debug && (
                  <div
                    style={{
                      fontSize: 10,
                      position: 'absolute',
                      right: '100%',
                      width: 60,
                    }}
                  >
                    {x.id}
                  </div>
                )}
                <EdgeLabel
                  label={x.text}
                  width="100%"
                  height="100%"
                  targetId={x.target.globalId ?? null}
                  style={{
                    background: labelColor,
                    border: `1px solid ${strokeColor.toString()}`,
                  }}
                  onFocus={() => setCurrentNode(globalId)}
                  onUpdate={(text) => {
                    if (text.length === 0) {
                      deleteConnector({
                        originId: x.origin.id,
                        position: x.position,
                      })
                    } else {
                      setConnectorText({
                        originId: x.origin.id,
                        position: x.position,
                        text,
                      })
                    }
                  }}
                />
              </Box>
            )
          })}
          {/* New label */}
          {viewMode === DiagramViewMode.Editing && (
            <Box
              w={labelWidth}
              ml={SWITCH_NODE_ITEM_INSET}
              h={SWITCH_NODE_ITEM_HEIGHT}
              mb={SWITCH_NODE_ITEM_MARGIN}
              py={3}
              px={8}
              align="stretch"
              justify="stretch"
            >
              <EdgeLabel
                key={connectors.length}
                label={''}
                width="100%"
                height="100%"
                targetId={null}
                placeholder="New option..."
                onFocus={() => setCurrentNode(globalId)}
                onUpdate={(text) => {
                  addConnector({
                    originId: id,
                    targetId: null,
                    text,
                  })
                }}
              />
            </Box>
          )}
        </Column>
      </DiagramNodeWrapper>
    </>
  )
}
