import { DocumentRole } from '@prisma/client'
import {
  ModelName,
  ModelCommandsValidation,
  authorized,
  invalid,
  unauthorized,
  valid,
} from '~/commands/base-commands'

/**
 * DocumentMember commands validation
 */

export const documentMemberValidation = {
  SetRole: {
    validate({ target, sender, meta }) {
      // Allow changing roles in the event that no owner exists.
      //  This could happen if an owner deletes their account
      if (meta.documentOwnerCount > 0) {
        if (!sender.isOwner) {
          return unauthorized(`Only owners can change member roles`)
        }
      }

      if (target.currentRole === target.newRole) {
        return invalid(`Member already has this role`)
      }

      if (
        target.currentRole === DocumentRole.Owner &&
        meta.documentOwnerCount === 1
      ) {
        if (target.isSender) {
          return unauthorized(`Cannot change role before adding another owner`)
        } else {
          return unauthorized(`Cannot change role of sole category owner`)
        }
      }

      return valid()
    },
    authorize({ sender, target, meta }) {
      if (meta.isDocumentRoot) {
        return invalid(`Cannot change member roles on root category`)
      }

      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.DocumentMember>
