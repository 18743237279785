import { Alert, Fieldset, Paper } from '@mantine/core'
import { OrganizationType } from '@prisma/client'
import { RouteMap } from '~/client/RouteMap.ts'
import { Column } from '~/client/shared/Layout'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { MemberCard } from '~/client/dashboard/components/global/Prefabs'
import { OrganizationDropdown } from '~/client/dashboard/components/organization/OrganizationDropdown'
import { SettingsItem } from '~/client/dashboard/components/settings/SettingsShell'
import { EditMemberForm } from '~/client/dashboard/forms/OrganizationMemberForms'
import { EditUserForm } from '~/client/dashboard/forms/UserForms'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { WithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { useDarkMode } from '~/client/dashboard/stores/GlobalStore'

export const Profile = () => {
  const isDarkMode = useDarkMode()
  const user = useUserContext((x) => x.user)
  const memberId = useOrganizationContext((x) => x.currentMember.id)
  const organization = useOrganizationContext((x) => x.organization)

  return (
    <Column h="100%" w="100%" maw={400}>
      <Paper
        p="sm"
        mb="lg"
        withBorder={true}
        bg={isDarkMode ? 'dark.8' : 'gray.0'}
      >
        <MemberCard id={memberId} />
      </Paper>
      <EditUserForm queryKey={{ id: user.id }} />
      {/* Organization Profile */}
      {organization && <OrganizationMember />}
    </Column>
  )
}

const OrganizationMember = () => {
  const navigate = useNavigate()
  const user = useUserContext((x) => x.user)
  const profileId = useOrganizationContext((x) => x.currentMember.id)
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const organizationType = useOrganizationContext((x) => x.organization.type)
  const organizationName = useOrganizationContext((x) => x.organization.name)
  const { organizationMember } = useOrganizationMemberQuery({
    id: profileId,
    organizationId,
  })
  const teamOrganizations = useUserContext((x) => x.organizations).filter(
    (x) => x.type !== OrganizationType.Personal,
  )

  if (!organizationMember || teamOrganizations.length === 0) return null

  return (
    <Fieldset mt="xl" p="lg" legend="Organization settings">
      {organizationType === OrganizationType.Personal && (
        <>
          <OrganizationDropdown
            activeId={user.activeOrganizationId}
            filter={(x) => x.type !== OrganizationType.Personal}
            onSelect={(val) => {
              const slug = teamOrganizations.find((x) => x.id === val)?.slug
              if (slug) navigate(RouteMap.Settings(SettingsItem.Profile, slug))
            }}
          />
        </>
      )}
      {organizationType !== OrganizationType.Personal && (
        <>
          <WithCommand
            model={ModelName.OrganizationMember}
            command="UpdateProfile"
            queryKey={organizationMember}
            notAllowed={({ reason }) => (
              <Alert
                variant="light"
                mb="sm"
                icon={<Icon nudgeUp={4} size={16} name="PiInfo" />}
                title={reason?.message}
              />
            )}
          >
            <Alert
              variant="light"
              mb="sm"
              icon={<Icon nudgeUp={4} size={16} name="PiInfo" />}
              title={`These settings will only apply to your profile inside ${organizationName}`}
            />
          </WithCommand>
          <EditMemberForm
            queryKey={{
              id: organizationMember.id,
              organizationId,
            }}
            settings={{
              placeholders: {
                alias: user.username,
                imageUrl: user.imageUrl,
              },
            }}
          />
        </>
      )}
    </Fieldset>
  )
}
