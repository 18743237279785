import {
  BuiltInNode,
  Edge,
  type Edge as ReactFlowEdge,
  NodeProps,
  Position,
  useReactFlow,
} from '@xyflow/react'
import { LayoutOptions } from 'elkjs/lib/elk-api'
import { CustomEdge } from '~/client/dashboard/components/process/diagram/DiagramConnectors'
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import { ProcessTreePathItem } from '~/client/dashboard/stores/ProcessStore'
import { NodeType } from '~/schemas/node-schema'
import {
  AddNode,
  CustomNode,
} from '~/client/dashboard/components/process/diagram/DiagramCustomNode.tsx'

// Represents only relevant node data required to build diagram
export type DiagramBasisNode = {
  id: number
  type: NodeType
  globalId: string
  processId: string
  revisionId: string
  subprocessId?: string
  subprocessRevisionId?: string
  isExpanded: boolean
  isRoot: boolean
  nodePath: string
  parentNodeGlobalId: string | null
  treePath: ProcessTreePathItem[]
}
export type DiagramBasisNodes = Map<string, DiagramBasisNode>

// Represents only relevant connector data required to build diagram
export type DiagramBasisConnector = {
  id: string
  globalId: string
  processId: string
  revisionId: string
  position: number
  text: string
  parentNodeGlobalId: string | null
  origin: {
    id: number
    globalId: string
  }
  target: {
    id: number | null
    globalId: string | null
  }
}
export type DiagramBasisConnectors = Map<string, DiagramBasisConnector>

export type Position2D = {
  x: number
  y: number
}

export type DiagramNodeProps = NodeProps<BuiltInNode> & {
  height: number
  width: number
  data: DiagramNodeData
}

export type DiagramConnector = ReactFlowEdge & {
  data: DiagramBasisConnector & {
    startPoint: Position2D
    endPoint: Position2D
  }
}

// The node data passed into our custom node components
export type DiagramNodeData = {
  id: number
  globalId: string
  type: NodeType
  parentGlobalId: string | null
  treePath: ProcessTreePathItem[]
  connectorsIn: DiagramBasisConnector[]
  connectorsOut: DiagramBasisConnector[]
  textInset: TextInset
  isExpanded: boolean
}

export const useNodeParentPosition = (globalId?: string | null) => {
  const reactFlow = useReactFlow()
  const parentNode = globalId ? reactFlow.getNode(globalId) : null
  return (
    parentNode?.position ?? {
      x: 0,
      y: 0,
    }
  )
}

export type DiagramNodeType = keyof typeof nodeTypes
export const nodeTypes = {
  custom: CustomNode,
  add: AddNode,
}

export type DiagramEdgeType = keyof typeof edgeTypes
export const edgeTypes = {
  custom: CustomEdge,
  // pathfinding: PathfindingEdge,
}

export const getAddNodeId = (connectorId: string) => connectorId + ':Add'

export const getHandleId = (
  connectorId: string,
  portType: 'source' | 'target',
) => connectorId + ':' + portType

export const getConnectorId = ({
  originId,
  position,
  targetId,
}: {
  originId: number
  position: number
  targetId: number | string | null
}) => `(${originId} -> ${targetId ?? '_'})[${position}]`

export const getHandleSide = (
  nodeSize: NodeSize,
  handlePosition: Position2D,
): Position => {
  const buffer = 10
  if (handlePosition.x < buffer) {
    return Position.Left
  } else if (handlePosition.x > nodeSize.width - buffer) {
    return Position.Right
  } else if (handlePosition.y < buffer) {
    return Position.Top
  } else {
    return Position.Bottom
  }
}

export const SWITCH_NODE_CONTENT_HEIGHT = 58
export const SWITCH_NODE_CONTENT_MARGIN = 4
export const SWITCH_NODE_ITEM_HEIGHT = 24
export const SWITCH_NODE_ITEM_MARGIN = 5
export const SWITCH_NODE_ITEM_INSET = 8
export const SWITCH_NODE_WIDTH = 150

export const SUBPROCESS_NODE_CONTENT_HEIGHT = 50
export const SUBPROCESS_NODE_CONTENT_MARGIN = 4
export const SUBPROCESS_NODE_ITEM_HEIGHT = 40
export const SUBPROCESS_NODE_ITEM_MARGIN = 5
export const SUBPROCESS_NODE_ITEM_INSET = 12
export const SUBPROCESS_NODE_EMPTY_HEIGHT = 50
export const SUBPROCESS_NODE_WIDTH = 150

export const BASE_ACTIVE_STROKE_OUTSET = 13
export const BUTTON_OFFSET_VIEW = 5
export const BUTTON_OFFSET_EDIT = 10

export const DRAG_DISTANCE_START = 3

export const SUBPROCESS_PADDING_LEFT = 30
export const SUBPROCESS_PADDING_RIGHT = 10
export const SUBPROCESS_PADDING_BOTTOM = 40
export const SUBPROCESS_PADDING_TOP = 40

export const MIN_ZOOM = 0.2
export const MAX_ZOOM = 1.5

export const edgeDefaults = {
  interactionWidth: 0,
  animated: true,
  markerEnd: {
    type: 'arrowclosed' as any,
    width: 20,
    height: 20,
  },
} satisfies Partial<Edge>

/**
 * Elk settings
 */

export const layoutOptionsDefaults = {
  'elk.algorithm': 'layered',
  'elk.layered.spacing.nodeNodeBetweenLayers': '80',
  'elk.spacing.nodeNode': '100',
  'elk.padding': `[top=${SUBPROCESS_PADDING_TOP},left=${SUBPROCESS_PADDING_LEFT},bottom=${SUBPROCESS_PADDING_BOTTOM},right=${SUBPROCESS_PADDING_RIGHT}]`,
  'elk.hierarchyHandling': 'INCLUDE_CHILDREN',
  // 'elk.layered.nodePlacement.strategy': 'SIMPLE',
  // 'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
  'elk.layered.nodePlacement.strategy': 'BRANDES_KOEPF',
  'elk.portConstraints': 'FIXED_ORDER',
  'elk.layered.allowNonFlowPortsToSwitchSides': 'true',
  'elk.spacing.portPort': '10',
  'elk.port.borderOffset': '10',
  'elk.layered.crossingMinimization.forceNodeModelOrder': 'true',
  'elk.layered.considerModelOrder.strategy': 'NODES_AND_EDGES',
  'elk.layered.crossingMinimization.strategy': 'LAYER_SWEEP',
  'elk.layered.crossingMinimization.semiInteractive': 'true',
  'elk.layered.thoroughness': '10',
  'elk.layered.portSortingStrategy': 'PORT_DEGREE',
  // 'elk.layered.crossingMinimization.strategy': 'INTERACTIVE',
  // 'elk.interactiveLayout': 'true',
  // 'elk.layered.nodePlacement.linearSegments.deflectionDampening': '0.01',

  // 'elk.portAlignment.default': 'JUSTIFY',
} as LayoutOptions

type NodeSize = { height: number; width: number }

const ElkHandleSide = {
  [Position.Bottom]: 'SOUTH',
  [Position.Left]: 'WEST',
  [Position.Top]: 'NORTH',
  [Position.Right]: 'EAST',
} as const

export enum DiagramDirection {
  Right = 'RIGHT',
  Down = 'DOWN',
}

export const nodeSettings = {
  Add: (direction, numConnectorsOut) => {
    const size = {
      width: 150,
      height: 90,
    }

    return {
      size,
      layoutOptions: {},
    }
  },
  [NodeType.Start]: (direction, numConnectorsOut) => {
    const size = {
      width: 150,
      height: 71,
    }

    return {
      size,
      layoutOptions: {},
      textInsets: {
        top: '8%',
        bottom: '8%',
        left: '8%',
        right: '8%',
      },
      getOutgoingPortSettings: (i) => {
        const { height, width } = size

        if (direction === DiagramDirection.Down) {
          return {
            x: width / 2,
            y: height,
            layoutOptions: {
              side: ElkHandleSide[Position.Bottom],
            },
          }
        } else {
          return {
            x: width,
            y: height / 2,
            layoutOptions: {
              side: ElkHandleSide[Position.Right],
            },
          }
        }
      },
    }
  },
  [NodeType.End]: (direction, numConnectorsOut) => {
    const size = {
      width: 150,
      height: 71,
    }

    return {
      size,
      layoutOptions: {},
      textInsets: {
        top: '8%',
        bottom: '8%',
        left: '8%',
        right: '8%',
      },
      getOutgoingPortSettings: (i) => {
        const { height, width } = size

        if (direction === DiagramDirection.Down) {
          return {
            x: width / 2,
            y: height,
            layoutOptions: {
              side: ElkHandleSide[Position.Bottom],
            },
          }
        } else {
          return {
            x: width,
            y: height / 2,
            layoutOptions: {
              side: ElkHandleSide[Position.Right],
            },
          }
        }
      },
    }
  },
  [NodeType.Step]: (direction, numConnectorsOut) => {
    const size = {
      width: 150,
      height: 90,
    }

    return {
      size,
      layoutOptions: {},
      textInsets: {
        top: '0%',
        bottom: '0%',
        left: '5%',
        right: '5%',
      },
      getOutgoingPortSettings: (i) => {
        const { height, width } = size

        if (direction === DiagramDirection.Down) {
          return {
            x: width / 2,
            y: height,
            layoutOptions: {
              side: ElkHandleSide[Position.Bottom],
            },
          }
        } else {
          return {
            x: width,
            y: height / 2,
            layoutOptions: {
              side: ElkHandleSide[Position.Right],
            },
          }
        }
      },
    }
  },
  [NodeType.Decision]: (direction, numConnectorsOut) => {
    const size = {
      width: 135,
      height: 90,
    }

    return {
      size,
      layoutOptions: {},
      textInsets: {
        top: '20%',
        bottom: '18%',
        left: '15%',
        right: '15%',
      },
      getOutgoingPortSettings: (i) => {
        const { height, width } = size

        if (direction === DiagramDirection.Down) {
          if (i === 0) {
            return {
              x: width / 2,
              y: height,
              layoutOptions: {
                side: ElkHandleSide[Position.Bottom],
              },
            }
          } else {
            return {
              x: width,
              y: height / 2,
              layoutOptions: {
                side: ElkHandleSide[Position.Right],
              },
            }
          }
        } else {
          if (i === 0) {
            return {
              x: width,
              y: height / 2,
              layoutOptions: {
                side: ElkHandleSide[Position.Right],
              },
            }
          } else {
            return {
              x: width / 2,
              y: height,
              layoutOptions: {
                side: ElkHandleSide[Position.Bottom],
              },
            }
          }
        }
      },
    }
  },
  [NodeType.Switch]: (direction, numConnectorsOut) => {
    const size = {
      width: SWITCH_NODE_WIDTH,
      height:
        SWITCH_NODE_ITEM_HEIGHT * (numConnectorsOut + 1) +
        SWITCH_NODE_ITEM_MARGIN * (numConnectorsOut + 1) +
        SWITCH_NODE_CONTENT_HEIGHT +
        SWITCH_NODE_CONTENT_MARGIN,
    }

    return {
      size,
      layoutOptions: {},
      textInsets: {
        top: '5%',
        bottom: '5%',
        left: '15%',
        right: '15%',
      },
      getOutgoingPortSettings: (i) => {
        const { height, width } = size

        return {
          x: width - SWITCH_NODE_ITEM_INSET,
          y:
            3 + // Additional required due to SVG scaling
            SWITCH_NODE_CONTENT_HEIGHT +
            SWITCH_NODE_CONTENT_MARGIN +
            SWITCH_NODE_ITEM_HEIGHT * i +
            SWITCH_NODE_ITEM_MARGIN * i +
            SWITCH_NODE_ITEM_HEIGHT / 2,
          layoutOptions: {
            side: ElkHandleSide[Position.Right],
          },
        }
      },
    }
  },
  [NodeType.Subprocess]: (direction, numConnectorsOut) => {
    const itemsHeight =
      numConnectorsOut === 0
        ? SUBPROCESS_NODE_EMPTY_HEIGHT
        : SUBPROCESS_NODE_ITEM_HEIGHT * numConnectorsOut +
          SUBPROCESS_NODE_ITEM_MARGIN * numConnectorsOut

    const size = {
      width: SUBPROCESS_NODE_WIDTH,
      height:
        itemsHeight +
        SUBPROCESS_NODE_CONTENT_HEIGHT +
        SUBPROCESS_NODE_CONTENT_MARGIN +
        SUBPROCESS_NODE_ITEM_INSET,
    }

    return {
      size,
      layoutOptions: {},
      textInsets: {
        top: '0%',
        bottom: '0%',
        left: '5%',
        right: '5%',
      },
      getOutgoingPortSettings: (i) => {
        const { height, width } = size

        return {
          x: width,
          y:
            SUBPROCESS_NODE_CONTENT_HEIGHT +
            SUBPROCESS_NODE_CONTENT_MARGIN +
            SUBPROCESS_NODE_ITEM_HEIGHT * i +
            SUBPROCESS_NODE_ITEM_MARGIN * i +
            SUBPROCESS_NODE_ITEM_HEIGHT / 2,
          layoutOptions: {
            side: ElkHandleSide[Position.Right],
          },
        }
      },
    }
  },
} satisfies {
  [type in `${NodeType}` | 'Add']: (
    direction: DiagramDirection,
    numConnectorsOut: number,
  ) => {
    getOutgoingPortSettings?: (index: number) => {
      x: number
      y: number
      layoutOptions: LayoutOptions & {
        side: (typeof ElkHandleSide)[Position]
      }
    }
    size: NodeSize
    layoutOptions: LayoutOptions
    textInsets?: TextInset
  }
}

export type TextInset = {
  top: string
  bottom: string
  left: string
  right: string
}

export type DiagramNodeContentType = NodeType | 'Add'

export const getLabelPosition = (position: Position) => {
  if (position === Position.Left) {
    return {
      translateX: '-100%',
      translateY: 0,
      left: -6,
      top: 6,
    }
  }
  if (position === Position.Right) {
    return {
      translateX: 0,
      translateY: 0,
      left: 1,
      top: 6,
    }
  }
  if (position === Position.Top) {
    return {
      translateX: 0,
      translateY: '-100%',
      left: 5,
      top: 0,
    }
  }
  if (position === Position.Bottom) {
    return {
      translateX: 0,
      translateY: 0,
      left: 2,
      top: 1,
    }
  }
}

export const movingNodePosition = {
  position: { x: 0, y: 0, width: 0, height: 0 },
}

export const useNodeFontSize = () => {
  const type = useNodeContext((x) => x.type)
  const title = useNodeContext((x) => x.title)

  let fontSize = 12
  if (type === NodeType.Decision || type === NodeType.Switch) {
    fontSize = title.length > 30 ? (title.length > 40 ? 9 : 11) : 12
  }
  return fontSize
}

// TODO: This is a weak check
export const isAddNode = (id?: string) => Boolean(id?.includes('Add'))
