'use client'

import { Column } from '~/client/shared/Layout'
import { EditOrganizationForm } from '~/client/dashboard/forms/OrganizationForms'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { ModelName } from '~/commands/base-commands'
import { WithCommand } from '~/commands/WithCommand'

export const Appearance = () => {
  const organization = useOrganizationContext((x) => x.organization)

  return (
    <Column h="100%" w="100%" maw={400}>
      <WithCommand
        model={ModelName.Organization}
        command="UpdateProfile"
        notAllowed="disable"
        queryKey={{ id: organization.id }}
      >
        <EditOrganizationForm
          queryKey={{
            id: organization.id,
          }}
        />
      </WithCommand>
    </Column>
  )
}
