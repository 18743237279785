'use client'
import { ScrollArea } from '@mantine/core'
import { mergeRefs } from '@mantine/hooks'
import { Link, RichTextEditor, RichTextEditorProps } from '@mantine/tiptap'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import SubScript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Underline from '@tiptap/extension-underline'
import Typography from '@tiptap/extension-typography'
import {
  BubbleMenu,
  Editor,
  EditorOptions,
  FloatingMenu,
  useEditor,
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Ref, useEffect, useState } from 'react'
import { Row } from '~/client/shared/Layout'
import useElementFromRef from '~/client/shared/hooks/useElementFromRef'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'

export const TextEditor = ({
  content,
  options = {},
  styles = {},
  placeholder = '',
  withBubbleMenu = true,
  withFloatingMenu = true,
  mih,
  getInitialEditor,
  toolbarRef,
  editorRef: _editorRef,
  ...props
}: {
  mih?: number
  content: string
  options?: Partial<EditorOptions>
  withBubbleMenu?: boolean
  withFloatingMenu?: boolean
  getInitialEditor?: (editor: Editor) => void
  toolbarRef?: Ref<HTMLDivElement>
  editorRef?: Ref<HTMLDivElement>
  maxHeight?: number
  placeholder: string
} & Omit<RichTextEditorProps, 'editor' | 'content' | 'children'>) => {
  const deviceSize = useGlobalContext(x => x.deviceSize)
  const deviceType = useGlobalContext(x => x.deviceType)
  const [editorRef, editorEl] = useElementFromRef<HTMLDivElement>()
  const [focused, setFocused] = useState(false)
  const [ready, setReady] = useState(false)
  const editor = useEditor({
    ...options,
    onUpdate(content) {
      options.onUpdate?.(content)
    },
    onFocus(e) {
      options.onFocus?.(e)
      setFocused(true)
    },
    onBlur(e) {
      options.onBlur?.(e)
      setFocused(false)
    },
    onCreate() {
      setTimeout(() => {
        setReady(true)
      })
    },
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Typography.configure({
        openSingleQuote: false,
        closeSingleQuote: false,
        openDoubleQuote: false,
        closeDoubleQuote: false,
      }),
      Placeholder.configure({ placeholder, showOnlyWhenEditable: false }),
      // TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    editable: false,
    content,
  })

  editor?.setOptions({})

  useEffect(() => {
    if (!editor) return
    getInitialEditor?.(editor)
  }, [Boolean(editor)])

  useEffect(() => {
    if (!editor) return
    editor.setEditable(Boolean(options.editable))
    editor.view.dom.tabIndex = 2
  }, [Boolean(options.editable), editor])

  return (
    <RichTextEditor
      editor={editor}
      // @ts-ignore
      ref={mergeRefs(_editorRef, editorRef)}
      {...props}
      styles={{
        root: {
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          flexDirection: 'column',
          border: 'none',
          background: 'transparent',
        },
        toolbar: {
          rowGap: '0.5rem',
          top: 0,
          padding: 0,
        },
        typographyStylesProvider: {
          flexGrow: 1,
          display: 'flex',
          width: '100%',
          overflowY: 'auto',
        },
        control: {
          width: deviceSize.isSmall && !deviceSize.isXS ? 34 : 28,
          height: deviceSize.isSmall && !deviceSize.isXS ? 36 : 30,
        },
        controlsGroup: {
          borderRadius: 5,
        },
        content: {
          display: 'flex',
          flexGrow: 1,
          alignItems: 'stretch',
          justifyContent: 'stretch',
          flexDirection: 'column',
          fontSize: 13,
          background: 'transparent',
          // overflowY: 'auto !important',
        },
      }}
    >
      {editor && withFloatingMenu && (
        <FloatingMenu editor={editor}>
          <Row gap={6} mt={8}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>
          </Row>
        </FloatingMenu>
      )}
      {/* </Portal> */}

      {editor && withBubbleMenu && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            placement: deviceType.os === 'android' ? 'bottom' : 'top',
            offset: deviceType.os === 'android' ? [0, 24] : [0, 10],
          }}
        >
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Code />
            <RichTextEditor.Link />
          </RichTextEditor.ControlsGroup>
        </BubbleMenu>
      )}

      <ScrollArea
        styles={{
          viewport: {
            maxHeight: deviceSize.isSmall ? 170 : '50vh',
          },
        }}
      >
        <RichTextEditor.Content mih={mih} />
      </ScrollArea>
    </RichTextEditor>
  )
}
