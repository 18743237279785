import { TeamAccess, TeamRole } from '@prisma/client'
import { z } from 'zod'
import { imageUrlSchema, UserSchema } from '~/schemas/user-schema'
import { replaceNewLine } from '~/utils/logic'

/**
 * Constants
 */

export const NAME_MAX_LENGTH = 60
export const DESCRIPTION_MAX_LENGTH = 200

/**
 * Fields
 */

const nameSchema = z
  .string()
  .trim()
  .transform(replaceNewLine)
  .pipe(
    z
      .string()
      .max(
        NAME_MAX_LENGTH,
        `Name must be less than ${NAME_MAX_LENGTH} characters`,
      ),
  )

const descriptionSchema = z
  .string()
  .max(
    DESCRIPTION_MAX_LENGTH,
    `Description must be less than ${DESCRIPTION_MAX_LENGTH} characters`,
  )

/**
 * Team schemas
 */

export type TeamSchema = z.infer<typeof TeamSchema>
export const TeamSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  creatorId: z.string().readonly(),
  name: nameSchema,
  slug: z.string(),
  imageUrl: imageUrlSchema.nullable(),
  description: descriptionSchema.nullable(),
  access: z.nativeEnum(TeamAccess),
})

const TeamQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    slug: z.string(),
    organizationId: z.string(),
  }),
])
export const teamQueryKeys = TeamQueryKeys.items

export const TeamQueryKey = z.union(TeamQueryKeys.items)
export type TeamQueryKey = z.infer<typeof TeamQueryKey>

export const ClientTeamSchema = TeamSchema
