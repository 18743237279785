import { IconName } from '~/client/dashboard/components/global/Icon.tsx'

export const logoUrl = '/logo-x.png'

export const globalIcons = {
  MenuDots: 'PiDotsThreeBold',
  Delete: 'PiTrashSimpleDuotone',
  Archive: 'PiArchive',
  Unarchive: 'PiArrowFatLineUp',
  Settings: 'PiGear',
  Join: 'PiUserPlus',
  Home: 'PiHouse',
  Exit: 'PiSignOutBold',
  Logout: 'PiSignOutBold',
  MemberList: 'PiUserList',
  OrganizationLogo: 'PiWarehouseDuotone',
  Team: 'PiFlowerLotus',
  TeamLogo: 'PiFlowerLotusDuotone',
  LogoPlaceholder: 'PiPlaceholderDuotone',
} satisfies {
  [key: string]: IconName
}
