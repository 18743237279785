'use client'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { createStore, useStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { BodySkeleton } from '~/client/dashboard/components/global/DashboardShell'
// import { useReportProcessView } from '~/client/dashboard/mutations/process-mutations'
import { Redirect } from 'wouter'
import { RouteMap } from '~/client/RouteMap.ts'
import { useDocumentsWithProfileQuery } from '~/client/dashboard/queries/helpers/composite-queries'
import { QueryRequestError } from '~/client/dashboard/queries/helpers/query-helpers'
import { useProcessMemberProfileQuery } from '~/client/dashboard/queries/process-member-queries.tsx'
import {
  useProcessQuery,
  useProcessRevisionsQuery,
} from '~/client/dashboard/queries/process-queries'
import { DiagramViewMode } from './DiagramStore'
import {
  useGlobalContext,
  useGlobalStore,
} from '~/client/dashboard/stores/GlobalStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { ProcessContext } from '~/client/dashboard/stores/ProcessContext'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'
import { getMemberProcessAccess } from '~/commands/process/process-commands-validation'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { ProcessMemberProfileSchema } from '~/schemas/process-member-schema.ts'
import { ProcessQueryKey } from '~/schemas/process-schema'
import { RevisionPublicationSchema } from '~/schemas/revision-schema'
import { getNodeGlobalId } from '~/client/dashboard/stores/NodeStore'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import { RequestError } from '~/client/shared/ErrorPage'

/**
 * Store
 */

export type ProcessTreePathItem = {
  processId: string
  revisionId: string
  nodeId: number
}

export type ProcessState = {
  id: string
  queryKey: ModelQueryKey['Process']
  process: ClientModel['Process']
  revisions: ClientModel['Revision'][]
  versions: Versions
  memberProfile: ProcessMemberProfileSchema
  // Diagram/tree storage
  treePath: ProcessTreePathItem[]
  globalCurrentNodeId: string | null
  parentNodeGlobalId: string | null
}

export type RevisionPublishedItem = {
  isDraft: false
  revision: ClientModel['Revision']
  publication: RevisionPublicationSchema
  displayName: string
  version: number
}

export type RevisionDraftItem = {
  isDraft: true
  revision: ClientModel['Revision']
  displayName: string
  updatedAt: Date
  parent: RevisionPublishedItem | null
}

export type Versions = {
  isLoading: boolean
  published: RevisionPublishedItem[]
  drafts: RevisionDraftItem[]
}

const PERSISTED_FIELDS = ['globalCurrentNodeId'] as (keyof ProcessState)[]

type ProcessStoreProps = {
  process: ProcessState['process']
  revisions: ProcessState['revisions']
  memberProfile: ProcessState['memberProfile']
  treePath: ProcessTreePathItem[]
}

export type ProcessStore = ReturnType<typeof createProcessStore>

const createProcessStore = ({
  process,
  revisions,
  memberProfile,
  treePath,
}: ProcessStoreProps) => {
  const DEFAULT_PROPS = {
    id: process.id,
    queryKey: {
      id: process.id,
      organizationId: process.organizationId,
    },
    revisions,
    process,
    memberProfile,
    treePath,
    parentNodeGlobalId: null,
  } satisfies Partial<ProcessState>

  return createStore<ProcessState>()(
    persist(
      (set) =>
        ({
          ...DEFAULT_PROPS,
        }) as ProcessState,
      {
        name: `process-storage:${process.id}`,
        version: 0, // Update if any fields change
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              PERSISTED_FIELDS.includes(key as keyof ProcessState),
            ),
          ),
      },
    ),
  )
}

/**
 * Context
 */

type ProcessProviderProps = React.PropsWithChildren<{
  queryKey: ProcessQueryKey
  skipLogic?: boolean
  treePath?: ProcessTreePathItem[]
  loader?: ReactNode
  failure?: ReactNode
  notFound?: ReactNode
}>

export const ProcessProvider = ({
  children,
  queryKey,
  treePath = [],
  skipLogic = false,
  loader,
  failure,
  notFound,
}: ProcessProviderProps) => {
  // GlobalStore logic
  const addProcessStore = useGlobalContext((x) => x.addProcessStore)

  // Fields
  const organizationId = useOrganizationContext((x) => x.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const memberId = useOrganizationContext((x) => x.currentMember.id)

  // Queries
  const { process, isLoading, error } = useProcessQuery(queryKey, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })
  const { revisions, isLoading: isLoadingRevisions } =
    useProcessRevisionsQuery(queryKey)
  const { memberProfile, isLoadingMemberProfile, isLoadingMemberProfileError } =
    useProcessMemberProfileQuery(
      {
        memberId,
        organizationId,
        processId: process?.id,
      },
      {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      },
    )

  const id = process?.id
  const globalId = getProcessGlobalId(id ?? null, treePath)
  const processStore = useGlobalContext((x) =>
    globalId ? x.processStores.get(globalId) : null,
  )

  useEffect(() => {
    if (!globalId || !process || !memberProfile) return
    if (processStore) {
      processStore.setState({
        process,
        revisions,
        memberProfile,
      })
    } else {
      const store = createProcessStore({
        process,
        revisions,
        memberProfile,
        treePath,
      })
      addProcessStore(globalId, store)
    }
  }, [processStore, process, revisions, memberProfile, globalId])

  if (error) {
    return failure ?? <QueryRequestError error={error} />
  }

  const isRoot = treePath.length === 0

  if (!process || !memberProfile) {
    if (isLoading || isLoadingMemberProfile) {
      return loader
    }
    return notFound ?? <RequestError code={404} message="Not Found" />
  }

  if (!processStore) return null

  __dev.process = {
    ...process,
    store: processStore,
  }

  return (
    <ErrorBoundary>
      <ProcessContext.Provider value={processStore}>
        {!skipLogic && <ProcessContextLogic />}
        {!skipLogic && isRoot && <ProcessContextRootLogic />}
        {children}
      </ProcessContext.Provider>
    </ErrorBoundary>
  )
}

const ProcessContextRootLogic = () => {
  const addActiveItem = useOrganizationContext((x) => x.addActiveItem)
  const removeActiveItem = useOrganizationContext((x) => x.removeActiveItem)
  const processId = useProcessContext((x) => x.id)

  useEffect(() => {
    addActiveItem({ type: 'Process', id: processId })

    return () => {
      removeActiveItem(processId)
    }
  }, [processId])

  return null
}

const ProcessContextLogic = () => {
  const queryKey = useProcessContext((x) => x.queryKey)
  const treePath = useProcessContext((x) => x.treePath)
  const versions = useProcessVersionsQuery(queryKey)
  const store = useProcessStore()

  useEffect(() => {
    store.setState({
      versions,
    })
  }, [store, versions])

  useEffect(() => {
    const parentNodeId = treePath.at(-1)?.nodeId
    const parentNodeGlobalId = parentNodeId
      ? getNodeGlobalId(parentNodeId, treePath.slice(0, -1))!
      : undefined
    store.setState({
      parentNodeGlobalId,
    })
  }, [store, treePath])

  return null
}

export const useProcessContext = <T extends any>(
  selector: (state: ProcessState) => T,
) => {
  const store = useContext(ProcessContext)
  if (!store) throw new Error('Missing ProcessContext.Provider in the tree')
  return useStore(store, selector)
}

export const useProcessStore = () => {
  const store = useContext(ProcessContext)
  if (!store) throw new Error('Missing ProcessContext.Provider in the tree')
  return store
}

export const useProcessAccess = () => {
  const organizationId = useProcessContext((x) => x.process.organizationId)
  const organizationMemberId = useOrganizationContext((x) => x.currentMember.id)
  const documentIds = useProcessContext((x) => x.process.documentIds)
  const privacy = useProcessContext((x) => x.process.privacy)
  const role = useProcessContext((x) => x.memberProfile.role)
  const { documentMemberProfileMap } = useDocumentsWithProfileQuery({
    documentIds,
    organizationId,
    organizationMemberId,
  })

  const hasCategoryViewAccess = documentMemberProfileMap
    .map(({ document, memberProfile }) =>
      document
        ? getMemberDocumentAccess({
            privacy: document.privacy,
            documentMemberRole: memberProfile?.role ?? null,
          })
        : { edit: false, view: false },
    )
    .some((x) => x.view)

  return getMemberProcessAccess({
    privacy,
    processMemberRole: role,
    hasCategoryViewAccess,
  })
}

export const useProcessVersionsQuery = (
  queryKey?: ModelQueryKey['Process'],
) => {
  const { process, isLoading: isLoadingProcess } = useProcessQuery(queryKey)
  const { revisions, isLoading: isLoadingRevisions } =
    useProcessRevisionsQuery(queryKey)
  const [result, setResult] = useState<
    {
      published: RevisionPublishedItem[]
      drafts: RevisionDraftItem[]
    } & (
      | {
          isLoading: true
          process: null
        }
      | {
          isLoading: false
          process: ClientModel['Process']
        }
    )
  >({
    isLoading: true,
    process: null,
    published: [],
    drafts: [],
  })

  useEffect(() => {
    if (isLoadingProcess || isLoadingRevisions || !process) return

    const _published = process
      ? process.revisionHistory
          .sort(
            (d1, d2) =>
              new Date(d1.publishedAt).getTime() -
              new Date(d2.publishedAt).getTime(),
          )
          .map(
            (publication, i) =>
              ({
                isDraft: false,
                publication,
                revision: revisions.find(
                  (revision) => revision.id === publication.revisionId,
                )!,
                displayName: `Version ${publication.version}`,
                version: publication.version,
              }) as RevisionPublishedItem,
          )
          .filter((x) => Boolean(x.revision))
          .reverse()
      : []

    const _drafts = revisions
      .filter((x) => x.isDraft)
      .sort(
        (d1, d2) =>
          new Date(d2.updatedAt).getTime() - new Date(d1.updatedAt).getTime(),
      )
      .map((draft) => {
        const parent = _published.find(
          (x) => x.revision.id === draft.parentRevisionId,
        )
        return {
          isDraft: true,
          revision: draft,
          displayName: 'Draft',
          updatedAt: draft.updatedAt,
          parent,
        } as RevisionDraftItem
      })

    setResult({
      process,
      isLoading: false,
      published: _published,
      drafts: _drafts,
    })
  }, [process, revisions, isLoadingProcess, isLoadingRevisions])

  return result
}

// Root revision is the one containing all other subprocesses
export const useRootProcessContext = <T extends unknown>(
  selector: (state: ProcessState) => T,
) => {
  const revisionStore = useRootProcessStore()
  return useStore(revisionStore, selector)
}

export const useRootProcessStore = () => {
  const processId = useProcessContext((x) => x.id)
  const rootProcessId =
    useProcessContext((x) => x.treePath[0]?.processId) ?? processId
  return useGlobalContext((x) => x.processStores.get(rootProcessId))!
}

export const getProcessPathString = (treePath: ProcessTreePathItem[]) => {
  return treePath.map((x) => x.processId).join('_')
}

export const getProcessGlobalId = (
  id: number | string | null,
  treePath: ProcessTreePathItem[],
) => {
  if (!id) return null
  if (treePath.length === 0) return String(id)
  return getProcessPathString(treePath) + '_' + id
}

export const useProcessGlobalId = <
  T extends number | string | null,
  U extends T extends null ? null : string,
>(
  id: T,
): U => {
  const treePath = useProcessContext((x) => x.treePath)
  return getProcessGlobalId(id, treePath) as U
}
