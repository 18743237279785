/**
 * Bootstrap / Current user
 */
import { useEffect, useState } from 'react'
import { cacheBootstrapOrganization } from '~/client/dashboard/queries/organization-queries'
import {
  ApiContext,
  cacheEachKey,
  parseKey,
  QueryOptions,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { cacheUser } from '~/client/dashboard/queries/user-queries'
import { PartialRequired } from '~/client/globals'
import { Bootstrap, ClientModel } from '~/schemas'
import {
  UserQueryKey,
  userQueryKeys,
  toClientUser,
  CurrentUserQueryKey,
} from '~/schemas/user-schema'
import { api } from '~/utils/api'
import { useAuth } from '@clerk/clerk-react'

// Intended for initial startup data retrieval and caching.
//  Content in this query will not be kept up to date.
export const _useBootstrapQuery = () => {
  const ctx = api.useUtils()
  const [ready, setReady] = useState(false)
  const result = api.User.getBootstrap.useQuery(
    {},
    {
      staleTime: 30 * 60 * 1000, // 3 Minutes
      gcTime: Infinity,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: RETRY,
    },
  )
  const bootstrap = result.data

  useEffect(() => {
    if (!bootstrap) return
    cacheBootstrap(ctx, bootstrap, {})
    setReady(true)
  }, [bootstrap])

  return { ...result, bootstrap, ready }
}

export const useCurrentUserQuery = (
  key: PartialRequired<CurrentUserQueryKey>,
  options: QueryOptions = {},
) => {
  const { userId, isLoaded } = useAuth()
  const enabled = CurrentUserQueryKey.safeParse(key).success && isLoaded
  const result = api.User.getCurrent.useQuery(parseKey(key, UserQueryKey), {
    enabled,
    staleTime: 5 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: RETRY,
    ...options,
  })

  const user = result.data
  useCacheResult(result, cacheCurrentUser, () => key, false)

  return {
    ...result,
    user,
    isLoadingUser: result.isPending && enabled,
    isLoadingUserError: result.isError,
  }
}

/**
 * Cache get
 */

export const getCurrentUserFromCache = (
  ctx: ApiContext,
  key: CurrentUserQueryKey,
) => {
  key = CurrentUserQueryKey.parse(key)
  return ctx.User.getCurrent.getData(key)
}

/**
 * Cache set
 */

export const cacheBootstrap = (
  ctx: ApiContext,
  data: Bootstrap,
  key: CurrentUserQueryKey,
) => {
  // Note: Unlike other caching methods, this cannot be reverted
  key = CurrentUserQueryKey.parse(key)
  ctx.User.getBootstrap.setData(key, data)

  // Debug helper
  __dev.ctx = ctx

  const { profiles, user } = data

  cacheCurrentUser(ctx, user, user)

  // Traverse all bootstrap data and set initial cache for all items
  profiles.forEach((x) =>
    cacheBootstrapOrganization(
      ctx,
      x,
      {
        id: x.organization.id,
        slug: x.organization.slug,
      },
      {},
    ),
  )
}

export const cacheCurrentUser = (
  ctx: ApiContext,
  data:
    | ClientModel['User']
    | null
    | undefined
    | ((
        old: ClientModel['User'] | null | undefined,
      ) => ClientModel['User'] | null | undefined),
  key: CurrentUserQueryKey,
  itemOnly = false,
) => {
  key = CurrentUserQueryKey.parse(key)
  const previous = getCurrentUserFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    cacheEachKey(ctx.User.getCurrent, userQueryKeys, result, result)

    if (!itemOnly) {
      cacheUser(ctx, result, result, true)
    }
  }
  return {
    previous,
    revert: () => {
      cacheCurrentUser(ctx, previous, key, itemOnly)
    },
  }
}
