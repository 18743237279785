import { z } from 'zod'
import type { ModelCommandsSchema } from '~/commands/base-commands'
import { BootstrapProcess } from '~/schemas'
import { DocumentAccess } from '~/schemas/document-member-schema'
import { DocumentSchema } from '~/schemas/document-schema'
import {
  ClientInvitationSchema,
  OrganizationInvitationSchema,
} from '~/schemas/invitation-schema'
import { OrganizationMemberSchema } from '~/schemas/organization-member-schema'
import { OrganizationSchema } from '~/schemas/organization-schema'
import { ProcessSchema } from '~/schemas/process-schema'
import { ClientTeamSchema, TeamSchema } from '~/schemas/team-schema'

/**
 * Organization commands schema
 */

export const organizationCommandsSchema = {
  UpdateProfile: {
    summary: `Update an organization's profile settings`,
    description: '',
    method: 'PATCH',
    schemas: {
      input: z.object({
        name: OrganizationSchema.shape.name,
        description: OrganizationSchema.shape.description.optional(),
        imageUrl: OrganizationSchema.shape.imageUrl.optional(),
      }),
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          role: OrganizationMemberSchema.shape.role.optional(),
        },
        target: {},
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update organization settings',
    },
    variables: {
      execute: 'updateOrganizationProfile',
      isPending: 'isUpdatingOrganizationProfile',
    },
  },
  Archive: {
    summary: 'Archive an organization',
    description: '',
    method: 'PATCH',
    schemas: {
      input: undefined,
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          isOwner: z.boolean(),
        },
        target: { type: OrganizationSchema.shape.type },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to archive organization',
    },
    variables: {
      execute: 'archiveOrganization',
      isPending: 'isArchivingOrganization',
    },
  },
  Unarchive: {
    summary: 'Unarchive an organization',
    description: '',
    method: 'PATCH',
    schemas: {
      input: undefined,
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          isOwner: z.boolean(),
        },
        target: { type: OrganizationSchema.shape.type },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to unarchive organization',
    },
    variables: {
      execute: 'unarchiveOrganization',
      isPending: 'isUnarchivingOrganization',
    },
  },
  Delete: {
    summary: 'Delete an organization',
    description: '',
    method: 'DELETE',
    schemas: {
      input: undefined,
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          isOwner: z.boolean(),
        },
        target: { type: OrganizationSchema.shape.type },
        meta: {},
      },
    },
    messages: {
      processing: 'Deleting organization...',
      failure: 'Failed to delete organization',
    },
    variables: {
      execute: 'deleteOrganization',
      isPending: 'isDeletingOrganization',
    },
  },
  CreateTeam: {
    summary: `Create a team`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        name: TeamSchema.shape.name,
        imageUrl: TeamSchema.shape.imageUrl.optional(),
        description: TeamSchema.shape.description.optional(),
        access: TeamSchema.shape.access.optional(),
      }),
      output: ClientTeamSchema,
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          role: OrganizationMemberSchema.shape.role.optional(),
        },
        target: { type: OrganizationSchema.shape.type },
        meta: {},
      },
    },
    messages: {
      processing: 'Creating team...',
      failure: 'Failed to create team',
    },
    variables: {
      execute: 'createTeam',
      isPending: 'isCreatingTeam',
    },
  },
  CreateInvitation: {
    summary: `Create an invitation`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        role: OrganizationInvitationSchema.shape.role,
        label: OrganizationInvitationSchema.shape.label.optional(),
        validUntil: OrganizationInvitationSchema.shape.validUntil.optional(),
        inviteMessage:
          OrganizationInvitationSchema.shape.inviteMessage.optional(),
        emailToMatch:
          OrganizationInvitationSchema.shape.emailToMatch.optional(),
        memberAlias: OrganizationInvitationSchema.shape.memberAlias.optional(),
        memberImageUrl:
          OrganizationInvitationSchema.shape.memberImageUrl.optional(),
        memberTitle: OrganizationInvitationSchema.shape.memberTitle.optional(),
        memberDescription:
          OrganizationInvitationSchema.shape.memberDescription.optional(),
        memberIsDisplayLocked:
          OrganizationInvitationSchema.shape.memberIsDisplayLocked.optional(),
      }),
      output: ClientInvitationSchema,
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          role: OrganizationMemberSchema.shape.role.optional(),
        },
        target: { type: OrganizationSchema.shape.type },
        meta: {},
      },
    },
    messages: {
      processing: 'Creating invitation...',
      failure: 'Failed to create invitation',
    },
    variables: {
      execute: 'createInvitation',
      isPending: 'isCreatingInvitation',
    },
  },
  CreateProcess: {
    summary: `Create a new process`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        privacy: ProcessSchema.shape.privacy.optional(),
        isVersionControlled: z.boolean().optional(),
        documentIds: z.array(z.string()),
      }),
      // TODO: Resolve this circular dependency
      output: z.any() as unknown as typeof BootstrapProcess,
      validate: {
        sender: { documentsAccess: z.array(DocumentAccess) },
        target: {
          type: OrganizationSchema.shape.type,
          rootDocumentId: DocumentSchema.shape.id,
        },
        meta: {
          isVersionControlled: z.boolean().optional(),
          documentIds: z.array(DocumentSchema.shape.id),
        },
      },
      authorize: {
        sender: {
          role: OrganizationMemberSchema.shape.role.optional(),
        },
        target: { type: OrganizationSchema.shape.type },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to create process',
    },
    variables: {
      execute: 'createProcess',
      isPending: 'isCreatingProcess',
    },
  },
} as const satisfies ModelCommandsSchema
