/**
 * User
 */
import { cacheOrganizationMember } from '~/client/dashboard/queries/organization-member-queries'
import {
  ApiContext,
  CacheFn,
  QueryOptions,
  RETRY,
  cacheEachKey,
  parseKey,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import {
  cacheCurrentUser,
  getCurrentUserFromCache,
} from '~/client/dashboard/queries/user-bootstrap-queries'
import { PartialRequired } from '~/client/globals'
import { ModelName } from '~/commands/base-commands'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { UserQueryKey, userQueryKeys } from '~/schemas/user-schema'
import { api } from '~/utils/api'
import { cacheOrganization } from '~/client/dashboard/queries/organization-queries'

const STALE_TIME = 60 * 1000 // 1 minute
const CACHE_TIME = 60 * 60 * 1000 // 60 minutes

export const useUserQuery = (
  key: PartialRequired<UserQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = UserQueryKey.safeParse(key).success
  const result = api.User.get.useQuery(parseKey(key, UserQueryKey), {
    enabled,
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: RETRY,
    ...options,
  })

  const user = result.data
  useCacheResult(result, cacheUser, () => key, false)

  return {
    ...result,
    user,
    isLoadingUser: result.isPending && enabled,
    isLoadingUserError: result.isError,
  }
}

export const useUserOrganizationsQuery = (
  key: PartialRequired<UserQueryKey>,
  options: QueryOptions = {},
) => {
  const result = api.User.getOrganizations.useQuery(
    parseKey(key, UserQueryKey),
    {
      enabled: UserQueryKey.safeParse(key).success,
      staleTime: 5 * 60 * 1000,
      gcTime: 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )

  return {
    ...result,
    organizations: result?.data ?? [],
    isLoadingUser: result.isPending,
    isLoadingUserError: result.isError,
  }
}

/**
 * Cache get
 */

export const getUserFromCache = (ctx: ApiContext, key: UserQueryKey) => {
  key = UserQueryKey.parse(key)
  return ctx.User.get.getData(key)
}

export const getUserOrganizationsFromCache = (
  ctx: ApiContext,
  key: UserQueryKey,
) => {
  key = UserQueryKey.parse(key)
  return ctx.User.getOrganizations.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheUser: CacheFn<
  ModelQueryKey['User'],
  ClientModel['User'],
  boolean
> = (ctx, data, key, itemOnly = false) => {
  key = UserQueryKey.parse(key)
  const previous = getUserFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    cacheEachKey(ctx.User.get, userQueryKeys, result, result)

    if (!itemOnly) {
      // Update all user's "OrganizationMember" in cache
      const organizations = getUserOrganizationsFromCache(ctx, result)
      organizations.forEach((x) => {
        cacheOrganizationMember(
          ctx,
          (previous) =>
            previous
              ? {
                  ...previous,
                  user: result,
                }
              : undefined,
          { userId: result.id, organizationId: x.id },
          { updateUser: false, updateOrganizationMembers: true },
        )
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheUser(ctx, previous, key, itemOnly)
    },
  }
}

export const cacheUserOrganizations = (
  ctx: ApiContext,
  data:
    | ClientModel['Organization'][]
    | undefined
    | ((
        old: ClientModel['Organization'][] | undefined,
      ) => ClientModel['Organization'][] | undefined),
  key: UserQueryKey,
  listOnly?: boolean,
) => {
  key = UserQueryKey.parse(key)
  const previous = getUserOrganizationsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const user = getCurrentUserFromCache(ctx, key)
    userQueryKeys.forEach((x) => {
      try {
        ctx.User.getOrganizations.setData(x.parse(user), result)
      } catch {}
    })
    if (!listOnly) {
      result.forEach((x) => {
        cacheOrganization(ctx, x, x, true)
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheUserOrganizations(ctx, previous, key, listOnly)
    },
  }
}
