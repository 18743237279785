import { Column } from '~/client/shared/Layout.tsx'
import { SignUp } from '@clerk/clerk-react'
import { useLoginAppearance } from '~/client/marketing/Login.tsx'
import { Title } from '@mantine/core'

export const SignupForm = () => {
  const appearance = useLoginAppearance()
  const searchParams = new URL(window.location.href).searchParams
  const next = searchParams?.get('next') ?? '/dashboard'

  return (
    <SignUp path="/signup" appearance={appearance} fallbackRedirectUrl={next} />
  )
}
export default function Signup() {
  return (
    <Column w="100%" h="60vh" align="center" justify="center">
      <Title order={3}>Welcome to About3</Title>
      <SignupForm />
    </Column>
  )
}
