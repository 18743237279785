import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import { useTeamMemberQuery } from '~/client/dashboard/queries/team-member-queries'
import {
  cacheTeam,
  useTeamQuery,
} from '~/client/dashboard/queries/team-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { useOrganizationModelCtx } from '~/commands/organization/organization-commands-client'

/**
 * Team client commands
 */

export const teamCommandsClient = {
  UpdateProfile: {
    getValidationContext({ input, target }) {
      return {
        sender: {},
        target: {
          newAccess: input.params.access ?? target.access,
        },
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          role: sender.teamProfile?.role,
          isJoined: Boolean(sender.teamProfile),
        },
        target: {
          access: target.access,
        },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { params, key }) =>
        cacheTeam(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          false,
        ),
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.Team>

/**
 * Team command context
 */

export const teamCommandContext = {
  UpdateProfile() {},
} satisfies ClientCommandContextDefinitions<ModelName.Team>

/**
 * Team client context
 */

export const useTeamModelCtx = ((key) => {
  const { ctx, sender, state } = useOrganizationModelCtx({
    id: key.organizationId,
  })

  const { team: target, isLoading, isError } = useTeamQuery(key)
  const {
    teamMember: senderTeamProfile,
    isLoadingTeamMember,
    isLoadingTeamMemberError,
  } = useTeamMemberQuery({
    memberId: sender.organizationProfile?.id,
    teamId: target?.id,
    organizationId: key.organizationId,
  })

  return {
    target: target,
    sender: {
      ...sender,
      teamProfile: senderTeamProfile,
    },
    ctx: {
      ...ctx,
      team: target,
    },
    state: {
      isLoading: state.isLoading || isLoading || isLoadingTeamMember,
      failed: state.failed || isError || isLoadingTeamMemberError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.Document>
