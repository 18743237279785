import { ProcessRole } from '@prisma/client'
import { z } from 'zod'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

export type ProcessAccess = z.infer<typeof ProcessAccess>
export const ProcessAccess = z.object({ edit: z.boolean(), view: z.boolean() })

/**
 * ProcessMemberProfile
 */

export type ProcessMemberProfileSchema = z.infer<
  typeof ProcessMemberProfileSchema
>
export const ProcessMemberProfileSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  processId: z.string().readonly(),
  memberId: z.string().readonly(),
  activeRevisionId: z.string().nullable(),
  isFavorite: z.boolean(),
  role: z.nativeEnum(ProcessRole),
  lastViewedAt: z.date(),
}) satisfies PrismaModelSchema<'ProcessMemberProfile'>

export const ClientProcessMemberProfileSchema = ProcessMemberProfileSchema

export const ProcessMemberProfileQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    processId: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    memberId: z.string(),
    processId: z.string(),
    organizationId: z.string(),
  }),
])

export const processMemberProfileQueryKeys = ProcessMemberProfileQueryKeys.items
export const ProcessMemberProfileQueryKey = z.union(
  ProcessMemberProfileQueryKeys.items,
)
export type ProcessMemberProfileQueryKey = z.infer<
  typeof ProcessMemberProfileQueryKey
>
