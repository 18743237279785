import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import {
  cacheProcess,
  useProcessQuery,
} from '~/client/dashboard/queries/process-queries'
import {
  cacheRevision,
  cacheRevisionContent,
  useRevisionQuery,
} from '~/client/dashboard/queries/revision-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandContextDefinitions,
  ClientCommandDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { useProcessMemberProfileQuery } from '~/client/dashboard/queries/process-member-queries.tsx'
import { useProcessModelCtx } from '~/commands/process/process-commands-client'

/**
 * Revision client commands
 */

export const revisionCommandContext = {
  Modify: () => {},
  SetContent: () => {},
  Publish: () => {},
} satisfies ClientCommandContextDefinitions<ModelName.Revision>

export const revisionCommandsClient = {
  Modify: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ target }) {
      return {
        sender: {},
        target: { isDraft: target.isDraft },
        meta: {},
      }
    },
    onSuccess: [
      (ctx, { revision }) =>
        cacheRevision(ctx, revision, revision, {
          updateOrganizationRevisions: true,
          updateProcessRevisions: true,
        }),
      (ctx, { revision, content }) =>
        cacheRevisionContent(ctx, content, revision, {}),
    ],
  },
  SetContent: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ target, sender }) {
      return {
        sender: {
          isCreator: sender.organizationProfile?.id === target.creatorId,
        },
        target: {
          isDraft: target.isDraft,
        },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key, params }) =>
        cacheRevisionContent(ctx, params.content, key, {}),
    ],
    onSuccess: [
      // TODO: Implement if needed (or remove)
      // (ctx, { difference }) => {
      //   const revertMap = difference.removedNodeIds.map((x) => removeNodeDetailFromCache)
      // },
    ],
  },
  Publish: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ target, sender }) {
      return {
        sender: {
          isCreator: sender.organizationProfile?.id === target.creatorId,
          processAccess: sender.processAccess,
        },
        target: {
          isDraft: target.isDraft,
        },
        meta: {},
      }
    },
    onMutate: [],
    onSuccess: [
      (ctx, { process }, { key }) => cacheProcess(ctx, process, key, true),
      (ctx, { revision }, { key }) =>
        cacheRevision(ctx, revision, key, {
          updateOrganizationRevisions: true,
          updateProcessRevisions: true,
        }),
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.Revision>

/**
 * Revision client context
 */

export const useRevisionModelCtx = ((key) => {
  const { ctx, sender, state } = useProcessModelCtx({
    id: key.processId,
    organizationId: key.organizationId,
  })

  const { revision: target, isLoading, isError } = useRevisionQuery(key)

  return {
    target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
      revision: target,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.Revision>
