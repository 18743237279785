'use client'

import { ActionIcon, Text, useCombobox } from '@mantine/core'
import { RouteMap } from '~/client/RouteMap.ts'
import { Column, Row } from '~/client/shared/Layout'
import { useLogout } from '~/client/shared/hooks/useLogout'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import {
  ColorSchemeToggle,
  DropdownDivider,
  DropdownMenu,
  DropdownOption,
  UserAvatar,
} from '~/client/dashboard/components/global/Prefabs'
import { SettingsItem } from '~/client/dashboard/components/settings/SettingsShell'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { globalIcons } from '~/client/shared/data/global-data'
import { useThemeColor } from '~/client/dashboard/components/global/colors'

export const UserDropdown = ({
  imageUrl,
  username,
  organizationSlug,
}: {
  imageUrl?: string | null
  username?: string | null
  organizationSlug?: string | null
}) => {
  const combobox = useCombobox()
  const navigate = useNavigate()
  const theme = useThemeColor()
  const currentUsername = useUserContext((x) => x.user.username)
  const currentImageUrl = useUserContext((x) => x.user.imageUrl)
  const email = useUserContext((x) => x.email)
  const logout = useLogout()

  imageUrl = imageUrl ?? currentImageUrl ?? ''
  username = username ?? currentUsername ?? ''

  return (
    <DropdownMenu
      store={combobox}
      width={220}
      position="bottom-end"
      arrowPosition="side"
      target={
        <ActionIcon
          onClick={(e) => combobox.toggleDropdown()}
          radius="xl"
          size={36}
          color={theme(['gray', 8], ['dark', 2])}
        >
          <UserAvatar size={36} imageUrl={imageUrl} username={username} />
        </ActionIcon>
      }
    >
      <Row px="sm" pt="sm" mb="md">
        <UserAvatar size={40} imageUrl={imageUrl} username={username} />
        <Column ml="md" style={{ overflow: 'hidden' }}>
          <Text truncate="end" c={theme(['black', 9], ['gray', 0])}>
            {username}
          </Text>
          <Text fz="sm" truncate="end">
            {email}
          </Text>
        </Column>
      </Row>
      <Row p="sm" pt="xs">
        <ColorSchemeToggle />
      </Row>
      <DropdownDivider />
      {organizationSlug && (
        <DropdownOption
          icon={globalIcons.Settings}
          onClick={() => {
            navigate(RouteMap.Settings(SettingsItem.Profile, organizationSlug))
          }}
        >
          Settings
        </DropdownOption>
      )}
      <DropdownOption
        icon={globalIcons.Logout}
        onClick={() => {
          void logout()
        }}
      >
        Log out
      </DropdownOption>
    </DropdownMenu>
  )
}
