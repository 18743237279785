'use client'

import { CSSProperties, ReactNode } from 'react'
import { Box } from '~/client/shared/Layout'
import * as IconMap from './IconMap'

// React Icons
import { BoxProps, rem } from '@mantine/core'
import {
  MdAdd,
  MdAddCircle,
  MdArrowRightAlt,
  MdCached,
  MdCheck,
  MdCheckCircle,
  MdClose,
  MdDeviceHub,
  MdExitToApp,
  MdHorizontalRule,
  MdKeyboardBackspace,
  MdLink,
  MdLinkOff,
  MdNoAccounts,
  MdOpenInNew,
  MdQuestionMark,
} from 'react-icons/md'
import {
  PiArchive,
  PiArrowFatLineUp,
  PiArrowSquareIn,
  PiArrowUpRight,
  PiArrowsHorizontal,
  PiArrowsVertical,
  PiArticle,
  PiCaretDownBold,
  PiCaretLeftBold,
  PiCaretRightBold,
  PiCaretUpBold,
  PiChatCircle,
  PiChatsCircle,
  PiCheckBold,
  PiCheckCircleFill,
  PiCheckFatFill,
  PiCircleDashed,
  PiCircleFill,
  PiDiamondFill,
  PiDotsThreeBold,
  PiEyeSlash,
  PiEyeSlashDuotone,
  PiFileDashed,
  PiFileDuotone,
  PiFiles,
  PiFlaskDuotone,
  PiFolderSimple,
  PiFolderSimpleDuotone,
  PiGear,
  PiGhost,
  PiGlobe,
  PiHouse,
  PiInfo,
  PiLightning,
  PiLinkBreak,
  PiLockKey,
  PiMagnifyingGlass,
  PiMagnifyingGlassDuotone,
  PiMoon,
  PiPaperclip,
  PiPencilSimple,
  PiPencilSimpleLine,
  PiPlaceholderDuotone,
  PiPlusBold,
  PiPlusCircle,
  PiPlusCircleDuotone,
  PiPresentation,
  PiRectangleBold,
  PiRectangleFill,
  PiRecycleDuotone,
  PiSealDuotone,
  PiShieldDuotone,
  PiSignOutBold,
  PiSun,
  PiSwap,
  PiTagChevronDuotone,
  PiTagChevron,
  PiTagDuotone,
  PiTagSimple,
  PiTagSimpleDuotone,
  PiTagSimpleFill,
  PiTrashSimpleDuotone,
  PiUser,
  PiUserBold,
  PiUserCircle,
  PiUserList,
  PiUserPlus,
  PiUsersThree,
  PiWarningCircle,
  PiWarningCircleFill,
  PiWarningFill,
  PiProhibit,
  PiX,
  PiIdentificationBadge,
  PiFlowerLotus,
  PiFlowerLotusDuotone,
  PiWarehouseDuotone,
  PiArrowsInSimple,
  PiArrowsOutSimple,
  PiMinus,
} from 'react-icons/pi'
import {
  RxComponentPlaceholder,
  RxShare1,
  RxSize,
  RxThickArrowDown,
} from 'react-icons/rx'
import {
  TbArrowDown,
  TbArrowNarrowDown,
  TbArrowRightCircle,
  TbExclamationMark,
  TbTrash,
} from 'react-icons/tb'
import { VscFile, VscFolder, VscTypeHierarchySub } from 'react-icons/vsc'

const ReactIcons = {
  MdDeviceHub,
  MdExitToApp,
  MdOpenInNew,
  MdArrowRightAlt,
  MdKeyboardBackspace,
  MdCached,
  MdCheckCircle,
  MdLink,
  MdLinkOff,
  MdClose,
  MdAddCircle,
  MdQuestionMark,
  MdAdd,
  MdCheck,
  MdHorizontalRule,
  MdNoAccounts,
  TbTrash,
  TbArrowDown,
  TbArrowNarrowDown,
  TbArrowRightCircle,
  TbExclamationMark,
  RxSize,
  RxShare1,
  RxThickArrowDown,
  RxComponentPlaceholder,
  VscFolder,
  VscFile,
  VscTypeHierarchySub,
  PiHouse,
  PiGear,
  PiDotsThreeBold,
  PiSun,
  PiMoon,
  PiFiles,
  PiFileDashed,
  PiFileDuotone,
  PiFolderSimple,
  PiFolderSimpleDuotone,
  PiTagDuotone,
  PiTagSimple,
  PiTagSimpleDuotone,
  PiTagSimpleFill,
  PiTagChevronDuotone,
  PiTagChevron,
  PiPlaceholderDuotone,
  PiRectangleBold,
  PiRectangleFill,
  PiCircleFill,
  PiDiamondFill,
  PiCheckFatFill,
  PiCheckCircleFill,
  PiWarningFill,
  PiWarningCircle,
  PiWarningCircleFill,
  PiCheckBold,
  PiCaretRightBold,
  PiCaretDownBold,
  PiCaretLeftBold,
  PiCaretUpBold,
  PiGhost,
  PiMagnifyingGlass,
  PiMagnifyingGlassDuotone,
  PiFlaskDuotone,
  PiPlusBold,
  PiPlusCircle,
  PiPlusCircleDuotone,
  PiCircleDashed,
  PiShieldDuotone,
  PiTrashSimpleDuotone,
  PiRecycleDuotone,
  PiPencilSimple,
  PiPencilSimpleLine,
  PiInfo,
  PiUser,
  PiUserCircle,
  PiUserPlus,
  PiUserBold,
  PiSignOutBold,
  PiArrowSquareIn,
  PiArrowUpRight,
  PiArrowsVertical,
  PiArrowsHorizontal,
  PiLinkBreak,
  PiArticle,
  PiPaperclip,
  PiChatCircle,
  PiChatsCircle,
  PiArchive,
  PiArrowFatLineUp,
  PiSwap,
  PiSealDuotone,
  PiLightning,
  PiLockKey,
  PiEyeSlash,
  PiEyeSlashDuotone,
  PiUsersThree,
  PiGlobe,
  PiPresentation,
  PiUserList,
  PiProhibit,
  PiX,
  PiMinus,
  PiIdentificationBadge,
  PiFlowerLotus,
  PiFlowerLotusDuotone,
  PiWarehouseDuotone,
  PiArrowsInSimple,
  PiArrowsOutSimple,
}

type ReactIcon = keyof typeof ReactIcons

export type IconName = keyof typeof IconMap | ReactIcon

const customIcons = Object.keys(IconMap)

const nudge = (props: Partial<IconProps>): CSSProperties | null => {
  if (
    !(props.nudgeUp || props.nudgeDown || props.nudgeRight || props.nudgeLeft)
  )
    return null

  return {
    position: 'relative',
    top: props.nudgeDown,
    left: props.nudgeRight,
    right: props.nudgeLeft,
    bottom: props.nudgeUp,
  }
}

type Props = {
  // Shorthand for width/height
  size?: number | string

  marginLeft?: number
  marginTop?: number

  nudgeUp?: number | string
  nudgeDown?: number | string
  nudgeLeft?: number | string
  nudgeRight?: number | string

  style?: CSSProperties
} & BoxProps & { children?: ReactNode }

const SVGWrapper = ({
  children,
  size,
  marginLeft,
  marginTop,
  style = {},
  ...props
}: Props) => {
  return (
    <Box
      style={{
        ...nudge(props),
        display: 'flex',
        justifyContent: 'center',
        flexBasis: rem(size) || 'auto',
        flexShrink: 0,
        width: rem(size) || '1em',
        height: rem(size) ? 'fit-content' : '1em',
        fill: 'currentColor',
        color: 'inherit',
        marginLeft,
        marginTop,
        ...style,
      }}
    >
      {children}
    </Box>
  )
}

export type IconProps = Props & { name: IconName }
const Icon = ({ name, ...props }: IconProps) => {
  if (!name) return null
  if (customIcons.includes(name as string)) {
    // @ts-ignore
    return <SVGWrapper {...props}>{IconMap[name]}</SVGWrapper>
  } else {
    return <ReactIcon {...{ name: name as ReactIcon, ...props }} />
  }
}

type ReactIconProps = Props & {
  name: ReactIcon
}
const ReactIcon = ({ name, ...props }: ReactIconProps) => {
  const Component = ReactIcons[name]
  return (
    <SVGWrapper {...props}>
      <Component
        style={{
          height: rem(props.size),
          width: rem(props.size),
        }}
      />
    </SVGWrapper>
  )
}

type SVGProps = Props & { svg: ReactNode }
const SVG = ({ svg, ...props }: SVGProps) => {
  return <SVGWrapper {...props}>{svg}</SVGWrapper>
}

export { Icon, ReactIcon, SVG }
