import { MantineStyleProp, Text, TextInput } from '@mantine/core'
import { useShallowEffect } from '@mantine/hooks'
import {
  BaseEdge,
  EdgeProps,
  Handle,
  Position,
  ViewportPortal,
  getBezierPath,
  useReactFlow,
  useUpdateNodeInternals,
} from '@xyflow/react'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import {
  NodeConnectorButtonEditing,
  NodeConnectorButtonViewing,
} from '~/client/dashboard/components/process/diagram/DiagramNodeButtons'
import { DiagramNodeContext } from '~/client/dashboard/components/process/diagram/DiagramNodeWrapper'
import {
  DiagramBasisConnector,
  DiagramConnector,
  DiagramNodeProps,
  Position2D,
  getHandleSide,
  getLabelPosition,
  isAddNode,
  movingNodePosition,
  nodeSettings,
  useNodeParentPosition,
  DiagramDirection,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import { useDiagramContext } from '~/client/dashboard/stores/DiagramStore'
import { DiagramViewMode } from "~/client/dashboard/stores/DiagramStore"
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import { useProcessContext } from '~/client/dashboard/stores/ProcessStore'
import {
  useProcessAction,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore'
import { Box, Row } from '~/client/shared/Layout'
import { useRequestAnimationFrame } from '~/client/shared/hooks/useAnimationFrame'
import { TEXT_MAX_LENGTH } from '~/schemas/connector-schema'
import { NodeType } from '~/schemas/node-schema'
import {useDarkMode} from '~/client/dashboard/stores/GlobalStore.tsx'
import { useThemeColor } from '~/client/dashboard/components/global/colors'

export type ConnectorButtonProps = {
  // connector: DiagramConnector
  side: Position
  position: Position2D
  isNodeActive: boolean
}

export const DefaultNodePort = ({
  size = 6,
  top,
  left,
}: {
  size?: number
  top: string | number
  left: string | number
}) => {
  const isDarkMode = useDarkMode()

  return (
    <Box
      style={{
        top,
        left,
        position: 'absolute',
        marginLeft: -Math.ceil(size / 2),
        marginTop: -size / 2,
        height: size,
        width: size,
        background: isDarkMode ? Color.gray(100) : Color.gray(700),
        borderRadius: '50%',
      }}
    />
  )
}

// this is a little helper component to render the actual edge label
export const EdgeLabel = ({
  label,
  targetId,
  onUpdate,
  onFocus,
  width,
  height,
  placeholder = 'Enter a label...',
  style = {},
}: {
  label: string
  targetId: string | null
  onUpdate: (val: string) => void
  onFocus?: () => void
  width?: number | string
  height?: number | string
  placeholder?: string
  style?: MantineStyleProp
}) => {
  const isDarkMode = useDarkMode()
  const theme = useThemeColor()
  const scrollToNode = useDiagramContext((x) => x.scrollToNode)
  const setCurrentNode = useDiagramContext((x) => x.setCurrentNode)
  const viewMode = useDiagramContext((x) => x.viewMode)
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(label)
  const ref = useRef<HTMLInputElement>(null)
  const fontSize = 11

  return (
    <Row
      className="nopan"
      style={{
        // display: 'block',
        position: 'relative',
        fontSize: 10,
        fontWeight: 500,
        pointerEvents: 'all',
        borderRadius: 5,
        minWidth: 32,
        minHeight: 24,
        height,
        width: width ?? (isEditing || value.length === 0 ? 120 : 'auto'),
        cursor: viewMode === DiagramViewMode.Editing ? 'text' : 'auto',
        background: isDarkMode ? Color.gray(800) : Color.gray(100),
        border: `1px solid ${theme(['gray', 4], ['gray', 7], true)}`,
        color: isDarkMode ? 'white' : Color.dark(800),
        overflow: 'visible',
        ...style,
        ...(viewMode === DiagramViewMode.Viewing && {
          cursor: 'pointer',
        }),
      }}
      onClick={() => {
        if (viewMode === DiagramViewMode.Viewing) {
          if (targetId && !isAddNode(targetId)) {
            setCurrentNode(targetId)
            scrollToNode(targetId, 400)
          }
        }
      }}
    >
      {!isEditing && (
        <Row h="100%" w="100%">
          <Text
            px={8}
            py={2}
            variant="filled"
            style={{
              fontSize,
              lineHeight: 1.2,
            }}
          >
            {value}
          </Text>
        </Row>
      )}
      {viewMode === DiagramViewMode.Editing && (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            ref.current?.blur()
          }}
          style={{
            position: isEditing ? 'relative' : 'absolute',
            inset: 0,
            height: '100%',
            width: '100%',
            opacity: isEditing || value.length === 0 ? 1 : 0,
          }}
        >
          <TextInput
            ref={ref}
            defaultValue={value}
            variant="unstyled"
            type="text"
            fz="xs"
            placeholder={placeholder}
            onFocus={(e) => {
              setIsEditing(true)
              e.target.select()
              onFocus?.()
            }}
            onBlur={(e) => {
              const newValue = ref.current?.value ?? ''
              if (newValue !== label) {
                onUpdate(newValue)
                setValue(newValue)
              }
              setIsEditing(false)
            }}
            style={{ height: '100%', width: '100%' }}
            styles={{
              root: {
                padding: 0,
                height: '100%',
                width: '100%',
              },
              wrapper: {
                padding: 0,
                height: '100%',
                width: '100%',
              },
              input: {
                fontSize,
                lineHeight: 1.2,
                height: '100%',
                width: '100%',
                padding: '2px 8px',
                minHeight: 0,
                fontWeight: 500,
              },
            }}
            maxLength={TEXT_MAX_LENGTH}
          />
        </form>
      )}
    </Row>
  )
}

// May be ignored by some nodes e.g. Switch
export const DefaultNodePoints = (
  props: DiagramNodeProps & { isAdd?: boolean },
) => {
  const { globalId, connectorsIn, connectorsOut } = props.data
  const viewMode = useDiagramContext((x) => x.viewMode)
  const currentNodeId = useDiagramContext((x) => x.currentNodeId)
  const reactFlow = useReactFlow()
  const isActive = currentNodeId === globalId
  const updateNodeInternals = useUpdateNodeInternals()
  const parentPosition = useNodeParentPosition(props.data.parentGlobalId)
  const direction = useDiagramContext((x) => x.direction)
  const debug = useDiagramContext((x) => x.debug)

  const internalConnectorsOut = connectorsOut.map((x) => {
    return reactFlow.getEdge(x.globalId) as DiagramConnector
  })
  const internalConnectorsIn = connectorsIn.map((x) => {
    return reactFlow.getEdge(x.globalId) as DiagramConnector
  })

  useShallowEffect(() => {
    updateNodeInternals(props.id)
  }, [
    internalConnectorsOut.map((x) => x?.sourceHandle),
    internalConnectorsIn.map((x) => x?.targetHandle),
  ])

  const settings = nodeSettings[props.data.type](
    direction,
    connectorsOut.length,
  )

  return (
    <Box onClick={(e) => e.stopPropagation()} style={{ zIndex: 3 }}>
      {/* Outgoing handles */}
      {internalConnectorsOut.map((connector, i) => {
        if (!connector) return

        const position = settings.getOutgoingPortSettings(i)

        const side = getHandleSide(props, position)

        return (
          <>
            {/* TODO: Create a CustomHandle component and move these inside */}
            {viewMode === DiagramViewMode.Editing ? (
              <NodeConnectorButtonEditing
                key={connector.id + ':button'}
                connectorPosition={connector.data.position}
                originId={connector.data.origin.id}
                targetId={connector.data.target.id}
                position={position}
                side={side}
                isNodeActive={isActive}
              />
            ) : (
              <NodeConnectorButtonViewing
                key={connector.id + ':button'}
                position={position}
                side={side}
                isNodeActive={isActive}
              />
            )}

            <Handle
              key={connector.sourceHandle}
              id={connector.sourceHandle!}
              className="node-handle"
              type="source"
              // Indicates arrow pointer direction
              // TODO: Get based on node type and connector position
              position={side}
              style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
                opacity: 0,
                pointerEvents: 'none',
                transform: `translate3d(-50%, -50%, 0)`,
              }}
            />

            {debug && (
              <div
                style={{
                  position: 'absolute',
                  top: position.y + 8,
                  left: position.x + 100,
                  pointerEvents: 'none',
                  transform: `translate3d(-50%, -50%, 0)`,
                  width: 200,
                  fontSize: 10,
                }}
              >
                {connector.sourceHandle}
              </div>
            )}
          </>
        )
      })}
      {/* Incoming handles */}
      {internalConnectorsIn.map((connector) => {
        if (!connector) return

        const position = {
          x:
            connector.data.endPoint.x +
            (parentPosition.x ?? 0) -
            props.positionAbsoluteX,
          y:
            connector.data.endPoint.y +
            (parentPosition.y ?? 0) -
            props.positionAbsoluteY,
        }

        const side = getHandleSide(props, position)

        if (props.isAdd) {
          if (side === Position.Top) position.y += 16
          if (side === Position.Left) position.x += 40
        }

        if (props.data.isExpanded && side === Position.Top) {
          position.y -= 40
        }

        return (
          <>
            <Handle
              key={connector.targetHandle}
              id={connector.targetHandle!}
              className="node-handle"
              type="target"
              // Indicates arrow pointer direction
              // TODO: Get based on position relative to node center
              position={side}
              style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
                opacity: 0,
                pointerEvents: 'none',
                transform: `translate3d(-50%, -50%, 0)`,
              }}
            />
            {debug && (
              <div
                style={{
                  position: 'absolute',
                  top: position.y - 8,
                  left: position.x,
                  pointerEvents: 'none',
                  transform: `translate3d(-100%, 0, 0)`,
                  fontSize: 10,
                }}
              >
                {connector.targetHandle}
              </div>
            )}
          </>
        )
      })}
    </Box>
  )
}

const getAngleBetweenPoints = (p1: Position2D, p2: Position2D) => {
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI
}

const getTargetPosition = (angle: number, sourceHandlePosition?: Position) => {
  if (!sourceHandlePosition) {
    if (Math.abs(angle) > 135) {
      return Position.Left
    } else if (Math.abs(angle) < 45) {
      return Position.Right
    } else if (angle > 45 && angle < 135) {
      return Position.Bottom
    } else {
      return Position.Top
    }
  } else {
    // TODO:
    // if (sourceHandlePosition === Position.Bottom) {
    // }
  }
}

const moveDistance = (angle: number, distance: number) => {
  const rad = (angle * Math.PI) / 180
  return {
    x: Math.cos(rad) * distance,
    y: Math.sin(rad) * distance,
  }
}

export const CustomEdge = (
  props: Omit<EdgeProps, 'data'> & { data: DiagramBasisConnector },
) => {
  return (
    <DiagramNodeContext globalId={props.data.origin.globalId}>
      <CustomEdgeContent {...props} />
    </DiagramNodeContext>
  )
}

export const CustomEdgeContent = (
  props: Omit<EdgeProps, 'data'> & { data: DiagramBasisConnector },
) => {
  const [position, setPosition] = useState({
    x: props.targetX,
    y: props.targetY,
  })
  const theme = useThemeColor()
  const queryKey = useRevisionContext((x) => x.queryKey)
  const nodeType = useNodeContext((x) => x.type)
  const hasRendered = useRef(false)
  const [opacity, setOpacity] = useState(1)
  const { setConnectorText } = useProcessAction(queryKey, 'setConnectorText')

  let {
    id,
    target,
    source,
    sourceX,
    sourceY,
    targetX: initialTargetX,
    targetY: initialTargetY,
    sourcePosition,
    targetPosition,
    style,
    markerStart,
    markerEnd,
    data,
  } = props

  const angle = getAngleBetweenPoints(position, {
    x: sourceX,
    y: sourceY,
  })

  const movingNode = useDiagramContext((x) => x.movingNode)
  const isMoving = Boolean(movingNode) && movingNode === target

  // TODO: REMOVE
  let targetX = position.x
  let targetY = position.y
  if (isMoving) {
    const distance = 40 // TODO: Figure out dynamic distance value
    // const offset = moveDistance(angle, distance)
    // targetX += offset.x
    // targetY += offset.y
    targetPosition = getTargetPosition(angle)!
    if (targetPosition === Position.Left) {
      targetX -= distance
    } else if (targetPosition === Position.Right) {
      targetX += distance
    } else if (targetPosition === Position.Top) {
      targetY -= distance
    } else {
      targetY += distance
    }
  }

  useEffect(() => {
    if (isMoving) return
    setPosition({
      x: initialTargetX,
      y: initialTargetY,
    })
  }, [isMoving, initialTargetX, initialTargetY])

  useRequestAnimationFrame(() => {
    const { x, y, height, width } = movingNodePosition.position ?? {}
    setPosition({
      x: x + width / 2,
      y: y + height / 2,
    })
  }, isMoving)

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetPosition,
    targetX,
    targetY,
  })

  // useEffect(() => {
  //   if (hasRendered.current) return
  //   setOpacity(0)
  //   setTimeout(() => {
  //     setOpacity(1)
  //   })
  //   hasRendered.current = true
  // }, [edgePath])

  const { left, top, translateX, translateY } =
    getLabelPosition(sourcePosition)!

  style = {
    ...style,
    pointerEvents: 'none',
    stroke: theme(['gray', 6], ['gray', 2], true),
    opacity,
    transition: opacity === 0 ? 'none' : '2s ease opacity',
  }

  return (
    <>
      {!isMoving && (
        <BaseEdge
          key={props.data.id + '-base-edge'}
          path={edgePath}
          markerEnd={markerEnd}
          style={style}
        />
      )}
      <ViewportPortal>
        {isMoving && (
          <div className="react-flow__edges">
            <svg style={{ position: 'relative', zIndex: 2 }}>
              <g className="react-flow__edge animated">
                <BaseEdge
                  key={props.data.id + '-base-edge'}
                  path={edgePath}
                  markerEnd={markerEnd}
                  style={style}
                />
              </g>
            </svg>
          </div>
        )}
        <motion.div
          key={props.data.id + '-motion'}
          className="custom-edge"
          initial={false}
          transition={{ ease: 'anticipate', duration: 0.4 }}
          animate={{
            left,
            top,
          }}
          style={{
            zIndex: 1,
            opacity,
            position: 'absolute',
            transform: `translate3d(${translateX}, ${translateY}, 0)  translate(${sourceX}px,${sourceY}px)`,
            transition: opacity === 0 ? 'none' : '2s ease opacity',
          }}
        >
          {nodeType === NodeType.Decision && (
            <EdgeLabel
              label={data.text}
              targetId={target}
              onUpdate={(text) => {
                setConnectorText({
                  originId: data.origin.id,
                  position: data.position,
                  text,
                })
              }}
            />
          )}
        </motion.div>
      </ViewportPortal>
    </>
  )
}

// const PathfindingEdge = (props: EdgeProps) => {
//   const {
//     id,
//     sourceX,
//     sourceY,
//     targetX,
//     targetY,
//     sourcePosition,
//     targetPosition,
//     style,
//     markerStart,
//     markerEnd,
//     data,
//   } = props

//   const nodes = useNodes()

//   const getSmartEdgeResponse = getSmartEdge({
//     options: {
//       generatePath: pathfindingJumpPointNoDiagonal,
//       drawEdge: svgDrawStraightLinePath,
//       nodePadding: 40,
//     },
//     sourceX,
//     sourceY,
//     targetX,
//     targetY,
//     sourcePosition,
//     targetPosition,
//     nodes,
//   })

//   // If the value returned is null, it means "getSmartEdge" was unable to find
//   // a valid path, and you should do something else instead
//   if (getSmartEdgeResponse === null) {
//     return <StepEdge {...props} />
//   }

//   const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse

//   return (
//     <>
//       <path
//         style={style}
//         className="react-flow__edge-path"
//         d={svgPathString}
//         markerEnd={markerEnd}
//         markerStart={markerStart}
//       />
//       <Portal target=".react-flow__viewport">
//         <div
//           style={{
//             position: 'absolute',
//             left: '50%',
//             top: '50%',
//             transform: 'translate3d(-50%, -50%, 0)',
//           }}
//         >
//           <EdgeLabel label={data.label} onClick={() => {}} />
//         </div>
//       </Portal>
//     </>
//   )
// }
