import { MemberStatus, OrganizationRole } from '@prisma/client'
import {
  cacheOrganizationMember,
  getOrganizationMemberFromCache,
  useOrganizationMemberQuery,
} from '~/client/dashboard/queries/organization-member-queries'
import {
  cacheOrganization,
  useOrganizationQuery,
} from '~/client/dashboard/queries/organization-queries'
import { getUserFromCache } from '~/client/dashboard/queries/user-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
  useCommandMutation,
} from '~/commands/client-commands'
import { useOrganizationModelCtx } from '~/commands/organization/organization-commands-client'

/**
 * Member client commands
 */

export const organizationMemberCommandContext = {
  SetAsOwner() {},
  UpdateProfile() {},
  SetStatus() {},
  SetRole() {},
  Leave() {},
} satisfies ClientCommandContextDefinitions<ModelName.OrganizationMember>

export const organizationMemberCommandsClient = {
  SetAsOwner: {
    getValidationContext({}) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ ctx, sender, target }) {
      return {
        sender: {
          isOwner:
            ctx.organization.ownerId === sender.organizationProfile?.userId,
        },
        target: {
          hasUser: Boolean(target.userId),
          status: target.status,
          isOwner: target.userId === ctx.organization.ownerId,
        },
        meta: {
          organizationType: ctx.organization.type,
        },
      }
    },
    onMutate: [
      (ctx, { key }) => {
        const member = getOrganizationMemberFromCache(ctx, key)!
        return cacheOrganization(
          ctx,
          (old) => (old ? { ...old, ownerId: member.userId! } : undefined),
          { id: key.organizationId },
          false,
        )
      },
      (ctx, { key }) => {
        return cacheOrganizationMember(
          ctx,
          (old) =>
            old
              ? {
                  ...old,
                  role: OrganizationRole.Admin,
                  isDisplayLocked: false,
                }
              : undefined,
          key,
          { updateOrganizationMembers: true, updateUser: true },
        )
      },
    ],
  },
  UpdateProfile: {
    getValidationContext({ input, sender, target }) {
      return {
        sender: {
          role: sender.organizationProfile?.role ?? null,
        },
        target: {
          role: target.role,
          isSender: sender.organizationProfile?.id === target.id,
          isDisplayLockedChanged:
            input.params.isDisplayLocked !== undefined &&
            target.isDisplayLocked !== input.params.isDisplayLocked,
        },
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          role: sender.organizationProfile?.role ?? null,
        },
        target: {
          isSender: sender.organizationProfile?.id === target.id,
          isDisplayLocked: target.isDisplayLocked,
          status: target.status,
        },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key, params }) => {
        return cacheOrganizationMember(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          { updateOrganizationMembers: true, updateUser: true },
        )
      },
    ],
  },
  SetStatus: {
    getValidationContext({}) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ ctx, sender, target }) {
      return {
        sender: {
          role: sender.organizationProfile?.role ?? null,
        },
        target: {
          status: target.status,
          isSender: target.id === sender.organizationProfile?.id,
          isOwner: target.userId === ctx.organization.ownerId,
        },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key, params }) => {
        return cacheOrganizationMember(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          { updateOrganizationMembers: true, updateUser: true },
        )
      },
    ],
  },
  SetRole: {
    getValidationContext({}) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ ctx, sender, target }) {
      return {
        sender: {
          role: sender.organizationProfile?.role ?? null,
        },
        target: {
          isSender: target.id === sender.organizationProfile?.id,
          isOwner: target.userId === ctx.organization.ownerId,
        },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key, params }) => {
        return cacheOrganizationMember(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          { updateOrganizationMembers: true, updateUser: true },
        )
      },
    ],
  },
  Leave: {
    getValidationContext({}) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ ctx, sender, target }) {
      return {
        sender: {},
        target: {
          isSender: target.id === sender.organizationProfile?.id,
          isOwner: target.userId === ctx.organization.ownerId,
        },
        meta: {
          organizationType: ctx.organization.type,
        },
      }
    },
    onMutate: [
      (ctx, { key }) => {
        return cacheOrganizationMember(
          ctx,
          (old) => (old ? { ...old, status: MemberStatus.Exited } : undefined),
          key,
          { updateOrganizationMembers: true, updateUser: true },
        )
      },
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.OrganizationMember>

/**
 * Member client context
 */

export const useOrganizationMemberModelCtx = ((key) => {
  const { ctx, sender, state } = useOrganizationModelCtx({
    id: key.organizationId,
  })

  const {
    organizationMember: target,
    isLoading,
    isError,
  } = useOrganizationMemberQuery(key)

  return {
    target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
      organizationMember: target,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.OrganizationMember>
