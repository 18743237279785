import { Box, Button, Text, Title } from '@mantine/core'
import { modals } from '@mantine/modals'
import { Column } from '~/client/shared/Layout'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'

export const Account = () => {
  const user = useUserContext((x) => x.user)
  const { deleteUser } = useCommand(ModelName.User, 'Delete', {
    id: user.id,
  })

  // TODO: Check whether user is owner of any organizations with more than one user. If so, inform them they must set another owner before deleting.

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: (
        <Title component="div" order={3}>
          Confirm Deletion
        </Title>
      ),
      children: (
        <Column pb="lg">
          <Text>
            All of the data associated with your account will be permanently
            removed. This action cannot be undone.
          </Text>
        </Column>
      ),
      labels: { confirm: 'Delete Account', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onCancel: () => {},
      onConfirm: () =>
        deleteUser({
          id: user.id,
        }),
    })

  return (
    <Column h="100%" w="100%" maw={400}>
      <Box>
        <Button size="sm" color="red" onClick={openDeleteModal}>
          Delete account
        </Button>
      </Box>
    </Column>
  )
}
