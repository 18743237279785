import {
  ActionIcon,
  Drawer,
  DrawerProps,
  Text,
  Title,
  useCombobox,
} from '@mantine/core'
import { DocumentMemberProfile, DocumentRole } from '@prisma/client'
import { Column, Row } from '~/client/shared/Layout'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  DocumentParticipantBadge,
  DropdownMenu,
  DropdownOption,
  MemberAvatar,
} from '~/client/dashboard/components/global/Prefabs'
import { useDocumentMembersQuery } from '~/client/dashboard/queries/document-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useDocumentContext } from '~/client/dashboard/stores/DocumentStore'
import { ModelName } from '~/commands/base-commands'
import { globalIcons } from '~/client/shared/data/global-data'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import classes from './Document.module.css'
import { documentIcons } from '~/client/shared/data/document-data'
import { useThemeColor } from '~/client/dashboard/components/global/colors'

export const DocumentParticipantsTitle = () => {
  const organizationId = useDocumentContext((x) => x.document.organizationId)
  const documentId = useDocumentContext((x) => x.document.id)
  const { documentMembers } = useDocumentMembersQuery({
    id: documentId,
    organizationId,
  })

  return (
    <Title component="div" order={4} mb="md">
      {documentMembers.length} member{documentMembers.length === 1 ? '' : 's'}
    </Title>
  )
}

export const DocumentParticipantSidebar = () => {
  return (
    <Column pl="lg" w={240} shrink={0}>
      <DocumentParticipantsTitle />
      <DocumentParticipantList />
    </Column>
  )
}

export const DocumentParticipantList = () => {
  const organizationId = useDocumentContext((x) => x.document.organizationId)
  const documentId = useDocumentContext((x) => x.document.id)
  const { documentMembers } = useDocumentMembersQuery({
    id: documentId,
    organizationId,
  })

  return (
    <Column className={classes.documentMemberList} gap="md">
      {documentMembers.map((x) => (
        <DocumentParticipantItem key={x.id} participant={x} />
      ))}
    </Column>
  )
}

export const DocumentParticipantDrawer = ({ ...props }: {} & DrawerProps) => {
  return (
    <Drawer
      title={<DocumentParticipantsTitle />}
      position="bottom"
      transitionProps={{
        transition: 'slide-up',
        duration: 150,
        timingFunction: 'linear',
      }}
      {...props}
    >
      <DocumentParticipantList />
    </Drawer>
  )
}

export const DocumentParticipantItem = ({
  participant,
}: {
  participant: DocumentMemberProfile
}) => {
  const theme = useThemeColor()
  const combobox = useCombobox()
  const { organizationMember } = useOrganizationMemberQuery({
    id: participant.memberId,
    organizationId: participant.organizationId,
  })
  const profileId = useOrganizationContext((x) => x.currentMember.id)

  if (!organizationMember) return null

  const isSelf = profileId === organizationMember.id

  return (
    <Row
      className={classes.documentMemberItem}
      justify="space-between"
      gap="sm"
    >
      <Row gap="md" style={{ overflow: 'hidden' }}>
        <MemberAvatar id={participant.memberId} glance={true} size={30} />
        <Text truncate="end">{organizationMember.alias}</Text>
        <DocumentParticipantBadge
          role={participant.role}
          style={{ flexShrink: 0 }}
        />
      </Row>
      <Row>
        <DropdownMenu
          store={combobox}
          position="bottom-end"
          target={
            <ActionIcon
              className={classes.menuDots}
              variant="subtle"
              pos="relative"
              size={24}
              onClick={() => combobox.toggleDropdown()}
              color="gray"
              radius="50%"
              style={{
                color: theme(['gray', 6], ['dark', 0], true),
              }}
            >
              <Icon name={globalIcons.MenuDots} size={16} />
            </ActionIcon>
          }
        >
          <DropdownOption
            icon={documentIcons.Owner}
            onClick={({ setDocumentMemberRole }) => setDocumentMemberRole({})}
            withCommand={{
              model: ModelName.DocumentMember,
              command: 'SetRole',
              notAllowed: 'hide',
              queryKey: participant,
              validate: {
                role: DocumentRole.Owner,
              },
            }}
          >
            Set as owner
          </DropdownOption>
          <DropdownOption
            icon={documentIcons.Member}
            onClick={({ setDocumentMemberRole }) => setDocumentMemberRole({})}
            withCommand={{
              model: ModelName.DocumentMember,
              command: 'SetRole',
              notAllowed: 'hide',
              queryKey: participant,
              validate: {
                memberId: organizationMember.id,
                role: DocumentRole.Default,
              },
            }}
          >
            Revoke ownership
          </DropdownOption>
          <DropdownOption
            icon={globalIcons.Exit}
            onClick={({ removeDocumentParticipant }) =>
              removeDocumentParticipant({})
            }
            type={isSelf ? 'default' : 'danger'}
            withCommand={{
              model: ModelName.Document,
              command: 'RemoveParticipant',
              notAllowed: 'disable',
              queryKey: {
                id: participant.documentId,
                organizationId: participant.organizationId,
              },
              validate: { memberId: organizationMember.id },
            }}
          >
            {isSelf ? 'Leave' : 'Remove'}
          </DropdownOption>
        </DropdownMenu>
      </Row>
    </Row>
  )
}
