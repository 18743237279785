import { z } from 'zod'
import type { ModelCommandsSchema } from '~/commands/base-commands'
import {
  ClientDocumentMemberSchema,
  DocumentMemberSchema,
} from '~/schemas/document-member-schema'
import { ClientDocumentSchema, DocumentSchema } from '~/schemas/document-schema'
import { OrganizationMemberSchema } from '~/schemas/organization-member-schema'
import { OrganizationSchema } from '~/schemas/organization-schema'

/**
 * Document commands schema
 */

export const documentCommandsSchema = {
  CreateDocument: {
    summary: `Create a category as a child of another category`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        title: DocumentSchema.shape.title.optional(),
        description: DocumentSchema.shape.description.optional(),
        privacy: DocumentSchema.shape.privacy.optional(),
      }),
      output: z.object({
        document: ClientDocumentSchema,
        documentMemberProfile: ClientDocumentMemberSchema,
      }),
      validate: {
        sender: {},
        target: {
          privacy: DocumentSchema.shape.privacy,
        },
        meta: {
          newCategoryPrivacy: DocumentSchema.shape.privacy,
        },
      },
      authorize: {
        sender: {
          role: DocumentMemberSchema.shape.role.optional(),
          isJoined: z.boolean(),
        },
        target: {
          privacy: DocumentSchema.shape.privacy,
        },
        meta: {
          organizationType: OrganizationSchema.shape.type,
        },
      },
    },
    messages: {
      failure: 'Failed to create category',
    },
    variables: {
      execute: 'createDocument',
      isPending: 'isCreatingDocument',
    },
  },
  Delete: {
    summary: `Delete category`,
    description: ``,
    method: 'POST',
    schemas: {
      input: undefined,
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {
          role: DocumentMemberSchema.shape.role.optional(),
          isJoined: z.boolean(),
        },
        target: {
          isRoot: z.boolean(),
          privacy: DocumentSchema.shape.privacy,
        },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to delete category',
    },
    variables: {
      execute: 'deleteDocument',
      isPending: 'isDeletingDocument',
    },
  },
  UpdateProfile: {
    summary: `Update category's profile settings`,
    description: ``,
    method: 'PATCH',
    schemas: {
      input: z.object({
        title: DocumentSchema.shape.title.optional(),
        parentId: DocumentSchema.shape.parentId.optional(),
        description: DocumentSchema.shape.description.optional(),
        privacy: DocumentSchema.shape.privacy.optional(),
      }),
      output: z.literal(true),
      validate: {
        sender: {
          targetParentRole: DocumentMemberSchema.shape.role.optional(),
          isJoinedNewParent: z.boolean(),
        },
        target: {
          isRoot: z.boolean(),
          newPrivacy: DocumentSchema.shape.privacy,
          previousParentId: DocumentSchema.shape.parentId,
          newParentId: DocumentSchema.shape.parentId,
          newParentPrivacy: DocumentSchema.shape.privacy,
        },
        meta: {},
      },
      authorize: {
        sender: {
          role: DocumentMemberSchema.shape.role.optional(),
          isJoined: z.boolean(),
        },
        target: {
          isRoot: z.boolean(),
          privacy: DocumentSchema.shape.privacy,
        },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update category settings',
    },
    variables: {
      execute: 'updateDocumentProfile',
      isPending: 'isUpdatingDocumentProfile',
    },
  },
  AddParticipant: {
    summary: `Become a participant of category`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        memberId: OrganizationMemberSchema.shape.id,
        role: DocumentMemberSchema.shape.role,
      }),
      output: ClientDocumentMemberSchema,
      validate: {
        sender: {
          isOwner: z.boolean(),
          isJoined: z.boolean(),
        },
        target: {
          isJoined: z.boolean(),
          isSender: z.boolean(),
          role: DocumentMemberSchema.shape.role,
        },
        meta: {
          documentPrivacy: DocumentSchema.shape.privacy,
        },
      },
      authorize: {
        sender: {},
        target: {},
        meta: {},
      },
    },
    messages: {
      // TODO: Different messages based on whether the participant is self
      failure: 'Failed to add member to category',
    },
    variables: {
      execute: 'addDocumentParticipant',
      isPending: 'isAddingDocumentParticipant',
    },
  },
  RemoveParticipant: {
    summary: `Stop participating in category`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        memberId: OrganizationMemberSchema.shape.id,
      }),
      output: z.literal(true),
      validate: {
        sender: {
          isOwner: z.boolean(),
          isJoined: z.boolean(),
        },
        target: {
          isJoined: z.boolean(),
          isSender: z.boolean(),
          isOwner: z.boolean(),
        },
        meta: {
          documentPrivacy: DocumentSchema.shape.privacy,
          documentOwnerCount: z.number(),
        },
      },
      authorize: {
        sender: {},
        target: {},
        meta: {
          isDocumentRoot: z.boolean(),
        },
      },
    },
    messages: {
      failure: 'Failed to remove member from category',
    },
    variables: {
      execute: 'removeDocumentParticipant' as const,
      isPending: 'isRemovingDocumentParticipant',
    },
  },
} as const satisfies ModelCommandsSchema
