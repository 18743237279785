'use client'

import { useShallowEffect } from '@mantine/hooks'
import { useContext, useEffect } from 'react'
import { createStore, useStore } from 'zustand'
import { useRevisionQuery } from '~/client/dashboard/queries/revision-queries'
import { useDiagramContext } from '~/client/dashboard/stores/DiagramStore'
import { useGlobalContext, useGlobalStore } from '~/client/dashboard/stores/GlobalStore'
import { NodeContext } from '~/client/dashboard/stores/NodeContext'
import {
  ProcessTreePathItem,
  useProcessContext,
} from '~/client/dashboard/stores/ProcessStore'
import { useRevisionContext } from '~/client/dashboard/stores/RevisionStore'
import { ModelQueryKey } from '~/schemas'
import { NodeSchema, NodeType } from '~/schemas/node-schema'

/**
 * Store
 */

export type NodeState = NodeSchema & {
  queryKey: ModelQueryKey['NodeDetail']
  globalId: string
  processId: string
  revisionId: string
  organizationId: string
  isExpanded: boolean
  isRoot: boolean
  subprocessId?: string
  subprocessRevisionId?: string
  subprocessQueryKey?: ModelQueryKey['Process']
  subprocessRevisionQueryKey?: ModelQueryKey['Revision']
}

type NodeStoreProps = {
  node: NodeSchema
  globalId: string
  processId: string
  revisionId: string
  organizationId: string
}

export type NodeStore = ReturnType<typeof createNodeStore>

const createNodeStore = ({
  node,
  globalId,
  processId,
  revisionId,
  organizationId,
}: NodeStoreProps) => {
  return createStore<NodeState>()((set) => ({
    ...node,
    processId,
    revisionId,
    organizationId,
    globalId,
    isExpanded: false,
    isRoot: false,
    queryKey: {
      organizationId,
      processId,
      revisionId,
      nodeId: node.id,
    },
  }))
}

/**
 * Context
 */

type NodeProviderProps = React.PropsWithChildren<{
  id: number
  skipLogic?: boolean
}>

export const NodeProvider = ({
  id,
  children,
  skipLogic = false,
}: NodeProviderProps) => {
  const globalId = useNodeGlobalId(id)
  const addNodeStore = useGlobalContext((x) => x.addNodeStore)
  const revisionId = useRevisionContext((x) => x.id)
  const organizationId = useRevisionContext((x) => x.revision.organizationId)
  const processId = useProcessContext((x) => x.id)
  const nodeStore = useGlobalContext((x) => x.nodeStores.get(globalId))

  // Get node information from revision context
  const node = useRevisionContext((x) =>
    x.content.nodes.find((x) => x.id === id),
  )

  useEffect(() => {
    if (!node || nodeStore) return
    const store = createNodeStore({
      node,
      globalId,
      processId,
      revisionId,
      organizationId,
    })
    addNodeStore(globalId, store)
  }, [nodeStore, node, globalId, processId, revisionId, organizationId])

  if (!nodeStore) return null

  return (
    <NodeContext.Provider value={nodeStore}>
      {!skipLogic && <NodeContextLogic />}
      {children}
    </NodeContext.Provider>
  )
}

export const useNodeContext = <T extends any>(
  selector: (state: NodeState) => T,
) => {
  const store = useContext(NodeContext)
  if (!store) throw new Error('Missing NodeContext.Provider in the tree')
  return useStore(store, selector)
}

export const useNodeStore = () => {
  const store = useContext(NodeContext)
  if (!store) throw new Error('Missing NodeContext.Provider in the tree')
  return store
}

const NodeContextLogic = () => {
  const revisionTitle = useRevisionContext((x) => x.revision.title)
  const rootNodeId = useRevisionContext((x) => x.revision.rootNodeId)
  const globalId = useNodeContext((x) => x.globalId)
  const id = useNodeContext((x) => x.id)
  const type = useNodeContext((x) => x.type)
  const store = useNodeStore()
  const organizationId = useNodeContext((x) => x.organizationId)
  const expandedNodeIds = useDiagramContext((x) => x.expandedNodeIds)
  const node = useRevisionContext((x) =>
    x.content.nodes.find((x) => x.id === id),
  )
  const subprocessId = useNodeContext((x) => x.subprocessId)!
  const subprocessRevisionId = useNodeContext((x) => x.subprocessRevisionId)!

  const { revision: subprocessRevision } = useRevisionQuery({
    id: subprocessRevisionId,
    processId: subprocessId,
    organizationId,
  })

  useShallowEffect(() => {
    store.setState({
      isExpanded:
        type === NodeType.Subprocess &&
        expandedNodeIds.some((x) => x === globalId),
    })
  }, [store, type, expandedNodeIds, globalId])

  useShallowEffect(() => {
    if (!node) return

    let title = node.title
    switch (node.type) {
      case NodeType.Start: {
        title = revisionTitle
        break
      }
      case NodeType.Subprocess: {
        title = subprocessRevision?.title ?? ''
        break
      }
    }

    store.setState({
      ...node,
      title,
    })
  }, [store, node, revisionTitle, subprocessRevision?.title])

  useEffect(() => {
    store.setState({
      isRoot: id === rootNodeId,
    })
  }, [store, id, rootNodeId])

  useEffect(() => {
    store.setState({
      subprocessQueryKey: subprocessId
        ? {
            id: subprocessId,
            organizationId,
          }
        : undefined,
      subprocessRevisionQueryKey: subprocessRevisionId
        ? {
            id: subprocessRevisionId,
            processId: subprocessId,
            organizationId,
          }
        : undefined,
    })
  }, [organizationId, subprocessId, subprocessRevisionId])

  return null
}

export const getNodePathString = (treePath: ProcessTreePathItem[]) => {
  return treePath.map((x) => x.nodeId).join('_')
}

export const getNodeGlobalId = (
  id: number | string | null,
  treePath: ProcessTreePathItem[],
) => {
  if (!id) return null
  if (treePath.length === 0) return String(id)
  return getNodePathString(treePath) + '_' + id
}

export const useNodeGlobalId = <
  T extends number | string | null,
  U extends T extends null ? null : string,
>(
  id: T,
): U => {
  const treePath = useProcessContext((x) => x.treePath)
  return getNodeGlobalId(id, treePath) as U
}
