import { Box, Center, Portal, Text } from '@mantine/core'
import { getHotkeyHandler } from '@mantine/hooks'
import { motion } from 'framer-motion'
import { MutableRefObject, PropsWithChildren } from 'react'
import { ColorHelper } from '~/client/dashboard/components/global/colors'
import {
  BASE_ACTIVE_STROKE_OUTSET,
  DiagramNodeProps,
  TextInset,
  useNodeFontSize,
  useNodeParentPosition,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import { Nodes } from '~/client/dashboard/components/process/node/NodeComponents'
import { useDiagramContext } from '~/client/dashboard/stores/DiagramStore'
import { useDarkMode, useGlobalContext } from '~/client/dashboard/stores/GlobalStore'
import {
  NodeProvider,
  useNodeContext,
} from '~/client/dashboard/stores/NodeStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { ProcessProvider } from '~/client/dashboard/stores/ProcessStore'
import { RevisionProvider } from '~/client/dashboard/stores/RevisionStore'
import { useDebugMount } from '~/client/shared/hooks/useDebugMount'
import { NodeType } from '~/schemas/node-schema'
import classes from './Diagram.module.css'

export const DiagramNodeContext = ({
  globalId,
  children,
}: PropsWithChildren<{
  globalId: string | null
}>) => {
  const organizationId = useOrganizationContext((x) => x.id)
  const { nodes } = useDiagramContext((x) => x.basisContent)
  const node = globalId ? nodes.get(globalId) : null

  if (!node) return null

  const processId = node.processId
  const revisionId = node.revisionId
  const treePath = node.treePath

  if (treePath.length === 0) {
    // We already already inside the context of current process and revision
    return (
      <NodeProvider id={node.id} skipLogic={true}>
        {children}
      </NodeProvider>
    )
  }

  // Wrap in subprocess and subprocess revision context
  return (
    <ProcessProvider
      queryKey={{ id: processId, organizationId }}
      skipLogic={true}
      treePath={treePath}
    >
      <RevisionProvider id={revisionId} skipLogic={true}>
        <NodeProvider id={node.id} skipLogic={true}>
          {children}
        </NodeProvider>
      </RevisionProvider>
    </ProcessProvider>
  )
}

export const DiagramNodeWrapper = (
  props: PropsWithChildren<DiagramNodeProps> & {
    withMotion?: boolean
    moveableRef?: MutableRefObject<HTMLDivElement | null>
  },
) => {
  const {
    id,
    data,
    positionAbsoluteX,
    positionAbsoluteY,
    children,
    withMotion = true,
  } = props
  useDebugMount('DiagramNodeWrapper', id)

  const parentPosition = useNodeParentPosition(props.data.parentGlobalId)

  return (
    <Portal target=".react-flow__viewport">
      {!withMotion && (
        <Box
          ref={props.moveableRef}
          pos="absolute"
          style={{
            touchAction: 'none',
            left: positionAbsoluteX,
            top: positionAbsoluteY,
          }}
        >
          {children}
        </Box>
      )}
      {withMotion && (
        <motion.div
          className={classes.diagramNodeWrapper}
          data-id={data.id}
          data-global-id={id}
          data-parent-global-id={data.parentGlobalId}
          data-type={data.type}
          style={{
            width: props.width,
            height: props.height,
            zIndex: 2,
          }}
          initial={{
            left: parentPosition.x,
            top: parentPosition.y,
            opacity: 0,
          }}
          animate={{
            left: positionAbsoluteX,
            top: positionAbsoluteY,
            opacity: 1,
          }}
          tabIndex={1}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
          onFocus={() => {
            // setCurrentNode(id)
          }}
          onKeyDown={getHotkeyHandler([
            // TODO: Hotkeys
          ])}
        >
          {children}
        </motion.div>
      )}
    </Portal>
  )
}

// const NodeHoverCard = ({
//   nodeId,
//   children,
// }: {
//   nodeId: string
//   children: ReactNode
// }) => {
//   const node = useNode(nodeId)
//   const currentNodeId = useProcessContext((x) => x.currentNodeId)

//   if (!node || nodeId === currentNodeId) return children

//   return (
//     <HoverCard
//       width={200}
//       position="bottom"
//       withArrow
//       shadow="md"
//       withinPortal={true}
//     >
//       <HoverCard.Target>
//         <Box>{children}</Box>
//       </HoverCard.Target>
//       {node.title && (
//         <HoverCard.Dropdown>
//           <Column className={nodePaneClasses.title}>{node.title}</Column>
//         </HoverCard.Dropdown>
//       )}
//     </HoverCard>
//   )
// }

export const DiagramNodeSVG = ({
  nodeId,
  type,
  width,
  height,
  color,
  strokeColor,
  strokeWidth,
  textInset,
}: {
  nodeId: string
  type: NodeType
  width: number
  height: number
  color?: ColorHelper
  strokeColor?: ColorHelper
  strokeWidth?: number
  textInset: TextInset
}) => {
  const isDarkMode = useDarkMode()
  const currentNodeId = useDiagramContext((x) => x.currentNodeId)
  const setCurrentNode = useDiagramContext((x) => x.setCurrentNode)
  const getNodeColor = useGlobalContext((x) => x.getNodeColor)
  const getNodeStrokeColor = useGlobalContext((x) => x.getNodeStrokeColor)
  const debug = useDiagramContext((x) => x.debug)
  const nodeType = useNodeContext((x) => x.type)
  const nodeTitle = useNodeContext((x) => x.title)
  const fontSize = useNodeFontSize()
  color = color ?? getNodeColor(nodeType)
  strokeColor = strokeColor ?? getNodeStrokeColor(color)
  const isActive = currentNodeId === nodeId

  // TODO: Scale with viewport
  // const strokeOutset = BASE_ACTIVE_STROKE_OUTSET / viewport.zoom
  const strokeOutset = BASE_ACTIVE_STROKE_OUTSET

  // TODO: Convert Nodes[] to function component <NodeSVG type={} />
  const SVG = Nodes[type]

  return (
    <Center
      className="nopan"
      pos="relative"
      w="100%"
      h="100%"
      style={{
        pointerEvents: 'none',
        stroke: strokeColor.toString(),
      }}
    >
      {debug && (
        <div
          style={{
            position: 'absolute',
            top: 4,
            left: 4,
          }}
        >
          {nodeId}
        </div>
      )}
      <Center
        style={{
          position: 'absolute',
          inset: `${textInset.top} ${textInset.right} ${textInset.bottom} ${textInset.left}`,
          textAlign: 'center',
          display: 'flex',
        }}
      >
        <Text lh="1.2" fz={fontSize} c={isDarkMode ? 'white.0' : 'dark.8'}>
          {nodeTitle ?? ''}
        </Text>
      </Center>
      <SVG
        width={width}
        height={height}
        strokeWidth={strokeWidth}
        pathProps={{
          onClick: () => {
            setCurrentNode(nodeId)
          },
          style: {
            pointerEvents: 'all',
            cursor: 'pointer',
            fill: isActive
              ? color.lighten(isDarkMode ? 0.05 : 0.02).toString()
              : color.toString(),
          },
        }}
        strokeOutset={strokeOutset}
        strokeWidthOuter={6}
        outerStroke={
          isActive ? (isDarkMode ? Color.blue(100) : Color.blue(400)) : 'none'
        }
        middleStroke={
          isActive ? (isDarkMode ? Color.gray(900) : Color.gray(0)) : 'none'
        }
      />
    </Center>
  )
}
