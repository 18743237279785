import { ModelName, ModelCommandsValidation, authorized, valid } from '~/commands/base-commands'

/**
 * Invitation commands validation
 */

export const invitationValidation = {
  Example: {
    validate(ctx) {
      return valid()
    },
    authorize(ctx) {
      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.Invitation>
