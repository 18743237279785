import { z } from 'zod'
import { ModelCommandsSchema } from '~/commands/base-commands'
import { Model } from '~/schemas'
import { NodeSchema, NodeSchemaBase } from '~/schemas/node-schema'
import { ProcessAccess } from '~/schemas/process-member-schema'
import { ClientProcessSchema } from '~/schemas/process-schema'
import {
  ClientRevisionSchema,
  ProcessContentInputSchema,
  RevisionPublicationSchema,
  RevisionSchema,
} from '~/schemas/revision-schema'

/**
 * Revision commands schema
 */

export const revisionCommandsSchema = {
  Modify: {
    summary: `Edit as a new revision`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        // TODO: Allow setting updated content on checkout
        //  (Determine how this affects the checkout process)
        // content: ProcessContentInputSchema.optional(),
      }),
      output: z.object({
        revision: ClientRevisionSchema,
        content: RevisionSchema.shape.content,
      }),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {},
        target: { isDraft: z.boolean() },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to create new revision',
      success: 'New draft created',
    },
    variables: {
      execute: 'modify',
      isPending: 'isModifying',
    },
  },
  SetContent: {
    summary: `Update content for revision`,
    description: ``,
    method: 'PATCH',
    schemas: {
      input: z.object({
        content: ProcessContentInputSchema.optional(),
      }),
      output: z.object({
        difference: z.object({
          removedNodeIds: z.array(NodeSchemaBase.shape.id),
        }),
      }),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: { isCreator: z.boolean() },
        target: { isDraft: z.boolean() },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update revision content',
    },
    variables: {
      execute: 'setContent',
      isPending: 'isSettingContent',
    },
  },
  Publish: {
    summary: `Publish a revision`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        message: RevisionPublicationSchema.shape.message.optional(),
      }),
      output: z.object({
        revision: ClientRevisionSchema,
        process: ClientProcessSchema,
      }),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: { isCreator: z.boolean(), processAccess: ProcessAccess },
        target: { isDraft: z.boolean() },
        meta: {},
      },
    },
    messages: {
      processing: 'Publishing revision...',
      success: 'Revision published as new version',
      failure: 'Failed to publish revision',
    },
    variables: {
      execute: 'publish',
      isPending: 'isPublishing',
    },
  },
} as const satisfies ModelCommandsSchema
