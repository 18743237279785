import { ActionIcon } from '@mantine/core'
import { useEventListener } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  TreeSearch,
  TreeSearchModal,
} from '~/client/dashboard/components/global/Search'
import { useProcessQuery } from '~/client/dashboard/queries/process-queries'
import { useRevisionQuery } from '~/client/dashboard/queries/revision-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { useProcessContext } from '~/client/dashboard/stores/ProcessStore'
import {
  useProcessTreeData
} from '~/client/dashboard/stores/RevisionStore'
import { Box, Column } from '~/client/shared/Layout'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import { ClientModel } from '~/schemas'
import classes from './Process.module.css'
import { useDarkMode, useDeviceSize, useGlobalContext } from '~/client/dashboard/stores/GlobalStore'

export const ProcessIcon = ({
  size = 24,
  id,
  revisionId,
}: {
  size?: number | string
  id: string
  revisionId: string | null
}) => {
  const vars = useThemeVars()
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const getDocColor = useGlobalContext((x) => x.getDocColor)
  const getDraftColor = useGlobalContext((x) => x.getDraftColor)
  const organizationId = useOrganizationContext((x) => x.id)
  const { process, isLoading } = useProcessQuery({
    id,
    organizationId,
  })
  const { revision, isLoadingRevision } = useRevisionQuery({
    id: revisionId ?? process?.mainRevisionId ?? undefined,
    processId: id,
    organizationId,
  })

  if (!process || (!revision && !isLoadingRevision))
    return <ProcessIconDefault size={size} />

  const color = getDocColor(id)
  const isPublished = Boolean(process.mainRevisionId)
  const isDraft = revision ? revision.isDraft : true

  return (
    <Box
      w={size}
      h={size}
      align="center"
      justify="center"
      style={{
        borderRadius: '50%',
        color: color.toString(),
        flexShrink: 0,
        pointerEvents: 'all',
        background: color.fade(0.2).toString(),
        ...(!isDraft && {
          border: '2px solid ' + color.toString(),
        }),
        ...(isDraft && {
          border: '2px dashed ' + color.toString(),
        }),
        ...(isDraft &&
          !isPublished && {
            border:
              '2px dashed ' +
              (isDarkMode ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.3)'),
            background: getDraftColor(),
            color: vars.colors.text,
          }),
      }}
    >
      {(!isDraft || isPublished) && (
        <Icon name="MdDeviceHub" size="85%" nudgeUp={1} />
      )}
    </Box>
  )
}

export const ProcessIconDefault = ({
  size = 24,
}: {
  size?: number | string
}) => {
  const isDarkMode = useDarkMode()
  const color = isDarkMode ? Color.gray(600) : Color.gray(500)
  return (
    <Box
      w={size}
      h={size}
      align="center"
      justify="center"
      style={{
        borderRadius: '50%',
        flexShrink: 0,
        color,
        background: isDarkMode ? Color.gray(800) : Color.gray(200),
        border: '2px solid ' + color,
      }}
    >
      <Icon name="MdDeviceHub" size="85%" nudgeUp={1} />
    </Box>
  )
}

// export const SubprocessHeader = ({ id }: { id: string }) => {
//   const organizationId = useOrganizationContext((x) => x.id)
//   const currentNodeId = useRevisionContext((x) => x
//   const { process } = useProcessQuery({ id, organizationId }).currentNodeId)
//   const collapseSubdocNode = useRevisionContext((x) => x.collapseSubdocNode)
//   const nodeIndex = useRevisionContext((x) => x.nodeIndex)
//   const docLightColor = getDocLightColor(id)
//   const strokeColor = getDocStrokeColor(process?.id)
//   const organizationSlug = useOrganizationContext((x) => x.organization.slug)

//   const currentNode = nodeIndex[currentNodeId!]

//   if (!currentNode) return
//   if (!process) return null

//   const previousProcessId =
//     currentNode.processPath.length > 2
//       ? currentNode.processPath[currentNode.processPath.length - 2]
//       : null

//   return (
//     <Row
//       pl="md"
//       py="md"
//       style={{
//         position: 'relative',
//         // margin: 6,
//         // borderRadius: 4,
//         background: docLightColor.toString(),
//         // outline: `3px solid ${Color.getDocColor(
//         //   subdocument.id,
//         // ).toString()}`,
//       }}
//     >
//       <ProcessIcon id={id} size={30} revisionId={null} />
//       <Column ml="md" style={{ overflow: 'hidden' }}>
//         <Text
//           style={{
//             fontSize: 10,
//             textTransform: 'uppercase',
//             fontWeight: 700,
//             color: isDarkMode
//               ? 'rgba(255, 255, 255, 0.4)'
//               : 'rgba(0, 0, 0, 0.4)',
//           }}
//           mt={-4}
//           mb={2}
//           component={Link}
//           href={RouteMap.Process(organizationSlug, process.slug)}
//           asChild
//           // @ts-ignore
//           target="_blank"
//         >
//           Sub-process
//           <Icon
//             name="MdOpenInNew"
//             style={{ display: 'inline', marginLeft: 4 }}
//             size={13}
//             nudgeDown={3}
//           />
//         </Text>
//         <Text fz="0.75rem" style={{ lineHeight: 1.2 }}>
//           {process.title}
//         </Text>
//       </Column>
//       {/* Collapse button */}
//       <Row ml="auto" mr="sm" pos="relative">
//         {/* {currentNode.processPath
//         .slice(1, currentNode.processPath.length - 1)
//         .map((x) => (
//           <ProcessIcon key={x} id={x} size={30} />
//         ))} */}
//         <Button
//           variant="outline"
//           onClick={() => {
//             collapseSubdocNode(currentNode.parentNodeId!)
//           }}
//           h={36}
//           miw={36}
//           px={6}
//           style={{
//             color: strokeColor.toString(),
//             borderColor: strokeColor.toString(),
//           }}
//         >
//           {previousProcessId && (
//             <Box mr={4}>
//               <ProcessIcon
//                 id={currentNode.processPath[currentNode.processPath.length - 2]}
//                 revisionId={null}
//               />
//             </Box>
//           )}
//           <Icon name="MdKeyboardBackspace" size={18} />
//           {/* {!previousProcessId && <Text ml={4}>Back</Text>} */}
//         </Button>
//       </Row>
//     </Row>
//   )
// }

// export const InboundConnection = ({
//   connector,
// }: {
//   connector: ListConnector
// }) => {
//   const queryKey = useRevisionContext((x) => x.queryKey)
//   const setCurrentNode = useRevisionContext((x) => x.setCurrentNode)
//   const viewMode = useProcessContext((x) => x.viewMode)
//   const { setStepTarget } = useProcessAction(queryKey, 'setStepTarget')
//   const { setConnectorText } = useProcessAction(queryKey, 'setConnectorText')
//   const originNode = useNode(connector.originId)
//   const targetNode = useNode(connector.targetId)!
//   const isEditing = viewMode === ViewMode.Editing && connector.canEdit

//   const otherConnector = originNode?.connectorsOut.find(
//     (x) => x.id !== connector.id,
//   )
//   const otherTargetNode = useNode(otherConnector?.targetId)

//   if (!originNode) return null

//   return (
//     <Column w="100%" align="center">
//       {/* Node */}
//       <Box mb={5}>
//         <NodeButton
//           node={originNode}
//           onClick={() => {
//             setCurrentNode(originNode.id)
//           }}
//         />
//       </Box>
//       <Row
//         justify="space-around"
//         align="stretch"
//         maw={deviceSize.isSmall ? '100%' : '75%'}
//         miw={deviceSize.isSmall ? '80%' : '70%'}
//       >
//         <Column
//           maw={otherConnector ? '50%' : '100%'}
//           style={{ order: connector.position }}
//         >
//           {/* Label */}
//           {(connector.text || originNode.type === NodeType.Decision) && (
//             <TextInput
//               disabled={!isEditing}
//               variant="unstyled"
//               w="100%"
//               mt={4}
//               size="md"
//               opacity={1}
//               placeholder={isEditing ? 'Label...' : ''}
//               defaultValue={connector.text ?? ''}
//               styles={{
//                 input: {
//                   textOverflow: 'ellipsis',
//                   textAlign: 'center',
//                   padding: '0 !important',
//                   lineHeight: 1.3,
//                   fontSize: 13,
//                   height: 20,
//                   minHeight: 0,
//                 },
//               }}
//               onFocus={(e) => e.target.select()}
//               onBlur={(e) => {
//                 setConnectorText({
//                   position: connector.position,
//                   originId: connector.originServerId,
//                   text: e.target.value ?? '',
//                 })
//               }}
//               maxLength={TEXT_MAX_LENGTH}
//             />
//           )}
//           {/* Arrow */}
//           <div style={{ marginTop: 5 }}>
//             <Box pos="relative" left="calc(50% - 20px)">
//               <Box opacity={0.5} style={{ zIndex: 2, pointerEvents: 'none' }}>
//                 <Icon name={'TbArrowNarrowDown'} size={40} />
//               </Box>
//               <Box mt={5} pos="relative" left={-10}>
//                 {/* Dropdown menu */}
//                 {/* {isEditing && (
//                   <DropdownButton
//                     colors={Color.scheme(['gray', 0], ['dark', 8])}
//                   >
//                     <Menu.Item
//                       leftSection={<Icon name="MdAddCircle" size={18} />}
//                       onClick={(e) => {
//                         openNodeAdd({
//                           connector,
//                           isInjecting: true,
//                         })
//                       }}
//                     >
//                       Insert step
//                     </Menu.Item>
//                     <Menu.Item
//                       leftSection={<Icon name="MdClose" size={18} />}
//                       onClick={(e) => {
//                         setStepTarget({
//                           originId: nodeIndex[connector.originId]!.serverId!,
//                           position: connector.position,
//                           targetId: null,
//                         })
//                       }}
//                     >
//                       Disconnect
//                     </Menu.Item>
//                   </DropdownButton>
//                 )} */}
//               </Box>
//             </Box>
//           </div>
//         </Column>
//         {otherConnector && (
//           <Column
//             align="center"
//             maw="50%"
//             style={{ order: otherConnector.position }}
//           >
//             {/* Label */}
//             {otherConnector.text && (
//               <TextInput
//                 disabled={!isEditing}
//                 variant="unstyled"
//                 w="100%"
//                 size="md"
//                 opacity={0.4}
//                 mt={4}
//                 defaultValue={otherConnector.text}
//                 style={{ pointerEvents: 'none' }}
//                 styles={{
//                   input: {
//                     textOverflow: 'ellipsis',
//                     textAlign: 'center',
//                     padding: '0 !important',
//                     lineHeight: 1.3,
//                     fontSize: 13,
//                     height: 20,
//                     minHeight: 0,
//                   },
//                 }}
//               />
//             )}
//             {/* Other node button */}
//             {otherTargetNode && (
//               <Button
//                 mt={9}
//                 h={32}
//                 py={4}
//                 px={10}
//                 variant="default"
//                 onClick={() => {
//                   setCurrentNode(otherConnector.targetId, connector.originId)
//                 }}
//               >
//                 <NodeTitleIcon
//                   node={otherTargetNode}
//                   width={
//                     otherTargetNode.processId ||
//                     isSubdocBoundaryNode(otherTargetNode)
//                       ? 20
//                       : 30
//                   }
//                 />
//               </Button>
//             )}
//             {/* No other node */}
//             {!otherConnector.targetId && (
//               <Box mt={9} opacity={!isEditing ? 0.5 : 1}>
//                 {!isEditing && (
//                   <Icon name={'RxComponentPlaceholder'} size={32} />
//                 )}
//                 {/* {isEditing && (
//                   <ActionIcon
//                     variant="outline"
//                     radius="50%"
//                     size={32}
//                     onClick={() => {
//                       openNodeAdd({ connector })
//                     }}
//                   >
//                     <Icon name="MdAdd" size={24} />
//                   </ActionIcon>
//                 )} */}
//               </Box>
//             )}
//           </Column>
//         )}
//       </Row>
//     </Column>
//   )
// }

export const AddStepButton = (props: { onClick: () => void }) => (
  <ActionIcon variant="outline" radius="50%" size={50} onClick={props.onClick}>
    <Icon name="MdAdd" size={32} />
  </ActionIcon>
)

// export const OutboundConnection = ({
//   connector,
// }: {
//   connector: ListConnector
// }) => {
//   const queryKey = useRevisionContext((x) => x.queryKey)
//   const setCurrentNode = useRevisionContext((x) => x.setCurrentNode)
//   const viewMode = useProcessContext((x) => x.viewMode)
//   const { setConnectorText } = useProcessAction(queryKey, 'setConnectorText')
//   const { setStepTarget } = useProcessAction(queryKey, 'setStepTarget')
//   const originNode = useNode(connector.originId)
//   const targetNode = useNode(connector.targetId)
//   const isEditing = viewMode === ViewMode.Editing && connector.canEdit
//   const isEditingUnterminatedSubdoc =
//     isEditing &&
//     Boolean(originNode?.parentNodeId) &&
//     originNode?.type !== NodeType.End &&
//     targetNode?.type === ListNodeType.SubdocEnd

//   if (!originNode) return null

//   return (
//     <Column w="100%" align="center" gap={4}>
//       {/* Label */}
//       {(connector.text || originNode.type === NodeType.Decision) && (
//         <TextInput
//           disabled={!isEditing}
//           variant="unstyled"
//           w="100%"
//           size="md"
//           opacity={0.7}
//           placeholder={isEditing ? 'Label...' : ''}
//           styles={{
//             input: {
//               textAlign: 'center',
//               padding: '0 !important',
//               lineHeight: 1.3,
//               fontSize: 13,
//               height: 20,
//               minHeight: 0,
//             },
//           }}
//           defaultValue={connector.text ?? ''}
//           onFocus={(e) => e.target.select()}
//           onBlur={(e) => {
//             setConnectorText({
//               position: connector.position,
//               originId: connector.originServerId,
//               text: e.target.value ?? '',
//             })
//           }}
//           maxLength={TEXT_MAX_LENGTH}
//         />
//       )}
//       <Column gap={10} w="100%" align="center">
//         {/* Arrow */}
//         <div>
//           <Box pos="relative" left="calc(50% - 20px)">
//             <Box opacity={0.2} style={{ zIndex: 2, pointerEvents: 'none' }}>
//               <Icon name={'TbArrowNarrowDown'} size={40} />
//             </Box>
//             {/* {targetNode && isEditing && (
//               <Box mt={5} pos="relative" left={-10}>
//                 <DropdownButton colors={Color.scheme(['gray', 0], ['dark', 8])}>
//                   <Menu.Item
//                     leftSection={<Icon name="MdAddCircle" size={18} />}
//                     onClick={(e) => {
//                       openNodeSelect({ connector, isInjecting: true })
//                     }}
//                   >
//                     Insert step
//                   </Menu.Item>
//                   <Menu.Item
//                     leftSection={<Icon name="TbArrowRightCircle" size={18} />}
//                     onClick={(e) => {
//                       openNodeSelect({ connector })
//                     }}
//                   >
//                     Change target
//                   </Menu.Item>
//                   <Menu.Item
//                     leftSection={<Icon name="MdClose" size={18} />}
//                     onClick={(e) => {
//                       setStepTarget({
//                         originId: originNode.serverId!,
//                         position: connector.position,
//                         targetId: null,
//                       })
//                     }}
//                   >
//                     Disconnect
//                   </Menu.Item>
//                 </DropdownButton>
//               </Box>
//             )} */}
//           </Box>
//         </div>
//         {targetNode && !isEditingUnterminatedSubdoc && (
//           <NodeButton
//             node={targetNode}
//             onClick={() => {
//               setCurrentNode(targetNode.id)
//             }}
//           />
//         )}
//         {/* {(isEditingUnterminatedSubdoc || (!targetNode && isEditing)) && (
//           <>
//             <AddStepButton
//               onClick={() => {
//                 openNodeAdd({ connector })
//               }}
//             />
//             <Text c="dimmed" fw={300}>
//               {originNode.isRoot ? 'Create first step' : 'Add a step'}
//             </Text>
//           </>
//         )} */}
//         {!targetNode && !isEditing && (
//           <Box opacity={0.5}>
//             <Icon name="RxComponentPlaceholder" size={40} />
//           </Box>
//         )}
//       </Column>
//     </Column>
//   )
// }

export type ProcessSelectOptions = {
  onSelect?: (process: ClientModel['Process']) => void
}

export const ProcessSelectionModal = ({
  isOpen,
  onClose,
  onSelect,
}: ProcessSelectOptions & {
  isOpen: boolean
  onClose: () => void
}) => {
  const id = useProcessContext((x) => x.id)
  const insertProcessTree = useProcessTreeData()

  return (
    <TreeSearchModal
      title="Choose a process to insert"
      data={insertProcessTree.data}
      filter={insertProcessTree.filter}
      defaultVisibleIds={[id]}
      onSelect={(x) => onSelect!(x.data as ClientModel['Process'])}
      opened={isOpen}
      onClose={onClose}
      size={280}
      mah={300}
    />
  )
}

export const ProcessSelectionMenu = ({ onSelect }: ProcessSelectOptions) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const deviceSize = useDeviceSize()
  const insertProcessTree = useProcessTreeData()
  const ref = useEventListener('wheel', (e) => e.stopPropagation())
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  useEffect(() => {
    if (!ready) return
    setTimeout(() => {
      if (!deviceSize.isSmall) {
        inputRef.current?.focus()
      }
    })
  }, [ready])

  return (
    <Column
      className="nopan"
      ref={ref}
      w={240}
      mah={300}
      style={{
        zIndex: 100,
        pointerEvents: 'auto',
        overflow: 'auto',
        display: ready ? 'flex' : 'none',
      }}
    >
      <TreeSearch
        data={insertProcessTree.data}
        filter={insertProcessTree.filter}
        defaultVisibleIds={insertProcessTree.defaultVisibleIds}
        inputRef={inputRef}
        input={{
          classNames: {
            input: classes.processSelectionInput,
          },
          placeholder: 'Process to insert...',
        }}
        onSelect={(x) => onSelect!(x.data as ClientModel['Process'])}
      />
    </Column>
  )
}

// export const useConnectableNodes = (nodeId?: string) => {
//   const { nodeList } = useNodeList()
//   const currentNodeId = useRevisionContext((x) => x.currentNodeId)
//   const node = useNode(nodeId)

//   if (!node) return []

//   return nodeList.filter(
//     (x) =>
//       x.canEdit &&
//       x.parentNodeId === node?.parentNodeId &&
//       !x.isRoot &&
//       x.id !== currentNodeId &&
//       !node.connectorsOut.some((c) => c.targetId === x.id),
//   )
// }
