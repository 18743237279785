'use client'

import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Redirect } from 'wouter'
import { createStore, useStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { RouteMap } from '~/client/RouteMap.ts'
import { BodySkeleton } from '~/client/dashboard/components/global/DashboardShell'
import {
  TreeItem,
  buildDocumentFlatTree,
} from '~/client/dashboard/components/global/TreeView'
import { QueryRequestError } from '~/client/dashboard/queries/helpers/query-helpers'
import {
  useOrganizationDocumentsQuery,
  useOrganizationProcessesQuery,
} from '~/client/dashboard/queries/organization-queries'
import {
  useRevisionContentQuery,
  useRevisionNodeDetailsQuery,
  useRevisionQuery,
} from '~/client/dashboard/queries/revision-queries'
import { DiagramViewMode } from "./DiagramStore"
import { useGlobalContext, useGlobalStore } from '~/client/dashboard/stores/GlobalStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import {
  ProcessTreePathItem,
  useProcessContext,
  useProcessStore,
} from '~/client/dashboard/stores/ProcessStore'
import { RevisionContext } from '~/client/dashboard/stores/RevisionContext'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import { toast } from '~/client/shared/Toast'
import { useAsRef } from '~/client/shared/hooks/useAsRef'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { ModelName } from '~/commands/base-commands'
import { useCommand, useCommandAuthorization } from '~/commands/client-commands'
import { ClientModel, Model, ModelQueryKey } from '~/schemas'
import { ProcessActions } from '~/utils/process-actions'

/**
 * Store
 */

export type RevisionState = {
  id: string
  queryKey: ModelQueryKey['Revision']
  version: string | number
  revision: ClientModel['Revision']
  content: Model['Revision']['content']
  activeTab: RevisionTab
  layoutSize: { x: number; y: number }

  // Actions
  setActiveTab: (tab: RevisionTab) => void
}

const PERSISTED_FIELDS = ['activeTab'] as (keyof RevisionState)[]

type RevisionStoreProps = {
  revision: RevisionState['revision']
  content: RevisionState['content']
}

export type RevisionStore = ReturnType<typeof createRevisionStore>

const createRevisionStore = ({ revision, content }: RevisionStoreProps) => {
  const DEFAULT_PROPS = {
    id: revision.id,
    version: revision.id,
    queryKey: {
      id: revision.id,
      processId: revision.processId,
      organizationId: revision.organizationId,
    },
    revision,
    content,
    activeTab: 'diagram',
    layoutSize: { x: 0, y: 0 },
  } satisfies Partial<RevisionState>

  return createStore<RevisionState>()(
    persist(
      (set) => ({
        ...DEFAULT_PROPS,
        setActiveTab: (activeTab) =>
          set(() => ({
            activeTab,
          })),
      }),
      {
        name: `revision-storage:${revision.id}`,
        version: 0, // Update if any stored fields change
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              PERSISTED_FIELDS.includes(key as keyof RevisionState),
            ),
          ),
      },
    ),
  )
}

/**
 * Context
 */

type RevisionProviderProps = React.PropsWithChildren<{
  id: string
  skipLogic?: boolean
}>

export const RevisionProvider = ({
  children,
  id,
  skipLogic = false,
}: RevisionProviderProps) => {
  const globalId = useRevisionGlobalId(id)
  const revisionStore = useGlobalContext((x) => x.revisionStores.get(globalId))
  const addRevisionStore = useGlobalContext((x) => x.addRevisionStore)

  // Fields
  const organizationId = useOrganizationContext((x) => x.id)
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const removeActiveItem = useOrganizationContext((x) => x.removeActiveItem)

  // Queries
  const processId = useProcessContext((x) => x.id)
  const processSlug = useProcessContext((x) => x.process.slug)
  const treePath = useProcessContext((x) => x.treePath)
  const { revision, isLoadingRevision, isLoadingRevisionError } =
    useRevisionQuery({
      id,
      processId: processId,
      organizationId,
    })
  const { content, isLoadingContent, isLoadingContentError } =
    useRevisionContentQuery(
      {
        id,
        processId: processId,
        organizationId,
      },
      {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      },
    )

  useEffect(() => {
    if (!revision || !content) return
    if (revisionStore) {
      revisionStore.setState({
        revision,
        content,
      })
    } else {
      const store = createRevisionStore({
        revision,
        content,
      })
      addRevisionStore(globalId, store)
    }
  }, [revisionStore, revision, content, globalId])

  if (isLoadingRevisionError || isLoadingContentError) {
    return (
      <QueryRequestError
        error={isLoadingRevisionError || isLoadingContentError}
      />
    )
  }

  const isRoot = treePath.length === 0

  if (!revision || !content) {
    if (isLoadingContent || isLoadingRevision) {
      return isRoot ? <BodySkeleton /> : null
    }
    return (
      <Redirect
        to={RouteMap.Process(organizationSlug, processSlug)}
        replace={true}
      />
    )
  }

  if (!revisionStore) return null

  __dev.revision = {
    ...revision,
    store: revisionStore,
  }

  return (
    <ErrorBoundary>
      <RevisionContext.Provider value={revisionStore}>
        {!skipLogic && <RevisionContextLogic />}
        {children}
      </RevisionContext.Provider>
    </ErrorBoundary>
  )
}

export const useRevisionContext = <T extends any>(
  selector: (state: RevisionState) => T,
) => {
  const store = useContext(RevisionContext)
  if (!store) throw new Error('Missing RevisionContext.Provider in the tree')
  return useStore(store, selector)
}

export const useRevisionStore = () => {
  const store = useContext(RevisionContext)
  if (!store) throw new Error('Missing RevisionContext.Provider in the tree')
  return store
}

const RevisionContextLogic = () => {
  const { setActiveRevision } = useCommand(
    ModelName.ProcessMember,
    'SetActiveRevision',
  )
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const processId = useProcessContext((x) => x.process.id)
  const memberProfileId = useProcessContext((x) => x.memberProfile.id)
  const revisionStore = useRevisionStore()
  const versions = useProcessContext((x) => x.versions)
  const id = useRevisionContext((x) => x.id)
  const queryKey = useRevisionContext((x) => x.queryKey)

  // Fetch and cache node details
  useRevisionNodeDetailsQuery({
    id,
    processId,
    organizationId,
  })

  const version =
    versions.published.find((x) => x.revision.id === id)?.version ?? id

  useEffect(() => {
    // Set active revision every time so it can be used to
    //  track total number of views
    setActiveRevision({
      key: {
        id: memberProfileId,
        processId: queryKey.processId,
        organizationId: queryKey.organizationId,
      },
      params: { revisionId: queryKey.id },
    })
  }, [queryKey, memberProfileId])

  useEffect(() => {
    revisionStore.setState({ version })
  }, [revisionStore, version])

  return null
}

/**
 * Content types
 */

export enum SubdocNodeType {
  SubdocStart = 'SubdocStart',
  SubdocEnd = 'SubdocEnd',
}

export type RevisionTab = 'flow' | 'diagram'

/**
 * Derived fields
 */

export const useProcessTreeData = () => {
  const id = useRevisionContext((x) => x.id)
  const documentIds = useProcessContext((x) => x.process.documentIds)

  const [processTreeData, setProcessTreeData] = useState<TreeItem[]>([])
  const [defaultVisibleIds, setDefaultVisibleIds] = useState<string[]>([])
  const organization = useOrganizationContext((x) => x.organization)
  const filter = (x: TreeItem) => {
    return (
      x.type === 'Process' &&
      !x.data.isArchived &&
      x.data.id !== id &&
      Boolean((x.data as ClientModel['Process']).mainRevisionId)
    )
  }

  const { documents } = useOrganizationDocumentsQuery({
    id: organization.id,
  })
  const { processes } = useOrganizationProcessesQuery({
    id: organization.id,
  })

  useEffect(() => {
    // Get process tree
    if (!organization) return
    const data = buildDocumentFlatTree(
      organization.rootDocumentId,
      documents,
      processes,
      filter,
    )
    const visibleIds = data
      .filter(
        (x) =>
          documentIds.includes(x.data.id) ||
          (x.data.type === 'Process' &&
            x.data.documentIds.some((id) => documentIds.includes(id))),
      )
      .map((x) => x.id)
    setProcessTreeData(data)
    setDefaultVisibleIds(visibleIds)
  }, [JSON.stringify(documents.map((x) => x.parentId))])

  return {
    filter,
    data: processTreeData,
    defaultVisibleIds,
  }
}

export const useProcessAction = <
  T extends keyof typeof ProcessActions,
  Args = Parameters<(typeof ProcessActions)[T]>[1],
>(
  queryKey: ModelQueryKey['Revision'],
  action: T,
) => {
  const { setContent, setContentAsync, isSettingContent } = useCommand(
    ModelName.Revision,
    'SetContent',
    queryKey,
  )
  const { content } = useRevisionContentQuery(queryKey)
  const _content = useAsRef(content)

  const getNewContent = useCallback(
    (args: Args) => {
      if (!_content.current) return null
      try {
        // @ts-ignore
        return ProcessActions[action](_content.current, args)
      } catch (e) {
        console.warn(e)
        toast.failure('Something went wrong while updating')
      }
    },
    [_content.current],
  )

  const actionMutation = useCallback((args: Args) => {
    const newContent = getNewContent(args)
    if (!newContent) return

    return setContent({
      content: newContent,
    })
  }, [])

  const actionMutationAsync = useCallback((args: Args) => {
    const newContent = getNewContent(args)
    if (!newContent) return

    return setContentAsync({
      content: newContent,
    })
  }, [])

  type Result = {
    [Action in T]: typeof actionMutation
  } & {
    [Action in `${T}Async`]: typeof actionMutationAsync
  } & {
    isSettingContent: boolean
  }

  return {
    [action]: actionMutation,
    [action + 'Async']: actionMutationAsync,
    isSettingContent,
  } as Result
}

export const useCanEditRevision = () => {
  const revisionQueryKey = useRevisionContext((x) => x.queryKey)
  const { valid: canEditRevision } = useCommandAuthorization(
    ModelName.Revision,
    'SetContent',
    {
      key: revisionQueryKey,
      params: {},
    },
  )

  return canEditRevision
}

// Root revision is the one containing all other subprocesses
export const useRootRevisionContext = <T extends unknown>(
  selector: (state: RevisionState) => T,
) => {
  const revisionStore = useRootRevisionStore()
  return useStore(revisionStore, selector)
}

export const useRootRevisionStore = () => {
  const revisionId = useRevisionContext((x) => x.id)
  const rootRevisionId =
    useProcessContext((x) => x.treePath[0]?.revisionId) ?? revisionId
  return useGlobalContext((x) => x.revisionStores.get(rootRevisionId))!
}

const emptyStore = createStore(() => null)

export const useParentRevisionContext = <T extends unknown>(
  selector: (state: RevisionState) => T,
) => {
  const revisionStore = useParentRevisionStore()
  return useStore(revisionStore ?? emptyStore, selector)
}

export const useParentRevisionStore = () => {
  const revisionId = useRevisionContext((x) => x.id)
  const parentRevisionId =
    useProcessContext((x) => x.treePath.at(-1)?.revisionId) ?? revisionId
  return useGlobalContext((x) => x.revisionStores.get(parentRevisionId))!
}

export const getRevisionPathString = (treePath: ProcessTreePathItem[]) => {
  return treePath.map((x) => x.revisionId).join('_')
}

export const getRevisionGlobalId = (
  id: number | string | null,
  treePath: ProcessTreePathItem[],
) => {
  if (!id) return null
  if (treePath.length === 0) return String(id)
  return getRevisionPathString(treePath) + '_' + id
}

export const useRevisionGlobalId = <
  T extends number | string | null,
  U extends T extends null ? null : string,
>(
  id: T,
): U => {
  const treePath = useProcessContext((x) => x.treePath)
  return getRevisionGlobalId(id, treePath) as U
}
