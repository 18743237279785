import { Center, Menu, Transition } from '@mantine/core'
import { mergeRefs, useEventListener } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { Position } from '@xyflow/react'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { DropdownButton } from '~/client/dashboard/components/global/Prefabs'
import { ProcessSelectionMenu } from '~/client/dashboard/components/process/ProcessComponents'
import {
  ConnectorButtonProps,
  DefaultNodePort,
} from '~/client/dashboard/components/process/diagram/DiagramConnectors'
import {
  BUTTON_OFFSET_EDIT,
  getHandleSide,
  isAddNode,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import { ChooseNodeInsertMenu } from '~/client/dashboard/components/process/node/NodeComponents'
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import {
  useProcessAction,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore'
import { Column, Row } from '~/client/shared/Layout'
import { NodeType } from '~/schemas/node-schema'
import classes from './Diagram.module.css'
import { useDiagramContext } from '~/client/dashboard/stores/DiagramStore'
import {useDarkMode} from '~/client/dashboard/stores/GlobalStore.tsx'
import { useThemeColor } from '~/client/dashboard/components/global/colors'

export const NodeConnectorButtonEditing = ({
  position,
  side,
  isNodeActive,
  showDeleteOption = false,
  originId,
  targetId,
  connectorPosition,
}: ConnectorButtonProps & {
  showDeleteOption?: boolean
  originId: number
  targetId: number | null
  connectorPosition: number
}) => {
  const isDarkMode = useDarkMode()
  const theme = useThemeColor()
  const queryKey = useRevisionContext((x) => x.queryKey)
  const [transitionStep, setTransitionStep] = useState<
    null | 'Main' | 'ChooseNode' | 'ChooseProcess'
  >(null)
  const [menuStep, setMenuStep] = useState<
    null | 'Main' | 'ChooseNode' | 'ChooseProcess'
  >(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const id = useNodeContext((x) => x.id)
  const isRoot = useNodeContext((x) => x.isRoot)
  const pointSize = 23

  const { addStep } = useProcessAction(queryKey, 'addStep')
  const { setStepTarget } = useProcessAction(queryKey, 'setStepTarget')
  const { deleteConnector } = useProcessAction(queryKey, 'deleteConnector')
  const wrapperRef = mergeRefs(
    useEventListener('dblclick', (e) => {
      e.stopPropagation()
    }),
    useEventListener('mousedown', (e) => {
      e.stopPropagation()
    }),
  )
  const scale = useDiagramContext((x) => Math.min(1 / x.viewport.zoom, 2))

  useEffect(() => {
    if (!menuStep) return

    setTransitionStep(menuStep)

    const listener = (e: MouseEvent) => {
      if (
        dropdownRef.current?.contains(e.target as HTMLElement) ||
        buttonRef.current?.contains(e.target as HTMLElement)
      )
        return
      setMenuStep(null)
    }
    setTimeout(() => {
      // Re-focus the dropdown menu on content change
      const dropdown = document.querySelector(
        '[data-menu-dropdown]',
      ) as HTMLInputElement
      dropdown?.focus()

      document.body.addEventListener('click', listener)
    })
    return () => {
      document.body.removeEventListener('click', listener)
    }
  }, [menuStep])

  if (!isNodeActive || !targetId) {
    return <DefaultNodePort top={position.y} left={position.x} />
  }

  const offset = { ...position }
  switch (side) {
    case Position.Left: {
      offset.x -= BUTTON_OFFSET_EDIT
      break
    }
    case Position.Right: {
      offset.x += BUTTON_OFFSET_EDIT
      break
    }
    case Position.Bottom: {
      offset.y += BUTTON_OFFSET_EDIT
      break
    }
    case Position.Top: {
      offset.y -= BUTTON_OFFSET_EDIT
      break
    }
  }

  return (
    <div ref={wrapperRef} onClick={(e) => e.stopPropagation()}>
      <DropdownButton
        size={pointSize}
        buttonRef={buttonRef}
        className={classes.dropdownButton}
        opened={Boolean(menuStep)}
        onClose={() => setMenuStep(null)}
        onClick={() => setMenuStep(menuStep ? null : 'Main')}
        transitionProps={{
          onExited() {
            setTransitionStep(null)
          },
        }}
        scale={scale}
        withinPortal={true}
        portalProps={{
          target: '.react-flow__viewport',
        }}
        dropdownRef={dropdownRef}
        style={{
          top: offset.y,
          left: offset.x,
          position: 'absolute',
          marginLeft: -pointSize / 2,
          marginTop: -pointSize / 2,
          height: pointSize,
          width: pointSize,
          borderRadius: '50%',
          cursor: 'pointer',
          border: isDarkMode
            ? `1px solid ${Color.gray(700)}`
            : `1px solid ${Color.gray(300)}`,
          background: isDarkMode ? Color.gray(800) : Color.white(0),
          color: isDarkMode ? Color.gray(500) : Color.gray(600),
        }}
      >
        {transitionStep === 'Main' && (
          <>
            <Menu.Item
              pr="md"
              leftSection={
                <Row w={22} justify="center">
                  <Center
                    w={19}
                    h={19}
                    bg="blue.4"
                    c="white"
                    style={{ borderRadius: '50%' }}
                  >
                    <Icon size={18} name="MdAdd" />
                  </Center>
                </Row>
              }
              onClick={(e) => {
                setTimeout(() => {
                  setMenuStep('ChooseNode')
                })
              }}
            >
              Insert step
            </Menu.Item>
            {targetId && (
              <Menu.Item
                pr="md"
                leftSection={
                  <Row
                    w={22}
                    justify="center"
                    c={theme(['gray', 9], ['white', 0])}
                  >
                    <Icon nudgeUp={1} name="PiLinkBreak" size={21} />
                  </Row>
                }
                onClick={(e) => {
                  setStepTarget({
                    originId: id,
                    position: connectorPosition,
                    targetId: null,
                  })
                }}
              >
                Disconnect
              </Menu.Item>
            )}
            {showDeleteOption && <Menu.Divider my="xs" />}
            {showDeleteOption && (
              <Menu.Item
                pr="md"
                leftSection={
                  <Row w={22} justify="center" c="red.3">
                    <Icon name="PiTrashSimpleDuotone" size={19} />
                  </Row>
                }
                onClick={(e) => {
                  deleteConnector({
                    originId: id,
                    position: connectorPosition,
                  })
                }}
              >
                Remove
              </Menu.Item>
            )}
          </>
        )}
        <Transition
          mounted={transitionStep === 'ChooseNode'}
          keepMounted={true}
          transition="fade"
          duration={200}
          exitDuration={0}
          timingFunction="ease"
        >
          {(style) => (
            <Column style={{ ...style }}>
              <ChooseNodeInsertMenu
                addNode={(type) => {
                  addStep({
                    position: connectorPosition,
                    originId,
                    step: {
                      type,
                    },
                  })
                }}
                isInserting={true}
                isRoot={isRoot}
                newStepText="Step"
                addProcess={() => {
                  setTimeout(() => {
                    setMenuStep('ChooseProcess')
                  })
                }}
              />
            </Column>
          )}
        </Transition>
        {transitionStep === 'ChooseProcess' && (
          <ProcessSelectionMenu
            onSelect={(x) => {
              addStep({
                originId: id,
                position: connectorPosition,
                step: {
                  type: NodeType.Subprocess,
                  title: x.title ?? '',
                  subprocessId: x.id,
                  // mainRevision is filtered in the selection menu
                  subprocessRevisionId: x.mainRevisionId!,
                },
              })
            }}
          />
        )}
      </DropdownButton>
    </div>
  )
}
export const NodeConnectorButtonViewing = ({
  position,
  isNodeActive,
}: ConnectorButtonProps) => {
  // TODO: From connector
  const side = Position.Bottom
  // const { side } = connector.origin.handle

  // const targetNode = useNode(targetId)
  const pointSize = 20

  const setCurrentNode = useDiagramContext((x) => x.setCurrentNode)
  const scrollToNode = useDiagramContext((x) => x.scrollToNode)

  // if (!targetNode) return null

  // if (!isNodeActive) {
  return <DefaultNodePort top={position.y} left={position.x} />
  // }

  // NOTE: This is the code to show next node connector as a button,
  //  allowing user to click to progress. This could be helpful for
  //  mobile users.
  // UPDATE: This should exist in Activity view (not Process view)

  // const color = targetNode.parentNodeId
  //   ? Color.getDocNodeColor(targetNode.processId, targetNode.type)
  //   : Color.getNodeColor(targetNode.type)
  // const strokeColor = getNodeStrokeColor(color)

  // const offset = { x: 0, y: 0 }
  // switch (position) {
  //   case Position.Left: {
  //     offset.x -= BUTTON_OFFSET_VIEW
  //     break
  //   }
  //   case Position.Right: {
  //     offset.x += BUTTON_OFFSET_VIEW
  //     break
  //   }
  //   case Position.Bottom: {
  //     offset.y += BUTTON_OFFSET_VIEW
  //     break
  //   }
  //   case Position.Top: {
  //     offset.y -= BUTTON_OFFSET_VIEW
  //     break
  //   }
  // }

  // return (
  //   <Box
  //     style={{
  //       top: `calc(${top} + ${offset.y}px)`,
  //       left: `calc(${left} + ${offset.x}px)`,
  //       position: 'absolute',
  //       marginLeft: -pointSize / 2,
  //       marginTop: -pointSize / 2,
  //       height: pointSize,
  //       width: pointSize,
  //       border: `1px solid ${strokeColor.toString()}`,
  //       borderRadius: '50%',
  //       background: color.toString(),
  //       zIndex: 2,
  //       pointerEvents: 'all',
  //       cursor: 'pointer',
  //     }}
  //     onClick={() => {
  //       setCurrentNode(targetId)
  //       scrollToNode(targetId, 400)
  //     }}
  //   ></Box>
  // )
}
