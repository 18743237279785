'use client'

/**
 * An expanding list of icons/logos.
 *
 * Color data should be stripped from the SVG, to
 *  be controlled through the "Icon" component.
 */

export const Warning = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 0 24 24">
    <path d="M12 5.8125C12.4102 5.8125 12.791 6.04688 12.9961 6.39844L19.3242 17.1797C19.5293 17.5605 19.5293 18 19.3242 18.3516C19.1191 18.7324 18.7383 18.9375 18.3281 18.9375H5.67188C5.23242 18.9375 4.85156 18.7324 4.64648 18.3516C4.44141 18 4.44141 17.5605 4.64648 17.1797L10.9746 6.39844C11.1797 6.04688 11.5605 5.8125 12 5.8125ZM12 9.5625C11.5898 9.5625 11.2969 9.88477 11.2969 10.2656V13.5469C11.2969 13.957 11.5898 14.25 12 14.25C12.3809 14.25 12.7031 13.957 12.7031 13.5469V10.2656C12.7031 9.88477 12.3809 9.5625 12 9.5625ZM12.9375 16.125C12.9375 15.627 12.498 15.1875 12 15.1875C11.4727 15.1875 11.0625 15.627 11.0625 16.125C11.0625 16.6523 11.4727 17.0625 12 17.0625C12.498 17.0625 12.9375 16.6523 12.9375 16.125Z" />
  </svg>
)

export const Passkey = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 -960 960 960">
    <path d="M172-212v-47q0-23 14-42t37-30q57-25 110.979-38 53.98-13 106.021-13 23.75 0 48 3t49 8q2 41 21 76.5t51 60.5v22H172Zm572 96-42.692-42.162v-130.703Q670-298 650.5-324T631-383q0-41.014 28.99-70.007Q688.98-482 729.99-482q41.01 0 69.51 29.032 28.5 29.033 28.5 70.103Q828-351 810.5-326.5 793-302 765-291.789L800-257l-42 42.3 42 42.3-56 56.4ZM440-498q-43.55 0-74.275-30.725Q335-559.45 335-603q0-43.55 30.725-74.275Q396.45-708 440-708q43.55 0 74.275 30.725Q545-646.55 545-603q0 43.55-30.725 74.275Q483.55-498 440-498Zm290 129q11 0 19.5-9t8.5-20q0-11-8.5-19.5T730-426q-11 0-20 8.5t-9 19.5q0 11 9 20t20 9Z" />
  </svg>
)

export const Lock = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 -960 960 960">
    <path d="M220-80q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h70v-96q0-78.85 55.606-134.425Q401.212-920 480.106-920T614.5-864.425Q670-808.85 670-730v96h70q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-434H220v434Zm260.168-140Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM350-634h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426-860 388-822.083 350-784.167 350-730v96ZM220-140v-434 434Z" />
  </svg>
)

export const LockFill = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 -960 960 960">
    <path d="M220-80q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h70v-96q0-78.85 55.606-134.425Q401.212-920 480.106-920T614.5-864.425Q670-808.85 670-730v96h70q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Zm260.168-200Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM350-634h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426-860 388-822.083 350-784.167 350-730v96Z" />
  </svg>
)

export const LockUser = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 -960 960 960">
    <path d="M360-600h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM704.615-95.384q-66.846 0-113.423-46.577t-46.577-113.424q0-66.846 46.577-113.423t113.423-46.577q66.847 0 113.424 46.577 46.577 46.577 46.577 113.423 0 66.847-46.577 113.424-46.577 46.577-113.424 46.577ZM506.923-120H264.615q-26.654 0-45.634-18.981Q200-157.961 200-184.615v-350.77q0-26.654 18.981-45.634Q237.961-600 264.615-600H320v-80q0-66.846 46.577-113.423T480-840q66.846 0 113.423 46.577T640-680v80h55.385q26.654 0 45.634 18.981Q760-562.039 760-535.385V-488q-13.846-5.462-27.692-6.039t-28.462-.577q-99.308 0-169.269 69.962-69.962 69.962-69.962 169.524 0 36.592 8.885 72.092T506.923-120Zm197.692-135.385q25 0 42.5-17.5t17.5-42.5q0-25-17.5-42.5t-42.5-17.5q-25 0-42.5 17.5t-17.5 42.5q0 25 17.5 42.5t42.5 17.5Zm0 120q30 0 56-14t43-39q-23-14-48-20.5t-51-6.5q-26 0-51 6.5t-48 20.5q17 25 43 39t56 14Z" />
  </svg>
)

export const Private = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 -960 960 960">
    <path d="M455.078-100.001q-78.154 0-147.308-29.962-69.153-29.961-120.961-81.769-51.807-51.807-81.769-120.961Q75.079-401.846 75.079-480q0-78.769 29.961-148.115 29.962-69.346 81.769-120.653 51.808-51.308 120.961-81.269 69.154-29.962 147.308-29.962 78.768 0 148.114 29.962 69.346 29.961 120.654 81.269 51.307 51.307 81.269 120.653Q835.076-558.769 835.076-480q0 78.154-29.961 147.307-29.962 69.154-81.269 120.961-51.308 51.808-120.654 81.769-69.346 29.962-148.114 29.962Zm40.845-47.23q114.539-14.462 196-95.923 81.462-81.462 95.923-196L495.923-147.231ZM122.924-523.23l289.308-288.924q-113.923 15.462-193.885 95.423-79.962 79.962-95.423 193.501Zm16.692 155.076 427.923-427.307q-20.846-7.923-43.154-12.308-22.308-4.385-45.309-5.615L121.693-456.001q1.231 23 5.808 45.001 4.577 22 12.115 42.846Zm63.308 108.077 472.077-472.461q-14.693-12.692-30.462-23.116-15.77-10.423-33.232-19.961l-451.845 451.46q9.539 17.847 20.154 33.616 10.616 15.77 23.308 30.462Zm96.308 75.692L750.693-637.23q-9.154-17.462-19.962-32.731-10.808-15.27-23.5-29.962L236.154-227.462q14.308 12.692 29.77 23.116 15.462 10.423 33.308 19.961Zm131.847 37.769 357.383-357.383q-1.231-23-5.616-45.308-4.384-22.308-12.307-43.155L342.616-164.539q20.846 7.923 43.154 12.308 22.308 4.385 45.309 5.615Z" />
  </svg>
)

export const Shield = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 -960 960 960">
    <path d="M480-105.155q-6.231 0-12.231-1t-11.623-3q-126.53-45-201.337-159.577Q180.001-383.308 180.001-516v-180.153q0-22.791 13.106-41.024t33.894-26.437l227.692-85q12.846-4.615 25.307-4.615 12.461 0 25.307 4.615l227.692 85q20.788 8.204 33.894 26.437 13.106 18.233 13.106 41.024V-516q0 132.692-74.808 247.268-74.807 114.577-201.337 159.577-5.623 2-11.623 3-6 1-12.231 1ZM480-164q104-33 172-132t68-220v-180.538q0-3.846-2.116-6.923-2.115-3.078-5.962-4.616l-227.691-85q-1.923-.769-4.231-.769t-4.231.769l-227.691 85q-3.847 1.538-5.962 4.616-2.116 3.077-2.116 6.923V-516q0 121 68 220t172 132Zm0-315.231Z" />
  </svg>
)

export const ShieldLock = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 -960 960 960">
    <path d="M680-280q25 0 42.5-17.5T740-340q0-25-17.5-42.5T680-400q-25 0-42.5 17.5T620-340q0 25 17.5 42.5T680-280Zm0 120q31.385 0 57.192-14.308 25.808-14.307 42.193-38.307Q757-226 732-233q-25-7-52-7t-52 7q-25 7-47.385 20.385 16.385 24 42.193 38.307Q648.615-160 680-160Zm0 59.999q-74.922 0-127.461-52.538Q500.001-205.078 500.001-280t52.538-127.461Q605.078-459.999 680-459.999t127.461 52.538Q859.999-354.922 859.999-280t-52.538 127.461Q754.922-100.001 680-100.001Zm-200-.77q-129.769-35.384-214.884-152.768Q180.001-370.924 180.001-516v-180.153q0-22.692 13.154-40.961 13.154-18.27 33.846-26.5l227.692-85q12.846-4.615 25.307-4.615 12.461 0 25.307 4.615l227.692 85q20.692 8.23 33.846 26.5 13.154 18.269 13.154 40.961v176.616q-21.385-9.923-48.5-15.192-27.115-5.27-51.499-5.27-107.922 0-183.961 76.038Q420.001-387.922 420.001-280q0 51.615 18.692 96.23t50.461 79.691q-2.538.77-4.577 1.654-2.038.885-4.577 1.654Z" />
  </svg>
)

export const Categories = (
  <svg style={{ flex: '0 0 100%' }} viewBox="0 0 256 256">
    <g
      style={{
        transform: 'scale(0.9)',
        transformOrigin: '50% 50%',
        strokeWidth: 4,
        stroke: 'white',
      }}
    >
      <path d="M246.66,123.56,201,55.13A15.94,15.94,0,0,0,187.72,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H187.72A16,16,0,0,0,201,200.88l45.63-68.44A8,8,0,0,0,246.66,123.56ZM187.72,192H40V64H187.72l42.66,64Z"></path>
    </g>
    <g
      style={{
        transform: 'scale(0.4) translateX(-20%) translateY(94%)',
        transformOrigin: '50% 50%',
        strokeWidth: 7,
        stroke: 'white',
      }}
    >
      <path d="M246.66,123.56,201,55.13A15.94,15.94,0,0,0,187.72,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H187.72A16,16,0,0,0,201,200.88l45.63-68.44A8,8,0,0,0,246.66,123.56ZM187.72,192H40V64H187.72l42.66,64Z"></path>
    </g>
    <g
      style={{
        transform: 'scale(0.4) translateX(-20%) translateY(-86%)',
        transformOrigin: '50% 50%',
        strokeWidth: 7,
        stroke: 'white',
      }}
    >
      <path d="M246.66,123.56,201,55.13A15.94,15.94,0,0,0,187.72,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H187.72A16,16,0,0,0,201,200.88l45.63-68.44A8,8,0,0,0,246.66,123.56ZM187.72,192H40V64H187.72l42.66,64Z"></path>
    </g>
  </svg>
)
