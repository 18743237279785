import { DocumentRole } from '@prisma/client'
import {
  cacheDocumentMember,
  useDocumentMemberQuery,
} from '~/client/dashboard/queries/document-member-queries'
import {
  useDocumentMembersQuery,
  useDocumentQuery,
} from '~/client/dashboard/queries/document-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
  useCommandMutation,
} from '~/commands/client-commands'
import { useDocumentModelCtx } from '~/commands/document/document-commands-client'

/**
 * DocumentMember client commands
 */

export const documentMemberCommandsClient = {
  SetRole: {
    getValidationContext({ sender, input, target, ctx, commandCtx }) {
      return {
        sender: {
          isOwner: sender.documentProfile?.role === DocumentRole.Owner,
        },
        target: {
          isSender: sender.organizationProfile?.id === target.memberId,
          currentRole: target.role,
          newRole: input.params.role ?? target.role,
        },
        meta: {
          documentPrivacy: ctx.document.privacy,
          documentOwnerCount: commandCtx.documentOwnerCount,
        },
      }
    },
    getAuthorizationContext({ sender, target, ctx }) {
      return {
        sender: {
          isJoined: Boolean(sender.documentProfile),
        },
        target: {},
        meta: {
          isDocumentRoot: target.id === ctx.organization.rootDocumentId,
        },
      }
    },
    onMutate: [
      (ctx, { params, key }) =>
        cacheDocumentMember(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          {
            updateDocumentMembers: true,
            updateOrganizationMemberDocumentProfiles: true,
          },
        ),
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.DocumentMember>

/**
 * DocumentMember command context
 */

export const documentMemberCommandContext = {
  SetRole({ input, target, ctx }) {
    const { documentMembers, isLoading, isError } =
      useDocumentMembersQuery({
        id: ctx.document?.id,
        organizationId: ctx.document?.organizationId,
      })
    const documentOwnerCount = documentMembers.filter(
      (x) => x.role === DocumentRole.Owner,
    ).length

    return {
      state: {
        failed: isError,
        isLoading,
      },
      ctx: { documentOwnerCount },
    }
  },
} satisfies ClientCommandContextDefinitions<ModelName.DocumentMember>

/**
 * DocumentMember client context
 */

export const useDocumentMemberModelCtx = ((key) => {
  const { ctx, sender, state } = useDocumentModelCtx({
    id: key.documentId,
    organizationId: key.organizationId,
  })

  const {
    documentMember: target,
    isLoading,
    isError,
  } = useDocumentMemberQuery(key)

  return {
    target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
      documentMember: target,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.DocumentMember>
