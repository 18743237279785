import { DocumentPrivacy, DocumentRole } from '@prisma/client'
import {
  ModelCommandsValidation,
  ModelName,
  authorized,
  invalid,
  unauthorized,
  valid,
  valueMatches,
} from '~/commands/base-commands'
import { DocumentAccess } from '~/schemas/document-member-schema'

const DocumentPrivacyValues = {
  [DocumentPrivacy.Open]: 1,
  [DocumentPrivacy.Protected]: 2,
  [DocumentPrivacy.Managed]: 3,
}

const DocumentRoleValues = {
  [DocumentRole.Default]: 1,
  [DocumentRole.Owner]: 2,
}

export const getHighestDocumentRole = (roles: Array<DocumentRole | null>) => {
  return roles.reduce(getHigherDocumentRole, null)
}

const getHigherDocumentRole = (
  role1: DocumentRole | null,
  role2: DocumentRole | null,
) => {
  const roleValue1 = role1 ? DocumentRoleValues[role1] : 0
  const roleValue2 = role2 ? DocumentRoleValues[role2] : 0
  return roleValue2 > roleValue1 ? role2 : role1
}

// TODO: Use this from within Validation methods
export const getMemberDocumentAccess = ({
  privacy,
  documentMemberRole,
  // documentTeamRoles,
}: {
  privacy: DocumentPrivacy // global
  documentMemberRole: DocumentRole | null // member
  // TODO: Uncomment when teams are available
  // documentTeamRoles: DocumentRole[] | null // team
}): DocumentAccess => {
  // TODO: Replace when available
  // const role = getHighestDocumentRole([documentMemberRole, ...documentTeamRoles])
  const role = getHigherDocumentRole(null, documentMemberRole)

  switch (privacy) {
    case DocumentPrivacy.Open: {
      return {
        edit: true,
        view: true,
      }
    }
    case DocumentPrivacy.Protected: {
      return {
        edit: Boolean(role),
        view: Boolean(role),
      }
    }
    case DocumentPrivacy.Managed: {
      return {
        edit: valueMatches([DocumentRole.Owner], role),
        view: Boolean(role),
      }
    }
    // case DocumentPrivacy.Private: {
    //   return {
    //     edit: valueMatches([DocumentRole.Owner], role),
    //     view: Boolean(role),
    //   }
    // }
  }
}

/**
 * Document commands validation
 */

export const documentValidation = {
  CreateDocument: {
    validate({ target, meta }) {
      // Prevent creating non-private document inside private document
      if (
        DocumentPrivacyValues[meta.newCategoryPrivacy] <
        DocumentPrivacyValues[target.privacy]
      ) {
        return invalid(`Not allowed inside ${target.privacy} parent`)
      }

      return valid()
    },
    authorize({ sender, target }) {
      switch (target.privacy) {
        case DocumentPrivacy.Protected: {
          if (!sender.isJoined) {
            return unauthorized(
              `Only members can add subcategories to this category`,
            )
          }
          break
        }
        case DocumentPrivacy.Managed: {
          if (!valueMatches([DocumentRole.Owner], sender.role)) {
            return unauthorized(
              'Only owners can add subcategories to this category',
            )
          }
          break
        }
        // case DocumentPrivacy.Private: {
        //   if (!valueMatches([DocumentRole.Owner], sender.role)) {
        //     return unauthorized(
        //       'Only owners can add subcategories to this category',
        //     )
        //   }
        //   break
        // }
      }
      return authorized()
    },
  },
  Delete: {
    validate({}) {
      return valid()
    },
    authorize({ sender, target }) {
      if (!sender.isJoined) {
        return unauthorized('Only members can delete')
      }
      if (
        valueMatches(
          [/* DocumentPrivacy.Private,*/ DocumentPrivacy.Managed],
          target.privacy,
        )
      ) {
        if (sender.role !== DocumentRole.Owner) {
          return unauthorized('Must be an owner to delete this category')
        }
      }
      if (target.isRoot) {
        return invalid('Cannot delete document root')
      }
      return authorized()
    },
  },
  UpdateProfile: {
    validate({ target, sender }) {
      if (!target.newParentId) {
        return unauthorized('Target category not found')
      }

      // Prevent creating non-private document inside private document
      if (
        DocumentPrivacyValues[target.newPrivacy] <
        DocumentPrivacyValues[target.newParentPrivacy]
      ) {
        return invalid(`Not allowed inside ${target.newParentPrivacy} parent`)
      }

      if (target.previousParentId !== target.newParentId) {
        // Check parent category permissions
        switch (target.newParentPrivacy) {
          case DocumentPrivacy.Protected: {
            if (!sender.isJoinedNewParent) {
              return unauthorized('Must be a member of the target category')
            }
            break
          }
          case DocumentPrivacy.Managed: {
            if (!valueMatches([DocumentRole.Owner], sender.targetParentRole)) {
              return unauthorized('Must be an owner of the target category')
            }
            break
          }
          // case DocumentPrivacy.Private: {
          //   if (!valueMatches([DocumentRole.Owner], sender.targetParentRole)) {
          //     return unauthorized('Must be an owner of the target category')
          //   }
          //   break
          // }
        }
      }

      // if (target.newPrivacy === DocumentPrivacy.Private) {
      //   if (target.isRoot) {
      //     return invalid('Root category cannot be made private')
      //   }
      // }

      // if (target.newPrivacy !== DocumentPrivacy.Private) {
      //   if (target.parentPrivacy === DocumentPrivacy.Private) {
      //     return invalid('Children of private categories must also be private')
      //   }
      // }

      return valid()
    },
    authorize({ sender, target }) {
      if (!sender.isJoined) {
        return unauthorized('Only members can edit')
      }
      if (target.isRoot) {
        return invalid('Cannot edit document root')
      }
      switch (target.privacy) {
        case DocumentPrivacy.Protected: {
          if (!sender.isJoined) {
            return unauthorized(
              `Only members can change this category's settings`,
            )
          }
          break
        }
        case DocumentPrivacy.Managed: {
          if (!valueMatches([DocumentRole.Owner], sender.role)) {
            return unauthorized(
              `Only owners can change this category's settings`,
            )
          }
          break
        }
        // case DocumentPrivacy.Private: {
        //   if (!valueMatches([DocumentRole.Owner], sender.role)) {
        //     return unauthorized(
        //       `Only owners can change this category's settings`,
        //     )
        //   }
        //   break
        // }
      }
      return authorized()
    },
  },
  AddParticipant: {
    validate({ target, sender, meta }) {
      if (target.isJoined) {
        return invalid(`Already a member`)
      }

      if (target.role === DocumentRole.Owner && !sender.isOwner) {
        return unauthorized(`Only owners can add other owners`)
      }

      if (!target.isSender) {
        if (!sender.isJoined) {
          return unauthorized(`Only members can add other members`)
        }

        if (
          valueMatches(
            [/* DocumentPrivacy.Private,  */ DocumentPrivacy.Managed],
            meta.documentPrivacy,
          )
        ) {
          if (!sender.isOwner) {
            return unauthorized(`Only owners can add members to this category`)
          }
        }
      } else {
        switch (meta.documentPrivacy) {
          case DocumentPrivacy.Protected: {
            return unauthorized(`This category requires an invitation`)
          }
          case DocumentPrivacy.Managed: {
            return unauthorized(`This category requires an invitation`)
          }
          // case DocumentPrivacy.Private: {
          //   return unauthorized(`This category requires an invitation`)
          // }
        }
      }

      return valid()
    },
    authorize({ sender, target, meta }) {
      return authorized()
    },
  },
  RemoveParticipant: {
    validate({ target, sender, meta }) {
      if (!target.isJoined) {
        return invalid(`Not a member`)
      }

      if (!target.isSender) {
        if (!sender.isOwner) {
          return unauthorized(`Only owners can remove other members`)
        }
      }

      if (meta.documentOwnerCount === 1 && target.isOwner) {
        if (target.isSender) {
          return invalid(`Cannot leave before adding another owner`)
        } else {
          return invalid(`Cannot remove the last owner of a category`)
        }
      }

      return valid()
    },
    authorize({ sender, target, meta }) {
      if (meta.isDocumentRoot) {
        return invalid(`Cannot leave root category`)
      }

      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.Document>
