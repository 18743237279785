/* eslint-disable */
import { color, ColorHelper } from 'csx'
import { NodeType } from '~/schemas/node-schema'
import { DefaultMantineColor } from '@mantine/core'
import { useDarkMode } from '~/client/dashboard/stores/GlobalStore'
import { useCallback } from 'react'

export { ColorHelper }
export { color }

type Color = `#${string}` | `hsl(${string})` | `rgb(${string})`

type WeightedColor = {
  0: Color
  100: Color
  200: Color
  300: Color
  400: Color
  500: Color
  600: Color
  700: Color
  800: Color
  900: Color
}

// Alias color helper for named import (e.g. Color.of())
export const of = color

export type ThemeColor = keyof typeof themeColors
export type SchemeColorPart = [ThemeColor, number?]

export const useThemeColor = () => {
  const isDarkMode = useDarkMode()
  const result = useCallback(
    (
      [lightModeColor, lightModeWeight]: SchemeColorPart,
      [darkModeColor, darkModeWeight]: SchemeColorPart = [
        lightModeColor,
        lightModeWeight,
      ],
      asHex: boolean = false,
    ): string => {
      let color = isDarkMode ? darkModeColor : lightModeColor ?? 0
      let weight = isDarkMode ? darkModeWeight : lightModeWeight ?? 0

      if (typeof weight === 'undefined') return color

      return asHex
        ? // @ts-ignore
          themeColors[color][String(weight * 100)]
        : `${color}.${weight}`
    },
    [isDarkMode],
  )

  return result
}

/**
 * Theme colors
 */

export const themeColors = {
  white: {
    0: '#FFFFFF',
    100: '#F1F3F5',
    200: '#E9ECEF',
    300: '#DEE2E6',
    400: '#CED4DA',
    500: '#ADB5BD',
    600: '#868E96',
    700: '#495057',
    800: '#343A40',
    900: '#212529',
  },
  black: {
    0: '#C1C2C5',
    100: '#A6A7AB',
    200: '#909296',
    300: '#5c5f66',
    400: '#373A40',
    500: '#2C2E33',
    600: '#25262b',
    700: '#1A1B1E',
    800: '#141517',
    900: '#000000',
  },
  gray: {
    0: '#F8F9FA',
    100: '#F1F3F5',
    200: '#E9ECEF',
    300: '#DEE2E6',
    400: '#CED4DA',
    500: '#ADB5BD',
    600: '#868E96',
    700: '#495057',
    800: '#343A40',
    900: '#212529',
  },
  red: {
    0: '#FCE8E8',
    100: '#F7BFBF',
    200: '#F29797',
    300: '#ED6E6E',
    400: '#E84545',
    500: '#E31C1C',
    600: '#B61616',
    700: '#881111',
    800: '#5B0B0B',
    900: '#2D0606',
  },
  orange: {
    0: '#FCF0E9',
    100: '#F6D6C1',
    200: '#F0BB99',
    300: '#EAA171',
    400: '#E48649',
    500: '#DE6C21',
    600: '#B2561A',
    700: '#854114',
    800: '#592B0D',
    900: '#2C1607',
  },
  yellow: {
    0: '#FBF5E9',
    100: '#F4E4C2',
    200: '#EDD39C',
    300: '#E6C175',
    400: '#DFB04E',
    500: '#D89F27',
    600: '#AD7F1F',
    700: '#825F17',
    800: '#563F10',
    900: '#2B2008',
  },
  green: {
    0: '#ECF8F2',
    100: '#CAECDA',
    200: '#A8E0C3',
    300: '#86D5AB',
    400: '#64C993',
    500: '#42BD7B',
    600: '#359763',
    700: '#28714A',
    800: '#1B4B31',
    900: '#0D2619',
  },
  teal: {
    0: '#ECF9F8',
    100: '#C9EDED',
    200: '#A7E2E1',
    300: '#84D7D5',
    400: '#61CCC9',
    500: '#3FC0BE',
    600: '#329A98',
    700: '#267372',
    800: '#194D4C',
    900: '#0D2626',
  },
  cyan: {
    0: '#EDF6F8',
    100: '#CBE5EB',
    200: '#AAD5DF',
    300: '#89C5D2',
    400: '#68B4C5',
    500: '#47A4B8',
    600: '#388394',
    700: '#2A626F',
    800: '#1C424A',
    900: '#0E2125',
  },
  blue: {
    0: '#f7faff',
    100: '#e4ecf1',
    200: '#90CAF9',
    300: '#64B6F7',
    400: '#39A1F4',
    500: '#0D8DF2',
    600: '#0A70C2',
    700: '#085491',
    800: '#053861',
    900: '#031C30',
  },
  purple: {
    0: '#F0ECF8',
    100: '#D4CAEC',
    200: '#B9A8E1',
    300: '#9D86D5',
    400: '#8264C9',
    500: '#6642BD',
    600: '#523597',
    700: '#3D2871',
    800: '#291B4B',
    900: '#140D26',
  },
  pink: {
    0: '#FBE9F2',
    100: '#F4C3DB',
    200: '#ED9CC4',
    300: '#E575AD',
    400: '#DE4F96',
    500: '#D7287E',
    600: '#AC2065',
    700: '#81184C',
    800: '#561033',
    900: '#2B0819',
  },
  dark: {
    0: '#C1C2C5',
    100: '#A6A7AB',
    200: '#909296',
    300: '#5c5f66',
    400: '#373A40',
    500: '#2C2E33',
    600: '#25262b',
    700: '#1A1B1E',
    800: '#141517',
    900: '#101113',
  },
  light: {
    0: 'hsl(217.5deg 100% 99.3%)',
    100: 'hsl(217.5deg 100% 98.4%)',
    200: 'hsl(217.5deg 90% 97.4%)',
    300: 'rgb(244 250 255)',
    // TODO: Remaining shades
    400: 'rgb(233 243 252)',
    500: 'rgb(225 233 241)',
    600: 'hsl(217.5deg 90% 97.4%)',
    700: 'hsl(217.5deg 90% 97.4%)',
    800: 'hsl(217.5deg 90% 97.4%)',
    900: 'hsl(217.5deg 90% 97.4%)',
  },
} satisfies Partial<{
  [color in DefaultMantineColor]: WeightedColor
}>

export const roleColors = {
  Admin: themeColors.orange,
  User: themeColors.cyan,
  Guest: themeColors.pink,
} as const

/**
 * General colors
 */

export const nodeColor = {
  dark: {
    Start: color(themeColors.green[600]).desaturate(0.2),
    End: color(themeColors.green[600]).desaturate(0.2),
    Decision: color(themeColors.yellow[600]).desaturate(0.2),
    Switch: color(themeColors.orange[600]).desaturate(0.2),
    Delay: color(themeColors.cyan[600]).desaturate(0.2),
    Document: color(themeColors.cyan[600]).desaturate(0.2),
    Data: color(themeColors.cyan[600]).desaturate(0.2),
    Step: color(themeColors.cyan[600]).desaturate(0.2),
    Subprocess: color(themeColors.cyan[600]).desaturate(0.2),
    SubdocStart: color(themeColors.green[600]).desaturate(0.2),
    SubdocEnd: color(themeColors.green[600]).desaturate(0.2),
  },
  light: {
    Start: color(themeColors.green[300]).lighten(0.2),
    End: color(themeColors.green[300]).lighten(0.2),
    Decision: color(themeColors.yellow[300]).lighten(0.2),
    Switch: color(themeColors.orange[300]).lighten(0.2),
    Delay: color(themeColors.cyan[300]).lighten(0.2),
    Document: color(themeColors.cyan[300]).lighten(0.2),
    Data: color(themeColors.cyan[300]).lighten(0.2),
    Step: color(themeColors.cyan[300]).lighten(0.2),
    Subprocess: color(themeColors.cyan[300]).lighten(0.2),
    SubdocStart: color(themeColors.green[300]).lighten(0.2),
    SubdocEnd: color(themeColors.green[300]).lighten(0.2),
  },
} as {
  [colorScheme in 'dark' | 'light']: { [type in NodeType]: ColorHelper }
}

export const types = Object.keys(themeColors) as ThemeColor[]

const weight =
  (type: keyof typeof themeColors) =>
  (weight: number): string => {
    const map = themeColors[type] as any
    if (map[weight]) return map[weight]

    // Find the first available weight greater than or equal to the provided weight
    const availableWeights = Object.keys(themeColors[type]).map(Number)
    const result = availableWeights.find((x) => weight <= x)

    // Return last color if none matches
    if (typeof result === 'number') {
      return map[result]
    } else {
      // @ts-ignore
      return map[availableWeights.slice(availableWeights.length - 1)[0]]
    }
  }

export const gray = weight('gray')
export const red = weight('red')
export const yellow = weight('yellow')
export const orange = weight('orange')
export const green = weight('green')
export const teal = weight('teal')
export const cyan = weight('cyan')
export const blue = weight('blue')
export const purple = weight('purple')
export const pink = weight('pink')
export const dark = weight('dark')
export const light = weight('light')
export const white = weight('white')
export const black = weight('black')

// Hashing helpers

const djb2 = (str: string) => {
  let hash = 5381
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i) /* hash * 33 + c */
  }
  return hash
}

export const hashStringToColor = (str: string) => {
  const hash = djb2(str)
  const r = (hash & 0xff0000) >> 16
  const g = (hash & 0x00ff00) >> 8
  const b = hash & 0x0000ff

  const _r = 0 + r.toString(16)
  const _g = 0 + g.toString(16)
  const _b = 0 + b.toString(16)

  return (
    '#' +
    _r.substring(_r.length - 2) +
    _g.substring(_g.length - 2) +
    _b.substring(_b.length - 2)
  )
}
