'use client'

import { SignIn } from '@clerk/clerk-react'
import { dark } from '@clerk/themes'
import { Title } from '@mantine/core'
import React from 'react'
import * as Color from '~/client/dashboard/components/global/colors'
import { useDarkMode } from '~/client/dashboard/stores/GlobalStore'
import { Column } from '~/client/shared/Layout.tsx'

export default function Login() {
  return (
    <Column w="100%" h="60vh" align="center" justify="center">
      <LoginForm />
    </Column>
  )
}

export const useLoginAppearance = () => {
  const isDarkMode = useDarkMode()

  return {
    baseTheme: isDarkMode ? dark : undefined,
    variables: {},
    layout: {
      privacyPageUrl: undefined,
      termsPageUrl: undefined,
      socialButtonsVariant: 'blockButton',
    },
    elements: {
      header: {
        display: 'none',
      },
      headerSubtitle: {
        display: 'none',
      },
      button: {
        height: 40,
        outline: isDarkMode ? `1px solid ${Color.dark(400)}` : 'none',
      },
      socialButtons: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: rem(200),
      },
      logoBox: {
        display: 'none',
      },
      cardBox: {
        border: 'none',
        boxShadow: 'none',
      },
      card: {
        boxShadow: 'none',
        background: 'transparent',
        border: 'none',
      },
      footer: {
        background: 'transparent',
        border: 'none',
      },
      footerActionLink: {
        color: isDarkMode ? Color.blue(400) : Color.blue(600),
      },
    },
  } as React.ComponentProps<typeof SignIn>['appearance']
}

export const LoginForm = () => {
  const appearance = useLoginAppearance()
  const searchParams = new URL(window.location.href).searchParams
  const next = searchParams.get('next') ?? '/dashboard'

  return (
    <Column align="center">
      <Title order={3}>Log in to About3</Title>
      <SignIn path="/login" appearance={appearance} fallbackRedirectUrl={next} />
    </Column>
  )
}
