import { DocumentRole } from '@prisma/client'
import {
  cacheDocumentMember,
  removeDocumentMemberFromCache,
  useDocumentMemberQuery,
} from '~/client/dashboard/queries/document-member-queries'
import {
  cacheDocument,
  getDocumentFromCache,
  removeDocumentFromCache,
  useDocumentMembersQuery,
  useDocumentQuery,
} from '~/client/dashboard/queries/document-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import {
  cacheOrganizationDocuments,
  cacheOrganizationProcesses,
  useOrganizationQuery,
} from '~/client/dashboard/queries/organization-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandContextDefinitions,
  ClientCommandDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { useOrganizationModelCtx } from '~/commands/organization/organization-commands-client'

/**
 * Document client commands
 */

export const documentCommandsClient = {
  CreateDocument: {
    getValidationContext({ target, ctx, input }) {
      return {
        sender: {},
        target: {
          privacy: target.privacy,
        },
        meta: {
          newCategoryPrivacy: input.params.privacy ?? target.privacy,
        },
      }
    },
    getAuthorizationContext({ sender, target, ctx }) {
      return {
        sender: {
          role: sender.documentProfile?.role,
          isJoined: Boolean(sender.documentProfile),
        },
        target: {
          privacy: target.privacy,
        },
        meta: {
          organizationType: ctx.organization.type,
        },
      }
    },
    onSuccess: [
      (ctx, { document }) => cacheDocument(ctx, document, document, false),
      (ctx, { documentMemberProfile }) =>
        cacheDocumentMember(ctx, documentMemberProfile, documentMemberProfile, {
          updateDocumentMembers: true,
          updateOrganizationMemberDocumentProfiles: true,
        }),
    ],
  },
  Delete: {
    getValidationContext(ctx) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          role: sender.documentProfile?.role,
          isJoined: Boolean(sender.documentProfile),
        },
        target: {
          privacy: target.privacy,
          isRoot: !target.parentId,
        },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key }) => {
        // Update all documents whose parent was deleted
        const document = getDocumentFromCache(ctx, key)
        if (!document) return

        return cacheOrganizationDocuments(
          ctx,
          (old) =>
            (old ?? []).map((x) =>
              x.parentId === document.id
                ? {
                    ...x,
                    parentId: document.parentId,
                  }
                : x,
            ),
          { id: key.organizationId },
          false,
        )
      },
      (ctx, { key }) => {
        const document = getDocumentFromCache(ctx, key)
        if (!document) return

        return cacheOrganizationProcesses(
          ctx,
          (old) =>
            (old ?? []).map((x) =>
              x.documentIds.includes(document.id)
                ? {
                    ...x,
                    documentIds: x.documentIds.filter(
                      (id) => id !== document.id,
                    ),
                  }
                : x,
            ),
          { id: key.organizationId },
          false,
        )
      },
      (ctx, { key }) => removeDocumentFromCache(ctx, key),
    ],
  },
  UpdateProfile: {
    getValidationContext({ input, target, commandCtx }) {
      const newPrivacy = input.params.privacy ?? target.privacy
      const newParentId = input.params.parentId ?? target.parentId

      return {
        sender: {
          isJoinedNewParent: Boolean(commandCtx.senderParentDocumentProfile),
          targetParentRole: commandCtx.senderParentDocumentProfile?.role,
        },
        target: {
          isRoot: !parent,
          newPrivacy,
          previousParentId: target.parentId,
          newParentId,
          newParentPrivacy: commandCtx.newParentDocument.privacy,
        },
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target, ctx }) {
      return {
        sender: {
          role: sender.documentProfile?.role,
          isJoined: Boolean(sender.documentProfile),
        },
        target: {
          isRoot: !parent,
          privacy: target.privacy,
        },
        meta: {
          organizationType: ctx.organization.type,
        },
      }
    },
    onMutate: [
      (ctx, { params, key }) =>
        cacheDocument(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          false,
        ),
    ],
  },
  AddParticipant: {
    getValidationContext({ sender, input, target, commandCtx }) {
      return {
        sender: {
          isJoined: Boolean(sender.documentProfile),
          isOwner: sender.documentProfile?.role === DocumentRole.Owner,
        },
        target: {
          isJoined: commandCtx.isTargetJoined,
          isSender: sender.organizationProfile?.id === input.params.memberId,
          role: input.params.role ?? DocumentRole.Default,
        },
        meta: {
          documentPrivacy: target.privacy,
        },
      }
    },
    getAuthorizationContext({}) {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    onSuccess: [
      (ctx, result) =>
        cacheDocumentMember(ctx, result, result, {
          updateDocumentMembers: true,
          updateOrganizationMemberDocumentProfiles: true,
        }),
    ],
  },
  RemoveParticipant: {
    getValidationContext({ sender, input, target, commandCtx }) {
      return {
        sender: {
          isJoined: Boolean(sender.documentProfile),
          isOwner: sender.documentProfile?.role === DocumentRole.Owner,
        },
        target: {
          isJoined: commandCtx.isTargetJoined,
          isSender: sender.organizationProfile?.id === input.params.memberId,
          isOwner: commandCtx.isTargetOwner,
        },
        meta: {
          documentPrivacy: target.privacy,
          documentOwnerCount: commandCtx.documentOwnerCount,
        },
      }
    },
    getAuthorizationContext({ target, ctx }) {
      return {
        sender: {},
        target: {},
        meta: {
          isDocumentRoot: target.id === ctx.organization.rootDocumentId,
        },
      }
    },
    onMutate: [
      (ctx, { key, params }) => {
        const document = getDocumentFromCache(ctx, key)!
        return removeDocumentMemberFromCache(ctx, {
          documentId: document.id,
          organizationId: document.organizationId,
          memberId: params.memberId,
        })
      },
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.Document>

/**
 * Document command context
 */

export const documentCommandContext = {
  CreateDocument() {},
  Delete() {},
  UpdateProfile({ input, sender, target, ctx }) {
    const newParentId = input.params.parentId ?? target?.parentId
    const {
      document: newParentDocument,
      isLoadingDocument: isLoadingParent,
      isLoadingDocumentError: isLoadingParentError,
    } = useDocumentQuery({
      id: newParentId ?? undefined,
      organizationId: ctx.organization?.id,
    })

    const {
      documentMember: senderParentDocumentProfile,
      isLoadingDocumentMember,
      isLoadingDocumentMemberError,
    } = useDocumentMemberQuery({
      memberId: sender.organizationProfile?.id,
      documentId: newParentDocument?.id,
      organizationId: input.key.organizationId,
    })

    return {
      state: {
        isLoading: isLoadingParent || isLoadingDocumentMember,
        failed:
          isLoadingParentError ||
          isLoadingDocumentMemberError ||
          (!isLoadingParent && !newParentDocument),
      },
      ctx: {
        newParentDocument: newParentDocument!,
        senderParentDocumentProfile,
      },
    }
  },
  AddParticipant({ input, target }) {
    const { documentMember, isLoading, isError } = useDocumentMemberQuery({
      documentId: target?.id,
      memberId: input.params.memberId,
      organizationId: input.key.organizationId,
    })
    return {
      state: {
        isLoading,
        failed: isError,
      },
      ctx: {
        isTargetJoined: Boolean(documentMember),
      },
    }
  },
  RemoveParticipant({ input, target }) {
    const { documentMember, isLoading, isError } = useDocumentMemberQuery({
      documentId: target?.id,
      memberId: input.params.memberId,
      organizationId: input.key.organizationId,
    })
    const { documentMembers } = useDocumentMembersQuery({
      id: target?.id,
      organizationId: target?.organizationId,
    })
    const documentOwnerCount = documentMembers.filter(
      (x) => x.role === DocumentRole.Owner,
    ).length

    return {
      state: {
        isLoading,
        failed: isError,
      },
      ctx: {
        isTargetJoined: Boolean(documentMember),
        isTargetOwner: documentMember?.role === DocumentRole.Owner,
        documentOwnerCount,
      },
    }
  },
} satisfies ClientCommandContextDefinitions<ModelName.Document>

/**
 * Document client context
 */

export const useDocumentModelCtx = ((key) => {
  const { ctx, sender, state } = useOrganizationModelCtx({
    id: key.organizationId,
  })

  const {
    document: target,
    isLoadingDocument,
    isLoadingDocumentError,
  } = useDocumentQuery(key)

  const {
    documentMember: senderDocumentProfile,
    isLoadingDocumentMember,
    isLoadingDocumentMemberError,
  } = useDocumentMemberQuery({
    memberId: sender.organizationProfile?.id,
    documentId: target?.id,
    organizationId: key.organizationId,
  })

  return {
    target,
    sender: {
      ...sender,
      documentProfile: senderDocumentProfile,
    },
    ctx: {
      ...ctx,
      document: target,
    },
    state: {
      isLoading:
        state.isLoading || isLoadingDocument || isLoadingDocumentMember,
      failed:
        state.failed || isLoadingDocumentError || isLoadingDocumentMemberError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.Document>
