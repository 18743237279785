import {
  useDocumentsWithProfileQuery,
  useProcessAccessQuery,
} from '~/client/dashboard/queries/helpers/composite-queries'
import { useProcessMemberProfileQuery } from '~/client/dashboard/queries/process-member-queries.tsx'
import {
  cacheProcess,
  useProcessQuery,
} from '~/client/dashboard/queries/process-queries'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandContextDefinitions,
  ClientCommandDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'
import { useOrganizationModelCtx } from '~/commands/organization/organization-commands-client'
import { union, xor } from '~/utils/logic'

/**
 * Process client commands
 */

export const processCommandContext = {
  SetCategories: ({ input, ctx, target, sender }) => {
    const allDocumentIds = union(
      input.params.documentIds ?? [],
      target?.documentIds ?? [],
    )

    const { documentMemberProfileMap, isLoading, isError } =
      useDocumentsWithProfileQuery({
        documentIds: allDocumentIds,
        organizationId: ctx.organization?.id,
        organizationMemberId: sender?.organizationProfile?.id,
      })

    return {
      state: {
        isLoading,
        failed: isError,
      },
      ctx: {
        documentMemberProfileMap,
      },
    }
  },
} satisfies ClientCommandContextDefinitions<ModelName.Process>

export const processCommandsClient = {
  SetCategories: {
    getValidationContext({ input, target, ctx, commandCtx }) {
      const changedDocumentIds = xor(
        input.params.documentIds ?? target.documentIds,
        target.documentIds,
      )
      const changedDocumentsAccess = commandCtx.documentMemberProfileMap
        .filter((x) => x.document && changedDocumentIds.includes(x.document.id))
        .map(({ document, memberProfile }) => {
          return document
            ? getMemberDocumentAccess({
                privacy: document.privacy,
                documentMemberRole: memberProfile?.role ?? null,
              })
            : { edit: false, view: false }
        })

      return {
        sender: {
          changedDocumentsAccess,
        },
        target: {},
        meta: {
          changedDocumentIds,
          organization: {
            rootDocumentId: ctx.organization.rootDocumentId,
          },
        },
      }
    },
    getAuthorizationContext({ sender, target, commandCtx }) {
      const initialDocumentsAccess = commandCtx.documentMemberProfileMap
        .filter((x) => x.document && target.documentIds.includes(x.document.id))
        .map(({ document, memberProfile }) => {
          return document
            ? getMemberDocumentAccess({
                privacy: document.privacy,
                documentMemberRole: memberProfile?.role ?? null,
              })
            : { edit: false, view: false }
        })

      return {
        sender: {
          role: sender.processProfile?.role ?? null,
          documentsAccess: initialDocumentsAccess,
        },
        target: { privacy: target.privacy },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key, params }) =>
        cacheProcess(
          ctx,
          (old) => {
            if (!old) return undefined
            return {
              ...old,
              documentIds: params.documentIds,
            }
          },
          key,
          false,
        ),
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.Process>

/**
 * Process client context
 */

export const useProcessModelCtx = ((key) => {
  const { ctx, sender, state } = useOrganizationModelCtx({
    id: key.organizationId,
  })

  const { process: target, isLoading, isError } = useProcessQuery(key)

  const {
    memberProfile: senderProcessProfile,
    isLoadingMemberProfile,
    isLoadingMemberProfileError,
  } = useProcessMemberProfileQuery({
    memberId: sender.organizationProfile?.id,
    processId: target?.id,
    organizationId: key.organizationId,
  })

  const senderProcessAccess = useProcessAccessQuery(key, senderProcessProfile)

  return {
    target,
    sender: {
      ...sender,
      processProfile: senderProcessProfile,
      processAccess: senderProcessAccess,
    },
    ctx: {
      ...ctx,
      process: target,
    },
    state: {
      isLoading: state.isLoading || isLoading || isLoadingMemberProfile,
      failed: state.failed || isError || isLoadingMemberProfileError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.Process>
