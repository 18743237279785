import { cacheOrganizationInvitations } from '~/client/dashboard/queries/organization-queries'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CacheRemoveFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { PartialRequired } from '~/client/globals'
import {
  OrganizationInvitationQueryKey,
  organizationInvitationQueryKeys,
} from '~/schemas/invitation-schema'
import { api } from '~/utils/api'
import { isMatch } from '~/utils/logic'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { ModelName } from '~/commands/base-commands'
import { InvitationRedemptionQueryKey } from '~/schemas/invitation-redemption-schema'
import { cacheInvitationRedemptions } from '~/client/dashboard/queries/invitation-queries'

export const useInvitationRedemptionQuery = (
  key: PartialRequired<InvitationRedemptionQueryKey>,
) => {
  const enabled = InvitationRedemptionQueryKey.safeParse(key).success
  const result = api.InvitationRedemption.get.useQuery(
    parseKey(key, InvitationRedemptionQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )
  const invitationRedemption = result?.data ?? null

  useCacheResult(result, cacheInvitationRedemption, () => key, false)

  return {
    ...result,
    invitationRedemption,
    isLoadingInvitationRedemption: result.isPending && enabled,
    isLoadingInvitationRedemptionError: Boolean(result?.isError),
  }
}

/**
 * Cache get
 */

export const getInvitationRedemptionFromCache = (
  ctx: ApiContext,
  key: InvitationRedemptionQueryKey,
) => {
  key = InvitationRedemptionQueryKey.parse(key)
  return ctx.InvitationRedemption.get.getData(key)
}

/**
 * Cache set
 */

export const cacheInvitationRedemption: CacheFn<
  ModelQueryKey['InvitationRedemption'],
  ClientModel['InvitationRedemption'],
  boolean
> = (ctx, data, key, itemOnly = false) => {
  key = InvitationRedemptionQueryKey.parse(key)
  const previous = getInvitationRedemptionFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.Invitation.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(
      ctx.Invitation.get,
      organizationInvitationQueryKeys,
      result,
      result,
    )

    if (!itemOnly) {
      cacheInvitationRedemptions(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (x.id === result.id ? result : x))
          }
        },
        { id: result.invitationId, organizationId: key.organizationId },
        true,
      )
    }
  }

  return {
    previous,
    revert: () => {
      cacheInvitationRedemption(ctx, previous, key, itemOnly)
    },
  }
}

/**
 * Cache remove
 */


