import { z } from 'zod'
import type { ModelCommandsSchema } from '~/commands/base-commands'

/**
 * Invitation commands schema
 */

export const invitationCommandsSchema = {
  Example: {
    summary: 'Do a thing',
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({}),
      output: z.literal(true),
      validate: {
        sender: {},
        target: {},
        meta: {},
      },
      authorize: {
        sender: {},
        target: {},
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to do thing',
    },
    variables: {
      execute: 'doThing',
      isPending: 'isDoingThing',
    },
  },
} as const satisfies ModelCommandsSchema
