import { OrganizationType, ProcessPrivacy, ProcessRole } from '@prisma/client'
import {
  ModelCommandsValidation,
  ModelName,
  authorized,
  invalid,
  unauthorized,
  valid,
  valueMatches,
} from '~/commands/base-commands'
import { ProcessAccess } from '~/schemas/process-member-schema'

const ProcessRoleValues = {
  [ProcessRole.Default]: 1,
  [ProcessRole.Viewer]: 2,
  [ProcessRole.Editor]: 3,
  [ProcessRole.Owner]: 4,
}

export const getHighestProcessRole = (roles: Array<ProcessRole | null>) => {
  return roles.reduce(getHigherProcessRole, null)
}

const getHigherProcessRole = (
  role1: ProcessRole | null,
  role2: ProcessRole | null,
) => {
  const roleValue1 = role1 ? ProcessRoleValues[role1] : 0
  const roleValue2 = role2 ? ProcessRoleValues[role2] : 0
  return roleValue2 > roleValue1 ? role2 : role1
}

export const getMemberProcessAccess = ({
  privacy,
  hasCategoryViewAccess,
  processMemberRole,
  // TODO: Uncomment when teams are available
  // processTeamRoles,
}: {
  privacy: ProcessPrivacy // global
  // User has view access to any category on the process
  hasCategoryViewAccess: boolean
  processMemberRole: ProcessRole | null // member
  // processTeamRoles: ProcessRole | null // team
}): ProcessAccess => {
  // TODO: Replace when available
  // const role = getHighestProcessRole([processMemberRole, ...processTeamRoles])
  const role = getHigherProcessRole(processMemberRole, null)

  switch (privacy) {
    case ProcessPrivacy.Open: {
      return {
        edit: true,
        view: true,
      }
    }
    case ProcessPrivacy.Protected: {
      return {
        edit:
          hasCategoryViewAccess ||
          valueMatches(
            [ProcessRole.Owner, ProcessRole.Editor, ProcessRole.Viewer],
            role,
          ),
        view:
          hasCategoryViewAccess ||
          valueMatches(
            [ProcessRole.Owner, ProcessRole.Editor, ProcessRole.Viewer],
            role,
          ),
      }
    }
    case ProcessPrivacy.Managed: {
      return {
        edit: valueMatches([ProcessRole.Owner], role),
        view:
          hasCategoryViewAccess ||
          valueMatches(
            [ProcessRole.Owner, ProcessRole.Editor, ProcessRole.Viewer],
            role,
          ),
      }
    }
  }
}

/**
 * Process commands validation
 */

export const processValidation = {
  SetCategories: {
    validate({ sender, target, meta }) {
      if (meta.changedDocumentIds.includes(meta.organization.rootDocumentId)) {
        return invalid('Cannot assign processes to root document')
      }

      const missingDocumentAccess = sender.changedDocumentsAccess.filter(
        (x) => !x.edit,
      )
      if (missingDocumentAccess.length > 0) {
        // TODO: Accurate messaging (sometimes you must be an owner)
        return unauthorized('Only members can add processes to this category')
      }

      return valid()
    },
    authorize({ sender, target }) {
      const hasCategoryViewAccess = sender.documentsAccess.some((x) => x.view)
      const processAccess = getMemberProcessAccess({
        privacy: target.privacy,
        processMemberRole: sender.role ?? null,
        hasCategoryViewAccess,
      })

      if (!processAccess.edit) {
        return unauthorized('Must have edit access for this process')
      }

      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.Process>
