'use client'

import { Text } from '@mantine/core'
import { TRPCClientError, TRPCClientErrorLike } from '@trpc/client'
import { UseTRPCQueryResult } from '@trpc/react-query/shared'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { ZodTypeAny, z } from 'zod'
import { RequestError } from '~/client/shared/ErrorPage'
import { ClientModel, ModelQueryKey } from '~/schemas'
import { api } from '~/utils/api'

// Organization should have a longer stale time but a
//  shorter cache time than child items.
//  - A longer stale time prevents over-fetching of
//  large globs of data that may not be needed.
//  - A shorter cache time ensures that we do not get the
//  waterfall fetching effect once data begins to invalidate.

export const CHILD_ITEM_STALE_TIME = 2 * 60 * 1000 // 2 minutes
export const CHILD_ITEM_CACHE_TIME = 24 * 60 * 60 * 1000 // 24 hours

export type ApiContext = ReturnType<typeof api.useUtils>

export const RETRY = (
  failureCount: number,
  error: TRPCClientErrorLike<any>,
) => {
  if (error instanceof TRPCClientError) {
    const code = error.data?.httpStatus
    if (code >= 500) return failureCount < 3
  }

  return false
}

export const getQueryErrorMessage = (error: unknown) => {
  let description: string | null = null
  if (error instanceof TRPCClientError && error.data) {
    const code = error.data.httpStatus
    let message = 'Unable to load content'
    if (error.data.code === 'NOT_FOUND') {
      message = 'Not Found'
      description =
        'The requested document might have been deleted, or access may have been revoked.'
    } else if (error.data.code === 'INTERNAL_SERVER_ERROR') {
      message = 'Server Error'
    }
    return { code, message, description }
  } else {
    return { code: 500, message: 'Server Error', description }
  }
}

export const QueryRequestError = ({
  error,
  children,
}: PropsWithChildren<{ error: unknown }>) => {
  const displayError = getQueryErrorMessage(error)

  return (
    <RequestError {...displayError}>
      {children || <Text ta="center">{displayError.description}</Text>}
    </RequestError>
  )
}

export type CacheFn<
  Key extends ModelQueryKey[keyof ModelQueryKey],
  Data,
  Options = {},
  U = Data | null | undefined,
> = (
  ctx: ApiContext,
  data: U | ((old?: U) => U),
  key: Key,
  options: Options,
) => {
  previous: U
  revert: () => void
}

export type CacheRemoveFn<
  Key extends ModelQueryKey[keyof ModelQueryKey],
  Data extends ClientModel[keyof ClientModel],
> = (
  ctx: ApiContext,
  key: Key,
) => {
  previous: Data | null | undefined
  revert: () => void
}

export const useCacheResult = <U extends UseTRPCQueryResult<any, any>, T>(
  result: U,
  cacheFn: T,
  key: (item: any) => unknown,
  options: any,
) => {
  const ctx = api.useUtils()
  const needsCache = useRef(false)

  useEffect(() => {
    if (needsCache.current) return
    needsCache.current = result.fetchStatus !== 'idle'
  }, [result])

  useEffect(() => {
    // Cache once query has resolved
    if (result.fetchStatus !== 'idle') return
    if (result?.data === undefined || !needsCache.current) return
    // @ts-ignore
    cacheFn(ctx, result.data, key?.(result.data), options)
    needsCache.current = false
  }, [result?.data])
}

export const parseKey = <T extends ZodTypeAny, U extends z.infer<T>>(
  key: any,
  schema: T,
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return schema.parse(key) as U
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return {} as U
  }
}

export const cacheEachKey = <T extends ZodTypeAny>(
  fn: { setData: (key: any, data: any) => void },
  schemas: T[],
  combinedKeys: any,
  data: any,
) => {
  schemas.forEach((x) => {
    try {
      fn.setData(x.parse(combinedKeys), data)
    } catch (e) {
      // Key will fail to cache if certain fields are not available
      //  This is expected. e.g. User { handle: null }
      //  While handle may act as an ID, it will not be cached unless it exists.
    }
  })
}

export type QueryOptions = {
  refetchOnMount?: boolean
  refetchOnWindowFocus?: boolean
}
