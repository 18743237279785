/**
 * Process
 */
import { toast } from '~/client/shared/Toast'
import { cacheNodeDetail } from '~/client/dashboard/queries/node-detail-queries'
import {
  cacheProcess,
  removeProcessFromCache,
} from '~/client/dashboard/queries/process-queries'
import { QuerySchema } from '~/schemas'
import { api } from '~/utils/api'

export const useUpdateRevisionProfile = () => {
  const ctx = api.useUtils()
  const result = api.Process.updateRevisionSettings.useMutation({
    onMutate(input) {
      // Validate input
      input = QuerySchema.Process.updateRevisionSettings.parse(input)

      const { id, organizationId } = input
      return cacheProcess(
        ctx,
        (old) => (old ? { ...old, ...input } : undefined),
        { id, organizationId },
        false,
      )
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to update process')
    },
  })

  return {
    ...result,
    updateProcess: result.mutate,
    isUpdatingProcess: result.isPending,
  }
}

// export const useReportProcessView = () => {
//   const ctx = api.useUtils()
//   const result = api.Process.reportView.useMutation({
//     onMutate(input) {
//       return cacheProcess(
//         ctx,
//         (old) =>
//           old
//             ? { ...old, views: old.views + 1, lastViewedAt: new Date() }
//             : undefined,
//         input,
//       )
//     },
//     onSuccess(result, input, context) {},
//     onError(err, input) {
//       // Note: Not necessary to revert
//     },
//   })

//   return {
//     ...result,
//     reportProcessView: result.mutate,
//     isReportingProcessView: result.isPending,
//   }
// }

export const useDeleteRevision = () => {
  const ctx = api.useUtils()
  const result = api.Process.deleteRevision.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Process.deleteRevision.parse(input)

      // TODO: Fetch updated process from a revision store
      // const { id, organizationId } = input
      // cacheProcess(ctx, { id, organizationId }, (old) =>
      //   old ? { ...old, activeRevisionId: input.revisionId } : undefined,
      // )

      return cacheProcess(
        ctx,
        (old) =>
          old
            ? {
                ...old,
                // @ts-ignore
                drafts: old.drafts.filter((x) => x.id !== input.revisionId),
                mainRevisionId: old.mainRevisionId!,
              }
            : undefined,
        { id: input.id, organizationId: input.organizationId },
        false,
      )
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to delete revision')
    },
  })

  return {
    ...result,
    deleteRevision: result.mutate,
    isDeletingRevision: result.isPending,
  }
}

export const useArchiveProcess = () => {
  const ctx = api.useUtils()
  const result = api.Process.archive.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Process.archive.parse(input)

      const { id, organizationId } = input
      return cacheProcess(
        ctx,
        (old) => (old ? { ...old, isArchived: true } : undefined),
        { id, organizationId },
        false,
      )
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to archive process')
    },
  })

  return {
    ...result,
    archiveProcess: result.mutate,
    isArchivingProcess: result.isPending,
  }
}

export const useUnarchiveProcess = () => {
  const ctx = api.useUtils()
  const result = api.Process.unarchive.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Process.unarchive.parse(input)

      const { id, organizationId } = input
      return cacheProcess(
        ctx,
        (old) => (old ? { ...old, isArchived: false } : undefined),
        { id, organizationId },
        false,
      )
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to unarchive process')
    },
  })

  return {
    ...result,
    unarchiveProcess: result.mutate,
    isUnarchivingProcess: result.isPending,
  }
}

export const useDeleteProcess = () => {
  const ctx = api.useUtils()
  const result = api.Process.delete.useMutation({
    onMutate(input) {
      // Validate input
      QuerySchema.Process.delete.parse(input)

      const { id, organizationId } = input
      return removeProcessFromCache(ctx, { id, organizationId })
    },
    onSuccess(result, input, context) {},
    onError(err, input, context) {
      context?.revert()
      toast.failure('Failed to delete process')
    },
  })

  return {
    ...result,
    deleteProcess: result.mutate,
    isDeletingProcess: result.isPending,
  }
}

export const useSetNodeDescription = () => {
  const ctx = api.useUtils()
  const result = api.Process.setNodeDescription.useMutation({
    onMutate(input) {
      // Validate input
      input = QuerySchema.Process.setNodeDescription.parse(input)

      const { nodeId, processId, revisionId, organizationId, description } =
        input

      return cacheNodeDetail(
        ctx,
        (old) =>
          old
            ? {
                ...old,
                description,
              }
            : undefined,
        { nodeId, processId, revisionId, organizationId },
        false,
      )
    },
    onError(err, input, context) {
      return context?.revert()
    },
  })

  return {
    ...result,
    setNodeDescription: result.mutate,
    isSettingNodeDescription: result.isPending,
  }
}
