import { Combobox } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Column, Row } from '~/client/shared/Layout'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  ItemSearch,
  ItemSearchProps,
  TreeSearchItem,
} from '~/client/dashboard/components/global/Search'
import {
  TreeItem,
  TreeView,
  buildDocumentFlatTree,
  hrefForTreeItem,
  toTreeItem,
} from '~/client/dashboard/components/global/TreeView'
import {
  useOrganizationDocumentsQuery,
  useOrganizationProcessesQuery,
} from '~/client/dashboard/queries/organization-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { uniqBy } from '~/utils/logic'
import { getProcessDateInfo } from '~/utils/model-logic'
import searchClasses from '../global/Search.module.css'

export const OrganizationSearchInput = ({
  ...props
}: Partial<ItemSearchProps<any>>) => {
  const vars = useThemeVars()
  const [data, setData] = useState<TreeItem[]>([])
  const [recentItems, setRecentItems] = useState<TreeItem[]>([])
  const organization = useOrganizationContext((x) => x.organization)
  const activeItems = useOrganizationContext((x) => x.activeItems)
  const { documents } = useOrganizationDocumentsQuery({
    id: organization.id,
  })
  const { processes } = useOrganizationProcessesQuery({
    id: organization.id,
  })

  useEffect(() => {
    if (!organization) return
    const data = buildDocumentFlatTree(
      organization.rootDocumentId,
      documents,
      processes,
      filter,
    ).map((x) => ({
      ...x,
      // Exclude path from process since there could be multiple paths
      path: x.type === 'Process' ? [] : x.path,
    }))
    setData(data)
  }, [documents, processes])

  useEffect(() => {
    if (!organization || data.length === 0) return
    // TODO: Sort by last viewed at
    const recentProcesses = processes
      .sort((p1, p2) => {
        const p1Dates = getProcessDateInfo(p2)
        const p2Dates = getProcessDateInfo(p1)

        return (
          (p1Dates.lastPublishedAt ?? p1Dates.createdAt).getTime() -
          (p2Dates.lastPublishedAt ?? p2Dates.createdAt).getTime()
        )
      })
      .map((item) => data.find((x) => x.data.id === item.id))
      .filter((item): item is TreeItem =>
        item ? !activeItems.some((x) => x.id === item.data.id) : false,
      )
      .slice(0, 4)
    setRecentItems(recentProcesses)
  }, [processes, activeItems, data, location])

  const filter = (x: TreeItem) =>
    !x.data.isArchived && x.id !== organization.rootDocumentId

  const listData = uniqBy(data, (x) => x.data.id).map((x) => ({
    ...x,
    id: x.data.id,
  }))

  if (!organization || data.length === 0) return null

  return (
    <ItemSearch
      dropdown={true}
      filter={filter}
      height={300}
      dropdownWidth={300}
      itemHref={(item) => hrefForTreeItem(organization.slug, item)}
      ItemIcon={({ item }) => (
        <TreeView.ItemIcon
          item={item.data}
          size={item.type === 'Category' ? 20 : 24}
        />
      )}
      {...props}
      input={{
        radius: 100,
        leftSection: <Icon size={20} name="PiMagnifyingGlass" />,
        ...props.input,
      }}
      data={listData}
    >
      {recentItems.length > 0 && (
        <Combobox.Options>
          <Combobox.Group
            label="Recent items"
            styles={{ groupLabel: { fontSize: vars.fontSizes.sm } }}
          >
            {recentItems.map((item) => (
              <Combobox.Option
                className={searchClasses.searchItemOption}
                value={item.id}
                key={item.id}
              >
                <TreeSearchItem
                  data={data}
                  item={item}
                  itemHref={(item) => hrefForTreeItem(organization.slug, item)}
                  ItemIcon={({ item }) => (
                    <TreeView.ItemIcon
                      item={item.data}
                      size={item.type === 'Category' ? 20 : 24}
                    />
                  )}
                />
              </Combobox.Option>
            ))}
          </Combobox.Group>
        </Combobox.Options>
      )}
    </ItemSearch>
  )
}
