import { useEffect, useRef } from 'react'

const mountIdStore = new Map<any, any>()

export const useDebugMount = (displayName: string, id?: any) => {
  const mountId = String(useRef(Math.random()).current)
  const uniqId = displayName + '-' + id
  const existing = mountIdStore.get(uniqId)

  useEffect(() => {
    return () => {
      mountIdStore.delete(uniqId)
    }
  }, [uniqId])

  if (existing && mountId !== existing) {
    console.log('Detected additional mount for component: ', uniqId)
  }

  mountIdStore.set(uniqId, mountId)
}
