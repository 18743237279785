import { api } from '~/utils/api'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  QueryOptions,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { RevisionQueryKey, revisionQueryKeys } from '~/schemas/revision-schema'
import { ClientModel, Model, ModelQueryKey } from '~/schemas'
import { cacheNodeDetail } from '~/client/dashboard/queries/node-detail-queries'
import { PartialRequired } from '~/client/globals'
import { cacheOrganizationRevisions } from '~/client/dashboard/queries/organization-queries'
import { cacheProcessRevisions } from '~/client/dashboard/queries/process-queries'
import { zip } from '~/utils/logic'

export const useRevisionQuery = (
  key: PartialRequired<RevisionQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = RevisionQueryKey.safeParse(key).success
  const result = api.Revision.get.useQuery(parseKey(key, RevisionQueryKey), {
    enabled,
    staleTime: CHILD_ITEM_STALE_TIME,
    gcTime: CHILD_ITEM_CACHE_TIME,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: RETRY,
    ...options,
  })
  useCacheResult(result, cacheRevision, () => key, {})

  const revision = result.data ?? null

  return {
    ...result,
    revision,
    isLoadingRevision: result.isPending && enabled,
    isLoadingRevisionError: Boolean(result?.isError),
  }
}

export const useRevisionContentQuery = (
  key: PartialRequired<RevisionQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = RevisionQueryKey.safeParse(key).success
  const result = api.Revision.getContent.useQuery(
    parseKey(key, RevisionQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )
  useCacheResult(result, cacheRevisionContent, () => key, {})

  const content = result.data ?? null

  return {
    ...result,
    content,
    isLoadingContent: result.isPending && enabled,
    isLoadingContentError: Boolean(result?.isError),
  }
}

export const useRevisionContentQueryList = (
  keys: PartialRequired<RevisionQueryKey>[] = [],
) => {
  const resultList = api.useQueries((t) =>
    keys.map((key) =>
      t.Revision.getContent(parseKey(key, RevisionQueryKey), {
        enabled: RevisionQueryKey.safeParse(key).success,
        staleTime: CHILD_ITEM_STALE_TIME,
        gcTime: CHILD_ITEM_CACHE_TIME,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: RETRY,
      }),
    ),
  )

  // TODO: Cache each result (this is not valid)
  // resultList.forEach(result => {
  //   useCacheResult(result, cacheRevisionContent, () => key, false)
  // })

  const isLoading = resultList.some((x) => x.isLoading)

  const resultMap = zip(
    keys.map((x) => x.id),
    resultList,
  ).map(([revisionId, result]) => ({
    revisionId,
    content: result?.data ?? null,
  }))

  return {
    contentResults: isLoading ? [] : resultMap,
    isLoadingContent: isLoading,
    isLoadingContentError: resultList.some((x) => x.isError),
  }
}

export const useRevisionNodeDetailsQuery = (
  key: PartialRequired<RevisionQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = RevisionQueryKey.safeParse(key).success
  const result = api.Revision.getNodeDetails.useQuery(
    parseKey(key, RevisionQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )

  useCacheResult(result, cacheNodeDetails, () => key, false)

  const nodeDetails = result?.data ?? []

  return {
    ...result,
    nodeDetails,
    isLoadingNodeDetails: result.isPending && enabled,
    isLoadingNodeDetailsError: Boolean(result?.isError),
  }
}

/**
 * Cache get
 */

export const getRevisionFromCache = (
  ctx: ApiContext,
  key: RevisionQueryKey,
) => {
  key = RevisionQueryKey.parse(key)
  return ctx.Revision.get.getData(key)
}

export const getRevisionContentFromCache = (
  ctx: ApiContext,
  key: RevisionQueryKey,
) => {
  key = RevisionQueryKey.parse(key)
  return ctx.Revision.getContent.getData(key)
}

export const getNodeDetailsFromCache = (
  ctx: ApiContext,
  key: RevisionQueryKey,
) => {
  key = RevisionQueryKey.parse(key)
  return ctx.Revision.getNodeDetails.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheRevision: CacheFn<
  ModelQueryKey['Revision'],
  ClientModel['Revision'],
  {
    updateProcessRevisions: boolean
    updateOrganizationRevisions: boolean
  }
> = (
  ctx,
  data,
  key,
  { updateProcessRevisions = true, updateOrganizationRevisions = true },
) => {
  key = RevisionQueryKey.parse(key)
  const previous = getRevisionFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.Revision.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(ctx.Revision.get, revisionQueryKeys, result, result)

    if (updateProcessRevisions) {
      cacheProcessRevisions(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            old = [...old, result]
          } else {
            old = old.map((x) => (x.id === result.id ? result : x))
          }

          return old
        },
        { id: key.processId, organizationId: key.organizationId },
        true,
      )
    }

    if (updateOrganizationRevisions) {
      cacheOrganizationRevisions(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            old = [...old, result]
          } else {
            old = old.map((x) => (x.id === result.id ? result : x))
          }

          return old
        },
        { id: key.organizationId },
        true,
      )
    }
  }

  return {
    previous,
    revert: () => {
      cacheRevision(ctx, previous, key, {
        updateProcessRevisions: updateProcessRevisions,
        updateOrganizationRevisions,
      })
    },
  }
}

export const cacheRevisionContent: CacheFn<
  ModelQueryKey['Revision'],
  Model['Revision']['content']
> = (ctx, data, key) => {
  key = RevisionQueryKey.parse(key)
  const previous = getRevisionContentFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.Revision.getContent.setData(key, null)
  }

  if (result) {
    ctx.Revision.getContent.setData(key, result)
  }

  return {
    previous,
    revert: () => {
      cacheRevisionContent(ctx, previous, key, {})
    },
  }
}

export const cacheNodeDetails: CacheFn<
  ModelQueryKey['Revision'],
  ClientModel['NodeDetail'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = RevisionQueryKey.parse(key)
  const previous = getNodeDetailsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const revision = getRevisionFromCache(ctx, key)
    cacheEachKey(
      ctx.Revision.getNodeDetails,
      revisionQueryKeys,
      revision,
      result,
    )

    if (!listOnly) {
      result.forEach((x) => {
        cacheNodeDetail(ctx, x, x, true)
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheNodeDetails(ctx, previous, key, listOnly)
    },
  }
}
