import { ProcessRole } from '@prisma/client'
import { z } from 'zod'
import { ModelCommandsSchema } from '~/commands/base-commands'
import { DocumentAccess } from '~/schemas/document-member-schema'
import { DocumentSchema } from '~/schemas/document-schema'
import { ProcessSchema } from '~/schemas/process-schema'
import { RevisionSchema } from '~/schemas/revision-schema'

/**
 * Process commands schema
 */

export const processCommandsSchema = {
  SetCategories: {
    summary: `Assign categories to process`,
    description: ``,
    method: 'POST',
    schemas: {
      input: z.object({
        documentIds: z.array(z.string()),
      }),
      output: z.literal(true),
      validate: {
        sender: {
          changedDocumentsAccess: z.array(DocumentAccess),
        },
        target: {},
        meta: {
          changedDocumentIds: z.array(DocumentSchema.shape.id),
          organization: z.object({
            rootDocumentId: DocumentSchema.shape.id,
          }),
        },
      },
      authorize: {
        sender: {
          role: z.nativeEnum(ProcessRole).nullable(),
          documentsAccess: z.array(DocumentAccess),
        },
        target: {
          privacy: ProcessSchema.shape.privacy,
        },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update process categories',
    },
    variables: {
      execute: 'setProcessCategories',
      isPending: 'isSettingProcessCategories',
    },
  },
} as const satisfies ModelCommandsSchema
