import { useMantineTheme } from '@mantine/core'
import { themeToVars } from '@mantine/vanilla-extract'

let vars: any

export const useThemeVars = () => {
  const theme = useMantineTheme()

  if (vars) return vars as typeof newVars

  // Hack to keep types in place
  const newVars = themeToVars(theme)
  vars = newVars
  return newVars
}
