import { cacheDocumentMember } from '~/client/dashboard/queries/document-member-queries'
import { cacheOrganizationDocuments } from '~/client/dashboard/queries/organization-queries'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CacheRemoveFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  QueryOptions,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { PartialRequired } from '~/client/globals'
import { BootstrapDocument, ClientModel, ModelQueryKey } from '~/schemas'
import { DocumentQueryKey, documentQueryKeys } from '~/schemas/document-schema'
import { api } from '~/utils/api'
import { isMatch } from '~/utils/logic'

export const useDocumentQuery = (
  key: PartialRequired<DocumentQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = DocumentQueryKey.safeParse(key).success
  const result = api.Document.get.useQuery(parseKey(key, DocumentQueryKey), {
    enabled,
    staleTime: CHILD_ITEM_STALE_TIME,
    gcTime: CHILD_ITEM_CACHE_TIME,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: RETRY,
    ...options,
  })
  const document = result?.data ?? null

  useCacheResult(result, cacheDocument, () => key, false)

  return {
    ...result,
    document,
    isLoadingDocument: result.isPending && enabled,
    isLoadingDocumentError: Boolean(result?.isError),
  }
}

export const useDocumentMembersQuery = (
  key: PartialRequired<DocumentQueryKey>,
  options: QueryOptions = {},
) => {
  const result = api.Document.getMembers.useQuery(
    parseKey(key, DocumentQueryKey),
    {
      enabled: DocumentQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )

  useCacheResult(result, cacheDocumentMembers, () => key, false)

  return { ...result, documentMembers: result.data ?? [] }
}

/**
 * Cache get
 */

export const getDocumentFromCache = (
  ctx: ApiContext,
  key: DocumentQueryKey,
) => {
  key = DocumentQueryKey.parse(key)
  return ctx.Document.get.getData(key)
}

export const getDocumentMembersFromCache = (
  ctx: ApiContext,
  key: DocumentQueryKey,
) => {
  key = DocumentQueryKey.parse(key)
  return ctx.Document.getMembers.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheBootstrapDocument: CacheFn<
  ModelQueryKey['Document'],
  BootstrapDocument
> = (ctx, data, key) => {
  key = DocumentQueryKey.parse(key)
  const result = typeof data === 'function' ? data() : data

  if (result === null) {
    ctx.Document.get.setData(key, null)
  }

  if (result) {
    // Cache all documents
    cacheDocument(ctx, ClientModel.Document.parse(result), key, false)

    // Cache all document members
    cacheDocumentMembers(ctx, result.members, key, false)
  }

  return {
    previous: undefined,
    revert: () => {
      cacheBootstrapDocument(ctx, undefined, key, {})
    },
  }
}

export const cacheDocument: CacheFn<
  ModelQueryKey['Document'],
  ClientModel['Document'],
  boolean | undefined
> = (ctx, data, key, itemOnly = false) => {
  key = DocumentQueryKey.parse(key)
  const previous = getDocumentFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    cacheEachKey(ctx.Document.get, documentQueryKeys, result, result)

    if (!itemOnly) {
      cacheOrganizationDocuments(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            old = [...old, result]
          } else {
            old = old.map((x) => (x.id === result.id ? result : x))
          }

          return old
        },
        { id: key.organizationId },
        true,
      )
    }
  }
  return {
    previous,
    revert: () => {
      cacheDocument(ctx, previous, key, itemOnly)
    },
  }
}

export const cacheDocumentMembers: CacheFn<
  ModelQueryKey['Document'],
  ClientModel['DocumentMember'][],
  boolean | undefined
> = (ctx, data, key, listOnly = false) => {
  key = DocumentQueryKey.parse(key)
  const previous = getDocumentMembersFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const document = getDocumentFromCache(ctx, key)
    cacheEachKey(ctx.Document.getMembers, documentQueryKeys, document, result)

    if (!listOnly) {
      result.forEach((x) => {
        cacheDocumentMember(ctx, x, x, {
          updateDocumentMembers: false,
          updateOrganizationMemberDocumentProfiles: true,
        })
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheDocumentMembers(ctx, previous, key, listOnly)
    },
  }
}

/**
 * Cache remove
 */

export const removeDocumentFromCache: CacheRemoveFn<
  ModelQueryKey['Document'],
  ClientModel['Document']
> = (ctx, key) => {
  key = DocumentQueryKey.parse(key)
  const previous = getDocumentFromCache(ctx, key)

  if (previous) {
    cacheEachKey(ctx.Document.get, documentQueryKeys, previous, null)
  }

  const { revert } = cacheOrganizationDocuments(
    ctx,
    (old) => {
      if (!old) return
      return old.filter((x) => !isMatch(x, key))
    },
    { id: key.organizationId },
    true,
  )

  return {
    previous,
    revert,
  }
}
