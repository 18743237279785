'use client'

import {
  ActionIcon,
  Button,
  Center,
  Combobox,
  Divider,
  Indicator,
  Input,
  Menu,
  Pill,
  ScrollArea,
  Text,
  Textarea,
  Tooltip,
  Transition,
  useCombobox,
} from '@mantine/core'
import { useElementSize, useLocalStorage } from '@mantine/hooks'
import dayjs from 'dayjs'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { TextEditor } from '~/client/dashboard/components/global/TextEditor'
import { DiagramNodeContext } from '~/client/dashboard/components/process/diagram/DiagramNodeWrapper'
import {
  ChooseNodeTypeMenu,
  Nodes,
  getPlaceholderForNode,
} from '~/client/dashboard/components/process/node/NodeComponents'
import { useSetNodeDescription } from '~/client/dashboard/mutations/process-mutations'
import { useNodeDetailQuery } from '~/client/dashboard/queries/node-detail-queries'
import { useDiagramContext } from '~/client/dashboard/stores/DiagramStore'
import { DiagramViewMode } from '~/client/dashboard/stores/DiagramStore'
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import {
  RevisionDraftItem,
  useProcessContext,
  useProcessVersionsQuery,
  useRootProcessContext,
} from '~/client/dashboard/stores/ProcessStore'
import {
  useProcessAction,
  useRevisionContext,
} from '~/client/dashboard/stores/RevisionStore'
import { Box, Column, Row } from '~/client/shared/Layout'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import { NodeType, TITLE_MAX_LENGTH } from '~/schemas/node-schema'
import classes from './NodePane.module.css'
import { ProcessIcon } from '~/client/dashboard/components/process/ProcessComponents'
import { DeviceSize } from '~/client/shared/hooks/useDeviceSize'
import {
  useDarkMode,
  useDeviceSize,
  useGlobalContext,
} from '~/client/dashboard/stores/GlobalStore'

export const NodePaneWrapper = () => {
  const currentNodeId = useDiagramContext((x) => x.currentNodeId)
  const [isFirst, setIsFirst] = useState(Boolean(currentNodeId))
  let { ref, height, width = 0 } = useElementSize()
  const deviceSize = useDeviceSize()
  const [sizes, setSizes] = useState(calculateSizes(deviceSize, width))
  const processId = useRootProcessContext((x) => x.id)
  const [opened, setOpened] = useLocalStorage<boolean>({
    key: 'node-pane-opened-' + processId,
    defaultValue: true,
  })

  useEffect(() => {
    setIsFirst(false)
  }, [isFirst])

  // The useElementSize hook sometimes does not return a value when it should
  width = width > 0 ? width : ref.current?.getBoundingClientRect().width ?? 0

  useEffect(() => {
    if (width === 0) return
    setSizes(calculateSizes(deviceSize, width))
  }, [deviceSize, width])

  return (
    <Box
      className={classes.nodePane}
      ref={ref}
      w="100%"
      h="100%"
      pos="absolute"
      top={'6vh'}
      left={0}
      style={{
        zIndex: 1,
        pointerEvents: 'none',
        opacity: isFirst ? 0 : 1,
        transitionDelay: '1000ms',
        transitionDuration: '500ms',
        transitionProperty: 'opacity',
        transitionTimingFunction: 'ease',
      }}
    >
      {currentNodeId && (
        <Box key={currentNodeId} onClick={(e) => e.stopPropagation()}>
          <DiagramNodeContext globalId={currentNodeId}>
            <NodePane sizes={sizes} setOpened={setOpened} opened={opened} />
          </DiagramNodeContext>
        </Box>
      )}
    </Box>
  )
}

const calculateSizes = (deviceSize: DeviceSize, width: number) => {
  const sizes = {
    pane: Math.min(width - 14, Math.max(460, Math.min(0.3 * width)), 640),
    topHeight: deviceSize.isSmall ? 58 : deviceSize.isXL ? 84 : 70,
    topPaddingLeft: 8,
    topPaddingRight: 4,
    thumb: deviceSize.isSmall ? 90 : deviceSize.isXL ? 140 : 112,
    svgWidth: deviceSize.isSmall ? 60 : deviceSize.isXL ? 100 : 80,
    svgHeight: deviceSize.isSmall ? 30 : deviceSize.isXL ? 50 : 40,
    svgMarginRight: 16,
    closeIcon: 32,
    closeIconMarginLeft: deviceSize.isSmall ? 4 : 12,
    closeIconMarginRight: 0,
    buttonSize: deviceSize.isSmall ? 40 : deviceSize.isXL ? 56 : 46,
    bottomGap: deviceSize.isSmall ? 4 : deviceSize.isXL ? 8 : 6,
    bottomMarginLeft: deviceSize.isSmall ? 0 : deviceSize.isXL ? 14 : 6,
    contentMargin: 4,
    caret: 14,
    caretMargin: deviceSize.isSmall ? 2 : deviceSize.isXL ? 8 : 4,
  }

  const additionalContentSize = deviceSize.isSmall ? 4 : 0

  // Include derived values
  return {
    isReady: width > 0,
    ...sizes,
    title:
      sizes.pane -
      sizes.svgWidth -
      sizes.svgMarginRight -
      sizes.topPaddingLeft -
      sizes.topPaddingRight -
      sizes.closeIcon -
      sizes.closeIconMarginLeft -
      sizes.closeIconMarginRight,
    titleHeight: sizes.topHeight - 8,
    contentSize:
      sizes.pane +
      additionalContentSize -
      sizes.bottomMarginLeft -
      (36 + sizes.buttonSize + sizes.bottomGap),
  }
}

const colors = {
  main: {
    light: Color.gray(0),
    dark: Color.dark(500),
  },
}

enum ActivePanel {
  Description,
  Attachments,
  Comments,
}

export const NodePane = ({
  sizes,
  opened,
  setOpened,
}: {
  sizes: ReturnType<typeof calculateSizes>
  opened: boolean
  setOpened: (opened: boolean) => void
}) => {
  const vars = useThemeVars()
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const getNodeColor = useGlobalContext((x) => x.getNodeColor)
  const getNodeStrokeColor = useGlobalContext((x) => x.getNodeStrokeColor)
  const queryKey = useRevisionContext((x) => x.queryKey)
  const id = useNodeContext((x) => x.id)
  const globalId = useNodeContext((x) => x.globalId)
  const subprocessId = useNodeContext((x) => x.subprocessId)
  const type = useNodeContext((x) => x.type)
  const treePath = useProcessContext((x) => x.treePath)
  const isRoot = useNodeContext((x) => x.isRoot)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const processId = useRootProcessContext((x) => x.id)
  const [activePanel, setActivePanel] = useLocalStorage<ActivePanel | null>({
    key: 'node-active-panel-' + processId,
    defaultValue: null,
  })
  const title = useNodeContext((x) => x.title)
  const viewMode = useDiagramContext((x) => x.viewMode)
  // const lastNode = useRef<ListNode>()
  const [initialTitle, setInitialTitle] = useState(title)
  const { setStepTitle } = useProcessAction(queryKey, 'setStepTitle')
  const [hasDescription, setHasDescription] = useState(false)
  const isFirstNode = isRoot && treePath.length === 0

  useEffect(() => {
    setInitialTitle(title)
  }, [title])

  const handlePanelSelected = useCallback(
    (panel: ActivePanel) => {
      const active = activePanel === panel && opened ? null : panel
      setActivePanel(active)
      if (active !== null) {
        setOpened(true)
      }
    },
    [activePanel, opened, setOpened],
  )

  const placeholder = useMemo(() => getPlaceholderForNode(type), [type, id])

  const SVG = Nodes[type]
  const contentOpened = opened && activePanel !== undefined
  const openActivePanel = opened ? activePanel! : null
  const color = getNodeColor(type)
  const strokeColor = getNodeStrokeColor(color)
  const isEditing = viewMode === DiagramViewMode.Editing
  const isEditingTitle =
    isEditing && ![NodeType.Start, NodeType.Subprocess].includes(type)

  return (
    <Column w={sizes.pane} align="flex-start">
      <Row gap="md" w="100%">
        {/* Top */}
        <Row
          className={classes.thumb}
          data-opened={opened}
          py="sm"
          pl={sizes.topPaddingLeft}
          pr={sizes.topPaddingRight}
          h={sizes.topHeight}
          w={opened ? '100%' : '0%'}
          miw={sizes.thumb}
          pos="relative"
          onClick={() => {
            if (!opened) {
              setOpened(true)
            }
          }}
          style={{
            cursor: opened ? 'default' : 'pointer',
            borderTopRightRadius: 100,
            borderBottomRightRadius: 100,
            borderWidth: 1,
            borderColor: isDarkMode ? Color.dark(300) : Color.gray(300),
            borderStyle: 'solid',
            borderLeft: 'none',
            // boxShadow: 'var(--mantine-shadow-md)',
            pointerEvents: 'auto',
            transition: '150ms ease all',
          }}
        >
          <Row
            onClick={() => {
              if (opened) {
                setOpened(false)
              }
            }}
            mr={sizes.svgMarginRight}
            style={{ cursor: 'pointer' }}
          >
            {!subprocessId && (
              <SVG
                width={sizes.svgWidth}
                height={sizes.svgHeight}
                stroke={strokeColor.toString()}
                strokeWidth={4}
              />
            )}
            {subprocessId && (
              <Center w={sizes.svgWidth} h={sizes.svgHeight}>
                <ProcessIcon
                  size={sizes.svgHeight}
                  id={subprocessId}
                  revisionId={null}
                />
              </Center>
            )}
            {!opened && (
              <Box
                className={classes.caret}
                ml={sizes.caretMargin}
                w={sizes.caret}
              >
                <Icon
                  name="PiCaretRightBold"
                  c={isDarkMode ? 'dark.3' : 'gray.2'}
                />
              </Box>
            )}
          </Row>
          <Row
            className={classes.title}
            w={opened ? sizes.title : 0}
            h={100}
            opacity={opened ? (title || isEditing ? 1 : 0.5) : 0}
          >
            <Row h="100%" style={{ width: sizes.title }} shrink={0}>
              {!isEditingTitle && (title || 'Empty step')}
              {isEditingTitle && (
                <Textarea
                  ref={inputRef}
                  placeholder={placeholder}
                  defaultValue={title}
                  tabIndex={1}
                  variant="unstyled"
                  autosize={true}
                  style={{ width: sizes.title }}
                  classNames={{
                    root: classes.titleEditor,
                    wrapper: classes.titleEditor__wrapper,
                    input: `${classes.titleEditor__input} ${classes.title}`,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.currentTarget.blur()
                    }
                  }}
                  onChange={(e) => {}}
                  onBlur={(e) => {
                    const title = e.target.value
                    if (title === initialTitle) return
                    setStepTitle({
                      id,
                      title,
                    })
                  }}
                  maxLength={TITLE_MAX_LENGTH}
                />
              )}
            </Row>
          </Row>
          {opened && (
            <ActionIcon
              color={isDarkMode ? colors.main.dark : colors.main.light}
              c={isDarkMode ? vars.colors.text : Color.gray(800)}
              tabIndex={4}
              radius="50%"
              size={sizes.closeIcon}
              onClick={() => {
                setOpened(false)
              }}
              ml={sizes.closeIconMarginLeft}
            >
              <Icon
                name="PiCaretLeftBold"
                size={14}
                style={{ pointerEvents: 'auto' }}
              />
            </ActionIcon>
          )}
          <Box
            style={{ position: 'absolute', bottom: '100%', left: 0 }}
            onClick={(e) => e.stopPropagation()}
          >
            <RevisionDropdown />
          </Box>
        </Row>
      </Row>
      {/* Bottom */}
      <Row ml={sizes.bottomMarginLeft} gap={sizes.bottomGap} align="flex-start">
        {/* Buttons */}
        <Column
          ml="sm"
          mt="sm"
          mb="sm"
          gap="sm"
          // style={{ transition: '150ms ease all' }}
        >
          <NodePanelButton
            size={sizes.buttonSize}
            activePanel={activePanel}
            openActivePanel={openActivePanel}
            panelName={ActivePanel.Description}
            indicator={hasDescription}
            onClick={handlePanelSelected}
          >
            <Icon name="PiArticle" size={deviceSize.isSmall ? 20 : 24} />
          </NodePanelButton>
          <NodePanelButton
            size={sizes.buttonSize}
            activePanel={activePanel}
            openActivePanel={openActivePanel}
            panelName={ActivePanel.Attachments}
            // TODO: # Attachments
            indicator={false}
            onClick={handlePanelSelected}
          >
            <Icon name="PiPaperclip" size={deviceSize.isSmall ? 20 : 24} />
          </NodePanelButton>
          <NodePanelButton
            size={sizes.buttonSize}
            activePanel={activePanel}
            openActivePanel={openActivePanel}
            panelName={ActivePanel.Comments}
            onClick={handlePanelSelected}
          >
            <Icon
              name="PiChatsCircle"
              nudgeUp={1}
              size={deviceSize.isSmall ? 20 : 24}
            />
          </NodePanelButton>
          {viewMode === DiagramViewMode.Editing && !isFirstNode && (
            <NodePaneDropdownButton nodeId={globalId} size={sizes.buttonSize} />
          )}
        </Column>

        {/* Content */}
        <Box
          mt="sm"
          ml={sizes.contentMargin}
          w={sizes.contentSize}
          pos="relative"
        >
          <NodePanelContent
            active={openActivePanel === ActivePanel.Description}
          >
            <NodePanelDescription
              isEditing={viewMode === DiagramViewMode.Editing}
              onChange={(description) =>
                setHasDescription(Boolean(description))
              }
            />
          </NodePanelContent>
          <NodePanelContent
            active={openActivePanel === ActivePanel.Attachments}
          >
            <NodePanelAttachments />
          </NodePanelContent>
          <NodePanelContent active={openActivePanel === ActivePanel.Comments}>
            <NodePanelComments />
          </NodePanelContent>
        </Box>
      </Row>
    </Column>
  )
}

type NodePanelButtonProps = {
  panelName: ActivePanel
  size: number
  openActivePanel: ActivePanel | null
  activePanel: ActivePanel | null
  indicator?: string | number | boolean
  onClick: (panel: ActivePanel) => void
  children: ReactNode
}
const NodePanelButton = ({
  panelName,
  size,
  openActivePanel,
  activePanel,
  indicator,
  onClick,
  children,
}: NodePanelButtonProps) => {
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const vars = useThemeVars()
  const label = typeof indicator === 'boolean' ? null : indicator

  return (
    <Indicator
      inline
      disabled={
        indicator === undefined ||
        indicator === false ||
        openActivePanel === panelName
      }
      label={label}
      size={label ? 14 : 13}
      offset={deviceSize.isSmall ? 6 : 3}
    >
      <ActionIcon
        color={
          openActivePanel === panelName
            ? 'blue.5'
            : activePanel === panelName
              ? isDarkMode
                ? 'dark.3'
                : 'white.0'
              : isDarkMode
                ? 'dark.5'
                : 'gray.0'
        }
        c={
          openActivePanel === panelName
            ? 'white'
            : isDarkMode
              ? vars.colors.text
              : Color.gray(800)
        }
        tabIndex={3}
        radius="50%"
        size={size}
        style={{
          pointerEvents: 'auto',
          border:
            openActivePanel === panelName
              ? 'none'
              : `1px solid ${isDarkMode ? Color.dark(300) : Color.gray(300)}`,
        }}
        onClick={() => onClick(panelName)}
      >
        {children}
      </ActionIcon>
    </Indicator>
  )
}

const NodePanelContent = ({
  active,
  children,
}: {
  active: boolean
  children: ReactNode
}) => {
  const isDarkMode = useDarkMode()

  return (
    // <Transition
    //   mounted={active}
    //   transition="fade"
    //   duration={200}
    //   timingFunction="ease"
    //   keepMounted
    // >
    //   {(transitionStyle) => (
    <Column
      bg={isDarkMode ? Color.dark(500) : Color.gray(0)}
      w="100%"
      pos="absolute"
      style={{
        borderRadius: 6,
        border: `1px solid ${isDarkMode ? Color.dark(300) : Color.gray(300)}`,
        overflow: 'auto',
        pointerEvents: active ? 'auto' : 'none',
        opacity: active ? 1 : 0,
        // boxShadow: 'var(--mantine-shadow-md)',
        // ...transitionStyle,
      }}
    >
      {children}
    </Column>
    // )}
    // </Transition>
  )
}

const NodePanelAttachments = ({}: {}) => {
  // TODO: Set # attachments on load (indicator)
  const attachments = []

  return (
    <ScrollArea>
      {attachments.length === 0 && (
        <EmptyPanelContent>No attachments</EmptyPanelContent>
      )}
    </ScrollArea>
  )
}

const NodePanelComments = ({}: {}) => {
  // TODO:
  const comments = []

  return (
    <ScrollArea>
      {comments.length === 0 && (
        <EmptyPanelContent>No comments</EmptyPanelContent>
      )}
    </ScrollArea>
  )
}

const NodePanelDescription = ({
  isEditing,
  onChange,
}: {
  isEditing: boolean
  onChange?: (description: string) => void
}) => {
  const id = useNodeContext((x) => x.id)
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const processId = useProcessContext((x) => x.id)
  const revisionId = useRevisionContext((x) => x.revision.id)
  const { nodeDetail, isLoading, error } = useNodeDetailQuery({
    processId,
    revisionId,
    nodeId: id,
    organizationId,
  })
  const [loaded, setLoaded] = useState(false)
  const [content, setContent] = useState('')
  const [initialDescription, setInitialDescription] = useState('')
  const { setNodeDescription } = useSetNodeDescription()

  useEffect(() => {
    if (isLoading || !nodeDetail) return
    setContent(nodeDetail.description)
    setInitialDescription(nodeDetail.description)
    onChange?.(nodeDetail.description)
    setLoaded(true)
  }, [nodeDetail?.description, isLoading])

  if (error) {
    return <EmptyPanelContent>Failed to load details</EmptyPanelContent>
  }

  if (isLoading || !loaded) {
    return (
      <Box opacity={0.5}>
        <EmptyPanelContent>Loading...</EmptyPanelContent>
      </Box>
    )
  }

  if (!isEditing && !content) {
    return <EmptyPanelContent>No details provided</EmptyPanelContent>
  }

  return (
    <ScrollArea>
      <TextEditor
        mih={170}
        content={content}
        placeholder={'Provide additional details...'}
        options={{
          editable: isEditing,
          onUpdate({ editor }) {
            if (!isLoading) {
              const value = editor.isEmpty ? '' : editor.getHTML()
              setContent(value)
            }
          },
          onBlur() {
            if (initialDescription !== content && !isLoading) {
              setInitialDescription(content)
              setNodeDescription({
                nodeId: id,
                processId,
                revisionId,
                description: content,
                organizationId,
              })
              onChange?.(content)
            }
          },
        }}
      />
      {/* <Space h="md" /> */}
    </ScrollArea>
  )
}

const EmptyPanelContent = ({ children }: { children: string }) => {
  const deviceSize = useDeviceSize()

  return (
    <Box
      mih={170}
      p="xl"
      w="100%"
      align="center"
      justify="center"
      style={{
        '--text-fz': deviceSize.isSmall
          ? 'var(--mantine-font-size-sm)'
          : 'var(--mantine-font-size-md)',
      }}
    >
      <Text>{children}</Text>
    </Box>
  )
}

const NodePaneDropdownButton = ({
  nodeId,
  size,
}: {
  nodeId: string
  size: number | string
}) => {
  const vars = useThemeVars()
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const queryKey = useRevisionContext((x) => x.queryKey)
  const { removeStep } = useProcessAction(queryKey, 'removeStep')
  const { changeStepType } = useProcessAction(queryKey, 'changeStepType')
  const id = useNodeContext((x) => x.id)
  const globalId = useNodeContext((x) => x.globalId)
  const type = useNodeContext((x) => x.type)
  const treePath = useProcessContext((x) => x.treePath)
  const isRoot = useNodeContext((x) => x.isRoot)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const [transitionStep, setTransitionStep] = useState<
    null | 'Main' | 'ChooseNode'
  >(null)
  const [menuStep, setMenuStep] = useState<null | 'Main' | 'ChooseNode'>(null)

  const isFirstNode = isRoot && treePath.length === 0

  useEffect(() => {
    if (!menuStep) return

    setTransitionStep(menuStep)

    setTimeout(() => {
      // Re-focus the dropdown menu on content change
      const dropdown = document.querySelector(
        '[data-menu-dropdown]',
      ) as HTMLInputElement
      dropdown?.focus()
    })
  }, [menuStep])

  return (
    <Menu
      position="bottom-start"
      withArrow={true}
      arrowOffset={20}
      opened={Boolean(menuStep)}
      closeOnItemClick={false}
      onClose={() => {
        setMenuStep(null)
      }}
      transitionProps={{
        onExited() {
          setTransitionStep(null)
        },
      }}
    >
      <Menu.Target>
        <ActionIcon
          className={classes.panelButton}
          color={isDarkMode ? 'dark.5' : 'gray.0'}
          c={isDarkMode ? vars.colors.text : Color.gray(800)}
          radius="50%"
          tabIndex={3}
          size={size}
          style={{
            pointerEvents: 'auto',
          }}
          onClick={() => {
            setMenuStep(menuStep ? null : 'Main')
          }}
        >
          <Icon name="PiDotsThreeBold" size={deviceSize.isSmall ? 20 : 24} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown ref={dropdownRef}>
        <div></div>
        {transitionStep === 'Main' && (
          <>
            {!isFirstNode && (
              <Menu.Item
                leftSection={<Icon name="PiSwap" size={16} />}
                onClick={() => {
                  setTimeout(() => {
                    setMenuStep('ChooseNode')
                  })
                }}
              >
                Change shape
              </Menu.Item>
            )}
            <Menu.Divider></Menu.Divider>
            <Menu.Item
              leftSection={<Icon name="PiTrashSimpleDuotone" size={16} />}
              color="red"
              c="red"
              onClick={() => {
                removeStep({
                  id,
                })
                setMenuStep(null)
              }}
            >
              Delete
            </Menu.Item>
          </>
        )}
        <Transition
          mounted={transitionStep === 'ChooseNode'}
          transition="fade"
          duration={200}
          exitDuration={0}
          timingFunction="ease"
        >
          {(style) => (
            <Column style={{ ...style }}>
              <Menu.Label mb="xs">Change to</Menu.Label>
              <ChooseNodeTypeMenu
                onSelect={(type) => {
                  changeStepType({
                    id,
                    step: {
                      type,
                    },
                  })
                  setMenuStep(null)
                }}
                Step={type !== NodeType.Step}
                Decision={type !== NodeType.Decision}
                Switch={type !== NodeType.Switch}
                End={type !== NodeType.End}
              />
            </Column>
          )}
        </Transition>
      </Menu.Dropdown>
    </Menu>
  )
}

const RevisionDropdown = () => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const deviceSize = useDeviceSize()
  const isDarkMode = useDarkMode()
  const nodeId = useNodeContext((x) => x.id)
  const revisionQueryKey = useRevisionContext((x) => x.queryKey)
  const subprocessId = useNodeContext((x) => x.subprocessId)
  const activeRevisionId = useNodeContext((x) => x.subprocessRevisionId)
  const subprocessQueryKey = useNodeContext((x) => x.subprocessQueryKey)
  const { setStepSubprocess, isSettingContent } = useProcessAction(
    revisionQueryKey,
    'setStepSubprocess',
  )

  const {
    isLoading,
    process: subprocess,
    published,
    // drafts,
  } = useProcessVersionsQuery(subprocessQueryKey)

  if (isLoading || !subprocessId) return null

  // NOTE: We currently don't support editing subprocess drafts
  //  Remove this line when we do.
  const drafts = [] as RevisionDraftItem[]

  const selectedItem =
    published.find((x) => x.revision.id === activeRevisionId) ??
    drafts.find((x) => x.revision.id === activeRevisionId)

  const mainRevisionId = subprocess.mainRevisionId

  const parentRevisionId = selectedItem?.isDraft
    ? selectedItem.parent?.revision.id
    : null

  const isOldVersion =
    mainRevisionId !== activeRevisionId && mainRevisionId !== parentRevisionId

  return (
    <Combobox
      store={combobox}
      offset={3}
      width={drafts.length > 0 ? 150 : 135}
      position="bottom-start"
      disabled={isSettingContent}
      onOptionSubmit={(val) => {
        setStepSubprocess({
          id: nodeId,
          subprocessId,
          subprocessRevisionId: val,
        })
        combobox.closeDropdown()
      }}
    >
      {/* Top */}
      <Combobox.Target>
        <Button
          variant="subtle"
          color="gray"
          pr={2}
          styles={{
            root: {
              color: isDarkMode ? 'white' : 'black',
              padding: 0,
              paddingRight: 12,
              paddingLeft: 6,
              height: 30,
            },
          }}
          rightSection={
            <Combobox.Chevron color={isDarkMode ? 'white' : 'black'} />
          }
          onClick={() => combobox.toggleDropdown()}
        >
          <Row>
            {selectedItem ? (
              <Row>
                {isOldVersion && (
                  <Tooltip label="This version is out of date">
                    <Box c="yellow.4" mr={2}>
                      <Icon size="1.2rem" name="Warning" nudgeUp={1} />
                    </Box>
                  </Tooltip>
                )}
                {selectedItem.isDraft ? (
                  <Row gap="sm">
                    <Text fz={deviceSize.isSmall ? 'sm' : 'md'}>
                      {selectedItem.displayName}
                    </Text>
                    {selectedItem.parent && (
                      <Pill size="xs" bg={isDarkMode ? 'dark.4' : 'gray.3'}>
                        {deviceSize.isSmall
                          ? `v${selectedItem.parent.version}`
                          : selectedItem.parent.displayName}
                      </Pill>
                    )}
                  </Row>
                ) : (
                  <Text fz={deviceSize.isSmall ? 'sm' : 'md'}>
                    {selectedItem.displayName}
                  </Text>
                )}
              </Row>
            ) : (
              <Input.Placeholder>Select...</Input.Placeholder>
            )}
          </Row>
        </Button>
      </Combobox.Target>

      {/* Dropdown */}
      <Combobox.Dropdown
        onMouseLeave={() => combobox.resetSelectedOption()}
        p={0}
      >
        <ScrollArea.Autosize
          type="auto"
          mah="40vh"
          scrollbarSize="0.5rem"
          scrollbars="y"
        >
          <Combobox.Options>
            {/* Versions */}
            {published.map((item) => {
              const active = activeRevisionId === item.revision.id
              return (
                <Combobox.Option
                  key={item.revision.id}
                  value={item.revision.id}
                  active={active}
                >
                  <Row justify="space-between">
                    <Column gap={2}>
                      <Text>{item.displayName}</Text>
                      <Text size="xs" opacity={0.7}>
                        {dayjs(item.publication.publishedAt).format(
                          'MMM D, YYYY',
                        )}
                      </Text>
                    </Column>
                    {active && (
                      <Icon name="MdCheckCircle" size={18} c="green.5" />
                    )}
                  </Row>
                </Combobox.Option>
              )
            })}
            {/* Drafts */}
            {drafts.length > 0 && (
              <>
                <Divider my={6} />
                {drafts.map((item) => {
                  const active = activeRevisionId === item.revision.id
                  return (
                    <Combobox.Option
                      key={item.revision.id}
                      value={item.revision.id}
                      active={active}
                    >
                      <Row justify="space-between">
                        <Column gap={2}>
                          <Row align="center" gap={6}>
                            <Text>Draft</Text>
                            {item.parent && (
                              <Pill
                                size="xs"
                                bg={isDarkMode ? 'dark.4' : 'gray.3'}
                              >
                                {item.parent.displayName}
                              </Pill>
                            )}
                          </Row>
                          <Text size="xs" opacity={0.7}>
                            Edited {dayjs(item.updatedAt).format('MMM D, YYYY')}
                          </Text>
                        </Column>
                        {active && (
                          <Icon name="MdCheckCircle" size={18} c="green.5" />
                        )}
                      </Row>
                    </Combobox.Option>
                  )
                })}
              </>
            )}
          </Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  )
}
