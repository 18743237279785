import { useNodeDetailQuery } from '~/client/dashboard/queries/node-detail-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandContextDefinitions,
  ClientCommandDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { useRevisionModelCtx } from '~/commands/revision/revision-commands-client'

/**
 * NodeDetail client commands
 */

export const nodeDetailCommandContext = {
  Example: () => {},
} satisfies ClientCommandContextDefinitions<ModelName.NodeDetail>

export const nodeDetailCommandsClient = {
  Example: {
    getAuthorizationContext() {
      return {
        meta: {},
        sender: {},
        target: {},
      }
    },
    getValidationContext() {
      return {
        meta: {},
        sender: {},
        target: {},
      }
    },
    onSuccess: [],
  },
} satisfies ClientCommandDefinitions<ModelName.NodeDetail>

/**
 * NodeDetail client context
 */

export const useNodeDetailModelCtx = ((key) => {
  const { ctx, sender, state } = useRevisionModelCtx({
    id: key.revisionId,
    processId: key.processId,
    organizationId: key.organizationId,
  })

  const {
    nodeDetail: target,
    isLoading,
    isError,
  } = useNodeDetailQuery(key)

  return {
    target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
      nodeDetail: target,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.NodeDetail>
