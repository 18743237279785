import { Box, Button, ButtonProps, Tooltip, TooltipProps } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'
import { z } from 'zod'
import {
  ModelName,
  CommandParams,
  Commands,
  ValidationResult,
} from '~/commands/base-commands'
import {
  AuthorizationResult,
  useCommand,
  useCommandAuthorization,
} from '~/commands/client-commands'
import { ModelQueryKey } from '~/schemas'

// export type WithCommandResult<
//   Model extends CommandModel,
//   Command extends keyof Commands[Model],
// > = {
//   // @ts-ignore
//   execute: (
//     props: Omit<
//       Parameters<
//         // @ts-ignore
//         ReturnType<
//           // @ts-ignore
//           ClientCommands[Model][Command]['useCommand']
//         >['execute']
//       >[0],
//       keyof z.infer<CommandIdentifiers[Model]>
//     >,
//   ) => ReturnType<
//     // @ts-ignore
//     ReturnType<
//       // @ts-ignore
//       ClientCommands[Model][Command]['useCommand']
//     >['execute']
//   >
//   isExecuting: boolean
//   authorization: AuthorizationResult<Model, Command>
// }

export type WithCommandResult<
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends ModelQueryKey[Model] | undefined = undefined,
  Validate extends Partial<CommandParams<Model, Command>> = {},
> = ReturnType<typeof useCommand<Model, Command, Key, Validate>>

export type WithCommandProps<
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends ModelQueryKey[Model] | undefined,
  Validate extends Partial<CommandParams<Model, Command>> = {},
> = {
  model: Model
  command: Command
  queryKey?: Key
  notAllowed?:
    | 'hide'
    | 'disable'
    | 'show'
    | ((props: { reason: ValidationResult['reason'] }) => ReactNode)
  placeholder?: ReactNode | null
  // @ts-ignore
  validate?: Validate
  tooltip?: Partial<TooltipProps>
  __override?: boolean // Note: Allow requests, for debugging server-side validation
  children:
    | ReactNode
    | ((
        options: WithCommandResult<Model, Command, Key, Validate>,
      ) => ReactNode | null)
}

export const WithCommand = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends ModelQueryKey[Model] | undefined = undefined,
  Validate extends Partial<CommandParams<Model, Command>> = {},
>({
  children,
  authorization,
  placeholder = null,
  ...props
}: WithCommandProps<Model, Command, Key, Validate> & {
  // Optionally include authorization, otherwise it will be derived
  authorization?: AuthorizationResult<Model, Command>
}) => {
  placeholder =
    typeof children === 'function' ? (
      <Disabled>{placeholder}</Disabled>
    ) : (
      children!
    )

  if (authorization) {
    return (
      <Delayed placeholder={placeholder}>
        <WithCommandAuthorization {...props} authorization={authorization}>
          {children}
        </WithCommandAuthorization>
      </Delayed>
    )
  }

  return (
    <Delayed placeholder={placeholder}>
      <WithCommandInner {...props}>{children}</WithCommandInner>
    </Delayed>
  )
}

const WithCommandInner = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends ModelQueryKey[Model] | undefined = undefined,
  Validate extends Partial<CommandParams<Model, Command>> = {},
>({
  children,
  queryKey,
  validate,
  model,
  command,
  ...props
}: WithCommandProps<Model, Command, Key, Validate>) => {
  const identifierParsed = ModelQueryKey[model].safeParse(queryKey)
  const authorization = useCommandAuthorization(
    model,
    command,
    // @ts-ignore
    {
      key: identifierParsed.success ? identifierParsed.data : {},
      params: validate ?? {},
    },
  )

  return (
    <WithCommandAuthorization
      {...props}
      queryKey={queryKey}
      validate={validate}
      model={model}
      command={command}
      authorization={authorization}
    >
      {children}
    </WithCommandAuthorization>
  )
}

const WithCommandAuthorization = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends ModelQueryKey[Model] | undefined = undefined,
  Validate extends Partial<CommandParams<Model, Command>> = {},
>({
  model,
  command,
  authorization,
  notAllowed = 'show',
  tooltip = {},
  children,
  queryKey,
  validate,
  __override = false,
}: WithCommandProps<Model, Command, Key, Validate> & {
  authorization: AuthorizationResult<Model, Command>
}) => {
  const { valid, reason } = authorization
  // @ts-ignore
  const commandResult = useCommand(model, command, queryKey, validate)

  // TODO: Get additional fields if needed
  // getCommand(model, command as any)

  useEffect(() => {
    if (reason?.type === 'error') console.error(reason.message)
  }, [reason?.type])

  useEffect(() => {
    if (__override) {
      console.warn('Validation override active for command:', command)
    }
  }, [__override])

  if (!valid && notAllowed !== 'show') {
    if (notAllowed === 'hide' && !__override) return null
    if (notAllowed === 'disable') {
      return (
        <Disabled
          tooltip={tooltip}
          message={
            ['invalid', 'unauthorized'].includes(reason?.type ?? '')
              ? reason?.message
              : 'Not allowed'
          }
        >
          {typeof children === 'function' ? children(commandResult) : children}
        </Disabled>
      )
    }
    if (typeof notAllowed === 'function') {
      const NotAllowed = notAllowed
      return <NotAllowed reason={reason} />
    }
  }

  return typeof children === 'function' ? children(commandResult) : children
}

export const ButtonWithCommand = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends ModelQueryKey[Model] | undefined = undefined,
  Validate extends Partial<CommandParams<Model, Command>> = {},
>({
  command,
  model,
  queryKey,
  notAllowed = 'disable',
  validate,
  tooltip,
  children,
  __override,
  onClick,
  ...buttonProps
}: {
  onClick: (
    commandResult: WithCommandResult<Model, Command, Key, Validate>,
  ) => void
} & ButtonProps &
  Omit<WithCommandProps<Model, Command, Key, Validate>, 'children'>) => {
  return (
    <WithCommand
      {...{
        command,
        model,
        queryKey,
        notAllowed,
        validate,
        tooltip,
        __override,
      }}
    >
      {(props) => (
        <Button
          {...buttonProps}
          // @ts-ignore
          loading={props.isPending}
          // @ts-ignore
          onClick={() => onClick(props)}
        >
          {children}
        </Button>
      )}
    </WithCommand>
  )
}

// Used to defer expensive authorization computation
const Delayed = ({
  children,
  waitBeforeShow = 0,
  placeholder = null,
}: {
  children: React.ReactNode
  waitBeforeShow?: number
  placeholder?: ReactNode | null
}) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, waitBeforeShow)
    return () => clearTimeout(timer)
  }, [waitBeforeShow])

  return isShown ? children : placeholder
}

const Disabled = ({
  message,
  tooltip = {},
  children,
}: {
  message?: string
  tooltip?: Partial<TooltipProps>
  children: ReactNode
}) => (
  <Tooltip
    label={message}
    multiline={true}
    maw={200}
    openDelay={200}
    offset={{ mainAxis: 12 }}
    {...tooltip}
  >
    <Box opacity={0.5}>
      <Box style={{ pointerEvents: 'none' }} data-command-disabled>
        {children}
      </Box>
    </Box>
  </Tooltip>
)
