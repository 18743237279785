import { useInvitationQuery } from '~/client/dashboard/queries/invitation-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { useOrganizationModelCtx } from '~/commands/organization/organization-commands-client'

/**
 * Invitation client commands
 */

export const invitationCommandContext = {
  Example: () => {},
} satisfies ClientCommandContextDefinitions<ModelName.Invitation>

export const invitationCommandsClient = {
  Example: {
    getAuthorizationContext(context) {
      return {
        meta: {},
        sender: {},
        target: {},
      }
    },
    getValidationContext(context) {
      return {
        meta: {},
        sender: {},
        target: {},
      }
    },
    onSuccess: [],
  },
} satisfies ClientCommandDefinitions<ModelName.Invitation>

/**
 * Invitation client context
 */

export const useInvitationModelCtx = ((key) => {
  const { ctx, sender, state } = useOrganizationModelCtx({
    id: key.organizationId,
  })

  const {
    invitation: target,
    isLoading,
    isError,
  } = useInvitationQuery(key)

  return {
    target: target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
      invitation: target,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.Invitation>
