import { cacheDocumentMembers } from '~/client/dashboard/queries/document-queries'
import {
  ApiContext,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  CacheFn,
  CacheRemoveFn,
  RETRY,
  cacheEachKey,
  parseKey,
  useCacheResult,
  QueryOptions,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { cacheOrganizationMemberDocumentProfiles } from '~/client/dashboard/queries/organization-member-queries'
import { PartialRequired } from '~/client/globals'
import { ClientModel, ModelQueryKey } from '~/schemas'
import {
  DocumentMemberQueryKey,
  documentMemberQueryKeys,
} from '~/schemas/document-member-schema'
import { api } from '~/utils/api'
import { isMatch } from '~/utils/logic'

export const useDocumentMemberQuery = (
  key: PartialRequired<DocumentMemberQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = DocumentMemberQueryKey.safeParse(key).success
  const result = api.DocumentMember.get.useQuery(
    parseKey(key, DocumentMemberQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )
  const documentMember = result.data ?? null

  useCacheResult(result, cacheDocumentMember, () => key, false)

  return {
    ...result,
    documentMember,
    isLoadingDocumentMember: result.isPending && enabled,
    isLoadingDocumentMemberError: Boolean(result?.isError),
  }
}

export const useDocumentMemberQueryList = (
  keys: PartialRequired<DocumentMemberQueryKey>[] = [],
) => {
  const resultList = api.useQueries((t) =>
    keys.map((key) =>
      t.DocumentMember.get(parseKey(key, DocumentMemberQueryKey), {
        enabled: DocumentMemberQueryKey.safeParse(key).success,
        staleTime: CHILD_ITEM_STALE_TIME,
        gcTime: CHILD_ITEM_CACHE_TIME,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: RETRY,
      }),
    ),
  )

  // TODO: Cache each result
  // documentMembers.forEach(result => {
  //   useCacheResult(result, cacheDocumentMember, () => key, false)
  // })

  const isLoading = resultList.some((x) => x.isLoading)

  return {
    documentMemberResults: isLoading
      ? []
      : resultList.map((x) => x.data ?? null),
    isLoadingDocumentMembers: isLoading,
    isLoadingDocumentMembersError: resultList.some((x) => x.isError),
  }
}

/**
 * Cache get
 */

export const getDocumentMemberFromCache = (
  ctx: ApiContext,
  key: DocumentMemberQueryKey,
) => {
  key = DocumentMemberQueryKey.parse(key)
  return ctx.DocumentMember.get.getData(key)
}

/**
 * Cache set
 */

export const cacheDocumentMember: CacheFn<
  ModelQueryKey['DocumentMember'],
  ClientModel['DocumentMember'],
  {
    updateDocumentMembers?: boolean
    updateOrganizationMemberDocumentProfiles?: boolean
  }
> = (
  ctx,
  data,
  key,
  {
    updateDocumentMembers = true,
    updateOrganizationMemberDocumentProfiles = true,
  },
) => {
  key = DocumentMemberQueryKey.parse(key)
  const previous = getDocumentMemberFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.DocumentMember.get.setData(key, null)
  }

  if (result) {
    documentMemberQueryKeys.forEach((x) => {
      try {
        ctx.DocumentMember.get.setData(x.parse(result), result)
      } catch {}
    })

    if (updateDocumentMembers) {
      cacheDocumentMembers(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (x.id === result.id ? result : x))
          }
        },
        { id: result.documentId, organizationId: result.organizationId },
        true,
      )
    }

    if (updateOrganizationMemberDocumentProfiles) {
      cacheOrganizationMemberDocumentProfiles(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (x.id === result.id ? result : x))
          }
        },
        { id: result.memberId, organizationId: result.organizationId },
        true,
      )
    }
  }
  return {
    previous,
    revert: () => {
      cacheDocumentMember(ctx, previous, key, {
        updateDocumentMembers,
        updateOrganizationMemberDocumentProfiles,
      })
    },
  }
}

/**
 * Cache remove
 */

export const removeDocumentMemberFromCache: CacheRemoveFn<
  ModelQueryKey['DocumentMember'],
  ClientModel['DocumentMember']
> = (ctx, key) => {
  key = DocumentMemberQueryKey.parse(key)
  const previous = getDocumentMemberFromCache(ctx, key)

  if (previous) {
    cacheEachKey(
      ctx.DocumentMember.get,
      documentMemberQueryKeys,
      previous,
      null,
    )
  }

  const { revert } = cacheDocumentMembers(
    ctx,
    (old) => {
      if (!old) return
      return old.filter((x) => !isMatch(x, key))
    },
    { organizationId: key.organizationId, id: key.documentId },
    true,
  )

  return {
    previous,
    revert,
  }
}
