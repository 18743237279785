import {
  ModelName,
  ModelCommandsValidation,
  authorized,
  valid,
} from '~/commands/base-commands'

/**
 * InvitationRedemption commands validation
 */

export const invitationRedemptionValidation = {
  Example: {
    validate(ctx) {
      return valid()
    },
    authorize(ctx) {
      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.InvitationRedemption>
