// Explicitly export the required lodash functions for this application.
// This ensures proper tree-shaking and simplifies usage throughout the app.

import omit from 'lodash-es/omit'
export { omit }

import omitBy from 'lodash-es/omitBy'
export { omitBy }

import isUndefined from 'lodash-es/isUndefined'
export { isUndefined }

import isNull from 'lodash-es/isNull'
export { isNull }

import isFunction from 'lodash-es/isFunction'
export { isFunction }

import pick from 'lodash-es/pick'
export { pick }

import pickBy from 'lodash-es/pickBy'
export { pickBy }

import debounce from 'lodash-es/debounce'
export { debounce }

import result from 'lodash-es/result'
export { result }

import random from 'lodash-es/random'
export { random }

import merge from 'lodash-es/merge'
export { merge }

import orderBy from 'lodash-es/orderBy'
export { orderBy }

import sortBy from 'lodash-es/sortBy'
export { sortBy }

import uniq from 'lodash-es/uniq'
export { uniq }

import uniqBy from 'lodash-es/uniqBy'
export { uniqBy }

import union from 'lodash-es/union'
export { union }

import every from 'lodash-es/every'
export { every }

import throttle from 'lodash-es/throttle'
export { throttle }

// Symmetric difference
import xor from 'lodash-es/xor'
export { xor }

import difference from 'lodash-es/difference'
export { difference }

import differenceBy from 'lodash-es/differenceBy'
export { differenceBy }

import groupBy from 'lodash-es/groupBy'
export { groupBy }

import lowerFirst from 'lodash-es/lowerFirst'
export { lowerFirst }

import isMatch from 'lodash-es/isMatch'
export { isMatch }

import zip from 'lodash-es/zip'
export { zip }

/**
 * Custom functions
 */

import { customAlphabet } from 'nanoid'

export const randomElement = <T extends any>(arr: T[] = []): T => {
  return arr[random(0, arr.length - 1)]!
}

export const reduceDown = <T extends { children: T[] }, U>(
  node: T,
  fn: (acc: U, next: T) => U,
  start?: U,
): U => {
  return node.children.reduce((acc, next) => {
    return reduceDown(next, fn, fn(acc!, next))
  }, start)!
}

export const allDefined = (obj: object) => {
  return every(obj, (x) => !isUndefined(x))
}

export const replaceNewLine = (x: string) => x.replace(/\r\n|\r|\n/g, ' ')

export const generateId = (length = 12) =>
  customAlphabet(
    '0123456789ABCDEFGHJKMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  )(length)
