'use client'

import { Button, NavLink, ScrollArea, Text, Title } from '@mantine/core'
import { modals } from '@mantine/modals'
import { OrganizationType } from '@prisma/client'
import { ReactNode } from 'react'
import { Link } from 'wouter'
import { RouteMap } from '~/client/RouteMap.ts'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import { Box, Column, Row } from '~/client/shared/Layout'
import { useNavigate } from '~/client/shared/hooks/useNavigate'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  PortalTarget,
  PortalTargetId,
} from '~/client/dashboard/components/global/Portals'
import { PageTitle } from '~/client/dashboard/components/global/Prefabs'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { WithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import classes from './SettingsShell.module.css'
import { globalIcons } from '~/client/shared/data/global-data'
import { useDeviceSize } from '~/client/dashboard/stores/GlobalStore'

export enum SettingsItem {
  Profile = 'Profile',
  Account = 'Account',
  Appearance = 'Appearance',
  Members = 'Members',
  Invitations = 'Invitations',
}

export const navGroups = {
  'Your Account': [SettingsItem.Profile, SettingsItem.Account],
  Organization: [
    SettingsItem.Appearance,
    SettingsItem.Members,
    SettingsItem.Invitations,
  ],
} satisfies {
  [group: string]: SettingsItem[]
}

// type SettingsState = {
//   activeItem: SettingsItem | null
//   setActiveItem: (activeItem: SettingsItem) => void
// }

// export const useSettingsStore = create<SettingsState>((set) => ({
//   activeItem: null,
//   setActiveItem: (activeItem) =>
//     set({
//       activeItem,
//     }),
// }))

export const SettingsShell = ({
  activeItem,
  children,
}: {
  activeItem: SettingsItem
  children: ReactNode
}) => {
  const deviceSize = useDeviceSize()
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const bodyPaddingTop = deviceSize.isSmall ? 'md' : 'lg'

  return (
    <Row h="100%" w="100%" justify="stretch" align="stretch">
      {!deviceSize.isSmall && !deviceSize.isMedium && (
        <SettingsNav activeItem={activeItem} />
      )}
      <ScrollArea
        h="100%"
        w="100%"
        classNames={{ viewport: classes.scrollViewport }}
      >
        <Column pt={bodyPaddingTop} px="lg" pb="lg" w="100%" h="100%" maw={800}>
          <PageTitle
            breadcrumbs={[{ name: 'Settings' }]}
            selectedHref={RouteMap.Settings(activeItem, organizationSlug)}
            options={Object.entries(navGroups)
              .map(([group, items]) =>
                items.map((x) => ({
                  href: RouteMap.Settings(x, organizationSlug),
                  group,
                  Title: () => <Box pl="xs">{x}</Box>,
                })),
              )
              .flat()}
            title={activeItem}
            stack={false}
            rightSection={
              <PortalTarget id={PortalTargetId.SettingsTitleRight} />
            }
          />
          <ErrorBoundary id="SettingsShell">{children}</ErrorBoundary>
        </Column>
      </ScrollArea>
    </Row>
  )
}

export const SettingsNav = ({ activeItem }: { activeItem: SettingsItem }) => {
  const organizationSlug = useOrganizationContext((x) => x.organization.slug)
  const type = useOrganizationContext((x) => x.organization.type)

  return (
    <Column
      className={classes.settingsNav}
      style={{ overflow: 'auto', height: 'auto' }}
      justify="space-between"
      px="sm"
      my="lg"
    >
      <ScrollArea type="auto" px="sm">
        <Column gap="lg" w="100%" pb={40}>
          {Object.entries(navGroups).map(([group, items]) => {
            return (
              (group !== 'Organization' ||
                type !== OrganizationType.Personal) && (
                <Column w="100%" key={group} gap={2}>
                  <Text opacity={0.7} fz="xs" tt="uppercase" mb="sm">
                    {group}
                  </Text>
                  {items.map((x) => (
                    <NavLink
                      key={x}
                      component={Link}
                      href={RouteMap.Settings(x, organizationSlug)}
                      label={x}
                      active={activeItem === x}
                    />
                  ))}
                </Column>
              )
            )
          })}
        </Column>
      </ScrollArea>
      <Column>
        {type !== OrganizationType.Personal && <LeaveOrganizationButton />}
        {type !== OrganizationType.Personal && <DeleteOrganizationButton />}
      </Column>
    </Column>
  )
}

const LeaveOrganizationButton = () => {
  const userId = useUserContext((x) => x.user.id)
  const organizations = useUserContext((x) => x.organizations)
  const activeOrganizationIds = organizations.map((x) => x.id)
  const organizationId = useOrganizationContext((x) => x.id)
  const ownerId = useOrganizationContext((x) => x.organization.ownerId)
  const profileIdentifier = useOrganizationContext((x) => ({
    id: x.currentMember.id,
    organizationId: x.organization.id,
  }))
  const { organization: nextOrganization } = useOrganizationQuery({
    id: activeOrganizationIds.find((x) => x !== organizationId)!,
  })
  const navigate = useNavigate()
  const { leaveOrganizationAsync } = useCommand(
    ModelName.OrganizationMember,
    'Leave',
    profileIdentifier,
  )

  const openLeaveModal = () =>
    modals.openConfirmModal({
      title: (
        <Title component="div" order={3}>
          Leave organization?
        </Title>
      ),
      children: (
        <Column pb="lg">
          <Text>
            You will not be able to access this organization again until you are
            invited back.
          </Text>
        </Column>
      ),
      labels: { confirm: 'Leave', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onCancel: () => {},
      onConfirm: () =>
        void leaveOrganizationAsync({}).then(() => {
          if (nextOrganization) {
            navigate(RouteMap.Organization(nextOrganization.slug))
          }
        }),
    })

  const openOwnerModal = () =>
    modals.open({
      title: (
        <Row gap="md">
          <Box c="yellow">
            <Icon size={24} name="PiWarningFill" />
          </Box>
          <Title order={3}>Unable to leave organization</Title>
        </Row>
      ),
      children: (
        <Column gap="lg">
          <Column p="md" pt="xs">
            <Text>
              You cannot leave an organization while you are its owner.
            </Text>
            <Text>
              Please set another member to organization owner and try again.
            </Text>
          </Column>
          <Row justify="flex-end">
            <Button onClick={() => modals.closeAll()}>Okay</Button>
          </Row>
        </Column>
      ),
    })

  return (
    <WithCommand
      model={ModelName.OrganizationMember}
      command="Leave"
      queryKey={profileIdentifier}
      notAllowed="disable"
    >
      <Button
        leftSection={<Icon name={globalIcons.Exit} />}
        styles={{
          section: {
            marginLeft: rem(2),
            marginRight: rem(8),
          },
        }}
        style={{
          marginTop: 'auto',
          justifySelf: 'flex-end',
        }}
        variant="subtle"
        size="compact-md"
        color="red"
        justify="flex-start"
        onClick={() => {
          if (ownerId === userId) {
            openOwnerModal()
          } else {
            openLeaveModal()
          }
        }}
      >
        Leave organization
      </Button>
    </WithCommand>
  )
}

const DeleteOrganizationButton = () => {
  const userId = useUserContext((x) => x.user.id)
  const organizations = useUserContext((x) => x.organizations)
  const activeOrganizationIds = organizations.map((x) => x.id)
  const organizationId = useOrganizationContext((x) => x.id)
  const ownerId = useOrganizationContext((x) => x.organization.ownerId)
  const { organization: nextOrganization } = useOrganizationQuery({
    id: activeOrganizationIds.find((x) => x !== organizationId)!,
  })
  const navigate = useNavigate()
  const { deleteOrganizationAsync } = useCommand(
    ModelName.Organization,
    'Delete',
    { id: organizationId },
  )

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: (
        <Title component="div" order={3}>
          Delete organization?
        </Title>
      ),
      children: (
        <Column pb="lg">
          <Text>
            This organization and all of its contents will be permanently
            deleted. This cannot be undone.
          </Text>
        </Column>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onCancel: () => {},
      onConfirm: () =>
        void deleteOrganizationAsync({}).then(() => {
          if (nextOrganization) {
            navigate(RouteMap.Organization(nextOrganization.slug))
          }
        }),
    })

  return (
    <WithCommand
      model={ModelName.Organization}
      command="Delete"
      queryKey={{ id: organizationId }}
      notAllowed="hide"
    >
      <Button
        leftSection={<Icon name={globalIcons.Delete} />}
        styles={{
          section: {
            marginLeft: rem(2),
            marginRight: rem(8),
          },
        }}
        style={{
          marginTop: 'auto',
          justifySelf: 'flex-end',
        }}
        variant="subtle"
        size="compact-md"
        color="red"
        justify="flex-start"
        onClick={() => {
          openDeleteModal()
        }}
      >
        Delete organization
      </Button>
    </WithCommand>
  )
}
