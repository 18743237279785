import { Center } from '@mantine/core'
import {
  DiagramNodeSVG,
  DiagramNodeWrapper,
} from '~/client/dashboard/components/process/diagram/DiagramNodeWrapper'
import { DiagramNodeProps } from '~/client/dashboard/components/process/diagram/diagram-settings'
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import { useProcessContext } from '~/client/dashboard/stores/ProcessStore'

export const DiagramStepNode = (props: DiagramNodeProps) => {
  const { data } = props
  const id = useNodeContext((x) => x.id)
  const type = useNodeContext((x) => x.type)
  const globalId = useNodeContext((x) => x.globalId)
  const processId = useNodeContext((x) => x.processId)
  const treePath = useProcessContext((x) => x.treePath)

  const subprocessId = useNodeContext((x) => x.subprocessId)

  const parentNodeId = treePath.at(-1)?.nodeId

  // Excluding first and last entries
  const docPath = treePath.slice(1, treePath.length - 1).map((x) => x.processId)

  return (
    <>
      <DiagramNodeWrapper {...props} key={globalId}>
        <Center
          style={{
            width: props.width,
            height: props.height,
          }}
        >
          <DiagramNodeSVG
            type={type}
            nodeId={globalId}
            width={props.width}
            height={props.height}
            textInset={data.textInset}
          />
        </Center>
      </DiagramNodeWrapper>
    </>
  )
}
