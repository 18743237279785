import { SegmentedControl } from '@mantine/core'
import { Box, Row } from '~/client/shared/Layout'
import { DiagramViewMode } from "../../stores/DiagramStore"

export const ViewModeToggle = ({
  viewMode,
  onChange,
}: {
  viewMode: DiagramViewMode
  onChange: (mode: DiagramViewMode) => void
}) => {
  return (
    <SegmentedControl
      value={viewMode}
      onChange={(x) => onChange(x as DiagramViewMode)}
      w="100%"
      styles={{
        label: { padding: '4px 6px' },
      }}
      data={[
        {
          value: String(DiagramViewMode.Viewing),
          label: (
            <Row justify="center" py={3}>
              <Box>{viewMode === DiagramViewMode.Viewing ? 'Viewing' : 'View'}</Box>
            </Row>
          ),
        },
        {
          value: String(DiagramViewMode.Editing),
          label: (
            <Row justify="center" py={3}>
              <Box>{viewMode === DiagramViewMode.Editing ? 'Editing' : 'Edit'}</Box>
            </Row>
          ),
        },
      ]}
    />
  )
}
