import { TeamAccess, TeamRole } from '@prisma/client'
import {
  ModelName,
  ModelCommandsValidation,
  authorized,
  invalid,
  unauthorized,
  valid,
  valueMatches,
} from '~/commands/base-commands'

/**
 * TeamMember commands validation
 */

export const teamMemberValidation = {
  SetRole: {
    validate({ target, sender, meta }) {
      // Allow changing roles in the event that no owner exists.
      //  This could happen if an owner deletes their account
      if (meta.teamOwnerCount > 0) {
        if (!sender.isOwner) {
          return unauthorized(`Only owners can change member roles`)
        }
      }

      if (target.currentRole === target.newRole) {
        return invalid(`Member already has this role`)
      }

      if (target.currentRole === TeamRole.Owner && meta.teamOwnerCount === 1) {
        if (target.isSender) {
          return invalid(`Cannot change role before adding another owner`)
        } else {
          return invalid(`Cannot change role of sole category owner`)
        }
      }

      return valid()
    },
    authorize({ sender, target, meta }) {
      if (!sender.isJoined) {
        return unauthorized(`Must be a member of the team to make changes`)
      }

      return authorized()
    },
  },
} satisfies ModelCommandsValidation<ModelName.TeamMember>
