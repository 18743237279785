import { useInvitationQuery } from '~/client/dashboard/queries/invitation-queries'
import { useInvitationRedemptionQuery } from '~/client/dashboard/queries/invitation-redemptions-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { useInvitationModelCtx } from '~/commands/invitation/invitation-commands-client'

/**
 * InvitationRedemption client commands
 */

export const invitationRedemptionCommandContext = {
  Example: () => {},
} satisfies ClientCommandContextDefinitions<ModelName.InvitationRedemption>

export const invitationRedemptionCommandsClient = {
  Example: {
    getAuthorizationContext(context) {
      return {
        meta: {},
        sender: {},
        target: {},
      }
    },
    getValidationContext(context) {
      return {
        meta: {},
        sender: {},
        target: {},
      }
    },
    onSuccess: [],
  },
} satisfies ClientCommandDefinitions<ModelName.InvitationRedemption>

/**
 * InvitationRedemption client context
 */

export const useInvitationRedemptionModelCtx = ((key) => {
  const { ctx, sender, state } = useInvitationModelCtx({
    id: key.invitationId,
    organizationId: key.organizationId,
  })

  const {
    invitationRedemption: target,
    isLoading,
    isError,
  } = useInvitationRedemptionQuery(key)

  return {
    target,
    sender: {
      ...sender,
    },
    ctx: {
      ...ctx,
      invitationRedemption: target,
    },
    state: {
      isLoading: state.isLoading || isLoading,
      failed: state.failed || isError,
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.InvitationRedemption>
