import {PartialRequired} from '~/client/globals'
import {ProcessMemberProfileQueryKey, processMemberProfileQueryKeys} from '~/schemas/process-member-schema.ts'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  QueryOptions,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers.tsx'
import {api} from '~/utils/api.ts'
import {ClientModel, ModelQueryKey} from '~/schemas'

export const useProcessMemberProfileQuery = (
  key?: PartialRequired<ProcessMemberProfileQueryKey>,
  options: QueryOptions = {},
) => {
  const enabled = ProcessMemberProfileQueryKey.safeParse(key).success
  const result = api.ProcessMember.get.useQuery(
    parseKey(key, ProcessMemberProfileQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
      ...options,
    },
  )
  const memberProfile = result?.data ?? null

  useCacheResult(result, cacheProcessMemberProfile, () => key, false)

  return {
    ...result,
    memberProfile,
    isLoadingMemberProfile: Boolean(result.isPending && enabled),
    isLoadingMemberProfileError: Boolean(result?.isError),
  }
}

/**
 * Cache get
 */

export const getProcessMemberProfileFromCache = (
  ctx: ApiContext,
  key: ProcessMemberProfileQueryKey,
) => {
  key = ProcessMemberProfileQueryKey.parse(key)
  return ctx.ProcessMember.get.getData(key)
}

/**
 * Cache set
 */

export const cacheProcessMemberProfile: CacheFn<
  ModelQueryKey['ProcessMember'],
  ClientModel['ProcessMember']
> = (ctx, data, key) => {
  key = ProcessMemberProfileQueryKey.parse(key)
  const previous = getProcessMemberProfileFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.ProcessMember.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(
      ctx.ProcessMember.get,
      processMemberProfileQueryKeys,
      result,
      result,
    )
  }

  return {
    previous,
    revert: () => {
      cacheProcessMemberProfile(ctx, previous, key, {})
    },
  }
}

/**
 * Cache remove
 */
