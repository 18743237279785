import Prisma, {
  Document,
  DocumentAccessKey,
  DocumentMemberProfile,
  NodeDetail,
  Organization,
  OrganizationEvent,
  OrganizationInvitation,
  OrganizationInvitationRedemption,
  OrganizationMember,
  Process,
  ProcessMemberProfile,
  Revision,
  RevisionPublication,
  Team,
  TeamMemberProfile,
  User,
} from '@prisma/client'
import { RefinementCtx, SuperRefinement, ZodIssue, z } from 'zod'

// Indicates a failure to validate logic
//  - Does not indicate a type or schema mismatch
export class ValidationError extends Error {
  message: string
  constructor(message = '') {
    super()
    this.message = message
  }
}

type Models = {
  User: User
  Organization: Organization
  OrganizationMember: OrganizationMember
  OrganizationInvitation: OrganizationInvitation
  OrganizationInvitationRedemption: OrganizationInvitationRedemption
  Team: Team
  TeamMemberProfile: TeamMemberProfile
  Document: Document
  DocumentMemberProfile: DocumentMemberProfile
  Revision: Revision
  Process: Process
  ProcessMemberProfile: ProcessMemberProfile
  RevisionPublication: RevisionPublication
  NodeDetail: NodeDetail
  DocumentAccessKey: DocumentAccessKey
  OrganizationEvent: OrganizationEvent
}

export type PrismaModelSchema<PrismaModel extends Prisma.Prisma.ModelName> =
  z.ZodObject<{
    [key in keyof Models[PrismaModel]]: z.ZodTypeAny
  }>

/**
 * Refiners
 */

type CustomZodIssue = {
  path?: ZodIssue['path']
  message: ZodIssue['message']
}

type RefinerContext = {
  addIssue: (issue: CustomZodIssue) => void
}

export const refinement =
  <T>(refiner: ReturnType<typeof refine<T>>): SuperRefinement<T> =>
  (obj: T, ctx) => {
    const { issues } = refiner(obj)
    issues.forEach(ctx.addIssue)
  }

export const transformation =
  <T>(refiner: ReturnType<typeof refine<T>>) =>
  (obj: T, ctx: RefinementCtx) => {
    const { issues, result } = refiner(obj)
    issues.forEach(ctx.addIssue)
    return result
  }

// Wrap a method
export const refine =
  <T>(refiner: (obj: T, ctx: RefinerContext) => T) =>
  (obj: T): { issues: ZodIssue[]; result: T } => {
    const issues: ZodIssue[] = []
    const addIssue = (x: CustomZodIssue) =>
      issues.push({ code: 'custom', path: [], ...x })
    const result = refiner(obj, { addIssue })
    return { issues, result }
  }
