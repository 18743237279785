import { Column } from '~/client/shared/Layout.tsx'

export default function Home() {
  return (
    <Column>
      <h1>Home</h1>
      <main>...Content here</main>
    </Column>
  )
}
