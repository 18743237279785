import { ZodObject, ZodTypeAny, ZodUndefined, ZodUnion, z } from 'zod'
import { IconName } from '~/client/dashboard/components/global/Icon'
import { documentMemberCommandsSchema } from '~/commands/document-member/document-member-commands-schema'
import { documentMemberValidation } from '~/commands/document-member/document-member-commands-validation'
import { documentCommandsSchema } from '~/commands/document/document-commands-schema'
import { documentValidation } from '~/commands/document/document-commands-validation'
import { invitationRedemptionCommandsSchema } from '~/commands/invitation-redemption/invitation-redemption-commands-schema'
import { invitationRedemptionValidation } from '~/commands/invitation-redemption/invitation-redemption-commands-validation'
import { invitationCommandsSchema } from '~/commands/invitation/invitation-commands-schema'
import { invitationValidation } from '~/commands/invitation/invitation-commands-validation'
import { nodeDetailCommandsSchema } from '~/commands/node-detail/node-detail-commands-schema'
import { nodeDetailValidation } from '~/commands/node-detail/node-detail-commands-validation'
import { organizationMemberCommandsSchema } from '~/commands/organization-member/organization-member-commands-schema'
import { organizationMemberValidation } from '~/commands/organization-member/organization-member-commands-validation'
import { organizationCommandsSchema } from '~/commands/organization/organization-commands-schema'
import { organizationValidation } from '~/commands/organization/organization-commands-validation'
import { processMemberCommandsSchema } from '~/commands/process-member/process-member-commands-schema'
import { processMemberValidation } from '~/commands/process-member/process-member-commands-validation'
import { processCommandsSchema } from '~/commands/process/process-commands-schema'
import { processValidation } from '~/commands/process/process-commands-validation'
import { revisionCommandsSchema } from '~/commands/revision/revision-commands-schema'
import { revisionValidation } from '~/commands/revision/revision-commands-validation'
import { teamMemberCommandsSchema } from '~/commands/team-member/team-member-commands-schema'
import { teamMemberValidation } from '~/commands/team-member/team-member-commands-validation'
import { teamCommandsSchema } from '~/commands/team/team-commands-schema'
import { teamValidation } from '~/commands/team/team-commands-validation'
import { userCommandsSchema } from '~/commands/user/user-commands-schema'
import { userValidation } from '~/commands/user/user-commands-validation'
import { ModelQueryKey, ModelQueryKeyShape } from '~/schemas'

export enum ModelName {
  User = 'User',
  Organization = 'Organization',
  OrganizationMember = 'OrganizationMember',
  Team = 'Team',
  TeamMember = 'TeamMember',
  Document = 'Document',
  DocumentMember = 'DocumentMember',
  Process = 'Process',
  ProcessMember = 'ProcessMember',
  Revision = 'Revision',
  NodeDetail = 'NodeDetail',
  Invitation = 'Invitation',
  InvitationRedemption = 'InvitationRedemption',
}

// Command definitions
export const commands = {
  [ModelName.User]: userCommandsSchema,
  [ModelName.Organization]: organizationCommandsSchema,
  [ModelName.OrganizationMember]: organizationMemberCommandsSchema,
  [ModelName.Team]: teamCommandsSchema,
  [ModelName.TeamMember]: teamMemberCommandsSchema,
  [ModelName.Document]: documentCommandsSchema,
  [ModelName.DocumentMember]: documentMemberCommandsSchema,
  [ModelName.Process]: processCommandsSchema,
  [ModelName.ProcessMember]: processMemberCommandsSchema,
  [ModelName.Revision]: revisionCommandsSchema,
  [ModelName.NodeDetail]: nodeDetailCommandsSchema,
  [ModelName.Invitation]: invitationCommandsSchema,
  [ModelName.InvitationRedemption]: invitationRedemptionCommandsSchema,
} satisfies CommandsSchema

export type Commands = typeof commands

export const commandValidation = {
  [ModelName.User]: userValidation,
  [ModelName.Organization]: organizationValidation,
  [ModelName.OrganizationMember]: organizationMemberValidation,
  [ModelName.Team]: teamValidation,
  [ModelName.TeamMember]: teamMemberValidation,
  [ModelName.Document]: documentValidation,
  [ModelName.DocumentMember]: documentMemberValidation,
  [ModelName.Process]: processValidation,
  [ModelName.ProcessMember]: processMemberValidation,
  [ModelName.Revision]: revisionValidation,
  [ModelName.NodeDetail]: nodeDetailValidation,
  [ModelName.Invitation]: invitationValidation,
  [ModelName.InvitationRedemption]: invitationRedemptionValidation,
} satisfies CommandValidationConstraints

export type CommandValidation = typeof commandValidation

export type CommandParams<
  Model extends ModelName,
  Command extends keyof Commands[Model],
> =
  // @ts-ignore
  z.infer<Commands[Model][Command]['schemas']['input']>

export const authorized = (): ValidationResult => ({
  valid: true,
})

export const unauthorized = (message: string): ValidationResult => ({
  valid: false,
  reason: {
    type: 'unauthorized',
    message,
  },
})

export const valid = (): ValidationResult => ({
  valid: true,
})

export const invalid = (message: string): ValidationResult => ({
  valid: false,
  reason: {
    type: 'invalid',
    message,
  },
})

export const error = (message: string): ValidationResult => ({
  valid: false,
  reason: {
    type: 'error',
    message,
  },
})

export const valueMatches = (
  possibleValues: string[],
  fieldValue?: string | null,
) => (fieldValue ? possibleValues.includes(fieldValue) : false)

export type CommandsSchema = {
  [Model in ModelName]: ModelCommandsSchema
}

export type CommandIdentifiersConstraints = {
  [Model in ModelName]:
    | ZodUndefined
    | ZodObject<any>
    | ZodUnion<readonly [ZodTypeAny, ...ZodTypeAny[]]>
}

export type ModelCommandsSchema = {
  [command: string]: CommandDefinition
}

type CommandInputSchema = ZodObject<{
  [prop: string]: ZodTypeAny
}>

type CommandValidationSchema = {
  sender: {
    [prop: string]: ZodTypeAny
  }
  target: {
    [prop: string]: ZodTypeAny
  }
  meta: {
    [prop: string]: ZodTypeAny
  }
}

export type CommandSchemaMessages = {
  processing?: string
  success?: string
  failure?: string
}

export type CommandDefinition = {
  summary: string
  description?: string
  method: 'POST' | 'PATCH' | 'DELETE'
  icon?: IconName
  schemas: {
    input: CommandInputSchema | undefined
    output: ZodTypeAny
    validate: CommandValidationSchema
    authorize: CommandValidationSchema
  }
  messages: CommandSchemaMessages
  variables: {
    execute: string // e.g. 'createDocument'
    isPending: string // e.g. isCreatingDocument
  }
}

export type CommandValidationConstraints = {
  [Model in ModelName]: ModelCommandsValidation<Model>
}

export type ModelCommandsValidation<Model extends ModelName> = {
  [Command in keyof Commands[Model]]: CommandValidationDefinition<
    Model,
    Command
  >
}

export type CommandValidationDefinition<
  Model extends ModelName,
  Command extends keyof Commands[Model],
> = {
  validate: (
    ctx: CommandValidationContext<
      // @ts-ignore
      Commands[Model][Command]['schemas']['validate']
    >,
  ) => ValidationResult
  authorize: (
    ctx: CommandValidationContext<
      // @ts-ignore
      Commands[Model][Command]['schemas']['authorize']
    >,
  ) => ValidationResult
}

export type CommandValidationContext<T extends CommandValidationSchema> = {
  [U in keyof T]: {
    [V in keyof T[U]]: z.infer<
      // @ts-ignore
      T[U][V]
    >
  }
}

export const getCommandInputSchema = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
>(
  model: Model,
  command: Command,
  // @ts-ignore
): Commands[Model][Command]['schemas']['input'] extends {}
  ? // @ts-ignore
    ZodObject<{
      key: ModelQueryKeyShape[Model]
      // @ts-ignore
      params: Commands[Model][Command]['schemas']['input']
    }>
  : ZodObject<{
      key: ModelQueryKeyShape[Model]
    }> => {
      // @ts-ignore
  const params = commands[model][command]['schemas']['input']

  if (params) {
    // @ts-ignore
    return z.object({
      key: ModelQueryKey[model],
      params,
    })
  } else {
    // @ts-ignore
    return z.object({
      key: ModelQueryKey[model],
    })
  }
}

export const getCommandOutputSchema = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
>(
  model: Model,
  command: Command,
) =>
  // @ts-ignore
  commands[model][command]['schemas']['output'] as CommandOutput<Model, Command>

export const parseCommandInputSchema = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
>(
  model: Model,
  command: Command,
  input: CommandInput<Model, Command>,
) => {
  return getCommandInputSchema(model, command).parse(input)
}

export type CommandParameters<
  Model extends ModelName,
  Command extends keyof Commands[Model],
  // @ts-ignore
> = Commands[Model][Command]['schemas']['input'] extends undefined
  ? undefined
  : z.infer<
      // @ts-ignore
      Commands[Model][Command]['schemas']['input']
    >

export type CommandInput<
  Model extends ModelName,
  Command extends keyof Commands[Model],
  PartialParams extends boolean = false,
> =
  CommandParameters<Model, Command> extends undefined
    ? {
        key: z.infer<ModelQueryKeyShape[Model]>
      }
    : {
        key: z.infer<ModelQueryKeyShape[Model]>
        params: PartialParams extends true
          ? Partial<CommandParameters<Model, Command>>
          : CommandParameters<Model, Command>
      }

export type CommandOutput<
  Model extends ModelName,
  Command extends keyof Commands[Model],
  // @ts-ignore
> = z.infer<Commands[Model][Command]['schemas']['output']>

export type ValidationResult = {
  valid: boolean
  reason?: {
    type: 'invalid' | 'unauthorized' | 'error'
    message: string
  }
}

export const getCommand = <
  Model extends keyof Commands,
  Command extends keyof Commands[Model],
>(
  model: Model,
  command: Command,
) => commands[model][command]
