import { TRPCError } from '@trpc/server'
import { z } from 'zod'
import { ConnectorSchema, InputConnector } from '~/schemas/connector-schema'
import {
  NodeSchemaBase,
  NodeSchemaSubprocess,
  InputNode,
  NodeType,
} from '~/schemas/node-schema'
import { ValidationError } from '~/schemas/schema-helpers'
import { parseContent, ProcessContent } from '~/schemas/revision-schema'

export const validateAsRequest = <T extends any, U>(
  fn: (content: ProcessContent, input: T) => U,
  content: ProcessContent,
  input: T,
): U => {
  try {
    return fn(content, input)
  } catch (e) {
    if (e instanceof ValidationError) {
      throw new TRPCError({ code: 'BAD_REQUEST', message: e.message, cause: e })
    }
    throw e
  }
}

/**
 * Actions
 */

const AddNodeBase = z.object({
  title: NodeSchemaBase.shape.title.default(''),
})

const AddStepNode = AddNodeBase.extend({
  type: z.literal(NodeType.Step).default(NodeType.Step),
})

const AddSwitchNode = AddNodeBase.extend({
  type: z.literal(NodeType.Switch),
  decisionAnswers: z
    .tuple([z.string(), z.string()])
    .default(['Option 1', 'Option 2']),
})

const AddDecisionNode = AddNodeBase.extend({
  type: z.literal(NodeType.Decision),
  decisionAnswers: z.tuple([z.string(), z.string()]).default(['Yes', 'No']),
})

const AddSubprocessNode = AddNodeBase.extend({
  type: z.literal(NodeType.Subprocess),
  subprocessId: NodeSchemaSubprocess.shape.subprocessId,
  subprocessRevisionId: NodeSchemaSubprocess.shape.subprocessRevisionId,
})

const AddEndNode = AddNodeBase.extend({
  type: z.literal(NodeType.End),
})

export const AddStepInput = z.object({
  originId: NodeSchemaBase.shape.id,
  position: ConnectorSchema.shape.position,
  step: z
    .discriminatedUnion('type', [
      AddStepNode,
      AddDecisionNode,
      AddSwitchNode,
      AddSubprocessNode,
      AddEndNode,
    ])
    .default(AddStepNode.parse({})),
})
export type AddStepInput = z.input<typeof AddStepInput>

export const addStep = (content: ProcessContent, args: AddStepInput) => {
  const input = AddStepInput.parse(args)
  const TEMP_ID = 'x'
  const nodes = [
    ...content.nodes,
    {
      id: TEMP_ID,
      ...input.step,
    },
  ]

  // Update the existing connector to point to the new node
  const inboundConnector = content.connectors.find(
    (x) => x.position === input.position && x.originId === input.originId,
  )

  let connectorText: string[] = []
  switch (input.step.type) {
    case NodeType.Step:
      connectorText = ['']
      break
    case NodeType.Subprocess:
      connectorText = ['']
      break
    case NodeType.End:
      connectorText = []
      break
    case NodeType.Decision:
      connectorText = input.step.decisionAnswers
      break
    case NodeType.Switch:
      connectorText = input.step.decisionAnswers
      break
  }

  let connectors: InputConnector[] = [
    ...content.connectors,
    ...connectorText.map((text, i) => ({
      originId: TEMP_ID,
      // Only update the first connector in case of multiple
      targetId: i === 0 ? inboundConnector?.targetId ?? null : null,
      position: i,
      text,
    })),
  ]

  if (inboundConnector) {
    // Update the connector in the list
    connectors = connectors.map((x) =>
      x === inboundConnector
        ? {
            ...x,
            targetId: TEMP_ID,
          }
        : x,
    )
  } else {
    throw new ValidationError('Connector does not exist')
  }

  return parseContent({
    nodes,
    connectors,
  })
}

export const RemoveStepInput = z.object({
  id: NodeSchemaBase.shape.id,
})
export type RemoveStepInput = z.input<typeof RemoveStepInput>

export const removeStep = (content: ProcessContent, args: RemoveStepInput) => {
  const input = RemoveStepInput.parse(args)
  const nodes = content.nodes.filter((x) => x.id !== input.id)

  if (nodes.length === content.nodes.length) {
    throw new ValidationError('Node not found')
  }

  const nodeConnectors = content.connectors.filter(
    (x) => x.originId === input.id,
  )

  // Removed node's target is used to pass connecting
  //  node through to following node
  const nodeTargetId = nodeConnectors[0]?.targetId ?? null

  const connectors = content.connectors
    // Filter connectors originating from removed node
    .filter((x) => x.originId !== input.id)
    // Pass connectors through removed node
    .map((x) => ({
      ...x,
      targetId: x.targetId === input.id ? nodeTargetId : x.targetId,
    }))
    // Re-run filter to remove connection if node
    //  now points back to itself
    .filter((x) => x.originId !== x.targetId)

  return parseContent({
    nodes,
    connectors,
  })
}

export const SetStepTargetInput = z.object({
  originId: NodeSchemaBase.shape.id,
  position: ConnectorSchema.shape.position,
  targetId: NodeSchemaBase.shape.id.nullable(),
})
export type SetStepTargetInput = z.input<typeof SetStepTargetInput>

export const setStepTarget = (
  content: ProcessContent,
  args: SetStepTargetInput,
) => {
  const input = SetStepTargetInput.parse(args)
  let connectors = content.connectors

  // Update the existing connector to point to the new node
  const updateConnector = connectors.find(
    (x) => x.position === input.position && x.originId === input.originId,
  )

  if (!updateConnector) {
    throw new ValidationError('Connector does not exist')
  }

  // Update the connector in the list
  connectors = connectors.map((x) =>
    x === updateConnector
      ? {
          ...x,
          targetId: input.targetId,
        }
      : x,
  )

  return parseContent({
    nodes: content.nodes,
    connectors,
  })
}

export const SetStepTitleInput = NodeSchemaBase.pick({
  id: true,
  title: true,
})
export type SetStepTitleInput = z.input<typeof SetStepTitleInput>

export const setStepTitle = (
  content: ProcessContent,
  args: SetStepTitleInput,
) => {
  const input = SetStepTitleInput.parse(args)
  const node = content.nodes.find((x) => x.id === input.id)

  if (!node) {
    throw new ValidationError('Step does not exist')
  }

  // Update the node in the list
  const nodes = content.nodes.map((x) =>
    x === node
      ? {
          ...x,
          title: input.title,
        }
      : x,
  )

  return parseContent({
    nodes,
    connectors: content.connectors,
  })
}

export const SetStepSubprocessInput = NodeSchemaSubprocess.pick({
  id: true,
  subprocessId: true,
  subprocessRevisionId: true,
})
export type SetStepSubprocessInput = z.input<typeof SetStepSubprocessInput>

export const setStepSubprocess = (
  content: ProcessContent,
  args: SetStepSubprocessInput,
) => {
  const input = SetStepSubprocessInput.parse(args)
  const node = content.nodes.find((x) => x.id === input.id)

  if (!node) {
    throw new ValidationError('Step does not exist')
  }

  // Update the node in the list
  const nodes = content.nodes.map((x) =>
    x === node
      ? {
          ...x,
          subprocessId: input.subprocessId,
          subprocessRevisionId: input.subprocessRevisionId,
        }
      : x,
  )

  return parseContent({
    nodes,
    connectors: content.connectors,
  })
}

const ChangeStepNode = z.object({
  type: z.literal(NodeType.Step).default(NodeType.Step),
})

const ChangeDecisionNode = z.object({
  type: z.literal(NodeType.Decision),
  decisionAnswers: z.tuple([z.string(), z.string()]).default(['Yes', 'No']),
})

const ChangeSwitchNode = z.object({
  type: z.literal(NodeType.Switch),
  decisionAnswers: z
    .tuple([z.string(), z.string()])
    .default(['Option 1', 'Option 2']),
})

const ChangeSubprocessNode = z.object({
  type: z.literal(NodeType.Subprocess),
  subprocessId: NodeSchemaSubprocess.shape.subprocessId,
  subprocessRevisionId: NodeSchemaSubprocess.shape.subprocessRevisionId,
})

const ChangeEndNode = z.object({
  type: z.literal(NodeType.End),
})

export const ChangeStepTypeInput = z.object({
  id: NodeSchemaBase.shape.id,
  step: z.discriminatedUnion('type', [
    ChangeStepNode,
    ChangeDecisionNode,
    ChangeSwitchNode,
    ChangeSubprocessNode,
    ChangeEndNode,
  ]),
})
export type ChangeStepTypeInput = z.input<typeof ChangeStepTypeInput>

export const changeStepType = (
  content: ProcessContent,
  args: ChangeStepTypeInput,
) => {
  const input = ChangeStepTypeInput.parse(args)
  const node = content.nodes.find((x) => x.id === input.id)

  if (!node) {
    throw new ValidationError('Step does not exist')
  }

  // Update the node in the list
  const nodes = content.nodes.map((x) =>
    x === node
      ? ({
          ...x,
          type: input.step.type,
        } as InputNode)
      : x,
  )

  if (input.step.type === node.type) {
    throw new ValidationError('Node type must be different')
  }

  // Get current connectors
  const currentConnectors = content.connectors.filter(
    (x) => x.originId === node.id,
  )

  // Get all connectors excluding node's current
  let connectors = content.connectors.filter((x) => x.originId !== node.id)

  // Get connectors for node type
  let connectorText: string[] = []
  switch (input.step.type) {
    case NodeType.Step:
      connectorText = ['']
      break
    case NodeType.Subprocess:
      connectorText = ['']
      break
    case NodeType.End:
      connectorText = []
      break
    case NodeType.Decision:
      connectorText = input.step.decisionAnswers
      break
    case NodeType.Switch:
      connectorText = input.step.decisionAnswers
      break
  }

  // Add node connectors back into list
  connectors = [
    ...connectors,
    ...connectorText.map((text, i) => ({
      originId: node.id,
      targetId:
        currentConnectors.find((x) => x.position === i)?.targetId ?? null,
      position: i,
      text,
    })),
  ]

  return parseContent({
    nodes,
    connectors,
  })
}

export const SetConnectorTextInput = ConnectorSchema.pick({
  originId: true,
  position: true,
  text: true,
})
export type SetConnectorTextInput = z.infer<typeof SetConnectorTextInput>

export const setConnectorText = (
  content: ProcessContent,
  args: SetConnectorTextInput,
) => {
  const input = SetConnectorTextInput.parse(args)
  const connector = content.connectors.find(
    (x) => x.originId === input.originId && x.position === input.position,
  )

  if (!connector) {
    throw new ValidationError('Connector does not exist at position')
  }

  // Update the connector in the list
  const connectors = content.connectors.map((x) =>
    x === connector
      ? {
          ...x,
          text: input.text,
        }
      : x,
  )

  return parseContent({
    nodes: content.nodes,
    connectors,
  })
}

export const AddConnectorInput = ConnectorSchema.pick({
  originId: true,
  targetId: true,
  text: true,
})
export type AddConnectorInput = z.infer<typeof AddConnectorInput>

export const addConnector = (
  content: ProcessContent,
  args: AddConnectorInput,
) => {
  const input = AddConnectorInput.parse(args)

  // Get the node at the origin
  const node = content.nodes.find((x) => x.id === input.originId)

  if (!node) {
    throw new ValidationError('Origin node does not exist')
  }

  if (node.type !== NodeType.Switch) {
    throw new ValidationError('Cannot add connector to this node type')
  }

  // Get current connectors
  const currentConnectors = content.connectors.filter(
    (x) => x.originId === input.originId,
  )

  const highestPosition = Math.max(
    ...currentConnectors.map((x) => x.position),
    -1,
  )

  // Append the connector to existing
  const connectors = [
    ...content.connectors,
    {
      ...input,
      position: highestPosition + 1,
    },
  ]

  return parseContent({
    nodes: content.nodes,
    connectors,
  })
}

export const DeleteConnectorInput = ConnectorSchema.pick({
  originId: true,
  position: true,
})
export type DeleteConnectorInput = z.infer<typeof DeleteConnectorInput>

export const deleteConnector = (
  content: ProcessContent,
  args: DeleteConnectorInput,
) => {
  const input = DeleteConnectorInput.parse(args)

  // Get the node at the origin
  const node = content.nodes.find((x) => x.id === input.originId)

  if (!node) {
    throw new ValidationError('Origin node does not exist')
  }

  if (node.type !== NodeType.Switch) {
    throw new ValidationError('Cannot delete a connector from this node type')
  }

  // Filter the deleted connector
  let connectors = content.connectors.filter(
    (x) => x.originId !== input.originId || x.position !== input.position,
  )

  if (connectors.length === content.connectors.length) {
    throw new ValidationError('Connector does not exist at position')
  }

  // Update affected connector positions
  connectors = connectors.map((x) => {
    if (x.originId !== input.originId || x.position < input.position) return x
    return { ...x, position: x.position - 1 }
  })

  return parseContent({
    nodes: content.nodes,
    connectors,
  })
}

export const ReorderConnectorsInput = z.object({
  nodeId: NodeSchemaBase.shape.id,
  order: z.array(ConnectorSchema.shape.position),
})

export type ReorderConnectorsInput = z.infer<typeof ReorderConnectorsInput>

export const reorderConnectors = (
  content: ProcessContent,
  args: ReorderConnectorsInput,
) => {
  const input = ReorderConnectorsInput.parse(args)

  // Get the node at the origin
  const node = content.nodes.find((x) => x.id === input.nodeId)

  if (!node) {
    throw new ValidationError('Origin node does not exist')
  }

  if (node.type !== NodeType.Switch) {
    throw new ValidationError('Cannot delete a connector from this node type')
  }

  // Get all node connectors
  let previousConnectors = content.connectors.filter(
    (x) => x.originId === node.id,
  )

  // Map unput order to their respective nodes
  const nodeConnectors = input.order.map((position, i) => {
    const connector = previousConnectors.find((x) => x.position === position)
    if (!connector) {
      throw new ValidationError(
        'Connector does not exist at position: ' + position,
      )
    }
    return {
      ...connector,
      position: i,
    }
  })

  // Get all connectors excluding node's current
  let otherConnectors = content.connectors.filter((x) => x.originId !== node.id)

  return parseContent({
    nodes: content.nodes,
    connectors: [...otherConnectors, ...nodeConnectors],
  })
}

export const ProcessActions = {
  addStep,
  removeStep,
  setStepTarget,
  setStepTitle,
  setStepSubprocess,
  setConnectorText,
  changeStepType,
  addConnector,
  deleteConnector,
  reorderConnectors,
} satisfies {
  [name: string]: (content: ProcessContent, input: any) => ProcessContent
}

export type ProcessAction = (typeof ProcessActions)[keyof typeof ProcessActions]

/**
 * Helpers
 */

export const getContentDifference = (
  before: ProcessContent = { nodes: [], connectors: [] },
  after: ProcessContent = { nodes: [], connectors: [] },
) => {
  const nodeIds = new Set<number>()
  before.nodes.forEach((x) => nodeIds.add(x.id))

  const addedNodeIds: number[] = []
  const removedNodeIds = new Set(nodeIds)

  after.nodes.forEach((x) => {
    if (!nodeIds.has(x.id)) addedNodeIds.push(x.id)
    removedNodeIds.delete(x.id)
  })

  return {
    addedNodeIds,
    removedNodeIds: Array.from(removedNodeIds),
  }
}
