'use client'

import {
  ActionIcon,
  ActionIconProps,
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Button,
  Combobox,
  ComboboxOptionProps,
  ComboboxProps,
  ComboboxStore,
  Divider,
  DividerProps,
  Group,
  HoverCard,
  Input,
  InputBase,
  Loader,
  Menu,
  MenuProps,
  Pill,
  PortalProps,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  TextInputProps,
  TextProps,
  Title,
  Tooltip,
  useCombobox,
} from '@mantine/core'
import { useClipboard, useId } from '@mantine/hooks'
import { DocumentRole, MemberStatus, OrganizationRole } from '@prisma/client'
import dayjs from 'dayjs'
import {
  MouseEventHandler,
  MutableRefObject,
  ReactNode,
  RefObject,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'wouter'
import { Icon, IconName } from '~/client/dashboard/components/global/Icon'
import { roleColors, useThemeColor } from '~/client/dashboard/components/global/colors'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useGlobalContext } from '~/client/dashboard/stores/GlobalStore'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { Column, Row } from '~/client/shared/Layout'
import { toast } from '~/client/shared/Toast'
import { globalIcons } from '~/client/shared/data/global-data'
import { useColors } from '~/client/shared/hooks/useColors'
import { useThemeVars } from '~/client/shared/hooks/useThemeVars'
import {
  WithCommand,
  WithCommandProps,
  WithCommandResult,
} from '~/commands/WithCommand'
import { CommandParams, Commands, ModelName } from '~/commands/base-commands'
import { ModelQueryKey } from '~/schemas'
import { groupBy } from '~/utils/logic'
import classes from './Prefabs.module.css'

export const OrganizationLogo = ({
  imageUrl,
  name,
  size,
}: {
  imageUrl: string | null
  name?: string
  size: number
}) => {
  const getAvatarColor = useGlobalContext((x) => x.getAvatarColor)

  return (
    <Avatar
      src={imageUrl ?? ''}
      size={size}
      alt={name ? `${name} logo` : ''}
      radius="xs"
      {...(imageUrl || !name
        ? {}
        : {
            variant: 'gradient',
            gradient: {
              from: getAvatarColor(name).toString(),
              to: getAvatarColor(name).spin(45).toString(),
              deg: 90,
            },
          })}
    >
      <Icon
        name={globalIcons.OrganizationLogo}
        size={size * 0.7}
        style={{ opacity: 0.7 }}
      />
    </Avatar>
  )
}

export const TeamLogo = ({
  imageUrl,
  name,
  size,
}: {
  imageUrl: string | null
  name?: string
  size: number
}) => {
  const getAvatarColor = useGlobalContext((x) => x.getAvatarColor)

  return (
    <Avatar
      src={imageUrl ?? ''}
      size={size}
      alt={name ? `Team ${name} logo` : ''}
      radius="md"
      styles={{
        placeholder: {
          color: 'white',
        },
      }}
      style={{
        background:
          imageUrl || !name ? 'none' : getAvatarColor(name).toString(),
      }}
    >
      <Icon
        name={globalIcons.TeamLogo}
        size={size * 0.7}
        style={{ opacity: 0.7 }}
      />
    </Avatar>
  )
}

export const UserAvatar = ({
  imageUrl,
  username,
  size,
}: {
  imageUrl: string | null
  username: string
  size: number
}) => {
  const getAvatarColor = useGlobalContext((x) => x.getAvatarColor)

  return (
    <Avatar
      src={imageUrl ?? ''}
      size={size}
      alt={`${username ?? 'User'}'s profile image`}
      styles={{
        placeholder: {
          color: 'white',
          opacity: 0.8,
        },
      }}
      style={{
        borderRadius: '50%',
        background: getAvatarColor(username).toString(),
      }}
    >
      {username.split(' ').map((x) => x[0])}
    </Avatar>
  )
}

export const MemberAvatar = forwardRef(
  (
    {
      id,
      size,
      glance,
    }: {
      id: string
      size: number
      glance: boolean
    },
    ref,
  ) => {
    const getAvatarColor = useGlobalContext((x) => x.getAvatarColor)
    const organizationId = useOrganizationContext((x) => x.organization.id)
    const { organizationMember } = useOrganizationMemberQuery({
      id,
      organizationId,
    })

    const avatar = (
      <Avatar
        ref={ref as RefObject<HTMLDivElement>}
        src={organizationMember?.imageUrl ?? ''}
        size={size}
        alt={
          glance ? '' : `${organizationMember?.alias ?? 'User'}'s profile image`
        }
        styles={{
          placeholder: {
            color: 'white',
            opacity: 0.8,
          },
        }}
        style={{
          borderRadius: '50%',
          background:
            organizationMember?.alias && organizationMember.userId
              ? getAvatarColor(organizationMember?.alias).toString()
              : Color.gray(700),
        }}
      >
        {organizationMember?.userId ? (
          organizationMember?.alias?.split(' ').map((x) => x[0])
        ) : (
          <Icon name="MdNoAccounts" size="85%" />
        )}
      </Avatar>
    )

    if (glance) return <MemberGlance id={id}>{avatar}</MemberGlance>
    return avatar
  },
)

export const MemberGlance = ({
  id,
  children,
}: {
  id: string
  children: ReactNode
}) => {
  return (
    <HoverCard
      position="top"
      shadow="md"
      withArrow
      openDelay={400}
      closeDelay={100}
    >
      <HoverCard.Target>
        <Box>{children}</Box>
      </HoverCard.Target>
      <HoverCard.Dropdown onClick={(e) => e.stopPropagation()}>
        <MemberCard id={id} />
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export const MemberCard = ({ id }: { id: string }) => {
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const { organizationMember } = useOrganizationMemberQuery({
    id,
    organizationId,
  })

  return (
    <Group mb={10} align="center">
      {!organizationMember && <Loader color="gray" />}
      {organizationMember && (
        <>
          <Row pos="relative" w={50} justify="center">
            <MemberAvatar glance={false} id={organizationMember.id} size={50} />
            <Row
              w="100%"
              justify="center"
              pos="absolute"
              style={{ bottom: -12 }}
            >
              <MemberRoleBadge role={organizationMember.role} />
            </Row>
          </Row>
          <Stack mt={8} mr={6} gap={0}>
            <Group justify="flex-start">
              <Text
                truncate="end"
                size="lg"
                c="var(--text-color-important)"
                fw={700}
                maw={240}
              >
                {organizationMember?.alias}
              </Text>
            </Group>
            {organizationMember.title && (
              <Text size="md" opacity={0.7}>
                {organizationMember.title}
              </Text>
            )}
          </Stack>
        </>
      )}
    </Group>
  )
}

export const DocumentParticipantBadge = ({
  role,
  ...props
}: { role: DocumentRole } & BadgeProps) => {
  if (role !== DocumentRole.Owner) return null

  return (
    <Badge variant="outline" color={'green.4'} {...props}>
      {role}
    </Badge>
  )
}

export const MemberRoleBadge = ({ role }: { role: OrganizationRole }) => {
  const isDarkMode = useGlobalContext((x) => x.isDarkMode)

  return (
    <Pill
      c={isDarkMode ? roleColors[role][200] : roleColors[role][700]}
      bg={isDarkMode ? roleColors[role][800] : roleColors[role][100]}
      size="xs"
      fw={700}
    >
      {role}
    </Pill>
  )
}

export const OwnerBadge = () => {
  return (
    <Tooltip label="Organization owner">
      <Box c="yellow">
        <Icon name="PiSealDuotone" size={16} />
      </Box>
    </Tooltip>
  )
}

export const MemberStatusIndicator = ({
  status,
  withLabel = true,
}: {
  status: MemberStatus
  withLabel?: boolean
}) => {
  return (
    <Row gap={rem(6)}>
      <Box
        w={rem(12)}
        h={rem(12)}
        bg={status === MemberStatus.Active ? 'green' : 'gray'}
        style={{ borderRadius: '50%' }}
      />
      {withLabel && (
        <Text>{status === MemberStatus.Active ? 'Active' : 'Inactive'}</Text>
      )}
    </Row>
  )
}

type DropdownButtonProps = {
  icon?: IconName
  size?: number
  colorWeight?: number
  opened?: boolean
  withinPortal?: boolean
  portalProps?: Omit<PortalProps, 'children'>
  dropdownRef?: MutableRefObject<HTMLDivElement | null>
  buttonRef?: MutableRefObject<HTMLButtonElement | null>
  width?: number
  scale?: number
  transitionProps?: MenuProps['transitionProps']
  position?: MenuProps['position']
  onClose?: () => void
  onClick?: MouseEventHandler
} & ActionIconProps
export const DropdownButton = ({
  icon = 'PiDotsThreeBold',
  size = 30,
  width,
  scale = 1,
  variant = 'light',
  withinPortal = true,
  portalProps,
  dropdownRef,
  buttonRef,
  transitionProps,
  children,
  opened,
  position,
  onClose,
  onClick,
  ...props
}: DropdownButtonProps) => {
  const theme = useThemeColor()
  return (
    <Menu
      opened={opened}
      onClose={onClose}
      shadow="md"
      withArrow={true}
      offset={5 * scale}
      width={width}
      withinPortal={withinPortal}
      portalProps={portalProps}
      transitionProps={transitionProps}
      position={position}
      arrowOffset={size / 2}
    >
      <Menu.Target>
        <ActionIcon
          variant={variant}
          size={size}
          onClick={onClick}
          color="gray"
          styles={{
            root: {
              transform: `scale(${Math.max(scale, 1)})`,
              transition: '100ms ease transform',
            },
          }}
          style={{
            borderRadius: '50%',
            color: theme(['gray', 6], ['dark', 0], true),
          }}
          ref={buttonRef}
          {...props}
        >
          <Icon name={icon} size={size * 0.8} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown
        ref={dropdownRef}
        miw={rem(140)}
        style={{
          pointerEvents: 'auto',
          cursor: 'default',
          transform: `scale(${scale})`,
        }}
      >
        {children}
      </Menu.Dropdown>
    </Menu>
  )
}

export const MenuDivider = (props: DividerProps) => {
  const theme = useThemeColor()
  return (
    <Divider
      my="sm"
      {...props}
      style={{
        borderColor: theme(['gray', 3], ['dark', 3], true),
        ...props.style,
      }}
    />
  )
}

export const ColorSchemeToggle = () => {
  const { toggleColorScheme, colorScheme } = useColors()

  return (
    <SegmentedControl
      value={colorScheme}
      onChange={() => toggleColorScheme()}
      w="100%"
      data={[
        {
          value: 'light',
          label: (
            <Row justify="center">
              <Icon name="PiSun" />
              <Box ml={10}>Light</Box>
            </Row>
          ),
        },
        {
          value: 'dark',
          label: (
            <Row justify="center">
              <Icon name="PiMoon" />
              <Box ml={10}>Dark</Box>
            </Row>
          ),
        },
      ]}
    />
  )
}

export type PageTitleBreadcrumb = { name: string; href?: string }
export type PageTitleOption = {
  Title: (props: { isSelected: boolean; isHeader: boolean }) => ReactNode
  href: string
  group?: string
}

export const PageTitle = ({
  title,
  options,
  selectedHref,
  breadcrumbs = [],
  stack = true,
  allowXL = false,
  rightSection,
}: {
  title?: string | ReactNode
  selectedHref?: string
  options?: PageTitleOption[]
  breadcrumbs?: PageTitleBreadcrumb[]
  stack?: boolean
  allowXL?: boolean
  rightSection?: ReactNode
}) => {
  const deviceSize = useGlobalContext((x) => x.deviceSize)
  // Take last three
  const topCrumbs = breadcrumbs.slice(Math.max(breadcrumbs.length - 3, 0))
  const showTop = stack && topCrumbs.length > 0 && !deviceSize.isSmall

  return (
    <Row gap="md" align="flex-start" justify="space-between">
      <Title
        order={deviceSize.isSmall ? 3 : deviceSize.isMedium || !allowXL ? 2 : 1}
        mb="1rem"
      >
        {!stack && (
          <Row>
            {breadcrumbs.length > 0 &&
              breadcrumbs.map((x, i) => <PageTitleBreadcrumb key={i} {...x} />)}
            <Box style={{ overflow: 'hidden' }}>
              {options && (
                <PageTitleSelect
                  selectedHref={selectedHref}
                  options={options}
                />
              )}
              {!options && title}
            </Box>
          </Row>
        )}
        {stack && (
          <Column align="flex-start" miw="100%" gap="xs">
            {showTop && (
              <Row
                fz={deviceSize.isLarge ? 'md' : 'sm'}
                align="center"
                miw="100%"
                wrap="wrap"
              >
                {topCrumbs.length > 0 &&
                  topCrumbs.map((x, i) => (
                    <PageTitleBreadcrumb key={i} {...x} />
                  ))}
              </Row>
            )}
            <Box style={{ overflow: 'hidden' }}>
              {options && (
                <PageTitleSelect
                  selectedHref={selectedHref}
                  options={options}
                />
              )}
              {!options && title}
            </Box>
          </Column>
        )}
      </Title>
      {rightSection && (
        <Box
          pos="relative"
          top={showTop ? rem(-8) : deviceSize.isSmall ? rem(3) : 0}
        >
          {rightSection}
        </Box>
      )}
    </Row>
  )
}

const PageTitleBreadcrumb = ({
  href,
  name,
  maw,
}: PageTitleBreadcrumb & { maw?: string | number }) => {
  return (
    <Row maw={maw}>
      {href && (
        <Text
          className={classes.pageTitleBreadcrumb}
          component={Link}
          href={href}
          data-link={true}
          mr="0.3em"
        >
          {name}
        </Text>
      )}
      {!href && (
        <Text className={classes.pageTitleBreadcrumb} mr="0.25em">
          {name}
        </Text>
      )}
      <Box opacity={0.5} mr="0.3em">
        <Icon name="PiCaretRightBold" size="0.7em" />
      </Box>
    </Row>
  )
}

const PageTitleSelect = ({
  selectedHref,
  options,
}: {
  selectedHref?: string
  options: PageTitleOption[]
}) => {
  const vars = useThemeVars()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const optionGroups = groupBy(options, (x) => x.group)
  const selectedOption = options.find((x) => x.href === selectedHref)

  const Option = ({ item }: { item: PageTitleOption }) => {
    const isSelected = selectedOption?.href === item.href
    return (
      <Link href={item.href} asChild>
        <Text component="a" w="100%" h="100%">
          <Combobox.Option value={item.href} px={6} py="sm" fw={500}>
            <Row justify="space-between" gap="xs" ff="text" fz="md">
              <item.Title isSelected={isSelected} isHeader={false} />
              {isSelected && <Icon name="PiCheckBold" />}
            </Row>
          </Combobox.Option>
        </Text>
      </Link>
    )
  }

  return (
    <Combobox
      classNames={classes}
      store={combobox}
      withinPortal={false}
      withArrow={true}
      position="top-start"
      onOptionSubmit={() => {
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          pointer
          rightSection={
            <Combobox.Chevron className={classes.pageTitleChevron} />
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          fz="inherit"
          fw="inherit"
          lh={1.2}
          classNames={{
            input: classes.pageTitleSelectButton,
          }}
        >
          {selectedOption && (
            <selectedOption.Title isSelected={false} isHeader={true} />
          )}
          {!selectedOption && (
            <Input.Placeholder ff={vars.fontFamily} fz="lg" fw={400}>
              Choose...
            </Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown maw={260} miw={200}>
        <Combobox.Options>
          {options.length === 0 && (
            <Combobox.Empty>
              <Text style={{ fontFamily: vars.fontFamily }}>Nothing found</Text>
            </Combobox.Empty>
          )}
          {Object.entries(optionGroups).map(
            ([group, options]: [string | Symbol, PageTitleOption[]], i) =>
              group === 'undefined' ? (
                options.map((x) => <Option item={x} key={x.href} />)
              ) : (
                <Combobox.Group key={i} label={String(group)}>
                  {options.map((x) => (
                    <Option item={x} key={x.href} />
                  ))}
                </Combobox.Group>
              ),
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export const DateText = ({ date, ...props }: TextProps & { date: Date }) => {
  return (
    <Tooltip
      openDelay={500}
      label={dayjs(date).format('MMMM D, YYYY h:mm:ss A')}
    >
      <Text {...props}>{dayjs(date).format('MMM D, YYYY')}</Text>
    </Tooltip>
  )
}

export const CopyInput = (props: TextInputProps & { copyText?: string }) => {
  const clipboard = useClipboard({ timeout: 2000 })

  return (
    <Row w="100%" justify="stretch" gap="xs">
      <TextInput
        {...props}
        styles={{
          input: {
            textOverflow: 'ellipsis',
          },
        }}
        readOnly={true}
        style={{ flexGrow: 1, ...(props.style ?? {}) }}
        rightSectionWidth={84}
        rightSection={
          <Button
            w={80}
            variant="light"
            color={clipboard.copied ? 'green' : 'blue'}
            onClick={() => {
              clipboard.copy(props.copyText ?? props.value)
              toast.success('Link copied to clipboard', { autoClose: 2000 })
            }}
          >
            {clipboard.copied ? 'Copied' : 'Copy'}
          </Button>
        }
      />
    </Row>
  )
}

export type DropdownMenuProps = {
  target: ReactNode
  store: ComboboxStore
} & ComboboxProps
export const DropdownMenu = ({
  target,
  children,
  ...props
}: DropdownMenuProps) => {
  const combobox = props.store

  return (
    <Combobox
      width={180}
      position="bottom-start"
      withArrow={true}
      withinPortal={true}
      arrowOffset={12}
      offset={{
        mainAxis: 10,
      }}
      onOptionSubmit={(val) => {
        combobox.closeDropdown()
      }}
      {...props}
    >
      <Combobox.Target>{target}</Combobox.Target>
      <Combobox.Dropdown
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
      >
        <Combobox.Options>{children}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export const DropdownOption = <
  Model extends ModelName,
  Command extends keyof Commands[Model],
  Key extends ModelQueryKey[Model] | undefined,
  Validate extends Partial<CommandParams<Model, Command>> = {},
>({
  icon,
  children,
  withCommand,
  value,
  type = 'default',
  href,
  onClick,
  ...props
}: {
  icon: IconName
  value?: string
  type?: 'default' | 'warning' | 'danger'
  onClick?: (
    commandResult: WithCommandResult<Model, Command, Key, Validate>,
  ) => void
  href?: string
  withCommand?: Omit<
    WithCommandProps<Model, Command, Key, Validate>,
    'children'
  >
} & Omit<ComboboxOptionProps, 'value' | 'onClick'> & {
    children: WithCommandProps<Model, Command, Key, Validate>['children']
  }) => {
  const deviceType = useGlobalContext((x) => x.deviceType)
  const [commandProps, setCommandProps] =
    useState<WithCommandResult<Model, Command, Key, Validate>>()
  const id = useId()
  value = value ?? id

  type CommandWrapperProps = WithCommandProps<Model, Command, Key, Validate>

  const LinkWrapper = href
    ? ({ children }: { children: ReactNode }) => (
        <Link href={href} asChild>
          <Text component="a" w="100%" h="100%">
            {children}
          </Text>
        </Link>
      )
    : ({ children }: { children: ReactNode }) => children

  const CommandWrapperInner = useMemo(
    () =>
      ({
        props,
        children,
      }: {
        props: WithCommandResult<Model, Command, Key, Validate>
        children: ReactNode
      }) => {
        useEffect(() => {
          if (commandProps?.isPending !== props.isPending) {
            setCommandProps(props)
          }
        }, [commandProps?.isPending, props.isPending])

        return children
      },
    [commandProps],
  )

  const CommandWrapper = withCommand
    ? ({ children }: { children: ReactNode }) => (
        <WithCommand
          notAllowed="disable"
          placeholder={withCommand.notAllowed !== 'hide' ? children : null}
          {...withCommand}
          tooltip={{
            position: 'left',
            ...withCommand.tooltip,
          }}
        >
          {(props) => (
            <CommandWrapperInner props={props}>{children}</CommandWrapperInner>
          )}
        </WithCommand>
      )
    : ({ children }: { children: ReactNode }) => children

  return (
    <LinkWrapper>
      <CommandWrapper>
        <Combobox.Option
          className={classes.dropdownOption}
          disabled={commandProps?.isPending}
          data-type={type}
          onClick={(e) => {
            onClick?.(commandProps!)
          }}
          {...(deviceType.isMobile && {
            py: 'sm',
          })}
          {...props}
          value={value}
        >
          <Row gap={10}>
            <Icon name={icon} size={14} />
            <Text component="div" size="md">
              {children}
            </Text>
          </Row>
        </Combobox.Option>
      </CommandWrapper>
    </LinkWrapper>
  )
}

export const DropdownDivider = () => <Divider my={4} />

export type ImageInputProps = {
  currentImageUrl: string | null
  avatar?: ReactNode
} & Partial<TextInputProps>
export const ImageInput = ({
  currentImageUrl,
  avatar,
  ...inputProps
}: ImageInputProps) => {
  return (
    <TextInput
      leftSection={
        <Box ml="md" mr="md">
          {avatar || <Avatar size={30} src={currentImageUrl} />}
        </Box>
      }
      style={{ flexGrow: 1 }}
      leftSectionWidth={44}
      placeholder="https://..."
      onFocus={(e) => e.currentTarget.select()}
      {...inputProps}
    />
  )
}
