import { cacheOrganizationTeams } from '~/client/dashboard/queries/organization-queries'
import {
  ApiContext,
  cacheEachKey,
  CacheFn,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  parseKey,
  RETRY,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { cacheTeamMember } from '~/client/dashboard/queries/team-member-queries'
import { PartialRequired } from '~/client/globals'
import { TeamQueryKey, teamQueryKeys } from '~/schemas/team-schema'
import { api } from '~/utils/api'
import { isMatch } from '~/utils/logic'
import { ClientModel, ModelQueryKey } from '~/schemas'

export const useTeamQuery = (key: PartialRequired<TeamQueryKey>) => {
  const enabled = TeamQueryKey.safeParse(key).success
  const result = api.Team.get.useQuery(parseKey(key, TeamQueryKey), {
    enabled,
    staleTime: CHILD_ITEM_STALE_TIME,
    gcTime: CHILD_ITEM_CACHE_TIME,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: RETRY,
  })
  const team = result?.data ?? null

  useCacheResult(result, cacheTeam, () => key, false)

  return {
    ...result,
    team,
    isLoadingTeam: result.isPending && enabled,
    isLoadingTeamError: result.isError,
  }
}

export const useTeamMembersQuery = (key: PartialRequired<TeamQueryKey>) => {
  const result = api.Team.getMembers.useQuery(parseKey(key, TeamQueryKey), {
    enabled: TeamQueryKey.safeParse(key).success,
    staleTime: CHILD_ITEM_STALE_TIME,
    gcTime: CHILD_ITEM_CACHE_TIME,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: RETRY,
  })

  useCacheResult(result, cacheTeamMembers, () => key, false)

  return { ...result, teamMembers: result.data ?? [] }
}

/**
 * Cache get
 */

export const getTeamFromCache = (ctx: ApiContext, key: TeamQueryKey) => {
  key = TeamQueryKey.parse(key)
  return ctx.Team.get.getData(key)
}

export const getTeamMembersFromCache = (ctx: ApiContext, key: TeamQueryKey) => {
  key = TeamQueryKey.parse(key)
  return ctx.Team.getMembers.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheTeam: CacheFn<
  ModelQueryKey['Team'],
  ClientModel['Team'],
  boolean
> = (ctx, data, key, itemOnly = false) => {
  key = TeamQueryKey.parse(key)
  const previous = getTeamFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result === null) {
    ctx.Team.get.setData(key, null)
  }

  if (result) {
    cacheEachKey(ctx.Team.get, teamQueryKeys, result, result)
    if (!itemOnly) {
      cacheOrganizationTeams(
        ctx,
        (old) => {
          if (!old) return
          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            return [...old, result]
          } else {
            return old.map((x) => (isMatch(x, key) ? result : x))
          }
        },
        { id: key.organizationId },
        true,
      )
    }
  }
  return {
    previous,
    revert: () => {
      cacheTeam(ctx, previous, key, itemOnly)
    },
  }
}

export const cacheTeamMembers: CacheFn<
  ModelQueryKey['Team'],
  ClientModel['TeamMember'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = TeamQueryKey.parse(key)
  const previous = getTeamMembersFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const team = getTeamFromCache(ctx, key)
    cacheEachKey(ctx.Team.getMembers, teamQueryKeys, team, result)
    if (!listOnly) {
      result.forEach((x) => {
        cacheTeamMember(ctx, x, x, true)
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheTeamMembers(ctx, previous, key, listOnly)
    },
  }
}

/**
 * Cache remove
 */
