import { z } from 'zod'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

/**
 * Invitation schemas
 */

export const InvitationRedemptionSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  invitationId: z.string(),
  organizationId: z.string(),
  memberId: z.string(),
}) satisfies PrismaModelSchema<'OrganizationInvitationRedemption'>

export const ClientInvitationRedemptionSchema = InvitationRedemptionSchema

export type InvitationRedemptionQueryKey = z.infer<
  typeof InvitationRedemptionQueryKey
>
export const InvitationRedemptionQueryKey = z.object({
  id: z.string(),
  invitationId: z.string(),
  organizationId: z.string(),
})

export const invitationRedemptionQueryKeys = [InvitationRedemptionQueryKey]
