'use client'

import { AppShell, ScrollArea } from '@mantine/core'
import ErrorBoundary from '~/client/shared/ErrorBoundary'
import classes from '~/client/dashboard/components/global/DashboardShell.module.css'

export const PageShell = ({
  children,
  header,
}: {
  children: React.ReactNode
  header?: React.ReactNode
}) => {
  return (
    <AppShell
      id="dashboard"
      style={{ height: '100%' }}
      header={{ height: 65, collapsed: !header }}
    >
      {header && <AppShell.Header px="lg">{header}</AppShell.Header>}
      <AppShell.Main>
        <ScrollArea
          h="100%"
          w="100%"
          classNames={{ viewport: classes.scrollViewport }}
        >
          <ErrorBoundary id="PageShell">{children}</ErrorBoundary>
        </ScrollArea>
      </AppShell.Main>
    </AppShell>
  )
}
