import {Divider, Text, Title} from '@mantine/core'
import {PropsWithChildren, ReactNode} from 'react'
import {Box, Column, Row} from '~/client/shared/Layout'

export const RequestError = ({
  code,
  message,
  children,
}: {
  code?: string | number
  message: string
  children?: ReactNode
}) => {
  return (
    <Column align="center" justify="center" w="100%" h="70%" px="xl">
      <Row h="3rem">
        {code && (
          <>
            <Title order={1}>{code}</Title>
            <Divider orientation="vertical" mx="lg" />
          </>
        )}
        <Text fz="lg">{message}</Text>
      </Row>
      {children && <Box mt="xl">{children}</Box>}
    </Column>
  )
}

export const NotFound = ({children}: PropsWithChildren) => {
  return (
    <RequestError code={404} message="Not Found">
      {children}
    </RequestError>
  )
}
export const ServerError = ({children}: PropsWithChildren) => {
  return (
    <RequestError code={500} message="Server Error">
      {children ?? <Text>Something went wrong. Please try again soon.</Text>}
    </RequestError>
  )
}