import { type Ref, useCallback, useState } from 'react'

export const useElementFromRef = function <T extends HTMLElement>(): [
  Ref<T>,
  T | undefined,
] {
  const [el, setEl] = useState<T>()

  const ref = useCallback((node: T) => {
    if (node) {
      setEl(node)
    }
  }, [])

  return [ref, el]
}

export default useElementFromRef
