import { OrganizationRole } from '@prisma/client'
import { z } from 'zod'
import { OrganizationMemberSchema } from '~/schemas/organization-member-schema'
import { replaceNewLine } from '~/utils/logic'
import { OrganizationSchema } from '~/schemas/organization-schema'
import { PrismaModelSchema } from '~/schemas/schema-helpers'

/**
 * Constants
 */

export const INVITATION_NAME_MAX_LENGTH = 50
export const INVITATION_MESSAGE_MAX_LENGTH = 200

/**
 * Fields
 */

const validUntilSchema = z
  .date()
  .min(new Date(), 'Date must be set for the future')

const labelSchema = z
  .string()
  .trim()
  .transform(replaceNewLine)
  .pipe(
    z
      .string()
      .max(
        INVITATION_NAME_MAX_LENGTH,
        `Name must be less than ${INVITATION_NAME_MAX_LENGTH} characters`,
      ),
  )

const inviteMessageSchema = z
  .string()
  .max(
    INVITATION_MESSAGE_MAX_LENGTH,
    `Message must be less than ${INVITATION_MESSAGE_MAX_LENGTH} characters`,
  )

/**
 * Invitation schemas
 */

export const OrganizationInvitationSchema = z.object({
  id: z.string().readonly(),
  createdAt: z.date().readonly(),
  updatedAt: z.date().readonly(),
  organizationId: z.string().readonly(),
  creatorId: z.string().readonly(),
  maxRedemptions: z.number(),
  role: z.nativeEnum(OrganizationRole),
  code: z.string(),
  label: labelSchema.nullable(),
  validUntil: validUntilSchema.nullable(),
  inviteMessage: inviteMessageSchema.nullable(),
  emailToMatch: z.string().email('Email is not of proper format').nullable(),
  memberAlias: OrganizationMemberSchema.shape.alias.nullable(),
  memberImageUrl: OrganizationMemberSchema.shape.imageUrl.nullable(),
  memberTitle: OrganizationMemberSchema.shape.title.nullable(),
  memberDescription: OrganizationMemberSchema.shape.description.nullable(),
  memberIsDisplayLocked: OrganizationMemberSchema.shape.isDisplayLocked,
  isArchived: z.boolean(),
}) satisfies PrismaModelSchema<'OrganizationInvitation'>

const OrganizationInvitationQueryKeys = z.tuple([
  z.object({
    id: z.string(),
    organizationId: z.string(),
  }),
  z.object({
    code: z.string(),
    organizationId: z.string(),
  }),
])
export const organizationInvitationQueryKeys =
  OrganizationInvitationQueryKeys.items

export const OrganizationInvitationQueryKey = z.union(
  OrganizationInvitationQueryKeys.items,
)
export type OrganizationInvitationQueryKey = z.infer<
  typeof OrganizationInvitationQueryKey
>

export enum InvitationValidationError {
  NotFound,
  RedemptionLimitReached,
  Expired,
  AlreadyMember,
}

export const ClientInvitationSchema = OrganizationInvitationSchema
