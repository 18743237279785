'use client'

import { Text } from '@mantine/core'
import { Row } from '~/client/shared/Layout'
import { logoUrl } from '~/client/shared/data/global-data'

// TODO: Change to actual logo
export const LogoIcon = ({ size }: { size: number }) => {
  return <img src={logoUrl} alt="Logo" width={size} height={size} />
}

// TODO: Change to SVG
export const Logo = () => {
  return (
    <Row gap={8}>
      <LogoIcon size={40} />
      <Text fz={rem(32)} data-important>
        About3
      </Text>
    </Row>
  )
}
