import {
  ModelCommandsValidation,
  ModelName,
  authorized,
  unauthorized,
  valid,
} from '~/commands/base-commands'

/**
 * User commands validation
 */

export const userValidation = {
  UpdateProfile: {
    authorize({ target }) {
      if (!target.isSender) {
        return unauthorized(`Cannot modify another user`)
      }
      return authorized()
    },
    validate({ target }) {
      return valid()
    },
  },
  SetActiveOrganization: {
    authorize({ target }) {
      if (!target.isSender) {
        return unauthorized(`Cannot modify another user`)
      }
      return authorized()
    },
    validate({ target }) {
      return valid()
    },
  },
  CreateOrganization: {
    authorize({ target }) {
      // TODO: Max # organizations (where createdBy=self)
      // TODO: Verify GlobalRole permissions
      if (!target.isSender) {
        return unauthorized(`Cannot modify another user`)
      }
      return authorized()
    },
    validate({ target }) {
      return valid()
    },
  },
  Delete: {
    authorize({ target }) {
      if (!target.isSender) {
        return unauthorized(`Cannot modify another user`)
      }
      return authorized()
    },
    validate({ target }) {
      return valid()
    },
  },
} satisfies ModelCommandsValidation<ModelName.User>
