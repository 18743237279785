'use client'
import { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import { createStore, useStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { OrganizationSkeleton } from '~/client/dashboard/components/organization/OrganizationShell'
import { QueryRequestError } from '~/client/dashboard/queries/helpers/query-helpers'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import { useOrganizationQuery } from '~/client/dashboard/queries/organization-queries'
import {
  useGlobalContext,
  useGlobalStore,
} from '~/client/dashboard/stores/GlobalStore'
import { OrganizationContext } from '~/client/dashboard/stores/OrganizationContext'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { RequestError } from '~/client/shared/ErrorPage'
import { useDebugMount } from '~/client/shared/hooks/useDebugMount'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { ClientModel } from '~/schemas'
import { OrganizationQueryKey } from '~/schemas/organization-schema'

export type ActiveOrganizationItem = {
  type: 'Process' | 'Category' | 'Revision'
  id: string
}

export enum OrganizationNavItem {
  Home = 'Home',
  Drafts = 'Drafts',
  Settings = 'Settings',
  TeamExplorer = 'Teams',
  Team = 'Team',
  Invitations = 'Invite users',
  Document = 'Document',
  Process = 'Process',
  Group = 'Group',
  Playground = 'Playground', // Debug helper
  Admin = 'Admin',
}

export type OrganizationState = {
  id: string
  activeItems: ActiveOrganizationItem[]
  activeNavItem: OrganizationNavItem | null
  organization: ClientModel['Organization']
  currentMember: ClientModel['OrganizationMember']

  // Actions
  addActiveItem: (item: ActiveOrganizationItem) => void
  removeActiveItem: (id: string) => void
  resetActiveItems: () => void
  setActiveNavItem: (item: OrganizationNavItem | null) => void
}

export const PERSISTED_FIELDS = [] as (keyof OrganizationState)[]

export type OrganizationStoreProps = {
  currentMember: ClientModel['OrganizationMember']
  organization: ClientModel['Organization']
}

export const createOrganizationStore = ({
  currentMember,
  organization,
}: OrganizationStoreProps) => {
  const id = organization.id
  const DEFAULT_PROPS = {
    id,
    organization,
    currentMember,
    activeItems: [],
    activeNavItem: null,
  } satisfies Partial<OrganizationState>

  return createStore<OrganizationState>()(
    persist(
      (set) =>
        ({
          ...DEFAULT_PROPS,
          setActiveNavItem: (activeNavItem) =>
            set(() => ({
              activeNavItem,
            })),
          addActiveItem: (item) =>
            set(({ activeItems }) => ({
              activeItems: activeItems.some((x) => x.id === item.id)
                ? activeItems
                : [...activeItems, item],
            })),
          removeActiveItem: (id) =>
            set(({ activeItems }) => ({
              activeItems: activeItems.some((x) => x.id === id)
                ? activeItems.filter((x) => x.id !== id)
                : activeItems,
            })),
          resetActiveItems: () =>
            set(() => ({
              activeItems: [],
            })),
        }) as OrganizationState,
      {
        name: 'organization-storage:' + id,
        version: 0, // Update if any fields change
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              PERSISTED_FIELDS.includes(key as keyof OrganizationState),
            ),
          ),
        // Uncomment this if data migration is required
        // In general, just updating the version is fine because UI state is not that important
        // migrate: (persistedState: OrganizationState, version) => {
        //   if (version === 0) {
        //     // if the stored value is in version 0, we rename the field to the new name
        //     persistedState.newField = persistedState.oldField
        //     delete persistedState.oldField
        //   }
        //   return persistedState
        // },
      },
    ),
  )
}

export type OrganizationStore = ReturnType<typeof createOrganizationStore>

type OrganizationProviderProps = React.PropsWithChildren<{
  queryKey: OrganizationQueryKey
  loader?: ReactNode
  failure?: ReactNode
  notFound?: ReactNode
}>

export const OrganizationProvider = ({
  queryKey,
  children,
  loader,
  failure,
  notFound,
}: OrganizationProviderProps) => {
  useDebugMount('OrganizationProvider')

  const userId = useUserContext((x) => x.id)
  const addOrganizationStore = useGlobalContext((x) => x.addOrganizationStore)
  const {
    organization,
    isLoading: isLoadingOrganization,
    error: errorLoadingOrganization,
  } = useOrganizationQuery(queryKey)
  const {
    organizationMember,
    isLoading: isLoadingMember,
    error: errorLoadingMember,
  } = useOrganizationMemberQuery({
    userId,
    organizationId: organization?.id,
  })
  const id = organization?.id
  const organizationStore = useGlobalContext((x) =>
    id ? x.organizationStores.get(id) : null,
  )

  useEffect(() => {
    if (!organizationMember || !organization || !id) return
    if (organizationStore) {
      organizationStore.setState({
        currentMember: organizationMember,
        organization,
      })
    } else {
      const store = createOrganizationStore({
        currentMember: organizationMember,
        organization,
      })
      addOrganizationStore(id, store)
    }
  }, [id, organizationStore, organizationMember])

  const error = errorLoadingOrganization || errorLoadingMember

  if (error) {
    return failure ?? <QueryRequestError error={error} />
  }

  if (!organizationMember || !organization) {
    if (isLoadingMember || isLoadingOrganization)
      return loader ?? <OrganizationSkeleton />
    return notFound ?? <RequestError code={404} message="Not Found" />
  }

  if (!organizationStore) return null

  __dev.organization = organization

  return (
    <OrganizationContext.Provider value={organizationStore}>
      <OrganizationContextLogic />
      {children}
    </OrganizationContext.Provider>
  )
}

export const useOrganizationContext = <T extends any>(
  selector: (state: OrganizationState) => T,
) => {
  const store = useContext(OrganizationContext)
  if (!store)
    throw new Error('Missing OrganizationContext.Provider in the tree')
  return useStore(store, selector)
}

export const useOrganizationStore = () => {
  const store = useContext(OrganizationContext)
  if (!store)
    throw new Error('Missing OrganizationContext.Provider in the tree')
  return store
}

const OrganizationContextLogic = () => {
  const userId = useUserContext((x) => x.id)
  const activeOrganizationId = useUserContext(
    (x) => x.user.activeOrganizationId,
  )
  const { setActiveOrganization } = useCommand(
    ModelName.User,
    'SetActiveOrganization',
    { id: userId },
  )
  const id = useOrganizationContext((x) => x.id)

  useEffect(() => {
    if (activeOrganizationId !== id) {
      setActiveOrganization({
        organizationId: id,
      })
    }
  }, [id, activeOrganizationId])

  return null
}
