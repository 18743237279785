import {
  Combobox,
  InputBase,
  PillsInputProps,
  Text,
  useCombobox,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { ProcessPrivacy } from '@prisma/client'
import { z } from 'zod'
import { Column, Row } from '~/client/shared/Layout'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  EditFormProps,
  FormButton,
  FormModal,
  FormProps,
  ModalFormProps,
  NewFormProps,
} from '~/client/dashboard/forms/form-helpers'
import { ModelName } from '~/commands/base-commands'
import { useCommand } from '~/commands/client-commands'
import { processPrivacy } from '~/client/shared/data/process-data'
import { BootstrapProcess, ClientModel } from '~/schemas'
import { ProcessQueryKey, ProcessSchema } from '~/schemas/process-schema'
import classes from './Forms.module.css'
import { useState, useCallback, useEffect } from 'react'
import {
  TreeItem,
  buildDocumentFlatTree,
} from '~/client/dashboard/components/global/TreeView'
import {
  DocumentItemMultiSelectInput,
  DocumentItemMultiSelectInputProps,
} from '~/client/dashboard/forms/DocumentForms'
import { useOrganizationMemberDocumentProfilesQuery } from '~/client/dashboard/queries/organization-member-queries'
import {
  useOrganizationQuery,
  useOrganizationDocumentsQuery,
} from '~/client/dashboard/queries/organization-queries'
import { useProcessQuery } from '~/client/dashboard/queries/process-queries'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'

/**
 * Appearance
 */

const ProcessFormSchema = z.object({
  id: ProcessSchema.shape.id.optional(),
  privacy: ProcessSchema.shape.privacy,
})

type Settings = {}
type ProcessFormProps = FormProps<typeof ProcessFormSchema, Settings>

export const ProcessSettingsForm = ({
  form,
  settings = {},
}: ProcessFormProps) => {
  const { initialValues, isUpdating, onSubmit, buttonText = 'Save' } = form
  const processSettingsForm = useForm({
    validate: zodResolver(ProcessFormSchema),
    initialValues,
  })

  return (
    <form
      onSubmit={processSettingsForm.onSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      <ProcessPrivacySelect {...processSettingsForm.getInputProps('privacy')} />
      <FormButton mt="sm" loading={isUpdating}>
        {buttonText}
      </FormButton>
    </form>
  )
}

/**
 * New Process
 */

type NewProcessFormProps = NewFormProps<
  ProcessFormProps,
  {
    organizationId: string
    documentIds?: string[]
    onCreate?: (process: BootstrapProcess) => void
  }
>

export const NewProcessForm = ({
  onSubmit,
  onCreate,
  settings = {},
  organizationId,
  documentIds = [],
}: NewProcessFormProps) => {
  const { createProcessAsync, isPending } = useCommand(
    ModelName.Organization,
    'CreateProcess',
  )

  return (
    <ProcessSettingsForm
      settings={settings}
      form={{
        initialValues: {
          privacy: ProcessPrivacy.Open,
        },
        buttonText: 'Create',
        isUpdating: isPending,
        onSubmit(values) {
          onSubmit?.()
          void createProcessAsync({
            key: {
              id: organizationId,
            },
            params: {
              privacy: values.privacy,
              documentIds,
            },
          }).then(onCreate)
        },
      }}
    />
  )
}

export const NewProcessModal = ({
  documentIds = [],
  organizationId,
  onCreate,
  ...props
}: ModalFormProps<NewProcessFormProps>) => {
  return (
    <FormModal {...props} title="New Process">
      <NewProcessForm
        onSubmit={props.onClose}
        onCreate={onCreate}
        documentIds={documentIds}
        organizationId={organizationId}
      />
    </FormModal>
  )
}

/**
 * Edit Process
 */

type EditProcessFormProps = EditFormProps<ProcessFormProps, ProcessQueryKey>

export const EditProcessForm = ({
  queryKey,
  settings = {},
  onSubmit,
}: EditProcessFormProps) => {
  return null
  // const { document } = useProcessQuery(queryKey)
  // const { updateProcessProfile, isPending: isUpdating } = useCommand(
  //   ModelName.Process,
  //   'UpdateProfile',
  //   queryKey,
  // )
  // if (!document) return null
  // return (
  //   <ProcessSettingsForm
  //     settings={settings}
  //     form={{
  //       initialValues: {
  //         id: document.id,
  //         title: document.title,
  //         description: document.description,
  //         parentId: document.parentId!,
  //         privacy: document.privacy,
  //       },
  //       isUpdating,
  //       onSubmit(values) {
  //         updateProcessProfile({
  //           parentId: values.parentId,
  //           title: values.title,
  //           description: values.description,
  //           privacy: values.privacy,
  //         })
  //         onSubmit?.()
  //       },
  //     }}
  //   />
  // )
}

export const EditProcessModal = ({
  settings,
  queryKey,
  ...props
}: ModalFormProps<EditProcessFormProps>) => {
  return (
    <FormModal {...props} title="Process Settings">
      <EditProcessForm
        onSubmit={props.onClose}
        settings={settings}
        queryKey={queryKey}
      />
    </FormModal>
  )
}

export const EditProcessCategoriesModal = () => {
  // TODO:
  return null
}

/**
 * Custom inputs
 */

const ProcessPrivacySelect = ({
  value,
  onChange,
  error,
}: {
  value?: ProcessPrivacy
  onChange: (value: ProcessPrivacy) => void
  error?: string
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const Option = ({ privacy }: { privacy: ProcessPrivacy }) => {
    const active = privacy === value

    return (
      <Combobox.Option value={privacy}>
        <Row justify="space-between">
          <OptionContent privacy={privacy} />
          {active && <Icon size={20} name="PiCheckBold" />}
        </Row>
      </Combobox.Option>
    )
  }

  const OptionContent = ({ privacy }: { privacy: ProcessPrivacy }) => {
    return (
      <Row gap="sm">
        <Icon size={20} nudgeUp={2} name={processPrivacy[privacy].iconName} />
        <Column gap={2} px="sm" py="xs">
          <Text fz="md" fw={600} data-important>
            {privacy}
          </Text>
          <Text fz="sm">{processPrivacy[privacy].description}</Text>
        </Column>
      </Row>
    )
  }

  return (
    <Combobox
      store={combobox}
      offset={0}
      onOptionSubmit={(val) => {
        onChange(val as ProcessPrivacy)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          size="md"
          label="Privacy"
          component="button"
          error={error}
          pointer
          rightSection={
            <Row gap="xs" justify="flex-end" w="100%" pr="sm">
              <Icon name="PiCaretDownBold" />
            </Row>
          }
          onClick={(e) => {
            e.preventDefault()
            combobox.toggleDropdown()
          }}
          rightSectionPointerEvents="none"
          styles={{
            section: {
              width: rem(60),
            },
            input: {
              height: 56,
            },
          }}
        >
          <OptionContent privacy={value!} />
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown className={classes.dropdown}>
        <Combobox.Options>
          <Option privacy={ProcessPrivacy.Open} />
          <Option privacy={ProcessPrivacy.Protected} />
          <Option privacy={ProcessPrivacy.Managed} />
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export const ProcessDocumentSelectionInput = ({
  context,
  ...props
}: {
  context: {
    processId: string
    organizationId: string
    organizationMemberId: string
  }
} & Pick<
  DocumentItemMultiSelectInputProps,
  | 'nested'
  | 'label'
  | 'error'
  | 'isDropdown'
  | 'isEditable'
  | 'wrap'
  | 'maxItemWidth'
>) => {
  const { processId, organizationId, organizationMemberId } = context
  const { setProcessCategories } = useCommand(
    ModelName.Process,
    'SetCategories',
    {
      id: processId,
      organizationId,
    },
  )

  // Queries
  const { process } = useProcessQuery({
    id: processId,
    organizationId,
  })
  const { organization } = useOrganizationQuery({
    id: organizationId,
  })
  const { documents } = useOrganizationDocumentsQuery({
    id: organizationId,
  })
  const { memberDocumentProfiles } = useOrganizationMemberDocumentProfilesQuery(
    {
      id: organizationMemberId,
      organizationId,
    },
  )

  // State
  const [documentTreeData, setDocumentTreeData] = useState<
    TreeItem<ClientModel['Document']>[]
  >([])

  // Helpers
  const canEditDocument = useCallback(
    (document: ClientModel['Document']) => {
      const documentMemberProfile =
        memberDocumentProfiles.find(
          (member) => member.documentId === document.id,
        ) ?? null

      // TODO: Check team roles
      const documentAccess = getMemberDocumentAccess({
        privacy: document.privacy,
        documentMemberRole: documentMemberProfile?.role ?? null,
      })

      return documentAccess.edit
    },
    [memberDocumentProfiles.length],
  )

  useEffect(() => {
    if (!organization || !process) return

    const data = buildDocumentFlatTree<ClientModel['Document']>(
      organization.rootDocumentId,
      documents,
      [],
      (x) => !x.data.isArchived,
    )
    setDocumentTreeData(data)
  }, [documents.length, organization?.id, process?.id])

  return (
    <DocumentItemMultiSelectInput
      {...props}
      data={documentTreeData}
      value={process?.documentIds ?? []}
      disabledFilter={(x) => {
        return !canEditDocument(x.data)
      }}
      onAdd={(id) => {
        if (!process) return
        setProcessCategories({
          documentIds: [...process.documentIds, id],
        })
      }}
      onRemove={(id) => {
        if (!process) return
        setProcessCategories({
          documentIds: process.documentIds.filter((x) => x !== id),
        })
      }}
    />
  )
}
