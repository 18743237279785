import { Center, Portal, Text } from '@mantine/core'
import { getHotkeyHandler, usePrevious } from '@mantine/hooks'
import { motion } from 'framer-motion'
import { ProcessIcon } from '~/client/dashboard/components/process/ProcessComponents'
import { DeadEnd } from '~/client/dashboard/components/process/diagram/DiagramAddNode'
import {
  DiagramNodeProps,
  SUBPROCESS_NODE_CONTENT_HEIGHT,
  SUBPROCESS_NODE_CONTENT_MARGIN,
  SUBPROCESS_NODE_EMPTY_HEIGHT,
  SUBPROCESS_NODE_ITEM_HEIGHT,
  SUBPROCESS_NODE_ITEM_INSET,
  SUBPROCESS_NODE_ITEM_MARGIN,
  SUBPROCESS_NODE_WIDTH,
  useNodeFontSize,
  useNodeParentPosition,
} from '~/client/dashboard/components/process/diagram/diagram-settings'
import { useRevisionContentQuery } from '~/client/dashboard/queries/revision-queries'
import { useDiagramContext } from '~/client/dashboard/stores/DiagramStore'
import { useNodeContext } from '~/client/dashboard/stores/NodeStore'
import { useProcessContext } from '~/client/dashboard/stores/ProcessStore'
import { Box, Row } from '~/client/shared/Layout'
import { NodeType } from '~/schemas/node-schema'
import { sortBy } from '~/utils/logic'
import classes from './Diagram.module.css'
import { useDarkMode, useGlobalContext } from '~/client/dashboard/stores/GlobalStore'

export const DiagramSubprocessNode = (props: DiagramNodeProps) => {
  const isDarkMode = useDarkMode()
  const getDocColor = useGlobalContext((x) => x.getDocColor)
  const getNodeColor = useGlobalContext((x) => x.getNodeColor)
  const getNodeStrokeColor = useGlobalContext((x) => x.getNodeStrokeColor)
  const { data, positionAbsoluteX, positionAbsoluteY } = props
  const globalId = useNodeContext((x) => x.globalId)
  const isExpanded = useNodeContext((x) => x.isExpanded)
  const title = useNodeContext((x) => x.title)
  const organizationId = useProcessContext((x) => x.process.organizationId)
  const subprocessId = useNodeContext((x) => x.subprocessId)!
  const subprocessRevisionId = useNodeContext((x) => x.subprocessRevisionId)!
  const expandSubdocNode = useDiagramContext((x) => x.expandSubdocNode)
  const collapseSubdocNode = useDiagramContext((x) => x.collapseSubdocNode)
  const fontSize = useNodeFontSize()
  const currentNodeId = useDiagramContext((x) => x.currentNodeId)
  const setCurrentNode = useDiagramContext((x) => x.setCurrentNode)
  const debug = useDiagramContext((x) => x.debug)
  const isActive = currentNodeId === props.id
  const textInset = props.data.textInset

  const { content: subprocessContent } = useRevisionContentQuery({
    id: subprocessRevisionId,
    processId: subprocessId,
    organizationId,
  })

  const outlineColor = isDarkMode ? Color.blue(100) : Color.blue(400)
  const processColor = getDocColor(subprocessId)
  const nodeColor = getNodeColor(data.type)
  const backgroundColor = processColor
    .mix(
      isDarkMode ? Color.dark(700) : Color.white(0),
      isDarkMode ? 0.25 : 0.12,
    )
    .desaturate(0.1)
    .fade(0.6)
    .toString()
  const boxShadow = isExpanded
    ? `0 0 0 3px inset ${processColor.toString()}`
    : `0 0 0 1px inset ${processColor.toString()}`

  const previous = {
    width: usePrevious(props.width),
    height: usePrevious(props.height),
    boxShadow: usePrevious(boxShadow),
    backgroundColor: usePrevious(backgroundColor),
  }

  const parentPosition = useNodeParentPosition(props.data.parentGlobalId)
  const color = getNodeColor(NodeType.End)

  if (!subprocessId) return null

  return (
    <>
      <Portal target=".react-flow__viewport">
        <motion.div
          className={classes.diagramNodeWrapper}
          data-id={data.id}
          data-global-id={globalId}
          data-parent-global-id={data.parentGlobalId}
          data-expanded={data.isExpanded}
          onClick={() => {
            setCurrentNode(props.id)
          }}
          style={{
            zIndex: isExpanded ? 0 : 2,
            pointerEvents: 'all',
            cursor: 'pointer',
            inset: `${textInset.top} ${textInset.right} ${textInset.bottom} ${textInset.left}`,
            textAlign: 'center',
            outlineWidth: 3,
            outlineOffset: 4,
            outlineStyle: isActive && !isExpanded ? 'solid' : 'none',
            outlineColor,
          }}
          initial={{
            left: parentPosition.x,
            top: parentPosition.y,
            height: previous.height,
            width: previous.width,
            boxShadow: previous.boxShadow,
            backgroundColor: previous.backgroundColor,
          }}
          animate={{
            left: positionAbsoluteX,
            top: positionAbsoluteY,
            boxShadow,
            backgroundColor,
            height: props.height,
            width: props.width,
          }}
          tabIndex={1}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
          onFocus={() => {
            // setCurrentNode(id)
          }}
          onKeyDown={getHotkeyHandler([
            // TODO: Hotkeys
          ])}
        >
          {/* TOP */}
          <Row
            w="100%"
            h={40}
            pl={36}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              transition: '400ms ease all',
              transform: isExpanded ? `translateY(-44px)` : `translateY(0)`,
              pointerEvents: 'none',
            }}
          >
            <Box
              className={classes.diagramNodeSubprocessIcon + ' nodrag'}
              style={
                isExpanded
                  ? {
                      transform: `translateY(0) translateX(0)`,
                      top: 3,
                      left: 2,
                    }
                  : {
                      transform: `translateY(-50%) translateX(-50%)`,
                      top: 3,
                      left: 2,
                    }
              }
              onClick={() =>
                isExpanded
                  ? collapseSubdocNode(globalId)
                  : expandSubdocNode(globalId)
              }
              bg={isDarkMode ? 'dark.6' : 'white'}
            >
              <ProcessIcon size={34} id={subprocessId} revisionId={null} />
            </Box>
            <Box
              px="sm"
              py="xs"
              w="100%"
              c={isDarkMode ? 'white.0' : 'dark.8'}
              miw={200}
              style={{
                position: 'absolute',
                opacity: isExpanded ? 1 : 0,
                transition: `opacity 200ms ease ${isExpanded ? '0ms' : '400ms'}`,
                // background: processColor.toString(),
              }}
            >
              {title}
            </Box>
          </Row>
          {!isExpanded && (
            <>
              <Center
                w="100%"
                h={SUBPROCESS_NODE_CONTENT_HEIGHT}
                mb={SUBPROCESS_NODE_CONTENT_MARGIN}
              >
                <Text
                  lh="1.2"
                  fz={fontSize}
                  c={isDarkMode ? 'white.0' : 'dark.8'}
                >
                  {title}
                </Text>
              </Center>
            </>
          )}

          {!isExpanded && data.connectorsOut.length === 0 && (
            <Center w="100%" h={SUBPROCESS_NODE_EMPTY_HEIGHT}>
              <DeadEnd />
            </Center>
          )}

          {/* Labels */}
          {sortBy(data.connectorsOut, 'position').map((x) => {
            return (
              <Box
                key={x.id + '-label'}
                w={SUBPROCESS_NODE_WIDTH - SUBPROCESS_NODE_ITEM_INSET * 2}
                h={SUBPROCESS_NODE_ITEM_HEIGHT}
                ml={SUBPROCESS_NODE_ITEM_INSET}
                mb={SUBPROCESS_NODE_ITEM_MARGIN}
                onClick={() => setCurrentNode(globalId)}
                style={{
                  position: 'relative',
                  borderRadius: SUBPROCESS_NODE_ITEM_HEIGHT / 2,
                  background: color.toString(),
                  border: `1px solid ${getNodeStrokeColor(color).toString()}`,
                  opacity: isExpanded ? 0 : 1,
                  transition: '300ms ease all',
                  pointerEvents: 'all',
                  cursor: 'pointer',
                }}
              >
                <Center
                  style={{
                    position: 'absolute',
                    inset: `${textInset.top} ${textInset.right} ${textInset.bottom} ${textInset.left}`,
                    textAlign: 'center',
                    display: 'flex',
                  }}
                >
                  <Text
                    lh="1.2"
                    fz={fontSize}
                    c={isDarkMode ? 'white.0' : 'dark.8'}
                  >
                    {
                      subprocessContent?.nodes.find(
                        (node) => node.id === x.position,
                      )?.title
                    }
                  </Text>
                </Center>
                {debug && (
                  <div
                    style={{
                      fontSize: 10,
                      position: 'absolute',
                      right: '100%',
                      width: 60,
                    }}
                  >
                    {x.id}
                  </div>
                )}
              </Box>
            )
          })}
        </motion.div>
      </Portal>
    </>
  )
}
