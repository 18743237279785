'use client'

import { ActionIcon } from '@mantine/core'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  useColors,
} from '~/client/shared/hooks/useColors'
import {useDarkMode} from '~/client/dashboard/stores/GlobalStore.tsx'

export const DarkModeToggle = () => {
  const { toggleColorScheme } = useColors()
  const isDarkMode = useDarkMode()

  return (
    <ActionIcon
      onClick={() => toggleColorScheme()}
      variant="default"
      aria-label="Toggle color scheme"
    >
      {isDarkMode && <Icon name="PiSun" />}
      {!isDarkMode && <Icon name="PiMoon" />}
    </ActionIcon>
  )
}
