import {
  Anchor,
  Box,
  Button,
  ModalProps,
  Select,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { OrganizationRole } from '@prisma/client'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { Column, Row } from '~/client/shared/Layout'
import { Icon } from '~/client/dashboard/components/global/Icon'
import { CopyInput } from '~/client/dashboard/components/global/Prefabs'
import {
  INVITATION_URL,
  InvitationBadges,
} from '~/client/dashboard/components/settings/Invitations'
import {
  EditFormProps,
  FormButton,
  FormModal,
  FormProps,
} from '~/client/dashboard/forms/form-helpers'
import {
  useUpdateInvitation,
  useArchiveInvitation,
  useUnarchiveInvitation,
} from '~/client/dashboard/mutations/invitation-mutations'
import {
  useInvitationQuery,
  useInvitationRedemptionsQuery,
} from '~/client/dashboard/queries/invitation-queries'
import { useOrganizationInvitationsQuery } from '~/client/dashboard/queries/organization-queries'
import { ClientModel } from '~/schemas'
import {
  OrganizationInvitationQueryKey,
  OrganizationInvitationSchema,
} from '~/schemas/invitation-schema'
import { NAME_MAX_LENGTH } from '~/schemas/organization-schema'

/**
 * Appearance
 */

const InvitationFormSchema = z.object({
  role: OrganizationInvitationSchema.shape.role,
  label: OrganizationInvitationSchema.shape.label.optional(),
  validUntil: OrganizationInvitationSchema.shape.validUntil.optional(),
  inviteMessage: OrganizationInvitationSchema.shape.inviteMessage.optional(),
  emailToMatch: OrganizationInvitationSchema.shape.emailToMatch.optional(),
  memberAlias: OrganizationInvitationSchema.shape.memberAlias.optional(),
  memberImageUrl: OrganizationInvitationSchema.shape.memberImageUrl.optional(),
  memberTitle: OrganizationInvitationSchema.shape.memberTitle.optional(),
  memberDescription:
    OrganizationInvitationSchema.shape.memberDescription.optional(),
  memberIsDisplayLocked:
    OrganizationInvitationSchema.shape.memberIsDisplayLocked.optional(),
})

type Settings = {}
type InvitationFormProps = FormProps<typeof InvitationFormSchema, Settings>

export const InvitationSettingsForm = ({
  form,
  settings = {},
}: InvitationFormProps) => {
  const { initialValues, isUpdating, onSubmit, buttonText = 'Save' } = form
  const editInvitationForm = useForm({
    validate: zodResolver(InvitationFormSchema),
    initialValues,
  })

  return (
    <form
      onSubmit={editInvitationForm.onSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      {/* <Select
        data={[
          {
            value: null,
            label: '1 day',
          },
          {
            value: dayjs(new Date()).add(7, 'day').toString(),
            label: '7 days',
          },
          {
            value: dayjs(new Date()).add(7, 'day').toString(),
            label: '7 days',
          },
        ]}
      /> */}
    </form>
  )
}

type OverviewSettings = {
  redemptionsRemaining?: number
  id: string
  organizationId: string
  code: ClientModel['Invitation']['code']
}
type InvitationOverviewFormProps = FormProps<
  typeof InvitationFormSchema,
  OverviewSettings
>

export const InvitationOverviewForm = ({
  form,
  settings,
}: InvitationOverviewFormProps) => {
  const { initialValues, isUpdating, onSubmit, buttonText = 'Save' } = form
  const editInvitationForm = useForm({
    validate: zodResolver(InvitationFormSchema),
    initialValues,
  })
  const { values } = editInvitationForm
  const submit = editInvitationForm.onSubmit(onSubmit)

  // Note: Uncomment if auto-update is desired
  // useEffect(() => {
  //   // Run update on each change
  //   if (document.activeElement?.tagName.toLowerCase() === 'input') return
  //   if (!editInvitationForm.isDirty()) return

  //   submit()
  // }, [values.role, values.label])

  return (
    <form
      onSubmit={submit}
      style={{ display: 'flex', flexDirection: 'column', gap: rem(12) }}
    >
      <Column gap="md">
        <TextInput
          label="Label"
          placeholder="Add a label"
          maxLength={NAME_MAX_LENGTH}
          {...editInvitationForm.getInputProps('label')}
        />
        <Select
          label="Role"
          data={[
            {
              value: OrganizationRole.User,
              label: 'User',
            },
            {
              value: OrganizationRole.Admin,
              label: 'Admin',
            },
            {
              value: OrganizationRole.Guest,
              label: 'Guest',
            },
          ]}
          {...editInvitationForm.getInputProps('role')}
        />
        <Row>
          <CopyInput
            label="Invite Link"
            inputWrapperOrder={['label', 'input', 'description']}
            description={
              settings.redemptionsRemaining === undefined
                ? ''
                : `${settings.redemptionsRemaining} use${
                    settings.redemptionsRemaining === 1 ? '' : 's'
                  } remaining`
            }
            styles={{
              input: { background: 'transparent' },
            }}
            onFocus={(e) => e.currentTarget.select()}
            value={INVITATION_URL(settings.code)}
          />
        </Row>
        <Row w="100%" justify="space-between" mt="sm">
          <InvitationDisabledToggle
            invitationId={settings.id}
            organizationId={settings.organizationId}
          />
          {/* TODO: Add this back when implementing link settings */}
          {/* <Button
              size="sm"
              onClick={() => {
                setActiveTab(EditInvitationStep.Edit)
              }}
            >
              Edit link settings
            </Button> */}
          {/* TODO: Remove this when implementing link settings */}
          <FormButton mt="sm" loading={isUpdating}>
            {buttonText}
          </FormButton>
        </Row>
      </Column>
    </form>
  )
}

/**
 * Manage Invitation
 */

type ManageInvitationFormProps = EditFormProps<
  InvitationFormProps,
  OrganizationInvitationQueryKey
>

enum ManageInvitationStep {
  Main = 'Main',
  Edit = 'Edit',
}

export const ManageInvitationForm = ({
  settings = {},
  queryKey,
  onSubmit,
}: ManageInvitationFormProps) => {
  const { invitation } = useInvitationQuery(queryKey)
  const { updateInvitation, isUpdatingInvitation } = useUpdateInvitation()
  const [activeTab, setActiveTab] = useState<ManageInvitationStep>(
    ManageInvitationStep.Main,
  )
  const { invitationRedemptions, isPending } = useInvitationRedemptionsQuery({
    id: invitation?.id,
    organizationId: invitation?.organizationId,
  })

  useEffect(() => {
    setActiveTab(ManageInvitationStep.Main)
  }, [invitation?.id])

  if (!invitation) return null

  const remaining = invitation.maxRedemptions - invitationRedemptions.length

  return (
    <Tabs value={activeTab}>
      <Tabs.Panel value={ManageInvitationStep.Main}>
        <InvitationOverviewForm
          form={{
            initialValues: {
              role: invitation.role,
              label: invitation.label,
            },
            isUpdating: isUpdatingInvitation,
            onSubmit(values) {
              updateInvitation({
                id: invitation.id,
                organizationId: invitation.organizationId,
                role: values.role,
                label: values.label,
              })
              onSubmit?.()
            },
          }}
          settings={{
            code: invitation.code,
            id: invitation.id,
            organizationId: invitation.organizationId,
            redemptionsRemaining: remaining,
            ...settings,
          }}
        />
      </Tabs.Panel>
      {/* <Tabs.Panel value={EditInvitationStep.Edit}>
        <InvitationSettingsForm
          settings={settings}
          form={{
            initialValues: InvitationFormSchema.parse(invitation),
            isUpdating: isUpdatingInvitation,
            onSubmit() {
              updateInvitation({
                id: invitation.id,
                organizationId: invitation.organizationId,
                ...values,
              })
              onSubmit?.()
            },
          }}
        >
          <Row w="100%" justify="space-between" mt="sm">
            <InvitationDisabledToggle
              invitationId={invitationId}
              organizationId={organizationId}
            />
            <Row gap="sm">
              <Button
                size="sm"
                color="gray"
                onClick={() => {
                  setActiveTab(EditInvitationStep.Main)
                }}
              >
                Back
              </Button>
              <FormButton size="sm">Save</FormButton>
            </Row>
          </Row>
        </InvitationSettingsForm>
      </Tabs.Panel> */}
    </Tabs>
  )
}

export const ManageInvitationModal = ({
  queryKey,
  settings,
  ...props
}: ModalProps & ManageInvitationFormProps) => {
  const badges = <InvitationBadges {...queryKey} />

  return (
    <FormModal
      size={420}
      {...props}
      title={
        <Column>
          <Box>Manage Invitation</Box>
          <Row pos="relative" mt="xs" gap="sm">
            {badges}
          </Row>
        </Column>
      }
    >
      <ManageInvitationForm
        queryKey={queryKey}
        settings={settings}
        onSubmit={props.onClose}
      />
    </FormModal>
  )
}

/**
 * Custom Fields
 */

const InvitationDisabledToggle = ({
  invitationId,
  organizationId,
}: {
  invitationId: string
  organizationId: string
}) => {
  const { invitations } = useOrganizationInvitationsQuery({
    id: organizationId,
  })
  const invitation = invitations.find((x) => x.id === invitationId)
  const { archiveInvitation } = useArchiveInvitation()
  const { unarchiveInvitation } = useUnarchiveInvitation()

  if (!invitation) return null

  return (
    <>
      {!invitation.isArchived && (
        <Row gap="sm" align="flex-start">
          <Icon size={16} name="MdLink" mr="sm" />
          <Column align="flex-start">
            <Text>Link is active</Text>
            <Anchor
              c="red.3"
              fz="sm"
              onClick={() => {
                archiveInvitation({
                  id: invitation.id,
                  organizationId,
                })
              }}
            >
              Disable
            </Anchor>
          </Column>
        </Row>
      )}
      {invitation.isArchived && (
        <Row gap="sm" align="flex-start">
          <Icon size={16} name="MdLinkOff" mr="sm" />
          <Column align="flex-start">
            <Text>Link is disabled</Text>
            <Anchor
              onClick={() => {
                unarchiveInvitation({
                  id: invitation.id,
                  organizationId,
                })
              }}
            >
              Enable
            </Anchor>
          </Column>
        </Row>
      )}
    </>
  )
}
