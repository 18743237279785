import {
  cacheBootstrapDocument,
  cacheDocument,
} from '~/client/dashboard/queries/document-queries'
import { cacheInvitation } from '~/client/dashboard/queries/invitation-queries'
import { cacheOrganizationMember } from '~/client/dashboard/queries/organization-member-queries'
import {
  cacheBootstrapProcess,
  cacheProcess,
  cacheProcessRevisions,
} from '~/client/dashboard/queries/process-queries'
import { cacheRevision } from '~/client/dashboard/queries/revision-queries'
import {
  ApiContext,
  CHILD_ITEM_CACHE_TIME,
  CHILD_ITEM_STALE_TIME,
  CacheFn,
  CacheRemoveFn,
  RETRY,
  cacheEachKey,
  parseKey,
  useCacheResult,
} from '~/client/dashboard/queries/helpers/query-helpers'
import { cacheTeam } from '~/client/dashboard/queries/team-queries'
import {
  getCurrentUserFromCache,
} from '~/client/dashboard/queries/user-bootstrap-queries'
import { cacheUserOrganizations } from '~/client/dashboard/queries/user-queries'
import { PartialRequired } from '~/client/globals'
import { ModelName } from '~/commands/base-commands'
import {
  BootstrapOrganizationProfile,
  ClientModel,
  ModelQueryKey,
} from '~/schemas'
import { toDisplayMember } from '~/schemas/organization-member-schema'
import {
  OrganizationQueryKey,
  organizationQueryKeys,
} from '~/schemas/organization-schema'
import { api } from '~/utils/api'
import { groupBy, isMatch } from '~/utils/logic'
import { cacheDocumentMember } from '~/client/dashboard/queries/document-member-queries'

export const useOrganizationQuery = (
  key: PartialRequired<OrganizationQueryKey>,
) => {
  const enabled = OrganizationQueryKey.safeParse(key).success
  const result = api.Organization.get.useQuery(
    parseKey(key, OrganizationQueryKey),
    {
      enabled,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: RETRY,
    },
  )
  useCacheResult(result, cacheOrganization, () => key, false)

  const organization = result?.data ?? null

  return {
    ...result,
    organization,
    isLoadingOrganization: result.isPending && enabled,
    isLoadingOrganizationError: Boolean(result?.isError),
  }
}

export const useOrganizationMembersQuery = (
  key: PartialRequired<OrganizationQueryKey>,
) => {
  const result = api.Organization.getMembers.useQuery(
    parseKey(key, OrganizationQueryKey),
    {
      enabled: OrganizationQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheOrganizationMembers, () => key, false)
  const members = result.data?.map(toDisplayMember) ?? []

  return { ...result, members }
}

export const useOrganizationTeamsQuery = (
  key: PartialRequired<OrganizationQueryKey>,
) => {
  const result = api.Organization.getTeams.useQuery(
    parseKey(key, OrganizationQueryKey),
    {
      enabled: OrganizationQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheOrganizationTeams, () => key, false)

  return {
    ...result,
    isLoadingTeams: result.isPending,
    isLoadingTeamsError: result.isError,
    teams: result.data ?? [],
  }
}

export const useOrganizationDocumentsQuery = (
  key: PartialRequired<OrganizationQueryKey>,
) => {
  const result = api.Organization.getDocuments.useQuery(
    parseKey(key, OrganizationQueryKey),
    {
      enabled: OrganizationQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheOrganizationDocuments, () => key, false)

  return {
    ...result,
    documents: result.data ?? [],
    isLoadingDocuments: result.isPending,
    isLoadingDocumentsError: result.isError,
  }
}

export const useOrganizationProcessesQuery = (
  key: PartialRequired<OrganizationQueryKey>,
) => {
  const result = api.Organization.getProcesses.useQuery(
    parseKey(key, OrganizationQueryKey),
    {
      enabled: OrganizationQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheOrganizationProcesses, () => key, false)

  return {
    ...result,
    processes: result.data ?? [],
    isLoadingProcesses: result.isPending,
  }
}

export const useOrganizationInvitationsQuery = (
  key: PartialRequired<OrganizationQueryKey>,
) => {
  const result = api.Organization.getInvitations.useQuery(
    parseKey(key, OrganizationQueryKey),
    {
      enabled: OrganizationQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheOrganizationInvitations, () => key, false)

  return {
    ...result,
    invitations: result.data ?? [],
    isLoadingInvitations: result.isPending,
  }
}

export const useOrganizationRevisionsQuery = (
  key: PartialRequired<OrganizationQueryKey>,
) => {
  const result = api.Organization.getRevisions.useQuery(
    parseKey(key, OrganizationQueryKey),
    {
      enabled: OrganizationQueryKey.safeParse(key).success,
      staleTime: CHILD_ITEM_STALE_TIME,
      gcTime: CHILD_ITEM_CACHE_TIME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: RETRY,
    },
  )

  useCacheResult(result, cacheOrganizationRevisions, () => key, false)

  return {
    ...result,
    revisions: result.data ?? [],
    isLoadingRevisions: result.isPending,
  }
}

/**
 * Cache get
 */

export const getOrganizationFromCache = (
  ctx: ApiContext,
  key: OrganizationQueryKey,
) => {
  key = OrganizationQueryKey.parse(key)
  return ctx.Organization.get.getData(key)
}

export const getOrganizationTeamsFromCache = (
  ctx: ApiContext,
  key: OrganizationQueryKey,
) => {
  key = OrganizationQueryKey.parse(key)
  return ctx.Organization.getTeams.getData(key) ?? []
}

export const getOrganizationDocumentsFromCache = (
  ctx: ApiContext,
  key: OrganizationQueryKey,
) => {
  key = OrganizationQueryKey.parse(key)
  return ctx.Organization.getDocuments.getData(key) ?? []
}

export const getOrganizationProcessesFromCache = (
  ctx: ApiContext,
  key: OrganizationQueryKey,
) => {
  key = OrganizationQueryKey.parse(key)
  return ctx.Organization.getProcesses.getData(key) ?? []
}

export const getOrganizationMembersFromCache = (
  ctx: ApiContext,
  key: OrganizationQueryKey,
) => {
  key = OrganizationQueryKey.parse(key)
  return ctx.Organization.getMembers.getData(key) ?? []
}

export const getOrganizationInvitationsFromCache = (
  ctx: ApiContext,
  key: OrganizationQueryKey,
) => {
  key = OrganizationQueryKey.parse(key)
  return ctx.Organization.getInvitations.getData(key) ?? []
}

export const getOrganizationRevisionsFromCache = (
  ctx: ApiContext,
  key: OrganizationQueryKey,
) => {
  key = OrganizationQueryKey.parse(key)
  return ctx.Organization.getRevisions.getData(key) ?? []
}

/**
 * Cache set
 */

export const cacheBootstrapOrganization: CacheFn<
  ModelQueryKey['Organization'],
  BootstrapOrganizationProfile
> = (ctx, data, key) => {
  key = OrganizationQueryKey.parse(key)
  const result = typeof data === 'function' ? data() : data

  if (result) {
    const { organization, id } = result

    // Cache all document queries
    cacheOrganization(
      ctx,
      ClientModel.Organization.parse(organization),
      key,
      false,
    )

    // Cache all member queries
    cacheOrganizationMembers(ctx, organization.members, key, false)

    // Cache all bootstrap process queries
    organization.processes.forEach((process) => {
      cacheBootstrapProcess(ctx, process, process.process, {})
    })

    // Cache all bootstrap document queries
    organization.documents.forEach((document) => {
      // Cache current member's profile as null if it does not exist
      if (!document.members.some((x) => x.memberId === id)) {
        cacheDocumentMember(
          ctx,
          null,
          {
            memberId: id,
            documentId: document.id,
            organizationId: document.organizationId,
          },
          {},
        )
      }
      cacheBootstrapDocument(ctx, document, document, {})
    })

    // Cache all revision queries
    cacheOrganizationRevisions(ctx, organization.revisions, key, false)
  }

  return {
    previous: undefined,
    revert: () => {
      cacheBootstrapOrganization(ctx, undefined, key, {})
    },
  }
}

export const cacheOrganization: CacheFn<
  ModelQueryKey['Organization'],
  ClientModel['Organization'],
  boolean
> = (ctx, current, key, itemOnly = false) => {
  key = OrganizationQueryKey.parse(key)
  const userId = getCurrentUserFromCache(ctx, {})?.id
  const previous = getOrganizationFromCache(
    ctx,
    OrganizationQueryKey.parse(key),
  )
  const result = typeof current === 'function' ? current(previous) : current

  if (result === null) {
    ctx.Organization.get.setData(key, null)
  }

  if (result) {
    organizationQueryKeys.forEach((x) => {
      try {
        ctx.Organization.get.setData(x.parse(result), result)
      } catch {}
    })
    if (!itemOnly && userId) {
      cacheUserOrganizations(
        ctx,
        (old) => {
          if (!old) return

          if (!old.some((x) => x.id === result.id)) {
            // Add if new
            old = [...old, result]
          } else {
            old = old.map((x) => (isMatch(x, key) ? result : x))
          }

          return old
        },
        { id: userId },
      )
    }
  }

  return {
    previous,
    revert: () => {
      cacheOrganization(ctx, previous, key, itemOnly)
    },
  }
}

export const cacheOrganizationTeams: CacheFn<
  ModelQueryKey['Organization'],
  ClientModel['Team'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = OrganizationQueryKey.parse(key)
  const previous = getOrganizationTeamsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const organization = getOrganizationFromCache(ctx, key)
    cacheEachKey(
      ctx.Organization.getTeams,
      organizationQueryKeys,
      organization,
      result,
    )
    if (!listOnly) {
      result.forEach((x) => {
        cacheTeam(ctx, x, x, true)
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheOrganizationTeams(ctx, previous, key, listOnly)
    },
  }
}

export const cacheOrganizationDocuments: CacheFn<
  ModelQueryKey['Organization'],
  ClientModel['Document'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = OrganizationQueryKey.parse(key)
  const previous = getOrganizationDocumentsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const organization = getOrganizationFromCache(ctx, key)
    cacheEachKey(
      ctx.Organization.getDocuments,
      organizationQueryKeys,
      organization,
      result,
    )
    if (!listOnly) {
      result.forEach((x) => {
        cacheDocument(ctx, x, x, true)
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheOrganizationDocuments(ctx, previous, key, listOnly)
    },
  }
}

export const cacheOrganizationProcesses: CacheFn<
  ModelQueryKey['Organization'],
  ClientModel['Process'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = OrganizationQueryKey.parse(key)
  const previous = getOrganizationProcessesFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const organization = getOrganizationFromCache(ctx, key)
    cacheEachKey(
      ctx.Organization.getProcesses,
      organizationQueryKeys,
      organization,
      result,
    )
    if (!listOnly) {
      result.forEach((x) => {
        cacheProcess(ctx, x, x, true)
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheOrganizationProcesses(ctx, previous, key, listOnly)
    },
  }
}

export const cacheOrganizationMembers: CacheFn<
  ModelQueryKey['Organization'],
  ClientModel['OrganizationMember'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = OrganizationQueryKey.parse(key)
  const previous = getOrganizationMembersFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const organization = getOrganizationFromCache(ctx, key)
    cacheEachKey(
      ctx.Organization.getMembers,
      organizationQueryKeys,
      organization,
      result,
    )
    if (!listOnly) {
      result.forEach((x) => {
        cacheOrganizationMember(
          ctx,
          x,
          { ...x, userId: x.userId! },
          {
            updateUser: true,
            updateOrganizationMembers: false,
          },
        )
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheOrganizationMembers(ctx, previous, key, listOnly)
    },
  }
}

export const cacheOrganizationInvitations: CacheFn<
  ModelQueryKey['Organization'],
  ClientModel['Invitation'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = OrganizationQueryKey.parse(key)
  const previous = getOrganizationInvitationsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const organization = getOrganizationFromCache(ctx, key)
    cacheEachKey(
      ctx.Organization.getInvitations,
      organizationQueryKeys,
      organization,
      result,
    )
    if (!listOnly) {
      result.forEach((x) => {
        cacheInvitation(ctx, x, x, true)
      })
    }
  }

  return {
    previous,
    revert: () => {
      cacheOrganizationInvitations(ctx, previous, key, listOnly)
    },
  }
}

export const cacheOrganizationRevisions: CacheFn<
  ModelQueryKey['Organization'],
  ClientModel['Revision'][],
  boolean
> = (ctx, data, key, listOnly = false) => {
  key = OrganizationQueryKey.parse(key)
  const previous = getOrganizationRevisionsFromCache(ctx, key)
  const result = typeof data === 'function' ? data(previous) : data

  if (result) {
    const organization = getOrganizationFromCache(ctx, key)

    if (organization) {
      cacheEachKey(
        ctx.Organization.getRevisions,
        organizationQueryKeys,
        organization,
        result,
      )

      // Update process revisions
      const processRevisions = groupBy(result, (x) => x.processId)
      Object.entries(processRevisions).map(([processId, revisions]) => {
        cacheProcessRevisions(
          ctx,
          revisions,
          {
            id: processId,
            organizationId: organization.id,
          },
          true,
        )
      })

      if (!listOnly) {
        result.forEach((x) => {
          cacheRevision(ctx, x, x, {
            updateOrganizationRevisions: false,
            updateProcessRevisions: false,
          })
        })
      }
    }
  }

  return {
    previous,
    revert: () => {
      cacheOrganizationRevisions(ctx, previous, key, listOnly)
    },
  }
}

/**
 * Cache remove
 */

export const removeOrganizationFromCache: CacheRemoveFn<
  ModelQueryKey['Organization'],
  ClientModel['Organization']
> = (ctx, key) => {
  key = OrganizationQueryKey.parse(key)
  const previous = getOrganizationFromCache(
    ctx,
    OrganizationQueryKey.parse(key),
  )

  if (previous) {
    cacheEachKey(ctx.Organization.get, organizationQueryKeys, previous, null)
  }

  const { revert } = cacheUserOrganizations(
    ctx,
    (old) => {
      if (!old) return
      return old.filter((x) => !isMatch(x, key))
    },
    {},
  )

  return {
    previous,
    revert,
  }
}
