import { z } from 'zod'
import type { ModelCommandsSchema } from '~/commands/base-commands'
import { TeamMemberSchema } from '~/schemas/team-member-schema'
import { TeamSchema } from '~/schemas/team-schema'

/**
 * Team commands schema
 */

export const teamCommandsSchema = {
  UpdateProfile: {
    summary: `Update a team's profile settings`,
    description: ``,
    method: 'PATCH',
    schemas: {
      input: z.object({
        name: TeamSchema.shape.name,
        imageUrl: TeamSchema.shape.imageUrl.optional(),
        description: TeamSchema.shape.description.optional(),
        access: TeamSchema.shape.access.optional(),
      }),
      output: z.literal(true),
      validate: {
        sender: {},
        target: {
          newAccess: TeamSchema.shape.access.optional(),
        },
        meta: {},
      },
      authorize: {
        sender: {
          role: TeamMemberSchema.shape.role.optional(),
          isJoined: z.boolean(),
        },
        target: {
          access: TeamSchema.shape.access,
        },
        meta: {},
      },
    },
    messages: {
      failure: 'Failed to update team settings',
    },
    variables: {
      execute: 'updateTeamProfile',
      isPending: 'isUpdatingTeamProfile',
    },
  },
} as const satisfies ModelCommandsSchema
