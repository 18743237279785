'use client'

import { useUser } from '@clerk/clerk-react'
import { Button } from '@mantine/core'
import { Link } from 'wouter'
import { LogoIcon } from '~/client/shared/Logo'

export const HomeButton = () => {
  const { isSignedIn } = useUser()

  return (
    <Link href={isSignedIn ? '/dashboard' : '/'}>
      <LogoIcon size={40} />
    </Link>
  )
}

export const DashboardButton = () => {
  const { isSignedIn } = useUser()

  if (!isSignedIn) return null
  return (
    <Link href="/dashboard">
      <Button fz="md" variant="filled">
        Dashboard
      </Button>
    </Link>
  )
}

export const LoginButton = () => {
  const { isSignedIn } = useUser()

  if (isSignedIn) return null
  return (
    <Link href={'/login' + document.location.search}>
      <Button fz="md" variant="outline">
        Log In
      </Button>
    </Link>
  )
}

export const SignupButton = () => {
  const { isSignedIn } = useUser()

  if (isSignedIn) return null
  return (
    <Link href={'/signup' + document.location.search}>
      <Button fz="md" variant="filled">
        Sign up
      </Button>
    </Link>
  )
}
