import { useDocumentsWithProfileQuery } from '~/client/dashboard/queries/helpers/composite-queries'
import { cacheInvitation } from '~/client/dashboard/queries/invitation-queries'
import { useOrganizationMemberQuery } from '~/client/dashboard/queries/organization-member-queries'
import {
  cacheOrganization,
  removeOrganizationFromCache,
  useOrganizationQuery,
} from '~/client/dashboard/queries/organization-queries'
import { cacheBootstrapProcess } from '~/client/dashboard/queries/process-queries'
import { cacheTeam } from '~/client/dashboard/queries/team-queries'
import { useUserContext } from '~/client/dashboard/stores/UserStore'
import { ModelName } from '~/commands/base-commands'
import {
  ClientCommandDefinitions,
  ClientCommandContextDefinitions,
  ClientModelContextDefinition,
} from '~/commands/client-commands'
import { getMemberDocumentAccess } from '~/commands/document/document-commands-validation'

/**
 * Organization client commands
 */

export const organizationCommandContext = {
  UpdateProfile() {},
  Archive() {},
  Unarchive() {},
  Delete() {},
  CreateTeam() {},
  CreateInvitation() {},
  CreateProcess({ input, sender, target }) {
    const { documentMemberProfileMap, isLoading, isError } =
      useDocumentsWithProfileQuery({
        documentIds: input.params.documentIds ?? [],
        organizationId: target?.id,
        organizationMemberId: sender?.organizationProfile?.id,
      })

    return {
      state: {
        isLoading,
        failed: isError,
      },
      ctx: {
        documentMemberProfileMap,
      },
    }
  },
} satisfies ClientCommandContextDefinitions<ModelName.Organization>

export const organizationCommandsClient = {
  UpdateProfile: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ sender }) {
      return {
        sender: { role: sender.organizationProfile?.role },
        target: {},
        meta: {},
      }
    },
    onMutate: [
      (ctx, { params, key }) =>
        cacheOrganization(
          ctx,
          (old) => (old ? { ...old, ...params } : undefined),
          key,
          false,
        ),
    ],
  },
  Archive: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          isOwner: sender.organizationProfile?.userId === target.ownerId,
        },
        target: { type: target.type },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key }) =>
        cacheOrganization(
          ctx,
          (old) => (old ? { ...old, isArchived: true } : undefined),
          key,
          false,
        ),
    ],
  },
  Unarchive: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          isOwner: sender.organizationProfile?.userId === target.ownerId,
        },
        target: { type: target.type },
        meta: {},
      }
    },
    onMutate: [
      (ctx, { key }) =>
        cacheOrganization(
          ctx,
          (old) => (old ? { ...old, isArchived: false } : undefined),
          key,
          false,
        ),
    ],
  },
  Delete: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          isOwner: sender.organizationProfile?.userId === target.ownerId,
        },
        target: { type: target.type },
        meta: {},
      }
    },
    onSuccess: [
      (ctx, result, { key }) => removeOrganizationFromCache(ctx, key),
    ],
  },
  CreateTeam: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          role: sender.organizationProfile?.role,
        },
        target: { type: target.type },
        meta: {},
      }
    },
    onSuccess: [(ctx, result) => cacheTeam(ctx, result, result, false)],
  },
  CreateInvitation: {
    getValidationContext() {
      return {
        sender: {},
        target: {},
        meta: {},
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          role: sender.organizationProfile?.role,
        },
        target: { type: target.type },
        meta: {},
      }
    },
    onSuccess: [(ctx, result) => cacheInvitation(ctx, result, result, false)],
  },
  CreateProcess: {
    getValidationContext({ target, input, commandCtx }) {
      return {
        sender: {
          documentsAccess: commandCtx.documentMemberProfileMap.map(
            ({ document, memberProfile }) => {
              return document
                ? getMemberDocumentAccess({
                    privacy: document.privacy,
                    documentMemberRole: memberProfile?.role ?? null,
                  })
                : { edit: false, view: false }
            },
          ),
        },
        target: { type: target.type, rootDocumentId: target.rootDocumentId },
        meta: {
          isVersionControlled: input.params.isVersionControlled,
          documentIds: input.params.documentIds ?? [],
        },
      }
    },
    getAuthorizationContext({ sender, target }) {
      return {
        sender: {
          role: sender.organizationProfile?.role,
        },
        target: { type: target.type },
        meta: {},
      }
    },
    onSuccess: [
      (ctx, result) =>
        cacheBootstrapProcess(ctx, result, result.process, false),
    ],
  },
} satisfies ClientCommandDefinitions<ModelName.Organization>

/**
 * Organization client context
 */

export const useOrganizationModelCtx = ((key) => {
  const userId = useUserContext((x) => x.id)
  const { organization: target, isLoading, error } = useOrganizationQuery(key)
  
  const {
    organizationMember,
    isLoadingOrganizationMember,
    isLoadingOrganizationMemberError,
  } = useOrganizationMemberQuery({
    userId,
    organizationId: target?.id,
  })

  return {
    target,
    sender: {
      organizationProfile: organizationMember,
    },
    ctx: {
      organization: target
    },
    state: {
      isLoading: isLoading || isLoadingOrganizationMember,
      failed: Boolean(error || isLoadingOrganizationMemberError),
    },
  }
}) satisfies ClientModelContextDefinition<ModelName.Organization>
