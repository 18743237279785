import { IconName } from '~/client/dashboard/components/global/Icon.tsx'
import { DocumentPrivacy } from '@prisma/client'

export const documentIconSize = '1.2em'

export const documentIcon = 'PiTagSimpleDuotone' as IconName
export const documentIconEmpty = 'PiTagSimple' as IconName
export const documentIconFill = 'PiTagSimpleFill' as IconName
export const documentIconMultiple = 'Categories' as IconName
export const documentIconChevron = 'PiTagChevronDuotone' as IconName
export const documentIconChevronEmpty = 'PiTagChevron' as IconName

export const documentIcons = {
  Owner: 'PiIdentificationBadge',
  Member: 'PiUser',
  Draft: 'PiCircleDashed',
} satisfies {
  [key: string]: IconName
}

export const documentPrivacy = {
  Open: {
    iconName: 'PiGlobe',
    description: 'Anyone can join, view, or edit.',
  },
  Protected: {
    iconName: 'Lock',
    description: 'Members can invite and edit.',
  },
  Managed: {
    iconName: 'LockFill',
    description: 'Owners can invite and edit.',
  },
  // Private: {
  //   Note: Alternate option: 'PiEyeSlashDuotone'
  //   iconName: 'Private',
  //   description: 'Owners can invite and edit. Not discoverable.',
  // },
} satisfies {
  [Privacy in DocumentPrivacy]: {
    iconName: IconName
    description: string
  }
}
