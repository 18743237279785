import { Button, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { TeamAccess } from '@prisma/client'
import { Column, Row } from '~/client/shared/Layout'
import { Icon } from '~/client/dashboard/components/global/Icon'
import {
  PageTitle,
  TeamLogo,
} from '~/client/dashboard/components/global/Prefabs'
import { NewTeamModal } from '~/client/dashboard/forms/TeamForms'
import { useOrganizationTeamsQuery } from '~/client/dashboard/queries/organization-queries'
import { useTeamQuery } from '~/client/dashboard/queries/team-queries'
import { useOrganizationContext } from '~/client/dashboard/stores/OrganizationStore'
import { WithCommand } from '~/commands/WithCommand'
import { ModelName } from '~/commands/base-commands'
import { teamAccess } from '~/client/shared/data/team-data'

export const TeamExplorerContent = () => {
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const { teams, isLoadingTeams, isLoadingTeamsError } =
    useOrganizationTeamsQuery({
      id: organizationId,
    })

  const [newTeamOpened, { close: closeNewTeam, open: openNewTeam }] =
    useDisclosure()

  // TODO: Error Message
  if (isLoadingTeamsError) return null

  return (
    <Row align="stretch" gap="lg" w="100%">
      <Column grow={1}>
        <PageTitle
          title="Teams"
          allowXL={true}
          rightSection={
            <WithCommand
              model={ModelName.Organization}
              command="CreateTeam"
              queryKey={{ id: organizationId }}
            >
              <Button onClick={openNewTeam}>Create Team</Button>
            </WithCommand>
          }
        />
        <Column gap="sm">
          {teams.map((x) => (
            <TeamItem key={x.id} id={x.id} />
          ))}
        </Column>
      </Column>
      {/* New team modal */}
      <NewTeamModal
        opened={newTeamOpened}
        onClose={closeNewTeam}
        organizationId={organizationId}
      />
    </Row>
  )
}

const TeamItem = ({ id }: { id: string }) => {
  const organizationId = useOrganizationContext((x) => x.organization.id)
  const { team, isLoadingTeamError } = useTeamQuery({
    id,
    organizationId,
  })

  if (!team) return null

  // TODO: Error Message
  if (isLoadingTeamError) return null

  return (
    <Column>
      <Row gap="sm">
        <TeamLogo imageUrl={team.imageUrl} name={team.name} size={34} />
        <Text fz="lg">{team.name}</Text>
        {team.access !== TeamAccess.Open && (
          <Icon
            nudgeUp={4}
            name={teamAccess[team.access].iconName}
            size="0.8em"
          />
        )}
      </Row>
    </Column>
  )
}
